import React, { Component } from "react"
import { NavLink as Link } from "react-router-dom"
import NavbarLink, { NavbarButton } from "../../NavigationLink"
import { saveLinks } from "../../../../../actions/contentActions"
import { startFetch, endFetch } from "../../../../../actions/generalActions"
import NavigationSubNavbarLink, {
  SubNavbarButton,
} from "../../NavigationSubNavbarLink"
import { connect } from "react-redux"
import NotificationList from "../../Notifications/index"
import Counter from "../../Counter"
import Burger from "../../Burger"
import Dropdown from "./ElipsisDropdown"
import {
  UserService,
  AuthenticationService,
  doLogout,
  selectCampaign,
  ShoppingCartIcon,
  ProfileIcon,
  substring
} from "../../../normalizer"
import {
  headerLinksService
} from "../../../../../services"
import SimpleDropdown from "../../../../GamificationLevelTemplate/shared/SimpleDropDown"
import HeadersSection from "./HeadersSection"
import { HoverButton } from "../../../../GamificationLevelTemplate/shared/HoverButton"
import ReactGA from "react-ga"
import { withRouter } from "react-router-dom"

class NavBar extends Component {
  state = {
    toggled: false,
    Component: NavbarLink,
    ButtonComponent: NavbarButton,
  }
  mobileClassesToAdd = ["gl-nav--active", "bg-white", "text-color"]
  mobileClassesToRemove = ["navbar-text"]


  stickNavbar = () => {
    const classes = ["gl-navbar--scrolling"]

    const currentPosition = window.pageYOffset
    const navbar = document.getElementById("g-navbar")

    if (currentPosition > navbar.offsetHeight + navbar.offsetTop) {
      classes.forEach((c) => {
        navbar.classList.add(c)
      })
    } else {
      classes.forEach((c) => {
        navbar.classList.remove(c)
      })
    }
  }

  handleLogout = () => {
    UserService.logOut().then((response) => {
      AuthenticationService.logout()
      this.props.selectCampaign(undefined)
      this.props.doLogOut()
    })
  }

  hideMenu = () => {
    const nav = document.getElementById("nav").classList
    this.mobileClassesToRemove.forEach((c) => nav.add(c))
    this.mobileClassesToAdd.forEach((c) => nav.remove(c))

    const gNav = document.getElementById("g-navbar").classList
    gNav.remove("h-screen")
    this.setState({ toggled: false })
  }

  showMenu = () => {
    const nav = document.getElementById("nav").classList

    this.mobileClassesToAdd.forEach((c) => nav.add(c))
    this.mobileClassesToRemove.forEach((c) => nav.remove(c))

    const gNav = document.getElementById("g-navbar").classList
    gNav.add("h-screen")
    this.setState({ toggled: true })
  }

  updateSizes = () => {
    if (window.innerWidth > 900) this.hideMenu()

    this.setState({
      Component: window.innerWidth > 900 ? NavbarLink : NavigationSubNavbarLink,
    })
    this.setState({
      ButtonComponent: window.innerWidth > 900 ? NavbarButton : SubNavbarButton,
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSizes)
    this.updateSizes()
    window.onscroll = this.stickNavbar
    this.getHeaderLinks()
  }

  getHeaderLinks() {
    this.props.startFetch()
    const { user, campaign } = this.props
    const payload = { clientId: user.client.slug, campaignSlug: campaign.slug }
    headerLinksService
      .getLinks(payload)
      .then(response => response.data.header_links)
      .then(links => {
        this.props.saveLinks({ header_links: links })
        this.props.endFetch()
      })
      .catch(error => {
        this.props.endFetch()
      })    
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSizes)
    window.onscroll = undefined
  }

  customNavbar(campaign, type) {
    if (campaign.custom_navitems === undefined) {
      return {}
    }
    if (campaign.custom_navitems.length === 0) {
      return {}
    }
    const selectedItem = campaign.custom_navitems.find(
      (navitem) => navitem.navitem_type === type
    )
    return selectedItem || {}
  }

  customizeNavBar = () => {
    const { toRender, campaign } = this.props

    const dashboardWillRender = toRender("Dashboard") !== undefined;
    const showAcceleratorsTab = (campaign.hide_accelerators_tab == null) || !campaign.hide_accelerators_tab

    const defaultNavbar = [
      {
        willRender: toRender("News") !== undefined,
        props: { to: "/news", onClick: this.hideMenu },
        text: this.customNavbar(campaign, "news").navitem_label || "Noticias",
        order: this.customNavbar(campaign, "news").navitem_order || 10001,
        type: "news",
      },
      {
        willRender: toRender("Quizzes") !== undefined,
        props: { to: "/quiz", onClick: this.hideMenu },
        text: this.customNavbar(campaign, "quizzes").navitem_label || "Quizzes",
        order: this.customNavbar(campaign, "quizzes").navitem_order || 10002,
        type: "quizzes",
      },
      {
        willRender: dashboardWillRender ? false : toRender("Gamification-Objetivos") !== undefined,
        props: { to: "/objectives", onClick: this.hideMenu },
        text: this.customNavbar(campaign, "objectives").navitem_label || "Resultados",
        order: this.customNavbar(campaign, "objectives").navitem_order || 10003,
        type: "objectives",
      },
      {
        willRender: dashboardWillRender ? false : toRender("Rankings") !== undefined,
        props: { to: "/rankings", onClick: this.hideMenu },
        text:
          this.customNavbar(campaign, "rankings").navitem_label || "Rankings",
        order: this.customNavbar(campaign, "rankings").navitem_order || 10004,
        type: "rankings",
      },
      {
        willRender: dashboardWillRender ? toRender("Dashboard") !== undefined : false,
        props: { to: "/dashboard", onClick: this.hideMenu },
        text:
          this.customNavbar(campaign, "dashboard").navitem_label ||
          "Dashboard",
        order:
          this.customNavbar(campaign, "dashboard").navitem_order || 100007,
        type: "dashboard",
      },
      {
        willRender: true,
        props: { to: "/catalog", onClick: this.hideMenu },
        text:
          this.customNavbar(campaign, "prize_store").navitem_label ||
          "Loja de prémios",
        order:
          this.customNavbar(campaign, "prize_store").navitem_order || 10005,
        type: "prize_store",
      },
      {
        willRender: showAcceleratorsTab && toRender("Accelerators") !== undefined,
        props: { to: "/accelerators", onClick: this.hideMenu },
        text:
          this.customNavbar(campaign, "accelerators").navitem_label ||
          "Campanhas",
        order:
          this.customNavbar(campaign, "accelerators").navitem_order || 100006,
        type: "accelerators",
      },
      {
        willRender: toRender("SupportDocuments") !== undefined,
        props: { to: "/supporting-documents", onClick: this.hideMenu },
        text:
          this.customNavbar(campaign, "supporting-documents").navitem_label ||
          "Documentos",
        order:
          this.customNavbar(campaign, "supporting-documents").navitem_order || 100008,
        type: "supporting-documents",
      },      
    ]
    return defaultNavbar
  }

  separatePages() {
    const { pages } = this.props
    let customPagesOnNavbar = []
    let customPagesOnDropdown = []
    pages.forEach((page) => {
      if (page.show_on_navbar) {
        customPagesOnNavbar.push(page)
      } else {
        customPagesOnDropdown.push(page)
      }
    })
    return {
      customPagesOnNavbar: customPagesOnNavbar,
      customPagesOnDropdown: customPagesOnDropdown,
    }
  }

  // Campaign selection
  selectCampaign = (campaign) => {
    return () => {
      this.handleCampaignSelection(campaign)
    }
  }

  handleCampaignSelection = (campaign) => {
    if (campaign.slug !== this.props.campaign.slug) {
      ReactGA.event({
        category: "Campaign change",
        action: "Selected - " + campaign.name,
      })
      localStorage.setItem("campaign", JSON.stringify(campaign))
      this.props.selectCampaign(campaign)
      this.props.history.push("/")
    }
  }


  render() {
    const {
      links,
      logo,
      itemCount,
      campaignUser,
      campaign,
      campaigns,
      toRender,
    } = this.props
    const { toggled, Component, ButtonComponent } = this.state

    const { customPagesOnNavbar, customPagesOnDropdown } = this.separatePages()

    let customNavbar = this.customizeNavBar()
    customNavbar = customNavbar.sort(function(a, b) {
      return a.order - b.order
    })
    return (
      <div style={{height:'25px'}}>
        
        <nav id="g-navbar" className="gl-navbar gl-p-container header-bg" style={{height:'112px'}}>
          <div style={{position:'absolute',top:'0px',left:'0px', width:'100%', height:'50%'}}>
            {toRender("HeaderLinks") && links.length !== 0 && (
            <HeadersSection links={links} />
            )}
          </div>
          <div style={{position:'absolute',justifyContent:'center',top:'40%',left:'0px', display:'flex', height:'60%',width:'100%'}}>
          
        <Link
          onClick={this.hideMenu}
          exact
          to="/"
          className="gl-above-content content"
        >
          <img src={logo.url} alt="logo" className="gl-navbar__logo" />
        </Link>
        <div
          id="nav"
          className="gl-nav navbar-text z-2 gl-navbar__links lighter"
        >
          {customNavbar.map((navBar, i) => {
            if (navBar["willRender"]) {
              if (navBar["type"] == 'supporting-documents') {
                if (toRender("Pages") || toRender("SupportDocuments")) {
                  return (
                    <SimpleDropdown
                      condition={1 == 1}
                      message={navBar.text}
                      campaign={campaign}>
                      <div className="flex flex-col">
                        {toRender("SupportDocuments") && <Component
                            to="/supporting-documents/1"
                            onClick={this.hideMenu}
                            className="gl-mobile-show ">
                            Documentos de apoio
                          </Component>}
                        {toRender("Pages") && customPagesOnDropdown.map((page, i) => {
                          return <Component
                                   to={`/customized_pages/${page.slug}`}
                                   onClick={this.hideMenu}
                                   className="gl-mobile-show "
                                   >
                                     {substring(page.title, 11, "..")}
                                 </Component>
                        })}
                      </div>
                    </SimpleDropdown>
                  )
                }
              } else {
                return (
                  <Component {...navBar.props} key={i}>
                    {navBar.text}
                  </Component>
                )
              }
            }
          })}

          {customPagesOnNavbar.map((customPage, i) => {
            return (
              <Component
                key={i}
                to={`/customized_pages/${customPage.slug}`}
                onClick={this.hideMenu}
              >
                {customPage.title}
              </Component>
            )
          })}

          <SimpleDropdown
            condition={campaigns.length > 1}
            message="Campanhas"
            campaign={campaign}
          >
            <div className="flex flex-col">
              {campaigns.map((camp) => (
                <HoverButton
                  active={campaign.slug === camp.slug}
                  onClick={this.selectCampaign(camp)}
                  key={`nav-houver-${camp.slug}`}
                  campaign={campaign}
                >
                  {camp.name}
                </HoverButton>
              ))}
            </div>
          </SimpleDropdown>
          <ButtonComponent
            className="gl-mobile-show text-left"
            onClick={this.handleLogout}
          >
            Logout
          </ButtonComponent>
        </div>
        <div className="flex text-size gl-navbar__links">
          <NotificationList />
          <div className="gl-notification__container campaign-link-icon">
            <Link
              onClick={this.hideMenu}
              to="/profile"
              className="navbar-text gl-above-content campaign-link-icon__stroke"
            >
              <ProfileIcon className="g-navbar__icons24 mb-3" />
            </Link>
          </div>

          <Link
            onClick={this.hideMenu}
            to="/cart"
            className="navbar-text gl-notification__container campaign-link-icon"
          >
            <ShoppingCartIcon className="g-navbar__icons24 mb-3 campaign-link-icon__stroke" />
            <Counter value={itemCount} />
          </Link>
          <Dropdown
            onLogout={this.handleLogout}
            campaignUser={campaignUser}
            toRender={toRender}
            customPages={customPagesOnDropdown}
          />
        </div>
        <Burger onClick={toggled ? this.hideMenu : this.showMenu} />
        </div>
      </nav>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveLinks: links => {
      dispatch(saveLinks(links))
    },
    selectCampaign: (campaign) => {
      dispatch(selectCampaign(campaign))
    },
    doLogOut: () => {
      dispatch(doLogout())
    },
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  itemCount: state.cartReducer.cart.items.length,
  campaignUser: state.balanceReducer.campaignUser,
  campaign: state.campaignReducer.campaign,
  campaigns: state.campaignReducer.campaigns,
  links: state.contentReducer.links,
  pages: state.pagesReducer.pages,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar))
