const initialState = {
  orders: [],
  selectedOrder: {id: null},
  pagination: {}
}

const ordersReducer = (state = initialState, action) => {

  switch(action.type){
    case 'SAVE_ORDERS':
      return {
        ...state,
        orders: action.payload.orders,
        pagination: action.payload.pagination || {}
      }
    case 'SAVE_SELECTED_ORDER':
      return {
        ...state,
        selectedOrder: action.payload.order
      }
    default:
      return state;
  }
}
export default ordersReducer;