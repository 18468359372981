import React from "react"
import { Link } from "react-router-dom"

const SuccessContainer = ({ client_logo: ClientLogo, client, user }) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
        <div className="py-10">
          <ClientLogo src={client.logo.url} />
        </div>
        <div className="pb-10 px-10">
          <h2>{user}</h2>
          <p>
            Visita o teu email para terminar a recuperação da palavra-passe.
          </p>
        </div>
        <Link className="link pb-20" to={"/login"}>
          Voltar para login
        </Link>
      </div>
    </div>
  )
}

export default SuccessContainer
