import React, { Component } from 'react'
import UserAddress from './UserAddress'
import UserAddressEditForm from './UserAddressEditForm'
import { AddressesService } from '../../../../services';
import { isEmpty } from '../../../../utils/utils';
import { withContryStateContainer } from '../../../containers/gamification';

class UserAddressContainer extends Component {
  state = {
    isEditing: false,
  }

  setEditing = () => {
    this.setState({isEditing: true})
  }

  handleEditCancel = () => {
    this.setState({isEditing: false})
  }

  handleRemoveAddress = () => {
    const payload = { id: this.props.address.id }
    this.props.startTransaction( ()=>
      AddressesService.deleteAddress(payload)
        .then((response) => {
          this.props.saveAddresses(response.data);
        })
    )
  }

  handleSubmitUserAddressEdit = (formData) => {

    if (isEmpty(formData) ){
      return;
    }
    
    const payload = {
      address: {
        ...formData,
        zip_code: formData.zipCode,
        main_name: formData.addressName,
        first_name: formData.firstName,
        last_name: formData.lastName,
        state_id: this.props.selectedState.id,
        country_id: this.props.selectedCountry.id
      },
      id: this.props.address.id
    }
  
    this.props.startTransaction(()=>
      AddressesService.updateAddress(payload)
        .then((response) => {
          this.props.saveAddresses(response.data);
          this.setState({isEditing: false})
        })
        .catch((error) => {
          this.setState({errors: error.response.data});
        })
    )
  }

  render() {
    const {
      address,
      countries,
      states, 
      selectedCountry,
      selectedState,
      isShowOnly
    } = this.props 

    const {
      isEditing,
    } = this.state

    return isEditing ?
      <UserAddressEditForm 
        {...address}
        state={selectedState}
        country={selectedCountry}
        availableStates={states}
        availableCountries={countries}  
        onEditSubmit={this.handleSubmitUserAddressEdit} 
        onEditCancel={this.handleEditCancel}
        onCountryChange={this.props.onCountryChange}
        onStateChange={this.props.onStateChange}
        />
      : <UserAddress 
          onRemove={this.handleRemoveAddress}
          setEditing={this.setEditing}  
          isShowOnly={isShowOnly}
          {...address}
        />
  
  }
}

export default withContryStateContainer(UserAddressContainer)
