import React from "react"
import { Link } from "react-router-dom"

const EditForgottenPassword = ({
  client_logo: ClientLogo,
  client,
  login,
  finishPasswordRecovery
}) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 gamification__container-login">
        <div className="py-10">
          <ClientLogo src={client.logo.url} />
        </div>
        <form className="pb-10 vodafone" onSubmit={finishPasswordRecovery}>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="gamification__input"
              placeholder="Nova Password"
              required
              type="password"
              name="password"
            />
          </div>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="gamification__input"
              placeholder="Repetir Password"
              required
              type="password"
              name="passwordConfirmation"
            />
          </div>

          <div className="row center-lg center-xs center-md py-5">
            <button
              className="gamification__button-cart gamification__button-primary text-xl vodafone-bold"
              type="submit"
              value="Submit"
            >
              finalizar
            </button>
          </div>
        </form>
        <Link
          className="gamification__link pb-20 vodafone"
          to={login}
        >
          Voltar para login
        </Link>
      </div>
    </div>
  )
}

export default EditForgottenPassword
