import React from "react"
import CartNavigation from "./CartNavigation"
import { withShoppingCartContainer } from "../../containers/gamification/ShoppingCartContainer"

const CartLayout = props => {
  const { children } = props
  const showNavigation = props.history.location.pathname !== "/cart/success"

  return (
    <div className="g-cart">
      <section className="my-5 flex flex-col">
        {showNavigation && <CartNavigation />}
        {children}
      </section>
    </div>
  )
}

export default withShoppingCartContainer(CartLayout)
