import React from "react"
import CircularLoader from "./CircularLoader"
import { Stars,  whiteStars, NumberToHumanRange } from "../../normalizer";


const isFull = ({campaign,requirement}) => {
  if ( requirement.percentage >= 100.0 ){
    return { 
            loaderClass: 'gl-levels__requirements__loader-circle-progress primary-bg', 
            loaderColor: 'white'
           }
  } else { 
    return {
            loaderClass: 'gl-levels__requirements__loader-circle-progress', 
            loaderColor: campaign.color_primary
           }
  }
}

const Requirement = (props) => {
  const { loaderColor, loaderClass } = isFull(props)
  const { requirement, isCampaignUserLevel } = props
  const isCompleted = requirement.percentage >= 100.0
  const normalizedPercentage = isCompleted ? 100 : Number(requirement.percentage)
  return (
      <div className='pb-4 col-md-3 col-sm-4 col-xs-6 flex flex-col gl-levels__requirements__container'>
        <div className='relative m-auto'> 
          <CircularLoader 
            color={loaderColor} 
            progress={normalizedPercentage} 
            stroke={8} 
            radius={64} 
            id={`${requirement.id}`}
            containerclassName={'m-auto'}
            svgclassName={loaderClass}
            svgContainerClassName={isCompleted ? 'gl-levels__requirements__loader-shadow' : ''}
          >
            <img src={isCompleted ? whiteStars : Stars} />
            <p className={'gl-levels__requirements__loader-circle-wrapper-points' + (isCompleted ? ' secondary-text' : '')}>{NumberToHumanRange(requirement.goal,2,3)}</p>
            
          </CircularLoader>
          { isCampaignUserLevel &&
            <div className={'gl-levels__requirements__progress-bubble-container secondary-bg'}>
              {isCompleted ? 
                <i className='fa fa-check text-xl primary-text'></i>
              :
                <p className='text-color gl-levels__requirements__progress-bubble-text  font-bold'>{NumberToHumanRange(requirement.progress,0,3)}</p>
              }
            </div>
          }
        </div>
        <p className={'navbar-text text-center'}>{requirement.name}</p>
      </div>
  )
}

export default Requirement