import React from "react"
import ReactSelect from "react-select"
import { Switch } from "react-router-dom"
import { default as Route } from "../../../routes/CustomRoute"
import AnalyticsLayout from "./AnalyticsLayout"
import Store from "./Contributors"
import Objectives from "./Objectives"
import Sales from "./Sales"
import AnalyticsGDS from './GoogleDataStudio'
import Layout from "../shared/Layout"
import { withAnalyticsContainer } from "../normalizer"
import EmptyStateLayout from "../shared/EmptyStateLayout"
import { ReactComponent as NoStore } from "../../../assets/img/no_store.svg"

const Analytics = ({ client, stores, selectedGroup, onGroupSelection, campaignUser }) => {
  const message =
    stores.length > 1 ? "Comece por selecionar uma loja..." : "A carregar..."

  return (
    <>
      {stores.length > 1 && (
        <div className="flex justify-end mb-5 mt-10">
          <ReactSelect
            options={stores}
            onChange={onGroupSelection}
            getOptionLabel={option => option.name || option.external_identifier}
            getOptionValue={option => option.id}
            value={selectedGroup}
            placeholder={"Escolha uma loja..."}
            noOptionsMessage={() => "A loja que procura não existe..."}
            className="gl-profile__info gl-profile__info--value outline-none gl-analytics__select"
            classNamePrefix="o-custom-select"
          />
        </div>
      )}
      {selectedGroup ? (
        <AnalyticsLayout client={client} group={selectedGroup} campaignUser={campaignUser}>
          <Switch>
            <Route
              exact
              path="/analytics/:id"
              component={Store}
              group={selectedGroup}
            />
            <Route
              exact
              path="/analytics/:id/sales"
              component={Sales}
              group={selectedGroup}
            />
            <Route
              exact
              path="/analytics/:id/objectives"
              component={Objectives}
              group={selectedGroup}
            />
            <Route
              exact
              path="/analytics/:id/gds"
              component={AnalyticsGDS}
              group={selectedGroup}
            />
          </Switch>
        </AnalyticsLayout>
      ) : (
        <EmptyStateLayout message={message}>
          <div className="gl-no-store">
            <NoStore className="mr-3 dead-grey gl-no-store__image" />
          </div>
        </EmptyStateLayout>
      )}
    </>
  )
}

export default withAnalyticsContainer(Analytics)
