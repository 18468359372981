import React from "react"
import { GridLoader, ClipLoader } from "react-spinners"
import { withCampaignContainer } from "../containers/shared"

const SpinnerLoader = ({ icon: SpinnerLoader, size, client, loading }) => {
  return (
    <SpinnerLoader
      sizeUnit={"px"}
      size={size}
      color={client.spinner_color}
      loading={loading}
    />
  )
}

const SwitchSpinner = props => {
  const type = props.client ? props.client.spinner_type : ""
  switch (type) {
    case "vodafone":
      return <SpinnerLoader icon={ClipLoader} size={60} {...props} />
    case "default":
      return <SpinnerLoader icon={GridLoader} size={40} {...props} />
    default:
      return <div />
  }
}

class Spinner extends React.Component {
  render() {
    return (
      <>
        <div className={this.props.loading ? "spinner-wrapper" : ""}>
          <div className="Spinner ">
            <SwitchSpinner {...this.props} />
          </div>
        </div>
      </>
    )
  }

  static defaultProps = {
    color: "#04369B"
  }
}

export default withCampaignContainer(Spinner)
