import React from "react"
import NavigationSubNavbarLink from "../shared/NavigationSubNavbarLink"
import CustomLink from "../shared/CustomLink"

const BonusPointsNavigation = () => {
  return (
    <>
      <div className='flex justify-end'>
        <CustomLink primary to="/bonus_points/add">Atribuir Pontos</CustomLink>
      </div>
      <nav className="flex gl-bonus__title navbar-text mb-8">
        <NavigationSubNavbarLink exact to="/bonus_points">
          Extrato 
        </NavigationSubNavbarLink>
        <NavigationSubNavbarLink exact to="/bonus_points/history">Pontos atribuidos</NavigationSubNavbarLink>
      </nav>
     
    </>
  )
}

const BonusPointsLayout = props => {
  return (
  
    <div className="my-10 flex flex-col">
      <BonusPointsNavigation />
      {props.children}
    </div>
  )
}

export default BonusPointsLayout
