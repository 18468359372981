import React from "react"
import Slider from "./Slider"
import PostProduct from "./PostProduct";


const uuid = require('uuid/v1')

const ProductSlider = ({ products, backgroundColor, textColor, borderColor, key }) => {
  const colors = { backgroundColor, textColor, borderColor}
  if (products.length === 0) return null

  return (
    <Slider arrowColor={backgroundColor} sliderId={uuid()} direction={'center'}>
      {products.map(product => {
        return (
          <PostProduct {...product} colors = {colors} key={product.id}/>
        )
      })}
    </Slider>
  )
}

export default ProductSlider
