import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveCampaigns } from '../actions/campaignActions';
import { saveUser } from '../actions/userActions';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router';

class TermsRoute extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  render() {
    const {component: Component, ...rest} = this.props
    const { confirm_terms } = this.props.user
    return(
      <Route {...rest} render={(props) => {
        return localStorage.getItem('jwt') ? 
          confirm_terms ? <Redirect to='/' /> : <Component {...props}/>
         : <Redirect to={'/'+ localStorage.getItem('prevClient') + '/login'} />
      }} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCampaign: (campaign) => {
      dispatch(saveCampaigns(campaign));
    }
  }

}

export default connect(mapStateToProps,mapDispatchToProps)(TermsRoute);