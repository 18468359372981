import React from "react"
import ProductCatalogList from "../shared/ProductCatalog"
import CatalogProductItem from "./Product"
import { Switch } from "react-router-dom"
import Route from "../../../routes/CustomRoute"
import {
  withContainers,
  ClientContainer
} from "../../containers/gamification"
import {CatalogContainer} from "../../containers/shared"
import CatalogHeader from "./CatalogHeader"

function Catalog(props) {
  const {
    products,
    pagination,
    onPageChange,
    onProductTextSearch,
    currentPage,
    campaign,
    optionActions,
    defaultValues,
    optionState,
    onBrandChange,
    brands,
    selectedBrand,
    generateFilterState,
    generateFilterActions,
    filtering,
    onFilterShow,
    onFilterHide,
    banners,
    orderOptions,
    selectedOrder,
    onOrderChange
  } = props

  const { currency_label: currencyLabel } = props.client
  const banner = banners.length > 0 ? banners[0] : undefined

  return (
    <>
      <Switch>
        <Route
          exact
          path="/catalog/product/:id"
          currencyLabel={currencyLabel}
          component={CatalogProductItem}
          products={products}
          campaign={campaign}
        />
        <Route
          exact
          path="/catalog"
          component={ProductCatalogList}
          currencyLabel={currencyLabel}
          pagination={pagination}
          onPageChange={onPageChange}
          products={products}
          currentPage={currentPage}
          header={
            <CatalogHeader
              filtering={filtering}
              campaign={campaign}
              onFilterHide={onFilterHide}
              onFilterShow={onFilterShow}
              onProductTextSearch={onProductTextSearch}
              optionState={optionState}
              optionActions={optionActions}
              defaultValues={defaultValues}
              onBrandChange={onBrandChange}
              brands={brands}
              selectedBrand={selectedBrand}
              generateFilterState={generateFilterState}
              generateFilterActions={generateFilterActions}
              orderOptions={orderOptions}
              selectedOrder={selectedOrder}
              onOrderChange={onOrderChange}
            />
          }
        />
      </Switch>
    </>
  )
}

export default withContainers(Catalog, [CatalogContainer, ClientContainer])
