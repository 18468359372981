import React, { Component } from "react"
import Banner from "./Banner"
import Navbar from "./Navbar"
import { withContainers, BannerContainer, ResizeContainer} from "../../normalizer"
import User from "./User"
import { matchPath } from "react-router"

class Header extends Component { 

  state = {
    mobileFriendly: false
  } 
  resize = () => {
    this.setState({mobileFriendly: window.innerWidth < 576})
  }
  
  componentDidMount(){
    window.addEventListener("resize",this.resize)
    this.resize();
  }
  componentWillUnmount(){
    window.removeEventListener("resize",this.resize)
  }
  render() {
    const mobileFriendly = this.state.mobileFriendly 
    const { banner, campaign, toRender, imageSize, features} = this.props
    const { pathname } = this.props.location
    const bannerProps = banner
      ? {
          gradient: banner.gradient_type,
          title: banner.title,
          link: banner.url,
          url: banner[imageSize] ? banner[imageSize].url : "",
          displayBanner: true
        }
      : {}

    const paths = ["profile", "cart", "analytics"]
    const find = paths.find(p => matchPath(pathname, { path: `/${p}` }))

    return (
      <header className="default-bg g-header">
        <Navbar logo={campaign.logo} gradient={campaign.gradient_type} features={features} />
        {!find && (
          <>
            <User mobileView={mobileFriendly} toRender={toRender}/>
            <Banner {...bannerProps} mobileView={mobileFriendly} />
          </>
        )}
      </header>
    )
  }
}

export default withContainers(Header, [BannerContainer, ResizeContainer])
