import Momment from 'moment';
import React from 'react';
import { t } from '../../../i18n';
import Modal from '../../shared/Modal';
import { formatNumber } from '../normalizer';
import RectLoader from '../shared/RectLoader';

const ObjectiveSection = ({title, h3Classes, h3Override, children}) => {

  const h3Class = ['dead-grey mb-5', h3Classes].join(' ')

  return (
    <div className="mb-5">
      {h3Override || <h3 className={h3Class}>{title}</h3> }
      <div className="mb-5">
        {children}
      </div>
      <hr className="g-objectives__line mt-5 mb-0"/>
    </div>
  )
}

const ObjectiveH3 = ({title, writtenProgress}) => (
  <div className="flex justify-between dead-grey mb-3">
    <h3>{title}</h3>
    <p>{writtenProgress}</p>
  </div>
)

const DisplayReward = ({type,reward,currencyLabel}) => {
  let normalizedType = type
  switch(type){
    case 'points':
      normalizedType = currencyLabel
      break;
    default:
      normalizedType = type
      break;
  }

  return (
  <div>
    {formatNumber(reward)} {normalizedType}
  </div>
  )}

const ObjectiveSectionContainer = ({rules, currencyLabel}) => (
  rules.map(({id, name, target, percentage, progress, award, quantity}) => {
    return(
      <ObjectiveSection key={id} title={name} >
        <div className='flex justify-between'>
          <DisplayReward type={award} reward={quantity} currencyLabel={currencyLabel}/>
          { Number(percentage) >= 100 &&
           <i className='fa fa-check'></i>
          }
        </div>
      </ObjectiveSection>
    )
  })
)

const objectiveTotalProgress = ({rules}) => {
  if (rules.length === 0){
    return '0%'
  }

  let firstRule = rules[0]
  let progressList = rules.map(item => item.progress)
  return progressList.reduce((a,b) =>  a+b, 0) / firstRule.target * 100 
}

const ObjectiveModal = ({selected, onUnselectObject, currencyLabel}) => {

  const objectiveTotal = objectiveTotalProgress(selected)
  const objectiveTotalLoader = objectiveTotal > 100 ? 100 : objectiveTotal
  const customH3 = <ObjectiveH3 title={'Atingimento (%)'} writtenProgress={`${objectiveTotal} %`}/>
  return (
  <Modal show={selected} untoggle={onUnselectObject}>
    <div className="g-objectives__modal p-3">
      <div className="flex justify-between items-center navbar-text mb-5">
        <h2>{selected.name}</h2>
        <button className="g-objectives__back outline-reset" onClick={onUnselectObject}>
          <i className="fa fa-times" ></i>
        </button>
      </div>

      <ObjectiveSection title={'Descrição'}>
        <div className="custom-inner-html g-objectives__description vodafone">
          <p className='vodafone' dangerouslySetInnerHTML={{ __html: selected.description }}></p>
          {!selected.description && <p>Sem descrição</p>}
        </div>
      </ObjectiveSection>

      <ObjectiveSection title={customH3} >
        <div className="g-objectives__progress">
          <RectLoader progress={Number(objectiveTotalLoader)}/>
        </div>
      </ObjectiveSection>
      <div>
      <ObjectiveSectionContainer rules={selected.rules}/>      
      </div>
      <div className="g-objectives__description">
        Termina a
        <span className="ml-1">
          {new Momment(selected.end_date).format(t('date-format'))}
        </span>
      </div>
    </div>
  </Modal> 
  )
}

export default ObjectiveModal
