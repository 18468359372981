import React, { Component } from 'react';
import { Link, NavLink, withRouter } from "react-router-dom";
import styled from 'styled-components';
import { connect } from 'react-redux';
import { saveSupportingDocuments } from '../../../actions/supportingDocumentsActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import { SupportingDocumentsService } from '../../../services'
class SupportingDocumentsNavbar extends Component{

  constructor(props){
    super(props);
    this.state={active: this.props.active,
      supportingDocuments: []};
  }

  render(){
    const activeStyle = {backgroundColor: this.props.campaign.color_primary }
    const {
      supportingDocuments
    } = this.props;
    const numberOfSupportingDocuments = supportingDocuments.length<=1?2:supportingDocuments.length;
    return(
      <div>
        <div className='flex user-tabs space-around'>
          {supportingDocuments.map((item,i) =>
            <NavLink 
              key={i}
              to={'/supporting-documents/'+item.id}
              className={"btn-secondary w-1/"+numberOfSupportingDocuments+" supporting-documents-button-tabs"}
              activeClassName={"w-1/"+numberOfSupportingDocuments+" supporting-documents-active-button"}
              activeStyle={activeStyle}
            >
              <div key={i} className='supporting-documents-button-tabs-text uppercase'>{item.name}</div>
            </NavLink>
            )}
        </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    supportingDocuments: state.supportingDocumentsReducer.supportingDocuments
  }
}

function mapDispatchToProps(dispatch){
    return{
      startFetch: () => { dispatch(startFetch())},
      endFetch: () => { dispatch(endFetch())},
    }
  }
  

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportingDocumentsNavbar));
  