import React from 'react'
import EditNavbar from '../EditNavbar';
import KeyDropdownValue from '../KeyDropdownValue';
import InputKeyValue from '../InputKeyValue';

const NewAddressForm = (props) => {

  const {
    country,
    availableCountries,
    state,
    availableStates,
    className,
    cancelMargin,
    errors={},
    ...actions
  } = props

  const handleOnSubmit = (event) => {
    event.preventDefault()

    const {
      address1,city,
      address2, zipCode,
      addressName, firstName,
      lastName, email,
      phone
    } = event.target

    actions.onNewAddress({
      address1:  address1.value,
      city:  city.value,
      address2:  address2.value,
      zipCode:  zipCode.value,
      addressName: addressName.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
    })
  }

  const containerClass= [' flex flex-col', 'mb-20' && !cancelMargin, className].join(' ')
  return (
    <form className={containerClass} onSubmit={handleOnSubmit}>
      <div className="g-profile__addresses-container">
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-8 col-sm-8 col-xs-12 remove-padding">
            <InputKeyValue colSpan={2}
              title={"Nome da morada"}
              placeholder="nome da morada e.g - Casa..."
              name="addressName"
              error={errors.address_name}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title={"Email"}
              placeholder="email..."
              name="email"
              error={errors.email}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Nome"
              name="firstName"
              placeholder="nome..."
              error={errors.firstName}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Apelido"
              name="lastName"
              placeholder="apelido..."
              error={errors.last_name}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Telemóvel"
              name="phone"
              placeholder="telemóvel..."
              error={errors.phone}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Endereço"
              name="address1"
              placeholder="endereço 1..."
              error={errors.address1}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Cidade"
              name="city"
              placeholder="cidade..."
              error={errors.city}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Código postal"
              name="zipCode"
              placeholder="código postal..."
              error={errors.zip_code}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Endereço 2"
              name="address2"
              placeholder="endereço2..."
              error={errors.zip_code}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyDropdownValue
              title="País"
              placeholder="país..."
              value={country}
              options={availableCountries}
              onChange={actions.onCountryChange}
              getOptionLabel={(option) => option.name}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyDropdownValue
              title="Região"
              value={state}
              options={availableStates}
              onChange={actions.onStateChange}
              getOptionLabel={(option) => option.name}
              placeholder="região..."
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
      </div>
      <EditNavbar onEditCancel={actions.onAddressCreationCancel}/>
  </form>
  )
}
export default NewAddressForm
