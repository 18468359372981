import React from 'react'
import { NavLink as Link } from 'react-router-dom'

const NavigationLink = ({className, ...rest}) => {
  const currentClassName = [className, 'navbar-campaign-link flex gl-navbar__link'].join(' ')
  return (
    <Link {...rest}
      activeClassName ="navbar-active-link"
      className={currentClassName}
    />
  )
}

const NavbarButton = ({className, ...rest}) => {
  const currentClassName = [className, 'navbar-campaign-link flex gl-navbar__link'].join(' ')
  return (
    <button {...rest}
      className={currentClassName}
    />
  )
}

export default NavigationLink

export { NavbarButton }