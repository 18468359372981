import React, { Component } from 'react';
import Avatar from '../../../assets/img/user-placeholder.svg'

const parseTotalPoints = (totalPoints) => {
  const parsedTotalPoints = parseFloat(totalPoints)
  const normalizedTotalPoints = parsedTotalPoints % 1 === 0 ? parsedTotalPoints : parsedTotalPoints.toFixed(2)
  return normalizedTotalPoints
}

class RankingRow extends Component {

  state = {
    mobileFriendly: false,
    normalizedIdentifier: this.props.result.full_name
  }

  resize = () => {
    const mobileFriendly = window.innerWidth < 700
    let fullName = this.props.result.full_name
    if (mobileFriendly){
      const splittedFullName = fullName.split(' ') 
      fullName = splittedFullName[0] + ' ' + splittedFullName[splittedFullName.length - 1]
    }
    this.setState({normalizedIdentifier: fullName})
  }

  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  
  render (){
    const { result, isCurrentUser, isWinner } = this.props
   
  const normalizeAvatar = result.avatar.url || Avatar
  const normalizedIdentifier = this.state.normalizedIdentifier
  const normalizedGroups = result.groups
  const normalizedTotalPoints = parseTotalPoints(result.total_points)
  const tableClass= [
    'g-rankings__data text-sm', 
    'navbar-text',
    isCurrentUser && 'eee-bg'  
  ].join(' ')

  return(
    <tr key={result.id} className={tableClass}>
      <td className='text-left p-2 sm:p-3  '>{result.position}</td>
      <td className='align-baseline p-2 sm:p-3 text-left content-center whitespace-no-wrap'>
        <img src={normalizeAvatar} alt="avatar" className="mr-2 g-rankings__avatar align-middle"/> 
        <span>
          {normalizedIdentifier}
          {isWinner && <i className="px-2 mr-3 fas fa-award"></i> }
        </span>
      </td>
      <td className='align-baseline p-2 sm:p-3 text-left whitespace-no-wrap content-center '>
        {normalizedGroups}
      </td>
      <td className='p-2 sm:p-3 text-right'>
        {normalizedTotalPoints}
      </td>
    </tr>
  )}
}

export default RankingRow