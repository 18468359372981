import React from 'react'
import CatalogCheckbox from './CatalogCheckbox';

const FilterElement = ({isSelected, label, handleClick, addVerticalLine}) => {
  const checkboxComp = <CatalogCheckbox
                    isSelected={isSelected}
                    label={label}
                    handleClick={handleClick}
                  />;
  if (addVerticalLine){
    return (
      <>
        <div className="catalog__vertical-line"/>
        {checkboxComp}
      </>
    );
  } else {
    return checkboxComp;
  }
};

const CatalogFilters = ({filterState, filterActions }) => {
  const {
    rangeFilters
  } = filterState;
  const {
    handleRangeClick
  } = filterActions;

  return (
    <>
      <p className='mt-2 pt-3 pb-3 pr-3'>Por pontos</p>
      <div className="flex catalog__checkbox">
        {
          rangeFilters.map((rangeFilter, idx) => {
            return <FilterElement
              key={rangeFilter.rangeFilter.id}
              isSelected={rangeFilter.active}
              label={rangeFilter.label}
              handleClick={e => handleRangeClick(rangeFilter.rangeFilter.id, e)}
              addVerticalLine={idx !== 0}
            />;
          })
        }
      </div>
    </>
  )
}

export default CatalogFilters
