import React, { Component } from 'react'
import { prefix } from '../../../i18n';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { CartService } from '../../../services';
import {ToastStore, ToastContainer} from 'react-toasts';
import {saveCart} from '../../../actions/cartActions';
import {buildTransaction} from '../../../actions/generalActions';
import { saveCampaignUser, saveOrderPayment } from '../../../actions/balanceActions';

const tCartCheckout = prefix("cart-checkout")

class ShoppingCartContainer extends Component {
  payload = (item) => ({
    client_slug: this.props.user.client.slug,
    campaign_slug: this.props.campaign.slug,
    product_id: item.id
  })

  handleReturnToCatalog = () => {
    this.props.history.push('/catalog')
  }

  handleReturnToCart = () => {
    this.props.history.push('/cart')
  }

  handleReturnToAdress = () => {
    this.props.history.push('/cart/address')
  }

  handleIncreaseItem = (item) => {
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: this.props.user.client.slug,
      slug: this.props.campaign.slug,
      item_id: item.id
      }

    this.props.startTransaction(() =>
      CartService.increaseItem(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        ToastStore.success('Foi adicionado uma unidade do produto')
      })
      .catch((error) => {
        const message = error.response.data.message || "Impossivel de alterar produto"
        ToastStore.error(message)
      })
    );

  }

  handleDecreaseItem = (item) => {
    const payload = {
      client_slug: this.props.user.client.slug,
      slug: this.props.campaign.slug,
      item_id: item.id
      }

    this.props.startTransaction(() =>
      CartService.decreaseItem(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        ToastStore.success('Foi removida uma unidade do produto')
      })
      .catch((error) => {
        const message = error.response.data.message || "Impossivel de alterar produto"
        ToastStore.error(message)
      })
    )
  }

  handleRemoveItem = (item) => {
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: this.props.user.client.slug,
      slug: this.props.campaign.slug,
      item_id: item.id
    }
    this.props.startTransaction(() =>
    CartService.removeItem(payload)
    .then((response) => {
      this.props.saveCart(response.data)
      ToastStore.success('Produto foi removido')
    })
    .catch((error) => {
      ToastStore.error('Impossivel de remover produto')
    }))
  }

  handleCheckCartItems = () => {
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug
    }

    this.props.startTransaction(() =>
    CartService.checkCartItems(payload)
      .then((response) => {
        this.props.history.push('/cart/address')
      })
      .catch((error) => {
        ToastStore.error('Carrinho vazio')
      }))
  }

  handleCheckout = ({ 
    shouldUseCoPayment = false,
    acceptCoPaymentConditions = false,
    selectedPaymentMethod = null }) => {
    
    const {
      user,
      campaign,
      cart: { allows_co_payments }
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug,
      co_payment: allows_co_payments && shouldUseCoPayment ? {
        use_co_payment: shouldUseCoPayment,
        accepted_payment_terms: acceptCoPaymentConditions,
        payment_method_id: selectedPaymentMethod.id
      } : null
    }

    this.props.startTransaction(() =>
      CartService.CartCheckout(payload)
        .then((response) => {
          ToastStore.success(tCartCheckout("checkout-successfully"));
          this.props.saveCampaignUser(response.data);
          this.props.history.push('/cart/success')
        })
        .catch((error) => {
          ToastStore.error(error.response.data.error)
      })
    );
  }

  handleUpdateAddress = (option) => {
    
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug, 
      address_id : option.id
    }
    
    this.props.startTransaction(() => 
      CartService.updateShipmentAddress(payload)
        .then((response) => {
          this.props.saveCart(response.data)
          ToastStore.success('Endereço alterado')
        })
        .catch((error) => {
          this.props.saveCart({
            cart: {
              ...this.props.cart,
              shipment_address: undefined
            }
          })
          ToastStore.error('Impossivel de associar endereço')
        })
  )}

  handleCheckCartAddress = () =>{

    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug
    }

    this.props.startTransaction(() => 
    CartService.checkCartAddress(payload)
      .then((response) => {
        this.props.history.push("/cart/checkout")
      })
      .catch((error) => {
        ToastStore.error('Endereço  associado')
    }));
  }


  fetchCart = () => {
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug
    }

    CartService.getCart(payload)
    .then((response) => {
      this.props.saveCart(response.data)
      
    })
  }

  userCheckoutRestrictions = ( user_points, cart_total_points, maximum_percentage ) => {
    const userHasNoPoints = user_points == 0 
    const debtPoints = user_points < cart_total_points ? cart_total_points - user_points : 0;
    const restingPoints = cart_total_points * (maximum_percentage / 100) - debtPoints;

    const overcomeMaxPercentage = restingPoints < 0;
    const hasEnoughPoints = debtPoints <= 0;

    return {
      userHasNoPoints,
      hasEnoughPoints,
      overcomeMaxPercentage,
      restingPoints,
      debtPoints,
    };
  };

  render() {
    const {
      component: Component,
      ...rest
    } = this.props;

    return (
      <>
      <ToastContainer store ={ToastStore} />
      <Component {...rest}
        fetchCart={this.fetchCart}
        onCheckCartItem={this.handleCheckCartItems}
        onIncreaseItem={this.handleIncreaseItem}
        onDecreaseItem={this.handleDecreaseItem}
        onRemoveItem={this.handleRemoveItem}
        onReturnToCatalog={this.handleReturnToCatalog}
        onReturnToCart={this.handleReturnToCart}
        onReturnToAddress={this.handleReturnToAdress}
        onCheckout={this.handleCheckout}
        onUpdateAddress={this.handleUpdateAddress}
        onCheckAddresses={this.handleCheckCartAddress}
        getUserCheckoutRestrictions={this.userCheckoutRestrictions}
      />
      </>
     );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cartReducer.cart,
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  campaignUser: state.balanceReducer.campaignUser,
});

const mapDispatchToProps = (dispatch) => ({
  saveCart: (cart) => {
    dispatch(saveCart(cart))
  },
  saveCampaignUser: (user) => {
    dispatch(saveCampaignUser(user))
  },
  saveOrderPayment: (orderPayment) => {
    dispatch(saveOrderPayment(orderPayment))
  },
  startTransaction: buildTransaction (dispatch)
})

const Container = withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCartContainer))

export function withShoppingCartContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}


export default Container;
