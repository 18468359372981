import React from 'react';
import {Switch} from 'react-router-dom'
import {default as Route} from '../../../routes/CustomRoute'
import RankingTable from './RankingTable'
import BalanceTable from './Balance/BalanceTable'
import {withContainers, RankingsContainer, ClientContainer} from '../../GamificationLevelTemplate/normalizer'
import RankingsSelector from './RankingsSelector'
import RankingNavigation from './RankingNavigation'

const getRanking = ({rankings, match}) => {
  return rankings.find(r => r.id.toString() ===  match.params.id)
}

const getRankingAdditonalInfo = (type, currencyLabel) => {
  let info = {};
  switch (type) {
    case "sales": {
      info.currencyLabel=  "Vendas"
      info.winnerDescription = "vendas"
      break
    }
    case "objectives": {
      info.currencyLabel=  "Atingimento (%)"
      info.winnerDescription = "pp"
      break
    }
    default: {
      info.currencyLabel= `${currencyLabel} (#)`
      info.winnerDescription = currencyLabel
    }
  }
  return info
}

const RankingsLayout = (props) => {

  const {
    campaignUser,
    points,
    user,
    userPosition,
    winners,
    rankings,
    userNeighbours,
    firstRanking,
    currPage,
    totalPages,
    onPageChange
  } = props

  const{
    currency_label : currencyLabel
  } = props.client

  
  const ranking = getRanking(props) || {}
  const pointsLabel = getRankingAdditonalInfo(ranking.ranking_type,currencyLabel)
  return (
    <>
      <RankingNavigation ranking={ranking} firstRanking={firstRanking} />
      <div className="mb-20 lg:flex">
          <RankingsSelector rankings={rankings} />
          <div className='navbar-content-info w-2/3'>
            <Switch>
              <Route
                path={`/rankings/${ranking.id}`}
                exact
                rankingLabel={pointsLabel}
                component={RankingTable}
                user={user}
                campaignUser={campaignUser}
                winners={winners}
                userPosition={userPosition}
                userNeighbours={userNeighbours}
                endDate={ranking.end_date}
                isWinner={ranking.is_winner}
                winningMessage={ranking.winning_message}
                slug={props.client.slug}
              />
              <Route
                path={`/rankings/${ranking.id}/balance`}
                exact
                component={BalanceTable}
                rankingLabel={pointsLabel}
                points={points}
                page={currPage}
                totalPages={totalPages}
                onPageChange={onPageChange}

              />
            </Switch>
          </div>
      </div>
    </>
  )
}

export default withContainers(RankingsLayout, [RankingsContainer,ClientContainer])
