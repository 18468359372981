import React, { Component } from 'react';
import styled from 'styled-components';


const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  display: block;
  width: 18px;
  height: 18px;
  background: ${props => props.checked ? props.background_color : 'white'};
  border-radius: 3px;
  border: 1px solid;
  border-color: ${props => props.checked ?  'white' : '#706F6F'};
  transition: all 150ms;
  cursor: pointer;

  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`

const CustomCheckbox = ({ className, checked, campaign, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} background_color = {campaign.color_secondary}>
      <Icon viewBox="0 0 24 24">
         <polyline points="20 6 9 17 4 12" />
       </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default CustomCheckbox;
   