import React, { Component } from "react"
import { connect } from "react-redux"
import { UserService } from "../../../services"
import { ToastContainer, ToastStore } from "react-toasts"
import {
  startFetch,
  endFetch,
  resetFetch
} from "../../../actions/generalActions"
import incentivehouse from "../../../assets/img/incentivelogo.png"
import ClientLogo from "../client/Logo"
import BackgroundWrapper from "../client/BackgroundWrapper"
import PasswordRecoveryGamification from "../../GamificationTemplate/PasswordRecovery/index"
import PasswordRecoveryDefault from "../../DefaultTemplate/PasswordRecovery/index"

const RecoveryPasswordTemplate = props => {
  const { slug } = props
  switch (slug) {
    case "vodafone":
      return <PasswordRecoveryGamification {...props} />
    default:
      return <PasswordRecoveryDefault {...props} />
  }
}

class LogInWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: { url: "" },
      background_banner: { url: "" },
      name: "",
      slug: "",
      notfound: false
    }
  }

  recoverPassword = e => {
    e.preventDefault()
    const { value } = e.target.emailOrLogin
    const currPath = this.props.match.url
    const payload = {
      clientSlug: this.props.client.slug,
      emailOrLogin: value
    }
    this.props.startFetch()

    UserService.requestPasswordRecovery(payload)
      .then(response => {
        this.props.endFetch()
        this.props.history.push(`${currPath}/success`, {
          client: this.state.slug,
          logo: this.state.client ? this.state.client.logo.url : incentivehouse,
          banner: this.state.background_banner.url
            ? {
                backgroundImage: "url(" + this.state.background_banner.url + ")"
              }
            : {},
          user: value
        })
      })
      .catch(error => {
        ToastStore.error("Insira um email ou um login válido.")
        this.props.endFetch()
      })
  }

  componentWillUnmount() {
    this.props.resetFetch()
  }

  render() {
    return (
      <BackgroundWrapper
        backgroundImage={this.props.client.background_banner.url}
      >
        <ToastContainer
          store={ToastStore}
          position={ToastContainer.POSITION.TOP_CENTER}
          lightBackground
        />
        <RecoveryPasswordTemplate
          background_banner={this.state.background_banner}
          client={this.props.client}
          recoverPassword={this.recoverPassword}
          slug={this.props.client.slug}
          client_logo={ClientLogo}
        />
      </BackgroundWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    client: state.userReducer.client
  }
}
function mapDispatchToProps(dispatch) {
  return {
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    },
    resetFetch: () => {
      dispatch(resetFetch())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInWrapper)
