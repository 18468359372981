import React, { Component } from 'react';
import StyledImage from '../../shared/StyledImage'
class AcceleratorShow extends Component{
  render(){
    const {
      accelerator,
      campaign,
      imageSize
    } = this.props
    return(
      <div>
        <div className='row rounded content-container my-8' >
          <StyledImage 
            url={accelerator.preview_image[imageSize] ? accelerator.preview_image[imageSize].url : ''}
            className='col-lg-4 col-md-4 col-sm-12 col-xs-12 p-0 rounded-t md:rounded-t 
             lg:rounded-none lg:rounded-l xl:rounded-l sm:rounded-t content-images'>
          </StyledImage>
          <div className='col-lg-8 col-md-8 col-sm-12 col-xs-12 px-8 py-3'>
            <div className="flex between-lg between-md between-xs py-5">
              <div>
                <h2 style={{color: campaign.color_primary}} className="primary">{accelerator.name}</h2>
                <hr style={{ backgroundColor: campaign.text_color }} className="hr-down-left"></hr>
              </div>
            </div>
            <div className='flex between-xs'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 px-0'>
                <p className='content-text inline-block' dangerouslySetInnerHTML={{ __html: accelerator.description ? accelerator.description : 'Sem descrição' }}></p>
              </div> 
            </div>
          </div>
        </div>
      </div>
    )
  }

}
export default AcceleratorShow;