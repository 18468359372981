import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import RectLoader from "../shared/RectLoader";
import CustomButton from "../shared/CustomButton";
import { t } from "../../../i18n";

class ObjectiveCard extends Component {
  render() {
    const { name, percentage, expected_percentage, end_date, onClick, isFirst, clientSlug } = this.props;
    
    return (
      <div className={[isFirst ? "col-xs-12" : "col-md-6 col-xs-12"].join(" ")}>
        <div className="gl-objectives__card m-4 gl-content-shadow text-color">
          <div className="flex justify-between pb-3">
            <h3 className="gl-post__title">{name}</h3>
            <h3 className="primary-text font-medium">
              Checkpoint <span>{expected_percentage}%</span>
            </h3>
          </div>
          <div className="gl-objectives__progress">
            <RectLoader
              progress={Number(percentage)}
              expected_progress={Number(expected_percentage)}
            />
          </div>
          <div className="gl-objectives__label flex justify-between items-center pt-4">
            <span>
              Termina a
              <span className="ml-1">
                {new Moment(end_date).format(t("date-format"))}
              </span>
            </span>
            {clientSlug !== "vodafone" && (
              <CustomButton primary medium onClick={onClick}>
                Saiba mais
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ObjectiveCard);