import React from "react"
import Slider from "./Slider"
import PostProduct from "./PostProduct";


const uuid = require('uuid/v1')

const ProductSlider = ({ products, key }) => {
  if (products.length === 0) return null

  return (
    <Slider sliderId={uuid()}>
      {products.map(product => {
        return (
          <PostProduct {...product} key={product.id}/>
        )
      })}
    </Slider>
  )
}

export default ProductSlider
