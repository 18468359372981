export const saveStores = (stores) => ({
  type: 'SAVE_USER_STORES',
  payload : {
    stores
  }
})

export const saveGroupSales = (sales, pagination) => ({
  type: 'SAVE_GROUP_SALES',
  payload: {
    sales,
    salesPagination: pagination
  }
})

export const saveGroupObjectives = (objectives, pagination) => ({
  type: 'SAVE_GROUP_OBJECTIVES',
  payload: {
    objectives,
    objectivesPagination: pagination
  }
})

export const eraseStoreData = () => ({
  type: 'ERASE_STORE_DATA',
  payload: {
    objectives: [],
    objectivesPagination: {},
    sales: [],
    salesPagination: {}
  }
})
