import React, { Component } from 'react';
import Moment from 'moment';
import {Link} from 'react-router-dom'
import {t} from '../../i18n'
import StyledImage from './StyledImage'
class NewsShow extends Component{

  componentDidMount() {
    const {annoucement} = this.props
    const element = document.getElementById(`annoucement-${annoucement.id}`)

    let resize = -20
    if(this.props.gamification ) {
      const {offsetHeight} = document.getElementById("g-navbar")
      resize = -offsetHeight -10 
    }

    window.scrollTo(0, element.offsetTop + resize)
  }

  renderDefault(campaign, annoucement, imageSize) {
    return (
      <div>
        <div className='rounded content-container my-8' id={`annoucement-${annoucement.id}`} >

          <StyledImage url = {annoucement.image[imageSize] ? annoucement.image[imageSize].url : ''}
            className='relative col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 content-images-full rounded-t'>
            <Link to="/news">
              <i className='fa fa-times news-remove'></i>
            </Link>
          </StyledImage>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 px-5'>
            <p className='py-8 news-date-full'>{Moment(annoucement.created_at).format(t('date-format'))}</p>
            <div className='py-4'>
              <h2 style={{ fontSize: '34px', color: campaign.color_primary }} className="primary">{annoucement.title}</h2>
              <hr style={{ backgroundColor: campaign.text_color, width: '11%' }} className="hr-down-left"></hr>
            </div>
            <div className='py-10'>
              <p className='content-text inline-block' dangerouslySetInnerHTML={{ __html: annoucement.news_body }}></p>
            </div>

          </div>
        </div>
      </div>
    )
  }

  renderGamification(annoucement) {
    return (
      <div>
        <div className='rounded content-container my-8' id={`annoucement-${annoucement.id}`} >
          <div style={{ background: 'url(' + annoucement.main_image.url + ') no-repeat center center', backgroundSize: 'cover' }}
            className='relative col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 content-images-full rounded-t'>
            <Link to="/news">
              <i className='far fa-times-circle g-news__remove primary-bg g-content-shadow'></i>
            </Link>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 px-5'>
            <div className='py-4'>
              <h2 className="primary-text vodafone-bold news-title">{annoucement.title}</h2>
            </div>
            <div className='py-10'>
              <p className='content-text roboto inline-block' dangerouslySetInnerHTML={{ __html: annoucement.news_body }}></p>
            </div>
            <p className='py-8 news-date-full roboto text-size'>{Moment(annoucement.created_at).format(t('date-format'))}</p>
          </div>
        </div>
      </div>
    )
  }

  render(){
    const {
      annoucement,
      campaign,
      gamification,
      imageSize
    } = this.props
    return(
      <>
        {gamification && this.renderGamification(annoucement)}
        {!gamification && this.renderDefault( campaign, annoucement, imageSize)}
      </>
    )
  }

}
export default NewsShow;
