import React from 'react'
import { NavLink as ReactLink } from 'react-router-dom'

const Link = ({ className, fromDropdown, ...rest }) => {

  const classes = [
    className,
    'nav-item active-link',
    fromDropdown && 'fromDropdown'
  ].join(' ')

  return (
    <ReactLink
      {...rest}
      className={classes}
    />
  )
}

export default Link
