
import React from 'react'
import styled, {css} from 'styled-components'
const Title = styled.h1`
  ${props => props.sub && css`
    font-size: 1.75em;
  `}
`
export default function ContentTitle({title, className, sub}) {
  const pb = sub? 'pb-2' : 'pb-6'
  return (
    <div>
      <div className="col-lg col-md col-xs">
        <Title
          sub={sub}
          className={className+" font-quicksand primary-text"}
        >
          {title}
        </Title>
      </div>
      <div className={`cont row start-lg start-md start-xs ${pb}`}>
        <div className="col-lg col-md col-xs">
          <hr className="hr-down-catalog"></hr>
        </div>
      </div>
    </div>
  )
}
