import axios from './interceptor'
import { isEmpty } from '../utils/utils';

export const InvoiceService = {

  getCreatedInvoices({clientId, campaignId, filters, page = 1}){
    
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_created`
    return axios ({
      method: 'get',
      url,
      params: {
        page,
        ...buildFilters(filters)
      }
    })
  },
  getToApproveInvoices({clientId, campaignId, filters, page = 1}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_to_approved`
    return axios ({
      method: 'get',
      url,
      params: {
        page: page,
        ...buildFilters(filters)
      }
    })
  },
  getRegistredInvoices({clientId, campaignId, filters, page = 1}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_completed`
    return axios ({
      method: 'get',
      url,
      params: {
        page,
        ...buildFilters(filters)
      }
    })
  },

  getWaitingApprovalInvoices({clientId, campaignId, filters, page = 1}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_waiting_approval`
    return axios ({
      method: 'get',
      url,
      params: {
        page,
        ...buildFilters(filters)
      }
    })
  },
  
  getVerifiedInvoices({clientId, campaignId, page = 1, filters}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_verified`
    return axios ({
      method: 'get',
      url,
      params: {
        page,
        ...buildFilters(filters)
      }
    })
  },

  getSaleTypes({clientId, campaignId}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/get_sale_types`
    return axios({
      method: 'get',
      url
    })
  },

  approveInvoice({clientId, campaignId, id, ...rest}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}/approve_invoice`

    return axios({
      method: 'put',
      url
    })
  },
  refuseInvoice({clientId, campaignId, id, ...rest}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}/refuse_invoice`
    const payload = new FormData()
    payload.append('reprove_reason_id', rest.selectedReason.id)

    return axios({
      method: 'put',
      url,
      data: payload
    })
  },
  getInvoiceDetail({clientId, campaignId, id}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}`
    return axios({
      method: 'get',
      url: url,
    })
  },

  getInvoiceByNumber({clientId, campaignId, invoiceNumber}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/index_by_number?invoice_number=${invoiceNumber}`
    return axios({
      method: 'get',
      url: url,
    })
  },

  getReprovalReasons({clientId}){
    const url = `/api/clients/${clientId}/invoice_reprove_reasons`
    return axios({
      method: 'get',
      url
    })
  },
  addInvoiceHeader({clientId, campaignId, ...rest}) {
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/`
    const payload = new FormData()
    payload.append('invoice[invoice_number]', rest.number)
    payload.append('invoice[invoice_date]', rest.invoiceDate)
    payload.append('invoice[attachment]', rest.document, rest.name)
    payload.append('invoice[sale_type]', rest.saleType ? rest.saleType.id : '' )

    return axios({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    })
  },
  editInvoiceHeader({clientId, campaignId, invoiceId,...rest}) {
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${invoiceId}`
    const payload = new FormData()
    payload.append('invoice[invoice_number]', rest.number)
    payload.append('invoice[invoice_date]', rest.invoiceDate)
    !isEmpty(rest.document) && payload.append('invoice[attachment]', rest.document, rest.name) 
    payload.append('invoice[sale_type]', rest.saleType ? rest.saleType.id : '' )

    return axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    })
  },
  addInvoiceProduct({clientId, campaignId,id, ...rest}) {
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}/details`
    return axios({
      method: 'post',
      url: url,
      data:  {
        invoice_detail: {
          client_invoices_id: rest.id,
          client_products_id: rest.productId,
          client_category_id: rest.clientCategoryId,
          quantity: rest.quantity
        }
      }
    })
  },
  editInvoiceProduct({clientId, campaignId,invoiceId, productDetailId, ...rest}) {
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${invoiceId}/details/${productDetailId}`
    return axios({
      method: 'put',
      url: url,
      data:  {
        invoice_detail: {
          client_invoices_id: invoiceId,
          client_products_id: rest.productId,
          client_category_id: rest.clientCategoryId,
          quantity: rest.quantity
        }
      }
    })
  },

  sendToValidation({clientId, campaignId, id}){
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}/set_invoice_to_approval`
    return axios({
      method: 'put',
      url: url
    })
  },

  getClientProducts({clientId, parentId}) {
    const url = `/api/clients/${clientId}/products`
    return axios({
      method: 'get',
      url: url,
      params: {
        category_id: parentId
      }
    })
  },

  getClientCategories({clientId, parentId}) {
    const url = `/api/clients/${clientId}/product_categories`
    return axios({
      method: 'get',
      url: url,
      params: {
        product_category: parentId
      }
    })
  },

  removeInvoiceProduct({clientId, campaignId, id, productDetailId}) {
    const url = `/api/clients/${clientId}/campaigns/${campaignId}/invoices/${id}/details/${productDetailId}`
    return axios({
      method: 'delete',
      url: url
    })
  }
}

function buildFilters(filters) {
  const normalizedFilters = filters || {}
  return {
    start_invoice_date: normalizedFilters.initialInvoiceDate,
    end_invoice_date: normalizedFilters.endInvoiceDate,
    start_registration_date: normalizedFilters.initialRegistryDate,
    end_registration_date: normalizedFilters.endRegistryDate,
    invoice_number: normalizedFilters.number,
    store_name: normalizedFilters.storeName,
    user_name: normalizedFilters.username,
  }
}
