import React, { Component } from 'react';

class NewProductLabel extends Component {

  render(){
    return(
      <div className="new -mb-10" style={{outline: 'none'}}>
        <p className="new-label pt-1 pl-1">NOVO</p>
      </div>
    )
  };
}

export default NewProductLabel;
   