import React, { Component } from 'react';
import '../../../assets/css/shared/tooltip.css';

export class ChartTooltip extends Component {

  state = {
    display: ( this.props.visible ? 'block' : 'none' ),
    x: this.props.x,
    y: this.props.y,
    label: this.props.label,
    value: this.props.value,
    color: this.props.color
  }

  changeVisibility = ( visible) => {
    this.setState(
      {
        display: visible ? 'block' : 'none'
      }
    )
  }

  changeTooltip = ( x, y, label, value, color ) => {
    this.setState(
      {
        x: x,
        y: y,
        label: label,
        value: value,
        color: color 
      }
    )
  }

  render() { 
    return ( 
      <div className='tooltip-container' style={{ display: this.state.display, left: this.state.x, top: this.state.y }} >
        <div className='tooltip-marker' style={{ backgroundColor: this.state.color }} /><div className='tooltip-text' >{ this.state.label }: { this.state.value } { this.state.visible }</div>
      </div>
    );
  }
}

export default ChartTooltip;
