
import {Route} from 'react-router-dom'

import React from 'react'

export default function   CustomRoute({component: Component, ...props}) {
  return (
    <Route {...props} render={
      (rest)=> <Component {...props} {...rest} />
    }/>
  )
}