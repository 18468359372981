import axios from './interceptor';

export const ClientService = {

  getClient({slug, domain}) {
    const url ='/api/clients/check_client'
    return axios({
      method: 'get',
      url: url,
      params: {
        slug : slug || 'no-slug',
        domain: domain 
      }
    });
  },
  getCampaigns(payload) {
    const url ='/api/clients/'+ payload.client_slug +'/campaigns'// + payload.slug; remove this slug 
    return axios({
      method: 'get',
      url: url
    });
  },
  getCampaign(payload) {
    const url ='/api/clients/'+ payload.client_slug +'/campaigns/' + payload.slug; //remove this slug 
    return axios({
      method: 'get',
      url: url
    });
  },
  getGroups(payload) {
    const url =`/api/clients/${payload}/groups`
    return axios({
      method: 'get',
      url: url
    });
  },
  getPositions(payload) {
    const url= `/api/clients/${payload}/positions`
    return axios({
      method: 'get',
      url: url
    });
  },
  getBanners(payload) {
    const url= `/api/clients/${payload}/positions`
    return axios({
      method: 'get',
      url: url
    });
  },
  getRegisterFields({clientSlug}){
    const url= `/api/clients/${clientSlug}/register_fields`
    return axios({
      method: 'get',
      url: url
    });
  },
  getTermsOfService({clientSlug}){
    const url= `/api/clients/${clientSlug}/terms_of_services`
    return axios({
      method: 'get',
      url: url
    });
  },
  getCommercialManagers({clientSlug}){
    const url= `/api/clients/${clientSlug}/commercial_managers`
    return axios({
      method: 'get',
      url: url
    });
  }
};