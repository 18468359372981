import React from "react"
import ProductSlider from "./ProductSlider";

const CatalogPost = ({content}) => {
  return (
    <div className="text-color gl-home__catalog ">
      <label className="gl-post__label">{content.label}</label>
      <h3 className="gl-post__title gl-wishes__title">
        {content.title}
      </h3>
      { content.items.length === 0 ? 
        <p>Sem produtos destacados</p>
      :
        <ProductSlider 
          backgroundColor={content.background_color} 
          borderColor={content.border_color}
          textColor={content.text_color}
          products={content.items}
        />
      }
      

    </div>
  )
}

export default CatalogPost
