
export const ChatService = {

  createChatTag(id) {
		const chatScript = document.getElementById(id)
    const head = document.getElementsByTagName('head')[0],
    script = document.createElement('script');

    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=df4facc2-56d0-4121-a5c3-e2e7eb61f445';    
    script.id = id;
    head.appendChild(script);
  }
};