import React, { Component } from "react"
import { connect } from "react-redux"
import "./App.css"
import "./assets/css/tailwind.css"
import LogInWithToken from "./components/shared/LogInWithToken"
import NotFound from "./components/shared/NotFound"
import PrivacyPolicy from "./components/shared/PrivacyPolicy"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Spinner from "./components/shared/Spinner"

import ClientWrapper from "./wrappers/ClientWrapper"

class App extends Component {
  render() {
    return (
      <>
        <Spinner loading={this.props.loading > 0} />
        <div style={this.props.loading > 0 ? { opacity: "0.4" } : {}}>
          <Router>
            <Switch>
              <Route path="/not-found" component={NotFound} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/login/:token" component={LogInWithToken} />
              <Route path="/:slug?" component={ClientWrapper} />
            </Switch>
          </Router>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.generalReducer.loading
  }
}
export default connect(
  mapStateToProps,
  null
)(App)
