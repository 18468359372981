import React, { Component } from "react"
import CatalogPost from "./CatalogPost"
import PromotionPost from "./PromotionPost"
import SalesPost from "./SalesPost"
import AcceleratorPost from "./AcceleratorPost"
import { withResizeContainer } from "../../normalizer";

class PostFeed extends Component {
  
  constructor(props) {
    super(props)
    this.state = { 
      limitText: 400
    }
  }
  selectPost(post) {
    switch (post.content_type) {
      case "News":
        return <PromotionPost imageSize={this.props.imageSize} 
                              key={post.id} 
                              limitText={this.props.limitText} 
                              title={post.content_title} 
                              content={post.content} 
                            />
      case "ProductList":
        return <CatalogPost key={post.id} content={post.content} />
      case "SalesList":
        return <SalesPost key={post.id} content={post.content} />
      case "Accelerator":
        return <AcceleratorPost key={post.id} 
                                title={post.content_title} 
                                content={post.content} 
                                limitText={this.props.limitText} 
                                imageSize={this.props.imageSize} 
                              />
      default:
        return null
    }
  }
  render(){
    const posts = this.props.posts
    return ( 
      <>
      {posts.map(post => this.selectPost(post))}
     </>
    )
  }

}

export default withResizeContainer(PostFeed)
