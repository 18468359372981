import React, { Component } from 'react';
import styled from 'styled-components';
import OrderCheckbox from './OrderCheckbox';
import { OrdersService } from '../../../services'
import { Redirect } from "react-router";
import { saveSelectedOrder } from '../../../actions/ordersActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import { connect } from 'react-redux';

class OrderRow extends Component{
  constructor(props){
    super(props); 
    this.state = {redirect: false}
  }

  getRow(){
    const { color_primary } = this.props.campaign
    return styled.tr`
      :hover{
        p{
          color: ${color_primary}
        }
        .order-link{
          color: ${color_primary};
          text-decoration: underline;
          font-weight: bold;
          cursor: pointer;
        }
      }
    ` 
  }

  getStyle(status){
    const {
      color_primary,
      color_secondary
    } = this.props.campaign
    if (status === 'Enviado'){
      return {backgroundColor: color_primary}
    }
    else if (status === 'Reembolsado'){
      return { backgroundColor: color_secondary }
    }
    else if (status === 'Entregue'){
      return {backgroundColor: color_secondary}
    }
    else {
      return {}
    }
  }

  getInfoButton(order) {
    const { color_primary } = this.props.campaign
    const { status, id: order_id } = order
    if (status === 'Pago') {
      return <button style={{ backgroundColor: color_primary }} className="text-status" onClick={(e) => this.getOrderPaymentDetails(e, order_id)}>Factura</button> 
    } else if (status === 'Aguarda Pagamento'){
      return <button style={{ backgroundColor: color_primary }} className="text-status" onClick={(e) => this.getOrderPaymentDetails(e, order_id)}>Detalhes</button> 
    } else {
      return null 
    }
  }

  getOrderPaymentDetails(event, order_id) {
    this.props.startFetch();
    const { user, campaign } = this.props
    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: order_id
    }

    OrdersService.getSelectedOrder(payload).then((response) => {
      const order = response.data
      this.props.saveSelectedOrder(order);
      this.props.endFetch();
      const { status, order_payment } = order.order
      if (status === 'Pago'){ window.open(order_payment.invoice_permalink, "_blank") }
      else if ( status === 'Aguarda Pagamento' ){
        const { openOrderPaymentDetailsModal } = this.props
        openOrderPaymentDetailsModal(order_payment)
      } else {
        return null
      }

    });
  }

  getSelectedOrder(e, order_id){
    e.preventDefault()
    if (order_id === this.props.selectedOrder.id){
      this.setState({redirect: true})
      return;
    }
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: order_id
    }

    OrdersService.getSelectedOrder(payload).then((response) => {
      const order = response.data
      this.props.saveSelectedOrder(order);
      this.props.endFetch();
      this.setState({redirect: true})
    });
  }

  render() {
    const { 
      order,
      campaign,
      user,
      page
     } = this.props
    const CustomTr = this.getRow();
    if (this.state.redirect === true){
      return <Redirect order={order} to={"/profile/orders/"+ order.number}></Redirect>
    }
    return (
      <CustomTr className='user-row'>
        <td className='text-base text-center'>
          <p className='order-link' onClick={e => this.getSelectedOrder(e, order.id)}>{order.number}</p>
        </td>
        <td className='text-base text-date text-center'>
          <p>{order.checkout_date}</p>
        </td>
        <td className='order-points text-points text-base text-center'>
          <p> {order.total_points}</p>
        </td>
        <td className='text-base text-center sm:no-display'>
          <p style={this.getStyle(order.status)} className='text-status'>{order.status}</p>
        </td>
        {order.has_co_payment && 
          <td className='text-base text-center sm:no-display'>{this.getInfoButton(order)}</td>
        }
        <td className='text-base'>
          <OrderCheckbox 
           user={user}
           campaign={campaign} 
           order={order}
           page={page} 
          />
        </td>
      </CustomTr>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    selectedOrder: state.ordersReducer.selectedOrder
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveSelectedOrder: (order) => {
      dispatch(saveSelectedOrder(order))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(OrderRow);
