
import React from 'react'
import ContentLayout from '../shared/ContentLayout';
import SupportingDocumentsNavbar from './SupportingDocumentsNavbar';

const SupportingDocumentsLayout = ({campaign, user, children}) => {
  return (
    <ContentLayout campaign={campaign} title="Documentos de apoio">
      <div className="navbar-content-info">
        <SupportingDocumentsNavbar campaign={campaign} user={user} />
        <div className= "template">
          {children}
        </div>
      </div>
    </ContentLayout>
  )
}

export default SupportingDocumentsLayout
