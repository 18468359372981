import axios from "./interceptor"

export const SaleAttributionService = {
  getPendingSaleAttributions({clientSlug, campaignSlug, page}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_attributions/index_pending`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page
      }
    });
  },
  updateSaleAttribution({clientSlug, campaignSlug, id, userId}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_attributions/${id}`
    return axios({
      method: "put",
      url: url,
      data: {
        sale: {
          user_id: userId
        }
      }
    });
  },
  getCompletedSaleAttributions({clientSlug, campaignSlug, page}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_attributions/index_completed`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page
      }
    });
  }
}
