import React, { Component } from "react"
import { connect } from "react-redux"
import { startFetch, endFetch } from "../actions/generalActions"
import { ClientService, ChatService } from "../services"
import { saveClient } from "../actions/userActions"
import SetPassword from "../components/shared/FirstLogin/SetPassword"
import ValidateData from "../components/shared/FirstLogin/ValidateData"
import PasswordRoute from "../routes/PasswordRoute"
import DataRoute from "../routes/DataRoute"
import LogInRoute from "../routes/LogInRoute"
import TermsOfService from "../components/shared/FirstLogin/TermsOfService"
import TermsRoute from "../routes/TermsRoute"
import LogIn from "../components/shared/LogIn"
import GamificationLogin from "../components/GamificationTemplate/Login"
import RegisterWrapper from "../components/shared/Register/RegisterWrapper"
import FirstLoginWrapper from "../components/shared/FirstLogin/Wrapper"
import ThankYou from "../components/shared/Register/confirm"
import TemplateWrapper from "./TemplateWrapper"
import CampaignWrapper from "./CampaignWrapper"
import PrivateRoute from "../routes/PrivateRoute"
import { Redirect, Route, Switch } from "react-router-dom"
import PasswordRecovery from "../components/shared/PasswordRecovery"
import EditForgottenPassword from "../components/shared/PasswordRecovery/EditForgottenPassword"
import ForgetPasswordSuccess from "../components/shared/PasswordRecovery/SuccessContainer"

const HomeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return localStorage.getItem("jwt") ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }}
  />
)
const ignorableRoutes = [
  "login",
  "register",
  "password-recovery",
  "set_password",
  "confirmation",
  "validate_data"
]
//selects current Client
class ClientWrapper extends Component {
  state = {
    requested: false
  }
  getClient() {
    this.props.startFetch()
    const prevClient = window.localStorage.getItem("prevClient")
    const client = window.localStorage.getItem("client")
    let { slug } = this.props.match.params
    slug = ignorableRoutes.includes(slug) ? null : slug

    //user logged in -> user logged in and out -> match-path
    ClientService.getClient({
      slug: client || slug || prevClient || "",
      domain: window.location.hostname
    })
      .then(response => {
        const client = response.data

        const jwt = window.localStorage.getItem("jwt")
        if (!jwt) {
          window.localStorage.setItem("prevClient", client.slug)
        }
        document.title = client.name
        window.localStorage.setItem("currency", client.currency_label)

        this.props.saveClient(client)
        if (client.favicon.url) {
          const elem = document.getElementById("favicon")
          elem.setAttribute("type", "image/png")
          elem.setAttribute("href", client.favicon.url)
        }
        if (client.chat_enabled) {
          ChatService.createChatTag("ze-snippet")
        }
        this.setState({ requested: true })
        this.props.endFetch()
      })
      .catch(error => {
        if (error.response && (error.response.status == 302)) {
          // it's asking to redirect to another URL
          window.location.assign( error.response.data.redirection )
        } else {
          this.props.history.push("/not-found")
          this.props.endFetch()
        }
      })
  }

  componentDidMount() {
    this.getClient()
  }

  componentDidUpdate(prevProps) {
    //user logged out
    if (prevProps.client && !this.props.client && this.state.requested) {
      this.setState({ requested: false }, () => this.getClient())
    }
  }

  render() {
    if (!this.state.requested) return null

    return (
      <Switch>
        <HomeRoute path="/register/:token?" exact component={RegisterWrapper} />
        <Redirect from="/:slug/register/:token?" to="/register/:token?" />
        <LogInRoute
          path="/password-recovery/edit/:token"
          exact
          component={EditForgottenPassword}
        />
        <Redirect
          from="/:slug/password-recovery/edit/:token"
          to="/password-recovery/edit/:token"
        />
        <LogInRoute
          path="/password-recovery/success"
          exact
          component={ForgetPasswordSuccess}
        />
        <Redirect
          from="/:slug/password-recovery/success"
          to="/password-recovery/success"
        />
        <LogInRoute
          path="/password-recovery"
          exact
          component={PasswordRecovery}
        />
        <Redirect from="/:slug/password-recovery" to="/password-recovery" />
        <HomeRoute path="/thankyou" exact component={ThankYou} />
        <Redirect from="/:slug/thankyou" to="/thankyou" />
        <HomeRoute path="/confirmation" exact component={FirstLoginWrapper} />
        <Redirect from="/:slug/confirmation" to="/confirmation" />
        <PasswordRoute path="/set_password" exact component={SetPassword} />
        <Redirect from="/:slug/set_password" to="/set_password" />
        <LogInRoute path="/login" exact component={LogIn} />
        <DataRoute path="/validate_data" exact component={ValidateData} />
        <Redirect from="/:slug/validate_data" to="/validate_data" />
        <TermsRoute path="/terms_of_service" exact component={TermsOfService} />
        <Redirect from="/:slug/terms_of_service" to="/terms_of_service" />
        <PrivateRoute exact path="/campaign" component={CampaignWrapper} />
        <Redirect path="/:slug/campaign" to="/campaign" />
        <PrivateRoute path="/" component={TemplateWrapper} />
      </Switch>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    saveClient: client => dispatch(saveClient(client)),
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}
function mapStateToProps(state) {
  return {
    client: state.userReducer.client
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientWrapper)
