import Parser from 'html-react-parser';
import React, { Component } from 'react';

class CartWarning extends Component{

  render(){
    return(
      <div className='col-xs-12'>
        <div style={{padding: '7px 0 7px 10px'}} className='template-warning'>
          <p>ATENÇÃO</p>
        </div>
        <div style={{borderRadius:'0 0 4px 4px'}} className='template cart-warning-container'>
          {Parser(this.props.text)}
        </div>
      </div>
    )};
}

export default CartWarning;
