import axios from './interceptor';


export const QuizzService = {
  getAll({clientSlug, campaignSlug, page}){
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/quizzes`
    return axios({
      method: 'get',
      url: url,
      params:{
        page: page
      }
    });
  },
  getQuizQuestions({clientSlug, campaignSlug, id}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/quizzes/${id}/questions?per_page=100`

    return axios({
      method: 'get',
      url: url
    });
  },
  completeQuiz({clientSlug, campaignSlug, id, answers}){
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/quizzes/${id}/complete_quiz?per_page=100`

    return axios({
      method: 'post',
      url: url,
      data: {
        campaign_quiz: {
          answers
        }
      }
    })
  },
  getQuizHistory({clientSlug, campaignSlug}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_quizzes`
    return axios({
      method: 'get',
      url: url
    });
  },
  getHomeQuiz({clientSlug, campaignSlug}){
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/quizzes/home_index`
    return axios({
      method: 'get',
      url: url,
    });
  },
  getUserQuizAnswers({clientSlug, campaignSlug, id}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_quizzes/${id}`
    return axios({
      method: 'get',
      url: url
    });
  }
}