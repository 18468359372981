import React, {Component} from 'react'
import { connect } from 'react-redux'
import { UserService, saveConsents, buildTransaction } from '../normalizer'

import { ToastStore, ToastContainer } from 'react-toasts';

class ConsentsContainer extends Component {
  state = {
    editConsents: false,
    updatedConsents: []
  }
  getConsents(){
    this.props.startTransaction(() =>
      UserService.getConsents().then((response) => {
        const consents = response.data.consents
        this.props.saveConsents(consents || []);
        this.setState({
          updatedConsents: response.data.consents
        })
      })
    )
  }

  handleEditConsents = () => {
    this.setState({
      editConsents: true
    })
  }

  handleCancelEditConsents = () => {
    this.setState({
      editConsents: false
    })
  }

  updateConsent = (e, consent_id) => {
    const oldConsents = this.state.updatedConsents
    const newConsents = oldConsents.map(consent => {
      if(consent.id === consent_id){
        return { ...consent, accepted: e.target.checked }
      }
      return { ...consent }
    })
    this.setState({
      updatedConsents: newConsents
    })
  }

  confirmEditConsents = () => {
    const updatedConsents = this.state.updatedConsents
    const consents = this.props.consents 
    if ( updatedConsents === consents ){
      this.setState({
        editConsents: false
      })
      return;
    }
    const payload = updatedConsents.map((consent,i) => {
      return { consent_id: consent.id, accepted: consent.accepted }
    })
    this.props.startTransaction(() =>
      UserService.updateConsents(payload).then((response) => {
        const consents = response.data.consents
        this.props.saveConsents(consents || []);
        this.setState({
          updatedConsents: response.data.consents
        })
        ToastStore.success('Consentimentos atualizados com sucesso')
      })
    )
    this.setState({
      editConsents: false
    })
  }
  
  componentDidMount() {
    if(this.props.consents.length === 0){
      this.getConsents()
    }
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
      <>
      <ToastContainer store={ToastStore}/>
      <Component 
        {...rest}
        {...this.state}
        onHandleEditConsents = {this.handleEditConsents}
        onHandleCancelEditConsents = { this.handleCancelEditConsents }
        onUpdateConsent = { this.updateConsent }
        confirmEditConsents = { this.confirmEditConsents }
        
      />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  campaignUser: state.balanceReducer.campaignUser,
  user: state.userReducer.user,
  client: state.userReducer.client,
  consents: state.userReducer.consents
})

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch),
  saveConsents: (consents) => {
    dispatch(saveConsents(consents));
  }
})

const Container = connect(mapStateToProps, mapDispatchToProps)(ConsentsContainer)

export function withConsentsContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
