import React from "react"
import { imagePlaceholder } from "../../normalizer"

const PostSaleProductPlaceholder = ({ colors }) => {
  
  return (
    <div style={{borderColor: `${colors.borderColor}`}} className="gl-posts-sales__container gl-product__container--post bg-default">
      <p className="gl-posts-sales__title text-color">
        Produto Previo
      </p>
      <div>
        <img
          src={imagePlaceholder}
          className="gl-posts-sales__image "
        />
      </div>
      <div style={{color: colors.textColor, backgroundColor: colors.backgroundColor}} 
           className="gl-posts-sales__action-container">
        <div className="gl-posts-sales__points-container">
          <span className="gl-posts-sales__points-value gl-product__points-value--post">
            0
          </span>
        </div>
      </div>
    </div>
  )
}

export default PostSaleProductPlaceholder
