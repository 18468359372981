import React, { Component } from 'react'
import {connect} from 'react-redux'
import ReactPaginate from 'react-paginate'
import { NavLink, withRouter, Redirect, Switch, Route} from 'react-router-dom'
import RankingTable from './RankingTable'
import styled from 'styled-components'
import {RankingsService} from '../../../services'
import { startFetch, endFetch } from '../../../actions/generalActions';
import Moment from 'moment'
import {t, prefix} from '../../../i18n'
import { ToastContainer, ToastStore } from "react-toasts"
const tBalance = prefix('balance')

const TabLinkWrapper = ({children, ...rest}) => (
  <NavLink
    {...rest}
    className="w-1/2 flex items-center justify-center btn-secondary mx-2 px-6 text-center"
    activeClassName={"-my-2 utility-info-active-tab active-color"}
    >
    {children}
  </NavLink>
)

const TabLink = styled(TabLinkWrapper)`
  &.active-color {
    background: ${props => props.primary_color}
    color: ${props => props.navbar_text_color}
}

`
class SelectedRankingWrapper extends Component {
  state = {
    winners: [],
    userPosition : undefined,
    requested: false,
    totalPages: 1,
    currPage: 1
  }
  isNewRankingLoading = (ranking) => {
    return this.state.requested && !ranking
  }

  isCurrentRankingAvailable = ({oldRanking, ranking}) => {
    return !oldRanking && ranking 
  }

  wasRankingAvailable = ({oldRanking, ranking}) => {
    return oldRanking && ranking 
  }

  hasRankingChanged = ({oldRanking, ranking}) => {
    return  oldRanking && ranking.id !== oldRanking.id 
  }

  getRanking = ({rankings, match}) => (
    rankings.find(r => r.id.toString() ===  match.params.id)
  )

  fetchWinners = (payload) => {
    this.props.startFetch()
    RankingsService.getWinners(payload)
      .then((response) => {
        const {results, user_position} = response.data
        this.setState(state => ({
          winners : results,
          userPosition : user_position,
          requested: true
        }), () => this.props.endFetch())

      }).catch(error => {
        this.props.endFetch()
      })
  }

  fetchRankingPages(payload) {

    this.props.startFetch()
    const page = this.state.currPage;
    RankingsService.getRankingPoints({...payload, page: page})
      .then((response) => {
        this.setState({
          points: response.data.ranking_points,
          totalPages : response.data.pagination.total_pages,
          currPage : response.data.pagination.current_page,
        }, () => this.props.endFetch())
 
      }).catch(error => {
        this.props.endFetch()
      })
  }

  loadData = (ranking)=> {
    const {user, campaign} = this.props

    const payload  = {
      client_slug: user.client.slug, 
      campaign_slug: campaign.slug, 
      ranking_id: ranking.id
    }
    this.fetchRankingPages(payload)
    this.fetchWinners(payload)
  }

  handleOrderPageChange = data => {
    const selected = data.selected;
    this.setState(
      {
        currPage: selected + 1
      }, () => {
        const ranking = this.getRanking(this.props)
        const {user, campaign} = this.props
        const payload  = {
          client_slug: user.client.slug, 
          campaign_slug: campaign.slug, 
          ranking_id: ranking.id
        }
        this.fetchRankingPages(payload)
      }
    )
  }

  handlePointsExportClick = (user, campaign, ranking) => {
    const payload  = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug,
      ranking_id: ranking.id
    }

    RankingsService.requesPointsExport({payload})
      .then((response) => {
        const APIresponse = response.data
        if (APIresponse.result_ok) {
          ToastStore.success(APIresponse.message)
        } else {
          ToastStore.error(APIresponse.message)
        }
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido.')
      })
  }

  componentDidMount(){
    const ranking = this.getRanking(this.props)
    if(ranking) {
      this.loadData(ranking)
    } 
  }

  componentDidUpdate(oldProps) {
    const ranking = this.getRanking(this.props)
    const oldRanking = this.getRanking(oldProps)

    if(!this.isNewRankingLoading(ranking)) {
      const data = {oldRanking, ranking}
      if(this.isCurrentRankingAvailable(data)) {
        this.setState({currPage: 1, totalPages: 1}, () => {
          this.loadData(ranking)
        })
      }

      if(this.hasRankingChanged(data)) {
        this.setState({currPage: 1, totalPages: 1}, () => {
          this.loadData(ranking)
        })
      }
    }
  }

  render() {
    const {user, campaign } = this.props
    const {userPosition, requested, winners, points, totalPages, currPage} = this.state

    const ranking = this.getRanking(this.props)
    if(!requested || (requested && !ranking)) return null

    if(!ranking) return <Redirect to={"/not-found"} />

    const activeStyle = {backgroundColor: campaign.color_primary, color: campaign.navbar_text_color }

    return (
      <div className="col-lg-8 col-md-8 col-xs">
        <div className="flex py-2 mx-2 tab-height uppercase">
          <TabLink
            activeStyle={activeStyle}
            to={`/rankings/${ranking.id}`}
            exact
            >
            Ranking
          </TabLink>
          <TabLink
            activeStyle={activeStyle}      
            to={`/rankings/${ranking.id}/balance`}
            exact
            >
            Extrato
          </TabLink>
        </div>
        <div className="col-xs-12">
          <Switch>
            <Route exact  path={`/rankings/${ranking.id}/balance`}>
              <>
                <div className="template">
                  <RankingPointsTable points={points}/>
                </div>
                { totalPages > 1 &&
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    initialPage={currPage - 1}
                    onPageChange={this.handleOrderPageChange}
                    containerClassName={'pagination my-5'}
                    subContainerClassName={'pages'}
                  activeClassName={'active-block'}/>
                }
                <div className='flex justify-end'>
                  <PointsExportButton user={user} campaign={campaign} ranking={ranking} onClickHandler={this.handlePointsExportClick} />
                </div>
                <ToastContainer store={ToastStore} />
              </>
            </Route>
             <Route exact path={`/rankings/${ranking.id}`} >
                <RankingTable 
                  user = {user} 
                  ranking = {ranking} 
                  winners = {winners} 
                  user_position = {userPosition} 
                  campaign={campaign} 
                  />
            </Route> 
          </Switch>
        </div>
      </div>
    )
  }
}

const RankingPointsTable = ({points}) => {
  return (
      <table className='mt-3 w-full'>
        <thead>
          <tr className='user-header uppercase'>
            <th className='w-15 prod-th-title px-2 order-th text-left'>{tBalance('description')}</th>
            <th className='w-10 prod-th-title  text-center'>{tBalance('date')}</th>
            <th className='w-5 prod-th-title  text-right'>{tBalance('points')}</th>
          </tr>
        </thead>
        <tbody>
          {points && points.map((e, key) =><Row key={key} {...e}/> )}
        </tbody>
      </table>  
    
  )
}

const PointsExportButton = ({user,campaign,ranking,onClickHandler}) => {
  return (
      <button
        className="gl-profile__button gl-content-shadow primary-bg secondary-text"
        onClick={() => onClickHandler(user,campaign,ranking)}
      >Exportar</button>
  )
}

const Row = ({description , date, points}) => {
  const validDescription = description || "-"
return (
  <tr className="gray user-row">
    <th className="w-15 text-left py-2 pr-1 text-break">
      <p>{validDescription}</p>
    </th>
    <th className="w-10 text-center">
      <p>{Moment(date).format(t('date-format'))}</p>
    </th>
    <th className="w-5 text-right ">
      <p>{points}</p>
    </th>
  </tr>
)
}


const mapDispatchToProps =(dispatch) => ({
  startFetch: () => dispatch(startFetch()) ,
  endFetch: () => dispatch(endFetch()) 
})

export default withRouter(connect(null, mapDispatchToProps)(SelectedRankingWrapper))