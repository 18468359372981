const initialState = {
  banners: [],
  rankingsBanners: [],
  objectivesBanners: [],
  dashboardBanners: [],
  announcementsBanners: [],
  quizzesBanners: [],
  catalogBanners: []

}


const homepageBannersReducer = (state = initialState, action) => {
  function filterBanners(banners , type){
    let filteredBanners = banners.filter(
      banner => banner.screentab === type || banner.screentab === 'all_pages'
    )
    return filteredBanners
  }

  switch(action.type){
    case 'SAVE_HOMEPAGE_BANNERS':
      let banners = action.payload.banners || []
      const homePageBanners = filterBanners(banners,'homepage') || []
      const rankingsBanners = filterBanners(banners,'rankings') || []
      const objectivesBanners = filterBanners(banners,'objectives') || []
      const dashboardBanners = filterBanners(banners,'dashboard') || []
      const announcementsBanners = filterBanners(banners,'announcements') || []
      const catalogBanners = filterBanners(banners,'catalog') || []
      const quizzesBanners = filterBanners(banners,'quizzes') || []
      return {
        ...state,
        banners: homePageBanners,
        rankingsBanners: rankingsBanners,
        objectivesBanners: objectivesBanners,
        dashboardBanners: dashboardBanners,
        announcementsBanners: announcementsBanners,
        quizzesBanners: quizzesBanners,
        catalogBanners: catalogBanners
      }
    default:
      return state;
  }
}
export default homepageBannersReducer;