import React from 'react'
import CatalogSearch from './CatalogSearch';
import {ReactComponent as FilterSvg} from '../../../assets/img/filtro.svg'

const CatalogSimpleHeader = (props) => {
  const {
    onProductTextSearch,
    searching,
    onSearchOpen,
    filtering,
    onFilterShow,
    onFilterHide
  } = props

  return(
    <div className="flex justify-end mx-2 items-center py-5">
      <div className="flex gl-input__container">
        <CatalogSearch
          searching={searching}
          onProductTextSearch={onProductTextSearch}
          onSearchOpen={onSearchOpen}
        />
        <span className="gl-catalog__line"/>
        <button className="gl-outline-reset" onClick={filtering? onFilterHide: onFilterShow}>
          <FilterSvg className={filtering ? "primary-fill" : "dead-grey-fill"}/>
        </button>
      </div>
    </div>
  )
}
export default CatalogSimpleHeader
