import React, {Component} from 'react'
import {connect} from 'react-redux'


class CustomizedPagesContainer extends Component {

  render() { 
    const {component: Component, ...rest} = this.props
    return ( <Component {...rest}  /> );
    }
}

const mapStateToProps = (state) => ({
  campaign: state.campaignReducer.campaign,
  pages: state.pagesReducer.pages
});
  
const mapDispatchToProps = null;
  
const Container = connect(mapStateToProps, mapDispatchToProps)(CustomizedPagesContainer);

export function withCustomizedPagesContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  );
}

export default Container

