import React from 'react';
import Moment from 'moment';
import { t } from '../../../../i18n'
import Pagination from '../../shared/Pagination';
import { NavLink } from "react-router-dom";


const Row = ({description , date, points}) => {
  
  const validDescription = description || "-"
  const parsedPoints = parseFloat(points)
  const normalizedPoints = parsedPoints % 1 === 0 ? parsedPoints.toFixed(0) : parsedPoints

  return (
    <tr className="g-rankings__data text-sm my-5 vodafone font-normal">
      <td className="text-left">
        <p>{validDescription}</p>
      </td>
      <td className="text-center">
        <p>{Moment(date).format(t('date-format'))}</p>
      </td>
      <td className="text-right">
        <p>{normalizedPoints}</p>
      </td>
    </tr>
  )
}

const BalanceTable = props => {

  const {
    points,
    rankingLabel,
    totalPages,
    page,
    onPageChange
  } = props

  return(
    <>
    <div className='g-rankings__table-container'>
      <table className='g-rankings__table overflow-x-auto w-full text-size vodafone'>
        <thead className='my-10 text-left'>
          <tr className='g-rankings__table-header py-5 my-5'>
            <th className='g-rankings__table-header pt-3 pb-3'>Descrição</th>
            <th className='g-rankings__table-header text-center pt-3 pb-3'>Data</th>
            <th className='g-rankings__table-header text-right pt-3 pb-3 whitespace-no-wrap'>{rankingLabel.currencyLabel}</th>
          </tr>
        </thead>
        <tbody>
          {points && points.map((e, key) =><Row key={key} {...e}/> )}
        </tbody>
      </table> 
      { totalPages > 1  &&
        <div className="text-sm vodafone flex justify-end mt-10">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={onPageChange}/>
        </div>
        } 
        <p className='vodafone'>
          <span>Mais detalhe sobre as VCoins ganhas (no jogo e desafios associados), consulta o </span>
          <NavLink to={"/profile/balance"} className='g-footer--contacts-link' exact>extrato</NavLink> 
          <span> no teu perfil.</span>
        </p>
    </div>
    </>
   )
}

export default BalanceTable