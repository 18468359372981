import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { startFetch, endFetch } from "../../../actions/generalActions"
import ClientLogo from "../client/Logo"
import BackgroundWrapper from "../client/BackgroundWrapper"
import SuccessContainerDefault from "../../DefaultTemplate/PasswordRecovery/SuccessContainer"
import SuccessContainerGamification from "../../GamificationTemplate/PasswordRecovery/SuccessContainer"

const SuccessContainerTemplate = props => {
  const { slug } = props.client
  switch (slug) {
    case "vodafone":
      return <SuccessContainerGamification {...props} />
    default:
      return <SuccessContainerDefault {...props} />
  }
}

class LogInWrapper extends Component {
  render() {
    if (!this.props.location.state) {
      return <Redirect to={"/login"} />
    }
    const { state } = this.props.location

    return (
      <BackgroundWrapper
        backgroundImage={this.props.client.background_banner.url}
      >
        <SuccessContainerTemplate
          client_logo={ClientLogo}
          client={this.props.client}
          user={state.user}
        />
      </BackgroundWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.userReducer.loggedIn,
    client: state.userReducer.client
  }
}
function mapDispatchToProps(dispatch) {
  return {
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInWrapper)
