import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUser } from '../../../actions/userActions';
import { saveAddresses } from '../../../actions/addressesActions';
import { Redirect } from 'react-router';
import { startFetch, endFetch, doLogout } from '../../../actions/generalActions';
import { AddressesService, UserService,  CountryStateService, AuthenticationService } from '../../../services';
import {ToastContainer, ToastStore} from 'react-toasts';
import incentivehouse from '../../../assets/img/incentivelogo.png';
import ShowGroups from './ShowGroups';
import ShowJob from './ShowJob';
import CustomSelect from '../CustomSelect';
import { isEmpty } from '../../../utils/utils'
import {t, prefix} from '../../../i18n'

const tRegistry = prefix('registry')

class ValidateData extends Component{

  constructor(props){
    super(props);

    this.state={
      genderOptions: [{id: 'male', name: tRegistry('male')},{ id:'female', name: tRegistry('female')}],
      redirect: false, 
      errors: {},
      user: {},
      extraFields: {'formation-basic': false, 'formation-products': false},
      address: {},
      jobs: [],
      countries: [],
      selectedCountry: undefined,
      selectedState: undefined,
      states: [],
    
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }
  cancelConfirmation(){
    this.props.startFetch()
    UserService.logOut()
      .then((response) => {
        AuthenticationService.logout()
        this.props.doLogout()
        this.props.endFetch()
      })
      .catch((error) => {
        this.props.endFetch()
      });
  }

  handleCheckbox(event){
    let NewExtraFields = this.state.extraFields
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    NewExtraFields[name] = value
    this.setState({
      extraFields:  NewExtraFields
    });
  }
  handleAddressChange(event) {
    let NewAddress = this.state.address
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    NewAddress[name] = value
    this.setState({
      address: NewAddress
    });
  }

  handleUserChange(event) {
    let NewUser = this.state.user
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    NewUser[name] = value
    this.setState({
      user: NewUser
    });
  }
  getJobs(){
    this.props.startFetch()
    UserService.getJobs()
      .then((response) => {
        this.setState({jobs: response.data.group_position_users})
        this.props.endFetch()
      })
  }
  getAddresses(){
    this.props.startFetch()
    AddressesService.getAddresses()
      .then((response) => {
        this.props.saveAddresses(response.data)
        const address = response.data.addresses[0]
        if (address !== undefined){
          if (address.country){
           this.getStates(address.country)
          }
          this.setState(
            {
              selectedCountry: address.country || undefined, 
              selectedState: address.state || undefined
            }, () => {
              this.getInitalCountryState();
            }
           
            )
        }
        this.getInitalCountryState();
        this.props.endFetch()
      })
  }
  saveGender(option){
    let NewUser = this.state.user
    NewUser.gender = option.id
    this.setState({selectedGender: option, user: NewUser })
  }

  getInitalCountryState(){
    this.props.startFetch();
    let newAddress = this.state.address
    const {
      selectedCountry,
      selectedState
    } = this.state

    CountryStateService.getCountries()
    .then((response) => {
      const countries = response.data.countries 
      const defaultCountry = selectedCountry || countries.find(e => e.iso.toString() === 'PT')
      newAddress.country_id = defaultCountry.id
      CountryStateService.getStates({country_id: defaultCountry.id})
      .then((response) => {
        const states = response.data.states
        const defaultState = selectedState || states.find(e => e.abbr.toString() === 'CNT') || undefined
        newAddress.state_id = defaultState && defaultState.id
        this.setState({
          address: newAddress,
          countries: countries, 
          selectedCountry:  selectedCountry || defaultCountry,
          states: states, 
          selectedState: selectedState || defaultState
        }, () => {
           this.props.endFetch();
        })
      })
      .catch((error) => {
        this.props.endFetch();
      })
    })
    .catch((error) =>{
      this.props.endFetch();
    })
  }
  
  getStates(option){
    let NewAddress = this.state.address
    const payload = {country_id: option.id}
    NewAddress['country_id'] = (option.id).toString()
    CountryStateService.getStates(payload).then((response) => {
      this.setState({address: NewAddress, selectedCountry: option, states: response.data.states})
    })
  }
  saveState(option){
    let NewAddress = this.state.address
    NewAddress['state_id'] = (option.id).toString()
    this.setState({address: NewAddress, selectedState: option})
  }

  submit(e){
    e.preventDefault();

    
    const {
      user,
      address,
      selectedState,
      selectedCountry,
      selectedGender,
      extraFields
    } = this.state
   
    if ( !selectedState || !selectedCountry || !selectedGender){
      ToastStore.error(tRegistry('fill-select'))
      return;
    }
    const payload = {
      user: user,
      address: address,
      extra_fields: extraFields
    }
    if (isEmpty(payload.user)){
      payload.user = this.props.user
    }
    UserService.validateData(payload).then((response) => {
      const user = response.data.user
      this.props.saveUser(user)
      localStorage.setItem('user', JSON.stringify(user));
      
    })
    .catch((error) => {
      const data = error.response.data
      this.setState({errors: {...data.user_errors, ...data.address_errors } })
    })

  }

  getGenderOption(){
    const { 
      user
    } = this.props
    const {
      genderOptions
    } = this.state
    this.setState({selectedGender: genderOptions.find(e => user.gender === e.id)})
  }
  componentDidMount(){
    this.getGenderOption();
    this.getJobs();
    if (this.props.addresses.length === 0){
      this.getAddresses();
    }
    
  }

  render() {
    const { 
      user,
      addresses 
    } = this.props;
    const {
      countries, 
      states, 
      selectedCountry, 
      selectedState,
      selectedGender,
      errors,
      jobs,
      redirect,
      genderOptions
    } = this.state
    if (user.confirm_data || redirect){
      return <Redirect to='home' />
    }

    const address = addresses[0]
    const birthday = new Date(user.birthday).toISOString().substr(0,10);
    return (
      <div className='image-background' style={user.client.background_banner.url ? {backgroundImage: 'url(' + user.client.background_banner.url + ')'}: {} } >
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
        <div className="row font-quicksand center-lg center-xs center-md mt-20 h-full">
          <div className="col-lg-7 col-md-7 col-xs-11 default-container my-10">
            <div className='py-10'><img style={{width:'260px'}} src={user.client.logo.url ? user.client.logo.url : incentivehouse } /></div>
            
                <h2 className='pb-10 template-title'>VALIDAÇÃO DE DADOS</h2>
                <div className='px-8 mx-8'>
                  <div className="flex template-block between-xs text-left">
                    <div className='w-1/2'>
                      <p className="w-full template-blue" >Login: {user.login}</p>
                    </div>
                  </div>
                  <ShowJob jobs = { jobs } />
                  <ShowGroups jobs = { jobs } />
                </div>
                <form className="py-10 mx-8 px-8 text-left bg-white" onSubmit={(e)=> this.submit(e)  }>
                  <h2 className='py-8 template-title'>INFORMAÇÃO PESSOAL</h2>
                  <div className="flex template-block between-xs">
                    <div className='w46'>
                      <input required onChange = {this.handleUserChange} className="w-full template-blue" defaultValue={user.email}  type="email" name="email"></input>
                      {errors.email ? <p className='w-full template-errors'>Email: {errors.email.join(',')}</p> : null}
                    </div>
                  </div>
                  <div className="flex template-block between-xs">
                    <input required onChange = {this.handleUserChange} className="w46 template-blue" defaultValue = { user.first_name ? user.first_name : null}
                    placeholder={user.first_name ? user.first_name : 'Nome'}  type="text" name="first_name"></input>
                    <input required onChange = {this.handleUserChange} className="w46 template-blue" defaultValue = { user.last_name ? user.last_name : null}
                    placeholder={user.last_name ? user.last_name : 'Apelido'}  type="text" name="last_name"></input>
                  </div>
                  <div className="flex template-block between-xs">
                    <div className='w46'>
                      <input required onChange = {this.handleUserChange} className="w-full template-blue" placeholder={user.nif ? user.nif : 'NIF'}  
                      type="text" name="nif" defaultValue = { user.nif ? user.nif : null}></input>
                      {errors.nif  ? <p className='w-full template-errors'>Nif: {errors.nif.join(',')}</p> : null}
                    </div>
                    <div className='w46'>
                      <input required onChange = {this.handleUserChange} className="w-full template-blue" placeholder={user.phone ? user.phone : 'Telemovel'}  
                      type="text" name="phone" defaultValue = { user.phone ? user.phone : null}></input>
                      {errors.phone ? <p className='w-full template-errors'>Telemovel: {errors.phone.join(',')}</p> : null}
                    </div>
                  </div>
                  <div className="flex template-block between-xs">
                    <input required defaultValue={birthday} onChange = {this.handleUserChange} className="w46 template-blue" placeholder="Data de Nascimento"  type="date" name="birthday"></input>
                    <div className='w46'>
                      <CustomSelect defaultValue={selectedGender} campaign={this.props.campaign} onChange = {e => this.saveGender(e)} options = {genderOptions} 
                        getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
                        name='gender' placeholder={tRegistry('gender')} className='w-full mt-3' classNamePrefix='custom-select'/>
                    </div>
                  </div>
                  <h2 className='pt-8 template-title'>Já frequentou alguma das formações Sales Academy?</h2>
                  <div className="pt-4 pb-2">
                    <div className='w-full'>
                      <input onChange={this.handleCheckbox} className= 'template-blue' type='checkbox' name='formation-basic'></input>
                      <span className="text-xl checkbox-label"> Profit Builder I</span>
                    </div>
                  </div>
                  <div className="py-2">  
                    <div className='w-full'>
                      <input onChange={this.handleCheckbox} className='template-blue' type='checkbox' name='formation-products'></input>
                      <span className="text-xl checkbox-label"> Autocrédito, Renting e Manutenção</span>
                    </div>
                  </div>
                 
                  <div>
                  <h2 className='py-8 template-title'>ENDEREÇO</h2>
                  </div>
                  <div className='flex template-block between-xs'>
                    <div className='w46'>
                      <input required onChange = {this.handleAddressChange} placeholder={address ? address.address1 : 'Endereço1'}  
                        defaultValue = { address ? address.address1 : null} className='w-full template-blue'  type='text' name='address1'></input>
                    </div>
                    <div className='w46'>
                      <input onChange = {this.handleAddressChange} placeholder={'Endereço2'} className='w-full template-blue'
                        defaultValue = { address ? address.address1 : null} type='text' name='address2'></input>
                    </div>
                  </div>
                  <div className='flex template-block between-xs'>
                    <div className='w46'>
                      <input required onChange = {this.handleAddressChange}  placeholder={address ? address.zip_code : 'Codigo Postal'} 
                        defaultValue = { address ? address.zip_code : null} className='w-full template-blue' type='text' name='zip_code'></input>
                    </div>
                    <div className='w46'>
                      <input required onChange = {this.handleAddressChange}  placeholder={address ? address.city : 'Cidade'} 
                        defaultValue = { address ? address.city : null}  className='w-full template-blue' type='text' name='city'></input>
                    </div>
                  </div>
                  <div className='flex template-block between-xs'>
                    <div className='w-full flex between-xs'>
                      <div className='w46'>
                        <CustomSelect  campaign={this.props.campaign} onChange={(e) => this.getStates(e)} options = {countries}
                          getOptionLabel={(option) => option.name} value={selectedCountry} getOptionValue={(option) => option.id} 
                          name='country' placeholder='Pais' className='w-full' classNamePrefix='custom-select'/>
                      </div>
                      <div className='w46'>
                        <CustomSelect campaign={this.props.campaign} name='state' onChange={(e) => this.saveState(e)} options = {states}
                          value={selectedState} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
                          name='state' placeholder='Estado' className='w-full' classNamePrefix='custom-select'/>
                      </div>
                  
                    </div>
                  </div>
                  <div className='flex end-xs pt-10'>
                    <button className="button-secondary user-button mx-10" onClick = {(e) => this.cancelConfirmation() }>Cancelar</button>
                    <button className="button-primary user-button" type="submit" value="Submit">Validar</button>
                  </div>
              </form>
          </div>
        </div>
      </div>
    );
  }
  static defaultProps = {
    campaign: {
      navbar_text_color: null,
      color_secondary: null
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    addresses: state.addressesReducer.addresses
  } 
}
function mapDispatchToProps(dispatch){
  return{
    doLogout: () => {
      dispatch(doLogout());
    },
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveAddresses: (addresses) => {
      dispatch(saveAddresses(addresses))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateData);