export function saveCampaigns(state){
  return {
    type: 'SAVE_CAMPAIGNS',
    payload: state
  }
}
export function saveCampaign(state){
  return {
    type: 'SAVE_CAMPAIGN',
    payload: state
  }
}
export const saveCampaignPages = (state) => ({
  type: 'SAVE_CAMPAIGN_PAGES',
  payload: state
})
export const saveCampaignPageContent = (state) => ({
  type: 'SAVE_CAMPAIGN_PAGE_CONTENT',
  payload: state
})
export function selectCampaign(state){
  return {
    type: 'SELECT_CAMPAIGN',
    payload: state
  }
}
