import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router';

class DataRoute extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  render() {
    const {component: Component, ...rest} = this.props
    const { confirm_data } = this.props.user
    return(
      <Route {...rest} render={(props) => {
        return localStorage.getItem('jwt') ? 
          confirm_data ? <Redirect to='/' /> : <Component {...props}/>
         : <Redirect to={'/'+ localStorage.getItem('prevClient') + '/login'} />
      }} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
  }
}

export default connect(mapStateToProps,null)(DataRoute);