import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ClientService } from '../services';
import { saveCampaigns, selectCampaign} from '../actions/campaignActions';
import { startFetch, endFetch, resetFetch} from '../actions/generalActions';
import CampaignSelector from '../components/shared/CampaignSelector/index'
import NoCampaignsAvailable from '../components/shared/CampaignSelector/NoEmptyState'
//selects current campaign
class CampaignWrapper extends Component{

  componentDidMount(){
    const {user } = this.props
    const {slug} = user.client
    const payload = { client_slug: slug};
      this.props.startFetch()
      ClientService.getCampaigns(payload).then((response) => {
        this.props.saveCampaigns(response.data)
        this.props.endFetch()
        // if one, then force redirect and dont show the menu to select a campaign
        const {campaigns} = response.data
        if(campaigns.length === 1) {
          const campaign = campaigns[0]
          this.handleCampaignSelection(campaign)
          return 
        }

        const defaultCampaign = campaigns.find(e => e.default_campaign) 
        if(defaultCampaign) {
          this.handleCampaignSelection(defaultCampaign)
          return 
        }
        
      }).catch((error) => {
        this.props.endFetch()
        this.props.history.push('/not-found')
      })  
}

  selectCampaign = (campaign) => {
    return () => {
      this.handleCampaignSelection(campaign)
    }
  }

  handleCampaignSelection = (campaign)=> {
    localStorage.setItem('campaign', JSON.stringify(campaign))
    this.props.selectCampaign(campaign)
  }

  render() {
    const { selectedCampaign, user, campaigns} = this.props
    if(selectedCampaign) {
      return <Redirect to={`/`}/>
    }
    if(campaigns.length == 0){
       return (
         <NoCampaignsAvailable {...this.props}/>
       )
    }
    //only renders if campaigns has more than one item or doesnt have a default campaign
    if(campaigns.length <= 1 || campaigns.find(e => e.default_campaign)) return null
    
    return (
      <CampaignSelector 
        {...this.props}
        onSelectCampaign={this.selectCampaign}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    selectedCampaign: state.campaignReducer.selectedCampaign,
    campaigns: state.campaignReducer.campaigns,
  }
}

function mapDispatchToProps(dispatch){
  return {
    saveCampaigns: (campaigns) => {
      dispatch(saveCampaigns(campaigns));
    },
    selectCampaign: (campaign) => {
      dispatch(selectCampaign(campaign));
    },
    startFetch: () => {
      dispatch(startFetch());
    },
    endFetch: () => {
      dispatch(endFetch())
    }
    ,
    resetFetch: () => {
      dispatch(resetFetch())
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CampaignWrapper);
