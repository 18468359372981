const initialState = {
  campaign: { name: null, slug: null, start_date: null, end_date: null, 
    color_primary: null, color_secondary: null, navbar_text_color: null, text_color: null, logo: {url: null},
    show_regulations: null, show_rules: null, show_company_trademark: null, pages: null
  }, 
  campaign_loading: false,
  selectedCampaign: JSON.parse(localStorage.getItem('campaign')) || undefined,
  campaigns: []
}

const campaignReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_CAMPAIGN':
      return {
        ...state,
        campaign: action.payload.campaign,
      }
    case 'SAVE_CAMPAIGN_PAGES':
      return { 
        ...state, 
        campaign: {
          ...state.campaign,
          pages: action.payload.pages,
        }
      }
    case 'SAVE_CAMPAIGN_PAGE_CONTENT':
      const pages = state.campaign && state.campaign.pages ? state.campaign.pages : [];
      const updatedCampaignPages = pages.map( page => {
        if (page.slug == action.payload.slug) {
          return {
            ...page,
            content_blocks: action.payload.content_blocks,
          }
        }
        return page
      })
      return { 
        ...state, 
        campaign: {
          ...state.campaign,
          pages: updatedCampaignPages,
        }
      }
    case 'SAVE_CAMPAIGNS':
      return {
        ...state,
        campaigns: action.payload.campaigns,
      }
    case 'SELECT_CAMPAIGN':
      return {
        ...state,
        selectedCampaign: action.payload
      }
    case 'LOADING_CAMPAIGN_TRUE':
      return {
        ...state,
        campaign_loading: true
      }
    case 'LOADING_CAMPAIGN_FALSE':
      return {
        ...state,
        campaign_loading: false
      }
    case 'USER_LOGOUT':
      return {
        ...initialState,
        selectedCampaign: null
      }
    default:
      return state;
  }
}
export default campaignReducer;
