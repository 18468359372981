import React from 'react'
import CardTable from './CardTable'
import { withOrdersContainer, NoOrders } from '../../normalizer';
import EmptyStateLayout from '../../shared/EmptyStateLayout';
import {withClientContainer,withBalanceContainer}  from "../../normalizer"

const Card  = (props) => {
  const { campaign, points, ...rest } = props


  return (
    <div>
      <CardTable {...props}>  </CardTable>
    </div>
  )
}

export default withBalanceContainer(Card)
