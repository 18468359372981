const initialState = {
    pages: []
  }
  
  const pagesReducer = (state = initialState, action) => {
    switch(action.type){
      case 'SAVE_PAGES':
        return {
          ...state,
          pages: action.payload.pages
        }
      
      default:
        return state;
    }
  }
  export default pagesReducer;