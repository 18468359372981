import React from "react"
import CustomSelect from '../shared/CustomSelect'
const CatalogOrderOptions = ({campaign, orderOptions, onOrderChange, selectedOrder}) => {
  return (
        <CustomSelect
          isClearable
          onChangeEvent={onOrderChange}
          options={orderOptions}
          optionsLabel={'name'}
          optionsId={'id'}
          selectedValue={selectedOrder}
          placeholder="Ordenar por ..."
          classNames="text-color h-full px-2 py-3 sm:py-0 col-sm-3 col-xs-12"
          noMessage={() => "Sem filtros"}
        />
  )
}

export default CatalogOrderOptions
