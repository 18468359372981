import axios from './interceptor';

export const ProductsService = {
  getProducts(payload) {
    const url ='/api/clients/'+ payload.client_slug +'/campaigns/' + payload.campaign_slug + '/items'
    return axios({
      method: 'get',
      url: url,
      params: {
        page: payload.page,
        taxon: payload.taxon,
        affordable_products: payload.affordable_products,
        next_affordable_products: payload.next_affordable_products,
        alphabetical_desc: payload.alphabetical_desc,
        alphabetical_asc: payload.alphabetical_asc,
        price_asc: payload.price_asc,
        price_desc: payload.price_desc,
        highlighted: payload.highlighted,
        wished: payload.wished,
        more_views: payload.more_views,
        active_filter_ranges: payload.active_filter_ranges,
        brand: payload.brand,
        text: payload.text
      }
    });
  },
  getWishes(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/items/wishes`
    return axios({
      method: 'get',
      url: url
    });
  },
  getHighlights(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/items/highlights`
    return axios({
      method: 'get',
      url: url
    });
  },
  getMostViewed(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/items/more_views`
    return axios({
      method: 'get',
      url: url
    });
  },
  getRecent(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/items/recents`
    return axios({
      method: 'get',
      url: url
    });
  },
  getProductById({client_slug, campaign_slug, product_id}){
    const url = `/api/clients/${client_slug}/campaigns/${campaign_slug}/items/${product_id}`
    return axios({
      method: 'get',
      url: url
    });
  },
  incrementViewCount({client_slug, campaign_slug, product_id}){
    const url = `/api/clients/${client_slug}/campaigns/${campaign_slug}/items/${product_id}/increase_views`
    return axios({
      method: 'put',
      url: url
    });
  },
  getBrands({client_slug, campaign_slug}){
    const url = `/api/clients/${client_slug}/campaigns/${campaign_slug}/brands`
    return axios({
      method: 'get',
      url: url
    });

  }
}