import axios from './interceptor';

export const OrdersService = {

  getOrders(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.slug}/orders`;
    return axios({
      method: 'get',
      url: url,
      params: {
        page: payload.page
      }
    });
  },
  getSelectedOrder(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.slug}/orders/${payload.order_id}`;
    return axios({
      method: 'get',
      url: url
    });
  },
  updateOrder(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.slug}/orders/${payload.order_id}`;
    return axios({
      method: 'put',
      url: url,
      data: {
        page: payload.page,
        order: {
          received_by_user: payload.userReceived,
          status: payload.status
        }
      }
    })
  }
};
