import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildTransaction, UserService, incentivehouse} from '../normalizer';


export class Terms extends Component {
  state = {
    terms: ''
  }

  getTerms() {
    this.props.startTransaction(() =>
      UserService.getTermsOfService()
        .then((response) => {
          this.setState({ terms: response.data.terms.terms_of_service })
        })
        .catch((error) => {
          this.setState({ terms: 'Sem regulamento' })
        })
    )
  }

  getImageToRender() {
    const client = this.props.client
    if(client.logo){
      return client.logo.url
    }
    return incentivehouse
  }

  

  componentDidMount() {
    this.getTerms();
  }

  render() {
    
    return (
      <>
        <div className="primary-text items-center mb-5 px-2">
          <h3>Termos de Serviços</h3>
        </div>
        <div className="template">
          <div className='flex justify-center'>
          <img src={this.getImageToRender()} />  
          </div>
          <div className='custom-inner-html' dangerouslySetInnerHTML={{ __html: this.state.terms }}>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  client: state.userReducer.client
})

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch)
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Terms)
