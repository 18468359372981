import React from "react"
import CustomCheckbox from "../../shared/CustomCheckbox"

function CatalogCheckbox({ isSelected, label, handleClick }) {
  const className = ["flex", isSelected && "primary-text"].join(" ")

  return (
    <div className="pr-3 py-1">
      <label className={className}>
        <CustomCheckbox
          gamification
          primary
          checked={isSelected}
          onChange={e => handleClick(e)}
        />
        <span className={"ml-2 dark-grey"}>{label}</span>
      </label>
    </div>
  )
}
export default CatalogCheckbox
