import React from 'react';
import NavigationSubNavbarLink from '../shared/NavigationSubNavbarLink'



const RankingsNavigation = ({ranking, firstRanking}) => {
  const selectedRankingId = ranking.id || firstRanking.id
  return (
    <nav className="flex gl-profile__title mb-5 text-color">
        <NavigationSubNavbarLink
          to={`/rankings/${selectedRankingId}`}
          exact
        >
          Ranking
        </NavigationSubNavbarLink>
        <NavigationSubNavbarLink
          to={`/rankings/${selectedRankingId}/balance`}
          exact
        >
          Extrato
        </NavigationSubNavbarLink>
    </nav>

  )
}

export default RankingsNavigation