import React from "react";
import { colorSchemes } from '@nivo/colors'
import { ResponsivePie } from '@nivo/pie';

const Pie = (props) => {
  const { data, padAngle = 2, cornerRadius = 3, innerRadius = 0.7, height, legend, orientation, palette, dataOptions } = props
  const { onMouseEnter, onMouseLeave } = props

  let biggestScheme = 0;
  const schemeOptions = palette ? colorSchemes[palette.name] : colorSchemes['nivo']
  // const markers = dataOptions && dataOptions.markers && dataOptions.markers.length > 0 ? dataOptions.markers.map((marker) => {
  //   const axis = orientation && orientation.toLowerCase() == 'horizontal' ? 'x' : 'y'
  //   return {
  //     axis,
  //     value: marker.value,
  //     lineStyle: { stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2, strokeStyle: 'dotted' },
  //     legend: marker.label,
  //     legendOrientation: orientation,
  //   }
  // }) : []

  // Loop to get the right color palette
  Object.keys(schemeOptions).forEach(key => {
    if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
      biggestScheme = key + 1;
    } else {
      biggestScheme = key;
    }
  });

  const marginAndLegends = ({ anchor, direction }, serieSize = 0) => {
    let margin = {
      top: 40,
      bottom: 20,
      right: 30,
      left: 30
    }

    if (!anchor || !direction) {
      return {
        margin,
        legends: []
      }
    }

    let legend = {
      dataFrom: 'keys',
      anchor: anchor.replace('_', '-'),
      direction: direction,
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 50,
      itemHeight: 15,
      itemOpacity: 0.85,
      symbolSize: 10,
      itemsSpacing: 5,
    }

    if (anchor.includes('top')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              top: 80,
            },
            legends: [{
              ...legend,
              translateY: -20,
              itemDirection: 'bottom-to-top',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              top: (20 * serieSize),
            },
            legends: [{
              ...legend,
              translateY: (-15 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            top: 80,
          },
          legends: [{
            ...legend,
            translateY: -20,
            itemDirection: 'bottom-to-top',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            top: (20 * serieSize),
          },
          legends: [{
            ...legend,
            translateY: (-15 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    if (anchor.includes('bottom')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              bottom: 120,
            },
            legends: [{
              ...legend,
              translateY: 80,
              itemDirection: 'top-to-bottom',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              bottom: (20 * serieSize),
            },
            legends: [{
              ...legend,
              translateY: (15 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            bottom: 120,
          },
          legends: [{
            ...legend,
            translateY: 80,
            itemDirection: 'top-to-bottom',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            bottom: (20 * serieSize),
          },
          legends: [{
            ...legend,
            translateY: (15 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    return { margin, legends: [] }
  }

  // copy to avoid changing the original array
  let colorScheme = [ ...schemeOptions[biggestScheme] ]
  if (palette && palette.inverted) {
    colorScheme.reverse();
  }

  const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
  const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
  if (thereIsPaletteFixedColor) {
    colorScheme = thereIsPaletteStartIndex ? [colorScheme[palette.start_index]] : [colorScheme[0]];
  } else {
    colorScheme = thereIsPaletteStartIndex ? colorScheme.slice(palette.start_index) : colorScheme.slice(0);
  }

  let newData = data.map((dt) => {
    if (dt && dt.label && !dt.id) {
      return {
        ...dt,
        id: dt.label,
      }
    }
    return dt
  })

  return (
    <div style={{ width: '100%', height }}>
      <ResponsivePie
        data={newData}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        margin={marginAndLegends(legend, newData.length).margin}
        innerRadius={innerRadius}
        padAngle={padAngle}
        cornerRadius={cornerRadius}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        // arcLinkLabel="id"
        // arcLinkLabelsSkipAngle={10}
        // arcLinkLabelsTextColor="#333333"
        // arcLinkLabelsColor={{ from: 'color' }}
        // arcLinkLabelsDiagonalLength={13}
        // arcLinkLabelsThickness={2}
        arcLabelsRadiusOffset={1.3}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
        // markers={markers}
        colors={colorScheme || { scheme: 'nivo' }}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        legends={marginAndLegends(legend, newData.length).legends}
      />
    </div>
  );
};

export default Pie;

