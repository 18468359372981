import axios from './interceptor';

export const ObjectiveService = {

  getHighlighted({client_slug, slug}){
    const url = `/api/clients/${client_slug}/campaigns/${slug}/objectives/home_index `;
    return axios({
      method: 'get',
      url: url
    });
  },
  getAll({client_slug, slug, page}) {
    const url = `/api/clients/${client_slug}/campaigns/${slug}/objectives`;
    return axios({
      method: 'get',
      url: url,
      params: {
        page
      }
    });
  }
};