import React, { Component } from 'react';
import Select from 'react-select';
import {t, prefix} from '../../../i18n'

const CommercialManagerForm = ({required, commercialManagers, onChangeCommercialManager}) => {
  const tRegistry = prefix('registry')

  const handleCommercialManagerChange = e => {
    onChangeCommercialManager(e)
  }

  const optionLabel = option => {
    return option.full_name + ' [' + option.email + ']'
  }

  return (
    <>
      <div>
        <h2 className='py-8 template-title'>{tRegistry('commercial-manager-title')}</h2>
      </div>
      <div className="flex template-block">
        <Select isMulti={false} onChange={handleCommercialManagerChange} name='commercial_manager_id' options={commercialManagers} getOptionLabel={(option) => optionLabel(option)} getOptionValue={(option) => option.id}
          placeholder={tRegistry('commercial-manager')} className='w-full' classNamePrefix='custom-select'/>
      </div>
    </>
  )
}

export default CommercialManagerForm