import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
  buildTransaction, 
  NotificationsService,  
  saveNotifications, 
  viewNotification, 
  saveNewerNotifications 
} from '../normalizer'


export class NotificationContainer extends Component {
  interval = undefined
  state = {
    page: 1,
    allLoaded: false,
    scrollFetch: false
  }

  handleNotificationView = (id) => {
    const payload  = {
      client_slug: this.props.client.slug,
      slug: this.props.campaign.slug,
      id
    }

    NotificationsService.updateNotification(payload)
      .then(value => this.props.viewNotification(id))
      .catch(e=>{})
  }

  getNotifications = () => {
    const payload  = {
      client_slug: this.props.client.slug,
      slug: this.props.campaign.slug,
      page: this.state.page
    }

    NotificationsService.getNotifications(payload)
      .then(response => {
        const {current_page, total_pages} = response.data.pagination
        if ( current_page === total_pages ){
          this.setState({ allLoaded: true })
        }
        this.props.saveNotifications(response.data)
        this.setState({ scrollFetch: false })
      })
      .catch()
  }

  getNewNotifications = () => {
    const { notifications } = this.props
    const timestamp =  notifications[0] ? notifications[0].published_date : '' 
    const payload = {
      clientSlug: this.props.client.slug,
      slug: this.props.campaign.slug,
      timestamp: timestamp || ''
    }

    NotificationsService.getNewerNotifications(payload)
      .then(response => this.props.saveNewerNotifications(response.data))
      .catch()
  }

  handleScroll = () => { 
    const dropdown = document.querySelector("div#notification-dropdown") 
    const lastElement = document.querySelector("div#notification-dropdown > div#notification-item:last-child");
    if (lastElement === null){
      return;
    }
    var lastElementOffset = lastElement.offsetTop
    var dropdownOffset = dropdown.clientHeight + dropdown.scrollTop;
    let { allLoaded, scrollFetch } = this.state

    if ( dropdownOffset > lastElementOffset && !allLoaded && !scrollFetch){
      let page = this.state.page + 1
      this.setState( { page, scrollFetch: true }, () => this.getNotifications() )
    }
  }

  componentDidMount = ()=> {
    if (this.props.notifications.length === 0){
      this.getNotifications()
    }

    const notificationInterval = Number(process.env.REACT_APP_NOTIFICATION_INTERVAL)
    if( notificationInterval > 0){
      this.interval = setInterval(()=> {
        this.getNewNotifications()
      }, notificationInterval * 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {
      component: Component,
      ...rest
    } = this.props

    return (
      <Component 
        onNotificationView={this.handleNotificationView}
        onHandleScroll={this.handleScroll}
        {...rest} 
      />
    )
  }
}
const mapStateToProps = (state) => ({
  notifications: state.notificationsReducer.notifications,
  numNotificationsToShow: state.notificationsReducer.numNotificationsToShow,
  pagination: state.notificationsReducer.pagination,
  client: state.userReducer.client,
  campaign: state.campaignReducer.campaign
})

const mapDispatchToProps = (dispatch) => ({
  saveNotifications: (notifications) =>dispatch(saveNotifications(notifications)),
  saveNewerNotifications: (notifications) => dispatch(saveNewerNotifications(notifications)),
  viewNotification: (id) =>dispatch(viewNotification(id)),
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)

export function withNotificationContainer(component) {
  return (props) => (
    <Container 
      component={component}
      {...props}
    />
  )
}
export default Container