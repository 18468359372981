import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import ContentTitle from '../shared/ContentTitle'
import { prefix, t } from '../../../i18n';
import Modal from '../../shared/Modal'
import InvoiceProductTable from './InvoiceProductTable';
import InvoiceProductFormContainer  from './InvoiceProductFormContainer';
import InvoiceForm  from './InvoiceForm';
import Moment from 'moment'
const tInvoice = prefix('invoice')

class InvoiceItem  extends Component {
  state = {
    createModal: false,
    editModal: false,
    editHeaderModal: false,
    removeModal: false,
    product: null
  }

  handleProductCreation = (data) => {
    this.props.onProductCreation(data)
    this.setState({createModal: false, editModal: false, removeModal:false, editHeaderModal: false, product: null})
  }

  handleProductEdit = (data) => {
    this.props.onProductEdit(data, this.state.product )
    this.setState({createModal: false, editModal: false, removeModal:false, editHeaderModal: false, product: null})
  }

  handleProductRemove = () => {
    this.props.onProductRemove(this.state.product )
    this.setState({createModal: false, editModal: false, removeModal:false, editHeaderModal: false, product: null})
  }

  handleHeaderEdit = (data) => {
    this.props.onHeaderEdit(data)
    this.setState({createModal: false, editModal: false, removeModal:false, editHeaderModal: false, product: null})
  }

  showCreationModal = () => {
    this.setState({createModal: true, product: null})
  }

  showEditModal = (product) => {
    this.setState({editModal: true, product: product})
  }
  showRemoveModal = (product) => {
    this.setState({removeModal: true, product: product})
  }

  showEditHeaderModal = (invoice) => {
    this.setState({editHeaderModal: true, invoice: invoice})
  }

  untoggling = (modalName) => {
    return () => this.setState({[modalName]: false, product: null})
  }

  render() {
    const { campaign, linking, editable, invoice, products, saleTypes } = this.props
    const { createModal, editModal, editHeaderModal, removeModal } = this.state
    // console.log(" [InvoiceItem] Invoice => ", invoice)
    const place = invoice.client_group
    return (
      <>
        <div className ="flex crux justify-between mb-3 text-color">
         <Link className ="link-reset" to={linking}>
            Voltar
          </Link>
          <Link className="link-reset"to={linking}>
            <i className='text-xl fa fa-times '></i>
          </Link>
        </div>
        <InvoiceItemLayout
          hasAction={editable}
          actionTitle="Editar"
          title="Cabeçalho"
          campaign={campaign}
          onClick={() => this.showEditHeaderModal(invoice)}
        >
          <div className="flex flex-wrap justify-between font-quicksand-light">
            <div>
              <label className="primary-text text-xl"> {tInvoice('place')}</label>
              <div>{place ? place.external_identifier : 'Sem loja'}</div>
            </div>
            <div>
              <label className="primary-text text-xl"> {tInvoice('number')}</label>
              <div>{invoice.invoice_number}</div>
            </div>
            <div>
              <label className="primary-text text-xl"> {tInvoice('invoice-date')}</label>
              <div>{new Moment(invoice.invoice_date).format(t('date-format'))}</div>
            </div>
            <div>
              <label className="primary-text text-xl"> {tInvoice('sellType')}</label>
              <div>{invoice.sale_type}</div>
            </div>
            <div>
              <label className="primary-text text-xl"> {tInvoice('points')}</label>
              <div className='text-right' >{invoice.points || 0}</div>
            </div>
          </div>
          <div className="flex flex-wrap justify-between font-quicksand-light">
            { invoice.attachment &&
              <a className="download-document-button font-quicksand  primary-bg navbar-text" href={invoice.attachment.url} target="_blank" download >
                <i className="fas fa-arrow-down download-icon-button"></i> Download Anexo
              </a>
            }
           </div>
        </InvoiceItemLayout>
        <InvoiceItemLayout title="Produtos"
          hasAction={editable}
          actionTitle={"Adicionar"}
          campaign={campaign}
          onClick={this.showCreationModal}
        >
          <InvoiceProductTable
            editable={editable}
            products={products}
            onProductEdit={this.showEditModal}
            onProductRemove={this.showRemoveModal}
          />

          { editable &&
            <Modal untoggle={this.untoggling('createModal')} show={createModal}>
              <InvoiceProductFormContainer
                createMode
                onCancel={this.untoggling('createModal')}
                onSubmit={this.handleProductCreation}
                campaign={campaign}
              />
            </Modal>
          }
          { editable &&
            <Modal untoggle={this.untoggling('editModal')} show={editModal}>
              <InvoiceProductFormContainer
                onSubmit={this.handleProductEdit}
                campaign={campaign}
                onCancel={this.untoggling('editModal')}

              />
            </Modal>
          }
          { editable &&
            <Modal small untoggle={this.untoggling('removeModal')} show={removeModal}>
              <InvoiceRemover
                onSubmit={this.handleProductRemove}
                onCancel={this.untoggling('removeModal')}
              />
            </Modal>
          }

          { editable &&
            <Modal untoggle={this.untoggling('editHeaderModal')} show={editHeaderModal}>
               <div className="template-container w-full">
                  <ContentTitle title={'Editar Cabeçalho'} sub campaign={campaign}/>
                  <InvoiceForm
                    onSubmit={this.handleHeaderEdit}
                    campaign={campaign}
                    onCancel={this.untoggling('editHeaderModal')}
                    errors={this.props.headerErrors}
                    saleTypes={saleTypes}
                    inputWidth={'w-2/3'}
                    invoice={invoice}
                  />
               </div>
            </Modal>
          }


          {editable  && products.length > 0 &&
            <div className="flex justify-end items-center">
              <button
                className="mt-8 rounded primary-bg text-white px-3 py-5 outline"
                onClick={this.props.onSubmitToValidation}
                >
                Para aprovação
                <i className="ml-2 fas fa-chevron-right text-white"></i>
              </button>
            </div>
          }
        </InvoiceItemLayout>
      </>
    )
  }
}


const InvoiceItemLayout = ({title, hasAction, actionTitle, onClick, children}) => (
  <div className="my-16">
    <div className="flex justify-between items-center mb-5 ">
      <ContentTitle
        sub
        title={title}
        />
        { hasAction &&
          <button
            onClick={onClick}
            className="rounded primary-border border-primary primary-text border px-3 py-3 outline mr-bs"
            >
            {actionTitle}
          </button>
        }
    </div>
    <div className="col-xs">
      {children}
    </div>
  </div>
)

const InvoiceRemover = ({onCancel, onSubmit}) => (
  <div className="template-container">
    <ContentTitle title={"Remover produto?"} sub/>
    <div className="flex justify-between col-xs-12 mt-6">
      <button type="button" className="mish-bg navbar-text p-2 rounded " onClick={onCancel}>Não</button>
      <button type="button" className="primary-bg navbar-text p-2 rounded " onClick={onSubmit}>Sim</button>
    </div>
  </div>
)
export default InvoiceItem
