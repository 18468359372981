const initialState = {
  objectives: [],
  highlighted: [],
  endingObjective: undefined
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case 'SAVE_CAMPAIGN_OBJECTIVES':
    return { 
      ...state, 
      objectives: payload
    }
  case 'SAVE_CAMPAIGN_HIGHLIGHTED_OBJECTIVES':
    return { 
      ...state, 
      highlighted: payload.objectives,
      endingObjective: payload.ending_obj
    }
  default:
    return state
  }
}
