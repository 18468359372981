
import React from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'

const TablinkWrapper = ({className, ...rest}) => (
  <NavLink
    {...rest}
    className={`${className} flex items-center justify-center btn-secondary mx-2 px-6`}
    activeClassName={"-my-2 utility-info-active-tab primary-bg navbar-text-white"}
  />
)
const TabLink = styled(TablinkWrapper)`
  
`

export default TabLink