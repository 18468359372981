import React, { Component } from "react"
import { NavLink as Link } from "react-router-dom"
import NavbarLink, { NavbarButton } from "../../NavigationLink"
import { connect } from "react-redux"
import NotificationList from "../../Notifications/index"
import Counter from "../../Counter"
import Burger from "../../Burger"
import Dropdown from "./ElipsisDropdown"
import {
  UserService,
  AuthenticationService,
  doLogout,
  selectCampaign,
  ShoppingCartIcon,
  ProfileIcon,
} from "../../../normalizer"
import SimpleDropdown from "../../../../GamificationTemplate/shared/SimpleDropDown"
import { HoverButton } from "../../../../GamificationTemplate/shared/HoverButton"
import ReactGA from "react-ga"
import { withRouter } from "react-router-dom"

class NavBar extends Component {
  state = {
    toggled: false,
  }
  mobileClassesToAdd = ["nav--active", "bg-white", "text-color"]
  mobileClassesToRemove = ["navbar-text"]

  stickNavbar = () => {
    const classes = ["g-navbar--scrolling"]

    const currentPosition = window.pageYOffset
    const navbar = document.getElementById("g-navbar")

    if (currentPosition > navbar.offsetHeight + navbar.offsetTop) {
      classes.forEach((c) => {
        navbar.classList.add(c)
      })
    } else {
      classes.forEach((c) => {
        navbar.classList.remove(c)
      })
    }
  }

  handleLogout = () => {
    UserService.logOut().then((response) => {
      AuthenticationService.logout()
      this.props.selectCampaign(undefined)
      this.props.doLogOut()
    })
  }

  hideMenu = () => {
    const nav = document.getElementById("nav").classList
    this.mobileClassesToRemove.forEach((c) => nav.add(c))
    this.mobileClassesToAdd.forEach((c) => nav.remove(c))

    const gNav = document.getElementById("g-navbar").classList
    gNav.remove("h-screen")
    this.setState({
      toggled: false,
    })
  }

  showMenu = () => {
    const nav = document.getElementById("nav").classList

    this.mobileClassesToAdd.forEach((c) => nav.add(c))
    this.mobileClassesToRemove.forEach((c) => nav.remove(c))

    const gNav = document.getElementById("g-navbar").classList
    gNav.add("h-screen")
    this.setState({
      toggled: true,
    })
  }

  updateSizes = () => {
    if (window.innerWidth > 900) this.hideMenu()
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSizes)
    window.onscroll = this.stickNavbar
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSizes)
    window.onscroll = undefined
  }

  // Campaign selection
  selectCampaign = (campaign) => {
    return () => {
      this.handleCampaignSelection(campaign)
    }
  }

  handleCampaignSelection = (campaign) => {
    if (campaign.slug !== this.props.campaign.slug) {
      ReactGA.event({
        category: "Campaign change",
        action: "Selected - " + campaign.name,
      })
      localStorage.setItem("campaign", JSON.stringify(campaign))
      this.props.selectCampaign(campaign)
      this.props.history.push("/")
      window.location.reload();
    }
  }

  render() {
    const { logo, itemCount, campaignUser, campaigns, campaign, features } = this.props
    const { toggled } = this.state

    const findFeatureByName = (name) => {
      const feature = this.props.features.find(f => f.name === name)

      return feature
    }
     
    const hasNoDashboard = () => {
      const feature = findFeatureByName('Dashboard')
      return typeof feature == 'undefined'
    }

    const If = ({ condition, children }) => {
      if (condition) {
        return children
      } else {
        return null
      }
    }

    return (
      <nav id="g-navbar" className="g-navbar p-container">
        <Link
          onClick={this.hideMenu}
          exact
          to="/"
          className="above-content content"
        >
          <img src={logo.url} alt="logo" className="g-navbar__logo" />
        </Link>
        <div
          id="nav"
          className="nav navbar-text z-2 g-navbar__links lighter dead-grey"
        >
          <NavbarLink to="/announcement" onClick={this.hideMenu}>
            Jogos
          </NavbarLink>
          {/* <NavbarLink to="/news" onClick={hideMenu}>Notícias</NavbarLink> */}
          <NavbarLink to="/quiz" onClick={this.hideMenu}>
            Quizes
          </NavbarLink>
          <If condition={ hasNoDashboard() }>
            <>
              <NavbarLink to="/objectives" onClick={this.hideMenu}>
                Resultados
              </NavbarLink>
              <NavbarLink to="/rankings" onClick={this.hideMenu}>
                Rankings
              </NavbarLink>
            </>
          </If>

          <If condition={ !hasNoDashboard() }>
            <>
              <NavbarLink to="/objectives" onClick={this.hideMenu} excludeDisplayClass={true} className='flex md:hidden'>
                Resultados
              </NavbarLink>
              <NavbarLink to="/rankings"   onClick={this.hideMenu} excludeDisplayClass={true} className='flex md:hidden'>
                Rankings
              </NavbarLink>
              <NavbarLink to="/dashboard"  onClick={this.hideMenu} excludeDisplayClass={true} className='hidden md:flex_important'>
                Dashboard
              </NavbarLink>
            </>
          </If>

          <NavbarLink to="/catalog" onClick={this.hideMenu}>
            VStore
          </NavbarLink>
          <SimpleDropdown
            condition={campaigns.length > 1}
            message="Campanhas"
            campaign={campaign}
          >
            <div className="flex flex-col">
              {campaigns.map((camp) => (
                <HoverButton
                  active={campaign.slug === camp.slug}
                  onClick={this.selectCampaign(camp)}
                  key={`nav-houver-${camp.slug}`}
                  campaign={campaign}
                >
                  {camp.name}
                </HoverButton>
              ))}
            </div>
          </SimpleDropdown>
          <NavbarButton
            className="mobile-show text-left"
            onClick={this.handleLogout}
          >
            Logout
          </NavbarButton>
        </div>
        <div className="flex text-size">
          <NotificationList />
          <Link
            title="Perfil"
            onClick={this.hideMenu}
            to="/profile"
            className="navbar-text p-3 above-content relative campaign-link-icon"
          >
            <ProfileIcon className="g-navbar__icons24" />
          </Link>
          <Link
            title="Carrinho"
            onClick={this.hideMenu}
            to="/cart"
            className="navbar-text p-3 above-content relative campaign-link-icon"
          >
            <ShoppingCartIcon className="g-navbar__icons24" />
            <Counter value={itemCount} />
          </Link>
          <Dropdown onLogout={this.handleLogout} campaignUser={campaignUser} />
        </div>
        <Burger onClick={toggled ? this.hideMenu : this.showMenu} />
      </nav>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectCampaign: (campaign) => {
      dispatch(selectCampaign(campaign))
    },
    doLogOut: () => {
      dispatch(doLogout())
    },
  }
}

const mapStateToProps = (state) => ({
  itemCount: state.cartReducer.cart.items.length,
  campaignUser: state.balanceReducer.campaignUser,
  campaign: state.campaignReducer.campaign,
  campaigns: state.campaignReducer.campaigns,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar))
