import React from "react";
import { colorSchemes } from "@nivo/colors";
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import { line } from "d3-shape";

const RadialBar = (props) => {
  // const canvasRef = React.useRef(null);
  const radialBarRef = React.useRef(null);
  const { data, startAngle = 0, endAngle = 360, padding = 0.4, padAngle = 0, cornerRadius = 15, innerRadius = 0.4, height, margin, legend, palette, dataOptions, orientation } = props
  const { onMouseEnter, onMouseLeave } = props

  let biggestScheme = 0;
  const schemeOptions = palette ? colorSchemes[palette.name] : colorSchemes['nivo']
  const markers = dataOptions && dataOptions.markers && dataOptions.markers.length > 0 ? dataOptions.markers.map((marker) => {
    const axis = orientation && orientation.toLowerCase() == 'horizontal' ? 'x' : 'y'
    return {
      axis,
      value: marker.value,
      lineStyle: { stroke: 'rgba(0, 0, 0, .8)', strokeWidth: 5, strokeStyle: 'dotted' },
      legend: marker.label,
      legendOrientation: orientation && orientation.toLowerCase() == 'horizontal' ? 'vertical' : 'horizontal',
    }
  }) : []

  // Loop to get the right color palette
  Object.keys(schemeOptions).forEach(key => {
    if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
      biggestScheme = key + 1;
    } else {
      biggestScheme = key;
    }
  });

  // copy to avoid changing the original array
  let colorScheme = [ ...schemeOptions[biggestScheme] ]
  if (palette && palette.inverted) {
    colorScheme.reverse();
  }

  const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
  const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
  if (thereIsPaletteFixedColor) {
    colorScheme = thereIsPaletteStartIndex ? [colorScheme[palette.start_index]] : [colorScheme[0]];
  } else {
    colorScheme = thereIsPaletteStartIndex ? colorScheme.slice(palette.start_index) : colorScheme.slice(0);
  }

  let preparedData = []
  for (var i = 0; i < data.length; i++) {
    preparedData.push(
      {
        "id": (i + 1),
        "data": [
          {
            "x": data[i].label,
            "y": data[i].value,
          },
        ]
      }
    )
  }

  // Functions to draw marker over graph
  const drawPointer = (iX, iY, deg, len) => {
    const rad = (Math.PI / 180 * deg);
    const x = iX + Math.cos(rad) * len;
    const y = iY + Math.sin(rad) * len;
    const labelX = Math.cos(rad) > 0 ? x + 15 : x - 15;
    const labelY = Math.sin(rad) > 0 ? y + 15 : y - 15;
    return { x, y, labelX, labelY }
  }

  const drawLine = (ctx) => {
    // console.log(' CTX => ', ctx);
    // console.log(' height => ', height);
    // (function tester() {
      // var container = document.getElementById('radialBar').getElementsByTagName('div')[1];
      // console.log(' RadialBar Ref => ', radialBarRef.current)
      // console.log(' RadialBar Ref Width => ', radialBarRef.current.offsetWidth)
      // const svgElem = container && container.getElementsByTagName('svg')
      // let svgElem = radialBarRef && radialBarRef.current && radialBarRef.current.getElementsByTagName('svg')
      // console.log(' SVG => ', svgElem)
      // let containerWidth = !!svgElem && svgElem.length > 0 ? svgElem[0].clientWidth : 250;
      let containerWidth = radialBarRef && radialBarRef.current ? radialBarRef.current.offsetWidth : height;
    // })()

    if (markers[0].value === null)
      return <></>

    const checkpoint = parseFloat(markers[0].value);
    const initial = { x: ctx.center[0], y: ctx.center[1] };
    let final = { x: 0, y: 0 };

    final = drawPointer(initial.x, initial.y, (3.6 * (checkpoint - 25)), ((height / 2) * 0.70));
    // console.log('\n Initial -> ', initial);
    // console.log(' Final -> ', final);
    // console.log(' Container Width -> ', containerWidth);

    const points = [initial, final];
    // console.log(' Points -> ', points);
    const lineGenerator = line().x(point => point.x).y(point => point.y);
    return (
      <>
        <g transform={`translate(${final.labelX},${final.labelY})`}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            overflow={'hidden'}
            width="2em"
            style={{
              fill: '#333',
              fontSize: 22,
            }}
          >
            {`${checkpoint}%`}
          </text>
        </g>
        <path
          d={lineGenerator(points)}
          fill="none"
          stroke={"#000000"}
          style={{ pointerEvents: "none" }}
        />
      </>
    );
  }


  const marginAndLegends = ({ anchor, direction }, serieSize = 0) => {
    let margin = {
      top: 20,
      bottom: 20,
      right: 30,
      left: 30
    }

    if (!anchor || !direction) {
      return {
        margin,
        legends: []
      }
    }

    let legend = {
      dataFrom: 'keys',
      anchor: anchor.replace('_', '-'),
      direction: direction,
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 125,
      itemHeight: 15,
      itemOpacity: 0.85,
      symbolSize: 10,
      itemsSpacing: 5,
    }

    if (anchor.includes('top')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              top: 80,
            },
            legends: [{
              ...legend,
              translateY: -40,
              itemDirection: 'bottom-to-top',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              top: (20 * serieSize),
            },
            legends: [{
              ...legend,
              translateY: (-15 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            top: 80,
          },
          legends: [{
            ...legend,
            translateY: -40,
            itemDirection: 'bottom-to-top',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            top: (20 * serieSize),
          },
          legends: [{
            ...legend,
            translateY: (-15 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    if (anchor.includes('bottom')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              bottom: 80,
            },
            legends: [{
              ...legend,
              translateY: 40,
              itemDirection: 'top-to-bottom',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              bottom: (20 * serieSize),
            },
            legends: [{
              ...legend,
              translateY: (15 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            bottom: 80,
          },
          legends: [{
            ...legend,
            translateY: 40,
            itemDirection: 'top-to-bottom',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            bottom: (20 * serieSize),
          },
          legends: [{
            ...legend,
            translateY: (15 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    return { margin, legends: [] }
  }

  return (
    <div style={{ width: '100%', height }} id={'radialBar'} ref={radialBarRef}>
      <ResponsiveRadialBar
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animate={true}
        // data={data}
        data={preparedData}
        valueFormat=">-.2f"
        maxValue={dataOptions && dataOptions.values && dataOptions.values.max || 'auto'}
        padding={padding}
        padAngle={padAngle}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius}
        cornerRadius={cornerRadius}
        margin={marginAndLegends(legend, preparedData.length).margin}
        colors={colorScheme || { scheme: 'nivo' }}
        // radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        // circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0, tickComponent}}
        enableTracks={true}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        // radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        // circularAxisOuter={{ tickSize: 0, tickPadding: 12, tickRotation: 0 }}
        legends={marginAndLegends(legend, preparedData.length).legends}
        layers={[
          ...['grid', 'tracks', 'bars', 'labels', 'legends', drawLine],
        ]}
      />
    </div>
  )
}

export default RadialBar;

