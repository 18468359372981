import React from 'react'
import styled, {css} from 'styled-components'
const StepWrapper  = styled.div`
:after{
  border-left: 25px solid transparent;
}
 ${props => props.isActive && css`
    color: ${props.navbar_text_color} !important;
    background: ${props.color_primary}
    :after{
      border-left: 25px solid ${props.color_primary};
    }
    :before{
      border-left: 25px solid white ;
    }
 `}
` 

export default function CartNavigation({step, campaign}) {
  return (
    <div className='flex shopcart-navbar'>
      <StepWrapper isActive={step ===1} className='w-1/3 cart-item' {...campaign}>
        <p className='cart-item-text'>1. Encomenda</p>
      </StepWrapper>
      <StepWrapper isActive={step ===2} className='w-1/3 cart-item'{...campaign}>
        <p className='cart-item-text'>2. Dados de Entrega</p>
      </StepWrapper>
      <StepWrapper isActive={step ===3} className='w-1/3 cart-item'{...campaign}>
        <p className='cart-item-text'>3. Confirmação</p>
      </StepWrapper>
  </div>
  )
}
