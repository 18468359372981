import React, { useRef } from "react";
import { colorSchemes } from '@nivo/colors'
import BarChart from "../Chart/bar";
import PieChart from "../Chart/pie";
import LineChart from "../Chart/line";
import RadialBarChart from "../Chart/radialBar";
import PageTable from '../Table';

import ChartTooltip from "../Chart/chartTooltip.js";

const ColumnContent = (props) => {
  console.log('ColumnContent - props => ', props)
  const { content, bck_color, color, num_of_cols } = props
  const { type, palette, data, orientation, legend, axisLeft, axisRight, axisTop, axisBottom } = !!content ? content : {}
  const legendAnchor = legend && legend.anchor
  const legendDirection = legend && legend.direction
  const collection = data && data.collection
  const dataOptions = data && data.options
  const dataColumns = data && data.columns
  const message = data && data.message
  const columnContentHeight = content && content.data && content.data.message ? 330 : 380

  const hasMessage = (content) => {
    return (content && content.data && content.data.message)
  }

  const fullRow = (size) => {
    return (size >= 12)
  }

  const contentStyle = ( content, num_of_cols) => {
    let columnStyle = fullRow( num_of_cols ) ? 'dashboard-content-full-row-column' : 'dashboard-content-column'
    return ( columnStyle + ( hasMessage(content) ? '-with-message' : '') )
  }

  if (!!type && (type.toLowerCase() == "text" || type.toLowerCase() == "value")) {

    let biggestScheme = 0;
    const schemeOptions = palette ? colorSchemes[palette.name] : 'nivo'

    // Loop to get the right color palette
    Object.keys(schemeOptions).forEach(key => {
      if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
        biggestScheme = key + 1;
      } else {
        biggestScheme = key;
      }
    });

    // copy to avoid changing the original array
    let colorScheme = [ ...schemeOptions[biggestScheme] ]
    if (palette && palette.inverted) {
      colorScheme.reverse();
    }

    const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
    const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
    if (thereIsPaletteFixedColor) {
      colorScheme = thereIsPaletteStartIndex ? [colorScheme[palette.start_index]] : [colorScheme[0]];
    } else {
      colorScheme = thereIsPaletteStartIndex ? colorScheme.slice(palette.start_index) : colorScheme.slice(0);
    }

    let vAlignItems
    let xAlignItems
    let sameLine = false

    if (( legendAnchor === null ) || ( legendAnchor === undefined )) // true
      vAlignItems = 'justify-center'
    else if ( legendAnchor.indexOf('top') !== -1 ) // true
      vAlignItems = 'justify-start'
    else if ( legendAnchor.indexOf('bottom') !== -1 ) // true
      vAlignItems = 'justify-end'
    else
      vAlignItems = 'justify-center'

    if (( legendAnchor === null ) || ( legendAnchor === undefined )) // true
      xAlignItems = 'items-center'
    else if ( legendAnchor.indexOf('right') !== -1 ) // true
      xAlignItems = 'items-end'
    else if ( legendAnchor.indexOf('left') !== -1 ) // true
      xAlignItems = 'items-start'
    else
      xAlignItems = 'items-center'

    if (( vAlignItems == 'justify-center' ) && (( xAlignItems == 'items-end' ) || ( xAlignItems == 'items-start' )) )
      sameLine = true

    const columnStyle = contentStyle(content, parseInt(num_of_cols))

    return (
      <div className={`flex flex-col ${vAlignItems} ${xAlignItems} w-full h-full dashboard-content ${columnStyle}`} >
        {
          data && data.collection && data.collection.map((dt, idx) => {
            if (sameLine) 
              return (
                <div key={idx} className="p-2 my-2 truncate" >
                  <span className="font-bold mx-auto text-center truncate py-3 dashboard-text-label" style={{ color }}>{dt.label}</span>
                  <span className="font-bold text-center truncate p-3 dashboard-text-value" style={{ color: colorScheme[0] }}>{dt.value}</span>
                </div>
              )
            else
              return (
                <div key={idx} className="p-2 my-2 truncate" >
                  <p className="font-bold mx-auto text-center truncate py-3 dashboard-text-label" style={{ color }}>{dt.label}</p>
                  <p className="font-bold text-center truncate p-3 dashboard-text-value" style={{ color: colorScheme[0] }}>{dt.value}</p>
                </div>
              )
          })
        }
      </div>
    )
  }

  const tooltipElement = useRef();
  let tooltipVisible = false

  const handleEnter = ( node, event) => {
    // set this to mark has being visible
    tooltipVisible = true

    const label = node["category"] || node["indexValue"] || node["label"]
    const value = node["value"]
    const color = node.data[node.id + 'Color']
  
    // console.log( `Mouse enter: ${label} = ${node["value"]}`);

    tooltipElement.current.changeVisibility( true );

    tooltipElement.current.changeTooltip( event.pageX, event.pageY, label, value, color );
  };

  const handleLeave = (node, event) => {
    // console.log( `Mouse leave: ${node["id"]} = ${node["value"]}`);

    // unset this to marke that should hide
    tooltipVisible = false

    // wait
    setTimeout(function(){
      // hide if still unset
      if (!tooltipVisible) 
        tooltipElement.current.changeVisibility( false );
    }, 300)

  };

  if (collection && dataOptions) {
    if (!!type && type.toLowerCase() == 'bar') {
      return (
        <>
          <ChartTooltip ref={tooltipElement} />
          <BarChart
            height={columnContentHeight}
            data={collection}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            bckColor={bck_color}
            dataOptions={dataOptions}
            message={message || 'Testando a mensagem!'}
            legend={{ anchor: legendAnchor, direction: legendDirection }}
            orientation={orientation}
            palette={palette}
            axisTop={axisTop}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            axisRight={axisRight}
            numOfCols={num_of_cols}
          />
        </>
      )
    }

    if (!!type && type.toLowerCase() == 'pie') {
      return (
        <>
          <ChartTooltip ref={tooltipElement} />
          <PieChart
            height={columnContentHeight}
            bckColor={bck_color}
            data={collection}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            dataOptions={dataOptions}
            message={message || 'Testando a mensagem!'}
            legend={{ anchor: legendAnchor, direction: legendDirection }}
            orientation={orientation}
            palette={palette}
            axisTop={axisTop}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            axisRight={axisRight}
            numOfCols={num_of_cols}
          />
        </>
      )
    }

    if (!!type && type.toLowerCase() == 'radialbar') {
      return (
        <>
          <ChartTooltip ref={tooltipElement} />
          <RadialBarChart
            height={columnContentHeight}
            bckColor={bck_color}
            data={collection}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            dataOptions={dataOptions}
            message={message || 'Testando a mensagem!'}
            legend={{ anchor: legendAnchor, direction: legendDirection }}
            orientation={orientation}
            palette={palette}
            axisTop={axisTop}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            axisRight={axisRight}
            numOfCols={num_of_cols}
          />
        </>
      )
    }

    if (!!type && type.toLowerCase() == 'line') {
      return (
        <>          
          <ChartTooltip ref={tooltipElement} />
          <LineChart
            height={columnContentHeight}
            bckColor={bck_color}
            data={collection}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            dataOptions={dataOptions}
            message={message || 'Testando a mensagem!'}
            title={props.title}
            legend={{ anchor: legendAnchor, direction: legendDirection }}
            orientation={orientation}
            palette={palette}
            axisTop={axisTop}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            axisRight={axisRight}
            numOfCols={num_of_cols}
          />
        </>
      )
    }

    if (!!type && type.toLowerCase() == 'table') {

      let biggestScheme = 0;
      const schemeOptions = palette ? colorSchemes[palette.name] : 'nivo'

      // Loop to get the right color palette
      Object.keys(schemeOptions).forEach(key => {
        if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
          biggestScheme = key + 1;
        } else {
          biggestScheme = key;
        }
      });

      // copy to avoid changing the original array
      let colorScheme = [ ...schemeOptions[biggestScheme] ]
      if (palette && palette.inverted) {
        colorScheme.reverse();
      }

      const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
      const thereIsPaletteEnhancedIndex = palette && palette.enhanced_index !== null && palette.enhanced_index !== undefined
      const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
      const enhancedBckColor = colorScheme && colorScheme.length > 0 && thereIsPaletteEnhancedIndex && colorScheme[palette.enhanced_index % (colorScheme.length)] || '#fff'
      const headerBckColor = colorScheme && colorScheme.length > 0 && thereIsPaletteStartIndex && colorScheme[palette.start_index % (colorScheme.length)] || '#fff'

      return (
        <div style={{ height: columnContentHeight}}>
        <PageTable
          columns={dataColumns}
          rows={collection}
          headerBckColor={headerBckColor}
          enhancedBckColor={enhancedBckColor}
        />
        </div>
      )
    }
  }

  return (
    <React.Fragment />
  )
}

export default React.memo(ColumnContent);
