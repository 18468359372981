import React, { Component } from 'react'
import AddressList from './AddressList';

class Controller extends Component {
  state = {
    adding: false
  }

  handleShowAddressForm = () => {
    this.setState({adding: true})
  }
  handleHideAddressform = () => {
    this.setState({adding: false})
  }

  render() {
    return (
      <AddressList {...this.props}
        adding={this.state.adding}
        onShowAddressForm ={this.handleShowAddressForm}
        onHideAddressForm ={this.handleHideAddressform}
      />
    )
  }
}

export default Controller