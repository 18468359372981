import React, { Component } from "react"
import { Chevron } from "../../normalizer"

function LeftArrow({ onClick, color }) {
  return (
    <Chevron
      style={{
        fill: color, 
        color: color
      }}
      className={`gl-custom-slider__arrow gl-custom-slider__arrow--left secondary-fill`}
      onClick={onClick}
    />
  )
}

function RightArrow({ onClick, color }) {
  return (
    <Chevron
      style={{
        fill: color, 
        color: color
      }}
      className={`gl-custom-slider__arrow`}
      onClick={onClick}
    />
  )
}

export default class Slider extends Component {
  static defaultProps = {
    sliderId: "sliderId"
  }

  state = {
    currentIndex: 0,
    displayedElements: undefined,
    reachedEnd: true,
    atBegining: true
  }

  leftButtonClasses = () => {
    const { atBegining } = this.state
    return [
      "gl-custom-slider__button",
      atBegining && "gl-custom-slider__button--hidden"
    ].join(" ")
  }

  rightButtonClasses = () => {
    const { reachedEnd } = this.state
    return [
      "gl-custom-slider__button",
      reachedEnd && "gl-custom-slider__button--hidden"
    ].join(" ")
  }

  directionClasses = (direction) => {
    if (direction === 'center'){
      return 'gl-custom-slider gl-custom-slider__between'
    }
    if (direction === 'start'){
      return 'gl-custom-slider'
    }
    return 'gl-custom-slider gl-custom-slider__between'
  }

  reachedEnd = (elements, state) =>
    state.displayedElements &&
    state.currentIndex >= elements.length - state.displayedElements

  atBegining = (state, elements) =>
    state.currentIndex === 0 || state.displayedElements === elements.length

  scroll = ({ toleft }) => {
    const slider = document.getElementById(this.props.sliderId)
    const elements = slider.querySelectorAll(".gl-custom-slider__item")

    const element = elements[0]

    const signal = toleft ? -1 : 1
    this.setState(
      state => {
        if (!toleft && this.reachedEnd(elements, state)) {
          return {
            ...state,
            reachedEnd: true,
            atBegining: false
          }
        }

        if (toleft && this.atBegining(state, elements)) {
          return {
            ...state,
            atBegining: true,
            reachedEnd: false
          }
        }

        const newIndex = state.currentIndex + signal
        const atBegining = this.atBegining({ currentIndex: newIndex }, elements)
        const reachedEnd = this.reachedEnd(elements, {
          ...state,
          currentIndex: newIndex
        })
        return {
          currentIndex: state.currentIndex + signal,
          offset: element.offsetWidth * newIndex,
          atBegining,
          reachedEnd
        }
      },
      () => {
        if (slider.scroll) {
          slider.scroll({
            left: this.state.offset,
            top: 0,
            behavior: "smooth"
          })
        } else {
          slider.scrollLeft = this.state.offset
        }
      }
    )
  }

  resize = () => {
    const slider = document.getElementById(this.props.sliderId)
    const elements = slider.querySelectorAll(".gl-custom-slider__item")
    const element = elements[0]

    if (!element) return

    const displayedElements = parseInt(
      (slider.offsetWidth / element.offsetWidth) % 100 //parseto int
    )
    this.setState({
      displayedElements,
      atBegining: this.state.currentIndex === 0 || this.atBegining(this.state, elements),
      reachedEnd:
        this.reachedEnd(elements, this.state) ||
        elements.length === displayedElements
    })
  }

  handleToLeftClick = () => {
    this.scroll({ toleft: true })
  }

  handleToRightClick = () => {
    this.scroll({ toleft: false })
  }

  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  render() {
    const { children, sliderId, arrowColor, direction } = this.props
    const elements = React.Children.toArray(children).map((e, i) =>
      React.cloneElement(e, {
        key: "from-slider" + i + sliderId
      })
    )

    const leftBtnClassName = this.leftButtonClasses()
    const rightBtnClassName = this.rightButtonClasses()
    
    return (
      <div className={this.directionClasses(direction)}>
        <button onClick={this.handleToLeftClick} className={leftBtnClassName}>
          <LeftArrow color={arrowColor} />
        </button>
        <div className="gl-custom-slider__list" id={sliderId}>
          {elements.map((elem, idx) => (
            <div className="gl-custom-slider__item" key={"slider-item-" + idx}>
              {elem}
            </div>
          ))}
        </div>
        <button onClick={this.handleToRightClick} className={rightBtnClassName}>
          <RightArrow color={arrowColor} />
        </button>
      </div>
    )
  }
}
