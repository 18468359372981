export function saveLevels(state){
  return {
    type: 'SAVE_USER_LEVELS',
    payload: state
  }
}
export function selectLevel(state){
  return {
    type: 'SAVE_SELECTED_LEVEL',
    payload: state
  }
}