import React, { Component } from 'react';
import RankingBar from './RankingBar';
import { isDate } from 'util';

class RankingHome extends Component{

  constructor(props){
    super(props);
    const data = []
    for(var i=0; i< 22; i++){ data.push(i) }

    this.state = {list: data}
  }

  getBackground(){
    const {color_secondary, color_primary} = this.props.campaign

    return {
      background: `linear-gradient(90deg, ${color_primary} 0%, ${color_secondary} 65%)`,
    }
  } 
  getDate(){
    const { 
      ranking
    } = this.props
    if (ranking.updated_at !== 'N/A'){
      var options = { month: 'long', day: 'numeric' };
      var date = new Date(ranking.updated_at)
      return date.toLocaleDateString('pt-PT',options)
      
    }
    return 'N/A'

  }


  render(){
    const {
      ranking,
      campaign
    } = this.props

    const {
      list
    } = this.state
    
    return(
      <div className="mb-10">
        <div style={this.getBackground() } className='background-blue template-info mb-2 uppercase'>
          <p>Ranking {ranking.name}</p>
        </div>
        <div style={this.getBackground()} className="background-blue">
          <div className='px-5 py-6'>
          <h2 style={{color: 'white'}} className='ranking-text '>A minha Posição</h2>
          <p style={{color: 'white'}} className='ranking-text'>Atualização {this.getDate()}</p>
          </div>
          <div className='px-5 pt-3 pb-5 flex between-xs between-md between-lg items-end ranking-scale'>
          { list.map((item,i) => {
              return <RankingBar campaign={campaign} ranking={ranking} key={i} multiplier={item} />
          }
          )}
          </div>
        </div>
      </div>
    )
  }
}

export default RankingHome;