import React from 'react'
import CustomSelect from '../../shared/CustomSelect'
const ProductOptions = ({campaign, optionState, optionActions, defaultValues}) => {
  const { firstTaxon, secondTaxon, thirdTaxon } = optionState
  const { changeFirstTaxons, changeSecondTaxons, changeThirdTaxons} = optionActions
  const { selectedFirstTaxon, selectedSecondTaxon, selectedThirdTaxon } = defaultValues
  return (
   <div className="row between-xs pb-4">
      <CustomSelect 
         isClearable
         campaign={campaign} 
         onChange={e => changeFirstTaxons(e) }
         options={firstTaxon} 
         getOptionLabel={(option) => option.name} 
         defaultValue={selectedFirstTaxon} 
         getOptionValue={(option) => option.id} 
         placeholder='Prémios' className='col-sm-4 col-xs-12 ' 
         classNamePrefix='custom-select'/>
      <CustomSelect 
         isClearable
         campaign={campaign} 
         onChange={e => changeSecondTaxons(e) } 
         options={secondTaxon} 
         getOptionLabel={(option) => option.name} 
         defaultValue={selectedSecondTaxon} 
         getOptionValue={(option) => option.id} 
         placeholder='Categorias' 
         className='col-sm-4 col-xs-12 ' 
         classNamePrefix='custom-select'/>
      <CustomSelect 
         isClearable 
         campaign={campaign}
         onChange={e => changeThirdTaxons(e) }
         options={thirdTaxon} 
         getOptionLabel={(option) => option.name} 
         defaultValue={selectedThirdTaxon} 
         getOptionValue={(option) => option.id} 
         placeholder='Sub-categorias' 
         className='col-sm-4 col-xs-12 ' 
         classNamePrefix='custom-select'/>
   </div>
)
}

export default ProductOptions