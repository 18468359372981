import React from "react"
import { Link } from "react-router-dom"

const PasswordRecovery = ({
  client_logo: ClientLogo,
  client,
  recoverPassword
}) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
        <div className="py-10">
          <ClientLogo src={client.logo.url} />
        </div>
        <form className="pb-10" onSubmit={recoverPassword}>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="template-blue"
              placeholder="Email | Login"
              required
              type="text"
              name="emailOrLogin"
            />
          </div>
          <div className="row center-lg center-xs center-md py-5">
            <button
              style={{ width: "290px" }}
              className="button-cart button-primary text-xl"
              type="submit"
              value="Submit"
            >
              Pedir nova password
            </button>
          </div>
        </form>
        <Link className="link pb-20" style={{ color: "#008000" }} to={"/login"}>
          Voltar para login
        </Link>
      </div>
    </div>
  )
}

export default PasswordRecovery
