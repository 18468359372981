const initialState = {
  supportingDocuments: [],
  supportingDocumentsOfType: []
}

const supportingDocumentsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_SUPPORTING_DOCUMENTS':
    
      return {
        ...state,
        supportingDocuments: action.payload.document_types
      }
    
    case 'SAVE_SUPPORTING_DOCUMENTS_OF_TYPE':
    return {
      ...state,
      supportingDocumentsOfType: action.payload.document_pages
    }
    
    default:
      return state;
  }
}
export default supportingDocumentsReducer;