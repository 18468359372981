import React from 'react'

const EmptyStateLayout = ({title, message, children}) => {
  return (
    <div className="g-round flex-1 navbar-text default-bg flex flex-col items-center">
      {title &&<h2 className="vodafone-bold feed-header">{title}</h2>}
      {children}
      <p className="mb-4 vodafone-bold">{message}</p>
    </div>
  )
}

export default EmptyStateLayout
