import React, { Component } from 'react';
import AddressForm from './AddressForm';
import { connect } from 'react-redux';
import { AddressesService } from '../../../services'
import { saveAddresses } from '../../../actions/addressesActions';
import { startFetch, endFetch } from '../../../actions/generalActions';
import { ToastStore } from 'react-toasts';

class Address extends Component{

  constructor(props){
    super(props);
    this.state={editable: this.props.editable};
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(){
    this.setState(state => ({
      editable: !state.editable
    }));
  }
  destroyAddress(e){
    this.props.startFetch();
    const payload = { id: this.props.address.id }
    AddressesService.deleteAddress(payload)
      .then((response) => {
        this.props.saveAddresses(response.data);
        ToastStore.success('Morada Eliminada');
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.warning('Impossivel de Eliminar Morada')
        this.props.endFetch();
      });
  }

  render(){
    const color_primary = this.props.campaign.color_primary
    const address = this.props.address
    if (this.state.editable) {
      return(
        <AddressForm edit={true} address={address }campaign = {this.props.campaign} handleClick={this.handleClick} />
      )
    } else{
      return(
        <div className='flex template-address mt-6 mb-6 pt-5 pb-5 pl-8 pr-8'>
          <div className='w-1/3 template-address-title' >
            <h4 style={{color: color_primary}} className='template-address-title-text'>Morada{address.main_name}</h4>
          </div>
          <div className='w-1/2 text-break'>
            <p style={{color: color_primary}} className='template-address-blue'>{address.address1},{address.address2}</p>
            <p className='template-address-gray font-quicksand-light'>{address.city},{address.country.name}</p>
            <p className='template-address-gray font-quicksand-light'>{address.zip_code}</p>
          </div>
          <div className='flex w-1/6 between-xs'>
            <p style={{color: color_primary, cursor: 'pointer'}} onClick={this.handleClick} className='line-height-80 template-link-blue'>Editar</p>
            <i onClick={ (e) => this.destroyAddress(e) } className="fas fa-trash template-address-trash "></i>
          </div> 
        </div>
      ) 
    }
  }
  static defaultProps = {
    editable: false
  } 
}
function mapDispatchToProps(dispatch){
  return{
    saveAddresses: (addresses) => {
      dispatch(saveAddresses(addresses))
    },
    startFetch: () => { dispatch(startFetch()) },
    endFetch: () => { dispatch(endFetch()) }
  }
}
export default connect(null,mapDispatchToProps)(Address);
