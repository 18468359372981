import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BalanceService } from '../../../services';
import { saveBalance, saveHistory } from '../../../actions/balanceActions';
import {ToastStore, ToastContainer} from 'react-toasts';
import { buildTransaction, startFetch } from '../../../actions/generalActions';
import Moment from 'moment'

export class BalanceContainer extends Component {
  constructor(props){
    super(props)
    this.handleChangeTimePeriod = this.handleChangeTimePeriod.bind(this)
    this.handlePreviousMonth = this.handlePreviousMonth.bind(this)
    this.handleNextMonth = this.handleNextMonth.bind(this)
    this.handleChangeMonth = this.handleChangeMonth.bind(this)
    this.handleChangeYear = this.handleChangeYear.bind(this)

  }
  state = {
    page: 1,
    selectMonth: undefined,
    selectYear: undefined,
    timePage: 0,
    selectedTime: '1month',
    selectedDate: new Moment()
  }

  fetchUserBalance() {
    const {
      user,
      campaign
    } = this.props

    const payload = {
      clientSlug: user.client.slug,
      campaignId: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(()=>
      BalanceService.getUserBalance(payload).then((response) => {
        const balance = response.data
        this.props.saveBalance(balance);
      })
    )
  }

  requestUserProduction(){
    const {
      user,
      campaign
    } = this.props

    const payload = {
      clientSlug: user.client.slug,
      campaignId: campaign.slug,
    }

    this.props.startTransaction(() =>
    BalanceService.requestUserProduction(payload)
      .then((response) => {
        const APIresponse = response.data
        ToastStore.success(APIresponse.message)
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido')
      })
    );
  }

  handleChangeMonth(month){
    const  monthId = month.id
    let newSelectedDate = this.state.selectedDate.month(monthId)
    this.setState({
      timePage: 0,
      selectedDate: newSelectedDate
    }, () => this.requestUserHistory()
    )
  }

  handleChangeYear(year){
    const yearId = year.id
    let newSelectedDate = this.state.selectedDate.year(yearId)
    this.setState({
      timePage: 0,
      selectedDate: newSelectedDate
    }, () => this.requestUserHistory()
    )
  }

  handlePreviousMonth(){
    let newTimePage = this.state.timePage - 1
    this.setState({ timePage: newTimePage   },
      () => this.requestUserHistory())
  }

  handleNextMonth(){
    let newTimePage = this.state.timePage + 1
    this.setState({ timePage: newTimePage },
      () => this.requestUserHistory())
  }

  handleChangeTimePeriod(timePeriod){
    this.setState({ selectedTime: timePeriod, timePage: 0 },
      () => this.requestUserHistory())
  }

  requestUserHistory(){
    const {
      user,
      campaign
    } = this.props

    const { 
      selectedTime,
      timePage,
      selectedDate
    } = this.state

    const payload = {
      clientSlug: user.client.slug,
      campaignId: campaign.slug,
      selectedTime: selectedTime,
      timePage: timePage,
      date: selectedDate
    }
    
    this.props.startTransaction(() =>
    BalanceService.getUserHistory(payload)
      .then((response) => {
        const APIresponse = response.data
        this.props.saveHistory(APIresponse)
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido')
      })
    );
  }

  handleClickUserProduction = () => {
    this.requestUserProduction();
  }

  handlePageChange = (data)=> {
    this.setState({page: data.selected +1 },()=> this.fetchUserBalance())
  }

  componentDidMount(){
    this.fetchUserBalance()
    this.requestUserHistory()
  }
  render() {
    const { selectedTime, selectedDate } = this.state
    const {component: Component, ...rest} = this.props
    return (
      <>
        <ToastContainer store ={ToastStore} />
        <Component
          {...rest}
          onPageChange={this.handlePageChange}
          onClickUserProduction={this.handleClickUserProduction}
          selectedTime = {selectedTime}
          selectedDate = {selectedDate} 
          onChangeTimePeriod={this.handleChangeTimePeriod}
          onChangeNextMonth={this.handleNextMonth}
          onChangePrevMonth={this.handlePreviousMonth}
          page = {this.state.page}
          onChangeMonth={this.handleChangeMonth}
          onChangeYear={this.handleChangeYear}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  points: state.balanceReducer.points,
  pagination: state.balanceReducer.pointsPagination,
  historyPoints: state.balanceReducer.historyPoints,
  historyMonths: state.balanceReducer.historyMonths,
  historyRange: state.balanceReducer.historyRange
})

const mapDispatchToProps =(dispatch) => ({
  saveBalance: (balance) => { dispatch(saveBalance(balance))},
  saveHistory: (history) => { dispatch(saveHistory(history))},
  startTransaction: buildTransaction(dispatch)
})

const Container =  connect(mapStateToProps, mapDispatchToProps)(BalanceContainer)

export default Container


export function withBalanceContainer(component){
  return (props)=> (
    <Container component={component} {...props}/>
  )
}
