import { func } from 'prop-types';
import React, {useEffect, useState } from 'react';
import { event } from 'react-ga';

const Popup  = ({ Condition, Response, isOpenValue, Title, clearVariables, CampaignCards, Selected, ToExcute, Value, Value_, ToChangeValue, ToChangeValue_, EventType})=> {

  const [isOpen, setIsOpen] = useState(isOpenValue);
  const [wrongAtempt, setWrongAtempt] = useState(false);
  const [currentText, setCurrentText] = useState("");

  const [Comfirmed, setComfirmed] = useState(false);


    useEffect(() => {
      check()
    }, []);
  

    function ChangeValue(input)
    {
      ToChangeValue(input)
    }

    function Comfirm()
    {
      if(EventType=="Register")
      {
        ToExcute();



        WaitToClose()
      }
      else
      {
        if(currentText == Selected.number)
        {
          ToExcute();



          WaitToClose()
        }
        else{
          setWrongAtempt(true)
        }
      }
    }

    function Close()
    {
      Condition(false);
      Selected = null;
      clearVariables();
      
      window.location.reload() 
    }
    function WaitToClose()
    {
      setComfirmed(true);
    }

    function check()  {
      try{var input = document.querySelectorAll('input');
      for(var i = 0 ; i < input.length; i++){
        input[i].setAttribute('size',input[i].getAttribute('placeholder').length);
      }}
      catch{}
    }


   
    function getTemplateToCharge(){
      return <>
        {Title && <h2 style={popupTitle}>{Title}</h2>} 
          <div style={popupBody} >
            <div className="content" dangerouslySetInnerHTML={{__html: Selected.card_type.agreement_text}}></div>
            {(Response=='0' || Response=='-1') && <h3 style={subtitle}> Confirme o cartão a carregar:</h3>}
            {(Response=='0' || Response=='-1') && wrongAtempt && <h5 style={{marginTop:'15px'}}>Número do cartão está errado!</h5>}
            {(Response=='0' || Response=='-1') && <input style={{width:'100%',marginTop:'10px',borderRadius:'5px',backgroundColor:'#EBEBEB',height:'26px',fontSize:'14px',margin:'5px'}} type="text" id="location" placeholder={Selected.card_type.number_label} value={currentText} onChange={(e)=>setCurrentText(e.target.value)}/>}     
            {(Response=='0' || Response=='-1') && <select id="Card" name="breed" value={Value} className='appearance-none' style={{width:'100%',height:'26px',borderRadius:'5px', backgroundColor:'#EBEBEB',margin:'5px'}} onChange={e=>ToChangeValue(e.target.value)}>
            <option disabled={true} value="">Carregamentos disponíveis </option>
            {Selected.recharge_options.map(card => (
              <option value={card.value}> Carregamento {card.value}€ - {card.points} pontos </option>
            ))}
            </select>}
          </div>
        </>
    }

    function getTemplateToCancel(){
      return <>
          {Title && <h2 style={popupTitle}>{Title}</h2>} 
          <div style={popupBody}>
            <div className="content" dangerouslySetInnerHTML={{__html: Selected.card_type.agreement_text}}></div>
            <h3 style={subtitle}> Confirme o cartão a cancelar:</h3>
            {wrongAtempt && <h5 style={{marginTop:'15px'}}>Número do cartão está errado!</h5>}
            <input style={{width:'100%',marginTop:'10px',borderRadius:'5px',backgroundColor:'#EBEBEB',height:'26px',fontSize:'14px'}} type="text" id="location" placeholder={Selected.card_type.number_label}  value={currentText} onChange={(e)=>setCurrentText(e.target.value)}
/>
          </div>
        </>
    }

    function getTemplateToRegister(){
      return <> 
          <div style={popupBody} >
            {Title && <h2 style={popupTitle}>{Title}</h2>} 
            <div className="content" dangerouslySetInnerHTML={{__html: Value_ ?  CampaignCards.find(e => e.card_type.name == Value_).card_type.agreement_text : ""}}></div>
            
            
            <h3 style={subtitle}> Insira o número do cartão para registar e o tipo de cartão:</h3>
            {wrongAtempt && <h5 style={{marginTop:'15px'}}>Cartão não existe!</h5>}
            <input style={{width:'100%',marginTop:'10px',borderRadius:'5px',backgroundColor:'#EBEBEB',height:'26px',fontSize:'14px'}} type="text" id="location" placeholder={Value_ ?  CampaignCards.find(e => e.card_type.name == Value_).card_type.number_label : ""} value={Value} onChange={(e)=>ChangeValue(e.target.value)}/>       
            <select className='appearance-none' id="Card" name="CardsType" value={Value_} style={{width:'100%',marginTop:'10px'}} onChange={e=>ToChangeValue_(e.target.value)}>
              <option disabled={true} value="">Tipos de cartões</option>
                {CampaignCards.map(card => (
                  <option value={card.card_type.name}>{card.card_type.name}</option>
                 ))
                }
            </select>
          </div>
        </>
    }




    
    return (
      <div style={isOpen ? {...popupStyles, ...popupOpenStyles} : popupStyles}>
        <div style={popupContentStyles}>
          {EventType=="Charge" ? getTemplateToCharge() :  EventType=="Register" ? getTemplateToRegister() :  getTemplateToCancel()}
           {Response=='200' && Comfirmed && <h5> Efetuado com sucesso! </h5>}
           {Response!='200' && Comfirmed && <h5> Não foi possivel efetuar a operação com sucesso! </h5>}
           {(Response=='0' || Response=='-1')  && <button style={popupCloseBtnStyles} className='btn-secondary px-4 py-1' onClick={Comfirm}>Confirmar</button>}
          {(Response=='0' || Response=='-1')  && <button style={popupCloseBtnStyles} className='btn-secondary px-4 py-1' onClick={Close}>Cancelar</button>}
          {Response=='200' && Comfirmed && <button style={popupCloseBtnStyles} className='btn-secondary px-4 py-1' onClick={Close}>OK</button>}
        </div>
      </div>
    );
  };
  
  export default Popup;




//#region Styles

const popupStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0, 0.7)',
  zIndex: 999,
  opacity: 0,
  display: 'none',
};

const popupOpenStyles = {
  opacity: 1,
  display: 'block'
};

const popupContentStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '70%',
  minWidth: '30%',
  backgroundColor: 'white',
  padding: '20px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
  borderRadius: '8px',
  textAlign:'center'
};

const popupBody = {
  textAlign:'left',
  display: 'inline-block',
  justifyContent: 'center',
  width:'90%'
};

const popupTitle = {
  marginTop: '10px',
  marginBottom: '30px'
};

const subtitle = {
  marginTop: '10px',
  marginBottom: '10px'
};


const popupCloseBtnStyles = {
  fontSize: '20px',
  minWidth:'10%',
  borderRadius:'5px',
  margin: '20px',
  marginBottom: '10px',
  height: '38px',
  width: '160px',
  borderRadius: '10px'
};
//#endregion