import React from 'react'


const SaleRow = (props) => {
  const {
    employee_identifier : employeeIdentifier,
    description, product, date,
    points
  } = props
  
  return (
    <tr className="gl-sales__row dead-grey ">
      <td className="p-2">{employeeIdentifier}</td>
      <td className="text-sm sm:text-base p-2">{description}</td>
      <td className="p-2">{product.external_identifier}</td>
      <td className="p-2 text-right">{points}</td>
      <td className="p-2 whitespace-no-wrap">{date}</td>
    </tr>
  )
}

const SalesTable = ({sales}) => {
  const currency =  window.localStorage.getItem('currency') || 'pts'

  return (
    <div className='overflow-x-auto'>
      <table className='w-full'>
        <thead className="gl-sales__row dark-grey ">
          <tr>
            <th className="p-2 text-left">Jogador</th>
            <th className="p-2 text-left">Descrição</th>
            <th className="p-2 text-left">Produto</th>
            <th className="p-2 text-right">{currency}</th>
            <th className="p-2 text-left">Data</th>
          </tr>
        </thead>
        <tbody>
        {
          sales.map(sale => (
            <SaleRow key={"sale" + sale.id} {...sale}/> 
          ))
        }
        </tbody>
      </table>
    </div>
  )
}

export default SalesTable
