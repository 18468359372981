import React from 'react'
import KeyValue from '../../shared/KeyValue';
import InputKeyValue from '../../shared/InputKeyValue';
import KeyDrodownValue from '../../shared/KeyDropdownValue'

const UserInfoEdit = (props) => {
  const { onEditSubmit, onChangeGender, ...user} = props

  const handleSubmit = (e) => {
    e.preventDefault()
    const {
      firstName ,
      lastName,
      phone,
      email,
      nif
    } = e.target

    onEditSubmit({
      first_name: firstName.value ,
      last_name: lastName.value,
      phone: phone.value,
      email: email.value,
      nif: nif.value
    })
  }

  const options = [
    {
      id: "female",
      name: "Feminino"
    },
    {
      id: "male",
      name: "Masculino"
    }
  ]

  let gender = user.gender
  if ( gender !== undefined) {
    gender = options.find(e => user.gender === e.id)
  }
  return (
    <form onSubmit={handleSubmit} id="user-update-form" className="gl-profile__data-container mb-10">
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title={'Identificador'}
            value={user.login}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Primeiro nome'}
            defaultValue={user.first_name}
            name="firstName"
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Último nome'}
            name="lastName"
            defaultValue={user.last_name}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Email'}
            name="email"
            defaultValue={user.email}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Telefone/Telemóvel'}
            name="phone"
            defaultValue={user.phone}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding mb-4">
          <KeyDrodownValue
            title="Género"
            defaultValue={gender}
            options={options}
            onChange={onChangeGender}
            placeholder="Género"
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
            />
        </div>
      </div>
      <div className="row g-profile__info-data g-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'NIF'}
            name="nif"
            defaultValue={user.nif}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
    </form>
  )
}

export default UserInfoEdit
