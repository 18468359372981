import React from 'react'
import {Link} from 'react-router-dom'

const CustomLink = ({outline, primary, small, medium, large, secondary, right, cart, className, ...rest}) => {
  const buttonClass =  [
    'gl-profile__button gl-content-shadow',
    outline && 'gl-profile__button--border border-primary primary-text',
    right &&'gl-profile__button--right',
    primary &&'primary-bg navbar-text',
    secondary &&'white primary-text',
    cart && 'gl-profile__button--cart',
    small && 'gl-profile__button--small',
    medium && 'gl-profile__button--medium gl-profile__button--secondary',
    large && 'gl-profile__button--large',
    className
  ].join(' ')

  return (
    <Link className={buttonClass} {...rest}/>
  )
}

export default CustomLink