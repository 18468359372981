export function savePendingTrades(pendingSaleTrades,pagination) {
  return {
    type: 'SAVE_PENDING_TRADES',
    payload: {
      pendingSaleTrades,
      pagination
    }
  }
};

export function updateSaleTrade(state) {
  return {
    type: 'UPDATE_SALE_TRADE',
    payload: state
  }
}
export const saveRequestedTrades = (requested, pagination) => ({
  type: 'SAVE_REQUESTED_TRADES',
  payload: {
    requested: requested,
    requestedPagination: pagination
  }
})
export const moveToRequested = (sale) => ({
  type: 'MOVE_TO_REQUESTED_SALES',
  payload: {
    sale
  }
})
