import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import {Switch, Redirect,Route} from 'react-router-dom'
import RankingSelector from './RankingSelector';
import {t} from '../../../i18n'
import ContentLayout from '../shared/ContentLayout';
import { withRankingsContainer } from '../normalizer'
import SelectedRankingWrapper from './SelectedRankingWrapper';

const Rankings = props => {
  const {
    campaign,
    rankings,
    selectedRanking,
    user
  } = props
  const firstRanking = rankings[0]

  return (
    <ContentLayout campaign={campaign} title={t('ranking.title')}>
      <RankingSelector
        user = {user}
        selectedRanking = {selectedRanking}
        campaign={campaign}
        rankings={rankings}
        />
      <Switch>
        {firstRanking && <Redirect exact from="/rankings" to={`/rankings/${firstRanking.id}`} /> }
        <Route path= "/rankings/:id">
          <SelectedRankingWrapper
            campaign={campaign}
            user= {user}
            rankings={rankings}
          />
        </Route>
      </Switch>
    </ContentLayout>
  );
}
export default withRankingsContainer(Rankings);