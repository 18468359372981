import React from 'react'

import CustomSelector from '../shared/CustomSelector'
import CustomCheckbox from '../shared/CustomCheckbox'

const SideProductFilter = ({campaign, filterState, filterActions}) => {
  const {
    affordableProducts, 
    highlighted, 
    moreViews, 
    alphabeticalOrder,
    rangeFilters
  } = filterState;
  const {
    handleAffordableProducts,
    handleAlphabeticalOrder,
    handleHighlighted,
    handleMoreViews,
    handleRangeClick,
    handleClearFiltersClick
  } = filterActions;

  return (
    <>
      <div style={{backgroundColor: campaign.color_primary }} className='template-info mb-2'>
        <a className="clear-filters mr-3" onClick={() => handleClearFiltersClick()}>limpar filtros</a>
        <h2 className="header-title">Filtros</h2>
      </div>
      <div className='default-container py-3 filters-container'>
        <CustomSelector 
          handleClick = {(e) => handleAffordableProducts(e) } 
          campaign={campaign} 
          text='Apenas produtos que posso comprar'
          selected={affordableProducts}
          />
        <CustomSelector 
          handleClick = {(e) => handleHighlighted(e) } 
          campaign={campaign} 
          text='Destaques'
          selected={highlighted}
          />
        <p className='mt-2 p-3'>Por pontos</p>
        {
          rangeFilters.map((rangeFilter) => {
            return (
              <div className="px-3 py-1" key={rangeFilter.rangeFilter.id}>
                <label>
                  <CustomCheckbox
                    campaign={campaign} 
                    checked={rangeFilter.active}
                    onChange={(e) => handleRangeClick(rangeFilter.rangeFilter.id, e)}
                  />
                  <span className="ml-2 dark-gray">{rangeFilter.label}</span>
                </label>
              </div>
            );
          })
        }
      </div>
      <div style={{backgroundColor: campaign.color_primary }} className='template-info mt-3 mb-2'>
        <p className="uppercase">Ordenar</p>
      </div>
      <div className='default-container py-3 filters-container'>
        <CustomSelector 
          handleClick = {(e) => handleMoreViews(e) } 
          campaign={campaign} 
          text='Ordenar pelos mais vistos'
          selected={moreViews}
          />
        <CustomSelector 
          handleClick = {(e) => handleAlphabeticalOrder(e) } 
          campaign={campaign} 
          text='Orderar de Z-A'
          selected={alphabeticalOrder}
          />
      </div>
    </>
  )
}

export default SideProductFilter