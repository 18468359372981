const initialState = {
  ranking: {},
  rankings: [],
  selectedRanking: {id: null},
  selectedRankingWinners: [],
  selectedRankingUserPosition: { id: null}
}

const rankingsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'CLEAR_RANKINGS':
    return {
      ...state,
      selectedRanking: {id: null},
      selectedRankingWinners: [],
      selectedRankingUserPosition: { id: null}
    }
    case 'SAVE_RANKING':
      return {
        ...state,
        ranking: action.payload.ranking
      }
    case 'SAVE_RANKINGS':
      return {
        ...state,
        rankings: action.payload.rankings
      }
    case 'SAVE_SELECTED_RANKING':
      return {
        ...state,
        selectedRanking: action.payload.ranking
      }
    case 'SAVE_SELECTED_POSITIONS':
      return {
        ...state,
        selectedRankingWinners: action.payload.results,
        selectedRankingUserPosition: action.payload.user_position
      }
    default:
      return state;
  }
}
export default rankingsReducer;
