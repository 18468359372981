import React, { Component } from "react"
import { connect } from "react-redux"
import {
  SaleTradeService,
  buildTransaction,
  savePendingTrades,
  updateSaleTrade,
  saveCampaignUser
} from "../../normalizer"
import { HOC } from "../../gamification"
import { ToastContainer, ToastStore } from "react-toasts"

class PendingTradesContainer extends Component {
  state = {
    page: 1,
    isTradeSelected: false,
    saleTrade: undefined
  }

  handlePageChange = data => {
    this.setState({ page: data.selected + 1 }, () =>
      this.getPendingSaleTrades()
    )
  }

  getPendingSaleTrades() {
    const { user, campaign } = this.props

    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(() =>
      SaleTradeService.getPendingSaleTrades(payload).then(response => {
        const pendingTrades = response.data.sales_solicited
        const pagination = response.data.pagination
        this.props.savePendingTrades(pendingTrades, pagination)
      })
    )
  }

  preparePayload(id) {
    const { user, campaign } = this.props

    return { clientSlug: user.client.slug, campaignSlug: campaign.slug, id: id }
  }

  handleAcceptSaleTrade = saleId => {
    const payload = this.preparePayload(saleId)
    this.props.startTransaction(() =>
      SaleTradeService.acceptSaleTrade(payload)
        .then(response => {
          const saleTrade = response.data.sale_solicited
          const campaignUser = response.data.campaign_user
          this.props.updateSaleTrade(saleTrade)
          this.props.saveCampaignUser(campaignUser)
        })
        .catch(error => {
          ToastStore.error(error.response.data.error)
        })
    )
    this.unToggleAcceptModal()
  }

  handleRejectSaleTrade = saleId => {
    const payload = this.preparePayload(saleId)
    this.props.startTransaction(() =>
      SaleTradeService.rejectSaleTrade(payload)
        .then(response => {
          const saleTrade = response.data.sale_solicited
          this.props.updateSaleTrade(saleTrade)
        })
        .catch(error => {
          ToastStore.error(error.response.data.error)
        })
    )
  }

  componentDidMount() {
    if (this.props.pendingSaleTrades.length === 0) {
      this.getPendingSaleTrades()
    }
  }

  toggleAcceptModal = (saleTrade) => {
    this.setState({
      isTradeSelected: true,
      saleTrade
    })
  }

  unToggleAcceptModal = () => {
    this.setState({
      isTradeSelected: false,
      saleTrade: undefined
    })
  }

  render() {
    const { component: Component, ...rest } = this.props
    return (
      <>
        <ToastContainer store={ToastStore} />
        <Component
          {...rest}
          {...this.state}
          onRejectSaleTrade={this.handleRejectSaleTrade}
          onAcceptSaleTrade={this.handleAcceptSaleTrade}
          onToggleAcceptModal={this.toggleAcceptModal}
          onUnToggleAcceptModal={this.unToggleAcceptModal}
          onPageChange={this.handlePageChange}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    pendingSaleTrades: state.saleTradeReducer.pendingSaleTrades,
    pendingPagination: state.saleTradeReducer.pendingPagination
  }
}

function mapDispatchToProps(dispatch) {
  return {
    savePendingTrades: (pendingTrades, pagination) => {
      dispatch(savePendingTrades(pendingTrades, pagination))
    },
    updateSaleTrade: saleTrade => {
      dispatch(updateSaleTrade(saleTrade))
    },
    saveCampaignUser: campaignUser => {
      dispatch(saveCampaignUser(campaignUser))
    },
    startTransaction: buildTransaction(dispatch)
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingTradesContainer)

export function withPendingTradesContainer(component) {
  const withPendingTradesContainer = HOC(Container, component)
  return withPendingTradesContainer
}
export default Container
