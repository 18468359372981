import React, { Component } from 'react';
import Group from './Group';

class ShowGroups extends Component{
  
  render(){
    const {
      jobs
    } = this.props
    return(
      <div className="template-block between-xs text-left">
        <h2 className='pb-10 template-title'>Ponto de Vendas</h2>
        { 
          jobs.map((job,i) => {
            return <Group key={i} group = {job.group} keys = {i} />
          })
        }
      </div>
      ) 
  }
}

export default ShowGroups;