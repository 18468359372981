import React from "react"
import Select from 'react-select';
import ReactPaginate from "react-paginate"
import { prefix, t } from "../../../../i18n"
import Moment from "moment"
import TableHeaderLayout from "../TableHeaderLayout"
import CustomSelect from "../../../shared/CustomSelect"

const tAttribution = prefix("attributions")
const tSaleState = prefix("sale_state")
const PendingActions = ({
  saleAttribution,
  enabled,
  users,
  onUserChange,
  onAccept
}) => {

  var selectedUser = undefined
  var selectedInput = React.createRef();

  const css = () => {
    if (!saleAttribution.processing) {
      return "text-green-dark text-center cursor-pointer"
    } else {
      return "text-red-dark text-center cursor-pointer"
    }
  }

  const innerElement = () => {
    if (!saleAttribution.processing) {
      return (
        <><i className="fas fa-check" /> <span className="font-quicksand">Aceitar</span></>
      )
    } else {
      return (
        "Em processamento"
      )
    }
  }

  const handleOnAccept = (e) => {
    if (!saleAttribution.processing) {
      onAccept(saleAttribution, selectedUser)
    }
  }

  const handleOnUserChange = e => {
    selectedUser = e
  }

  return (
    <>
      <Select className="col-xs-3 text-xs" options={getUserOptions(users)} onChange={handleOnUserChange} />
      <span className="col-xs-2 text-center">
        <span
          ref={ input => { selectedInput = input; }}
          onClick={handleOnAccept}
          className={css()}
        >
          {innerElement()}
        </span>
      </span>
    </>
  )
}

const userOption = function( user) {
  return { id: user.id, value: user.id, label: user.employee_identifier }
}

const getUserOptions = function( users) {
  return users.map(userOption)
}

const getContractNumber = function( saleAttribution) {
  const ef = saleAttribution.extra_fields.find(ef => ef.name.toLowerCase() === 'contractnumber')
  return ef ? ef.value : ''
}

const Row = ({ saleAttribution, users, key, getUserOptions, onAccept }) => {

  return (
    <div className="row user-header font-quicksand-light text-left table-text items-center py-3">
      <span className="col-xs-2 ">{new Moment(saleAttribution.date).format(t("date-format"))}</span>
      <span className="col-xs-5 text-xs">{ getContractNumber(saleAttribution) }</span>
      <PendingActions
        saleAttribution={saleAttribution}
        users={users}
        onAccept={onAccept}
      />
    </div>
  )
}

const SaleTable = ({
  attributions,
  users,
  onAccept,
  page,
  pagination,
  onPageChange
}) => {

  return (
    <>
      <TableHeaderLayout>
        <span className="col-xs-2 text-left">{tAttribution("date")}</span>
        <span className="col-xs-5 text-left ">{tAttribution("contractnumber")}</span>
        <span className="col-xs-3 text-left ">{tAttribution("user")}</span>
        <span className="col-xs-2 text-center">Ações</span>
      </TableHeaderLayout>
      <div>
        {attributions.map(attribution => (
          <Row
            saleAttribution={attribution}
            users={users}
            key={`sale-attribution-table-span-${attribution.id}`}
            getUserOptions={getUserOptions}
            onAccept={onAccept}
          />
        ))}
      </div>
      {pagination.total_pages > 1 && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pagination.total_pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          initialPage={page - 1}
          onPageChange={onPageChange}
          containerClassName={"pagination my-5"}
          subContainerClassName={"pages"}
          activeClassName={"active-block"}
          forcePage={page - 1}
        />
      )}
    </>
  )
}

export default SaleTable
