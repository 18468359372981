import React from "react"
import Slider from "./Slider"
import PostSaleProduct from "./PostSaleProduct";
import PostSaleProductPlaceholder from "./PostSaleProductPlaceholder";


const uuid = require('uuid/v1')

const SaleSlider = ({ sales, backgroundColor, textColor, borderColor, numOfProducts }) => {
  const colors = { backgroundColor, textColor, borderColor}
  
  const placeholderProducts = []
  for( var i=0; i < numOfProducts - sales.length; i++){  
    placeholderProducts.push(<PostSaleProductPlaceholder colors = {colors} key={i}/>)
  }

  return (
    <Slider arrowColor={backgroundColor} sliderId={uuid()} direction = {'start'}>
      { sales.map(sale => {
          return (
            <PostSaleProduct saleProduct={sale} colors = {colors} key={sale.id}/>
          )
        })
      }
      {
        placeholderProducts
      }
    </Slider>
  )
}

export default SaleSlider
