import React, { Component } from 'react';
import { prefix } from '../../../i18n';
import { connect } from 'react-redux';
import '../../../assets/css/slider.scss';
import styled from 'styled-components';
import CartWarning from './CartWarning'

const tCartCheckout = prefix("cart-checkout")

class CartSuccess extends Component{

  getActiveStyle(){
    const  {
      text_color,
      navbar_text_color
    } = this.props.campaign
    return styled.div`
      color: ${navbar_text_color} !important;
      background: ${text_color}
      :before{
        border-left: 25px solid ${text_color};
      }
    ` 
  }
  getSpan(){
    const {
      color_primary, 
      navbar_text_color
    } = this.props.campaign
    
    const primary = styled.button`
      color: ${navbar_text_color};
      background: ${color_primary};
      border: 2px solid ${color_primary};
      padding: 5px 20px;
    `
    return primary
  }
  
  goToOrders = () => {
    this.props.history.push('/profile/orders')
  }
  
  render() {
    const { campaign, campaignUser } = this.props
    const ActiveDiv = this.getActiveStyle();
    let  SpanPrimary = this.getSpan();

    const hasPaymentInformation = (!!campaignUser.entity && !!campaignUser.reference && !!campaignUser.value)

    return (
      <div>     
        <div className='font-quicksand' style={{padding: '40px 0', backgroundColor: '#eaeaef'}}>
          <div className="cont flex start-lg start-md start-xs pb-2">
            <div className="col-lg col-md col-xs">
              <h1 style={{color: campaign.color_primary}} className="primary">CARRINHO DE COMPRAS</h1>
            </div>
          </div>
          <div className="cont row start-lg start-md start-xs pb-6">
            <div className="col-lg col-md col-xs">
              <hr className="hr-down-catalog"></hr>
            </div>
          </div>
          <div className="cont row start-lg start-md start-xs pb-6">
            <div className="col-xs-12">
              <div className='template cart-checkout-container'>
                <div className='w-95 m-auto'>
                  <div className='flex shopcart-navbar'>
                    <div className='w-1/3 cart-item'><p className='cart-item-text' >1. Encomenda</p></div>
                    <div className='w-1/3 cart-item'><p className='cart-item-text' >2. Dados de Entrega</p></div>
                    <ActiveDiv className='w-1/3 active-cart cart-item'><p className='cart-item-text'>3. Confirmação</p></ActiveDiv>
                  </div>
                
                  <div className='my-20 text-center'>
                    { hasPaymentInformation && (
                    <>
                    <h1 className="title_payment_data defaultLevel">Dados de Pagamento</h1>
                    <table className="table_payment_data defaultLevel">
                      <thead>
                        <tr>
                          <th className="table_payment_data_th defaultLevel"><strong>Entidade: </strong>{campaignUser.entity}</th>
                          <th className="table_payment_data_th defaultLevel"><strong>Referência: </strong>{campaignUser.reference}</th>
                          <th className="table_payment_data_th defaultLevel"><strong>Valor: </strong>{parseFloat(campaignUser.value).toFixed(2)} &euro;</th>
                          {campaignUser.validity ? <th className="table_payment_data_th defaultLevel"><strong>Validade (dias): </strong>{campaignUser.validity}</th> : null}
                        </tr>
                      </thead>
                    </table>
                    </>
                    )}
                    <h2 className="my-3 dead-grey">{tCartCheckout("checkout-successfully")}</h2>
                    <h2 style={{color: campaign.color_primary}} className='py-8 template-title'>{tCartCheckout("checkout-successfully")}</h2>
                    <p className='text-lg'>Consulte as <SpanPrimary onClick={this.goToOrders}>Encomendas</SpanPrimary> para verificar o estado da sua encomenda.</p>
                  </div>
                </div>
              </div>
            </div>
            <CartWarning text={campaign.call_attention} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer.campaign,
    campaignUser: state.balanceReducer.campaignUser,
  }
}
export default connect(mapStateToProps,null)(CartSuccess);
