import React from "react";
import Slider from "react-slick";
import { Chevron } from '../../../normalizer';

function GamificationNextArrow({ onClick }) {
  return (
    <Chevron
      className={`g-rankings__arrow g-rankings__arrow--next`}
      
      onClick={onClick}
    />
  );
}

function GamificationPrevArrow({ onClick }) {

  return (
    <Chevron
      className={`g-rankings__arrow g-rankings__arrow--left`}
      onClick={onClick}
    />
  );
}

const RankingSlider = ({ rankings }) => { 
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <GamificationNextArrow />,
      prevArrow: <GamificationPrevArrow />,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 7500,
      cssEase: "linear",
      appendDots: dots => (
        <div>
          <ul className='g-rankings__dots'> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <span className='g-rankings-dot'>.</span>
      )
    }
    if (rankings.length === 0){
      return <></>
    }
    
    return (

        <Slider {...settings} className={'text-color my-8 g-rankings__slider'}>
          {
            rankings.map((ranking,i) => {
            return(
              <div key={i} className='text-color vodafone text-xl  text-center mb-2'>
                  <p>{ranking.name}</p>
                  <p>
                    <span className='mr-1'>{ranking.is_winner ? ranking.winning_message : 'Posição'} </span>
                    <span className='text-color vodafone-bold text-3xl ml-1'>
                    {ranking.position !== 'N/A' ? `${ranking.position}º` : '-'}
                    </span>
                  </p>
              </div>
            )
            })
          }
        </Slider>
    );
}

export default RankingSlider;