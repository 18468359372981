import React from "react";
import Slider from "react-slick";
import Banner from "./Banner";
class BannerSlider extends React.Component {

  render() {
    const { data, borderColor, backgroundColor, showText } = this.props
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: "linear",
      appendDots: dots => (
        <div style={{bottom: '15px'}}>
          <ul className='custom-dots'> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <button className='custom-dot'
          style={{
            color: 'transparent',
            border: "2px " + borderColor + "solid",
            borderRadius: '50%',
            background: backgroundColor,
            width: '18px',
            height: '18px'
          }}
        >
        .
        </button>
      )
    }
    if (data.length === 0){
      return <div className='slide-container'></div>
    }
    
    return (
      <Slider {...settings} className='slide-container'>
        {
          data.map((banner,i) => {
          return(
            <div key={i}>
              <Banner showText={showText} banner={banner} />        
            </div>
          )
          })
        }
      </Slider>
    );
  }
}

export default BannerSlider;