import React from "react"
import CustomButton from "../../shared/CustomButton"
import { formatNumber } from "../../normalizer"

const AddProductToCar = props => {
  const { item, actions, currencyLabel } = props
  const { is_favorite } = item
  const heartClass = [
    "cursor-pointer text-3xl",
    !is_favorite && "far fa-heart primary-text",
    is_favorite && "fa fa-heart fav primary-text"
  ].join(" ")
  return (
    <div className="col-sm-2 col-xs-12 pt-8 mt-6  sm:mt-0 md:py-0 gl-product-detail__cart-container">
      <div className="flex justify-between px-3 sm:px-0 mb-5 items-center w-full">
        <div className="flex items-end justify-center px-2">
          <span className="gl-product-detail__points">
            {formatNumber(item.points)}
          </span>
          <span>{currencyLabel}</span>
        </div>
        <i
          className={heartClass}
          onClick={() =>
            item.is_favorite
              ? actions.onFavRemove(item)
              : actions.onFavAddition(item)
          }
        />
      </div>
      <CustomButton
        cart
        primary
        className="mb-5"
        onClick={() => actions.onCartAdd(item)}
      >
        Adicionar ao carrinho
      </CustomButton>
    </div>
  )
}

const ProductDetail = ({ main_image, description }) => {
  return (
    <div className="row col-xs-12 col-sm-10 gl-product-detail__content gl-product-detail__content--wide mt-5">
      <div className="col-xs-12 col-md-3 col-sm-4 pb-8 md:pb-0 flex justify-center m-auto">
        <img className="m-auto gl-product-detail__image"src={main_image.url} alt="ProductImage" />
      </div>
      <div
        className="px-5 col-xs-12 col-md-9 col-sm-8 gl-product-detail__description text-color"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

const Item = ({ item, currencyLabel, ...actions }) => {
  return (
    <div>
      <h3 className="gl-product-detail__title font-bold text-color">
        {item.product.name}
      </h3>
      <div className="gl-product-detail__content row">
        <ProductDetail {...item.product} />
        <div className="gl-catalog__vertical-line" />
        <AddProductToCar
          item={item}
          currencyLabel={currencyLabel}
          {...actions}
        />
      </div>
    </div>
  )
}

export default Item
