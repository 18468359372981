import React, {Component} from 'react'
import { connect } from 'react-redux'
import {saveAddresses, UserService, AddressesService, saveUser, buildTransaction } from '../normalizer'

import { isEmpty } from '../../../utils/utils';
import { prefix } from '../../../i18n';
import { ToastStore, ToastContainer } from 'react-toasts';

const tUser = prefix('user')
class CampaignUserContainer extends Component {
  state = {
    editingUserGeneralData : false,
    isAdding: false,
    editPassword: false,
    gender: {id: null},
    errors: {}
  }
  getAddresses(){
    this.props.startTransaction(() =>
      AddressesService.getAddresses().then((response) => {
        const addresses = response.data
        this.props.saveAddresses(addresses);
      })
    )
  }

  handleEditUserGeneralData = () => {
    this.setState({editingUserGeneralData: true})
  }

  handleEditCancel = () => {
    this.setState({editingUserGeneralData: false})
  }

  handleEditUserPassword = () => {
    this.setState({ editPassword: true })
  }

  handleEditPasswordCancel = () => {
    this.setState({ editPassword: false })
  }

  handleSubmitPassword = (payload) => {
    this.props.startTransaction( () =>
      UserService.updatePassword(payload)
      .then(response =>
        {
        this.setState({ editPassword: false })
        }
      ).catch((error) => {
        ToastStore.error('Ocorreu um erro com a atualização da password.')
        this.setState({ errors: error.response.data });
      }));
  }

  handleNewAddress = () => {
    this.setState({isAdding: true})
  }
  
  handleNewAddressCancel = () => {
    this.setState({isAdding: false})
  }

  handleNewAddressCreation = (formData) => {
    if (isEmpty(formData) ){
      return;
    }

    const payload = {
      address: {
        ...formData,
        zip_code: formData.zipCode,
        main_name: formData.addressName,
        first_name: formData.firstName,
        last_name: formData.lastName,
        state_id: this.props.selectedState.id,
        country_id: this.props.selectedCountry.id
      }
    }
  
    return this.props.startTransaction(() =>
      AddressesService.createAddress(payload)
        .then((response) => {
          this.props.saveAddresses(response.data);
          this.setState({isAdding: false, error: {}})
          formData.callBack()
        })
        .catch((error) => {
          this.setState({errors: error.response.data, isAdding: true});
        })
    )
  }

  onChangeGender = (e) => {
    this.setState({gender: e})
  }

  handleChangeAvatar = (file) => {
    if(!file) {
      ToastStore.error(tUser('unknown-format'));
      return
    }

    const formData = new FormData();
    
    formData.append('user[avatar]', file, file.name );
    this.props.startTransaction(()=>
      UserService.updateAvatar(formData).then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.props.saveUser(response.data.user);
        ToastStore.success('Avatar modificado com sucesso.');
      })
      .catch((error) => {
        ToastStore.error(tUser('image-error'));
      })
    )
  }

  handleSubmitUserEdit = (formData) => {

    const {gender} = this.state;
    let payload = {user: {...formData}}
    payload.user.gender  = gender.id

    if (isEmpty(payload.user)){
      return;
    }
    this.props.startTransaction(()=>
      UserService.updateUser(payload)
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data.user));
          this.props.saveUser(response.data.user);
          this.setState({editingUserGeneralData: false})
        })
    )
  }

  componentDidMount() {
    if(this.props.addresses.length === 0){
      this.getAddresses()
    }
    if (this.state.gender !== this.props.user.gender){
      this.setState({gender: {id: this.props.user.gender}})
    }
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
      <>
      <ToastContainer store={ToastStore}/>
      <Component {...rest}
          {...this.state}
        gender = {this.state.gender || this.props.user.gender}
        onEditGeneralData={this.handleEditUserGeneralData}
        onEditCancel={this.handleEditCancel}
        onEditSubmit={this.handleSubmitUserEdit}
        onNewAddressEnable={this.handleNewAddress}
        onNewAddressCreation={this.handleNewAddressCreation}
        onNewAddressCancel={this.handleNewAddressCancel}
        onEditPassword={this.handleEditUserPassword}
        onEditPasswordCancel={this.handleEditPasswordCancel}
        onEditPasswordSubmit={this.handleSubmitPassword}
        onChangeGender={this.onChangeGender}
        onAvatarChange={this.handleChangeAvatar}
      />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  campaignUser: state.balanceReducer.campaignUser,
  user: state.userReducer.user,
  addresses: state.addressesReducer.addresses,
  client: state.userReducer.client,
  consents: state.userReducer.consents
})

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch),
  saveAddresses: (addresses) => {
    dispatch(saveAddresses(addresses))
  },
  saveUser: (user) => {
    dispatch(saveUser(user));
  }
})

const Container = connect(mapStateToProps, mapDispatchToProps)(CampaignUserContainer)

export function withCampaignUserContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
