import React, { Component } from 'react';
import {prefix} from '../../../i18n'
const tCart= prefix('cart-item')
class CartItems extends Component{

  render(){
    const { 
      campaign,
      cart,
      removable,
      onItemRemove,
      onItemIncrease,
      onItemDecrease
    } = this.props
    return(
      <div className='mt-12 w-full'>
                  <div className='cart-header uppercase cart-header-height'>
                    <div className='title-product text-left'>{tCart('product')}</div>
                    <div className='title-points text-center'>{tCart('points')}</div>
                    <div className='title-quantity text-center'>{tCart('quantity')}</div>
                    <div className='title-value text-center'>{tCart('value')}<br></br><span className='text-xs lowercase'>{tCart('point-tag')}</span></div>
                    <div className='title-null text-center'></div>
                  </div>
                  { cart.items.map((item,i) => {
                    return (
                      <div className='cart-row' key={i}>

                          <div style={{color: campaign.text_color}} className='cart-item-product-mobile text-break'>
                            <p>{item.product.name}</p>
                          </div>

                          <div className='cart-item-product-image'>
                            <div style={{color: campaign.text_color}} className='cart-item-product text-break'>
                              <p>{item.product.name}</p>
                            </div>
                            <img style={{width:'110px'}} src={item.product.main_image.list.url}></img>
                          </div>

                          <div style={{color: campaign.color_primary}} className='cart-row-column text-center cart-row-blue cart-item-points'>
                            <p>{item.base_points}</p>
                          </div>

                          <div className='cart-row-column text-center cart-row-gray cart-item-quantity'>
                            <p>
                              {removable &&
                                <span onClick={() => onItemDecrease(item)} 
                                  style={{color: campaign.text_color}} 
                                  className='cart-row-signal'>-</span> 
                              }
                              {item.quantity}
                              {removable &&
                                <span onClick={() => onItemIncrease(item)} 
                                  style={{color: campaign.text_color}} 
                                  className='cart-row-signal'>+</span> 
                              }
                            </p>
                          </div>

                          <div className='cart-row-column text-center cart-row-gray cart-item-value'>
                            <p>{item.points}</p>
                          </div>

                         {removable &&
                          <div className='cart-row-column text-center cart-row-gray'>
                            <i onClick={() => onItemRemove(item)} className="fas fa-trash cart-row-trash cart-item-null"></i>
                          </div>
                         } 
                      </div>)
                    })
                  }
                  <div className='cart-footer'>
                    <div style={{color: campaign.color_primary}} className='text-right' colSpan='5'>
                      <span className='pr-8 font-quicksand-light'>{tCart('total')}</span>
                      <span className='pr-20'>{cart.total_points}</span>
                    </div>
                  </div>
      </div>
  )};
}

export default CartItems;
