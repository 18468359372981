import React, { useState } from 'react'
import LineHeader from './LineHeader'
import ColumnContainer from './ColumnContainer'
import Tab from './Tab'
import '../../../../src/assets/css/shared/_tabbed_pages.scss'

const Tabs = (props) => {
  const { rows } = props
  const [activeTab, setActiveTab] = useState(rows && rows.length > 0 && rows[0].title || '')

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div className="tabs vodafone navbar-text">
      <ol className="tab-list">
        {rows && rows.length > 0 && rows.map((row, idx) => {
          const { title } = row

          return (
              <Tab
                key={idx}
                activeTab={activeTab}
                key={title}
                label={title}
                onClick={onClickTabItem}
              />
          );
        })}
      </ol>
      <div className="tab-content">
        {rows && rows.length > 0 &&
          rows.map((row, key) => {
            const { rows: tabRows } = row
            if (row.title === activeTab) {
              return (
                <div key={key} className="py-5 w-full bg-grey-lighter">
                  {
                    tabRows && tabRows.length > 0 &&
                    tabRows.map((rw, idx) => {
                      const { cols } = rw
                      return (
                        <React.Fragment key={idx}>
                          <LineHeader {...rw} />
                          <div className="flex flex-row justify-between items-start -mx-1 h-full">
                            {cols && cols.length > 0 &&
                              cols.map((col, key) => ( <ColumnContainer key={key} {...col} />))
                            }
                          </div>
                        </React.Fragment>
                      )
                    }
                    )
                  }
                </div>
              )
            }
            return (
              <React.Fragment key={key}>
              </React.Fragment>
            )
          })
        }
      </div>
    </div>
  )
}

export default React.memo(Tabs);
