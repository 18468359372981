import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import BackgroundDiv from "../shared/BackgroundDiv"
import { withContainers, FeedContainer, ResizeContainer } from "../normalizer"
import { t } from "../../../i18n"

const  Details = (props) => {
  const { selectedAnnouncement, imageSize } = props
  if (selectedAnnouncement === undefined){
    return (
      <div></div>
    )
  }
  return (
      <div
        className="content-container my-8 navbar-text"
        id={`annoucement-${selectedAnnouncement.slug}`}
      >
        <BackgroundDiv
          url={selectedAnnouncement.image[imageSize] ? selectedAnnouncement.image[imageSize].url : ""}
          className="centered-bg relative col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 content-images-full rounded-t"
        >
          <Link to="/announcement" className="bg-white g-news__remove">
            <i className="fas fa-times fa-lg navbar-text" />
          </Link>
        </BackgroundDiv>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 px-5">
          <div className="py-4">
            <h2 className="primary-text vodafone-bold news-title ">
              {selectedAnnouncement.title}
            </h2>
          </div>
          <div className="custom-inner-html">
            <div
              className=" vodafone "
              dangerouslySetInnerHTML={{ __html: selectedAnnouncement.news_body }}
            />
          </div>
          <p className="py-3 vodafone navbar-text text-size text-right">
            {new Moment(selectedAnnouncement.created_at).format(t("date-format"))}
          </p>
        </div>
      </div>
    )
}

export default withContainers(Details, [FeedContainer, ResizeContainer])
