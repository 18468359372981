import React from "react";
import ColumnHeader from "./ColumnHeader";
import ColumnContent from "./ColumnContent";

const setCSSColCode = (size) => {
  if (size < 1) {
    size = 1
  }

  if (size > 12) {
    size = 12
  }

  return `w-${size}/12`
}

const showColumnHeader = (show_title,size) => {
  return ((!!show_title) || (size < 12))
}

const ColumnContainer = (props) => {

  const { content, num_of_cols, bck_color, ...rest } = props
  const { type } = !!content ? content : {}

  return (
    <div className={`flex-col justify-around my-2 mx-1 px-1 ${setCSSColCode(parseInt(num_of_cols))}`} style={{ backgroundColor: `${bck_color}` }} >
      { showColumnHeader( rest.show_title, parseInt(num_of_cols)) && <ColumnHeader {...rest} /> }
      <div className='block w-full h-full dashboard-column-container'>
        <ColumnContent title={props.title} content={content} bck_color={bck_color} color={rest.color} num_of_cols={num_of_cols} />
        {content && content.data && content.data.message &&
          <div className='flex flex-row justify-start items-center w-full content-message-container' >
            <p className='text-xl font-semibold content-message-text'>{content.data.message}</p>
          </div>
        }
      </div>
    </div>
  )
}

export default React.memo(ColumnContainer);
