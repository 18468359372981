import React from 'react'
import ReactPaginate from 'react-paginate';
import './css/style.scss';
import Product from '../shared/Product';
import NewProductLabel from "../shared/NewProductLabel";
const CatalogList = ({products, currentPage, campaign, pagination, onPageClick}) => {
  return (
    <>
      <div className="row start-xs">
      {
        products.map((item,i) => (
          <div className="col-lg-4 col-md-6 col-xs-12 col-sm-6 mb-4" key={i}>
            {
              item.is_new &&
              <NewProductLabel/>
            }
            <div className="box prod">
              <Product item={item} campaign={campaign}/>
            </div>
          </div>
        )) 
      }
      </div>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pagination.total_pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        initialPage={currentPage - 1}
        onPageChange={onPageClick}
        containerClassName={'pagination justif-start center-xs start-sm row'}
        subContainerClassName={'pages'}
        activeClassName={'active-block'}/>
    </>
  )
}

export default CatalogList