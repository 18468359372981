import React, { Component } from 'react';
import CustomSelect from '../../shared/CustomSelect';
import { connect } from 'react-redux';
import { AddressesService, CountryStateService } from '../../../services'
import { saveAddresses } from '../../../actions/addressesActions';
import {ToastStore} from 'react-toasts';
import { isEmpty } from '../../../utils/utils';
import { startFetch, endFetch} from '../../../actions/generalActions';
import {prefix } from '../../../i18n'

const tAdressForm = prefix('address-form')
const tRegistry = prefix('registry')
class AddressForm extends Component{

  constructor(props){
    super(props);
    const {state, country} = props.address

    this.state = { create: props.create,
                   edit: props.edit,
                   selectedCountry: country,
                   selectedState: state, 
                   countries: [],
                   states: props.states,
                   address: {},
                   errors: {}
                 }
    this.handleInputChange = this.handleInputChange.bind(this);

  }

  handleInputChange(event) {
    let NewAddress = this.state.address
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    NewAddress[name] = value
    this.setState({
      address: NewAddress
    });
  }

  getStyle(){
    const color_primary = this.props.campaign.color_primary;
    return {color: color_primary, 
            borderBottom: `1px solid ${color_primary}`
          }
  }

  getInitalCountryState(){
    this.props.startFetch();
    const { 
      selectedCountry, 
      selectedState,
    } = this.state

    CountryStateService.getCountries()
    .then((response) => {
      const countries = response.data.countries 
      const defaultCountry = selectedCountry || countries.find(e => e.iso.toString() === 'PT')
      CountryStateService.getStates({country_id: defaultCountry.id})
      .then((response) => {
        const states = response.data.states
        const defaultState = selectedState || states.find(e => e.abbr.toString() === 'CNT')
        this.setState({
          countries: countries, 
          selectedCountry:  selectedCountry || defaultCountry,
          states: states, 
          selectedState: selectedState || defaultState
        }, () => {
           this.props.endFetch();
        })
      })
      .catch((error) => {
        this.props.endFetch();
      })
    })
    .catch((error) =>{
      this.props.endFetch();
    })
  }

  getStates(option){
    const payload = {country_id: option.id}
    CountryStateService.getStates(payload)
    .then((response) => {
      const states = response.data.states
      this.setState({selectedCountry: option, states: states, selectedState: states[0]})
    })
  }
  saveState(option){
    this.setState({selectedState: option})
  }
  getPayload(){
    const { address, 
      selectedCountry, 
      selectedState
    } = this.state

    const payload = {address: address};
    if (selectedCountry)
      payload.address.country_id = selectedCountry.id
    if (selectedState)
      payload.address.state_id = selectedState.id
    return payload
  }

  createNewAddress(e){
    const payload = this.getPayload()
    if ( isEmpty(payload.address) ){
      ToastStore.warning(tAdressForm('create-warning'));
      return;
    }
    this.props.startFetch()
    AddressesService.createAddress(payload)
      .then((response) => {
        this.props.saveAddresses(response.data);
        ToastStore.success(tAdressForm('create-success'));
        this.props.endFetch()
        this.props.handleClick();
       
      })
      .catch((error) => {
        this.setState({errors: error.response.data});
        this.props.endFetch()
      });
  }
  editAddress(e){
    const payload = this.getPayload()
    payload.id = this.props.address.id

    if (isEmpty(payload.address)){
      this.props.handleClick();
    }
    this.props.startFetch()
    AddressesService.updateAddress(payload)
      .then((response) => {
        this.props.saveAddresses(response.data);
        ToastStore.success(tAdressForm('edit-success'));
        this.props.handleClick();
        this.props.endFetch()
      })
      .catch((error) => {
        this.setState({errors: error.response.data});
        this.props.endFetch()
      });
  }
  getCreateOrEdit(e){
    e.preventDefault();
    if (this.state.create === true) {
      this.createNewAddress(e)
    }
    if (this.state.edit === true){
      this.editAddress(e)
    }
  }

  componentDidMount(){
    this.getInitalCountryState()
    if (this.state.selectedCountry){
      this.getStates(this.state.selectedCountry)
    }
  }

  render(){
    const error = this.state.errors
    const { create } = this.state
    const { campaign } = this.props
    const address = this.props.address


    return(
      <div>
        <div className='mb-6 flex template-container between-xs '>
          <div>
            <h2 style={{color: campaign.color_primary}} className='template-title uppercase'>{address.main_name ? `${tAdressForm('title')} ${address.main_name}`: tAdressForm('create-new')}</h2>
            <hr style={{backgroundColor: campaign.text_color}} className="hr-down-left orange"></hr>
          </div>
          <button 
            onClick={e => this.getCreateOrEdit(e)} 
            required={create} 
            form='edit-address' 
            className='btn-secondary user-button px-4 py-1'
            >
              {tAdressForm('save')}
          </button>
        </div>
        <form id='edit-address' >
        <div className='flex template-block between-xs'>
          <input required={create} style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.first_name ? address.first_name : tRegistry('first-name')} className="w46 template-blue"  type="text" name='first_name'></input>
          <input required={create} style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.last_name ? address.last_name : tRegistry('last-name')} className="w46 template-blue"  type="text" name='last_name'></input>
        </div>
        <div className='flex template-block between-xs'>
          <div className='w46'>
            <input required={create} style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.phone ? address.phone : tRegistry('phone')} className='w-full template-blue' type='text' name='phone'></input>
          </div>
          <div className='w46'>
            <input required={create} style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.email ? address.email : tRegistry('email')} className='w-full template-blue'  type='email' name='email'></input>
          </div>
        </div>
        <div className='flex template-block between-xs'>
          <input required={create} style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.main_name ? address.main_name : tAdressForm('main-name')} className='w-full template-blue' type='text' name='main_name'></input>
        </div>
        <div className='template-block'>
          <div className='w-full'>
            <input required={create} style={this.getStyle()}  onChange={this.handleInputChange} placeholder={address.address1 ? address.address1 : tAdressForm('address-1')} className='w-full template-blue'  type='text' name='address1'></input>
            {error.address1 ? <p className='w-full template-errors'>{tAdressForm('address-1')}: {error.address1.join(',')}</p> : null}
          </div>
        </div>
        <div className='template-block' >
          <div className='w-full'>
            <input style={this.getStyle()}  onChange={this.handleInputChange} placeholder={address.address2 ? address.address2 : tAdressForm('address-2')} className='w-full template-blue' type='text' name='address2'></input>
          </div>
        </div>
        <div className='flex template-block between-xs'>
          <div className='w46'>
          <input style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.zip_code ? address.zip_code : tAdressForm('zipcode')} className='w-full template-blue' type='text' name='zip_code'></input>
          {error.zip_code ? <p className='w-full template-errors'>{tAdressForm('zipcode')}: {error.zip_code.join(',')}</p> : null}
          </div>
          <div className='w46'>
            <input style={this.getStyle()} onChange={this.handleInputChange} placeholder={address.city ? address.city : tAdressForm('location')} className='w-full template-blue' type='text' name='city'></input>
            {error.city ? <p className='w-full template-errors'>{tAdressForm('location')}: {error.city.join(',')}</p> : null}
          </div>
        </div>
        <div className='flex template-block between-xs'>
          <div className='w-full flex between-xs'>
            <div className='w46'>
              <CustomSelect campaign={campaign} name='country' onChange={(e) => this.getStates(e)} options={this.state.countries } 
                getOptionLabel={(option) => option.name} defaultValue={this.state.selectedCountry} getOptionValue={(option) => option.id} 
                placeholder={tAdressForm('country')}className='w-full' classNamePrefix='custom-select'/>
              {error.country ? <p className='w-full template-errors'>{tAdressForm('country')}: {error.country.join(',')}</p> : null}
            </div>
            <div className='w46'>
              <CustomSelect campaign={campaign} name='state' options={this.state.states} onChange={(e) => this.saveState(e)}
                defaultValue={this.state.selectedState} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id} 
                placeholder={tAdressForm('state')} className='w-full' classNamePrefix='custom-select'/>
              {error.state ? <p className='w-full template-errors'>{tAdressForm('state')}: {error.state.join(',')}</p> : null}
            </div>
          </div>
        </div> 
      </form>
    </div>
    )
  }
  static defaultProps = {
    address: {
      country: undefined, 
      state: undefined
    }
  } 
}
function mapDispatchToProps(dispatch){
  return{
    saveAddresses: (addresses) => {
      dispatch(saveAddresses(addresses))
    },
    startFetch: () => { dispatch(startFetch()) },
    endFetch: () => { dispatch(endFetch()) }

  }
}
export default connect(null,mapDispatchToProps)(AddressForm);