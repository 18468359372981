import axios from './interceptor';

export const AcceleratorsService = {

  getAccelerators(payload) {
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/accelerators`
    return axios({
      method: 'get',
      url: url
    });
  }
};
