import React, { Component } from 'react';
import Select from 'react-select';
import {t, prefix} from '../../../i18n'

const AddressForm = ({required, countries, states, selectedCountry, selectedState, onChangeAddress, onChangeCountry, onChangeState}) => {
  const tRegistry = prefix('registry')

  return (
    <>
      <div>
        <h2 className='py-8 template-title'>{tRegistry('address-title')}</h2>
      </div>
      <div className='template-block'>
        <input required={required} onChange={onChangeAddress} placeholder={tRegistry('address')} className='w-full template-blue'  type='text' name='address1'></input>
      </div>
      <div className='template-block'>
        <input onChange={onChangeAddress} placeholder={tRegistry('cont')} className='w-full template-blue' type='text' name='address2'></input>
      </div>
      <div className='flex template-block between-xs'>
        <div className='w46'>
          <input required={required} onChange={onChangeAddress} placeholder={tRegistry('zipcode')} className='w-full template-blue' type='text' name='zip_code'></input>
        </div>
        <div className='w46'>
          <input required={required} onChange={onChangeAddress} placeholder={tRegistry('city')} className='w-full template-blue' type='text' name='city'></input>
        </div>
      </div>
      <div className='flex template-block between-xs'>
        <div className='w-full flex between-xs'>
          <div className='w46'>
            <Select  onChange={(e) => onChangeCountry(e)} options={countries} value={selectedCountry}
              getOptionLabel={(option) => option.name}  getOptionValue={(option) => option.id} 
              name='pais' placeholder={tRegistry('country')} className='w-full' classNamePrefix='custom-select'/>
          </div>
          <div className='w46'>
            <Select onChange={(e) => onChangeState(e)} options={states}
              value={selectedState} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
              name='state' placeholder={tRegistry('state')} className='w-full' classNamePrefix='custom-select'/>
          </div>
        </div>
      </div> 
    </>
  )
}
export default AddressForm