import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PostsService, buildTransaction, savePosts } from '../normalizer';

class PostContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.posts.length === 0) {
      this.getPosts()
    }
  }

  getPosts() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }

    this.props.startTransaction(() =>
      PostsService.getPosts(payload)
        .then((response) => {
          this.props.savePosts(response.data)
      })
    )
  }

  render() {
    const {
      component: Component,
      posts: posts,
      ...rest
    } = this.props;

    return (
      <Component
        posts={posts}
        {...rest}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    posts: state.postsReducer.posts
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch),
    savePosts: (posts) => dispatch(savePosts(posts))
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(PostContainer)

export function withPostContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
