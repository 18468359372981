import React from "react"
import { Switch } from "react-router-dom"
import Route from "../../../routes/CustomRoute"
import CartItems from "./CartItems"
import CartLayout from "./CartLayout"
import CartCheckout from "./CartCheckout"
import CartAddress from "./Addresses"
import CartSuccess from "./CartSuccess"

const ShoppingCart = props => {
  return (
    <>
      <CartLayout>
        <Switch>
          <Route exact path="/cart" component={CartItems} campaign={props.campaign} />
          <Route exact path="/cart/address" component={CartAddress} campaign={props.campaign} />
          <Route exact path="/cart/checkout" component={CartCheckout} campaign={props.campaign} />
          <Route exact path="/cart/success" component={CartSuccess} campaign={props.campaign} />
        </Switch>
      </CartLayout>
    </>
  )
}

export default ShoppingCart
