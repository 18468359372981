import React from "react"
import Moment from "moment"
import { t } from "../../../i18n"
import StyledImage from "../../shared/StyledImage"
import { Chevron } from "../normalizer"
import { Link } from "react-router-dom"

const ImageCardLayout = ({ title, link, image, subtitle, enddate, label}) => {
  return (
    <Link className="text-color gl-home__promotion" to={link}>
      <StyledImage
        url={image}
        className="gl-promotion__image gl-promotion__image--announcement"
      />
      <div className="gl-promotion text-color">
        <label className="gl-post__label">{label}</label>
        <div className="gl-promotion__title">
          <h3 className="gl-post__title gl-post__title--promotion ">{title}</h3>
         
        </div>
        <div className="gl-promotion__description gl-promotion__description--announcement">
          <p>{subtitle}</p>
          <Chevron className="primary-fill gl-promotion__chevron" />
        </div>
        <p className="text-right">
          Termina a {Moment(enddate).format(t("date-format"))}
        </p>
      </div>
    </Link>
  )
}

export default ImageCardLayout
