import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveOrders, saveSelectedOrder } from '../../../actions/ordersActions';
import { OrdersService } from '../../../services';
import { buildTransaction } from '../../../actions/generalActions';
import { withRouter } from 'react-router-dom'
import { t } from '../../../i18n';
import OrderPaymentDetailsModal from '../../../components/GamificationTemplate/shared/OrderPaymentDetailsModal'

export class OrdersContainer extends Component {
  state = {
    page: 1,
    showPaymentDetailsModal: false,
    orderPayment: null,
  }

  fetchUserOrders() {
    const {
      user,
      campaign,
      page
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(()=>
      OrdersService.getOrders(payload).then((response) => {
        const orders = response.data
        this.props.saveOrders(orders);
      })
    )
  }

  fetchUserOrder(order_id) {
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: order_id
    }

    this.props.startTransaction(()=>
      OrdersService.getSelectedOrder(payload).then((response) => {
        const order = response.data
        this.props.saveSelectedOrder(order);
      })
    )
  }

  getOrderPaymentInfo = (order) => {
    if (!order.has_co_payment)
      return null

    const { color_primary } = this.props.campaign
    const { status, id: order_id } = order
    if (status === 'Pago') {
      return <button className={`gl-profile__order-status gl-profile__order-status--delivered m-3 p-2`} onClick={(e) => this.getOrderPaymentDetails(e, order_id)} >Factura</button>
    } else if (status === 'Aguarda Pagamento'){
      return <button className={`gl-profile__order-status gl-profile__order-status--processing m-3 p-2`} onClick={(e) => this.getOrderPaymentDetails(e, order_id)} >Detalhes</button>
    } else {
      return null 
    }
  }

  getOrderPaymentDetails = (event, order_id) => {
    event.stopPropagation()
    const { user, campaign } = this.props
    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: order_id
    }

    this.props.startTransaction(()=>
      OrdersService.getSelectedOrder(payload).then((response) => {
        const order = response.data
        this.props.saveSelectedOrder(order);
        const { status, order_payment } = order.order
        if (status === 'Pago'){ window.open(order_payment.invoice_permalink, "_blank") }
        else if ( status === 'Aguarda Pagamento' ){
          this.openModal(order_payment)
        } else {
          return null
        }
      })
    )
  }

  handlePageChange = (data)=> {
    this.setState({page: data.selected +1 },()=> this.fetchUserOrders())
  }

  handleOrderOnClick = (id) => {
    this.fetchUserOrder(id)
    this.props.history.push(`orders/${id}`) 
  }

  handleReceivedChange = (id) => {
    this.setState({status: "delivered"}, ()=> this.fetchUserOrder(id))
  }

  handleUserReceivedOnClick = (id, received_by_user, status) => {
    const {
      user,
      campaign,
      page,
      selectedOrder
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: id,
      page: page,
      userReceived: true,
      status: "delivered"
    }
    this.props.startTransaction(() => 
      OrdersService.updateOrder(payload).then((response) => {
        let order = selectedOrder
        order.received_by_user = !received_by_user
        this.props.saveSelectedOrder({order});
      })
    )
  }

  openModal = (orderPayment) => {
    this.setState(prevState => ({
      ...prevState,
      orderPayment,
      showPaymentDetailsModal: true,
    }))
  }

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      orderPayment: null,
      showPaymentDetailsModal: false,
    }))
  }

  componentDidMount(){
    this.fetchUserOrders()

    if(!this.props.selectedOrder.id){
      this.fetchUserOrder(this.props.match.params.id)
    }
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
      <>
      <Component
        {...rest}
        onReceivedChange={this.handleReceivedChange}
        onUserClickReceived={this.handleUserReceivedOnClick}
        onPageChange={this.handlePageChange}
        onOrderShow={this.handleOrderOnClick}
        page={this.state.page}
        getOrderPaymentInfo={this.getOrderPaymentInfo}
      />
      <OrderPaymentDetailsModal
        show={this.state.showPaymentDetailsModal}
        close={this.closeModal}
        orderPayment={this.state.orderPayment}
        bgColor={this.props.campaign.color_primary}
        textColor={this.props.campaign.footer_text_color}
      />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  orders: state.ordersReducer.orders,
  pagination: state.ordersReducer.pagination,
  selectedOrder: state.ordersReducer.selectedOrder,
  status: state.ordersReducer.selectedOrder.status
})

const mapDispatchToProps = (dispatch) => ({
  saveOrders: (orders) => { dispatch(saveOrders(orders))},
  saveSelectedOrder: (order) => { dispatch(saveSelectedOrder(order))},
  startTransaction: buildTransaction(dispatch)
})

const Container =  withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersContainer))

export default Container

export function withOrdersContainer(component){
  return (props)=> (
    <Container component={component} {...props}/>
  )
}
