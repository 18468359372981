import React, { Component } from "react"
import StyledImage from "../../shared/StyledImage"
import { substring, Chevron, withResizeContainer } from "../../normalizer"
import { Link } from "react-router-dom"
import PromotionPost from "../PostFeed/PromotionPost"

class NewsFeed extends Component{

  state = {
    isMobileFriendly: false
  }
  
  updateSizes = () => {
    this.setState({
      isMobileFriendly: window.innerWidth < 1024
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateSizes)
    this.updateSizes()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSizes)
  }

  render() {
    const feed = this.props.feed 
    const isMobileFriendly = this.state.isMobileFriendly
    return (
      <>
        {
        <div className="g-home__new-container col-xl-2 col-lg-2 col-md-2 col-xs-12 col-sm-12">
          { feed.map(item => {
            if (isMobileFriendly){
              let news_content =
                  {
                    title: item.title,
                    subtitle: item.subtitle,
                    content_title: "Notícias!",
                    url: "news/"+item.id,
                    prev_img: item.prev_image,
                  };
              return <PromotionPost content={news_content} imageSize={this.props.imageSize} limitText={this.props.limitText} key={"f-" + item.id} />
            } else {
              return <HighlightedNew {...item} imageSize={this.props.imageSize}key={"f-" + item.id} />
            }
          })}
        </div>
        }
      </>
    )
  }
}

const HighlightedNew = props => {
  const { prev_image: image, title, subtitle, id, banner_text, imageSize } = props

  return (
    <Link
      className="g-home__new-item navbar-text"
      to={"/announcement/news/" + id}
    >
      <StyledImage url={image.mobile_image ? image.mobile_image.url : ''} className="g-home__new-image" />
      <div className="g-home__new-content">
        <h3 className="g-home__new-type vodafone-bold">Notícia</h3>
        <div className="flex justify-between mb-3 ">
          <h2 className="g-home__new-title lighter">{substring(title, 15)}</h2>
          <Chevron className="primary-fill g-home__new-chevron" width="30px" />
        </div>
        {subtitle && <h3 className="g-home__new-subtitle">{subtitle}</h3>}
        <div
          className="g-home__new-description custom-inner-html"
          dangerouslySetInnerHTML={{ __html: banner_text }}
        />
      </div>
    </Link>
  )
}


export default withResizeContainer(NewsFeed)
