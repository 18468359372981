const numberFormat = require('number-format.js');

function capitalize(str){
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : 'Sem Nome';
}

function NumberToHumanRange(n,numberOfFixed,numberToSlice=3) {
  var ranges = [
    { divider: 1e18 , suffix: 'E' },
    { divider: 1e15 , suffix: 'P' },
    { divider: 1e12 , suffix: 'T' },
    { divider: 1e9 , suffix: 'B' },
    { divider: 1e6 , suffix: 'M' },
    { divider: 1e3 , suffix: 'K' }
  ];

  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const numberDivided = Number(n / ranges[i].divider)
      const normalizedNumber = numberDivided % 1 === 0 ? numberDivided.toFixed(0) : numberDivided.toFixed(numberOfFixed)
      return normalizedNumber.toString().slice(0,numberToSlice) + ranges[i].suffix;
    }
  }
  return Number(n).toFixed(0).toString().slice(0,numberToSlice);
}

function isEmpty(obj) {
  for (var x in obj) { return false; }
  return true;
}

function isDate(val) {
  return toString.call(val) === '[object Date]';
}

function formatNumber(val) {
  if( val === 0 ) 
    return val 
    
  return val % 1 === 0 ? numberFormat('###.###,##', val) : numberFormat('###.###,#0', val)
}

export {isEmpty, capitalize, isDate, formatNumber, NumberToHumanRange}

export function withSlug({slug}, path){
  return `/${slug}${path}`  
}

export function substring(target, limit, suffix) {
  return target.length > limit ? [target.substring(0, limit - 1), suffix].join('') : target
}

export function eventPath(evt) {
  const path = (evt.composedPath && evt.composedPath()) || evt.path,
    target = evt.target;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return (path.indexOf(window) < 0) ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo) {
    memo = memo || [];
    var parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    }
    else {
      return getParents(parentNode, memo.concat(parentNode));
    }
  }

  return [target].concat(getParents(target), window);
}

export function parseHTMLTags(msg){
  return msg.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
}
