import React, { Component } from "react"
import { connect } from "react-redux"
import { matchPath } from "react-router-dom"
import {
  saveUser,
  saveProducts,
  saveHighlights,
  saveProductsBrands,
  startFetch,
  endFetch,
  buildTransaction,
  ProductsService,
  TaxonomiesService
} from "../normalizer"

const wrapRedirectToCatalog = (action, history) => {
  return function() {
    if (
      matchPath(window.location.pathname, {
        exact: true,
        path: "/catalog/product/:id"
      })
    ) {
      history.push("/catalog")
    }
    action(...arguments)
  }
}

class CatalogContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      firstTaxon: [],
      selectedFirstTaxon: null,
      secondTaxon: [],
      selectedSecondTaxon: null,
      selectedBrand: null,
      thirdTaxon: [],
      selectedThirdTaxon: null,
      affordableProducts: false,
      nextAffordableProducts: false,
      alphabeticalDescOrder: false,
      highlighted: false,
      wished: false,
      activeFilterRanges: [],
      text: undefined,
      filtering: true,
      searching: false,
      selectedOrder: null
    }

    const { history } = props

    this.handleNextAffordableProductsClick = wrapRedirectToCatalog(
      this.handleNextAffordableProductsClick.bind(this),
      history
    )
    this.handleAffordableProductsClick = wrapRedirectToCatalog(
      this.handleAffordableProductsClick.bind(this),
      history
    )
    this.handleAlphabeticalOrderClick = wrapRedirectToCatalog(
      this.handleAlphabeticalOrderClick.bind(this),
      history
    )
    this.handleHighlightedClick = wrapRedirectToCatalog(
      this.handleHighlightedClick.bind(this),
      history
    )
    this.handleWishedClick = wrapRedirectToCatalog(
      this.handleWishedClick.bind(this),
      history
    )
    this.handleMoreViewsOrderClick = wrapRedirectToCatalog(
      this.handleMoreViewsOrderClick.bind(this),
      history
    )
    this.wrappedChangeFirstTaxons = wrapRedirectToCatalog(
      this.wrappedChangeFirstTaxons.bind(this),
      history
    )
    this.changeSecondTaxons = wrapRedirectToCatalog(
      this.changeSecondTaxons.bind(this),
      history
    )
    this.changeThirdTaxons = wrapRedirectToCatalog(
      this.changeThirdTaxons.bind(this),
      history
    )
    this.handleRangeClick = wrapRedirectToCatalog(
      this.handleRangeClick.bind(this),
      history
    )
    this.handleClearFiltersClick = wrapRedirectToCatalog(
      this.handleClearFiltersClick.bind(this),
      history
    )
    this.handleProductTextSearch = wrapRedirectToCatalog(
      this.handleProductTextSearch.bind(this),
      history
    )
    this.handleBrandChange = wrapRedirectToCatalog(
      this.handleBrandChange.bind(this),
      history
    )
    this.handleOrderChange = wrapRedirectToCatalog(
      this.handleOrderChange.bind(this),
      history
    )
  }

  getProducts() {
    this.props.startFetch()
    const { user, campaign } = this.props

    const {
      selectedFirstTaxon,
      selectedSecondTaxon,
      selectedThirdTaxon,
      affordableProducts,
      nextAffordableProducts,
      alphabeticalOrder,
      highlighted,
      wished,
      moreViews,
      activeFilterRanges,
      page,
      text,
      selectedBrand,
      selectedOrder
    } = this.state

    const selected_taxon =
      selectedThirdTaxon || selectedSecondTaxon || selectedFirstTaxon
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug,
      page: page,
      taxon: selected_taxon ? selected_taxon.id : "",
      affordable_products: affordableProducts,
      next_affordable_products: nextAffordableProducts,
      alphabetical_desc: alphabeticalOrder,
      highlighted: highlighted,
      wished: wished,
      more_views: moreViews,
      active_filter_ranges: activeFilterRanges,
      brand: selectedBrand ? selectedBrand.id : "",
      text
    }
    if (selectedOrder) {
      payload[selectedOrder.id] = true
    }

    ProductsService.getProducts(payload)
      .then(response => {
        const products = response.data
        this.props.saveProducts(products)
        this.props.endFetch()
        // window.scrollTo(0, 0)
      })
      .catch(e => this.props.endFetch())
  }

  getHighlights() {
    this.props.startFetch()
    const { user, campaign } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }
    ProductsService.getHighlights(payload)
      .then(response => {
        const highlights = response.data
        this.props.saveHighlights(highlights)
        this.props.endFetch()
      })
      .catch(e => this.props.endFetch())
  }

  getFirstTaxons = () => {
    this.props.startFetch()
    const { user, campaign } = this.props

    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug,
      parent_taxon: null
    }
    TaxonomiesService.getTaxons(payload)
      .then(response => {
        const taxonomies = response.data.taxonomies
        this.setState({ firstTaxon: taxonomies })
        if(taxonomies.length===1){
          this.changeFirstTaxons(taxonomies[0])
        }
        this.props.endFetch()
      })
      .catch(e => this.props.endFetch())
  }
  wrappedChangeFirstTaxons(option){
    this.changeFirstTaxons(option)
  }
  changeFirstTaxons = (option) => {
    if (!option) {

      this.setState(
        {
          selectedFirstTaxon: null,
          selectedSecondTaxon: null,
          selectedThirdTaxon: null,
          secondTaxon: [],
          thirdTaxon: [],
          page: 1
        },
        () => {

          this.getProducts()
        }
      )
    } else {

      const { user, campaign } = this.props

      const payload = {
        client_slug: user.client.slug,
        campaign_slug: campaign.slug,
        parent_taxon: option.id
      }
      this.props.startTransaction(() =>
        TaxonomiesService.getTaxons(payload).then(response => {
          const taxonomies = response.data.taxonomies

          this.setState(
            {
              selectedFirstTaxon: option,
              selectedSecondTaxon: null,
              selectedThirdTaxon: null,
              secondTaxon: taxonomies,
              page: 1
            },
            () => {
              this.getProducts()
            }
          )
        })
      )
    }
  }

  changeSecondTaxons(option) {
    if (!option) {
      this.setState(
        oldState => ({
          selectedSecondTaxon: null,
          selectedThirdTaxon: null,
          thirdTaxon: [],
          page: 1
        }),
        () => {
          this.getProducts()
        }
      )
    } else {
      const { user, campaign } = this.props

      const payload = {
        client_slug: user.client.slug,
        campaign_slug: campaign.slug,
        parent_taxon: option.id
      }

      this.props.startTransaction(() =>
        TaxonomiesService.getTaxons(payload).then(response => {
          const taxonomies = response.data.taxonomies
          this.setState(
            {
              selectedSecondTaxon: option,
              selectedThirdTaxon: null,
              thirdTaxon: taxonomies,
              page: 1
            },
            () => {
              this.getProducts()
            }
          )
        })
      )
    }
  }

  changeThirdTaxons(option) {
    if (!option) {
      this.setState(
        {
          selectedThirdTaxon: null,
          page: 1
        },
        () => {
          this.getProducts()
        }
      )
    } else {
      this.setState(
        {
          selectedThirdTaxon: option,
          page: 1
        },
        () => {
          this.getProducts()
        }
      )
    }
  }

  handleBrandChange(brand) {
    this.setState(
      {
        selectedBrand: brand,
        page: 1
      },
      () => {
        this.getProducts()
      }
    )
  }

  getBrands() {
    const { campaign, user } = this.props
    this.props.startFetch()
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }
    ProductsService.getBrands(payload)
      .then(response => {
        this.props.saveProductsBrands(response.data)
        this.props.endFetch()
      })
      .catch(e => this.props.endFetch())
  }

  handlePageClick = data => {
    let selected = data.selected
    this.setState(
      {
        page: selected + 1
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleNextAffordableProductsClick() {
    const { nextAffordableProducts } = this.state
    this.setState(
      {
        page: 1,
        nextAffordableProducts: !nextAffordableProducts
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleAffordableProductsClick() {
    const { affordableProducts } = this.state
    this.setState(
      {
        page: 1,
        affordableProducts: !affordableProducts
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleWishedClick() {
    const wished = this.state.wished
    this.setState(
      {
        page: 1,
        wished: !wished
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleHighlightedClick() {
    const highlighted = this.state.highlighted
    this.setState(
      {
        page: 1,
        highlighted: !highlighted
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleMoreViewsOrderClick() {
    const moreViews = this.state.moreViews
    this.setState(
      {
        page: 1,
        alphabeticalOrder: false,
        moreViews: !moreViews
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleAlphabeticalOrderClick() {
    const alphabeticalOrder = this.state.alphabeticalOrder
    this.setState(
      {
        moreViews: false,
        alphabeticalOrder: !alphabeticalOrder
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleRangeClick(rangeId) {
    this.setState( (prevState) => {
      let newFilterRanges = prevState.activeFilterRanges.filter(currId => currId !== rangeId); // filter returns new array
      if (newFilterRanges.length === prevState.activeFilterRanges.length){  // filter range wasnt active
        newFilterRanges.push(rangeId);
      }
      return {
        page: 1,
        activeFilterRanges: newFilterRanges
        };
      },
      () => {
        this.getProducts();
      }
    );
  }

  handleOrderChange(order) {
    this.setState(
      {
        page: 1,
        selectedOrder: order
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleClearFiltersClick() {
    this.setState(
      {
        nextAffordableProducts: false,
        affordableProducts: false,
        highlighted: false,
        moreViews: false,
        alphabeticalOrder: false,
        activeFilterRanges: []
      },
      () => {
        this.getProducts()
      }
    )
  }

  handleSearchOpen = () => {
    this.setState({ searching: true })
  }

  handleSearchClose = () => {
    this.setState({ searching: false })
  }
  handleFilterShow = () => {
    this.setState({ filtering: true })
  }
  handleFilterHide = () => {
    this.setState({ filtering: false })
  }

  generateFilterActions = () => ({
    handleNextAffordableProducts: this.handleNextAffordableProductsClick,
    handleAffordableProducts: this.handleAffordableProductsClick,
    handleHighlighted: this.handleHighlightedClick,
    handleWished: this.handleWishedClick,
    handleMoreViews: this.handleMoreViewsOrderClick,
    handleAlphabeticalOrder: this.handleAlphabeticalOrderClick,
    handleRangeClick: (rangeId) => {this.handleRangeClick(rangeId)},

    handleClearFiltersClick: this.handleClearFiltersClick
  })

  generateRangeFilters = () => {
    const {campaign} = this.props;

    const generateLabel = (min, max) => {
      if (min && max){
        return min + ' < ' + max;
      } else if (min) {
        return '> ' + min;
      } else if (max){
        return '< ' + max;
      } else {
        return '?';
      }
    };

    if (!campaign.range_filters) {return []; }

    return campaign.range_filters.map(rangeFilter => {
      let active = false;
      if (this.state.activeFilterRanges.includes(rangeFilter.id)){
        active = true;
      }
      return {
        rangeFilter: rangeFilter,
        active: active,
        label: generateLabel(rangeFilter.min_value, rangeFilter.max_value)
      };
    });
  };

  generateFilterState = () => ({
    nextAffordableProducts: this.state.nextAffordableProducts,
    affordableProducts: this.state.affordableProducts,
    highlighted: this.state.highlighted,
    wished: this.state.wished,
    moreViews: this.state.moreViews,
    alphabeticalOrder: this.state.alphabeticalOrder,
    rangeFilters: this.generateRangeFilters()
  })

  generateOptionState = () => ({
    firstTaxon: this.state.firstTaxon,
    secondTaxon: this.state.secondTaxon,
    thirdTaxon: this.state.thirdTaxon
  })
  generateOptionDefaultValues = () => ({
    selectedFirstTaxon: this.state.selectedFirstTaxon,
    selectedSecondTaxon: this.state.selectedSecondTaxon,
    selectedThirdTaxon: this.state.selectedThirdTaxon
  })
  generateOptionActions = () => ({
    changeFirstTaxons: this.wrappedChangeFirstTaxons,
    changeSecondTaxons: this.changeSecondTaxons,
    changeThirdTaxons: this.changeThirdTaxons
  })

  handleProductTextSearch({ value }) {
    if (value !== undefined) {
      this.setState({ text: value }, () => {
        this.getProducts()
      })
    }
  }

  componentDidMount() {
    this.getProducts()

    if (this.state.firstTaxon.length === 0) {
      this.getFirstTaxons()
    }

    if (this.props.brands.length === 0) {
      this.getBrands()
    }
  }

  orderOptions(){
    return [
      {id: 'more_views', name: 'Ordenar por mais vistos' },
      {id: 'price_asc', name: 'Ordenar por preço ascendente'},
      {id: 'price_desc', name: 'Ordenar por preço descendente'},
      {id: 'alphabetical_asc', name: 'Ordenar de A-Z'},
      {id: 'alphabetical_desc', name: 'Ordenar de Z-A'}
    ]
  }

  componentDidUpdate(oldProps) {
    if (oldProps.campaign.slug !== this.props.campaign.slug) {
      this.getBrands()
      this.getProducts()
      this.getFirstTaxons()
      this.getHighlights()
    }
  }

  render() {
    const { component: Component, ...rest } = this.props

    const orderOptions = this.orderOptions()
    return (
      <Component
        onProductTextSearch={this.handleProductTextSearch}
        onPageChange={this.handlePageClick}
        currentPage={this.state.page}
        optionState={this.generateOptionState}
        optionActions={this.generateOptionActions}
        defaultValues={this.generateOptionDefaultValues}
        onBrandChange={this.handleBrandChange}
        brands={this.props.brands}
        selectedBrand={this.state.selectedBrand}
        generateFilterState={this.generateFilterState}
        generateFilterActions={this.generateFilterActions}
        searching={this.state.searching}
        onSearchOpen={this.handleSearchOpen}
        onSearchClose={this.handleSearchClose}
        filtering={this.state.filtering}
        onFilterShow={this.handleFilterShow}
        onFilterHide={this.handleFilterHide}
        orderOptions={orderOptions}
        onOrderChange={this.handleOrderChange}
        selectedOrder={this.selectedOrder}
        {...rest}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    products: state.productsReducer.products,
    lastSeenProducts: state.productsReducer.lastSeenProducts,
    brands: state.productsReducer.brands,
    highlights: state.productsReducer.highlights,
    wishes: state.productsReducer.wishes,
    pagination: state.productsReducer.pagination,
    banners: state.homepageBannersReducer.banners
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveUser: user => {
      dispatch(saveUser(user))
    },
    saveProducts: products => {
      dispatch(saveProducts(products))
    },
    saveHighlights: highlights => {
      dispatch(saveHighlights(highlights))
    },
    saveProductsBrands: brands => {
      dispatch(saveProductsBrands(brands))
    },
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    },
    startTransaction: buildTransaction(dispatch)
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogContainer)

export function withCatalogContainer(component) {
  return props => <Container component={component} {...props} />
}

export default Container
