import React from "react"
import EmptyStateLayout from "../../shared/EmptyStateLayout"
import BalanceTable from "./BalanceTable"
import { NoPoints, withBalanceContainer } from "../../normalizer"

const Balance = props => {
  const { points, onClickUserProduction, ...rest } = props

  const message = "Ainda não foram registados pontos."

  return (
    <>
    { points.length > 0 ? 
        <>
        <BalanceTable points={points} {...rest} />
        <div className='row justify-between items-center'>
          <span className='col-xs-12 col-sm-12'>Para uma visualização mais detalhada clique 
            <a onClick={onClickUserProduction} href='#' className='g-footer--contacts-link'> aqui.</a>
          </span>
        </div>
        </>
       : (
        <EmptyStateLayout message="Fazer vendas, completar quizes e atingir resultados poderão ajudá-lo.">
          <div className="flex dead-grey flex-col justify-center items-center mt-5 mb-10">
            <NoPoints className="mr-3 dead-grey g-no-store__image mb-5" />
            <h2>{message}</h2>
          </div>
        </EmptyStateLayout>
        )
    }
    </>
  )
}

export default withBalanceContainer(Balance)
