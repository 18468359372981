import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  UserService, ClientService } from '../../../services';
import { startFetch, endFetch, buildTransaction } from '../../../actions/generalActions';
import Register from './index';


class RegisterWrapper extends Component {
  state = {
    tokenIsValid : false,
    user: {},
    optionalFields: [],
    terms: undefined,
    isTermsVisible: false
  }

  getRegisterFields(){
    const {client} = this.props
    const {token} = this.props.match.params

    if (client.registration_type !== 'free'){
      this.props.startFetch();
      const user_payload = {
        client_slug: client.slug,
        token
      }
      
      UserService.checkToken(user_payload).then((response)=>{
        const user = response.data.user
        this.setState({tokenIsValid : true, user})
        this.props.endFetch();
      })
      .catch((error) => {
        this.props.history.push('/login')
        this.props.endFetch();
      })
    }
    const clientPayload = { clientSlug: client.slug }
    this.props.startTransaction(() => 
      ClientService.getRegisterFields(clientPayload)
        .then(response => {
          const registerFields = response.data.register_fields
          this.setState({optionalFields: registerFields})
        })
    )
  }

  getTerms(){
    this.props.startFetch()
    const {client} = this.props
    const clientPayload = { clientSlug: client.slug }
    ClientService.getTermsOfService(clientPayload).then((response) => {
     this.setState({terms: response.data.terms.terms_of_service})
     this.props.endFetch();
    })
    .catch((error) => {
      this.setState({terms: 'Sem termos de serviço'})
      this.props.endFetch()
    });
  }

  componentDidMount(){
    const client = this.props.client
    if ( client.has_registration !== undefined && client.has_registration ){
      this.getRegisterFields();
      this.getTerms()
    } else {
      this.props.history.push('/login')
    }
  }

  render(){
    const { client } = this.props
    const { tokenIsValid, user, optionalFields, terms } = this.state
    const { params } = this.props.match
    if ((client.has_registration !== undefined && client.has_registration) && (client.registration_type === 'free' || tokenIsValid)){
      return (
        <div className='image-background'  style={client.background_banner.url ? {backgroundImage: 'url(' + client.background_banner.url + ')'}: {} }>
          <Register optionalFields={optionalFields} client = {client} user = { user } token ={params.token} terms = {terms} />
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.userReducer.client
  }
}
function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch()); },
    endFetch: () => { dispatch(endFetch()); },
    startTransaction: buildTransaction(dispatch)
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(RegisterWrapper);