import axios from './interceptor';

export const RankingsService = {
  getRankings(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/rankings`
    return axios({
      method: 'get',
      url: url
    });
  },
  getWinners(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}` +
      `/rankings/${payload.ranking_id}/results/user_position`
    return axios({
      method: 'get',
      url: url
    });
  },
  getHomeRanking(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/rankings/home_index`
    return axios({
      method: 'get',
      url: url
    });
  },
  getRankingPoints(payload){
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}` +
    `/rankings/${payload.ranking_id}/points`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: payload.page
      }
    });
  },
  getRankingTop10(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/rankings/gamification_index`
    return axios({
      method: 'get',
      url: url
    });
  },
  getRankingsGamificationTop10(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}` +
      `/rankings/${payload.ranking_id}/results/gamification_top_ten`
    return axios({
      method: 'get',
      url: url
    });
  },
  requesPointsExport({payload}) {
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}` +
      `/rankings/${payload.ranking_id}/points/export`
    return axios({
      method: 'get',
      url: url
    });
  },
}
