import React from "react"
import {connect} from "react-redux"

import { prefix } from "../../../i18n"
import { Link } from "react-router-dom"
import {
  buildTransaction,
  CampaignService
} from "../normalizer"
import {components} from "react-select"

const If = ({ condition, children }) => {
  if (condition) {
    return children
  } else {
    return null
  }
}

class Footer extends React.Component{
  constructor(props){
    super(props)
  }

  fetchManual(e){
    e.preventDefault()
    this.props.startTransaction( () =>
      CampaignService.getManualUrl(this.props.clientSlug, this.props.campaign.slug)
    .then(response => {
      const { url } = response.data
      window.open(url, '_blank')
    }))
  }

  findFeatureByName = (name) => {
    const feature = this.props.features.find(f => f.name === name)

    return feature
  }
   
  showRegulations = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_regulations : true
  }

  showRules = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_rules : true
  }
  
  showCompanyTrademark = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_company_trademark : true
  }

  render(){
    // to do - should be generic
    const generalNotesLink = "https://incentive-platform-prod.s3-eu-west-1.amazonaws.com/imagens/Notas+Gerais+VStore.pdf"

    return (
      <footer className="vodafone text-color g-footer break-words sm:break-normal">
        <div className="g-footer__container p-container">
          <div className="g-footer__main">
            {this.props.campaign.manual !== "" && (
              <div className="g-footer--margin-bottom-40">
                <h3 className="g-footer__title">Sobre o VPlay</h3>
                  <ul className="g-footer--items-no-style g-footer--center-ul">
                    <li className="g-footer__item">
                      <a className="g-footer--link" onClick={(e)=>this.fetchManual(e)}>
                        Manual de utilização da Plataforma
                      </a>
                    </li>
                  </ul>
              </div>)
            }
            <div className="g-footer--margin-bottom-40">
              <h3 className="g-footer__title">Outras informações</h3>
              <ul className="g-footer--items-no-style g-footer--center-ul">
                <If condition={this.showRegulations()}>
                  <li className="g-footer__item">
                    <Link className="g-footer--link" to={"/utility-info/regulation"}>
                      Regulamento (Termos, Condições e Privacidade)
                    </Link>
                  </li>
                </If>
                <If condition={this.showRules()}>
                  <li className="g-footer__item">
                    <Link className="g-footer--link" to={"/utility-info/rules"}>
                      Regras
                    </Link>
                  </li>
                </If>
                <li className="g-footer__item">
                  <a className="g-footer--link" target="_blank" href={generalNotesLink}>
                  Notas Gerais
                  </a>
                </li>
              </ul>
            </div>
            <div className="g-footer--margin-bottom-40">
              <h3 className="g-footer__title">Contactos</h3>
              <ul className="g-footer--items-no-style g-footer--center-ul">
                <div className='pb-2'>
                  <li className="g-footer__item-vodafone-contacts leading-normal">
                    <span className='text-lg'>Vodafone:  </span>
                    <a className="g-footer--contacts-link" href="mailto:unp_comissoes_e_incentivos@vodafone.com">
                      unp_comissoes_e_incentivos@vodafone.com
                    </a>
                    <br />
                    <span className='text-xs'>(questões de utilização da plataforma, dificuldades técnicas e esclarecimentos sobre report de atividade de vendas)</span>
                  </li>
                </div>
                <li className="my-3">
                  <span className='text-lg'>Incentivehouse:  </span>  
                  <a className="g-footer--contacts-link" href="mailto:vplay_incentivos@incentivehouse.com.pt">
                    Vplay_incentivos@incentivehouse.com.pt
                  </a>
                  <br />
                  <span className='text-xs'>(informações sobre Loja de Prémios - encomendas e entrega de prémios)</span>
                </li>
              </ul>
            </div>  
          </div>
          <If condition={this.showCompanyTrademark()}>
            <div className="g-footer__contract-list">
              <div>
                <p>© 2019 IncentiveHouse</p>
              </div>
            </div>
          </If>
        </div>
      </footer>
    )
  }
}
const mapDispatchToEvents = (dispatch) => {
  return {
    startTransaction: buildTransaction(dispatch)
  }
}

export default connect(null, mapDispatchToEvents)(Footer)

/* <span className='text-xs leading-loose'>
  <br />
  (para questões de utilização da plataforma, report de dificuldades técnicas e informações sobre 
  <br />
  Loja de Prémios - rebate de pontos, processo de encomenda e de entrega de prémios)
  </span>
*/
