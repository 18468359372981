import React from "react";

const LineHeader = (props) => {
  if (!props.show_title) {
    return null
  }

  return (
    <div className="flex justify-center items-center py-3 mt-6 w-full h-12"
        style={{ "backgroundColor": `${props.bck_color}` }}>
      <h1 className="font-bold text-center w-full" style={{ color: props.color}}>{props.title}</h1>
    </div>
  )
}

export default React.memo(LineHeader);
