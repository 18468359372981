import React from "react"
import ProductSlider from "./ProductSlider";

const CatalogPost = ({content}) => {
  return (
    <div className="navbar-text g-home__catalog ">
      <label className="g-post__label">{"Loja de prémios"}</label>
      <h3 className="g-post__title g-wishes__title">
        {content.title}
      </h3>
      { content.items.length === 0 ? 
        <p>Sem produtos destacados</p>
      :
        <ProductSlider products={content.items}/>
      }
      

    </div>
  )
}

export default CatalogPost
