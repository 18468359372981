import axios from "./interceptor"

export const SaleTradeService = {
  getPendingSaleTrades({clientSlug, campaignSlug, page}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_trades/index_solicited`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page
      }
    });
  },
  acceptSaleTrade({clientSlug, campaignSlug, id}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_trades/${id}/accept_sale_trade`
    return axios({
      method: 'put',
      url: url
    });
  },
  rejectSaleTrade({clientSlug, campaignSlug, id}) {
    const url =`/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_trades/${id}/reject_sale_trade`
    return axios({
      method: 'put',
      url: url
    });
  },
  getRequestedSales({clientSlug, campaignSlug, page}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_trades/index_requested`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page
      }
    });
  },
  createSaleTrade({clientSlug, campaignSlug, contract, user}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/sale_trades`
  
    return axios({
      method: "post",
      url: url,
      data: {
        sale: {
          contract_number: contract,
          user_id: user
        }
      }
    })
  }
}
