import axios from './interceptor';

export const SupportingDocumentsService = {

  getSupportingDocuments(payload){
      const url = 'api/clients/'+payload.client_slug+'/document_page_types';
    return axios({
      method: 'get',
      url: url
    });
  },

  getSupportingDocumentsOfType(payload, document_type_id) {
    const url = 'api/clients/'+payload.client_slug+
    '/document_page_types/'+document_type_id+'/document_pages';
    return axios({
      method: 'get',
      url: url
    });
  }
};
