export function savePendingAttributions(pendingSaleAttributions,pagination) {
  return {
    type: 'SAVE_PENDING_ATTRIBUTIONS',
    payload: {
      pendingSaleAttributions,
      pagination
    }
  }
};
export function saveCompletedAttributions(completedSaleAttributions,pagination) {
  return {
    type: 'SAVE_COMPLETED_ATTRIBUTIONS',
    payload: {
      completedSaleAttributions,
      pagination
    }
  }
};
export function updateSaleAttribution(state) {
  return {
    type: 'UPDATE_SALE_ATTRIBUTION',
    payload: state
  }
};
export function moveToCompleted(sale) {
  return {
    type: 'MOVE_TO_COMPLETED_ATTRIBUTIONS',
    payload: {
      sale
    }
  }
};
