import React from 'react';
import Button from '../shared/CustomButton';

const CartButtons = props => {

  const {
    onPrev,
    onNext,
    prevText,
    nextText,
    showNext
  } = props

  return (
    <div className='my-8 flex justify-between'>
      
      <Button outline onClick={() => onPrev()}>{prevText}</Button>
      {(showNext || typeof showNext === "undefined" ) && <Button primary onClick={() => onNext()}>{nextText}</Button>} 
    </div>
  )
}

export default CartButtons;
