import React from 'react'

const EmptyStateLayout = ({title, message, children}) => {
  return (
    <div className="gl-round flex-1 dead-grey default-bg flex flex-col items-center">
      {title &&<h2 className="font-bold gl-feed-header">{title}</h2>}
      {children}
      <p className="mb-4 font-bold">{message}</p>
    </div>
  )
}

export default EmptyStateLayout
