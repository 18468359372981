import axios from './interceptor';

export const NewsService = {

  getNews({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/news`;
    return axios({
      method: 'get',
      url: url
    }).then(response => {
      console.log('Response for getNews:', response);
      return response;
    });
  },

  getHomepageNews({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/news/homepage_news`;
    return axios({
      method: 'get',
      url: url
    }).then(response => {
      console.log('Response for getHomepageNews:', response);
      return response;
    });
  }
};
