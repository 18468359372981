import React from 'react'
import KeyValue from '../../shared/KeyValue'

const UserInfo = (props) => {
  const {
    first_name: firstName ,
    last_name: lastName,
    phone,
    login,
    email,
    gender,
    nif
  } = props

  const genderValue = gender === "male" ? "Masculino" : "";

  return (
    <div className="gl-profile__data-container mb-10">
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Identificador"
            value={login}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Primeiro nome"
            value={firstName}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Último nome"
            value={lastName}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Email"
            value={email}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Telefone/Telemóvel"
            value={phone}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Género"
            value={genderValue}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-10 col-sm-10 col-xs-12 remove-padding">
          <KeyValue
            title="NIF"
            value={nif}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
    </div>
  )
}

export default UserInfo
