import React from "react"
import { NumberToHumanRange } from "../../normalizer"

const hasRequirements = (requirements) => {
  if (requirements === undefined){
    return false
  }
  return true
}

const formatProgress = (requirements) => {
  const progresses = requirements.map((requirement,i) => {
    return Number(requirement.progress)
  })
  const total = progresses.reduce((result,progress) => result + progress)
  return `${total}  Pontos`
}

const LevelPointProgress = (props) => {
  const { requirements } = props
  return (
    <>
    { hasRequirements(requirements) &&
      <div className='mt-1'>
        <p className='text-lg text-center primary-text'>{formatProgress(requirements)}</p>
      </div>
    }   
    </>
  )
}

export default LevelPointProgress