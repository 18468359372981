import React from 'react';
import { withCampaignContainer } from "../../containers/shared/CampaignContainer";
import PageContainer from "./PageContainer";
// import {jsonContent} from "../../../utils/dump_2022_05_26"

const CampaignPagesContainer = (props) => {
  const pages = props && props.campaign && props.campaign.pages
  const pageContent = pages && pages.length > 0 && pages[0]

  return (
    <>
      {/* <PageContainer {...pageContent} json={jsonContent}/> */}
      <PageContainer {...pageContent} />
    </>
  )
}
export default withCampaignContainer(CampaignPagesContainer);
