import React from 'react'

const HistoryRow = ({row,rowHeader, headers}) => {
  let points = headers.map(header => {
    return row[header] || 0
  })
  let sumProduct = points.reduce((a,b) => a+b, 0)
  return <tr className='gl-profile__info-row'>
          <td className='text-left'>{rowHeader}</td>
          {headers.map(header => {
            return <td key={rowHeader+header} className='py-2 px-2 text-right'>{row[header] || 0}</td>
          }) 
          
          }
          <td className='text-right py-2 px-2'>{sumProduct}</td>    
      
        </tr>
}

const HistoryTable = ({headers, data}) => {

    const rowHeaders = Object.keys(data)
    return (
      <section className="overflow-x-auto w-full">
          <table style={{minWidth: 0}} className={"mb-10 w-full gl-profile__table"}>
            <thead>
              <tr className='gl-profile__info-row'>
                <th className='text-left'></th>
                { headers.map(header => {
                    return <th className='py-2 px-2 text-right' key={header}>{header}</th>
                  })
                }
                <th className='text-right primary-text'>Total por Unidade</th>
              </tr>
            </thead>
            <tbody>
              {
                rowHeaders.map((rowHeader,i) => {
                  const row = data[rowHeader]
                  return <HistoryRow key={rowHeader + i} row={row} headers={headers} rowHeader={rowHeader} />
                })
              }
            </tbody>
          </table>
        </section>
    )
}

export default HistoryTable