import React from "react"
import CatalogPost from "./CatalogPost"
import PromotionPost from "./PromotionPost"
import { withResizeContainer } from '../../normalizer'

const index = ({ posts, imageSize, limitText }) => {
  function selectPost(post) {
    switch (post.content_type) {
      case "News":
        post.content_title = post.content_title === null || post.content_title.undefined ? "Notícias!" : post.content_title
        let news_content =
            {
              title: post.content.title,
              subtitle: post.content.subtitle,
              content_title: post.content_title,
              content_type: post.content_type,
              url: "news/"+post.content.id,
              prev_img: post.content.prev_image,
            };
        return <PromotionPost imageSize={imageSize} limitText={limitText} key={post.id} content={news_content} />
      case"Accelerator":
        post.content_title = post.content_title === null || post.content_title.undefined ? "Jogos!" : post.content_title
        let acc_content =
            {
              title: post.content.name,
              subtitle: post.content.subtitle,
              content_title: post.content_title,
              content_type: post.content_type,
              url: "accelerators/"+post.content.id,
              prev_img: post.content.image,
            };
        return <PromotionPost imageSize={imageSize}  limitText={limitText} key={post.id} content={acc_content} />
      case "ProductList":
        return <CatalogPost key={post.id} content={post.content} />
      default:
        return null
    }
  }
  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 g-home__posts">
      {posts.map(post => selectPost(post))}
    </div>
  )
}

export default withResizeContainer(index)
