import React from "react"
import ReactPaginate from "react-paginate"
import { prefix, t } from "../../../../i18n"
import Moment from "moment"
import TableHeaderLayout from "../TableHeaderLayout"

const tTrade = prefix("trades")
const PendingActions = ({saleTrade, onAccept, onReject}) => {
  const handleOnAccept = e => {
    e.preventDefault()
    onAccept(saleTrade)
  }

  const handleOnReject = e => {
    e.preventDefault()
    onReject(saleTrade.id)
  }

  if (saleTrade.status !== "pending") {
    return <span className="col-xs-2 text-center">Já tratada</span>
  }

  return (
    <div className="col-xs-2 flex justify-around">
      <span
        onClick={handleOnAccept}
        className="text-green-dark text-center  cursor-pointer"
      >
        <i className="fas fa-check" />{" "}
        <span className="font-quicksand">Aceitar</span>
      </span>
      <span
        onClick={handleOnReject}
        className="text-red-dark text-center  cursor-pointer"
      >
        <i className="fas fa-times" />{" "}
        <span className="font-quicksand">Rejeitar</span>
      </span>
    </div>
  )
}

const Row = ({ saleTrade, onAccept, onReject }) => {
  return (
    <div className="row user-header font-quicksand-light text-left table-text items-center py-3">
      <span className="col-xs-3 text-xs">{saleTrade.description}</span>
      <span className="col-xs-3">{saleTrade.user_requester}</span>
      <span className="col-xs-2">{tTrade(`${saleTrade.status}`)}</span>
      <span className="col-xs-2 ">
        {new Moment(saleTrade.date).format(t("date-format"))}
      </span>

      <PendingActions
        saleTrade={saleTrade}
        onAccept={onAccept}
        onReject={onReject}
      />
    </div>
  )
}

const SaleTable = ({
  trades,
  onReject,
  onAccept,
  page,
  pagination,
  onPageChange
}) => {
  return (
    <>
      <TableHeaderLayout>
        <span className="col-xs-3 text-left ">{tTrade("description")}</span>
        <span className="col-xs-3 text-left">{tTrade("user-requester")}</span>
        <span className="col-xs-2 text-left">{tTrade("status")}</span>
        <span className="col-xs-2 text-left">{tTrade("registry-date")}</span>
        <span className="col-xs-2 text-center">Ações</span>
      </TableHeaderLayout>
      <div>
        {trades.map(trade => (
          <Row
            saleTrade={trade}
            key={`sale-trade-table-span-${trade.id}`}
            onReject={onReject}
            onAccept={onAccept}
          />
        ))}
      </div>
      {pagination.total_pages > 1 && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pagination.total_pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          initialPage={page - 1}
          onPageChange={onPageChange}
          containerClassName={"pagination my-5"}
          subContainerClassName={"pages"}
          activeClassName={"active-block"}
          forcePage={page - 1}
        />
      )}
    </>
  )
}

export default SaleTable
