import React, { Component } from 'react';
import Select from 'react-select';
import {t, prefix} from '../../../i18n'

const JobSelect = ({groups, positions, onChangeGroups, onChangePosition}) => {
  const tRegistry = prefix('registry')
  return (
    <div className="flex template-block between-xs">
      <div className='w46'>
        <Select required isMulti={true} onChange = {e => onChangeGroups(e)} name='group_id' options = {groups} getOptionLabel={(option) => option.external_identifier} getOptionValue={(option) => option.id}
          placeholder={tRegistry('job-location')} className='w-full' classNamePrefix='custom-select'/>
      </div>
      <div className='w46'>
        <Select required onChange = {e => onChangePosition(e)} options = {positions} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
          name='position_id' placeholder={tRegistry('job-category')} className='w-full' classNamePrefix='custom-select'/>
      </div>
    </div>
  )
}

export default JobSelect