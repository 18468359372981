import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { saveUser, saveLogIn, saveClient } from '../../../actions/userActions';
import { startFetch, endFetch } from '../../../actions/generalActions';
import { UserService } from '../../../services';
import {ToastContainer, ToastStore} from 'react-toasts';
import incentivehouse from '../../../assets/img/incentivelogo.png';

class SetPassword extends Component{

constructor(props){
  super(props);
  this.state={redirect: false, errors: {} };
}

submit(e){
  e.preventDefault();
  const { 
    user 
  } = this.props
  const password = e.target.password.value
  this.props.startFetch();
  const payload = { 
    client_slug: user.client.slug, 
    user: { 
      email: user.email,
      login: user.login,
      password: password, 
      password_confirmation: e.target.password_confirmation.value
    }
  };
  UserService.doFirstLogin(payload)
    .then((response) => {
      this.props.saveUser(response.data.user) 
      const loginPayload = {
        user: {
          username: user.login,
          password: password,
        }
      }
      UserService.doLogin(loginPayload,user.client.slug)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));
        this.props.saveUser(response.data);
        localStorage.setItem('client',user.client.slug)
        if (response.headers.authorization) {
          localStorage.setItem('jwt', response.headers.authorization);
          this.props.saveLogIn(localStorage.getItem('jwt'));
          
        }
        
        this.props.endFetch();
      })
    })
    .catch((error) => {
      this.setState({ errors: error.response.data })
      this.props.endFetch();
    });
}

render() {
  const { 
    user
  } = this.props;

  const {
    redirect,
    errors
  } = this.state

  if(redirect || user.last_sign_in_at !== null ){
    if(localStorage.getItem('jwt')){
      return <Redirect to='/' />
    }
  }


  return (
    <div className='image-background' style={user.client.background_banner.url ? {backgroundImage: 'url(' + user.client.background_banner.url + ')'}: {} } >
      <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
      <div className="row font-quicksand center-lg center-xs center-md mt-20">
        <div className="col-lg-5 col-md-7 col-xs-11 default-container">
          <div className='py-10'><img style={{width:'260px'}} src={user.client.logo.url ? user.client.logo.url : incentivehouse } /></div>
          <form className="pb-10" onSubmit={(e)=> this.submit(e) }>
            <div className="row center-lg center-xs center-md py-3">
              <div>
                <input className="template-blue" placeholder="Password" required type="password" name="password"></input>
                {errors.password  ? <p className='template-errors'>Password: {errors.password.join(',')}</p> : null}
              </div>
            </div>
            <div className="row center-lg center-xs center-md py-3">
              <div>
               <input className="template-blue" placeholder="Password Confirmação" required type="password" name="password_confirmation"></input>
               {errors.password_confirmation  ? <p className='template-errors'>Password: {errors.password_confirmation.join(',')}</p> : null}
              </div>
            </div>
            <div className='py-5 center-lg center-xs center-md row'>
              <button style={{width:'290px'}}className="button-cart button-primary text-xl" type="submit" value="Submit">Alterar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user
  }
}
function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveClient: (client) => {
      dispatch(saveClient(client));
    },
    saveLogIn: (state) => {
      dispatch(saveLogIn(state));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);