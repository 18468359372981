import React from 'react';
import RankingRow from './RankingRow'

const isCurrenUser = (result, userPosition) => {
  return userPosition && result.id === userPosition.id
}

const RankingTableLines = ({ results, userPosition, areWinners }) => {

  return(
    results.map(result => (
      <RankingRow 
        key={result.id}
        result={result} 
        isCurrentUser={isCurrenUser(result, userPosition)} 
        isWinner={areWinners}
      />
    ))
  )
}

export default RankingTableLines 