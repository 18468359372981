import axios from './interceptor';

export const headerLinksService = {

  getLinks({clientId, campaignSlug}){
    const url = `/api/clients/${clientId}/campaigns/${campaignSlug}/headers`
    return axios({
      method: 'get',
      url: url
    });
  }
};