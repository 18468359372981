import React from "react"
import { withCompletedAttributionsContainer } from "../../../containers/default/saleAttribution/CompletedAttributionsContainer"
import { prefix, t } from "../../../../i18n"
import Moment from "moment"
import TableHeaderLayout from "../TableHeaderLayout";

const tSaleState = prefix("sale_state")
const SaleRow = ({
  id,
  date,
  state,
  description,
  attribution_at,
  user
}) => {

  return (
    <div className="mt-5">
      <div className="row text-sm">
        <span className="col-xs-2 ">{new Moment(date).format(t("date-format"))}</span>
        <span className="col-xs-2 flex items-center">{tSaleState(state)}</span>
        <span className="col-xs-2 flex items-center">{user.employee_identifier}</span>
        <span className="col-xs-4 flex items-center ">{description}</span>
        <span className="col-xs-2 flex items-center">{new Moment(attribution_at).format(t("date-format"))}</span>
      </div>
    </div>
  )
}

const index = ({ completedSaleAttributions, pagination }) => {
  return (
    <div className="template col-xs-12 font-quicksand-light">
      <TableHeaderLayout>
        <span className="col-xs-2">Data</span>
        <span className="col-xs-2">Estado</span>
        <span className="col-xs-2">Utilizador</span>
        <span className="col-xs-4">Descrição</span>
        <span className="col-xs-2">Data da Correção</span>
      </TableHeaderLayout>
      <div>
        {completedSaleAttributions.map(s => (
          <SaleRow {...s} key={"sale-attribution-" + s.id} />
        ))}
      </div>
      
      {pagination.total_pages <= 0 && (
        <div className="text-center text-xl text-color pl-2 pt-10 pb-8">
          Não realizou nenhuma correção de utilizador em contratos.
        </div>
      )}
    </div>
  )
}

export default withCompletedAttributionsContainer(index)
