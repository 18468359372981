import React from 'react'
import InputKeyValue from '../InputKeyValue'
import EditNavbar from '../EditNavbar'
import KeyDropdownValue from '../KeyDropdownValue'

const UserAddressEditForm = (props) => {
  const {
    availableCountries,
    availableStates,
    first_name : firstName,
    last_name: lastName,
    phone,
    email,
    address1,city,
    address2,
    country = {},
    state = {},
    main_name : mainName,
    zip_code : zipCode,
    creating,
    errors,
    ...actions
  } = props

  const handleOnSubmit = (event) => {
    event.preventDefault()

    const {
      address1,city,
      address2, zipCode,
      firstName,
      lastName, email,
      phone
    } = event.target

    actions.onEditSubmit({
      address1:  address1.value,
      city:  city.value,
      address2:  address2.value,
      zipCode:  zipCode.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
    })
  }

  return (
    <form className="mb-20 flex flex-col" onSubmit={handleOnSubmit}>
      <h1 className="g-profile__title">Morada - {mainName}</h1>
      <div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-12 col-sm-10 col-xs-12 remove-padding">
            <InputKeyValue
              colSpan={3}
              title="Email"
              name="email"
              defaultValue={email}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Nome"
              name="firstName"
              defaultValue={firstName}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Apelido"
              name="lastName"
              defaultValue={lastName}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Telemóvel"
              name="phone"
              defaultValue={phone}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Endereço"
              name="address1"
              defaultValue={address1}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Cidade"
              name="city"
              defaultValue={city}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Código Postal"
              name="zipCode"
              defaultValue={zipCode}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
           />
          </div>
        </div>
        <div className="row g-profile__info-data g-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <InputKeyValue
              title="Endereço 2"
              name="address2"
              defaultValue={address2}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyDropdownValue
              title="País"
              value={country}
              options={availableCountries}
              onChange={actions.onCountryChange}
              getOptionLabel={(option) => option.name}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyDropdownValue
              title="Região"
              value={state}
              options={availableStates}
              onChange={actions.onStateChange}
              getOptionLabel={(option) => option.name}
              classNameData="g-profile__info-resized"
              classNameTitle="g-profile__info-title"
            />
          </div>
        </div>
      </div>
      <EditNavbar onEditCancel={actions.onEditCancel}/>
    </form>

  )
}
export default UserAddressEditForm
