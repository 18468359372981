import React, { Component } from 'react';
import { Chevron, substring } from "../normalizer";
import StyledImage from "../../shared/StyledImage";
import Moment from 'moment';
import { t } from 'i18next';
class AcceleratorShow extends Component {
  render() {
    const { accelerator, imageSize, limitText } = this.props;
    const image = accelerator.image[imageSize] || '';

    if (window.location.href.includes('news')) {
      return (
        <>
          <StyledImage
            url={image ? image.url : ""}
            className="gl-promotion__image gl-promotion__image--announcement"
          />
          <div className="gl-promotion w-full">
            <div className="gl-promotion__title">
              <h3 className="gl-post__title gl-post__title--promotion ">{accelerator.name}</h3>
              <Chevron className="primary-fill gl-promotion__chevron" />
            </div>
            <div className="gl-promotion__description gl-promotion__description--announcement">
              <p>{substring(accelerator.subtitle, limitText, '...')}</p>
            </div>
            <p className="text-right">
              {Moment(accelerator.created_at).format(t("date-format"))}
            </p>
          </div>
        </>
      );
    }

    return (
      <div className='gl-home__promotion my-8' >
        <StyledImage
          url={image ? image.url : ""}
          className="gl-promotion__image gl-promotion__image--announcement"
        />
        <div className="gl-promotion w-full">
          <div className="flex between-lg between-md between-xs py-2">
            <h2 className="mb-1 font-bold primary-text">{accelerator.name}</h2>
            <Chevron className="primary-fill gl-promotion__chevron" />
          </div>
          <p className='gl-promotion__description gl-promotion__description--announcement'>
            {accelerator.subtitle ? substring(accelerator.subtitle, limitText, '...') : 'Sem subtitulo'}
          </p>
        </div>
      </div>
    );
  }
}

export default AcceleratorShow;