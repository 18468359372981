import React, { Component } from 'react'
import { connect } from 'react-redux'
import { 
  updateLastSeenProducts, saveWishes, updateProducts,
  startFetch, endFetch, buildTransaction,
  CartService, WishProductsService,ProductsService,
  saveCart
} from '../../normalizer';
import { Redirect } from 'react-router-dom'

class CatalogProductContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      notFound: false,
      currentItem: null
    }
  }

  payload = (item) => ({
    client_slug: this.props.client.slug,
    campaign_slug: this.props.campaign.slug,
    product_id: item.product.id
  })

  handleCartAddition = (item) => {
    const payload = {
      client_slug: this.props.client.slug,
      slug: this.props.campaign.slug,
      item_id: item.id
    }
    
    this.props.startTransaction(() =>
      CartService.addItem(payload)
        .then((response) => {
          this.props.saveCart(response.data)
          this.props.history.push('/cart')
        })
    )
  }

  handleFavAddition = (item) => {
    const payload = this.payload(item)
    this.props.startTransaction(() =>
      WishProductsService.addToWishes(payload)
        .then((response) => {
          const { wishes } = response.data
          this.props.saveWishes(wishes)
          this.props.updateProducts({ id: payload.product_id, favorite: true })
        })
    )
  }

  handleFavRemove = (item) => {
    const payload = this.payload(item)
    this.props.startTransaction(() =>
      WishProductsService.deleteFromWishes(payload)
        .then((response) => {
          const { wishes } = response.data
          this.props.saveWishes(wishes)
          this.props.updateProducts({ id: payload.product_id, favorite: false })
        })
    )
  }

  findCachedProduct(data = this.props) {
    const {
      lastSeenProducts,
      products,
      highlights,
      recent,
      wishes,
      mostViewed,
      match
    } = data

    const {id} = match.params
    return products.find(e => e.id.toString() === id) ||
            lastSeenProducts.find(e => e.id.toString() === id) ||
            highlights.find(e => e.id.toString() === id) ||
            wishes.find(e => e.id.toString() === id) ||
            recent.find(e => e.id.toString() === id) ||
            mostViewed.find(e => e.id.toString() === id)
  }

  tryGetProduct(){

    const payload = {
      client_slug: this.props.client.slug,
      campaign_slug: this.props.campaign.slug,
      product_id: this.props.match.params.id
    }

    this.props.startFetch(); //IsLoading: true

    ProductsService.getProductById(payload).then(response=>{
      const item = response.data
      this.props.updateLastSeenProducts(item)
      this.props.endFetch(); //IsLoading: false
      this.incrementViewCount()
    }).catch(error => {
      this.props.endFetch(); //IsLoading: false
      this.setState({notFound: true})
    })
  }

  incrementViewCount = () => {
    const currentItem = this.findCachedProduct()

    const payload = {
      client_slug: this.props.client.slug,
      campaign_slug: this.props.campaign.slug,
      product_id: this.props.match.params.id
    }

    ProductsService.incrementViewCount(payload)
      .then()
      .catch()
    this.setState({currentItem: currentItem})
  }

  generateValidProduct({product}){
    let { description } = product
    return {
      ...product,
      description: description === "" ? "Sem descrição." : description.trim()
    }
  }


  componentDidMount() {
    const currentItem = this.findCachedProduct()

    if(currentItem) {
      this.incrementViewCount()
      this.props.updateLastSeenProducts({product : currentItem})
      window.scrollTo(0, 0)
    } else {
      this.tryGetProduct()
    }
  }

  render() {
    const campaignItem = this.findCachedProduct()
    const {notFound} = this.state
    const {component: Component, ...rest} = this.props

    if(!campaignItem && notFound){
      return <Redirect to="/product/not-found"/>
    }
    if(!campaignItem) {
      return <div></div>
    }
    return (
      <Component {...rest}
        onFavAddition={this.handleFavAddition}
        onFavRemove={this.handleFavRemove}
        onCartAdd={this.handleCartAddition}
        item={campaignItem}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  lastSeenProducts: state.productsReducer.lastSeenProducts,
  highlights: state.productsReducer.highlights,
  wishes: state.productsReducer.wishes,
  recent: state.productsReducer.recent,
  mostViewed:state.productsReducer.mostViewed,
  client: state.userReducer.user.client,
  campaign: state.campaignReducer.campaign
})

const mapDispatchToProps = (dispatch) => ({
  updateLastSeenProducts: (product) =>  dispatch(updateLastSeenProducts(product)),
  startFetch: () => { dispatch(startFetch()) },
  endFetch: () => { dispatch(endFetch()) },
  saveWishes: (wishes) => {
    dispatch(saveWishes(wishes))
  },
  saveCart: (cart) => {
    dispatch(saveCart(cart))
  },
  updateProducts: (wish) => {
    dispatch(updateProducts(wish))
  },
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(CatalogProductContainer)

export function withCatalogProductContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
    )
  }

export default Container
