import React, { Component }from "react"
import Flag  from "./LevelFlag"
import Product from '../../shared/Product';

class LevelProductList extends Component {
  constructor(props) {
    super(props)
  }
  
  isLevelInferior(){
    const { selectedLevel, campaignUser } = this.props
    return  selectedLevel.level_number <= campaignUser.currentLevel.level_number
  }

  render (){

    const {levelProducts, selectedLevel, client} = this.props
    
    return (
      <div className="gl-levels__products">
        <div className="row gl-levels__products__title-container">
          <div className='col-sm-6 col-xs-12 gl-levels__products__title-subcontainer'>
            <Flag  mode={this.isLevelInferior() ? 'active' : 'next-level'} textAlign={'top'} height={'67px'}>
              <div>
                <p className="gl-levels__products__title-level-description">Nível</p>
                <p className="gl-levels__products__title-level-text">{selectedLevel.name}</p>
              </div>
            </Flag>
            <span className='gl-levels__products__title-text'>Prémios de Destaque</span>
          </div>
          { this.isLevelInferior() ?
          <div>
          </div>
        :
          <div className='text-left md:text-right col-sm-5 col-xs-12 gl-levels__products__title-highlevel-container'>
            <p className="pl-2 md:pl-0  gl-levels__products__title-highlevel-text">Esta montra de prémios só está disponível no nível seguinte</p>
          </div>
        }
        </div>
       
        <div className="row justify-between -mx-bs">
        {
          levelProducts.map((p,i)=> (
            <Product
              key={`p-levels-id-${p.id}-${i}`}
              currencyLabel={client.currency_label}
              canBuy={this.isLevelInferior()}
              {...p}
            />

          ))
        }
      </div>
      </div>
    )
  }
}

export default LevelProductList