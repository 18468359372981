import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductCarousel from '../shared/ProductCarousel';
import BannerSlider from '../shared/BannerSlider'
import styled from 'styled-components'
import {
  saveRankings,
  saveWishes,
  saveHighlights,
  saveRecent,
  saveMostViewed,
  saveHomepageBanners,
  startFetch,
  endFetch,
  ProductsService,
  RankingsService,
  BannersService,
  NewsService,
  saveUser,
  withResizeContainer
} from '../normalizer'
// import '../../assets/css/slider.scss';
import RankingHome from './RankingHome';
import {prefix} from '../../../i18n'
import NewsFeed from './NewsFeed'
import gradient from '../../../assets/img/gradiente-destaques.png'
const tHome = prefix('home')
const HighlightContainer = styled.div`
  background-image: url(${props=> props.url || gradient });
  background-repeat: no-repeat;
  background-size:100% 100%;
`
class Home extends Component{
  constructor(props){
    super(props);
    this.state={
      fetched_wishes: false, 
      ranking: {
        percentil: 0,
        position: 0, 
        updated_at: 'N/A'
      },
      newsFeed: []
    };
  }

  getWishes(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getWishes(payload).then((response) => {
      const wishes = response.data
      this.props.saveWishes(wishes);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getRecent(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getRecent(payload).then((response) => {
      const recentProducts = response.data
      this.props.saveRecent(recentProducts);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getMostViewed(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getMostViewed(payload).then((response) => {
      const mostViewed = response.data
      this.props.saveMostViewed(mostViewed);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
    
  }

  getHighlights(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
       campaign_slug: campaign.slug 
    }
    ProductsService.getHighlights(payload).then((response) => {
      const highlights = response.data
      this.props.saveHighlights(highlights);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getHomeRanking() {
    const rankings = this.props.rankings
    if (rankings.length > 0){
      this.setState({ranking: rankings[0]})
      return; 
    }
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug
    const payload = {
      client_slug: clientSlug,
      campaign_slug: campaign.slug 
    }
    RankingsService.getRankings(payload)
      .then((response) => { 
        const data = response.data
        if ( data.rankings.length > 0){
          this.setState({ranking: data.rankings[0]})
        }
        this.props.saveRankings(data)
        this.props.endFetch();
      })
      .catch((error) => {
        this.props.endFetch();
      })
  }

  getHomepageBanners() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      client_slug: clientSlug,
      campaign_slug: campaign.slug 
    }
    this.props.startFetch();
    BannersService.getHomepageBanners(payload).then((response) => { 
      this.props.saveHomepageBanners(response.data);
      this.props.endFetch();
    })
    .catch((error) => {
      this.props.endFetch();
    })
  }

  getHomepageNews() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug 
    }

    NewsService.getHomepageNews(payload).then(response => {
      const feed = response.data["content/news"]
      this.setState({newsFeed: feed})
    }).catch(e => {})
  }

  componentDidUpdate(prevProps){
    if(prevProps.campaign !== this.props.campaign ){
      this.getWishes()
      this.getHighlights()
      this.getMostViewed()
      this.getRecent()
      this.getHomeRanking()
      this.getHomepageBanners()
    }
  }

  componentDidMount(){
    const {
      highlights,
      wishes,
      recent,
      mostViewed,
      banners,
      user,
      campaign
    } = this.props;
    if(user.client && campaign.slug) {
      if (wishes.length === 0){
        this.getWishes()
      }
      if (highlights.length === 0 ){
        this.getHighlights()
      }
      if (recent.length === 0 ){
        this.getRecent()
      }
      if (mostViewed.length === 0 ){
        this.getMostViewed()
      }
      if (banners.length === 0){
        this.getHomepageBanners()
      }
      this.getHomepageNews()
      this.getHomeRanking()
    }
  }

  render() {
    const {
      campaign,
      highlights,
      wishes,
      recent,
      mostViewed,
      banners,
      toRender,
      imageSize
    } = this.props;

    const {
      ranking,
      newsFeed
    } = this.state
    const doesRankingRender = toRender('Rankings')
    const doesNewsRender = toRender('News')
    const nothingRenders = !doesRankingRender && !doesNewsRender

    const sliderData = banners.map((element) => ( 
        { 
          'id': element.id, 
          'src': element[imageSize] ? element[imageSize].url : '', 
          'url': element.url, 
          'text': element.text
        } 
      ));
    const RankingOrNewsFeed = (doesRankingRender || doesNewsRender) &&
      <div className ="col-md-4 col-xs-12 flex flex-col col-xs mt-16">
        {doesRankingRender && <RankingHome ranking={ranking} campaign = {campaign}/>}
        {doesNewsRender && <NewsFeed campaign={campaign} feed={newsFeed}/> }
      </div> 
   
    return (
      <div className='font-quicksand'>
        <div className="row center-lg center-xs center-md slider">
          <div className="col-lg-12 col-md-12 col-xs-12">
            <BannerSlider data={sliderData} backgroundColor = {campaign.color_primary} borderColor = { campaign.color_secondary } />
          </div>
        </div>
        <div style={{backgroundColor: '#EAEAEF'}}>
          <div className="cont row start-lg start-xs start-md">
            <div className={`${nothingRenders ? 'col-md-12' : 'col-md-8'} col-xs-12 mt-16 `}>
              <div className ="mb-10">
                <ProductCarousel 
                  icon={'highlights'} 
                  productsNumber= {nothingRenders ? 4 :3} 
                  products = {recent} 
                  campaign = {campaign} 
                  title={tHome('last-entries')} 
                  lineColor="#82D643"/>
              </div>
              <ProductCarousel 
                productsNumber= {nothingRenders ? 4 :3} 
                products = {wishes} 
                campaign = {campaign} 
                title={tHome('my-favourites')} 
                lineColor="#002D6D"/>              
            </div>
            {RankingOrNewsFeed}
          </div>
          <HighlightContainer url={campaign.highlight_image.url} className="padding-cont row start-lg start-md start-xs mt-16" >
            <div className="col-xs-12 my-16 catalog">
              <ProductCarousel  
                catalog 
                transparent
                productsNumber= {4} 
                products = {highlights} 
                campaign ={campaign} 
                fontColor={'text-white'} 
                title={tHome('highlights')}  
                lineColor="#ef8115"/>
            </div>
          </HighlightContainer>
          <div className="cont row center-lg center-xs center-md" >
            <div className="col-lg-12 col-md-12 col-xs-12 my-16">
              <ProductCarousel 
                icon={'most-viewed'}
                productsNumber= {4}
                products = {mostViewed} 
                campaign ={campaign} 
                title={tHome('more-viewed')}  
                lineColor="#82D643"/>
            </div>
          </div>
        </div> 
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    products: state.productsReducer.products,
    highlights: state.productsReducer.highlights,
    wishes: state.productsReducer.wishes,
    recent: state.productsReducer.recent,
    mostViewed: state.productsReducer.mostViewed,
    banners: state.homepageBannersReducer.banners,
    rankings: state.rankingsReducer.rankings
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes));
    },
    saveRecent: (recent) => {
      dispatch(saveRecent(recent));
    },
    saveMostViewed: (mostViewed) => {
      dispatch(saveMostViewed(mostViewed));
    },
    saveRankings: (rankings) => {
      dispatch(saveRankings(rankings));
    },
    saveHighlights: (highlights) => {
      dispatch(saveHighlights(highlights));
    },
    saveHomepageBanners: (banners) => {
      dispatch(saveHomepageBanners(banners));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}
const HomeComponent = connect(mapStateToProps,mapDispatchToProps)(Home)
export default withResizeContainer(HomeComponent);

