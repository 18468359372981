import React from "react";
import { Route } from "react-router-dom";
import ObjectiveCard from "./ObjectiveCard";
import ObjectiveModal from "./ObjectiveModal";

const ObjectivesList = props => {
  const { client, currencyLabel, objectives, onObjectSelect, onUnselectObject, selected } = props;

  let first = undefined;
  let otherObjectives = undefined;

  if (objectives) {
    first = objectives[0];
    otherObjectives = objectives.slice(1);
  }
  //console.log('Valor de clientSlug:', client.slug);

  return (
    <>
      <div className="row">
        <ObjectiveCard
          onClick={() => onObjectSelect(first)}
          clientSlug={client.slug}
          {...first}
          key={"first"}
          isFirst
        />
        {otherObjectives.map(o => (
          <ObjectiveCard 
            onClick={() => onObjectSelect(o)}
            clientSlug={client.slug}
            {...o}
            key={o.id}
          />
        ))}
      </div>
      <Route path="/objectives/:id">
        {selected && (
          <ObjectiveModal
            selected={selected}
            onUnselectObject={onUnselectObject}
            currencyLabel={currencyLabel}
          />
        )}
      </Route>
    </>
  );
};

export default ObjectivesList;