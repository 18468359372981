
import axios from './interceptor';

export const BalanceService = {
  getUserBalance({clientSlug, campaignId, page}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/points`
    return axios({
      method: 'get',
      url: url,
      params:{
        page: page
      }
    });
  },
  getUserExpired({clientSlug, campaignId, page}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/points/expired`
    return axios({
      method: 'get',
      url: url,
      params:{
        page: page
      }
    });
  },
  getUserPoints({clientSlug, campaignId}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/users`
    return axios({
      method: 'get',
      url: url
    });
  },
  requesPointsExport({clientSlug, campaignId}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/points/export`
    return axios({
      method: 'get',
      url: url
    });
  },
  getUserHistory({clientSlug, campaignId, timePage, selectedTime, date}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/points/history_view`
    return axios({
      method: 'get',
      url: url,
      params: {
        selected_time: selectedTime,
        date: date,
        time_page: timePage
      }
    });
  },
  requestUserProduction({clientSlug, campaignId}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignId}/sales/send_email_with_sales`
    return axios({
      method: 'get',
      url: url
    });
  }
}