export const saveQuizzes = (quizzes, pagination) => ({
  type: 'SAVE_QUIZZES',
  payload : {
    quizzes,
    pagination
  }
})

export const saveHomeQuiz = (quiz) => ({
  type: 'SAVE_HOME_QUIZ',
  payload : {
    homeQuiz: quiz
  }
})

export const saveQuizHistory = (quizzes, pagination) => ({
  type: 'SAVE_QUIZZES_HISTORY',
  payload : {
    history: quizzes,
    historyPagination: pagination
  }
})

export const saveQuizResponse = (payload) => ({
  type: 'SAVE_QUIZ_RESPONSE',
  payload
})

export const moveToHistory = (quizzes,pagination,id) => ({
  type: 'MOVE_QUIZ_TO_HISTORY',
  payload: {
    id,
    history: quizzes,
    historyPagination: pagination
  }
})

