import React from 'react'
import Moment from 'moment'
import { t } from '../../../../i18n';
import { formatNumber } from '../../normalizer';

import Pagination from '../../shared/Pagination';
const Row = ({description, points_earned, date, expired_date}) => {

    return (
      <tr className="gl-profile__info-row table-body-color" >
        <td className="py-5 pr-2">{description}</td>
        <td className="pr-2 text-center">{new Moment(date).format(t('date-format'))}</td>
        {!!expired_date && <td className="pr-2 text-center"><p>{Moment(expired_date).format(t('date-format'))}</p></td>}
        <td className="text-right">{formatNumber(points_earned)}</td>
      </tr>
    )
  }

const BalanceTable = (props) => {
    const {
        currencyLabel,
        points,
        pagination,
        page,
        onPageChange
      } = props
    
      const thereIsExpiredDate = points.filter( p => !!p.expired_date );

      return (
        <section className="overflow-x-auto w-full">       
          <table className={"mb-10 w-full gl-profile__table"}>
            <thead className="primary-text">
              <tr className="gl-profile__info-row" >
                <th className="w-5/7 text-left py-3">Descrição</th>
                <th className="w-1/7 text-center">Data</th>
                {(thereIsExpiredDate.length > 0) && <th className="w-1/7 text-center">Validade dos Pontos</th>}
                <th className="w-1/7 text-right">{currencyLabel}</th>
              </tr>
            </thead>
            <tbody className="">
              {points.map(p => <Row {...p} key={p.id} />)}
            </tbody>
          </table>
          { pagination.total_pages > 1  &&
            <div className="flex justify-end mt-10">
              <Pagination
                totalPages={pagination.total_pages}
                currentPage={page}
                onPageChange={onPageChange}/>
            </div>
          }
        </section>
    )
}

export default BalanceTable
