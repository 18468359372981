
export function saveBalance(balance){
  return {
    type: 'SAVE_BALANCE',
    payload: balance
  }
}

export function saveCampaignUser(user){
  return {
    type: 'SAVE_CAMPAIGN_USER',
    payload: user
  }
}

export function saveOrderPayment(data){
  return {
    type: 'SAVE_ORDER_PAYMENT',
    payload: data
  }
}

export function saveValidity(balance){
  return {
    type: 'SAVE_VALIDITY',
    payload: balance
  }
}

export function saveHistory(history){
  return {
    type: 'SAVE_HISTORY',
    payload: history
  }
}