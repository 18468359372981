import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { 
  buildTransaction, saveQuizzes, 
  saveQuizHistory, QuizzService 
} from '../../normalizer';

class QuizzContainer extends Component {

  getAllQuizzes() {
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      page: 1 
    }

    this.props.startTransaction(() =>
      QuizzService.getAll(payload)
        .then(response => response.data)
        .then(data => this.props.saveQuizzes(data.quizzes, data.pagination))
    )
  }

  getQuizHistory() {
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      page: 1 
    }

    this.props.startTransaction(() =>
      QuizzService.getQuizHistory(payload)
        .then(response => response.data)
        .then(data => this.props.saveHistory(data.quizzes_completed, data.pagination))
    )
  }

  componentDidMount() {
    this.getAllQuizzes()
    this.getQuizHistory()
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
      <Component {...rest} />
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaing: state.campaignReducer.campaign,
  quizzes: state.quizReducer.quizzes,
  quizHistory: state.quizReducer.history
})

const mapDispatchToProps =(dispatch) =>  ({
  startTransaction: buildTransaction(dispatch),
  saveQuizzes: (quizzes, pagination)=> dispatch(saveQuizzes(quizzes, pagination)),
  saveHistory: (quizzes, pagination)=> dispatch(saveQuizHistory(quizzes, pagination)),
})

const Container = connect(mapStateToProps, mapDispatchToProps)(QuizzContainer)

export function withQuizContainer(component) {
  const QuizzContainerWrapper = (props) => (
    <Container component={component} {...props} />
  )
  return QuizzContainerWrapper
}

export default Container
