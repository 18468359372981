import React from 'react'
import { Switch} from 'react-router-dom';
import CustomRoute from '../../../routes/CustomRoute'
import { withQuizContainer } from '../../containers/gamification';
import QuestionCard from './QuestionCard';
import TodoQuizes from './TodoQuizes';
import QuizHistory from './QuizHistory';
import QuizHistoryCard from './QuizHistoryCard';

const Quiz = ({quizzes =[], quizHistory=[]}) => {
  return (  
    <>
      <Switch>
        <CustomRoute 
          exact
          path="/quiz/history"
          quizzes={quizHistory} 
          component={QuizHistory}
        />
        <CustomRoute 
          exact
          path="/quiz/history/:id"
          component={QuizHistoryCard}
        />

        <CustomRoute 
          exact 
          path="/quiz/:id" 
          component={QuestionCard}
        />

        <CustomRoute 
          path="/quiz" 
          quizzes={quizzes} 
          component={TodoQuizes}
        />
      </Switch>
    </>
  )
}

export default withQuizContainer(Quiz)
