import React, { Component } from "react"
import BannerSlider from "./BannerSlider"
import Navbar from "./Navbar"
import { withContainers, ResizeContainer, BannerContainer } from "../../normalizer"
import User from "./User"
import { matchPath } from "react-router"

class Header extends Component { 

  state = {
    mobileFriendly: false
  } 

  resize = () => {
    this.setState({
      mobileFriendly: window.innerWidth < 576
    })
  }
  
  componentDidMount(){
    window.addEventListener("resize",this.resize)
    this.resize();
  }
  componentWillUnmount(){
    window.removeEventListener("resize",this.resize)
  }
  render() {
    const {mobileFriendly} = this.state
    const { banner, campaign, toRender, fontFamily, banners, imageSize } = this.props
    const { pathname } = this.props.location
    const bannersProps = banners.map((element, i) => {
      if (element && element.banner) {
        let size = imageSize;
        const croppedImageKey = size.replace('image', 'cropped_image');
        const imageUrl = element.banner[croppedImageKey] && element.banner[croppedImageKey].url
                          ? element.banner[croppedImageKey].url
                          : (element.banner[size] ? element.banner[size].url : '');
    
        return {
          gradient: element.banner.gradient_type,
          title: element.banner.title,
          link: element.banner.url,
          url: imageUrl,
          displayBanner: true
        };
      } else {
        return {};
      }
    });
    
    

    const paths = ["profile", "cart", "analytics"]
    const find = paths.find(p => matchPath(pathname, { path: `/${p}` }))
    const headerClasses = [
      "default-bg gl-header",
      fontFamily
    ].join(" ")

    return (
      <header className={headerClasses}>
        <Navbar logo={campaign.logo} gradient={campaign.gradient_type} toRender={toRender}/>
        {!find && (
          <>
            <User mobileView={mobileFriendly} toRender={toRender}/>
            <BannerSlider data={bannersProps} mobileView={mobileFriendly} backgroundColor={campaign.color_primary}/>
          </>
        )}
      </header>
    )
  }
}

export default withContainers(Header, [ResizeContainer, BannerContainer])
