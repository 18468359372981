import React from 'react'
import KeyValue from '../KeyValue'
import CustomButton from '../CustomButton'

const UserAddress = (props) => {
  const {
    setEditing,
    first_name : firstName,
    last_name: lastName,
    phone,
    email,
    address1,city,
    address2,
    country = {},
    state = {},
    main_name : mainName,
    zip_code : zipCode,
    onRemove,
    isShowOnly
  } = props

  const hasMainName = mainName !== ''

  return (
    <section className={isShowOnly ? 'flex flex-col' : 'mb-20 flex flex-col'}>
      {!isShowOnly && hasMainName && <h1 className='gl-profile__title'>Morada - {mainName}</h1>}
      {!isShowOnly && !hasMainName && <h1 className='gl-profile__title'>Morada</h1>}
      <div className="gl-profile__address-container mb-10">
        <div className="row gl-profile__info-data gl-profile__info-block m-0">
          <div className="col-md-10 col-sm-10 col-xs-12 remove-padding">
            <KeyValue
              colSpan={3}
              title="Email"
              value={email}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
        </div>
        <div className="row gl-profile__info-data gl-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Nome"
              value={firstName}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Apelido"
              value={lastName}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Telemóvel"
              value={phone}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
        </div>
        <div className="row gl-profile__info-data gl-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Endereço"
              value={address1}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Cidade"
              value={city}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Código postal"
              value={zipCode}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
        </div>
        <div className="row gl-profile__info-data gl-profile__info-block m-0">
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Endereço 2"
              value={address2}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="País"
              value={country.name}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
            <KeyValue
              title="Região"
              value={state.name}
              classNameData="gl-profile__info-resized"
              classNameTitle="gl-profile__info-title"
            />
          </div>
        </div>
      </div>
      { isShowOnly ? null :
        <div className="flex justify-between gl-profile__between">
          <CustomButton outline onClick={onRemove}>Remover</CustomButton>
          <CustomButton outline onClick={setEditing}>Editar</CustomButton>
        </div>
      }
    </section>
  )
}

export default UserAddress
