import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUser } from '../../../actions/userActions';
import { Redirect } from 'react-router';
import { startFetch, endFetch, doLogout } from '../../../actions/generalActions';
import {ToastContainer, ToastStore} from 'react-toasts';
import incentivehouse from '../../../assets/img/incentivelogo.png';
import { UserService, AuthenticationService } from '../../../services';
import Consent from '../Consent';
import Terms from './Terms';


class TermsOfService extends Component{

  constructor(props){
    super(props);
    this.state={
      redirect: false, 
      terms: '',
      seeMoreClicked: false,
      consents: []
      
    };
  }

  onClickSeeMore = () => {
    this.setState({
      seeMoreClicked: true
    })
  }

  getTerms(){
    this.props.startFetch()
    UserService.getTermsOfService().then((response) => {
    const {terms, consents} = response.data
     this.setState({
       terms: terms.terms_of_service, 
       consents: consents || []
      })
     this.props.endFetch();
    })
    .catch(() => {
      this.setState({terms: 'Sem termos de serviço'})
      this.props.endFetch()
    });

  }
  acceptsTerms(){
    this.props.startFetch()
    const consents = this.state.consents
    const consentsToSend = consents.map((consent,i) => {
      return {id: consent.id, value: consent.accepted}
    })
    UserService.acceptsTermsOfService(consentsToSend)
      .then((response) => {
        const user = response.data.user
        this.props.saveUser(user)
        localStorage.setItem('user', JSON.stringify(user));
        this.props.endFetch()
      })
      .catch((error) => {
        this.props.endFetch()
      });
  }

  declineTerms(){
    this.props.startFetch()
    UserService.logOut()
      .then((response) => {
        AuthenticationService.logout()
        this.props.doLogout()
        this.props.endFetch()
      })
      .catch((error) => {
        this.props.endFetch()
      });
  }

  updateConsent = (e, consent_id) => {
    const consents = this.state.consents
    const updatedConsents = consents.map(consent => {
      if(consent.id === consent_id){
        return { ...consent, accepted: e.target.checked }
      }
      return { ...consent }
    })
    this.setState({
      consents: updatedConsents
    })
  }

  
  componentDidMount(){
    this.getTerms()
  }

  render() {
    const { 
      user
    } = this.props;
    const {
      redirect,
      terms,
      consents
    } = this.state
    
    if (user.confirm_terms || redirect){
      return <Redirect to={`/`} />
    }
  
    return (
      <div className='image-background py-10 items-center' style={user.client.background_banner.url ? {backgroundImage: 'url(' + user.client.background_banner.url + ')'}: {} } >
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
        <div className="cont row default-container p-10 ">
          <div className='col-xs-12 py-10 center-xs'>
            <img style={{width:'260px'}} src={user.client.logo.url ? user.client.logo.url : incentivehouse } />
          </div>
          <Terms text= {terms} onClickEvent={this.onClickSeeMore} seeMore={this.state.seeMoreClicked} />
          { this.state.seeMoreClicked &&
              <>
              { consents.map((consent,i) => {
                return <Consent {...consent} formUpdate = {this.updateConsent} key={consent.id} />
              })
              }
           
           <div className='flex self-center col-xs-12 end-xs '>
            <button className="button-secondary user-button mx-10" onClick = {(e) => this.declineTerms() }>Cancelar</button>
            <button className="button-primary user-button" onClick = {(e) => this.acceptsTerms() } >Aceitar</button>
           </div>
           </>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user
  } 
}
function mapDispatchToProps(dispatch){
  return{
    doLogout: () => {
      dispatch(doLogout());
    },
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);