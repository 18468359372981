import React from 'react'

export { default as HomeContainer, withHomeContainer} from './HomeContainer'
export { default as CampaignUserContainer, withCampaignUserContainer} from './CampaignUserContainer'
export { default as AddressContainer, withContryStateContainer} from './CountryStateContainer'
export { default as CatalogProductContainer, withCatalogProductContainer} from './Catalog/CatalogProductContainer'
export { default as ObjectiveContainer, withObjectiveContainer} from './ObjectiveContainer'
export { default as ShoppingCartContainer, withShoppingCartContainer} from './ShoppingCartContainer'
export { default as QuizContainer, withQuizContainer} from './Quiz/QuizContainer'    
export { default as QuizQuestionContainer, withQuestionContainer} from './Quiz/QuizQuestionContainer'
export { default as QuizQuestionHistoryContainer, withQuizQuestionHistoryContainer} from './Quiz/QuizQuestionHistoryContainer'
export { default as PopUpContainer, withPopUpContainer} from './PopUpContainer'
export { default as ClientContainer, withClientContainer} from './ClientContainer'
export { default as ObjectiveAnalyticsContainer, withObjectiveAnalyticsContainer} from './Analytics/ObjectiveAnalyticsContainer'
export { default as GroupSalesContainer, withGroupSalesContainer} from './Analytics/GroupSalesContainer'
export { default as AnalyticsContainer, withAnalyticsContainer} from './Analytics/AnalyticsContainer'
export { default as AcceleratorsContainer, withAcceleratorsContainer} from './AcceleratorsContainer'
export { default as BannerContainer, withBannerContainer} from './BannerContainer'
export { default as TemplateContainer, withTemplateContainer} from './TemplateContainer'
export { default as PostContainer, withPostContainer} from './PostContainer'
export { default as LevelContainer, withLevelContainer} from './LevelContainer'
export { default as PointBlockContainer, withPointBlockContainer} from './PointBlockContainer'
export { default as BonusPointsContainer, withBonusPointsContainer} from './BonusPointsContainer'
export { default as NewsContainer, withNewsContainer} from './NewsContainer'
export { default as FeedContainer, withFeedContainer} from './FeedContainer'

const containerReducer = (FinalComponent, CurrentContainer) => {
  return (props) => {
    return <CurrentContainer component={FinalComponent} {...props}/>
  }
}

export function withContainers(component, containers) {
  const Container = containers.reduce(containerReducer, component)
  return (props) => <Container {...props}/>
}

export function HOC(Container, component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
} 