const initialState = {
  quizzes : [],
  history: [],
  homeQuiz: undefined,
  pagination : {},
  historyPagination: {},
  cache: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SAVE_QUIZZES':
    case 'SAVE_QUIZZES_HISTORY':
    case 'SAVE_HOME_QUIZ':
      return { ...state, ...payload }
    case 'SAVE_QUIZ_RESPONSE':  
      const { quizId, questionId, answerId} = payload
      return {
        ...state,
        cache: {
          ...state.cache,
          [quizId] : {
            ...state.cache[quizId],
            [questionId] : answerId
          }
        }
      }
    case 'MOVE_QUIZ_TO_HISTORY':
        const {id, history, historyPagination} = payload
        const quizzes = state.quizzes.filter(quiz => quiz.id.toString() !== id.toString())
        
        return {
          ...state,
          quizzes,
          history,
          historyPagination
        }
    default:
      return state
  }
}
