import React from 'react'
import { withBalanceContainer }  from "../../normalizer"


const InspectCard = ({Condition, setCondition, Selected}) =>{
  
  return (
    <div>
        <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', margin:'10px auto', textAlign:'center', marginBottom:'15px'}}>
            <h4>Cartão: {Selected.number}</h4>
            <button style={{backgroundColor:'#336B85', color:'white', height:'40px', width:'200px', borderRadius:'10px'}} onClick={ event => setCondition(null)}> Voltar </button>
        </div>
        {Condition != null && <section className="w-full">
        <div className="overflow-x-auto">
          <table className={"mb-10 w-full"}>
            <thead className="text-color gl-profile__table">
              <tr className="gl-profile__info-row">
                <th className="w-15 prod-th-title order-th text-left">Encomenda</th>
                <th className="w-10 prod-th-title  text-left">Carregado em</th>
                <th className="w-10 prod-th-title  text-center">Estado</th>
                <th className="w-5 prod-th-title  text-center">Valor Carregado</th>
              </tr>
            </thead>
            <tbody className="dead-grey">
            {Selected == null || Selected.recharges == null || Selected.recharges.length == 0 ? 
                <tr className="gray user"  >
                  <td className="p-4"> --- </td>
                  <td className="p-4 whitespace-no-wrap"> --- </td>
                  <td className='p-4 text-center'> --- </td>
                  <td className='p-4 text-center'> --- </td>
                </tr> 
              : Selected.recharges.map((value)=>(
                <tr className="gray user"  >
                  <td className="p-4"> {value.order.number} </td>
                  <td className="p-4 whitespace-no-wrap"> {value.requested_at} </td>
                  <td className="p-4 text-center"> {value.state.label} </td>
                  <td className='p-4 text-center'> {value.requested_value} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>}
    </div>
  )
}

export default withBalanceContainer(InspectCard)
