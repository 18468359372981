import React from "react"
import { withObjectiveContainer } from "../../containers/gamification"
import EmptyStateLayout from "../shared/EmptyStateLayout"
import { ReactComponent as NoGoal } from "../../../assets/img/No_Goal.svg"
import ObjectivesList from "./ObjectivesList"

const Objectives = props => {
  const { objectives, ...rest } = props

  const message = "Não existem resultados para apresentar"
  return objectives.length > 0 ? (
    <ObjectivesList currencyLabel = {props.client.currency_label} objectives={objectives} {...rest} />
  ) : (
    <EmptyStateLayout message={message}>
      <div className="g-no-store">
        <NoGoal className="mr-3 dead-grey g-no-store__image" />
      </div>
    </EmptyStateLayout>
  )
}

export default withObjectiveContainer(Objectives)
