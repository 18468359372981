import React from "react";
import { Link } from "react-router-dom";
import { BannerContainer, ClientContainer, HomeContainer, PostContainer, withContainers } from "../normalizer";
import NewsFeed from "./NewsFeed";
import PostFeed from "./PostFeed";

const SeeMore = (
  <div className="flex justify-between mb-5 navbar-text ">
    <h3>Destaques</h3>
    <Link to={"/catalog"} className="link-reset">
      Ver todos
      <i className="ml-3 fas fa-arrow-right" />
    </Link>
  </div>
)

function Home(props) {
  
  const {
    posts,
    feed,
    toRender
  } = props

  return (
  
      <div className="row g-home__container text-color  transparent">
        {/* <LeftBox quiz={homeQuiz} objective={endingObjective} /> */}
        <PostFeed posts={posts} toRender={toRender} />
        <NewsFeed feed={feed}/>
        {/* <Ranking ranking={ranking} title="Ranking"/> */}
      </div>
    
  )
}

export default withContainers(Home, [
  HomeContainer,
  BannerContainer,
  ClientContainer,
  PostContainer
])
