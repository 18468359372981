import React, { Component } from 'react';
import { prefix } from '../../../i18n';
import { connect } from 'react-redux';
import { saveCart} from '../../../actions/cartActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import { saveCampaignUser } from '../../../actions/balanceActions'
import {ToastContainer, ToastStore} from 'react-toasts';
import CartItems from '../shared/CartItems';
import '../../../assets/css/slider.scss';
import styled from 'styled-components';
import { Redirect } from "react-router-dom";
import { CartService } from '../../../services';
import CartAddressShow from '../shared/AddressShow';
import CartWarning from './CartWarning'
import ContentLayout from '../shared/ContentLayout';
import CartNavigation from './CartNavigation'
import CartCheckoutModal from './shared/CartCheckoutModal';
import CoPaymentModal from './shared/CoPaymentModal'
import PaymentMethodsModal from './shared/PaymentMethodsModal'

const tCartCheckout = prefix("cart-checkout")

const ButtonPrimary = styled.button`
  color: ${props=> props.navbar_text_color};
  background: ${props=> props.color_primary};
  border: 2px solid ${props=> props.color_primary};
  :hover{
    background: ${props=> props.navbar_text_color};
    color: ${props=> props.color_primary};
  }
`
class CartCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showAlert: false,
      alertMsg: "",
      showPaymentMethodsModal: false,
      showCoPaymentConditionsModal: false,
      shouldUseCoPayment: false,
      acceptCoPaymentConditions: false,
      currentStage: 0,
      userHasNoPoints: false,
      checkoutStages: [
        "checking-points",
        "checking-percentage",
        "checking-cart",
        "checking-terms",
        "choosing-payment-method",
        "checking-end",
      ],
      selectedPaymentMethod: null,
      hasEnoughPoints: false,
      overcomeMaxPercentage: false,
      restingPoints: 0,
      noCoPaymentItems: [],
      debtPoints: 0,
    };
  }

  componentDidUpdate(prevState, prevProps) {
    const { campaign: { confirmation_message } } = this.props;
    if ( prevState && prevState.checkingEnd && this.state.checkingEnd && prevState.checkingEnd !== this.state.checkingEnd ) {
      this.setAlertMsg(confirmation_message);
    }
  }

  componentDidMount() {
    // console.log(" CartCheckout - DidMount ", this.state)
    const { cart: { allows_co_payments, payment_definitions, total_points, items }, campaignUser: { availablePoints } } = this.props;
    const maximum_percentage = payment_definitions ? payment_definitions.maximum_percentage : 0;
    const noCoPaymentItems = this.filterNoCoPaymentProducts(items);
    const { userHasNoPoints, hasEnoughPoints, overcomeMaxPercentage, restingPoints, debtPoints } = this.userCheckoutRestrictions(availablePoints, total_points, maximum_percentage );
    this.setState((prevState) => ({
      ...prevState,
      allows_co_payments,
      userHasNoPoints,
      hasEnoughPoints,
      overcomeMaxPercentage,
      restingPoints,
      debtPoints,
      noCoPaymentItems,
    }));
  }

  openAlertModal = () => {
    const { userHasNoPoints, hasEnoughPoints, allows_co_payments } = this.state;
    if (userHasNoPoints && !allows_co_payments) {
      ToastStore.error(tCartCheckout("user-has-no-points"))
    } else if ( !hasEnoughPoints && !allows_co_payments) {
      ToastStore.error(tCartCheckout("user-has-not-enough-points"))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showAlert: true,
        showCoPaymentConditionsModal: true,
      }));
    }
  };

  closeAlertModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  openCoPaymentConditionsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: true,
    }));
  };

  closeCoPaymentConditionsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCoPaymentConditionsModal: false,
    }));
  };

  openPaymentMethodsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: false,
      showPaymentMethodsModal: true,
    }));
  };

  closePaymentMethodsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPaymentMethodsModal: false,
    }));
  };

  setAlertMsg(message) {
    this.setState((prevState) => ({
      ...prevState,
      alertMsg: message,
    }));
  }

  setAcceptCoPaymentConditions = (e) => {
    e.preventDefault();
    const { target } = e;
    this.setState((prevState) => ({
      ...prevState,
      acceptCoPaymentConditions: target.checked,
      showAlert: true,
      showCoPaymentConditionsModal: false,
      showPaymentMethodsModal: true,
      currentStage: this.nextStage(),
    }));
  };

  goToTermsStage = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: true,
      currentStage: this.nextStage(),
    })); 
  }

  goToFinalStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: 5 })) 
  }

  goToNextStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: this.nextStage() })) 
  }

  goToPreviousStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: this.previousStage() })) 
  }
  
  previousStage = () => {
    const { currentStage, checkoutStages } = this.state
    return ( currentStage - 1) % checkoutStages.length
  }

  nextStage = () => {
    const { currentStage, checkoutStages } = this.state
    return (currentStage + 1) % checkoutStages.length
  }

  handleShouldUseCoPayment = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCoPaymentConditionsModal: true,
      shouldUseCoPayment: true,
      currentStage: this.nextStage(),
    }));
  };

  handleWishRemoveProducts = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentStage: this.nextStage(),
      redirect: true,
    }));
  }

  handleChoosePaymentMethod = (method) => {
    this.setState(prevState => ({
      ...prevState,
      showAlert: true,
      showCoPaymentConditionsModal: false,
      selectedPaymentMethod: method,
      currentStage: this.nextStage(),
    }))
  }

  handleModalCancellationAction = () => {
    this.closeAlertModal();
  };

  filterNoCoPaymentProducts = (items) =>
    items.filter((item) => !item.product.accepts_co_payment);

  checkout = () => {
    const { user, campaign, cart: { allows_co_payments } } = this.props;
    const { shouldUseCoPayment, acceptCoPaymentConditions, selectedPaymentMethod } = this.state
    this.props.startFetch();
    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug,
      co_payment: allows_co_payments && shouldUseCoPayment ? {
        use_co_payment: shouldUseCoPayment,
        accepted_payment_terms: acceptCoPaymentConditions,
        payment_method_id: selectedPaymentMethod.id
      } : null
    };

    CartService.CartCheckout(payload)
      .then((response) => {
        ToastStore.success(tCartCheckout("checkout-successfully"));
        this.props.saveCampaignUser(response.data);
        CartService.getCart(payload).then((response) => {
          this.props.saveCart(response.data);
        });
        this.props.endFetch();
        this.props.history.push("/cart_sucess");
      })
      .catch((error) => {
        if (!!error && !!error.response && !!error.response.data) {
          ToastStore.error(error.response.data.error);
        }
        this.props.endFetch();
      });
  };

  userCheckoutRestrictions = ( user_points, cart_total_points, maximum_percentage ) => {
    const userHasNoPoints = user_points == 0 
    const debtPoints = user_points < cart_total_points ? cart_total_points - user_points : 0;
    const restingPoints = cart_total_points * (maximum_percentage / 100) - debtPoints;

    const overcomeMaxPercentage = restingPoints < 0;
    const hasEnoughPoints = debtPoints <= 0;

    return {
      userHasNoPoints,
      hasEnoughPoints,
      overcomeMaxPercentage,
      restingPoints,
      debtPoints,
    };
  };

  renderFinalModal = () => {
    const { showAlert } = this.state;
    const content = {
      msg: tCartCheckout("order-confirmation")
    }

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.checkout}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderHasNotEnoughPointsModal = () => {
    const { showAlert, debtPoints } = this.state;
    const content = {
      alertMsg: tCartCheckout("insuficient-points").replace("{points}", Math.abs(debtPoints)),
      items: null,
      shouldUseCoPayment: false,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleShouldUseCoPayment}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderOvercomeMaxPercentageModal = () => {
    const { showAlert, restingPoints } = this.state;
    const content = {
      alertMsg: tCartCheckout("exceeded-percentage").replace("{restingPoints}", Math.abs( Math.floor(restingPoints * 100) / 100)),
      items: null,
      shouldUseCoPayment: false,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleWishRemoveProducts}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderWithNoCoPaymentProductsModal = () => {
    const { showAlert, noCoPaymentItems } = this.state;
    const content = {
      alertMsg: tCartCheckout("products-dont-allow-co-payment"),
      items: noCoPaymentItems,
      shouldUseCoPayment: true,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleWishRemoveProducts}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderCoPaymentTermsModal = () => {
    const { cart: { payment_definitions } } = this.props
    const { showCoPaymentConditionsModal, acceptCoPaymentConditions } = this.state;
    return (
        <CoPaymentModal
          show={showCoPaymentConditionsModal}
          close={this.closeCoPaymentConditionsModal}
          paymentDefinitions={payment_definitions}
          conditionsAccepted={acceptCoPaymentConditions}
          handleAcceptConditions={this.setAcceptCoPaymentConditions}
        />
    );
  };

  renderChoosePaymentMethodModal = () => {
    const { cart: { payment_methods: paymentMethods }, campaign: { rate } } = this.props;
    const { showPaymentMethodsModal, debtPoints } = this.state;
    return (
      <PaymentMethodsModal
        show={showPaymentMethodsModal}
        close={this.closePaymentMethodsModal}
        campaignRate={rate}
        debtPoints={Math.abs(debtPoints)}
        paymentMethods={paymentMethods}
        handleMethodChoosing={this.handleChoosePaymentMethod}
      />
    );
  };

  renderCheckoutModal = () => {
    const {
      hasEnoughPoints,
      currentStage,
      overcomeMaxPercentage,
      noCoPaymentItems,
    } = this.state;

    // console.log(" ###################################")
    // console.log(" #### CURRENT STAGE => ", currentStage)
    // console.log(" #### HAS ENOUGH POINTS => ", hasEnoughPoints)
    // console.log(" #### OVERCOME MAX PERCENTAGE => ", overcomeMaxPercentage)
    // console.log(" #### NO COPAYMENT ITEMS => ", noCoPaymentItems)

    return currentStage === 0
      ? !hasEnoughPoints ? this.renderHasNotEnoughPointsModal() : this.goToFinalStage() 
      : currentStage === 1
      ? overcomeMaxPercentage ? this.renderOvercomeMaxPercentageModal() : this.goToNextStage() 
      : currentStage === 2
      ? noCoPaymentItems.length > 0 ? this.renderWithNoCoPaymentProductsModal() : this.goToTermsStage()
      : currentStage === 3
      ? this.renderCoPaymentTermsModal()
      : currentStage === 4
      ? this.renderChoosePaymentMethodModal()
      : this.renderFinalModal();
  };

  render() {
    const { campaign, cart } = this.props;
    const { redirect } = this.state;

    if (cart.items.length === 0 || redirect) {
      return <Redirect to="/cart" />;
    }

    if (cart.shipment_address === undefined) {
      return <Redirect to="/cart_address" />;
    }

    return (
      <ContentLayout campaign={campaign} title="Carrinho de Compras">
        <div className="col-xs-12">
          <div className="template cart-checkout-container">
            <div className="w-95 m-auto">
              <ToastContainer
                store={ToastStore}
                position={ToastContainer.POSITION.TOP_LEFT}
                lightBackground
              />
              <CartNavigation campaign={campaign} step={3} />
              <CartItems campaign={campaign} cart={cart} />
              {cart.shipment_address ? (
                <CartAddressShow
                  classes="sm:flex-turn "
                  campaign={campaign}
                  address={cart.shipment_address}
                />
              ) : null}
              <div className="py-6 flex end-xs ">
                <ButtonPrimary
                  color_primary={campaign.color_primary}
                  navbar_text_color={campaign.navbar_text_color}
                  onClick={this.openAlertModal}
                  className="button-cart"
                >
                  Confirmar
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
        <CartWarning text={campaign.call_attention} />
        {this.renderCheckoutModal()}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    campaignUser: state.balanceReducer.campaignUser,
    cart: state.cartReducer.cart
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveCart: (cart) => {
      dispatch(saveCart(cart));
    },
    saveCampaignUser: (user) => {
      dispatch(saveCampaignUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CartCheckout);
