import React, { Component } from 'react';
import UserPassForm from './UserPassForm'

import {t} from '../../../i18n'
class UserPass extends Component{

  constructor(props){
    super(props);
    this.state={editable: this.props.editable};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    this.setState(state => ({
      editable: !state.editable
    }));
    
  }

  render(){
    const {color_primary, text_color} = this.props.campaign 
    return(
      <div>
        {this.state.editable ? <UserPassForm onClick = {(e) => this.handleClick()} campaign = {this.props.campaign} /> 
        :
        <div className='flex template-container between-xs sm:flex-turn sm:flex-turn--a-center sm:flex-turn--j-center'>
          <div>
            <h2 style={{color: color_primary}} className='template-title uppercase'>{t('user.pw')}</h2>
            <hr style={{backgroundColor: text_color}} className="hr-down-left"></hr>
          </div>
          <button onClick={this.handleClick} className='btn-secondary user-button px-4 py-1'>{t('user.edit')}</button>
        </div> }
      </div>
    )
  }

  static defaultProps = {
    editable: false
  }
}
export default UserPass;