import React, { Component } from 'react';

class RankingBar extends Component{

  filledWith(){
    const { 
      ranking,
      multiplier,
      campaign
    } = this.props
    const style = {}
    const height_prev = 28 + 5 * multiplier

    const percentil = 100 / 22 * multiplier
    if (ranking.percentil > percentil){
      style.background = `linear-gradient(${campaign.color_primary} 0%, 4%, ${campaign.color_secondary} 7%)`
    }
    if (this.getPosition()){
      style.borderRadius = '0 0 2px 2px'
    }
    style.height =  height_prev + 'px'
    return style 
  }


  getPosition(){
    const { 
      ranking,
      multiplier
    } = this.props

    const percentil = 100 / 22 * multiplier
    const percentil_plus = 100 / 22 * (multiplier +1)
    
    return ranking.percentil > percentil && ranking.percentil <= percentil_plus
  }

  bubbleStyle(){
    const { 
      campaign
    } = this.props
    return {
      background: `linear-gradient(115deg, ${campaign.color_primary} 0%, 30%, ${campaign.color_secondary} 70%)`,
      border: `5px solid ${campaign.color_secondary}`
    }
  }
 
  render(){  
    const {
      ranking,
      campaign
    } = this.props
    return(
      <div>
        {this.getPosition() ? 
          <div className='relative'>
          <div style={this.bubbleStyle()} className='ranking-position'>{ranking.position}<span className='ranking-position-span'>º</span></div>
          <div className='ranking-pointer' style={{background: campaign.color_primary}}></div>
          </div>
        : null
        }
        <div style={this.filledWith()}className='ranking-vertical'>
        </div>
      </div>
    )
  }
}

export default RankingBar;