import React from 'react'
import {Redirect} from 'react-router-dom'
import CartButtons from '../CartButtons';
import CustomButton from '../../shared/CustomButton';
import NewAddressForm from '../../shared/Address/NewAddressForm';
import {ReactComponent as NoAdresses} from '../../../../assets/img/home.svg'
import Address from './Address';


const AddressList = (props) => {
  const {
    addresses,
    adding,
    states,
    countries ,
    selectedState,
    selectedCountry,
    onUpdateAddress,
    onReturnToCart,
    onCheckAddresses,
    onShowAddressForm,
    onNewAddressCreation,
    onHideAddressForm,
    cart,
    errors,
    onCountryChange,
    onStateChange
  } = props

  const handleNewAdressCreation = (data) => {
    data.callBack = onHideAddressForm
    onNewAddressCreation(data)
  }

  if (cart.items.length === 0){
    return <Redirect to='/cart' />
  }
  
  return(
    <div className="mt-5">
      <div className="row">
      {cart.shipment_address &&
        <Address shipment {...cart.shipment_address} key={'shipment-'+cart.id} />
      }
      {
        addresses.map(a => (
          <Address {...a} key={a.id} onAdressSelection={onUpdateAddress}/>
        ))
      }
      </div>
      {
        addresses.length === 0  && !cart.shipment_address &&(
          <div className="g-cart__address--min-height flex flex-1 flex-col justify-center items-center">
            <NoAdresses className="g-cart__empty-state dead-grey-fill mb-10"/>
            <p className="vodafone-light dead-grey">Ainda não tem nenhuma morada definida.</p>
            <p className="vodafone-light dead-grey"> Por favor adicione uma morada.</p>
          </div>
        )
      }
     
      { adding &&
        <div className="flex-1 ">
          <hr className="catalog__horizontal-line mb-5"/>
          <NewAddressForm
            className="mb-3" 
            cancelMargin
            errors={errors}
            availableStates={states}
            availableCountries={countries}
            state={selectedState}
            country={selectedCountry}
            onAddressCreationCancel={onHideAddressForm}
            onNewAddress={handleNewAdressCreation}
            onCountryChange={onCountryChange}
            onStateChange={onStateChange}
          />
        </div>
      }
      <hr className="catalog__horizontal-line"/>
      {!adding &&
        <div className="row px-6">
            <CustomButton  onClick={onShowAddressForm}>
              Criar nova morada
            </CustomButton>
        </div> 
      }
      <CartButtons 
        onPrev={onReturnToCart}
        onNext={onCheckAddresses} 
        prevText="Voltar"
        nextText="Seguinte"/> 
    </div>
  )
}

export default AddressList
