import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildTransaction } from '../../../actions/generalActions'
import {PopUpService} from '../../../services';

class PopUpContainer extends Component {

  constructor(props) {
    super(props)
    this.state = {
        image: '',
        title: '',
        description: '',
        show: false,
        id: undefined,
        popupId: undefined,
        slug: undefined
    }
  }

  componentDidMount() {
    this.getUnseenPopup();

    const notificationInterval = Number(process.env.REACT_APP_NOTIFICATION_INTERVAL)

    const zero = 0
    if( notificationInterval > zero) {
      this.interval = setInterval(()=> {
        this.getUnseenPopup();
      }, notificationInterval * 1000)
    }
  }

  getUnseenPopup(){
    const payload  = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug
    }

    PopUpService.getUnseenPopup(payload)
      .then(response => response.data)
      .then(({popup, ...rest}) => {

        if(!popup.image) return Promise.resolve() //no popups to see
        this.setState({
          image: popup.image || '',
          title: popup.title,
          description: popup.description,
          id: popup.content_id,
          slug: popup.slug,
          popupId: popup.id,
          show: true
        });
      }).catch()

  }

  handlePopupView = () => {
    if(!this.state.popupId) return //dont do it
    const payload  = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.state.popupId
    }

    PopUpService.markAsSeen(payload)
      .then(() => this.setState({show: false, popupId: null}))
      .catch(e=>{ this.setState({show: false, popupId: null})})
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  untoggle = () => {
    this.handlePopupView()
  }

  render() {
    const {component: Component, ...rest} = this.props

    return (
      <Component
        {...this.state}
        onPopupView={this.handlePopupView}
        untoggle={this.untoggle}
        {...rest}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch),
})

const mapStateToProps = (state) => ({
  client: state.userReducer.client,
  campaign: state.campaignReducer.campaign
})

const Container = connect(mapStateToProps, mapDispatchToProps)(PopUpContainer)

export function withPopUpContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
