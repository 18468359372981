import axios from './interceptor';

export const PostsService = {
  getPosts({clientSlug,campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/posts`;
    return axios({
      method: 'get',
      url: url
    });
  }
};
