import React, { Component } from 'react';
import '../../../assets/css/slider.scss';

class RankingUserRow extends Component{

  render() {
    const {
      campaign,
      winner,
      isLoggedInUser
    } = this.props
    return( 
      isLoggedInUser ?
        <tr style={{ backgroundColor: campaign.color_secondary, color: campaign.color_primary}} className='user-row'>
          <td className='text-center'>{winner.position}</td>
          <td className='text-center lhn'>{winner.first_name} {winner.last_name}</td>
          <td className='text-center'>{winner.total_points}</td>
        </tr>
        :
        <tr className='user-row'>
          <td className='text-center'>{winner.position}</td>
          <td className='text-center lhn'>{winner.first_name} {winner.last_name}</td>
          <td className='text-center'>{winner.total_points}</td>
        </tr>
    )
  }

}

export default RankingUserRow;