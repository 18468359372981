import React from 'react';
import NavLink from '../shared/NavigationLink'



const RankingsNavigation = ({ranking, firstRanking}) => {
  const selectedRankingId = ranking.id || firstRanking.id
  return (
    <nav className="flex g-profile__title  mb-5 navbar-text">
        <NavLink
          to={`/rankings/${selectedRankingId}`}
          exact
        >
          Ranking
        </NavLink>
        <NavLink
          to={`/rankings/${selectedRankingId}/balance`}
          exact
        >
          Extrato
        </NavLink>
    </nav>

  )
}

export default RankingsNavigation