import React, { memo, useState } from 'react'
import Spinner from '../../../shared/Spinner'

const AnalyticsGDS = memo(({ group }) => {
    const [loading, setLoading] = useState(true)

    return (
      <div class="flex-1 flex justify-center items-center">
        <Spinner loading={loading} class="flex-1 justify-center items-center"/>
        <iframe
          style={{ minHeight: 975 }}
          class="h-full w-full"
          onLoad={() => setLoading(false)}
          src={group.gds_url || "https://datastudio.google.com/embed/reporting/2f610a49-22bd-4796-820f-f25f08aeed86/page/Aqe3B"} 
        />
      </div>
    )
})
export default AnalyticsGDS
