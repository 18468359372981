import React, { Component } from 'react';
import ContentLayout from '../shared/ContentLayout';
import styled from 'styled-components';
import {withCustomizedPagesContainer} from "../normalizer"


class Page extends Component{
  constructor(props){
    super(props);
  }

  getSpan(){
    const {
      color_primary,
      navbar_text_color
    } = this.props.campaign

    const primary = styled.span`
      color: ${navbar_text_color};
      background: ${color_primary};
      border: 2px solid ${color_primary};
      padding: 12px 4px ;
      line-height: 42px;
    `
    return primary
  }

  render() {
    const {
      campaign,
      pages,
      match
    } = this.props;

    let  SpanPrimary = this.getSpan();
    let page = pages.find(e => e.slug === match.params.slug)
    page = page ? page : {image: { url: undefined}, attachments: []};
    return (
      <div>
        <div className="row page-content px-4" >
          { page.image.url &&
            <div style = {{background: 'url('+ page.image.url + ') no-repeat center center', backgroundSize:'cover'}}
            className='col-lg-12 col-md-12 col-xs-12 content-images-full rounded-t'>
            </div>
           }
          <div className="page-text-container col-lg-12 col-md-12 col-xs-12 px-0 rounded-b">
            <ContentLayout subcontent title={page.subtitle ? page.subtitle : 'Subtítulo'} campaign={campaign} backgroundColor='#ffffff' font="font-quicksand-regular">
              <div className="width-available" dangerouslySetInnerHTML={{__html: page.body}} />
              { page.attachments.length !== 0 &&
                <div className='text-lg font-quicksand w-full pt-6'>
                  <p className='block' >Documentos</p>
                </div>
              }
              <div className = 'py-3 font-quicksand' >
                {
                  page.attachments.map((item, i) => {
                  return (
                      <a key={i} href={item.url} className='inline-block my-3 pr-2' target="_blank" download><SpanPrimary  style={{color:"white"}}>Descarregar Documento</SpanPrimary></a>
                  )
                  })
                }

              </div>
            </ContentLayout>
          </div>
        </div>
      </div>
    );
  }
}

export default withCustomizedPagesContainer(Page);
