import React from "react"
import { Switch } from "react-router-dom"
import Page from "./Page"
import { default as Route } from "../../../routes/CustomRoute"
import { withCustomizedPagesContainer } from "../normalizer";

const CustomizedPages = (props) => {
  const {
    pages
  } = props;

  return (
    <>
    <Switch>
      <Route
        exact
        path="/customized_pages/:slug"
        component={Page}
        pages={pages}
      />
    </Switch>
    </>
  );
};

export default withCustomizedPagesContainer(CustomizedPages)
