import React from 'react'
import RectLoader from '../shared/RectLoader'
import Modal from '../../shared/Modal'
import Momment from 'moment'
import {t} from '../../../i18n'
import { formatNumber} from '../normalizer';

const ObjectiveSection = ({title, h3Classes, h3Override, children}) => {

  const h3Class = ['dead-grey mb-5', h3Classes].join(' ')

  return (
    <div className="mb-5">
      {h3Override || <h3 className={h3Class}>{title}</h3> }
      <div className="mb-5">
        {children}
      </div>
      <hr className="gl-objectives__line mt-5 mb-0"/>
    </div>
  )
}

const ObjectiveH3 = ({title, writtenProgress}) => (
  <div className="flex justify-between dead-grey mb-3">
    <h3>{title}</h3>
    <p>{writtenProgress}</p>
  </div>
)

const DisplayReward = ({type,reward,currencyLabel}) => {
  let normalizedType = type
  switch(type){
    case 'points':
      normalizedType = currencyLabel
      break;
    default:
      normalizedType = type
      break;
  }

  return (
  <div>
    {formatNumber(reward)} {normalizedType}
  </div>
  )}

const ObjectiveSectionContainer = ({rules, currencyLabel}) => (
  rules.map(({id, name, target, percentage, progress, award, quantity}) => {
    const H3 = <ObjectiveH3 title={name} writtenProgress={progress +" de " +target}/>
    return(
      <ObjectiveSection key={id} title={name} h3Override={H3} >
        <div className="gl-objectives__progress gl-objectives__progress--rule">
          <RectLoader thin progress={Number(percentage)}/>
        </div>
        <DisplayReward type={award} reward={quantity} currencyLabel={currencyLabel}/>
      </ObjectiveSection>
    )
  })
)

const ObjectiveModal = ({selected, onUnselectObject, currencyLabel}) => {
  return (
  <Modal show={selected} untoggle={onUnselectObject}>
    <div className="gl-objectives__modal p-3">
      <div className="flex justify-between items-center navbar-text mb-5">
        <h2>{selected.name}</h2>
        <button className="gl-objectives__back gl-outline-reset" onClick={onUnselectObject}>
          <i className="fa fa-times" ></i>
        </button>
      </div>

      <ObjectiveSection title="Descrição">
        <div className="custom-inner-html gl-objectives__description">
          <p dangerouslySetInnerHTML={{ __html: selected.description }}></p>
          {!selected.description && <p>Sem descrição</p>}
        </div>
      </ObjectiveSection>

      <ObjectiveSection title="Atingimento">
        <div className="gl-objectives__progress">
          <RectLoader progress={Number(selected.percentage)}/>
        </div>
      </ObjectiveSection>
      <div>
      <ObjectiveSectionContainer rules={selected.rules}/>      
      </div>
      <div className="gl-objectives__description">
        Termina a
        <span className="ml-1">
          {new Momment(selected.end_date).format(t('date-format'))}
        </span>
      </div>
    </div>
  </Modal> 
  )
}

export default ObjectiveModal
