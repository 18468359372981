import React from "react"
import { withRequestedTradesContainer } from "../../../containers/default/saleTrade/RequestedTradesContainer"
import { t } from "../../../../i18n"
import Moment from "moment"
import TableHeaderLayout from "../TableHeaderLayout";

const SaleRow = ({
  description,
  date,
  completed_date,
  status,
  user_solicited
}) => {

  const responseDate = completed_date ? 
    new Moment(completed_date).format(t("date-format")) :
    '-'
  
  return (
    <div className="mt-5">
      <div className="row text-sm">
        <span className="col-xs-2 flex items-center">{user_solicited}</span>
        <span className="col-xs-3 flex items-center ">{description}</span>
        <span className="col-xs-3 flex items-center">
          {new Moment(date).format(t("date-format"))}
        </span>
        <span className="col-xs-2 flex items-center">
          {responseDate}
        </span>
        <div className="col-xs-1 flex items-center">
          <span>{t(`trades.${status}`)}</span>
        </div>
      </div>
    </div>
  )
}

const index = ({ sales, pagination }) => {
  return (
    <div className="template col-xs-12 font-quicksand-light">
      <TableHeaderLayout>
        <span className="col-xs-2">Utilizador</span>
        <span className="col-xs-3">Descrição</span>
        <span className="col-xs-3">Data de Submissão</span>
        <span className="col-xs-2">Data de Resposta</span>
        <span className="col-xs-1">Estado</span>
      </TableHeaderLayout>
      <div>
        {sales.map(s => (
          <SaleRow {...s} key={"sale-trade-" + s.id} />
        ))}
      </div>
      
      {pagination.total_pages <= 0 && (
        <div className="text-center text-xl text-color pl-2 pt-10 pb-8">
          Não realizou nenhuma trocas de contratos.
        </div>
      )}
    </div>
  )
}

export default withRequestedTradesContainer(index)
