import React from 'react'
import CustomButton from '../../shared/CustomButton';


const Address = (props) => {
  const {
    first_name : firstName,
    last_name: lastName,
    phone,
    email,
    address1,city,
    address2,
    country = {},
    state = {},
    zip_code : zipCode,
    main_name : mainName,
    onAdressSelection,
    shipment
  } = props

  const containerClass = [
    "g-cart__address vodafone-light line-height-20 col-md-4 mb-5 p-5",
    shipment && 'g-cart__address--selected'
  ].join(' ')

  return (
    <div className={containerClass}>
      {
        shipment && <p className="vodafone-bold mb-10 small-text-size"> Morada selecionada</p>
      }
      {
        !shipment && <p className="vodafone-bold mb-10 primary-text">{mainName}</p>
      }
      <div className="mb-5 flex-1">
        <p className="vodafone-bold">{firstName} {lastName}</p>
        <p>{address1}</p>
        <p>{city} {zipCode} {country.name}</p>
        <p>{email}</p>
        <p>{phone}</p>
      </div>
      {!shipment &&
        <CustomButton primary
          onClick={()=>onAdressSelection({id: props.id})}
        >
          Entregar nesta morada
        </CustomButton>
      }
    </div>
  )
}

export default Address