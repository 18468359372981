import React from 'react';
import BackgroundWrapper from '../client/BackgroundWrapper';
import ClientLogo from '../client/Logo';
const CampaignSelector = ({user, campaigns, onSelectCampaign}) => {
  return (
    <BackgroundWrapper backgroundImage={user.client.background_banner.url}>
      <div className="row center-lg  font-quicksand center-xs center-md h-screen">
        <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
          <div className='py-10'>
          <ClientLogo src={user.client.logo.url} />
          </div>
          <h2>Selecione uma campanha</h2>
          <div className="flex flex-col items-center py-10">
            {
              campaigns.map(campaign => {
                return (
                  <button 
                    key={`campaign-${campaign.slug}`}
                    className="my-4 mx-3 button-cart button-primary text-xl" 
                    onClick={onSelectCampaign(campaign)}> 
                      {campaign.name}
                  </button>
                )
              })
            }
          </div>
        </div>
      </div>
    </BackgroundWrapper>
  )
}
export default CampaignSelector