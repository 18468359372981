import React from "react";
import Slider from "react-slick";
import Banner from "./Banner";
class BannerSlider extends React.Component {

  render() {
    const { data, backgroundColor, showText, mobileFriendly } = this.props
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500,
      cssEase: "linear",
      appendDots: dots => (
        <div>
          <ul className='gl-home-banner__dots gl-dots'> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <span className='gl-home-banner__dot'>.</span>
      )
    }
    if (data.length === 0){
      return <div className='gl-home-banner__slider'></div>
    }
    
    return (
      <Slider {...settings} className='gl-home-banner__slider'>
        {
          data.map((banner,i) => {
          return(
            <div key={i}>
              <Banner {...banner} mobileFriendly={mobileFriendly}/>        
            </div>
          )
          })
        }
      </Slider>
    );
  }
}

export default BannerSlider;