export const saveUnapprovedInvoices = (invoices) => ({
  type: 'SAVE_UNAPPROVED_INVOICES',
  payload: invoices
})

export const saveVerifiedInvoices = (invoices) => ({
  type: 'SAVE_VERIFIED_INVOICES',
  payload: invoices
})

export const saveRegistredInvoices = (invoices) => ({
  type: 'SAVE_REGISTRED_INVOICES',
  payload: invoices
})

export const saveCreatedInvoices = (invoices) => ({
  type: 'SAVE_CREATED_INVOICES',
  payload: invoices
})

export const validateInvoice = (invoice) => ({
  type: 'VALIDATE_INVOICE',
  payload: invoice
})

export const saveSaleTypes = (saleTypes) => ({
  type: 'SAVE_INVOICE_SALE_TYPES',
  payload: saleTypes
})

export const saveReprovalReasons = (reprovalReasons) => ({
  type: 'SAVE_INVOICE_REPROVAL_REASONS',
  payload: reprovalReasons
})

export const createInvoice = (invoice) => ({
  type: 'CREATE_INVOICE',
  payload: invoice
})

export const saveBrands = (brands) => ({
  type: 'SAVE_INVOICE_BRANDS',
  payload: brands
})

export const updateCreatedInvoice = (invoice) => ({
  type: 'UPDATE_CREATED_INVOICE',
  payload: invoice
})

export const submitToValdiation = (invoice) => ({
  type: 'SUBMIT_TO_VALIDATION',
  payload: invoice
})
