import React, { useState } from 'react';




const popupStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0, 0.7)',
  zIndex: 999,
  opacity: 0,
  display: 'none',
};

const popupOpenStyles = {
  opacity: 1,
  display: 'block'
};

const popupContentStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '70%',
  backgroundColor: '#D94D15',
  padding: '20px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
  borderRadius: '8px',
  textAlign:'center'
};

const popupCloseBtnStyles = {
  fontSize: '20px',
  color: 'black',
  backgroundColor:'white',
  minWidth:'10%',
  borderRadius:'5px',
  marginTop: '20px',
};

const popupBody = {
  textAlign:'left'
};

const popupTitle = {
  color: 'white',
  marginBottom: '10px',
};

const Popup = ({ isOpenValue, onClose, title, children }) => {

  const [isOpen, setIsOpen] = useState(isOpenValue);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };


  return (
    <div style={isOpen ? {...popupStyles, ...popupOpenStyles} : popupStyles}>
      <div style={popupContentStyles}>
        {title && <h2 style={popupTitle}>{title}</h2>} 
        <div style={popupBody}>
          {children}
        </div>
        <button style={popupCloseBtnStyles} onClick={handleClose}>OK</button>
      </div>
    </div>
  );
};

export default Popup;