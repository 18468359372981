import React from 'react';
import styled from 'styled-components'
const BannerWrapper = styled.div`
    background: url(${props => props.url}) no-repeat center center; 
    background-size: cover
`
const Banner = ({banner}) => (
  <BannerWrapper url = {banner.src} className='sliding'>
    { banner.text && 
      <div className="cont banner-content banner-title" dangerouslySetInnerHTML= {{ __html: banner.text}}>        
      </div>
    }
      { banner.url && 
        <div className="cont button-wrapper">
          <a rel="noopener noreferrer" href={banner.url}><button className="button-primary banner-button">Saiba Mais</button></a>
        </div>
      }
  </BannerWrapper>
)

export default Banner;