import React from 'react'
import QuizzLayout from './QuizLayout';
import {ReactComponent as NoQuiz} from '../../../assets/img/No_Quiz.svg'
import CustomLink from '../shared/CustomLink';
import EmptyStateLayout from '../shared/EmptyStateLayout';
import {withClientContainer} from '../../containers/gamification'
import { formatNumber } from '../normalizer';

const HistoryRow = (props) => {
  const {
    quiz_name: name,
    total_corrects: answers,
    total_answers: questions,
    total_points: points,
    id
  } = props

  return (
    <tr className="text-color gl-info-table__row">
      <td className="p-4 text-left">{name}</td>
      <td className="p-4 text-left">{questions}</td>
      <td className="p-4 text-left">{answers}</td>
      <td className="p-4  text-right">{formatNumber(points)}</td>
      <td className="p-4 text-center">
        <CustomLink className="gl-info-table__button  whitespace-no-wrap" outline medium right to={"/quiz/history/" + id}>
          Rever Quiz
        </CustomLink>
      </td>
    </tr>
  )
}

const QuizHistory = props => {
  
  const {
    quizzes
  } = props

  const {
    currency_label : currencyLabel
  } = props.client

  const noElements = quizzes.length === 0

  return (
    <QuizzLayout>
      {
        noElements ? (
          <EmptyStateLayout message="O seu histórico de Quizes está vazio">
            <NoQuiz className="flex-1 mb-5"></NoQuiz>
          </EmptyStateLayout>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full text-size navbar-text gl-info-table">
              <thead>
                <tr className="text-color gl-info-table__row ">
                  <th className="p-4 text-left w-1/5 ie-11">Quiz</th>
                  <th className="p-4 text-left w-1/5 ie-11">Total (#)</th>
                  <th className="p-4  text-left w-1/5  ie-11">Respostas corretas (#)</th>
                  <th className="p-4 text-right  w-1/5 ie-11">{currencyLabel} atribuídos</th>
                  <th className="p-4 text-center  w-1/5 ie-11"> Rever?</th>
                </tr>
              </thead>
              <tbody>
                {
                  quizzes.map(qi => <HistoryRow {...qi} key={'history-item' + qi.id} />)
                }
              </tbody>
            </table>
          </div>
        )
      }
    </QuizzLayout>
  )
}
export default withClientContainer(QuizHistory)
