import React from "react"
import { withProductContainer } from "../../containers/shared"
import { Link } from "react-router-dom"
import { formatNumber, substring, CartItem } from "../normalizer"

const ableToBuy = (canBuy) => {
  if (canBuy !== undefined)
    return canBuy
  return true
}

const Div = ({children}) => {
  return (<div>
           {children}
          </div>
          )
}

const Product = props => {
  const {
    product,
    onFavAddition,
    onFavRemove,
    onCartAdd,
    currencyLabel,
    canBuy,
    ...campaignProduct
  } = props

  const { is_favorite, points } = campaignProduct
  const heartClass = [
    "gl-product__icon",
    !is_favorite && "far fa-heart primary-text",
    is_favorite && "fa fa-heart fav primary-text"
  ].join(" ")

  const WrapperComponent = ableToBuy(canBuy) ? Link : Div

  const limitedName = substring(product.name, 35, "...")
  return (
    <div className="col-md-3 col-sm-4 col-xs-12 px-12 gl-product__columns md:px-0 ">
      <div className="gl-product__container bg-default gl-content-shadow ">
        <p className="gl-product__title text-color">{limitedName}</p>
        <WrapperComponent to={`/catalog/product/${campaignProduct.id}`}>
          <img
            src={product.main_image.url}
            alt={product.name}
            className="gl-product__image"
          />
        </WrapperComponent>
        <div className="gl-product__action-container navbar-text">
          <i
            className={heartClass}
            onClick={is_favorite ? onFavRemove : onFavAddition}
          />
          <div className="primary-text gl-product__points-container">
            <span className="gl-product__points-value">
              {formatNumber(points)}
            </span>
            <span className="gl-product__points-label">{currencyLabel}</span>
          </div>
          { ableToBuy(canBuy) ? 
            <CartItem
              className="gl-navbar__icons20 cursor-pointer primary-fill campaign-drop-down-item"
              onClick={onCartAdd}
            />
            : 
            <div></div>
          }
        </div>
      </div>
    </div>
  )
}
export default withProductContainer(Product)
