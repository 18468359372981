import React from 'react'
import NavigationLink from '../shared/NavigationLink';

const QuizzLayout = ({children}) => {
  return (
    <>
      <nav className="mb-10 vodafone navbar-text text-size flex">
        <NavigationLink exact to ={"/quiz"}>
          Ativos
        </NavigationLink>
        <NavigationLink to ={"/quiz/history"}>
          Histórico
        </NavigationLink>
      </nav>
      {children}
    </>
  )
}

export default QuizzLayout
