import React, { Component } from "react"
import { saveUser } from "../../../../actions/userActions"
import { withRouter } from "react-router"
import { saveLinks } from "../../../../actions/contentActions"
import { startFetch, endFetch } from "../../../../actions/generalActions"
import { doLogout } from "../../../../actions/generalActions"
import { selectCampaign } from "../../../../actions/campaignActions"
import { connect } from "react-redux"
import {
  headerLinksService,
  UserService,
  AuthenticationService
} from "../../../../services"
import PropTypes from "prop-types"
import { CampaignButton } from "../client/buttons"
import ReactGA from "react-ga"
import HeadersSection from "./HeadersSection";
import UserSection from "./UserSection";
import Navigation from "./Navigation";

class Navbar extends Component {

  setBurgerNav = () => {
    document.getElementById("navbar").classList.remove("fade")
  }


  getHeaderLinks() {
    this.props.startFetch()
    const { user, campaign } = this.props
    const payload = { clientId: user.client.slug, campaignSlug: campaign.slug }
    headerLinksService
      .getLinks(payload)
      .then(response => response.data.header_links)
      .then(links => {
        this.props.saveLinks({ header_links: links })
        this.props.endFetch()
      })
      .catch(error => {
        this.props.endFetch()
      })
  }

  selectCampaign = campaign => {
    return () => {
      this.handleCampaignSelection(campaign)
    }
  }

  handleCampaignSelection = campaign => {
    if (campaign.slug !== this.props.campaign.slug) {
      ReactGA.event({
        category: "Campaign change",
        action: "Selected - " + campaign.name
      })
      localStorage.setItem("campaign", JSON.stringify(campaign))
      this.props.selectCampaign(campaign)
    }
  }

  logOut = (e) => {
    e.preventDefault()
    UserService.logOut()
      .then(response => {
        AuthenticationService.logout()
        this.props.selectCampaign(undefined)
        this.props.doLogOut()
        this.props.history.push(`/login`)
      })
      .catch(error => { })
  }


  componentDidMount() {
    if (this.props.links.length === 0) {
      this.getHeaderLinks()
    }
  }

  render() {

    const {
      links,
      campaign,
      user,
      cart,
      campaignUser,
      pages,
      campaigns,
      supportingDocuments,
      toRender
    } = this.props

    return (
      <header>
        {toRender("HeaderLinks") && links.length !== 0 && (
          <HeadersSection links={links} />
        )}

        <UserSection
          user={user}
          campaign={campaign}
          campaignUser={campaignUser}
          cart={cart}
          toRender={toRender}
        />

        <Navigation
          pages={pages}
          campaigns={campaigns}
          campaign={campaign}
          campaignUser={campaignUser}
          supportingDocuments={supportingDocuments}
          toRender={toRender}
          selectCampaign={this.selectCampaign}
          handleLogout={this.logOut}
        />

        <CampaignButton
          isPrimary
          campaign={campaign}
          className="burger"
          onClick={this.setBurgerNav}
        >
          <span />
          <span />
          <span />
        </CampaignButton>
      </header>
    )
  }
  
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaigns: state.campaignReducer.campaigns,
    campaignUser: state.balanceReducer.campaignUser,
    campaign: state.campaignReducer.campaign,
    cart: state.cartReducer.cart,
    links: state.contentReducer.links,
    pages: state.pagesReducer.pages,
    supportingDocuments: state.supportingDocumentsReducer.supportingDocuments
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveLinks: links => {
      dispatch(saveLinks(links))
    },
    saveUser: user => {
      dispatch(saveUser(user))
    },
    selectCampaign: campaign => {
      dispatch(selectCampaign(campaign))
    },
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    },
    doLogOut: () => {
      dispatch(doLogout())
    }
  }
}
Navbar.contextTypes = {
  router: PropTypes.object
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    pure: false
  }
)(withRouter(Navbar))
