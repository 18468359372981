import React, { Component } from "react"
import styled from "styled-components"
import { withCampaignContainer } from "../../../normalizer"

const RelativeWrapper = styled.div.attrs({
  className: "gl-absolute-center text-center"
})`
  max-width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  border-radius: 50%;
`

class CircularHeaderLoader extends Component {
  static defaultProps = {
    radius: 55,
    stroke: 6,
    progress: 0,
    expected_progress: 0,
    id: ""
  }
  handler = undefined

  constructor(props) {
    super(props)
    const { radius, stroke } = props
    this.normalizedRadius = radius - stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI
    this.state = {
      progressDashOffset: this.circumference,
      expectedProgressDashOffset: this.circumference
    }
  }

  calcStrokeDashoffset(p) {
    return this.circumference - (p / 100) * this.circumference
  }

  componentDidMount() {
    const { progress, expected_progress } = this.props
    const progressStrokeDashoffset = this.calcStrokeDashoffset(progress)
    const expectedProgressStrokeDashoffset = this.calcStrokeDashoffset(
      expected_progress
    )

    this.handler = setTimeout(() => {
      this.setState({
        progressDashOffset: progressStrokeDashoffset,
        expectedProgressDashOffset: expectedProgressStrokeDashoffset
      })
    }, 300)
  }

  componentWillUnmount() {
    clearTimeout(this.handler)
  }

  render() {
    const {
      radius,
      stroke,
      children,
      campaign,
      progress,
      expected_progress,
      id
    } = this.props

    return (
      <div className="relative">
        <svg className="gl-progress-ring" height={radius * 2} width={radius * 2}>
          <circle
            className="gl-progress-ring__container"
            fill="transparent"
            strokeWidth={stroke + 3}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />

          <circle
            id={`prog-${id}`}
            stroke={campaign.color_primary}
            className="gl-progress-ring__loader"
            strokeLinecap="round"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + " " + this.circumference}
            style={{ strokeDashoffset: this.state.progressDashOffset }}
            // style={ { strokeDashoffset } }
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          />

          <circle
            id={`exp-prog-${id}`}
            stroke={
              progress >= expected_progress ? "white" : campaign.color_primary
            }
            //stroke={campaign.color_primary}
            className="gl-progress-ring__loader"
            strokeLinecap="round"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + " " + this.circumference}
            style={{ strokeDashoffset: this.state.expectedProgressDashOffset }}
            // style={ { strokeDashoffset } }
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
            opacity="0.4"
          />
          <use
            xlinkHref={progress >= expected_progress ? `#prog-${id}` : `#exp-prog-${id}`}
          />
        </svg>

        <RelativeWrapper size={this.normalizedRadius * 2}>
          {children}
        </RelativeWrapper>
      </div>
    )
  }
}

export default CircularHeaderLoader
