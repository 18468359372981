import React from 'react'
import CustomButton from './CustomButton';
const EditNavbar = ({formName, onEditCancel}) => {
  return (
    <div className="flex justify-end gl-profile__between mt-3 mb-8">
      <CustomButton
        type="button"
        className="mr-3"
        onClick={onEditCancel}
        >
        Cancelar
      </CustomButton>
      <CustomButton
        primary
        type={"submit"}
        form={formName}
        >
        Guardar
      </CustomButton>
  </div>
  )
}

export default EditNavbar
