import React from 'react'
import Moment from 'moment'
import { withBalanceContainer } from "../../normalizer"
import { NavLink } from "react-router-dom";
import HistoryTable from './HistoryTable'
import { Chevron } from '../../normalizer';
import CustomSelect from '../../shared/CustomSelect'
import { prefix } from '../../../../i18n';


const HistoryButton = ({onChange, value, text, selectedValue}) => {
  let colors = selectedValue === value ? 'primary-bg navbar-text' : 'secondary-bg navbar-text'
  return <button onClick={() => onChange(value)} className={`gl-profile__history-button ${colors}`}> {text} </button>
}
    
const HistoryYearSelect = ({selectedDate,onChangeEvent}) => {
  const minYear = 2015
  const currentYear = Moment().year()
  const gapYears = currentYear - minYear
  let years = Array.from(Array(gapYears)).map((y,i) => {
    return { id: currentYear-i ,name: currentYear - i}
  }) 

  let selectedYear = years.find(year => year.id === selectedDate.year())

  return <CustomSelect 
            onChangeEvent={onChangeEvent}
            options={years}
            optionsLabel={'name'}
            optionsId={'id'}
            selectedValue={selectedYear}
            classNames={'w-full'}
            noMessage={'Sem meses'}
         />

}
const HistoryMonthSelect = ({selectedDate,onChangeEvent}) => {
  const tMonth = prefix('months')
  let months = Moment.months().map((month,i) => {
    return {name: tMonth(month),id: i}
  })
  let selectedMonth = months.find(months => months.id === selectedDate.month())
  
  return <CustomSelect 
            onChangeEvent={onChangeEvent}
            options={months}
            optionsLabel={'name'}
            optionsId={'id'}
            selectedValue={selectedMonth}
            classNamePrefixs= {'gl-profile__history-select'}
            classNames={'w-full'}
            noMessage={'Sem meses'}
          />

}

const BalanceHistory = props => {
    
    const { 
      historyMonths, 
      historyPoints, 
      onChangeTimePeriod, 
      selectedTime, 
      onChangeNextMonth,
      onChangePrevMonth, 
      selectedDate,
      onChangeMonth,
      onChangeYear,
      historyRange
    }= props
    return (
      <>
        <div className='justify-between py-4'>
          <NavLink to={"/profile/balance"} className = 'md:text-lg my-5 primary-text' exact>
            Ver tabela normal <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </NavLink>
          <span></span>
        </div>
        <div className='py-5 mb-5 row justify-start'>
        <div className='col-md-1-4 col-sm-3 col-xs-6 pr-3'>
          <p className='py-3'>Mês de Inicio</p>
          <HistoryMonthSelect selectedDate={selectedDate}  onChangeEvent={onChangeMonth} />
        </div>
        <div className='col-md-0-3 col-sm-2-3 col-xs-5 pl-3'>
          <p className='py-3'>Ano</p>
          <HistoryYearSelect selectedDate={selectedDate}  onChangeEvent={onChangeYear} />
        </div>
        </div>
        <div className='py-5 mb-5 row justify-center items-center'>
          <div className='col-xs-12 text-center py-3'>
            <p className='font-light'> Intervalo de Tempo</p>
          </div>
          <div onClick={() => onChangePrevMonth()} className='gl-profile__history-chevron-container'>
            <Chevron className='gl-profile__history-chevron gl-profile__history-chevron-right  text-color '/>
          </div>
          <div className='gl-profile__history-button-container'>
            <HistoryButton onChange={onChangeTimePeriod} value='1month' text='1 mês' selectedValue = { selectedTime } />
            <HistoryButton onChange={onChangeTimePeriod} value='3months' text='3 meses' selectedValue = { selectedTime } />
            <HistoryButton onChange={onChangeTimePeriod} value='6months' text='6 meses' selectedValue = { selectedTime } />
          </div>
          <div onClick={() => onChangeNextMonth()} className='gl-profile__history-chevron-container'>
           <Chevron className='gl-profile__history-chevron gl-profile__history-chevron-left primary-text'/>
          </div>
          <div className='col-xs-12 text-center py-3'>
            <p className='font-light'>{historyRange}</p>
          </div>
        </div>
        <HistoryTable headers={historyMonths} data={historyPoints}/>
      </>
    )
}

export default withBalanceContainer(BalanceHistory)