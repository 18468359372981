import React, { Component } from "react"
import { connect } from "react-redux"
import {
  ProductsService,
  NewsService,
  QuizzService,
  buildTransaction,
  saveHighlights,
  saveHomeQuiz,
  saveWishes
} from "../normalizer"

class HomeContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newsFeed: []
    }
  }

  fetchWishes() {
    const { user, campaign } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }
    ProductsService.getWishes(payload).then(response => {
      const wishes = response.data
      this.props.saveWishes(wishes)
    })
  }

  getHighlights() {
    const { user, campaign } = this.props

    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }

    this.props.startTransaction(() =>
      ProductsService.getHighlights(payload).then(response => {
        const highlights = response.data
        if (highlights) {
          this.props.saveHighlights(highlights)
        }
      })
    )
  }

  getHighlightedQuiz() {
    const { user, campaign } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }
    this.props.startTransaction(() =>
      QuizzService.getHomeQuiz(payload)
        .then(response => response.data.quiz)
        .then(quiz => {
          if (quiz) {
            this.props.saveHomeQuiz(quiz)
          }
        })
    )
  }

  getHomepageNews() {
    const { user, campaign } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }

    NewsService.getHomepageNews(payload)
      .then(response => {
        const feed = response.data["content/news"]
        this.setState({ newsFeed: feed })
      })
      .catch(e => {})
  }

  componentDidMount() {
    this.getHighlights()
    this.getHighlightedQuiz()
    this.getHomepageNews()
    this.fetchWishes()
  }

  render() {
    const { component: Component, rankings, ...rest } = this.props
    const { newsFeed } = this.state
    return <Component feed={newsFeed.slice()} rankings={rankings} {...rest} />
  }
}

const mapDispatchToProps = dispatch => ({
  startTransaction: buildTransaction(dispatch),
  saveHighlights: highlights => dispatch(saveHighlights(highlights)),
  saveHomeQuiz: quiz => dispatch(saveHomeQuiz(quiz)),
  saveWishes: whishes => dispatch(saveWishes(whishes))
})

const mapStateToProps = state => ({
  highlights: state.productsReducer.highlights,
  wishes: state.productsReducer.wishes,
  user: state.userReducer.user,
  endingObjective: state.objectivesReducer.endingObjective,
  homeQuiz: state.quizReducer.homeQuiz
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeContainer)

export function withHomeContainer(component) {
  return props => <Container component={component} {...props} />
}

export default Container
