const initialState = {
  pendingSaleTrades: [],
  pendingPagination: {},
  requested: [],
  requestedPagination: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_PENDING_TRADES":
      return {
        ...state,
        pendingSaleTrades: payload.pendingSaleTrades,
        pendingPagination: payload.pagination
      }

    case "UPDATE_SALE_TRADE":
      const updatedPendingSaleTrades = state.pendingSaleTrades.map(
        saleTrade => {
          if (saleTrade.id === payload.id) {
            return { ...saleTrade, status: payload.status }
          }
          return { ...saleTrade }
        }
      )
      return {
        ...state,
        pendingSaleTrades: updatedPendingSaleTrades
      }

    case "SAVE_REQUESTED_TRADES":
      return { ...state, ...payload }

    case "MOVE_TO_REQUESTED_SALES":
      state.requested.unshift(payload.sale)
      return {
        ...state
      }
    default:
      return state
  }
}
