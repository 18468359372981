import React from 'react'

const Burger = (props) => {

  return (
    <button className="g-burger primary-text outline-reset" onClick={props.onClick}>
      <span className="primary-bg"></span>
      <span className="primary-bg"></span>
      <span className="primary-bg"></span>
    </button>
  )
}

export default Burger
