import React from 'react'
import CustomSelect from './CustomSelect'

const KeyDropdownValue = ({ title, classNameData, classNameTitle, ...rest }) => {

  const tilteClass = classNameTitle !== undefined ? `gl-profile__info gl-profile__info--editable ${classNameTitle}` : "gl-profile__info gl-profile__info--editable"
  const dataClass = "gl-profile__info gl-profile__info--value outline-none gl-profile__info-icon w-full "

  return (
    <div className="gl-profile__table-cell">
      <div className={tilteClass}>
        <span>{title}</span>
        <i className="gl-profile__info-icon far fa-edit"></i>
      </div>
      <CustomSelect
        {...rest}
        classNames={dataClass}
      />
    </div>

  )
}
export default KeyDropdownValue
