
import React from 'react'
import ContentLayout from '../shared/ContentLayout';
import UserProfile from './UserProfile';
import UserNavbar from './UserNavbar';
import {t} from '../../../i18n'
const ProfileLayout = ({campaign, campaignUser, user, children}) => {
  return (
    <ContentLayout campaign={campaign} title={t('user.my-profile')}>
      <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
        <UserProfile  campaignUser={campaignUser} campaign={campaign} user={user} />
      </div>
      <div className="user-content-info col-lg-9 col-md-9 col-sm-8 col-xs-12">
        <UserNavbar campaign={campaign} />
        <div className= "template">
          {children}
        </div>
      </div>
    </ContentLayout>
  )
}

export default ProfileLayout
