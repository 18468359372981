import React from "react"

const CatalogSearch = ({ searching, onSearchOpen, onProductTextSearch }) => {
  const handleProductTextSearch = e => {
    e.preventDefault()
    onProductTextSearch(e.target.freeSearch)
  }

  return searching ? (
    <form
      className="flex g-input__container"
      onSubmit={handleProductTextSearch}
    >
      <input
        className="g-input--search g-input__container mr-3"
        type="text"
        name="freeSearch"
        placeholder="Pesquisa livre..."
      />
      <button className="outline-reset p-1" type="submit">
        <i className="g-navbar__icon-find text-xl" />
      </button>
    </form>
  ) : (
    <button className="outline-reset p-1" onClick={onSearchOpen}>
      <i className="fas fa-search text-xl dead-grey" />
    </button>
  )
}

export default CatalogSearch
