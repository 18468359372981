import React from 'react';
import { ClientContainer,ShoppingCartContainer, withContainers } from '../../containers/gamification';
import CartButtons from './CartButtons';
import CartWarning from './CartWarning';
import CartItemsTable from './CartItemsTable';
import EmptyStateLayout from '../shared/EmptyStateLayout';
import { ReactComponent as NoStore } from '../../../assets/img/no_store.svg'
import CustomLink from '../shared/CustomLink';
import { element } from 'prop-types';
import { components } from 'react-select';
import PopUp from '../shared/PopUp'

const CartItems = props => {

  const {
    client = {},
    cart,
    onIncreaseItem,
    onDecreaseItem,
    onRemoveItem,
    checkout,
    campaign,
    onReturnToCatalog,
    onCheckCartItem
  } = props

  const message = "Clique no link seguinte para adicionar items ao seu carrinho: "
  const path = `/catalog`

  var allowed = true

  var toRemove = [];

  function checkforcard()
  {
    var hasseparatecard = false
    var hasotheritem= false
    if(cart.items.length > 1)
    {
      cart.items.forEach(element => {
        if(element.product.is_a_card == true && element.requires_separate_checkout == true )
        {
          hasseparatecard = true
        }
        if(element.product.is_a_card == false)
        {
          hasotheritem = true
          toRemove.push(element.product.name);
        }
      });

      if(hasseparatecard== true && hasotheritem == true)
      {
        allowed=false
      }
    }
  }

  return(
    cart.items.length > 0 ? (
      <>
      {checkforcard()}
      <CartItemsTable
        currencyLabel={client.currency_label}
        cart={cart}
        onIncreaseItem={onIncreaseItem}
        onDecreaseItem={onDecreaseItem}
        onRemoveItem={onRemoveItem}
        checkout={checkout}
      />
      <CartButtons onPrev={onReturnToCatalog} onNext={onCheckCartItem} prevText="Voltar" nextText="Seguinte" showNext={allowed}/>
      {allowed == false && 
      <PopUp isOpenValue={true} title={"Atenção"}>
      <h4 style={{marginBottom:'7px', color:'white'}}>Os Produtos do tipo cartão apenas podem ser encomendados isoladamente.</h4>
      <h4 style={{marginBottom:'7px', color:'white'}}>Para continuar remova o(s) produtos(s) abaixo:</h4>  
        {toRemove.map((item) => {
          return (
            <h4 style={{marginBottom:'3px', color:'white'}}>- {item} </h4>
          )
        })}
      </PopUp>}
      <CartWarning text={campaign.call_attention} />
    </>
    )
    : (
      <div>
        <EmptyStateLayout message={message}>
          <div className="gl-no-store">
            <NoStore className="mr-3 dead-grey gl-no-store__image"></NoStore>
          </div>
          <h2 className="mb-5">O seu carrinho está vazio.</h2>
        </EmptyStateLayout>
        <div className="flex justify-center">
          <CustomLink
            to={path}
            primary
            
          >
            Loja de prémios
          </CustomLink>
        </div>
      </div>
    )
  )
}

export default withContainers(CartItems, [ ShoppingCartContainer,ClientContainer ])
