import React from "react"
import { NavLink as Link } from "react-router-dom"
import { formatNumber, withCampaignUserContainer, Avatar, t} from "../../../normalizer"
import Moment from "moment"
const Status = ({ user, campaignUser, client }) => {

  const { first_name: firstName, last_name: lastName } = user

  const { availablePoints, avatar: campaignAvatar = {} } = campaignUser

  const { currency_label: currencyLabel } = client

  let avatar = undefined
  if (campaignAvatar && campaignAvatar.url) {
    avatar = campaignAvatar.url
  }

  return (
    <div className="g-user__info">
      <Link to="/profile" className="g-user__link">
        <img src={avatar || Avatar} alt="user" className="g-user-avatar" />
      </Link>
      <div className="g-user-data">
        <p className="g-user-name ">
          {firstName} {lastName}
        </p>
        <div className="flex items-baseline">
          <p className="g-user-points__value ">
            {formatNumber(availablePoints)}
          </p>
          <p className="text-sm">{currencyLabel}</p>
          { // <p className="vodafone-bold mx-1">|</p>
            // <p>a {new Moment().format(t("date-format"))}</p>
          }
        </div>
      </div>
    </div>
  )
}

export default withCampaignUserContainer(Status)
