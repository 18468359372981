import React, { Component } from 'react';
import { BalanceService } from '../../../services'
import { startFetch, endFetch} from '../../../actions/generalActions';
import { connect } from 'react-redux';
import { ToastContainer, ToastStore } from "react-toasts"
class UserBalanceExport extends Component{
  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

/*
  handlePointsExportClick = (user, campaign) => {
    const payload  = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }

    console.log(payload)

    BalanceService.requesPointsExport({clientSlug: user.client.slug, campaignId: campaign.slug})
      .then((response) => {
        const APIresponse = response.data
        if (APIresponse.result_ok) {
          ToastStore.success(APIresponse.message)
        } else {
          ToastStore.error(APIresponse.message)
        }
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido.')
      })
  }
*/

  handleClick(){
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props
    
    const payload = {
      client_slug: user.client.slug, 
      campaignId: campaign.slug
    }

    BalanceService.requesPointsExport({clientSlug: user.client.slug, campaignId: campaign.slug})
      .then((response) => {
        const APIresponse = response.data
        if (APIresponse.result_ok) {
          ToastStore.success(APIresponse.message)
        } else {
          ToastStore.error(APIresponse.message)
        }
        this.props.endFetch();
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido.')
        this.props.endFetch();
      })
  }

  render() {
    const { user, campaign } = this.props
    return (
      <>
        <div className='flex justify-end'>
          <PointsExportButton onClickHandler={this.handleClick} />
        </div>
        <ToastContainer store={ToastStore} />
      </>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

const PointsExportButton = ({user,campaign,onClickHandler}) => {
  return (
      <button className="gl-profile__button gl-content-shadow primary-bg secondary-text" onClick={() => onClickHandler()}>Exportar</button>
  )
}

export default connect(null,mapDispatchToProps)(UserBalanceExport);