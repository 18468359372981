import React from 'react'
import NavigationSubNavbarLink from '../shared/NavigationSubNavbarLink';

const QuizzLayout = ({children}) => {
  return (
    <>
      <nav className="mb-10 navbar-text text-size flex">
        <NavigationSubNavbarLink exact to ={"/quiz"}>
          Ativos
        </NavigationSubNavbarLink>
        <NavigationSubNavbarLink to ={"/quiz/history"}>
          Histórico
        </NavigationSubNavbarLink>
      </nav>
      {children}
    </>
  )
}

export default QuizzLayout
