import React, { Component} from 'react';

export default class Modal extends Component {
  render() {

    const {show, untoggle, extraSmall ,small, lessPadding, large ,children} = this.props
    if(!show) return null

    const containerModifier = [extraSmall && 'modal__container--extra-small', small && 'modal__container--small', large && "modal__container--large" ,"modal__container"].join(' ')
    const contentModifier = [extraSmall && "modal__content--extra-small", lessPadding && "modal__content--lessPadding" , "modal__content"].join(" ")
    return (
      <>
        <div className="modal__wrapper" onClick={untoggle}></div>
        <div className={containerModifier}>
          <div className={contentModifier}>
            {children}
          </div>
        </div>
      </>
    )
  }
}
