import React from "react"

const CustomButton = ({
  outline,
  primary,
  secondary,
  right,
  cart,
  small,
  medium,
  className,
  ...rest
}) => {
  const buttonClass = [
    "gl-profile__button gl-content-shadow",
    outline && "gl-profile__button--border border-primary primary-text",
    right && "gl-profile__button--right",
    primary && "primary-bg navbar-text",
    secondary && "primary-text border-primary white",
    cart && "gl-profile__button--cart",
    small && "gl-profile__button--small",
    medium && "gl-profile__button--medium",
    className
  ].join(" ")
  return <button className={buttonClass} {...rest} />
}

export default CustomButton
