import React from 'react'
import styled, {css} from 'styled-components'

const ButtonWrapper = styled.button`
  ${props=> css`
  color: ${props.color} !important;
  border: 1px solid ${props.color} !important;
  :hover{
    color: ${props.hoverColor} !important;
    background-color: ${props.color} !important;
  }`
}
`

const OrderButton= (props)=> {
  return (
    <ButtonWrapper className="order-button uppercase" {...props}>
      <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Encomendar
    </ButtonWrapper>
  )
}

export default OrderButton