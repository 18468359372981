import React,{ lazy, Suspense } from "react"
import Header from "./Header"
import Footer from "./Footer"
import ClassBuilder from "./ClassBuilder"
import PopUpLayout from "./PopUpLayout"
import { withRouter } from "react-router"

const GamificationLayout = ({ client, children, className, campaign, toRender, fontFamily, features}) => {

  console.log('Campaign Props:', campaign);

  const container = [
    "gl-layout gl-p-container default-bg py-10 gl-catalog__container mb:pt-0",
    !className && "default-bg",
    className,
    fontFamily
  ].join(" ")

  // Directory of the specific Slug Footer 
  const footerdir= `./Footer${campaign.slug}`

  // Variable that will contain the Footer in case it exists
  const FooterComponent=null;

  try
  {
    // Tries to import the footer using lazy()
     FooterComponent = lazy(() => import(footerdir));
  }
  catch{}

  console.log('Valor de clientSlug:', client.slug);
  return (
    <ClassBuilder campaign={campaign}>
      <Header features={features} toRender={toRender} fontFamily={fontFamily}/>
      <main className={container}>{children}</main>
      <PopUpLayout fontFamily={fontFamily}/>

      {/* Conditionally Render the default or the slug footer*/}
      {FooterComponent == null ? 
      <Footer fontFamily={fontFamily} campaign={campaign} features={features} clientSlug={client.slug}/> : 
      <FooterComponent fontFamily={fontFamily} campaign={campaign} features={features} clientSlug={client.slug}></FooterComponent>}
    
    </ClassBuilder>
  )
}

export default withRouter(GamificationLayout)
