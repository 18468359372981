import React, { Component } from 'react';
import { t, prefix } from '../../../i18n';

const tPaymentMethod = prefix('cart-checkout.payment-method')
const tPaymentSource = prefix('cart-checkout.source')

class OrderPaymentDetailsInline extends Component{
  
  render(){
    const { orderPayment } = this.props
    const { color_primary } = this.props.campaign

    const { provider, source } = orderPayment
    return(
      <>
        <div className='my-6 flex template-container between-xs'>
          <div>
            <h2 style={{color: color_primary}} className='template-title'>DADOS DE PAGAMENTO</h2>
          </div>
        </div>
        <div class="flex-1 flex justify-around items-center">
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("entity") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.entity || "")}</h5>
         </div>
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("reference") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.reference || "")}</h5>
         </div>
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("value") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.value || "")}</h5>
         </div>
        {provider.validity > 0 && <div class="flex-1 flex justify-around items-start my-5">
           <h5 class="flex-1 my-2 text-center">{tPaymentMethod("validity") + ':'}</h5>
           <h5 class="flex-1 self-center text-left">{provider.validity || ""}</h5>
         </div>}
         </div>
      </>
  )};
  static defaultProps = {
    campaign: {
      color_primary: undefined, 
    }
  }
}

export default OrderPaymentDetailsInline;
