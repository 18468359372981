
import React, { Component } from 'react'
import incentivehouse from '../../assets/img/incentivelogo.png';

export default class PageNotFound extends Component {
  state = {
    
  }
  render() {


    return (
      <div className='image-background image-background--no-overlay'  
        style={
          this.state.background_banner ? {backgroundImage: 'url(' + this.state.background_banner.url + ')'}: {} 
        }>
          <div>
            <div className="row center-lg center-xs center-md h-screen font-quicksand">
              <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
                <div className='py-10'>
                  <img 
                    style={{width:'260px'}} 
                    src={this.state.client ? this.state.client.logo.url : incentivehouse} 
                    alt= "logo"
                  />
                </div>
                <div className='py-10'>
                  <h3>A página que procura não existe</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}
