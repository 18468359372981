import React from "react"
import Flag from "./LevelFlag";
import { withPointBlockContainer } from "../../../containers/gamification";

const PointBlockStatus = ({ campaign, groupBlock }) => {
  const normalizePercentage = Number(groupBlock.percentage) || 0
  const normalizeMeta = Number(groupBlock.block_meta) || 100
  const normalizeTotal = Number(groupBlock.total_block) || 0

  const flagRealPercentage = campaign.point_block_nominal ? Math.round(normalizeTotal / normalizeMeta * 100) : Math.round(normalizeMeta)
  const flagPercentage = flagRealPercentage > 100 ? 100 : flagRealPercentage
  const flagActive = campaign.point_block_nominal ? normalizeMeta <= normalizeTotal : normalizeMeta <= normalizePercentage
  const flagPosition = `${flagPercentage - 10}%`
  const flagLabel = campaign.point_block_nominal ? Math.round(normalizeMeta) : (Math.round(normalizeMeta).toFixed(1) + '%')

  const objectiveLabel = campaign.point_block_nominal ? normalizeTotal : normalizePercentage.toFixed(1) + "%"

  return (
    <div className='flex justify-end gl-levels__user--container'>
      <div className='gl-levels__user--loader'>
        <div id="profile-gro-loader" className="gl-levels__container-mini">
          <svg id='gl-levels_bar' className={'gl-levels__gro_bar'}>
            <defs>
              <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor={campaign.color_primary} />
                <stop offset="100%" stopColor={campaign.color_primary} />
              </linearGradient>
            </defs>
            <rect
              fill="url(#linear)"
              height={"15px"}
              width={normalizePercentage + "%"}
            >
            </rect>

          </svg>
          <div className='gl-levels__flags-container-mini' >
            {
              <Flag mode={flagActive ? "active" : "next-level"} miniFlag={true} height={"35px"} textAlign={"top"} style={{ left: (flagPosition) }}>
                <span className='font-bold gro-flag-content'>{flagLabel}</span>
              </Flag>
            }
          </div>
        </div>
        <h2 className='primary-text gro-text-content font-light'>{campaign.point_block_label || 'Point Block'}: <strong>{objectiveLabel} </strong> </h2>
      </div>
    </div>
  )
}

export default withPointBlockContainer(PointBlockStatus)