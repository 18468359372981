import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import { startFetch, endFetch } from '../../../actions/generalActions';
import { UserService } from '../../../services';
import NavigationSubNavbarLink from '../shared/NavigationSubNavbarLink';


export class UtilityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: '',
      regulation: ''
    };
  }

  getRegulation() {
    this.props.startFetch();
    UserService.getRegulation()
      .then((response) => {
        this.setState({ regulation: response.data.regulation.regulation })
        this.props.endFetch();
      })
      .catch((error) => {
        this.setState({ rules: 'Sem regulamento' })
        this.props.endFetch();
      })
  }

  getRules() {
    this.props.startFetch();

    UserService.getRules()
      .then((response) => {
        this.setState({ rules: response.data.rules.rules })
        this.props.endFetch();
      })
      .catch((error) => {
        this.setState({ rules: 'Sem regras' })
        this.props.endFetch();
      })
  }

  componentDidMount() {
    this.getRegulation();
    this.getRules();
  }

  render() {
    const {campaign} = this.props

    return (
      <>
        <div className="flex between-xs navbar-text items-center mb-5 px-2">
          <h3>Informações Úteis</h3>
        </div>
        <div className="flex gl-profile__title navbar-text">
            <NavigationSubNavbarLink
              to={"/utility-info/regulation"}
            >
              Regulamento
            </NavigationSubNavbarLink>
            <NavigationSubNavbarLink
              to={"/utility-info/rules"}
            >
              Regras
            </NavigationSubNavbarLink>
        </div>
        <div className="template">
          <Switch>
            <Redirect exact strict from="/utility-info" to="/utility-info/regulation"></Redirect>
            <Route exact path="/utility-info/regulation" >
              <div className='custom-inner-html' dangerouslySetInnerHTML={{ __html: this.state.regulation }}>
              </div>
            </Route>
            <Route exact path="/utility-info/rules" >
              <div className='custom-inner-html' dangerouslySetInnerHTML={{ __html: this.state.rules }}>
              </div>
            </Route>
          </Switch>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaignReducer.campaign
})

function mapDispatchToProps(dispatch) {
  return {
    startFetch: () => { dispatch(startFetch()) },
    endFetch: () => { dispatch(endFetch()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityInfo)
