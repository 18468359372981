import React, { Component } from "react"
import { Switch } from "react-router-dom"
import Objectives from "./Objectives"
import "../../assets/css/gamificationLevel/index.scss"
import { default as Route } from "../../routes/CustomRoute"
import Home from "./Home"
import Catalog from "./Catalog"
import UtilityInfo from "./UtilityInfo"
import Profile from "./Profile"
import ShoppingCart from "./ShoppingCart"
import News from "./Announcement"
import Page from "./Page"
import Accelerators from "./Accelerators"
import CampaignDashboad from "../shared/CampaignPages";
import SupportingDocuments from "./SupportingDocuments"
import Quiz from "./Quiz"
import Rankings from "./Rankings"
import Analytics from "./Analytics"
import BonusPoints from "./BonusPoints"
import CustomizedPages from "./CustomizedPages"
import Terms from './Terms'
import { CampaignService, ChatService } from "./normalizer"
import GamificationLayout from "./shared/Layout"

import SaleAttribution from "./SaleAttribution"
import SaleTrade from "./SaleTrade"

export default class GamificationLevelTemplate extends Component {
  state = {
    features: [],
    requested: false
  }

  fetchFeatures(clientSlug, campaignSlug) {
    CampaignService.getFeatures({ clientSlug, campaignSlug })
      .then(response => {
        const { features } = response.data

        const hasChatFeature = features.find(feature => feature.name === "Chat")
        if (hasChatFeature) ChatService.createChatTag("ze-snippet")

        this.setState({ features: features, requested: true })
      })
      .catch(e => {
        this.setState({ requested: true })
      })
  }

  toRender = route => {
    return this.state.features.find(
      f => f.name.toLowerCase() === route.toLowerCase()
    )
  }

  features = () => { return this.state.features }

  componentDidCatch(e) {
    const { rollbar } = window
    if (rollbar && rollbar.error) {
      window.rollbar.error(e)
    }
  }

  componentDidMount() {
    const { slug, campaign } = this.props
    this.fetchFeatures(slug, campaign.slug)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaign !== this.props.campaign) {
      const { slug, campaign } = this.props
      this.fetchFeatures(slug, campaign.slug)
    }
  }

  render() {
    const { client, campaign, campaignUser, slug, fontFamily } = this.props
    return (
      <GamificationLayout 
        toRender={this.toRender} 
        campaign={campaign} 
        fontFamily={fontFamily}
        client={client}
        features={this.features()}
      >
        <Switch>
          <Route
            exact
            path="/"
            component={Home}
            campaign={campaign}
            slug={slug}
            toRender={this.toRender}

          />
          <Route
            path="/catalog"
            component={Catalog}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/utility-info"
            component={UtilityInfo}
            campaign={campaign}
          />
          <Route 
            path="/terms" 
            component={Terms}
          />
          <Route
            path="/profile"
            component={Profile}
            client={client}
            campaign={campaign}
            slug={slug}
            toRender={this.toRender}
          />
          <Route
            path="/cart"
            component={ShoppingCart}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/objectives"
            component={Objectives}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/dashboard"
            component={CampaignDashboad}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/news"
            component={News}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/accelerators"
            component={Accelerators}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/quiz"
            component={Quiz}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/rankings"
            component={Rankings}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/analytics"
            component={Analytics}
            client={client}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/bonus_points"
            component={BonusPoints}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/customized_pages"
            component={CustomizedPages}
            campaign={campaign}
            slug={slug}
          />

          <Route
            path="/sale-trading"
            component={SaleTrade}
            campaign={campaign}
          />
          <Route
              path="/sale-attributions"
              component={SaleAttribution}
              campaign={campaign}
              campaignUser={campaignUser}
            />
            <Route
              path="/supporting-documents/:slug"
              component={SupportingDocuments}
            />
            <Route path="/page/:slug" component={Page} />
        </Switch>
      </GamificationLayout>
    )
  }
}
