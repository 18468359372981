import React from 'react'
import styled, {css} from 'styled-components'

const BgWrapper = styled.div`
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  position: absolute;
  ${props=> props.backgroundImage ? 
    css`background: url(${props.backgroundImage});`
   : 
    css`background: linear-gradient(90deg, #E38200 10%, 30%, #00369B 60%);`
  }
  background-position: center
  background-size: cover;
  background-repeat: no-repeat;

 
`
export default function BackgroundWrapper({ children, ...rest}) {
  return (
    <BgWrapper {...rest}>
      {children}
    </BgWrapper>
  )
}

const ColoredBackgroundWrapper = styled.div`
  background: ${props => props.bg}
  color: ${props => props.text}

  .secondary {
    color: ${props => props.secondary}
  }
`

export function ColoredBackground({children, ...rest}){
  return (
    <ColoredBackgroundWrapper {...rest}>
      {children}
    </ColoredBackgroundWrapper>
  )
}