import React, { Component } from 'react';
import { connect } from "react-redux"
import BackgroundWrapper from '../client/BackgroundWrapper';
import ClientLogo from '../client/Logo';
import { UserService, AuthenticationService } from "../../../services"
import { doLogout } from "../../../actions/generalActions"

class NoEmptyState extends Component {

  handleLogout = () => {
    UserService.logOut().then(() => {
      AuthenticationService.logout()
      this.props.doLogOut()
    })
  }

  render(){ 
    const client = this.props.user.client
    return (
      <BackgroundWrapper backgroundImage={client.background_banner.url}>
        <div className="row center-lg  font-quicksand center-xs center-md h-screen">
          <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
            <div className='py-10'>
              <ClientLogo src={client.logo.url} />
            </div>
            <div className='px-3 py-2'>
              <h2 className='py-2'>Não existem campanhas ativas de momento!</h2>
              <p className='py-3'>Por favor contacte os seus administradores para saber mais sobre novos programas</p>
                <button 
                  className="my-8 mx-auto button-cart button-primary text-xl" 
                  onClick={() => this.handleLogout()}> 
                    Logout
                </button>

            </div>
          </div>
        </div>
      </BackgroundWrapper>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    doLogOut: () => {
      dispatch(doLogout())
    }
  }
}

export default connect(null,mapDispatchToProps)(NoEmptyState)