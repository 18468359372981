import React from "react"
import StyledBannerImage from "../StyledBannerImage";

function selectGrandient(gradient) {
  switch (gradient) {
    case "hard":
      return "home-banner__container--hard"
    case "med":
      return "home-banner__container--medium"
    case "soft":
      return "home-banner__container--soft"
    default:
      return ""
  }
}

const CustomImg = ({src}) => {
  return <img src={src} alt='' className={selectGrandient()}/>
}

const HomeBanner = ({ url, title, gradient, link, mobileView }) => {
  const classes = [
    "home-banner__image ",
    selectGrandient(gradient)
  ].join(" ")
  return (
    // <StyledImage className={classes} url={url}>
    //   <p>{title}</p>
    // </StyledImage>
    <>
    {
      mobileView ? 
        <div>
         <CustomImg src={url} />
        </div>
      
      :
      <div className={"home-banner__container"}>
        <StyledBannerImage className={classes} url={url} />
        <p className="above-content home-banner__title">{title}</p>
      </div>
    }
    </>
  )
}

export default HomeBanner
