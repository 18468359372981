import React from "react"
import CircularHeaderLoader from "./CircularHeaderLoader"
import { withPointBlockContainer, NumberToHumanRange } from "../../../normalizer"

const ProductBlockContainer = props => {
  const productBlock = props.productBlock;
  const normalizedPercentage = Number(productBlock.percentage);

  return (
    <div className="flex flex-col items-center px-3">

      <CircularHeaderLoader
        campaign={props.campaign}
        progress={normalizedPercentage}
        expected_progress={0}
        id={props.id}
      >
        <span className='font-bold text-xl'>{NumberToHumanRange(productBlock.num_of_points_earned,2,5)}</span>
        <br></br>
        <span className='text-sm'>{props.currencyLabel}</span>
      </CircularHeaderLoader>
      <p className="text-lg text-center">{productBlock.product_name}</p>
    </div>
  )
}

const PointBlocks = ({ productBlocks, lastUpdated, campaign, currencyLabel, color }) => {
    return (
        <section className="m-1 my-5">
          <h3 className={`${color} font-light pt-6 text-center`}>Conquistas</h3>
          <div className={`${color} flex -mx-3 mt-2 flex-wrap justify-center`}>
            {productBlocks.map(productBlock => (
              <ProductBlockContainer
                productBlock={productBlock}
                campaign={campaign}
                currencyLabel = {currencyLabel}
                key={"product-block-" + productBlock.id}
              />
            ))}
          </div>
          <div className={`${color} flex mt-2 flex-wrap justify-center`}>
            <span className='text-center text-base'>Atualizado: {lastUpdated}</span>
          </div>
        </section>
      )
}

export default withPointBlockContainer(PointBlocks)
