const initialState = {
  levels: [],
  selectedLevel: {level_number: 0, requirements: [], id: 0}
}

const levelsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SELECT_CAMPAIGN':
      return initialState
    case 'SAVE_USER_LEVELS':
      return {
        ...state,
        levels: action.payload.levels
      }
    case 'SAVE_SELECTED_LEVEL':
      return {
        ...state,
        selectedLevel: action.payload
      }
    default:
      return state;
  }
}
export default levelsReducer;