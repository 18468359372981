import React from 'react'

const Burger = (props) => {

  return (
    <button className="gl-burger primary-text gl-outline-reset" onClick={props.onClick}>
      <span className="secondary-bg"></span>
      <span className="secondary-bg"></span>
      <span className="secondary-bg"></span>
    </button>
  )
}

export default Burger
