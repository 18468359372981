import React from "react"
import ContentLayout from "../shared/ContentLayout"
import TabLink from "../shared/TabLink"
import {Switch, Route} from "react-router-dom"
import Pending from './Pending'
import Completed from './Completed'
import Update from './Update'

const SaleAttribution = ({ campaign, campaignUser }) => {
  return (
    <>
      { !campaignUser.canSeeSaleAttributions ? 
        <></>
      : 
        <ContentLayout campaign={campaign} title={"Atribuição de Contratos"}>
          <div className="flex py-2 justify-between tab-height uppercase col-xs-12 font-quicksand-light">
            <div className="flex">
              <TabLink to={"/sale-attributions/pending"}>Contratos com Erro</TabLink>
              <TabLink to={"/sale-attributions/completed"}>Contratos Atribuídos</TabLink>
            </div>
          </div>
          <Switch>
            <Route path="/sale-attributions/pending" component={Pending}/>
            <Route path="/sale-attributions/completed" component={Completed}/>
            <Route path="/sale-attributions/update" component={Update}/>
          </Switch>
        </ContentLayout>
      }
    </>
  )
}
export default SaleAttribution
