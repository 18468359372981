import React from "react"
import BackgroundDiv from "../shared/BackgroundDiv"
import { t } from "../../../i18n"
import Moment from "moment"
import CustomLink from "../shared/CustomLink"
import ImageCardLayout from "../shared/ImageCardLayout"

function selectGrandient(gradient) {
  switch (gradient) {
    case "hard":
      return "gl-quiz--hard"
    case "med":
      return "gl-quiz--medium"
    case "soft":
      return "gl-quiz--soft"
    default:
      return ""
  }
}

const QuizzItem = props => {
  const {
    main_image: mainImage,
    name,
    description,
    gradient_type: gradientType,
    end_date: endDate,
    done,
    id
  } = props

  const normalizedEndDate = new Moment(endDate).format(t("date-format"))
  const hasBackground = !!mainImage.url

  const containerClass = [
    "gl-quiz font-light mb-5 p-5 gl-content-shadow",
    selectGrandient(gradientType),
    hasBackground ? "text-color " : "navbar-text "
  ].join(" ")

  const path = done ? `/quiz/history/${id}` : `quiz/${id}`

  return hasBackground ? (
    <div className="my-8 flex flex-col">
      <ImageCardLayout
        link={path}
        title={name}
        subtitle={description}
        image={mainImage.url}
        label="Quiz"
        enddate={endDate} 
      />
    </div>
  ) : (
    <BackgroundDiv
      emptyBackground={!hasBackground}
      className={containerClass}
    >
      <div className="flex justify-between mb-1 gl-above-content items-end">
        <p className="font-bold gl-title-size mb-1 gl-quiz__title">{name}</p>
        <p className="mb-1 gl-quiz__wrap">
          {done ? "Terminado a" : "Termina a"}{" "}
          <strong>{normalizedEndDate}</strong>
        </p>
      </div>
      <p className="gl-quiz__description gl-above-content flex-1">{description}</p>
      <CustomLink to={path} primary right className="gl-above-content">
        {done ? "Rever respostas" : "Participar no Quiz"}
      </CustomLink>
    </BackgroundDiv>
  )
}

export default QuizzItem
