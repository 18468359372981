import React from 'react'
import InputKeyValue from '../../shared/InputKeyValue';

const UserInfoEdit = (props) => {
  const { onEditPasswordSubmit } = props

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      currentPassword,
      newPassword,
      confNewPassword,
    } = e.target

    if(newPassword.value !== "" && newPassword.value === confNewPassword.value){
      onEditPasswordSubmit({
        user: {
          current_password: currentPassword.value,
          password: newPassword.value,
          password_confirmation: confNewPassword.value
        }
      })
    }
  }

  return (
    <form onSubmit={handleSubmit} id="user-update-form" className="g-profile__data-container">
      <div className="row g-profile__info-data g-profile__info-block mx-0 mb-4">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Password Atual'}
            type="password"
            placeholder={'password atual'}
            name="currentPassword"
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Nova Password'}
            type="password"
            placeholder={'nova password'}
            name="newPassword"
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <InputKeyValue
            title={'Confirmar Password'}
            type="password"
            placeholder={'confirmar password'}
            name="confNewPassword"
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
    </form>
  )
}

export default UserInfoEdit
