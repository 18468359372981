import React from 'react'
import { withQuizQuestionHistoryContainer } from '../../containers/gamification';
import {QuestionCard} from './QuestionCard';

const QuizHistoryCard = (props) => {
  
  return (
    <QuestionCard done {...props}/>
  )
}

export default withQuizQuestionHistoryContainer(QuizHistoryCard)
