import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveCampaigns } from '../actions/campaignActions';
import { saveUser } from '../actions/userActions';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router';

class LogInRoute extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  render() {
    const {component: Component, ...rest} = this.props
    const { confirm_data } = this.props.user
    const { client } = this.props
    const jwt= localStorage.getItem('jwt')
    return(
      <Route {...rest} render={(props) => {
        return client && jwt && confirm_data ?
          <Redirect to={`/campaign`} /> //user is authenticathed
          :
           <Component {...props}/> //user to authenticate
      }} />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    client: state.userReducer.client
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCampaign: (campaign) => {
      dispatch(saveCampaigns(campaign));
    }
  }

}

export default connect(mapStateToProps,mapDispatchToProps)(LogInRoute);