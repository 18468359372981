import axios from './interceptor';

export const NotificationsService = {

  getNotifications(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.slug}/notifications`;
    return axios({
      method: 'get',
      url: url,
      params: {
        page: payload.page
      }
    });
  },

  getNewerNotifications({clientSlug, slug, timestamp}){
    const url = `/api/clients/${clientSlug}/campaigns/${slug}/notifications/new_notifications_to_show`;
    return axios({
      method: 'get',
      url: url,
      params: {
        timestamp: timestamp
      }
    });
  },


  updateNotification(payload){
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.slug}/notifications/${payload.id}`;
    return axios({
      method: 'put',
      url: url
    });
  }
};