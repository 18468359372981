import React, { Component } from 'react'
import { prefix } from '../../../i18n';
import { Redirect } from 'react-router-dom'
import { withShoppingCartContainer } from '../../containers/gamification';
import { ReactComponent as Box } from '../../../assets/img/box.svg';
import CustomLink from '../shared/CustomLink';

const tCartCheckout = prefix("cart-checkout")

class CartSuccess extends Component {

  componentWillUnmount() {
    this.props.fetchCart()
  }
  render() {
    const { cart, campaignUser } = this.props
    const hasPaymentInformation = (!!campaignUser.entity && !!campaignUser.reference && !!campaignUser.value)
    if (!cart.shipment_address) {
      return <Redirect to="/cart" />
    }
    return (
      <div>
        { hasPaymentInformation && (
        <>
        <h1 className="title_payment_data">Dados de Pagamento</h1>
        <table className="table_payment_data">
          <thead>
            <tr>
              <th className="table_payment_data_th"><strong>Entidade: </strong>{campaignUser.entity}</th>
              <th className="table_payment_data_th"><strong>Referência: </strong>{campaignUser.reference}</th>
              <th className="table_payment_data_th"><strong>Valor: </strong>{parseFloat(campaignUser.value).toFixed(2)} &euro;</th>
              {campaignUser.validity ? <th className="table_payment_data_th"><strong>Validade (dias): </strong>{campaignUser.validity}</th> : null}
            </tr>
          </thead>
        </table>
        </>
        )}
        <div className="flex flex-col justify-center items-center">
          <Box className="dead-grey-fill mt-16 h-48" />
          <h2 className="my-3 dead-grey">{tCartCheckout("checkout-successfully")}</h2>
          <CustomLink className="mt-5" to="/catalog" primary>{tCartCheckout("return-to-store")}</CustomLink>
        </div>
      </div>
    )
  }
}

export default withShoppingCartContainer(CartSuccess)
