import React from "react"
import Moment from "moment"
import { t } from "../../../i18n"
import StyledImage from "./StyledImage"
import { Chevron } from "../normalizer"
import { Link } from "react-router-dom"

const ImageCardLayout = ({ title, link, image, subtitle, enddate, label}) => {
  return (
    <Link className="navbar-text g-home__promotion" to={link}>
      <StyledImage
        url={image}
        className="g-promotion__image g-promotion__image--announcement"
      />
      <div className="g-promotion">
        <label className="g-post__label">{label}</label>
        <div className="g-promotion__title">
          <h3 className="g-post__title g-post__title--promotion ">{title}</h3>
          <Chevron className="primary-fill g-promotion__chevron" />
        </div>
        <div className="g-promotion__description g-promotion__description--announcement">
          <p>{subtitle}</p>
        </div>
        <p className="text-right vodafone">
          Termina a {Moment(enddate).format(t("date-format"))}
        </p>
      </div>
    </Link>
  )
}

export default ImageCardLayout
