import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContentLayout from '../shared/ContentLayout';
import {Switch, Route, NavLink, Redirect} from 'react-router-dom'
import { startFetch, endFetch } from '../../../actions/generalActions';
import { UserService } from '../../../services';

export class UtilityInfo extends Component {
  constructor(props){
    super(props);
    this.state={rules: '',
      regulation: '' };
  }
  getRules(){
    this.props.startFetch();

    UserService.getRules()
      .then((response) => {
          this.setState({rules: response.data.rules.rules})
          this.props.endFetch();
      })
      .catch((error) => {
        this.setState({rules: 'Sem regras'})
        this.props.endFetch();
      })
  }
  getRegulation(){
    this.props.startFetch();

    UserService.getRegulation()
      .then((response) => {
          this.setState({regulation: response.data.regulation.regulation})
          this.props.endFetch();
      })
      .catch((error) => {
        this.setState({rules: 'Sem regulamento'})
        this.props.endFetch();
      })
  }
  componentDidMount(){
      this.getRules();
      this.getRegulation();
  }
  render() {
    const {
      campaign,
      tabs,
      rules
    } = this.props

    const activeStyle = {backgroundColor: campaign.color_primary, color: campaign.navbar_text_color }

    return (
      <ContentLayout  title="Informações Úteis" campaign={campaign}>
        <div className="navbar-content-info">
          <div className="flex py-2 tab-height uppercase">
            <TabLink
              to={"/utility-info/regulation"}
              activeStyle={activeStyle}
              >
              Regulamento
            </TabLink>
            <TabLink
              to={"/utility-info/rules"}
              activeStyle={activeStyle}
              >
              Regras
            </TabLink>
          </div>
          <div className="template">
            <Switch>
              <Redirect exact strict from="/utility-info" to ="/utility-info/regulation"></Redirect>
              <Route exact path="/utility-info/regulation" >
                <div dangerouslySetInnerHTML={ {__html: this.state.regulation} }>
                </div>
              </Route>
              <Route exact path="/utility-info/rules" >
                <div dangerouslySetInnerHTML={ {__html: this.state.rules} }>
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </ContentLayout>
    )
  }
}

const TabLink = ({to, children, activeStyle}) => (
  <NavLink
    className="w-1/2 flex items-center justify-center btn-secondary mx-2 px-6"
    activeClassName={"-my-2 utility-info-active-tab"}
    to={to}
    activeStyle={activeStyle}
    >
    {children}
  </NavLink>
)

const TabContent = ({path, title, content}) => (
  <Route path={path} exact>
    <div>{content}</div>
  </Route>
)


const mapStateToProps = (state) => ({
  campaign: state.campaignReducer.campaign,
  tabs: []
})

function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UtilityInfo)
