import React from "react"
import ReactSelect from "react-select"
const CatalogOrderOptions = ({campaign, orderOptions, onOrderChange, selectedOrder}) => {
  return (
        <ReactSelect
          isClearable
          campaign={campaign}
          onChange={e => onOrderChange(e)}
          options={orderOptions}
          value={selectedOrder}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          placeholder="Ordenar por ..."
          className="px-2 col-sm-3 h-full col-xs-12 catalog__filter"
          classNamePrefix="o-custom-select"
          noOptionsMessage={() => "Sem filtros"}
        />

  )
}

export default CatalogOrderOptions
