import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import Moment from 'moment'

class SalesFilter extends Component {
  state = {
    startDate: undefined,
    endDate: undefined,
    username: undefined
  }

  changeFilter(name, mapper = (event) => event.target.value) {
    return (value) => {
      this.setState({[name]: mapper(value)})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.onSaleFilter({...this.state})
  } 

  handleOnChangeSubmit = (e) => {
    e.preventDefault()
    this.setState(
      {username: e.target.value}, 
      () => this.props.onSaleFilter({...this.state}) 
    )
  }
  
  mommentMapper = (date) => date !== null ? new Moment(date) : undefined
 
  render() {
    const {endDate, startDate} = this.state
    
    const sartDateFromMoment = startDate ? startDate.toDate() : startDate
    const endDateFromMoment = endDate ? endDate.toDate() : endDate
    return (
      <form className="row justify-between mt-10 mb-5" onSubmit={this.handleSubmit}>
        <div className="col-md-6 col-sm-12 col-xs-12 row justify-between">
          <div className='col-sm-12 col-xs-12 col-md-6'>
            <div className="flex px-1 justify-between my-2  md:my-2 lg:my-0  dead-grey-border gl-sales__input">
              <DatePicker
                placeholderText="Data de Início"
                selected={sartDateFromMoment}
                onChange= {this.changeFilter('startDate', this.mommentMapper)}
                maxDate={endDateFromMoment}
                dateFormat="dd-MM-yyyy"
                name={"beginDate"}
                showDisabledMonthNavigation
              />
              <i className="far fa-calendar-alt dead-grey" />
            </div>
          </div>
          <div className="col-sm-12 col-xs-12 col-md-6">
            <div className="flex  px-1 justify-between my-2 md:my-2 lg:my-0 dead-grey-border gl-sales__input">
              <DatePicker
                placeholderText="Data de Fim"
                selected={endDateFromMoment}
                onChange= {this.changeFilter('endDate', this.mommentMapper)}
                minDate={sartDateFromMoment}
                dateFormat="dd-MM-yyyy"
                showDisabledMonthNavigation
                name={"endDate"}
              />
              <i className="far fa-calendar-alt dead-grey" />
            </div>
          </div>
        </div>
        <div className="row my-2 md:my-2 lg:my-0 col-sm-12 col-xs-12 col-md-3 flex justify-between">
          <div className='col-xs-10 col-sm-11 col-md-10'>
            <input
              className="dead-grey-border gl-sales__input gl-sales__input--direct mr-2"
              name="userName"
              placeholder="Procurar Jogador"
              onChange={this.handleOnChangeSubmit}
              value={this.state.username}
            />
          </div>
          <div className='flex justify-end col-xs-2 col-sm-1 col-md-2'>
            <button type="submit" className="p-2 dead-grey-border gl-sales__button">
              <i className="fas fa-search dead-grey" />
            </button>
          </div>
        </div>
      </form>
    )
  }
};

export default (SalesFilter);
