import React from "react"
import Modal from "../../../shared/Modal";
import CustomSelect from "../../../shared/CustomSelect"

const ConfirmationModal = ({
  selected,
  saleAttribution,
  selectedUser,
  onCancelSaleAttribution,
  onUpdateSaleAttribution
}) => {

  const handleAcceptAction = e => {
    e.preventDefault()
    onUpdateSaleAttribution(saleAttribution.id,selectedUser.id)
  }

  return (
    <Modal show={selected} untoggle={onCancelSaleAttribution} extraSmall>
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 py-2">
          <h2>Atenção</h2>
        </div>
        <div className="py-4 px-2 text-base bg-yellow-lighter text-yellow-darker max-w-sm my-5">
          <p>Deseja realmente executar esta ação? </p>
          <p>Se aceitar, os pontos do contrato serão atribuídos ao utilizador indicado</p>
        </div>
        <div className="py-4 px-2 text-base bg-red-lighter text-red-darker max-w-sm my-5">
          <p>A ação vai decorrer em segundo plano. Para confirmar a sua conclusão poderá verificar dentro de momentos a lista de “contratos atribuídos” ou atualizar a página.</p>
        </div>

        <div className="flex justify-between">
          <button
            className="outline-reset bg-red-dark text-base text-white rounded px-4 py-3"
            onClick={onCancelSaleAttribution}
          >
            <i className="fa fa-times" />{" "}
            <span className="font-quicksand">Cancelar</span>
          </button>
          <button
            className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
            onClick={handleAcceptAction}
          >
            <i className="fas fa-check" />{" "}
            <span className="font-quicksand">Aceitar</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
