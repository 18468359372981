import axios from './interceptor';

export const AddressesService = {

  getAddresses() {
    const url = 'api/users/addresses';
    return axios({
      method: 'get',
      url: url
    });
  },
  createAddress(payload){
    const url = 'api/users/addresses';
    return axios({
      method: 'post',
      url: url,
      data: payload
    })
  },
  updateAddress(payload){
    const url = 'api/users/addresses/'+ payload.id;
    return axios({
      method: 'put',
      url: url,
      data: payload
    })
  },
  deleteAddress(payload){
    const url = 'api/users/addresses/' + payload.id;
    return axios({
      method: 'delete',
      url: url
    })
  }
};