import React from "react"

const TableHeaderLayout = ({ children }) => {
  return (
    <div className="invoice__table-header primary-bg text-white row items-center font-10">
      {children}
    </div>
  )
}

export default TableHeaderLayout
