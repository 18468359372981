import React from 'react'
import Moment from "moment"
import { t } from "../../../../i18n"

const ObjectivesRow = (props) => {
    const { name, end_date, start_date, percentage, employee_identifier } = props
    return (
        <tr className="gl-sales__row dead-grey">
          <td className="p-2">{employee_identifier}</td>
          <td className="p-2">{name}</td>
          <td className="p-2">
            {new Moment(end_date).format(t("date-format"))}
          </td>
          <td className="p-2">
            {new Moment(start_date).format(t("date-format"))}
          </td>
          <td className="p-2 text-right">{percentage} %</td>
        </tr>
    )
}

const ObjectivesTable = ({objectives}) => {

  return (
    <div className='overflow-x-auto'>
      <table  className='w-full' >
        <thead className="gl-sales__row dark-grey">
          <tr> 
            <th className="p-2 text-left">Jogador</th>
            <th className="p-2 text-left">Nome</th>
            <th className="p-2 text-left whitespace-no-wrap">Data de fim</th>
            <th className="p-2 text-left whitespace-no-wrap">Data de início</th>
            <th className="p-2 text-right">Atingimento</th>
          </tr>
        </thead>
        <tbody>
        {objectives.map(e => (
          <ObjectivesRow key={'obj' + e.ojective_id + '-' + e.campaign_user_id} {...e} />
        ))
        }
        </tbody>
      </table>
    </div>
  )
}

export default ObjectivesTable
