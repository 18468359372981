const initialState = {
  notifications: [],
  pagination: {},
  numNotificationsToShow: 0
}

const notificationsReducer = (state = initialState, action) => {
  function removeDuplicatedNotifications(notificationsToAdd , currentNotifications){
    const currentNotificationsIds = currentNotifications.map(i => i.id)
    const filteredNotifications = notificationsToAdd.filter(
      notification => !currentNotificationsIds.includes(notification.id)   
     )
    return filteredNotifications
  }

  switch(action.type){
    case 'SAVE_NOTIFICATIONS':
      let currentNotifications = state.notifications 
      let moreNotifications = action.payload.notifications
      let notificationsToAdd = removeDuplicatedNotifications(moreNotifications, currentNotifications)
      let notifications = currentNotifications.concat(notificationsToAdd)
      return {
        ...state,
        numNotificationsToShow: action.payload.num_notifications_to_be_viewed|| 0,
        notifications: notifications,
        pagination: action.payload.pagination || {}
      }
    case 'SAVE_NEWER_NOTIFICATIONS': 
      let atualNotifications = state.notifications 

      let newerNotifications = action.payload.notifications
      let newerNotificationsToAdd = removeDuplicatedNotifications(newerNotifications, atualNotifications)
      let newNotificationsAdded = newerNotificationsToAdd.concat(atualNotifications)
      return {
        ...state,
        numNotificationsToShow: action.payload.num_notifications_to_be_viewed|| 0,
        notifications: newNotificationsAdded,
      }
    case 'VIEW_NOTIFICATION':
      const numNotificationsToShow = state.numNotificationsToShow > 1 ?
        state.numNotificationsToShow -1 : 0

      return {
        ...state,
        numNotificationsToShow,
        notifications: state.notifications.map(n => {
          if(n.id === action.payload.id){
            n.shown = true
          }
          return n
        })
      }
    default:
      return state;
  }
}
export default notificationsReducer;