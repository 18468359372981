import React from "react"
import { Switch, Route } from "react-router-dom"
import AnnouncementShow from "./Details"
import AnnouncementPreview from "./Preview"
import FeedContainer from '../../containers/gamification/FeedContainer'
import { withContainers, ResizeContainer } from "../normalizer"
import Pagination from "../shared/Pagination"

const Announcement = ({ news, pagination, currentPage, onPageChange, imageSize, limitText, onHandleFeedOnClick}) => {
  const previews = news.map(announcement => {
    return (
      <div
        className="my-8 flex flex-col"
        key={"announcement-" + announcement.id}
      >
        <AnnouncementPreview announcement={announcement} imageSize={imageSize} limitText={limitText} onHandleFeedOnClick={onHandleFeedOnClick} />
      </div>
    )
  })

  const paginate = pagination.total_pages > 1 && (
    <div className="flex justify-end mt-10">
      <Pagination
        totalPages={pagination.total_pages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  )
  return (
    <Switch>
      <Route exact 
             path={`/announcement/:slug/:id`} 
             component={AnnouncementShow}
      >
      </Route>
      <Route exact path={"/announcement"}>
        <>
          {previews}
          {paginate}
        </>
      </Route>
    </Switch>
  )
}

export default withContainers(Announcement, [FeedContainer, ResizeContainer])
