import React, {Component} from 'react'
import { startFetch, endFetch} from '../../../actions/generalActions';
import { saveBalance } from '../../../actions/balanceActions'
import { BalanceService } from '../../../services'
import { connect } from 'react-redux'
import Moment from 'moment'
import {prefix, t} from '../../../i18n'
import { NavLink } from "react-router-dom";
const tBalance = prefix('balance')
class UserBalance extends Component {

  fetchUserBalance() {
    this.props.startFetch();
    const {
      user,
      campaign,
      page
    } = this.props

    const payload = {
      clientSlug: user.client.slug, 
      campaignId: campaign.slug,
      page: page
    }

    BalanceService.getUserBalance(payload).then((response) => {
      const balance = response.data
      this.props.saveBalance(balance);
      this.props.endFetch();
    }).catch(error => {
      this.props.endFetch();
    })
  }

  componentDidUpdate(prevProps){
    if(this.props.page !== prevProps.page ){
      this.fetchUserBalance()
    }
    
    if( this.props.campaign.slug !== prevProps.campaign.slug) {
      this.fetchUserBalance()
    }
  }

  componentDidMount() {
    if (this.props.points.length === 0){
      this.fetchUserBalance()
    }
  }

  render() {
    const { points, toRender } =  this.props
    return (
        <> 
          { toRender('PointValidity') &&     
            <NavLink to={"/profile/validity"} className = 'text-lg float-right my-5 gray' exact>
              Ver tabela de expirados <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </NavLink>
          }
          <table className='mt-12 w-full'>
            <thead>
              <tr className='user-header uppercase'>
                <th className='w-15 prod-th-title order-th text-left'>{tBalance('description')}</th>
                <th className='w-10 prod-th-title  text-center'>{tBalance('date')}</th>
                <th className='w-10 prod-th-title  text-center'>{tBalance('expired-date')}</th>
                <th className='w-5 prod-th-title  text-right'>{tBalance('points')}</th>
              </tr>
            </thead>
            <tbody>
              {points && points.map((e, key) =><Row key={key} {...e}/> )}
            </tbody>
          </table>  
        </>
    )
  }
}
const Row = ({description, date, expired_date, points_earned}) => {
  return (
    <tr className="gray user-row">
      <th className="w-15 text-left py-2 text-break">
        <p>{description}</p>
      </th>
      <th className="w-10 text-center">
        <p>{Moment(date).format(t('date-format'))}</p>
      </th>
      <th className="w-10 text-center">
      {!!expired_date ? ( <p>{Moment(expired_date).format(t('date-format'))}</p> ) : ( <p>{'-'}</p> ) }
      </th>
      <th className="w-5 text-right ">
        <p>{points_earned}</p>
      </th>
    </tr>
  )
}

function mapDispatchToProps(dispatch){
  return{
    saveBalance: (balance) => { dispatch(saveBalance(balance))},
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}


export default connect(null, mapDispatchToProps)(UserBalance)
