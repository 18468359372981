import axios from './interceptor'
export const CardsService = {
  getCampaignCards({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/card_types`;
    return axios({
      method: 'get',
      url: url
    })
  },
  getUserCards({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_cards`;
    return axios({
      method: 'get',
      url: url
    })
  },



  putChargeUserCard({clientSlug, campaignSlug, cardId, value, points}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_cards/${cardId}/recharge`;
    return axios({
      method: 'put',
      url: url,
      data:{
        recharge_option : {
          value: parseInt(value),
          points: parseInt(points)

        }
      }
    })
  },

  putRegisterUserCard({clientSlug, campaignSlug,cardType,number}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_cards/register`;
    return axios({
      method: 'put',
      url: url,
      data:{
        client_card_type_id:`${cardType}`,
        number:`${number}`
      }
    })
  },
  putCancelUserCard({clientSlug, campaignSlug, cardId}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_cards/${cardId}/cancel`;
    return axios({
      method: 'put',
      url: url
    })
  },
};
