import React, {memo} from 'react'
import Modal from "../../../shared/Modal"
import {prefix} from '../../../../i18n'

const tCoPayment = prefix('co-payment')

const CoPaymentModal = memo(
  ({
    paymentDefinitions,
    show,
    close,
    conditionsAccepted,
    handleAcceptConditions,
  }) => { 

   return <Modal show={show} lessPadding medium untoggle={close}>
      {/*<div class="flex flex-1 flex-col justify-center items-center">*/}
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 pb-2 flex flex-row justify-center items-center">
          <h2 class="flex-1">{tCoPayment("conditions.title")}</h2>
        </div>
      </div>
      <div
        style={{ height: '360px' }}
        className="w-full overflow-x-hidden overflow-y-auto text-center custom-inner-html col-xs-12 leading-normal p-5"
        dangerouslySetInnerHTML={{
          __html: paymentDefinitions.general_terms_and_coditions,
        }}
       />
      <div className="flex flex-row justify-end items-center mt-10">
        {/*<div class="flex flex-row justify-center items-center">
          <input
            className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
            type="checkbox"
            checked={conditionsAccepted}
            onChange={close}
          />
          <span className="font-quicksand mx-3">Não Aceitar</span>
        </div>*/}
        <div class="flex flex-row justify-center items-center">
          <input
            className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
            style={{ height: '20px', width: '20px' }}
            type="checkbox"
            checked={conditionsAccepted}
            onChange={handleAcceptConditions}
          />
          <span className="font-quicksand mx-3">Aceitar</span>
        </div>
      </div>
    </Modal>
  }
); 

export default CoPaymentModal
