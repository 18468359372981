import React from "react"
import DefaultAvatar from "../../../../assets/img/user-placeholder.svg"

const Employee = props => {
  const { first_name: firstName, last_name: lastName, email, avatar } = props

  let identifier = firstName
  if (firstName) {
    identifier += " "
    identifier += lastName
  } else {
    identifier = email
  }

  return (
    <div className="col-xs-6 col-sm-4 col-md-2 g-analytics__employee mb-5 navbar-text">
      <img
        alt=""
        src={avatar.url || DefaultAvatar}
        className="g-user-avatar g-user-avatar--plain g-user-avatar--small mb-5"
      />
      <p className="vodafone small-title-size text-center">{identifier}</p>
    </div>
  )
}

const Contributors = ({ group }) => {
  return (
    <div>
      <div className="navbar-text vodafone-light flex items-center mb-5 justify-end">
        <p className="title-size">
          {group.employees.length} jogadores selecionados
        </p>
      </div>
      <div className="row">
        {group.employees.map(e => (
          <Employee key={`employee-${e.id}`} {...e} />
        ))}
      </div>
    </div>
  )
}

export default Contributors
