import React, { Component } from "react"
import { connect } from "react-redux"
import { FeedService, buildTransaction, saveNews, saveAnnouncement } from "../normalizer"

class FeedContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      page: 1
    }
  }

  getNews() {
    const { user, campaign } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(() =>
      FeedService.getFeed(payload).then(response => {
        const { news, pagination } = response.data
        this.props.saveNews(news, pagination)
      })
    )
  }

  getSelectedFeed(slug){
    const { user, campaign } = this.props
    const clientSlug = user.client.slug
    let splittedSlug = slug.split('/')
    const contentType = splittedSlug[0]
    const contentId = splittedSlug[1]
    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug,
      contentType: contentType,
      contentId: contentId
    }

    this.props.startTransaction(() =>
    FeedService.getSelectedFeed(payload).then(response => {
      const { announcement } = response.data
      this.props.saveAnnouncement(announcement)
      this.props.history.push(`/announcement/${slug}`)
    })
  )

  }

  handleFeedOnClick = (slug) => {
    this.getSelectedFeed(slug)
  }

  handlePageChange = data => {
    this.setState({ page: data.selected + 1 }, () => this.getNews())
  }

  componentDidMount() {
    this.getNews()
    const { slug, id } = this.props.match.params
    if (slug && id ){
      this.getSelectedFeed(`${slug}/${id}`)
    }
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Component
        onShow={this.handleOnShow}
        onPageChange={this.handlePageChange}
        currentPage={this.state.page}
        onHandleFeedOnClick={this.handleFeedOnClick}
        {...rest}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    news: state.newsReducer.news,
    pagination: state.newsReducer.pagination,
    selectedAnnouncement: state.newsReducer.selectedAnnouncement
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch),
    saveNews: (news, pagination) => dispatch(saveNews(news, pagination)),
    saveAnnouncement: (announcement) => dispatch(saveAnnouncement(announcement))
  }
}

const Container = connect(mapStateToProps,mapDispatchToProps)(FeedContainer)

export function withFeedContainer(component) {
  return props => <Container component={component} {...props} />
}

export default Container
