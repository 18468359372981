import React from "react"
import { Link } from "react-router-dom"
import StyledImage from "../../../shared/StyledImage"
import { substring } from "../../normalizer"


const AcceleratorPost = ({content, title, imageSize, limitText}) => {

  let size = 'tablet_image'
  let normlimitText = limitText
  if  ('tablet_image' === imageSize){
    normlimitText = 60
  } else if (!['extra_desktop_image','desktop_image'].includes(imageSize)){
    size = 'mobile_image'
  }

  const image = content.image[size] ? content.image[size].url : ''

  let descr = content.subtitle ? content.subtitle : "";
  descr = substring(descr, normlimitText, "...")

  return (

    <Link
      className="text-color gl-home__promotion"
      to={"/accelerators"}
    >
      <StyledImage url={image} alt="" className="gl-promotion__image" />
      <div className="py-5 px-8 gl-accelerator">
        <label className="gl-post__label primary-text">{title ? title : "Acelerador"}</label>
        <h3 className='gl-accelerator__title gl-post__title'>{content.name}</h3>
        <div className="gl-accelerator__description">
          <p className='gl-content-text custom-inner-html inline-block' dangerouslySetInnerHTML={{ __html: descr }}></p>
        </div>
      </div>
    </Link>


  );

};

export default AcceleratorPost;
