import React, { Component } from "react"
import { connect } from "react-redux"
import { SaleAttributionService, GroupPositionUserService, buildTransaction, savePendingAttributions, updateSaleAttribution} from "../../normalizer"
import { HOC } from "../../gamification"
import { ToastContainer, ToastStore } from "react-toasts"

class PendingAttributionsContainer extends Component {
  state = {
    page: 1,
    isAttributionSelected: false,
    saleAttribution: undefined,
    users: [],
    selectedUser: undefined
  }

  handlePageChange = data => {
    this.setState({ page: data.selected + 1 }, () =>
      this.getPendingSaleAttributions()
    )
  }

  getPendingSaleAttributions() {
    const { user, campaign } = this.props

    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(() =>
      SaleAttributionService.getPendingSaleAttributions(payload).then(response => {
        const pendingAttributions = response.data.sales_pending
        const pagination = response.data.pagination
        this.props.savePendingAttributions(pendingAttributions, pagination)
        this.fetchGroupUsers(payload)
      })
    )
  }

  preparePayload(id, userId) {
    const { user, campaign } = this.props
    return { clientSlug: user.client.slug, campaignSlug: campaign.slug, id: id, userId: userId }
  }

  handleUpdateSaleAttribution = (saleId, userId) => {
    const payload = this.preparePayload(saleId,userId)
    const saleAttribution = this.state.saleAttribution
    this.props.startTransaction(() =>
      SaleAttributionService.updateSaleAttribution(payload)
        .then(response => {
          if (response.data.success) {
            ToastStore.success(response.data.message)
            const respSaleAttribution = response.data.sale_attribution
            saleAttribution.processing = true
            saleAttribution.state = respSaleAttribution.state
            saleAttribution.attributed_by_id = respSaleAttribution.attributed_by_id
            saleAttribution.attributed_at = respSaleAttribution.attributed_at
            this.props.updateSaleAttribution(saleAttribution)
          } else {
            ToastStore.error(response.data.error)
          }
        })
        .catch(error => {
          ToastStore.error(error.response.data.error)
        })
    )
    this.unToggleAcceptModal()
  }

  fetchGroupUsers(payload) {
    try {
      GroupPositionUserService.getGroupUsers(payload).then(response => {
        this.setState({ users: response.data.users })
      })
    } catch (error) {
      if (error !== undefined) {
        ToastStore.error(error.response.data.error)
      }
    }
  }

  componentDidMount() {
    this.getPendingSaleAttributions()
  }

  toggleAcceptModal = (saleAttribution, selectedUser) => {
    if(saleAttribution.processing) {
      ToastStore.error("O registo já está em processamento")
      return
    }

    if(selectedUser === undefined) {
      ToastStore.error("Escolha um utilizador")
      return
    }

    this.setState({
      isAttributionSelected: true,
      saleAttribution,
      selectedUser
    })
  }

  unToggleAcceptModal = () => {
    this.setState({
      isAttributionSelected: false,
      saleAttribution: undefined
    })
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <>
        <ToastContainer store={ToastStore} />
        <Component
          {...rest}
          {...this.state}
          onUpdateSaleAttribution={this.handleUpdateSaleAttribution}
          onCancelSaleAttribution={this.unToggleAcceptModal}
          onToggleAcceptModal={this.toggleAcceptModal}
          onPageChange={this.handlePageChange}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    users: state.users,
    campaign: state.campaignReducer.campaign,
    pendingSaleAttributions: state.saleAttributionReducer.pendingSaleAttributions,
    pendingPagination: state.saleAttributionReducer.pendingPagination
  }
}

function mapDispatchToProps(dispatch) {
  return {
    savePendingAttributions: (pendingAttributions, pagination) => {
      dispatch(savePendingAttributions(pendingAttributions, pagination))
    },
    updateSaleAttribution: saleAttribution => {
      dispatch(updateSaleAttribution(saleAttribution))
    },
    startTransaction: buildTransaction(dispatch)
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingAttributionsContainer)

export function withPendingAttributionsContainer(component) {
  const withPendingAttributionsContainer = HOC(Container, component)
  return withPendingAttributionsContainer
}
export default Container
