import React, { Component } from 'react';
import UserInfoForm from './UserInfoForm'
import UserInfoShow from './UserInfoShow'
import {ToastContainer, ToastStore} from 'react-toasts';
class UserInfo extends Component{

  constructor(props){
    super(props);
    this.state={editable: this.props.editable};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    
    this.setState(state => ({
      editable: !state.editable
      
    }));
    
  }

  render(){
    const {campaign, user} = this.props
    return(
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_LEFT} lightBackground/>
        {this.state.editable ? 
        <UserInfoForm  onClick = {(e) => this.handleClick()} campaign= {campaign} user= {user} /> 
        : <UserInfoShow onClick = {(e) => this.handleClick()} campaign = {campaign} user= {user}/>} 
    </div>
    )
  }

  static defaultProps = {
    editable: false
  }
}
export default UserInfo;