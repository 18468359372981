import axios from './interceptor';

export const CountryStateService = {

  getCountries() {
    const url = 'api/countries';
    return axios({
      method: 'get',
      url: url
    });
  },
  getStates(payload){
    const url = `api/countries/${payload.country_id}/states`;
    return axios({
      method: 'get',
      url: url,
    })
  }
};