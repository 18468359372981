import React from "react"
import EmptyStateLayout from "../../shared/EmptyStateLayout"
import BalanceTable from "./BalanceTable"
import { NoPoints, withBalanceContainer } from "../../normalizer"
import { NavLink } from "react-router-dom";

const Balance = props => {
  const { client, campaign, points, onClickUserProduction, ...rest } = props

  const message = "Ainda não foram registados pontos."

  return points.length > 0 ? (
    <>
      <div className='row justify-between items-center'>
        <span></span>
        { !campaign.hide_balance_history ? (
            <NavLink to={"/profile/balance/history"} className = 'text-lg my-5 primary-text' exact>
              Ver histórico-tabela <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </NavLink> 
          ) : (<span></span>)
        }
      </div>
      <BalanceTable points={points} {...rest} />
      { client.slug == 'vodafone' ?
        ( <div className='row justify-between items-center'>
            <span className='col-xs-12 col-sm-12'>Para uma visualização mais detalhada clique 
              <a onClick={onClickUserProduction} href='#' className='g-footer--contacts-link'> aqui.</a>
            </span>
          </div> ) : ( <></> )
      }
    </>
  ) : (
    <EmptyStateLayout message="Fazer vendas, completar quizes e atingir resultados poderão ajudá-lo.">
      <div className="flex dead-grey flex-col justify-center items-center mt-5 mb-10">
        <NoPoints className="mr-3 dead-grey gl-no-store__image mb-5" />
        <h2>{message}</h2>
      </div>
    </EmptyStateLayout>
  )
}

export default withBalanceContainer(Balance)
