import React from "react"
import CustomButton from "../../shared/CustomButton"
import { FilePicker } from "react-file-picker"
import UserInfo from "./UserInfo"
import UserInfoEdit from "./UserInfoEdit"
import UserAddressContainer from "../../shared/Address/AddressContainer"
import NewAddressForm from "../../shared/Address/NewAddressForm"
import UserPasswordEdit from "./UserPasswordEdit"
import UserConsents from "./UserConsents"
import {
  withContainers,
  CampaignUserContainer,
  ConsentsContainer,
  AddressContainer,
  Avatar
} from "../../normalizer"

const UserDetails = props => {
  const {
    user,
    campaignUser,
    addresses,
    editingUserGeneralData,
    onEditGeneralData,
    isAdding,
    countries,
    states,
    selectedCountry,
    selectedState,
    onEditCancel,
    onEditSubmit,
    onNewAddressEnable,
    editPassword,
    onEditPassword,
    onEditPasswordCancel,
    onEditPasswordSubmit,
    onChangeGender,
    onAvatarChange,
    errors,
    consents,
    editConsents,
    onHandleEditConsents,
    onHandleCancelEditConsents,
    onUpdateConsent,
    updatedConsents,
    confirmEditConsents
  } = props

  const { avatar: campaignAvatar = {} } = campaignUser

  let avatar = undefined
  if (campaignAvatar && campaignAvatar.url) {
    avatar = campaignAvatar.url
  }

  const showUserInfo = !editingUserGeneralData && !editPassword

  return (
    <>
      <div className="mb-10 mt-5 flex flex-col navbar-text">
        <div className="flex gl-profile__column">
          <div className="gl-profile__image-container mb-10">
            <img
              src={avatar || Avatar}
              alt="avatar"
              className="gl-profile__image"
            />
            <FilePicker
              extensions={["jpg", "jpeg", "png"]}
              onChange={onAvatarChange}
              onError={() => onAvatarChange(null)}
            >
              <CustomButton>Alterar imagem</CustomButton>
            </FilePicker>
          </div>
          {showUserInfo && <UserInfo {...user} />}
          {editingUserGeneralData && (
            <UserInfoEdit
              onChangeGender={onChangeGender}
              onEditSubmit={onEditSubmit}
              {...user}
            />
          )}
          {editPassword && (
            <UserPasswordEdit onEditPasswordSubmit={onEditPasswordSubmit} />
          )}
        </div>
        {editingUserGeneralData && (
          <div className="flex justify-end  gl-profile__between">
            <CustomButton className="mr-3" onClick={onEditCancel}>
              Cancelar
            </CustomButton>
            <CustomButton primary type={"submit"} form={"user-update-form"}>
              Guardar
            </CustomButton>
          </div>
        )}
        {editPassword && (
          <div className="flex justify-end  gl-profile__between">
            <CustomButton className="mr-3" onClick={onEditPasswordCancel}>
              Cancelar
            </CustomButton>
            <CustomButton primary type={"submit"} form={"user-update-form"}>
              Guardar
            </CustomButton>
          </div>
        )}
        {showUserInfo && (
          <div className="flex justify-end gl-profile__between">
            <CustomButton className="mr-5" onClick={onEditPassword}>
              Alterar password
            </CustomButton>
            <CustomButton outline right onClick={onEditGeneralData}>
              Editar
            </CustomButton>
          </div>
        )}
      </div>

      { consents.length  > 0  &&   
        <UserConsents
        editConsents={editConsents} 
        consents={consents} 
        onHandleEditConsents={onHandleEditConsents}
        onHandleCancelEditConsents={onHandleCancelEditConsents}
        onUpdateConsent={onUpdateConsent}
        updatedConsents={updatedConsents}
        confirmEditConsents={confirmEditConsents}
        />
      }

      {addresses.map(address => (
        <UserAddressContainer key={address.id} address={address} />
      ))}

      {isAdding ? (
        <div>
          <h3 className="gl-profile__title">Adicionar morada</h3>
          <NewAddressForm
            errors={errors}
            state={selectedState}
            country={selectedCountry}
            availableStates={states}
            availableCountries={countries}
            onAddressCreationCancel={props.onNewAddressCancel}
            onNewAddress={props.onNewAddressCreation}
            onCountryChange={props.onCountryChange}
            onStateChange={props.onStateChange}
          />
        </div>
      ) : (
        <div className="flex gl-profile__center">
          <CustomButton primary onClick={onNewAddressEnable}>
            Adicionar morada
          </CustomButton>
           
        </div>
      )}
    </>
  )
}

export default withContainers(UserDetails, [
  CampaignUserContainer,
  AddressContainer,
  ConsentsContainer
])
