import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { saveUser } from '../../../actions/userActions';
import { startFetch, endFetch } from '../../../actions/generalActions';
import { UserService } from '../../../services';
import {ToastContainer, ToastStore} from 'react-toasts';
import incentivehouse from '../../../assets/img/incentivelogo.png';

class FirstLogin extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  submit(e){
    e.preventDefault();
    this.props.startFetch();
    const payload = { client_slug: this.props.client.slug, user: { email: e.target.email.value, login: e.target.login.value }};
    UserService.checkFirstLogin(payload)
      .then((response) => {
        this.props.saveUser(response.data.user) 
        localStorage.setItem('client',this.props.client.slug)
        this.setState({redirect: true})
        this.props.endFetch();

      })
      .catch((error) => {
        this.props.endFetch();
        ToastStore.error("User not found!");   
      })
  }

  render() {
    const { redirect } = this.state;
    
    if (redirect) {
      return <Redirect to={`/set_password`}/>;
    }

    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
        <div className="row center-lg  font-quicksand center-xs center-md h-screen">
          <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
            <div className='py-10'><img style={{width:'260px'}} src={this.props.client.logo.url ? this.props.client.logo.url : incentivehouse } /></div>
            <h1 className='py-3 template-title'>Validação</h1>
            <form className="pb-10" onSubmit={(e)=> this.submit(e) }>
              <div className="row center-lg center-xs center-md py-3">
                <input className="template-blue" placeholder="Email" required type="email" name="email"></input>
              </div>
              <div className="row center-lg center-xs center-md py-3">
                <input className="template-blue" placeholder="Login" required type="text" name="login"></input>
              </div>
              <div className='row center-lg center-xs center-md py-5'>
                <button style={{width:'290px'}}className="button-cart button-primary text-xl" type="submit" value="Submit">Validar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(null, mapDispatchToProps)(FirstLogin);