import React, { useState, useEffect } from 'react';
import Moment from 'moment'
import RankingTableLines from './RankingTableLines';
import RankingRow from './RankingRow';
import { t } from '../../../i18n';


const pointsDifference = (props) => {
  const winners = props.winners
  const userPosition = props.userPosition

  if (userPosition === undefined && winners.length !== 0){
    return winners[winners.length-1].total_points
  }
  if (winners.length !== 0 ){
    return  winners[winners.length-1].total_points - userPosition.total_points
  }
  return 0
}

const neighbourIsNextToWinners =  (winners, userNeighbours) => {
  if (winners === undefined || userNeighbours === undefined ){
    return false 
  }
  const neighboursPositions = userNeighbours.map((element) => { return element.position; })
  const firstNPosition = Math.min(...neighboursPositions)
  const winnersPositions = winners.map((element) => { return element.position; })
  const lastWPosition = Math.max(...winnersPositions)
  return (lastWPosition + 1 >= firstNPosition)
}

const NeighboursWithoutWinners = (neighbours,winners) => {
  return neighbours.filter(element => 
    !winners.find(winner => winner.id === element.id)
  )
}



const parseTotalPoints = (totalPoints) => {
  const parsedTotalPoints = parseFloat(totalPoints)
  const normalizedTotalPoints = parsedTotalPoints % 1 === 0 ? parsedTotalPoints.toFixed(0) : parsedTotalPoints
  return normalizedTotalPoints
}
  
const userAsWinner = (user, campaignUser, userPosition)=> {
  const normalizedFirstName = user.first_name || 'Sem'
  const normalizedLastName = user.last_name || 'Nome'
  return {
    avatar: campaignUser.avatar || user.avatar,
    full_name: userPosition ? userPosition.full_name : normalizedFirstName + ' ' +  normalizedLastName,
    position: userPosition ? userPosition.position : 'N/A',
    total_points: userPosition ? parseTotalPoints(userPosition.total_points) : 0
  }
}

const RankingSeparator = ({ slug }) => {
  const separatorColor = slug === 'vodafone' ? 'primary-text' : '';
  const tableClass = [
    'gl-rankings__data my-7', 
    'text-color',
  ].join(' ')
  return(
    <tr className={tableClass}>
      <td colSpan={4} className={'p-3'}>
        <hr className={`gl-rankings__data-separator ${separatorColor}`} />
      </td>
    </tr>
  )
}


const RankingTable = props => {
  
  const {
    slug,
    user,
    winners,
    userPosition,
    userNeighbours,
    campaignUser,
    endDate,
    isWinner,
    rankingLabel,
    winningMessage,
  } = props

  const userFakeWinner = userAsWinner(user, campaignUser, userPosition)
  const endDateNormalized = new Moment(endDate)
  console.log('Slug:', slug);
  function PositionCheck(){
    if(typeof winners[0] !== 'undefined')
    {
      const win = winners[0];
      if(typeof win.position !== 'undefined')
      {
        return <td className='gl-rankings__table-header text-left py-3 px-2 sm:px-3'>Posição</td>;
      }
    }
  }

  function Full_NameCheck(){
    if(typeof winners[0] !== 'undefined')
    {
      const win = winners[0];
      if(typeof win.full_name !== 'undefined')
      {
        return <td className='gl-rankings__table-header w-2/5 text-left py-3 px-2  sm:px-3'>Jogador</td>;
      }
    }
  }

  function GroupsCheck(){
    if(typeof winners[0] !== 'undefined')
    {
      const win = winners[0];
      if(typeof win.groups !== 'undefined') {
        return <td className='gl-rankings__table-header w-2/5 text-left py-3 px-2  sm:px-3'>Loja</td>;
      } else {
        return <td className='gl-rankings__table-header w-2/5 text-left py-3 px-2  sm:px-3'></td>;
      }
    }
  }

  function Total_PointsCheck(){
    if(typeof winners[0] !== 'undefined')
    {
      const win = winners[0];
      if(typeof win.total_points !== 'undefined')
      {
        return <td className='gl-rankings__table-header text-right py-3 px-2 whitespace-no-wrap sm:px-3'>{rankingLabel.currencyLabel}</td>;
      }
    } 
  }
  
  return(
    
    <>
      <div className='gl-rankings__table-container overflow-x-auto'>
        <table className='gl-rankings__table w-full px-2 text-size vodafone'>
          <thead className='my-10 text-left'>
            <tr className='gl-rankings__table-header py-5 my-5'>
              {PositionCheck()}
              {Full_NameCheck()}
              {GroupsCheck()}
              {Total_PointsCheck()}
            </tr>
          </thead>
          <tbody>
            <RankingTableLines 
              results = {winners}
              userPosition={userPosition}
              areWinners={true}
            />

            { !neighbourIsNextToWinners(winners, userNeighbours) && 
              <RankingSeparator slug={slug} />
            }
            <RankingTableLines 
              results = {NeighboursWithoutWinners(userNeighbours, winners)}
              userPosition={userPosition}
            />
          
            { !isWinner && userNeighbours.length === 0 &&
              <RankingRow 
                isCurrentUser
                result={userFakeWinner}
              />
            }
          </tbody>
        </table>  
      </div>
      <div className='gl-rankings__goal-container mt-4 p-3 navbar-text primary-bg'>
        { isWinner ?
          <p>{ winningMessage }</p>
          :
          <p>{`Faltam-te ${pointsDifference(props)} ${rankingLabel.winnerDescription} para chegar ao TOP`}</p>
        }
        {
          endDateNormalized._isValid && 
          <p className='mt-5'>{`Termina a ${endDateNormalized.format(t('date-format'))}`}</p>
        }
      </div>
    </>
  )
}


export default RankingTable