import React, { Component } from 'react';
import Avatar from '../../../assets/img/user-placeholder.svg'

const parseTotalPoints = (totalPoints) => {
  const parsedTotalPoints = parseFloat(totalPoints)
  const normalizedTotalPoints = parsedTotalPoints.toFixed(2)
  return normalizedTotalPoints
}

class RankingRow extends Component {

  state = {
    mobileFriendly: false,
    normalizedIdentifier: this.props.result.full_name
  }

  resize = () => {
    const mobileFriendly = window.innerWidth < 700
    let fullName = this.props.result.full_name
    if (mobileFriendly){
      const splittedFullName = fullName.split(' ') 
      fullName = splittedFullName[0] + ' ' + splittedFullName[splittedFullName.length - 1]
    }
    this.setState({normalizedIdentifier: fullName})
  }

  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }


  
  
  render (){
    const { result, isCurrentUser, isWinner } = this.props
   
  const normalizeAvatar = result.avatar.url || Avatar
  const normalizedIdentifier = this.state.normalizedIdentifier
  const normalizedPosition = result.position
  const normalizedGroups = result.groups
  const normalizedTotalPoints = parseTotalPoints(result.total_points)
  const tableClass= [
    'gl-rankings__data text-sm', 
    isCurrentUser ? 'secondary-text' : 'text-color',
    isCurrentUser && 'table-body-color-bg text-color'  
  ].join(' ')

  function PositionCheck(){
    if ((typeof normalizedPosition !== 'undefined') && (normalizedPosition !== null)) {
      return <td className='text-left p-2 sm:p-3  '>{result.position}</td>
    } else {
      return <td className='text-left p-2 sm:p-3  '></td>
    }
  }

  function Full_NameCheck(){
    if ((typeof normalizedIdentifier !== 'undefined') && (normalizedIdentifier !== null)) {
      return <td className='p-2 sm:p-3 text-left content-center whitespace-no-wrap' >
        <img src={normalizeAvatar} alt="avatar" className="mr-2 gl-rankings__avatar align-middle"/> 
        <span>
          {normalizedIdentifier}
          {isWinner && <i className="px-2 mr-3 fas fa-award"></i> }
        </span>
      </td>
    } else {
      return <td className='p-2 sm:p-3 text-left content-center whitespace-no-wrap' ></td>
    }
  }

  function GroupsCheck(){
    if ((typeof normalizedGroups !== 'undefined') && (normalizedGroups !== null)) {
      return <td className='align-baseline p-2 sm:p-3 text-left content-center '> {normalizedGroups} </td>
    } else {
      return <td></td>
    }
  }

  function Total_PointsCheck(){
    if ((typeof normalizedTotalPoints !== 'undefined') && (normalizedTotalPoints !== null)) {
      return <td className='p-2 sm:p-3 text-right'>{normalizedTotalPoints}</td>
    } else {
      return <td></td>
    }
  }

  return(
    
    <tr key={result.id} className={tableClass}>
      {PositionCheck()}
      {Full_NameCheck()}
      {GroupsCheck()}
      {Total_PointsCheck()}
    </tr>
  )}
}

export default RankingRow