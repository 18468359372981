export function saveProducts(state){
  return {
    type: 'SAVE_PRODUCTS',
    payload: state
  }
};
export function saveWishes(state){
  return {
    type: 'SAVE_WISHES',
    payload: state
  }
};
export function saveHighlights(state){
  return {
    type: 'SAVE_HIGHLIGHTS',
    payload: state
  }
};
export function saveRecent(state){
  return {
    type: 'SAVE_RECENT',
    payload: state
  }
};
export function saveMostViewed(state){
  return {
    type: 'SAVE_MOST_VIEWED',
    payload: state
  }
};
export function updateProducts(state){
  return { 
    type: 'UPDATE_PRODUCTS',
    payload: state
  } 
};
export function updateLastSeenProducts(state){
  return {
    type: 'UPDATE_LAST_SEEN_PRODUCTS',
    payload: state
  }
}

export function saveProductsBrands(state){
  return {
    type: 'SAVE_BRANDS',
    payload: state
  }
}
export function saveLevelProducts(state){
  return {
    type: 'SAVE_LEVEL_PRODUCTS',
    payload: state
  }
}