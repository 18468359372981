import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { AddressesService } from '../../../services'
import Address  from './Address';
import AddressForm from './AddressForm'
import UserInfo from './UserInfo';
import UserPass from './UserPass';
import UserOrders from './UserOrders';
import UserOrderShow from './UserOrderShow';
import ReactPaginate from 'react-paginate'
import { saveAddresses } from '../../../actions/addressesActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import { prefix } from '../../../i18n'
import ProfileLayout from './ProfileLayout';
import UserBalance from './UserBalance';
import UserBalanceExport from './UserBalanceExport';
import UserExpired from './UserExpired';
import Card from './Card';

class Profile extends Component{
  constructor(props){
    super(props);
    this.state = { 
      creatable: this.props.creatable,
      orderPage: 1,
      balancePage: 1,
      pointsValidityPage: 1
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    this.setState(state => ({
      creatable: !state.creatable
    }));
  }

  getAddresses(){
    this.props.startFetch();
    AddressesService.getAddresses().then((response) => {
      const addresses = response.data
      this.props.saveAddresses(addresses);
      this.props.endFetch();
    });
  }


  handleOrderPageChange = data => {
    const selected = data.selected;
    this.setState(
      {
        orderPage: selected + 1
      }
    )
  }

  handleBalancePageChange = data => {
    const selected = data.selected;
    this.setState(
      {
        balancePage: selected + 1
      }
    )
  }

  handlePointsValidityPageChange = data => {
    const selected = data.selected;
    this.setState(
      {
        pointsValidityPage: selected + 1
      }
    )
  }

  componentDidUpdate(oldProps){
    if(oldProps.campaign.slug !== this.props.campaign.slug) {
      this.setState({orderPage: 1, balancePage: 1, pointsValidityPage: 1})
    }
  }

  componentDidMount(){
    if (this.props.addresses.length === 0){
      this.getAddresses()
    } 
  }

  render() {
    const {
      campaign,
      user,
      orders,
      points,
      validityPoints,
      addresses,
      campaignUser,
      orderPagination,
      balancePagination,
      validityPagination,
      toRender
    } = this.props

    const { orderPage, balancePage, pointsValidityPage} = this.state
    const buttonText = this.state.creatable ? 'Cancelar' : 'Criar'
    return (
      <ProfileLayout campaignUser={campaignUser} campaign={campaign} user={user}>
        <Switch>

          
        

          <Route exact path= {"/profile"}>
            <>
              <UserInfo campaign={campaign} user= {user} />
              <UserPass campaign={campaign} />
              <div className='mb-6 flex template-container between-xs sm:flex-turn sm:flex-turn--a-center sm:flex-turn--j-center'>
                <div>
                  <h2 style={{color: campaign.color_primary}} className='template-title uppercase'>Moradas</h2>
                  <hr style={{backgroundColor: campaign.text_color}} className="hr-down-left orange"></hr>
                </div>
                <button onClick={this.handleClick} className='btn-secondary user-button px-4 py-1'>
                  {buttonText}
                </button>
              </div>
              {this.state.creatable && <AddressForm handleClick = {this.handleClick} create={true} campaign = {campaign}/> }
              { addresses.map((address,i) => 
                <Address key = {i} address = {address} campaign = {campaign} />
              )}
            </>
          </Route>
          <Route exact path ={"/profile/orders"}>
            <UserOrders 
              orders={orders} 
              campaign={campaign} 
              user={user} 
              page={orderPage}
              pagination={orderPagination}/>
          </Route>
          <Route exact path ="/profile/orders/:number">
            <UserOrderShow
              campaign={campaign} 
              user={user}
              order={this.props.order}
            />
          </Route>
          <Route exact path="/profile/balance">
            <UserBalance 
              points={points} 
              campaign={campaign} 
              user={user} 
              page={balancePage}
              pagination={balancePagination}
              toRender={toRender}
              />  
          </Route>
          { toRender('PointValidity') &&
            <Route exact path="/profile/validity">
            <UserExpired 
              points={validityPoints} 
              campaign={campaign} 
              user={user} 
              page={pointsValidityPage}
              pagination={validityPagination}/>
            </Route>
          }
          <Route exact path={"/profile/cards"}>
            <Card></Card>
          </Route>

          <Redirect from="*" to ="/not-found"/>

        </Switch>
        <Switch>
          <Route exact path={"/profile/orders"}>
            <>
            { orderPagination.total_pages > 1 &&
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={ orderPagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                initialPage={orderPage - 1}
                onPageChange={this.handleOrderPageChange}
                containerClassName={'pagination my-5'}
                subContainerClassName={'pages'}
                activeClassName={'active-block'}
                forcePage={orderPage - 1}
                />
            }
            </>
          </Route>
          <Route exact path={"/profile/balance"}>
            <>
            { balancePagination.total_pages > 1 &&
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={ balancePagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                initialPage={balancePage - 1}
                onPageChange={this.handleBalancePageChange}
                containerClassName={'pagination my-5'}
                subContainerClassName={'pages'}
                activeClassName={'active-block'} 
                forcePage={balancePage -1}
                />
            }
            </>
          </Route>
          <Route exact path={"/profile/validity"}>
            <>
            { toRender('PointValidity') && validityPagination.total_pages > 1 &&
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={ validityPagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                initialPage={pointsValidityPage - 1}
                onPageChange={this.handlePointsValidityPageChange}
                containerClassName={'pagination my-5'}
                subContainerClassName={'pages'}
                activeClassName={'active-block'} 
                forcePage={pointsValidityPage -1}
                />
            }
            </>
          </Route>
        </Switch>
        <Switch>
          <Route exact path={"/profile/balance"}>
            <>
              <UserBalanceExport
               user={user}
               campaign={campaign}
              />
            </>
          </Route>
        </Switch>

        
      </ProfileLayout>
    )
  }

  static defaultProps = {
    creatable: false
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    addresses: state.addressesReducer.addresses,
    campaignUser: state.balanceReducer.campaignUser,
    orders: state.ordersReducer.orders,
    orderPagination: state.ordersReducer.pagination,
    points: state.balanceReducer.points,
    balancePagination: state.balanceReducer.pointsPagination,
    validityPoints: state.balanceReducer.validityPoints,
    validityPagination: state.balanceReducer.validityPagination
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveAddresses: (addresses) => {
      dispatch(saveAddresses(addresses))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));