import styled, {css} from 'styled-components'
const ClassBuilder = styled.div`
  ${props => props.campaign && css`
    .primary-text {
      color: ${props.campaign.color_primary}
    }
    .secondary-text {
      color: ${props.campaign.color_secondary}
    }

    .navbar-text {
      color: ${props.campaign.navbar_text_color}
    }

    .text-color {
      color: ${props.campaign.text_color}
    }

    .primary-bg {
      background: ${props.campaign.color_primary}
      &:disabled{
        background: #ccc;
        cursor: not-allowed;
      }
    }

    .secondary-bg {
      background: ${props.campaign.color_secondary}
    }
  
    .mish-bg {
      background: ${props.campaign.text_color}
    }

    .border-primary {
      border-color: ${props.campaign.color_primary}
    }

    .border-secondary {
      border-color: ${props.campaign.color_secondary}
    }

    .custom-select__option:hover{
      color: ${props.navbar_text_color};
      background-color: ${props.color_secondary};
    }
    .custom-select__option--is-focused{
      background-color: ${props.color_secondary};
      color: ${props.navbar_text_color};
    }

    .active-link {
      color: ${props.navbar_text_color};
      background:${props.campaign.color_primary};

      &:hover {
        background:${props.campaign.color_secondary};
      }

      &.active {
        background:linear-gradient(to top, ${props.campaign.color_secondary} 0%, ${props.campaign.color_secondary} 10%, ${props.campaign.color_primary} 10%, ${props.campaign.color_primary} 100%);
      }
      &.fromDropdown.active {
        background:${props.campaign.color_secondary};
      }
    }

    .hover-secondary {
      &:hover {
        background:${props.campaign.color_secondary};
      }
    }
  
  `}
`

export default ClassBuilder