import React from "react"
import { Link } from "react-router-dom"
import ProductLoader from "./ProductLoader"
import {
  formatNumber,
  substring,
  withSimpleCampaignUserContainer
} from "../../normalizer"

const PostProduct = ({ product, ...campaignProduct }) => {
  const { points, percentage, points_left } = campaignProduct
  const limitedName = substring(product.name, 35, "...")
  const currencyLabel = localStorage.getItem("currency") || "pts"
  return (
    <div className="g-product__container g-product__container--post bg-default border-secondary">
      <p className="g-product__title g-product__title--post navbar-text">
        {limitedName}
      </p>
      <Link to={`/catalog/product/${campaignProduct.id}`}>
        <img
          src={product.main_image.url}
          alt={product.name}
          className="g-product__image g-product__image--post"
        />
      </Link>
      <div className="g-product__action-container g-product__action-container--post text-color secondary-bg">
        <div className="g-product__points-container">
          <span className="g-product__points-value g-product__points-value--post">
            {formatNumber(points)}
          </span>
          <span className="g-product__points-label g-product__points-label--post">
            {currencyLabel}
          </span>
          { percentage !== undefined &&
            <div className="g-product__loader-container">
              <ProductLoader progress={percentage} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PostProduct
