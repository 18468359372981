import React, { Component } from "react"
import incentivehouse from "../../assets/img/incentivelogo.png"

export default class PrivacyPolicy extends Component {
  state = {}

  render() {
    return (
      <div
        className="image-background"
        style={
          this.state.background_banner
            ? {
                backgroundImage: "url(" + this.state.background_banner.url + ")"
              }
            : {}
        }
      >
        <div className="font-quicksand-regular default-container-privacy-policy">
          <img
            style={{
              margin: "10px auto 20px auto",
              width: "260px",
              display: "block"
            }}
            src={
              this.state.client ? this.state.client.logo.url : incentivehouse
            }
            alt="logo"
          />
          <h1 className="text-center">POLÍTICA DE PRIVACIDADE</h1>

          <br />

          <h2>Preâmbulo</h2>
          <p>
            A IncentiveHouse solicita a V. Exas. a leitura atenta da Política de
            Privacidade, a fim de dar conhecimento e advertir os Utilizadores do
            tratamento dos seus dados pessoais, bem como das modificações
            resultantes das alterações legislativas. Neste sentido, publicitamos
            a realidade actual das informações e dados fornecidos no cumprimento
            dos deveres do novo Regulamento Geral da Proteção de Dados (RGPD).
            Caso a presente Política suscite qualquer dúvida pode contactar-nos
            através do seguinte endereço eletrónico: geral@incentivehouse.com.pt
          </p>

          <br />

          <h2>1. Âmbito da Política de Privacidade</h2>
          <p>
            A Política de Privacidade elaborada e ora comunicada, abrange o
            pedido de dados pessoais para registo e, consequentemente, o
            tratamento dos mesmos no âmbito da prestação de serviços da
            IncentiveHouse, abrangendo a aplicação da IncentiveHouse. A
            IncentiveHouse compromete-se a respeitar a privacidade dos
            Utilizadores, a cumprir a Política de Privacidade e bem assim os
            seus princípios, a confidencialidade e segurança de dados. Os
            Utilizadores prestam as informações e dados solicitados de acordo
            com a legislação nacional ou europeia em vigor e estão a autorizar,
            livre e esclarecidamente, o seu tratamento. Pretendemos informar,
            ainda, que o Utilizador da plataforma da IncentiveHouse pode exercer
            os seus direitos de acesso, rectificação, esquecimento, limitação, à
            portabilidade e oposição ao tratamento de dados pessoais. Para
            exercer os seus direitos deve fazê-lo por escrito, através de carta
            registada enviada para a sede da IncentiveHouse, sita Rua Gonçalves
            Zarco, nº 6-D, 1400-191, Lisboa ou para seguinte endereço
            electrónico geral@incentivehouse.com.pt De acordo com as disposições
            legais, a IncentiveHouse pode facultar toda a informação e dados
            pessoais dos Utilizadores, quando lhe sejam pedidos pelas
            autoridades competentes, nos termos da legislação aplicável, bem
            como quando esteja em causa um fim de interesse público.
          </p>

          <br />

          <h2>1. Finalidade dos dados</h2>
          <p>
            Os dados pessoais recolhidos dos Utilizadores, caso consinta
            especifica e expressamente, poderão ser tratados para alguma ou
            todas as seguintes finalidades:
          </p>
          <ul>
            <li>
              Registo da ficha do Utilizador, no âmbito dos serviços prestados
              pela IncentiveHouse e dos interesses do Cliente Vodafone;
            </li>
            <li>
              Permitir o contacto da IncentiveHouse com o Utilizador, caso seja
              estritamente necessário ao exercício da actividade e dos serviços
              prestados e bem assim para assegurar os interesses do Cliente
              Vodafone;
            </li>
            <li>
              Envio de comunicações informativas de serviços e de marketing
              relacionados com actividade da IncentiveHouse personalizadas de
              acordo com o perfil do Utilizador, tendo em conta idade, sexo,
              zona de residência, preferências pessoais indicadas;
            </li>
            <li>
              Envio de inquéritos de qualidade e satisfação dos serviços
              prestados.
            </li>
          </ul>
          <p>
            A IncentiveHouse não utilizará os seus dados pessoais para outros
            fins, cujo conteúdo da sua recolha não tenha sido consentido. O
            consentimento pode ser retirado por parte do Utilizador em qualquer
            altura, sem comprometer a licitude do tratamento efectuado com base
            no consentimento previamente dado.
          </p>

          <br />

          <h2>Tratamento dos dados pessoais e Responsáveis pelo tratamento</h2>
          <p>
            A IncentiveHouse, responsável pelo tratamento de dados, designou
            como seu representante para o tratamento de dados pessoais Miguel
            Mena.
          </p>
          <p>
            Os registos dos dados pessoais dos Utilizadores mantêm-se na base de
            dados interna, à qual só os seus colaboradores têm acesso,
            encontrando-se se os mesmos vinculados às sob as regras de sigilo e
            confidencialidade. Este registo é relevante para o tratamento dos
            dados pessoais, tendo em conta as finalidades acima enunciadas,
            garantindo a privacidade no processamento e tratamento dos seus
            dados de carácter pessoal. No âmbito do exercício da sua actividade
            a IncentiveHouse poderá tratar os dados pessoais conjuntamente com
            outras entidades, inclusive subcontratadas, que ficarão
            corresponsáveis pelo respetivo tratamento, garantindo a privacidade
            e protecção dos mesmos, por força da inclusão respectiva nos seus
            contratos a celebrar.
          </p>
          <p>
            Estas entidades tratam a informação pessoal na estrita medida do
            necessário, à prestação dos serviços em nome da IncentiveHouse. Os
            dados pessoais do Utilizador recolhidos no âmbito do exercício da
            sua actividade, serão tratados pela IncentiveHouse para efectuar a
            prestação de serviços acordada, incluindo a realização de auditorias
            de qualidade e de melhoria do serviço prestado.
          </p>

          <br />

          <h2>1. Manutenção dos dados pessoais e segurança</h2>
          <p>
            A IncentiveHouse irá manter e processar os seus dados pessoais, para
            atender e diligenciar pela integral execução das tarefas que lhe
            estão adstritas no âmbito do serviço prestado, como contributo para
            melhorar o modo de prestação de serviços e dos seus produtos. A
            IncentiveHouse diligencia as medidas adequadas para manter a
            confidencialidade, evitar a perda ou a destruição dos dados
            pessoais, a má utilização e o acesso sem a devida autorização, tendo
            instituído as medidas de controlo e fiscalização necessárias para o
            efeito.
          </p>
          <p>
            Os dados pessoais do Cliente serão conservados pelos prazos de
            vigência do contrato, acrescido dos prazos de prescrição e
            caducidade dos direitos associados, sem prejuízo de poder mantê-los
            para além desse período para cumprimento de obrigações legais. O
            Utilizador poderá pedir, a todo o tempo, o acesso aos seus dados
            pessoais, a sua rectificação ou eliminação e bem assim quaisquer
            esclarecimentos relacionados com o tratamento dos seus dados
            pessoais, mediante pedido escrito enviado para o endereço
            electrónico devidamente identificado no Ponto 1 – mbito da nossa
            Política de Privacidade, da presente Política. Caso o Utilizador
            venha a entender que a IncentiveHouse violou os direitos que lhe
            assistem nos termos da legislação de protecção de dados pessoais,
            poderá apresentar uma reclamação junto da Comissão Nacional de
            Protecção de Dados. O Utilizador será o responsável pela
            actualização dos seus dados pessoais e pelo pedido de rectificação
            dos mesmos junto da IncentiveHouse.
          </p>

          <br />

          <h2>1. Menores</h2>
          <p>
            Nos casos em que o Utilizador seja menor de 16 anos ou incapaz,
            caberá ao titular da responsabilidade parental ou da tutoria,
            prestar o consentimento para o tratamento dos dados, juntando para o
            efeito prova da sua legitimidade.
          </p>

          <br />

          <h2>Cookies na App</h2>
          <p>
            A IncentiveHouse tem ao seu dispor um Website, que se pode consultar
            através do seguinte link www.incentivehouse.com.pt. Contudo, a
            IncentiveHouse não assume a responsabilidade, por facto que não lhe
            seja imputável, caso a visita ao referido site implique algum dano,
            designadamente pela remissão para outros links ou páginas web que
            não tenham sido programados pela IncentiveHouse. A página web
            supramencionada utiliza cookies, através dos quais é possível
            monitorizar as visitas ao website e melhorar a sua utilização,
            permitindo a adequação dos anúncios às preferências dos
            Utilizadores. No entanto, existe a possibilidade de bloquear os
            cookies.
          </p>
        </div>
      </div>
    )
  }
}
