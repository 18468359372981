import React, { Component } from 'react';
import Moment from 'moment';
import {t} from '../../i18n'
import {Link} from 'react-router-dom'
import CustomButton from '../GamificationTemplate/shared/CustomButton';
import StyledImage from './StyledImage'

class NewsPreview extends Component{
  
  renderDefault(campaign, annoucement, imageSize) {
    return (
      <div>
        <div className='row rounded content-container my-4' >
          <StyledImage url={annoucement.prev_image[imageSize] ? annoucement.prev_image[imageSize].url : ''}
            className=' col-lg-4 col-md-4 col-sm-12 col-xs-12 p-0 rounded-t md:rounded-t lg:rounded-none lg:rounded-l xl:rounded-l sm:rounded-t content-images'>
          </StyledImage>
          <div className='col-lg-8 col-md-8 col-sm-12  col-xs-12 px-8 py-3'>
            <div className="flex between-lg between-md between-xs py-5 sm:flex-turn sm:flex-turn--reverse">
              <div>
                <h2 style={{ color: campaign.color_primary }} className="primary">{annoucement.title}</h2>
                <hr style={{ backgroundColor: campaign.text_color }} className="hr-down-left"></hr>
              </div>
              <span className='news-date'>{Moment(annoucement.created_at).format(t('date-format'))}</span>
            </div>
            <div className='flex between-xs sm:flex-turn '>
              <p className='content-text p-3'>
                {annoucement.subtitle}
              </p>
              <Link
                to={`/news/${annoucement.id}`}
                className='btn-secondary user-button text-center fit-content self-center'>
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderGamification(body, annoucement, onShow) {
    return (
      <div>
        <div className='row rounded content-container my-4' >
          <div style={{ background: 'url(' + annoucement.main_image.url + ') no-repeat center center', backgroundSize: 'cover' }}
            className=' col-lg-4 col-md-4 col-sm-12 col-xs-12 p-0 rounded-t md:rounded-t lg:rounded-none lg:rounded-l xl:rounded-l sm:rounded-t content-images'>
          </div>
          <div className='flex flex-col justify-between col-lg-8 col-md-8 col-sm-12  col-xs-12 px-8 py-5'>
            <div className="flex between-lg between-md between-xs sm:flex-turn sm:flex-turn--reverse">
              <div>
                <h2 className="primary-text vodafone-bold mb-3">{annoucement.title}</h2>
              </div>
            </div>
            <div className='content-text mb-4 roboto' dangerouslySetInnerHTML={{ __html: body }}/>
            <div className="flex between-xs sm:flex-turn items-center">
              <CustomButton
                outline
                primary
                onClick={() => onShow(annoucement.id)}>
                Ver mais
              </CustomButton>
              <span className='news-date small-title-size roboto'>{Moment(annoucement.created_at).format(t('date-format'))}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render(){
    const {
      annoucement,
      campaign,
      gamification,
      onShow,
      imageSize
    } = this.props

    const body = annoucement.news_body.length > 300 ? annoucement.news_body.substring(0,300) + '...' : annoucement.news_body

    return(
      <>
      {gamification && this.renderGamification(body, annoucement, onShow)}
      {!gamification && this.renderDefault(campaign, annoucement, imageSize)}
      </>
    )
  }
}
export default NewsPreview;
