import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { UserService } from "../../../services"
import { ToastContainer, ToastStore } from "react-toasts"
import { startFetch, endFetch } from "../../../actions/generalActions"
import BackgroundWrapper from "../client/BackgroundWrapper"
import ClientLogo from "../client/Logo"
import EditForgottenPasswordDefault from "../../DefaultTemplate/PasswordRecovery/EditForgottenPassword"
import EditForgottenPasswordGamification from "../../GamificationTemplate/PasswordRecovery/EditForgottenPassword"

const EditForgottenPasswordTemplate = props => {
  const { slug } = props.client
  switch (slug) {
    case "vodafone":
      return <EditForgottenPasswordGamification {...props} />
    default:
      return <EditForgottenPasswordDefault {...props} />
  }
}
class LogInWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logo: { url: "" },
      background_banner: { url: "" },
      name: "",
      slug: "",
      notfound: false
    }
  }

  finishPasswordRecovery = e => {
    e.preventDefault()
    const { value } = e.target.password
    const confirmation = e.target.passwordConfirmation.value
    if (confirmation !== value) {
      ToastStore.error("As palavras-passe têm de coincidir")
      return
    }

    const { client } = this.props

    const { token } = this.props.match.params

    const payload = {
      clientSlug: client.slug,
      pwToken: token,
      password: value,
      passwordConfirmation: confirmation
    }
    this.props.startFetch()

    UserService.finishPasswordRecovery(payload)
      .then(response => {
        this.props.endFetch()
        ToastStore.success("Password atualizada com sucesso")
        this.props.history.push(`/${client.slug}/login`)
      })
      .catch(error => {
        this.props.endFetch()
        ToastStore.error("Foi impossivel recuperar a palavra-passe")
      })
  }

  render() {
    const { client } = this.props
    const { token } = this.props.match.params
    if (!client.slug || !token) {
      const path = `/${client.slug}/login`
      return <Redirect to={path} />
    }
    const login = `/${client.slug}/login`

    return (
      <BackgroundWrapper backgroundImage={client.background_banner.url}>
        <EditForgottenPasswordTemplate
          client={client}
          client_logo={ClientLogo}
          login={login}
          finishPasswordRecovery={this.finishPasswordRecovery}
        />
        <ToastContainer
          store={ToastStore}
          position={ToastContainer.POSITION.TOP_CENTER}
          lightBackground
        />
      </BackgroundWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    client: state.userReducer.client
  }
}
function mapDispatchToProps(dispatch) {
  return {
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInWrapper)
