export function saveNotifications(state){
  return {
    type: 'SAVE_NOTIFICATIONS',
    payload: state
  }
};

export function saveNewerNotifications(state){
  return {
    type: 'SAVE_NEWER_NOTIFICATIONS',
    payload: state
  }
};
export function viewNotification(id){
  return {
    type: 'VIEW_NOTIFICATION',
    payload: {
      id
    }
  }
};