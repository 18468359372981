import React, { Component } from 'react';
import styled from 'styled-components'

const DropdownWrapper = styled.button`
  color: ${props=> props.textColor}
  
  &:hover {
    color: ${props => props.textColor}
  }

  &.active-link {
    color: ${props => props.secondaryTextColor}
    background: ${props=> props.secondary}
  }

  & > item {
    color: ${props=> props.textColor}
  }
`

export default function SimpleDropdown({ condition, message, campaign, children, boldIt}){
  const font = campaign.font ? `font-${campaign.font}` : ''

  if(!condition) return null
  return(
    <div className="g-navbar__dropdown-wrapper">
      <DropdownWrapper 
        textColor={campaign.navbar_text_color}
        secondaryTextColor={campaign.textColor}
        primary={campaign.header_bg_color}
        secondary={campaign.header_hover_bg_color}
        className={`g-navbar__dropdown-wrapper__campaigns campaign-link ${font} `} 
        >
        {message}
        <i className="fas fa-angle-down g-navbar__dropdown-wrapper__campaign-icon"></i>
      </DropdownWrapper>
      <div className={`g-navbar__dropdown-wrapper__campaigns-content width-available ${font}`} >
        {children}
      </div>
    </div>
  )
}
