import React from 'react';
import { formatNumber } from '../normalizer';

const CartItemsTable = props => {

  const {
    cart,
    currencyLabel,
    onIncreaseItem,
    onDecreaseItem,
    onRemoveItem,
    checkout,
  } = props

  const editCartItems = !checkout;

  return (
    <table className='gl-cart__table mt-6 primary-text '>
      <thead className='my-10 text-left'>
        <tr className='w-full text-sm md:text-lg'>
          <th className="gl-cart__number-cell-10">Nº</th>
          <th className="gl-cart__number-cell-60">Descrição do artigo</th>
          <th className='text-center gl-cart__number-cell-10'>{currencyLabel}</th>
          <th className='text-center w-1/5'>Qts</th>
          <th className='text-center gl-cart__number-cell-10'>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {cart.items.map((item, index ) => {
          return (
            <tr key={item.id} className='gl-cart__data text-xs md:text-base dead-grey my-5'>
              <td className="">{index + 1}</td>
              <td className='gl-cart__cell'>{item.product.name}</td>
              <td className="text-center">{formatNumber(item.base_points)}</td>
              <td className="text-center">
                <div>
                  {editCartItems &&
                    <button className="gl-outline-reset" onClick={() => onDecreaseItem(item)}>
                      <i className='fas fa-minus primary-text'/>
                    </button>
                  }
                  <span className="px-1">{item.quantity}</span>
                  {editCartItems &&
                    <button className="gl-outline-reset" onClick={() => onIncreaseItem(item)}>
                      <i className='fas fa-plus primary-text'/>
                    </button>
                  }
                </div>
              </td>
              <td className='cart-item__text text-center'>{formatNumber(item.points)}</td>
              {editCartItems &&
                <td className="text-right">
                  <i
                    className='fas fa-trash-alt cursor-pointer'
                    onClick={() => onRemoveItem(item)} />
                </td>
              }
            </tr>
          )
        })}
        <tr className='text-left '>
          <td className='py-5 text-size primary-text' colSpan={3}>Total da encomenda</td>
          <td/>
          <td className='text-center'>{formatNumber(cart.total_points)}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default CartItemsTable;
