import React from "react"
import { withObjectiveAnalyticsContainer, SearchIcon } from "../../normalizer"

import Pagination from "../../shared/Pagination"
import ObjectivesFilter from "./ObjectivesFilter"
import ObjectivesTable from "./ObjectivesTable"
import EmptyStateLayout from "../../shared/EmptyStateLayout"

const Objectives = props => {
  const {
    objectives,
    pagination,
    onPageChange,
    onObjectiveFilter,
    filtered,
    page
  } = props

  const noObjectives = objectives.length === 0
  const noObjectivesWhileFiltering = filtered && noObjectives
  const noObjectivesWhileNotFiltering = !filtered && noObjectives

  const EmptyStateContent = (
    <div className="w-full flex items-center flex-col">
      <div className="gl-objectives__line mt-5 mb-10 w-full" />
      <SearchIcon className="mb-10" />
    </div>
  )
  return (
    <div className="w-full text-size">
      {noObjectivesWhileNotFiltering ? (
        <EmptyStateLayout message="Ainda nao exitem objetivos registadas para esta loja.">
          {EmptyStateContent}
        </EmptyStateLayout>
      ) : (
        <ObjectivesFilter onObjectiveFilter={onObjectiveFilter} />
      )}

      {noObjectivesWhileFiltering ? (
        <EmptyStateLayout message="Não existem resultados com critérios de pesquisa introduzidos.">
          {EmptyStateContent}
          <h1 className="mb-5 normal-case">Sem resultados!</h1>
        </EmptyStateLayout>
      ) : (
        objectives.length > 0 && <ObjectivesTable objectives={objectives} />
      )}

      {pagination.total_pages > 1 && (
        <div className="flex justify-between mt-10">
          <p className="font-light lowercase dark-grey mb-5">
            {pagination.records || 0} vendas efetuadas
          </p>
          <Pagination
            totalPages={pagination.total_pages}
            currentPage={page}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}

export default withObjectiveAnalyticsContainer(Objectives)
