import React from 'react'
import { NavLink as Link } from 'react-router-dom'

const NavigationSubNavbarLink = ({className, ...rest}) => {
  const currentClassName = [className, 'campaign-link-sub-navbar flex gl-navbar__link'].join(' ')
  return (
    <Link {...rest}
      activeClassName ="active-link-sub-navbar"
      className={currentClassName}
    />
  )
}

const SubNavbarButton = ({className, ...rest}) => {
  const currentClassName = [className, 'campaign-link-sub-navbar flex gl-navbar__link'].join(' ')
  return (
    <button {...rest}
      className={currentClassName}
    />
  )
}

export default NavigationSubNavbarLink

export { SubNavbarButton }
