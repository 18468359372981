import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveCampaigns } from '../actions/campaignActions';
import { saveUser } from '../actions/userActions';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router';

class PrivateRoute extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  render() {
    const {component: Component, ...rest} = this.props
    const { confirm_terms } = this.props.user
    return(
      <Route {...rest} render={(props) => {
        const hasJwt = localStorage.getItem('jwt')
        const client = props.match.params.slug || localStorage.getItem('client') 
        
        if(hasJwt && confirm_terms) {
          return  <Component {...props}/>
        }

        if(hasJwt && !confirm_terms) {
          return <Redirect to={'terms_of_service'} />
        }
        return <Redirect to={'/login'} />
      }} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCampaign: (campaign) => {
      dispatch(saveCampaigns(campaign));
    }
  }

}

export default connect(mapStateToProps,mapDispatchToProps)(PrivateRoute);