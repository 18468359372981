import React, { Component } from "react"
import NotificationItem from "./NotificationITem"
import Counter from "../Counter"
import { ReactComponent as NotificationIcon } from "../../../../assets/img/notification.svg"

export default class NotificationList extends Component {
  handleClick = e => {
    const isContainer = !!this.node.contains(e.target)
    if (!isContainer && this.props.opened) {
      this.props.onListHide()
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  render() {
    const {
      notifications,
      opened,
      numNotificationsToShow,
      onListShow,
      onListHide,
      onNotificationView,
      onHandleScroll
    } = this.props

    const hasNotifications = numNotificationsToShow > 0

    return (
      <div
        className="g-notification__container above-content "
        ref={node => (this.node = node)}
      >
        <div id="notification-bell" title='Notificações' onClick={opened ? onListHide : onListShow}>
          <NotificationIcon className="g-navbar__icons24 m-3 cursor-pointer campaign-link-icon" />
          {hasNotifications && <Counter value={numNotificationsToShow} />}
        </div>
        {opened && (
          <div
            className="g-notification__dropdown default-bg  navbar-text  g-round g-content-shadow"
            id="notification-dropdown"
          >
            <div className="notification__dropdown__scrolling" id="notification-dropdown" onScroll = { onHandleScroll } >
              {
                notifications.map(n => (
                  <NotificationItem 
                    onNotificationView={onNotificationView} 
                    key={n.id} 
                    {...n}
                  />
                ))
              }
              {!hasNotifications &&  notifications.length === 0 && (
                <div className="flex flex-col items-center ">
                  <i className="primary-text fas fa-bell-slash text-xl mb-2"></i>
                  <p className="text-size">Sem notificações</p>
                </div>
              )}
            </div>
          </div> 
        )}
      </div>
    )
  }
}
