import React from "react"
import NavigationSubNavbarLink from "../shared/NavigationSubNavbarLink"
import KeyValue from "../shared/KeyValue"

const normalizedCountryState = (address) => {
  const countryState = {
    countryName: '',
    stateName: ''
  }
  if (address === null){
    return countryState.countryName + ' ' + countryState.stateName;
  }
  if (address.country){
    countryState.countryName = address.country.name
  }
  if (address.state){
    countryState.stateName = address.state.name
  }
  return countryState.countryName + ' ' + countryState.stateName
}

const getAddress = (address) => {
  if (address === null){
    return ''
  }
  const normalizedAddress1 = address.address1 === null ? '' : address.address1
  const normalizedAddress2 = address.address2 === null ? '' : address.address2

  return normalizedAddress1 + normalizedAddress2;
}

const StoreDetails = props => {
  const {
    external_identifier: identifier,
    contact_name: responsibleName,
    contact_phone: responsiblePhone,
    address,
    employees,
    fax,
    name,
    phone
  } = props

  const normalizedAddress = getAddress(address)
  function orEmpty(value) {
    return value || "-"
  }

  return (
    <div className="gl-round gl-content-shadow p-5 mb-10">
      <h2>{name || identifier}</h2>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Id de loja"
            value={identifier}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title primary-text"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Número de telefone"
            value={orEmpty(phone)}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Número de jogadores"
            value={employees.length}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Loja"
            value={orEmpty(normalizedAddress)}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Localidade"
            value={orEmpty(normalizedCountryState(address))}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Cidade"
            value={orEmpty(address !== null ? address.city : '')}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
      <div className="row gl-profile__info-data gl-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Responsável"
            value={orEmpty(responsibleName)}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Contacto do responsável"
            value={orEmpty(responsiblePhone)}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Fax"
            value={orEmpty(fax)}
            classNameData="gl-profile__info-resized"
            classNameTitle="gl-profile__info-title"
          />
        </div>
      </div>
    </div>
  )
}

const AnalyticsLayout = props => {
  const { group, campaignUser, client } = props
  const baseUrl = "/analytics/" + group.id
  return (
    <div className="my-10 flex flex-col">
      <StoreDetails {...group} />
      <nav className="flex gl-profile__title primary-text mb-5">
        <NavigationSubNavbarLink to={baseUrl} exact>
          Jogadores
        </NavigationSubNavbarLink>
        <NavigationSubNavbarLink to={baseUrl + "/sales"}>Vendas</NavigationSubNavbarLink>
        <NavigationSubNavbarLink to={baseUrl + "/objectives"}>Objetivos</NavigationSubNavbarLink>
        {campaignUser.canSeeAnalytics && campaignUser.canSeeGDS && (
          <NavigationSubNavbarLink to={baseUrl + "/gds"}>{client.gds_tab_name ? client.gds_tab_name : 'Analiticas'}</NavigationSubNavbarLink>
        )}
      </nav>
      {props.children}
    </div>
  )
}

export default AnalyticsLayout
