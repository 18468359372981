import React, { Component } from 'react'
import ContentLayout from '../shared/ContentLayout';
import {connect} from 'react-redux'
import {prefix} from '../../../i18n'
import ValidatorContainer from './ValidatorContainer';
import UserContainer from './UserContainer';
const tInvoice = prefix('invoice')

class InvoiceContainer extends Component {

  render() {
    const { campaign, campaignUser} = this.props

    const validator = campaignUser.canInvoiceValidate

    console.log(" [InvoiceContainer] Validator =>", validator)
    return (
      <ContentLayout enlarged title={tInvoice('title')} campaign={campaign}>
        {
          validator ?
            <ValidatorContainer campaign={campaign} />
            :
            <UserContainer campaign={campaign} />
        }
      </ContentLayout>
    )
  }
}

const mapToStateProps = (state) => ({
  campaign: state.campaignReducer.campaign,
  campaignUser: state.balanceReducer.campaignUser
})

const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapToStateProps, mapDispatchToProps)(InvoiceContainer)
