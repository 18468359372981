import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { SupportingDocumentsService } from '../../../services'
import { saveSupportingDocumentsOfType } from '../../../actions/supportingDocumentsActions';
import { startFetch, endFetch } from '../../../actions/generalActions';
import ContentLayout from '../shared/ContentLayout';

class SupportingDocumentsContent extends Component{

  constructor(props){
    super(props);
    this.state={
      user: this.props.user,
    };
  }

  componentDidMount() {
    this.getSupportingDocumentsOfType(this.props.match.params.slug);
  }

  componentDidUpdate(oldprops) {
    if (this.props.match.params.slug != oldprops.match.params.slug) {
      this.getSupportingDocumentsOfType(this.props.match.params.slug);
    }
  }

  getSupportingDocumentsOfType(typeId) {
    const payload = {
      client_slug: this.state.user.client.slug
    }
    this.props.startFetch()
    SupportingDocumentsService.getSupportingDocumentsOfType(payload, typeId)
      .then(response => {
        const supportingDocumentsOfType = response.data
        this.props.saveSupportingDocumentsOfType(supportingDocumentsOfType)
        this.props.endFetch()
      })
      .catch(()=>(this.props.endFetch()));
  }

  showDocumentDetails(e,documentId) {
    document.getElementById("document-title"+documentId).classList.toggle("hidden");
    document.getElementById("document-content"+documentId).classList.toggle("hidden");
  }

  getStyle(){
    const color_primary = this.props.campaign.color_primary;
    return {color: color_primary}
  }

  getButtonStyle(){
    const color_primary = this.props.campaign.color_primary;
    return {color: '#ffffff',
            backgroundColor:color_primary}
  }

  render(){
    const {
      campaign,
      user,
      campaignUser,
      supportingDocumentsOfType
    } = this.props
    return(
      <div className="container">
        {supportingDocumentsOfType.map((item,i) =>
        (
          <div key={i} className={"border-tb m-0"}>
            <div className={i==0?"row documents-content hidden":"row documents-content"} style={this.getStyle()} id={"document-title"+item.id}>
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <h3>{item.title}</h3>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 float-right">
                <a onClick={(e) => this.showDocumentDetails(e,item.id)} className="float-right font-quicksand-light show-more-anchor">
                  Ver mais
                </a>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 float-right">
                {item.attachments[0] &&
                  <a className="float-right" style={this.getStyle()}
                    href={item.attachments[0].url} download >
                    <i className="fas fa-arrow-down download-icon"></i>
                  </a>
                }
              </div>
            </div>
            <div className={i==0?"document-content":"hidden document-content"} id={"document-content"+item.id}>
              <i onClick={(e) => this.showDocumentDetails(e,item.id)} className='fa fa-times news-remove close-icon'></i>
              <ContentLayout
                key={i}  campaignUser={campaignUser}
                campaign={campaign}  user={user}
                title={item.title}
                backgroundColor="#ffffff" font="font-quicksand-regular"
                classTitle=""
                >
                  <div className="width-available break-words hyphens"  dangerouslySetInnerHTML={{__html: item.body}} />
                  {item.attachments[0] &&
                    <a className="download-document-button font-quicksand" style={this.getButtonStyle()}
                      href={item.attachments[0].url} download >
                      <i className="fas fa-arrow-down download-icon-button"></i> Download
                    </a>
                  }
              </ContentLayout>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    campaignUser: state.balanceReducer.campaignUser,
    supportingDocumentsOfType: state.supportingDocumentsReducer.supportingDocumentsOfType
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveSupportingDocumentsOfType: (supportingDocuments, documentId) => {
      dispatch(saveSupportingDocumentsOfType(supportingDocuments, documentId));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())},
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SupportingDocumentsContent));
