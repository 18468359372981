import React from "react"
import Modal from "../../../shared/Modal";

const ConfirmationModal = ({
  selected,
  saleTrade,
  onUnselectObject,
  onAcceptSaleTrade
}) => {

  const handleAcceptAction = e => {
    e.preventDefault()
    onAcceptSaleTrade(saleTrade.id)
  }

  return (
    <Modal show={selected} untoggle={onUnselectObject} extraSmall>
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 py-2">
          <h2>Atenção</h2>
        </div>
        <div className="py-4 px-2 text-base bg-yellow-lighter text-yellow-darker max-w-sm my-5">
          <p>Esta troca de contrato afetará os seus pontos e o seu ranking.</p>
          <p>Deseja realmente executar esta ação?</p>
        </div>
        <div className="flex justify-between">
          <button
            className="outline-reset bg-red-dark text-base text-white rounded px-4 py-3"
            onClick={onUnselectObject}
          >
            <i className="fa fa-times" />{" "}
            <span className="font-quicksand">Cancelar</span>
          </button>
          <button
            className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
            onClick={handleAcceptAction}
          >
            <i className="fas fa-check" />{" "}
            <span className="font-quicksand">Aceitar</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
