import React from "react"
import CatalogCheckbox from "./CatalogCheckbox"
import ReactSelect from "react-select"
import CatalogOrderOptions from "./CatalogOrderOptions"
const CatalogOptions = ({
  campaign,
  optionState,
  optionActions,
  defaultValues,
  onBrandChange,
  brands,
  selectedBrand,
  generateFilterActions,
  generateFilterState,
  selectedOrder,
  onOrderChange,
  orderOptions
}) => {
  const { nextAffordableProducts, affordableProducts, highlighted, wished } = generateFilterState()
  const {
    handleNextAffordableProducts,
    handleAffordableProducts,
    handleHighlighted,
    handleWished
  } = generateFilterActions()
  const { firstTaxon, secondTaxon, thirdTaxon } = optionState()
  const {
    changeFirstTaxons,
    changeSecondTaxons,
    changeThirdTaxons
  } = optionActions()
  const {
    selectedFirstTaxon,
    selectedSecondTaxon,
    selectedThirdTaxon
  } = defaultValues()
  return (
    <>
      <div className="row between-xs py-3 items-center">
        <ReactSelect
          isClearable
          campaign={campaign}
          onChange={e => onBrandChange(e)}
          options={brands}
          getOptionLabel={option => option.name}
          value={selectedBrand}
          getOptionValue={option => option.id}
          placeholder="Marcas"
          className="col-sm col-xs-12 catalog__filter"
          classNamePrefix="o-custom-select"
          noOptionsMessage={() => "Sem resultados"}
        />
        <ReactSelect
          isClearable
          campaign={campaign}
          onChange={e => changeFirstTaxons(e)}
          options={firstTaxon}
          getOptionLabel={option => option.name}
          value={selectedFirstTaxon}
          getOptionValue={option => option.id}
          placeholder="Prémios"
          className="col-sm col-xs-12 catalog__filter"
          classNamePrefix="o-custom-select"
          noOptionsMessage={() => "Sem resultados"}
        />
        <ReactSelect
          isClearable
          campaign={campaign}
          onChange={e => changeSecondTaxons(e)}
          options={secondTaxon}
          getOptionLabel={option => option.name}
          value={selectedSecondTaxon}
          getOptionValue={option => option.id}
          placeholder="Escolha uma categoria"
          className="col-sm col-xs-12 catalog__filter"
          classNamePrefix="o-custom-select"
          noOptionsMessage={() => "Sem resultados"}
        />
        <ReactSelect
          isClearable
          campaign={campaign}
          onChange={e => changeThirdTaxons(e)}
          options={thirdTaxon}
          getOptionLabel={option => option.name}
          value={selectedThirdTaxon}
          getOptionValue={option => option.id}
          placeholder="Sub-categorias"
          className="col-sm col-xs-12 catalog__filter"
          classNamePrefix="o-custom-select"
          noOptionsMessage={() => "Sem resultados"}
        />
      </div>
      <div className='row justify-between py-3'>
        <div className="col-sm-9 col-xs-12 flex flex-col">
          <CatalogCheckbox
            primary
            handleClick={e => handleAffordableProducts(e)}
            label="Já tenho VCoins para trocar por estes prémios"
            isSelected={affordableProducts}
          />
          <CatalogCheckbox
            handleClick={e => handleHighlighted(e)}
            label="Novidades na loja"
            isSelected={highlighted}
          />
          <CatalogCheckbox
            primary
            handleClick={e => {
              handleWished(e)
            }}
            label="Os meus favoritos"
            isSelected={wished}
          />
          <CatalogCheckbox
            primary
            handleClick={e => {
              handleNextAffordableProducts(e)
            }}
            label="Estou quase a ter VCoins para poder trocar por estes prémios"
            isSelected={nextAffordableProducts}
          />
        </div>
        <CatalogOrderOptions
          campaign={campaign}
          selectedOrder={selectedOrder}
          onOrderChange={onOrderChange}
          orderOptions={orderOptions}
        />
      </div>
    </>
  )
}

export default CatalogOptions
