import React, { Component } from 'react';
import { connect } from 'react-redux'
import { buildTransaction } from '../../../actions/generalActions';

export class ClientContainer extends Component {
  render() { 
    const {component: Component, ...rest} = this.props

    return ( <Component {...rest}  /> );
  }
}

const mapStateToProps = (state) => ({
  client: state.userReducer.client
})

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(ClientContainer)

export function withClientContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}
 
export default Container;