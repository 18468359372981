import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { startFetch, endFetch } from '../../../actions/generalActions';
import { UserService, ClientService, CountryStateService } from '../../../services';
import {ToastContainer, ToastStore} from 'react-toasts';
import {t, prefix} from '../../../i18n'
import TermsAndConditionsModal from "./TermsAndConditionsModal"
import AddressForm from './AddressForm'
import PersonalForm from './PersonalForm'
import JobSelect from './JobSelect'
import StoreDetailsForm from './StoreDetailsForm'
import CommercialManagerForm from './CommercialManagerForm'
import TermsAndConditionsForm from './TermsAndConditionsForm'

const tRegistry = prefix('registry')
class Register extends Component{

  constructor(props){
    super(props);
    this.state={
      genderOptions: [{id: 'male', name: tRegistry('male')},{ id:'female', name: tRegistry('female')}],
      errors: {}, 
      user: {}, 
      storeDetails: {},
      selectedCommercialManager: undefined,
      address: {},
      selectedCountry: undefined,
      selectedState: undefined,
      termsAccepted: false,
      commercialManagers: [], 
      groups: [],
      positions: [],
      countries: [], 
      states: undefined,
      redirect: false
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleStoreDetailsChange = this.handleStoreDetailsChange.bind(this);
    this.handleCommercialManagerChange = this.handleCommercialManagerChange.bind(this);
    this.getStates = this.getStates.bind(this);
    this.saveState = this.saveState.bind(this);

  }

  getJobs(){
    const payload = this.props.client.slug
    this.props.startFetch();
    ClientService.getGroups(payload)
      
      .then((response) => {
        this.setState({
          groups: response.data.client_groups
        })
        this.props.endFetch();
      })
      .catch((error) => {
        this.props.endFetch();
      });
    this.props.startFetch();
    ClientService.getPositions(payload)
    .then((response) => {
      this.setState({
        positions: response.data.client_positions
      })
      this.props.endFetch();
    })
    .catch((error) => {
      this.props.endFetch();
    });
  }

  getCommercialManagers(){
    this.props.startFetch()
    const {client} = this.props
    const clientPayload = { clientSlug: client.slug }
    ClientService.getCommercialManagers(clientPayload).then((response) => {
     this.setState({commercialManagers: response.data.commercial_managers})
     this.props.endFetch();
    })
    .catch((error) => {
      this.setState({commercialManagers: []})
      this.props.endFetch()
    });
  }

  getInitalCountryState(){
    this.props.startFetch();
    let NewAddress = this.state.address
    CountryStateService.getCountries()
    .then((response) => {
      const countries = response.data.countries 
      const portugal = countries.find(e => e.iso.toString() === 'PT')
      CountryStateService.getStates({country_id: portugal.id})
      .then((response) => {
        const states = response.data.states
        const default_state = states.find(e => e.abbr.toString() === 'CNT')
        NewAddress['country_id'] = (portugal.id).toString()
        NewAddress['state_id'] = (default_state.id).toString()
        this.setState({
          address: NewAddress, 
          countries: countries, 
          selectedCountry: portugal, 
          states: states, 
          selectedState: default_state
        }, () => {
           this.props.endFetch();
        })
      })
      .catch((error) => {
        this.props.endFetch();
      })
    })
    .catch((error) =>{
      this.props.endFetch();
    })
  }

  getStates(option){
    let NewAddress = this.state.address
    const payload = {country_id: option.id}
    NewAddress['country_id'] = (option.id).toString()
    CountryStateService.getStates(payload)
    .then((response) => {
      this.setState({address: NewAddress, selectedCountry: option, states: response.data.states})
    })
  }

  saveState(option){
    let NewAddress = this.state.address
    NewAddress['state_id'] = (option.id).toString()
    this.setState({address: NewAddress, selectedState: option})
  }

  saveGroups = (options) => { 
    this.setState({ selectedGroups: options})
  }

  savePosition = (option) => {
    this.setState({selectedPosition: option})
  }

  saveGender = (option) => {
    this.setState({selectedGender: option})
  }

  handleAddressChange(event) {
    let NewAddress = this.state.address
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    NewAddress[name] = value
    this.setState({
      address: NewAddress
    });
  }

  handleUserChange(event) {
    let NewUser = this.state.user
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    NewUser[name] = value
    this.setState({
      user: NewUser
    });
  }

  handleStoreDetailsChange(event) {
    let NewStoreDetails = this.state.storeDetails
    
    const target = event.target;
    const value = target.value;
    const name = target.name;

    NewStoreDetails[name] = value
    this.setState({
      storeDetails: NewStoreDetails
    });
  }

  handleCommercialManagerChange(option) {
    this.setState({
      selectedCommercialManager: option
    })
  }

  handleTermsCheckChange = (isChecked) => {
    this.setState({
      termsAccepted: isChecked
    });
  }

  handleShowTermsModal = () => {
    const {terms} = this.props
    this.setState({
      isTermsVisible: true,
      terms: terms
    })
  }

  handleNoAcceptTermsModal = () => {
    this.setState({
      isTermsVisible: false,
      termsAccepted: false
    })
  }

  handleAcceptTermsModal = () => {
    this.setState({
      isTermsVisible: false,
      termsAccepted: true
    })
  }

  register(e){
    e.preventDefault();
    const {
      address,
      storeDetails,
      selectedCommercialManager,
      selectedPosition,
      selectedGroups,
      selectedGender,
      selectedCountry,
      selectedState,
      termsAccepted
    } = this.state
    let {
      user 
    } = this.state
    const optionalFields = this.props.optionalFields

    const TermsFields = this.findOptionalFields(optionalFields, "commercial_manager")
    if ( TermsFields !== undefined && TermsFields.required ){
      if ( !termsAccepted ){
        ToastStore.error(tRegistry('you-must-accept-terms'))
        return;
      }
    }

    const CommercialManagerFields = this.findOptionalFields(optionalFields, "commercial_manager")
    if ( CommercialManagerFields !== undefined && CommercialManagerFields.required ){
      if ( !selectedCommercialManager ){
        ToastStore.error(tRegistry('you-must-choose-a-commercial-manager'))
        return;
      }
    }
    if (this.filterOptionalFields(optionalFields, ["address"]).length > 0){
      if (!selectedCountry || !selectedState){
        ToastStore.error(tRegistry('you-must-choose-a-country-and-region'))
        return;
      }
    }
    if (this.filterOptionalFields(optionalFields, ["gender"]).length > 0){
      if( !selectedGender ){
        ToastStore.error(tRegistry('you-must-choose-your-gender'))
        return;
      }
    }
    
    if ( this.filterOptionalFields(optionalFields, ["job"] ).length > 0 ){
      if ( !selectedGroups || !selectedPosition){
        ToastStore.error(tRegistry('you-must-choose-your-job'))
        return;
      }
    }
    user.gender = selectedGender ? selectedGender.id : ''
    const {
      token
    } = this.props
   
    const payload = { 
      user: user, 
      store_details: storeDetails,
      commercial_manager_id: selectedCommercialManager ? selectedCommercialManager.id : null,
      position: selectedPosition, 
      address: address,
      address: address,
      terms_accepted: termsAccepted,
      client_slug: this.props.client.slug, 
      groups: selectedGroups 
    };

    payload.token = token
    this.props.startFetch();
    UserService.createNewEntry(payload)
      .then((response) => {
        this.props.endFetch();
        this.setState({redirect: true});
      })
      .catch((error) => {
        this.props.endFetch();
        if (error.response.data.errors !== undefined) {
          this.setState({ errors: error.response.data.errors })
          ToastStore.error(error.response.data.error)
        } else {
          ToastStore.error('Ocorreu um erro não identificado no registo')
        }
      })
  }

  capitalizeClientName = (name) => {
   return (name.charAt(0).toUpperCase() + name.slice(1));
  }

  componentDidMount(){
    const { countries, commercialManagers } = this.state
    if ( countries.length === 0){
      this.getInitalCountryState()
    }
    if ( commercialManagers.length === 0){
      this.getCommercialManagers()
    }
    this.getJobs()
   
  }

  filterOptionalFields = (optionalFields, filterFields) => {
    let fieldsToShow = optionalFields.filter(
      field => filterFields.includes(field.field_type)
    )
    return fieldsToShow
  }

  findOptionalFields = (optionalFields, field) => {
    let fieldToShow = optionalFields.find((optionalField,i) => {
      return field === optionalField.field_type
    })
    return fieldToShow
  }

  getHint = (field) => {
    if (field === undefined){
      return;
    }
    return field.field_hint
  }


  render() {
    const { 
      genderOptions,
      redirect, 
      countries, 
      states, 
      selectedCountry, 
      selectedState,
      commercialManagers, 
      errors,
      positions,
      groups
    } = this.state;
    const { client, user, optionalFields } = this.props
    if (redirect) {
      return <Redirect client={client} to={`/thankyou`}/>;
    }
    const personalOptionalFields = this.filterOptionalFields(optionalFields, ["first_name", "last_name", "phone", "birthday", "nif", "gender"])
    const JobsFields = this.filterOptionalFields(optionalFields, ["job"])
    const AddressFields = this.filterOptionalFields(optionalFields, ["address"])
    const LoginFields = this.findOptionalFields(optionalFields, "login")
    const EmailFields = this.findOptionalFields(optionalFields, "email")
    const StoreDetailsFields = this.findOptionalFields(optionalFields, "store_details")
    const CommercialManagerFields = this.findOptionalFields(optionalFields, "commercial_manager")
    const TermsFields = this.findOptionalFields(optionalFields, "terms_and_conditions")
    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
        <div className="font-quicksand row center-xl h-full center-lg center-xs center-md ">
          <div className="col-lg-8 col-md-8 col-xs-11 default-container-login my-10 mx-auto">
            <h1 className='py-5'>{this.capitalizeClientName(client.name)}</h1>
            <form className="py-10 mx-8 px-8 my-10  text-left bg-white" onSubmit={(e)=> this.register(e)  }>
              <h2 className='pb-10 template-title'>{tRegistry('title')}</h2>
              <div className="flex template-block between-xs">
                <div className='w46'>
                  <input required onChange = {this.handleUserChange} 
                    className="w-full template-blue" placeholder={ user.login ? user.login : tRegistry('login')}  type="text" name="login"></input>
                  <p className='w-full my-1 template-hint'>{this.getHint(LoginFields)}</p>
                  {errors.login && <p className='my-1 w-full template-errors'>{tRegistry('login')}: {errors.login.join(',')}</p> }
                </div>
                <div className='w46'>
                  <input required onChange = {this.handleUserChange} className="w-full template-blue" 
                    placeholder={user.email ? user.email : tRegistry('email')}  hint={this.getHint(EmailFields)} type="email" name="email"></input>
                   <p className='w-full my-1 template-hint'>{this.getHint(EmailFields)}</p>
                  {errors.email && <p className=' my-1 w-full template-errors'>{tRegistry('email')}: {errors.email.join(',')}</p> }

                </div>
              </div>
              <div className="flex template-block between-xs">
                <div className='w46'>
                  <input required onChange = {this.handleUserChange} 
                    className="w-full template-blue" placeholder={tRegistry('password')} type="password" name="password"></input>
                  {errors.password && <p className='w-full template-errors'>{tRegistry('password')}: {errors.password.join(',')}</p> }
                </div>
                <div className='w46'>
                  <input required onChange = {this.handleUserChange} className="w-full template-blue" 
                    placeholder={tRegistry('password-confirmation')} type="password" name="password_confirmation"></input>
                  {errors.password_confirmation && <p className='w-full template-errors'>{tRegistry('password')}}: {errors.password_confirmation.join(',')}</p> }
                </div>
              </div>
              { JobsFields.length > 0 &&  
                <JobSelect groups={groups} positions={positions} onChangeGroups={this.saveGroups} onChangePosition={this.savePosition}/>
              }            
              { StoreDetailsFields !== undefined && 
                <StoreDetailsForm
                  required={StoreDetailsFields.required}
                  onChangeStoreDetails={this.handleStoreDetailsChange}
                />
              }
              { CommercialManagerFields !== undefined && 
                <CommercialManagerForm
                  required={CommercialManagerFields.required}
                  commercialManagers={this.state.commercialManagers}
                  onChangeCommercialManager={this.handleCommercialManagerChange}
                />
              }
              { personalOptionalFields.length > 0 && 
                <PersonalForm 
                  required={personalOptionalFields[0].required}
                  onUserChange={this.handleUserChange} genderOptions={genderOptions} errors={errors} 
                  onGenderChange={this.saveGender} personalOptionalFields={personalOptionalFields} 
                />
              }
              { AddressFields.length > 0 && 
                <AddressForm countries={countries} states={states} selectedCountry={selectedCountry} selectedState={selectedState} 
                  required={AddressFields[0].required}
                  onChangeAddress={this.handleAddressChange} onChangeCountry={this.getStates} onChangeState={this.saveState}
                />
              }
              { TermsFields !== undefined && 
                <>
                  <TermsAndConditionsForm
                    required={TermsFields.required}
                    termsAccepted={this.state.termsAccepted}
                    onTermsCheckChange={this.handleTermsCheckChange}
                    onShowTerms={this.handleShowTermsModal}
                  />
                  <TermsAndConditionsModal
                    selected={this.state.isTermsVisible}
                    terms={this.state.terms}
                    onNoAcceptTermsModal={this.handleNoAcceptTermsModal}
                    onAcceptTermsModal={this.handleAcceptTermsModal}
                  />
                </>

              }
              <div className='flex end-xs pt-10'>
                <button className="button-primary user-button" type="submit" value="Submit">{tRegistry('submit-btn')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch()); },
    endFetch: () => { dispatch(endFetch()); }
  }
}

export default connect(null, mapDispatchToProps)(Register);