import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startFetch, endFetch } from '../../../actions/generalActions';
import FirstLogin from './index';
class FirstLoginWrapper extends Component {

  render(){
    const {client} = this.props
    return (
      <div className='image-background' style={client.background_banner.url ? {backgroundImage: 'url(' + client.background_banner.url + ')'}: {} }>
        <FirstLogin client = {client} />
      </div>
   
    )
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.userReducer.client
  }
}
function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch()); },
    endFetch: () => { dispatch(endFetch()); }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FirstLoginWrapper);