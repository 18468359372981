import React, { Component } from 'react';
import { OrdersService } from '../../../services'
import { startFetch, endFetch} from '../../../actions/generalActions';
import { saveOrders } from '../../../actions/ordersActions';
import { connect } from 'react-redux';
class OrderCheckBox extends Component{
  constructor(props){
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(){
    this.props.startFetch();
    const {
      user,
      campaign,
      order,
      page
    } = this.props
    
    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      order_id: order.id,
      page: page,
      userReceived: !order.received_by_user,
      status: "delivered"
    }

    OrdersService.updateOrder(payload).then((response) => {
      if (response.status === 204) {
        this.props.endFetch()
        return;
      }  
      const orders = response.data.orders
      this.props.saveOrders({orders});
      this.props.endFetch();
      
    })
    .catch((error) => {
      this.props.endFetch();
    });
  }

  render() {
    const { order } = this.props
    return (
      <i onClick={e => this.handleClick()} className={order.received_by_user ? 'order-checkbox-completed' : 'order-checkbox'}></i>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveOrders: (orders) => {
      dispatch(saveOrders(orders))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(null,mapDispatchToProps)(OrderCheckBox);