import React from "react"
import { Link } from "react-router-dom"

const PasswordRecovery = ({
  client_logo: ClientLogo,
  client,
  recoverPassword
}) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 gamification__container-login">
        <div className="py-10">
        <img className="gamification__image" src={client.logo.url} alt=""/>
        </div>
        <form className="pb-2 vodafone" onSubmit={recoverPassword}>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="gamification__input"
              placeholder="Email / Login"
              required
              type="text"
              name="emailOrLogin"
            />
          </div>
          <div className="row center-lg center-xs center-md py-5">
            <button
              className="gamification__button-cart gamification__button-primary text-xl vodafone-bold"
              type="submit"
              value="Submit"
            >
              Pedir nova palavra-passe
            </button>
          </div>
        </form>
        <Link className="gamification__link mb-16 vodafone" to={"/login"}>
          Voltar para login
        </Link>
      </div>
    </div>
  )
}

export default PasswordRecovery
