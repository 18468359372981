import React, { Component } from 'react'
import ContentTitle from '../shared/ContentTitle'
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import { withRouter } from 'react-router-dom'
import { InvoiceService } from '../../../services';
import { startFetch, endFetch } from '../../../actions/generalActions'
import { saveReprovalReasons, validateInvoice }from '../../../actions/invoiceActions'
import {t, prefix} from '../../../i18n'
import {ToastStore} from 'react-toasts';
const tSelector = prefix('registry')
class InvoiceValidationForm extends Component {

  state = {
    selectedReason: undefined,
    invoice: {},
  }

  invoicePayload = () => ({
    clientId: this.props.user.client.slug,
    campaignId: this.props.campaign.slug
  })

  getInvoiceFromPath = (props = this.props) => {
    const {startTran} = props
    const {id} = props.match.params
    const payload = {
      ...this.invoicePayload(),
      id
    }

    startTran(() =>
      InvoiceService.getInvoiceDetail(payload)
        .then(response => response.data.invoice)
        .then(invoice => {
          console.log(" [InvoiceValidationForm] [getInvoiceFromPath] Invoice = ", invoice)
          this.setState(prevState => ({...prevState, invoice}))
        })
        .catch(error => {
          const errors = error.response.data.errors
          this.setState({headerErrors: errors})
        })
    )
  }

  formPayload = () => ({
    clientId: this.props.user.client.slug,
    campaignId: this.props.campaign.slug,
    id: this.state.invoice.id
  })

  getRefusalReasons = () =>  {
    this.props.startTran(()=>
      InvoiceService.getReprovalReasons(this.invoicePayload())
        .then(response => response.data.reprove_reasons)
        .then(reproveReasons => {
          this.props.saveReprovalReasons(reproveReasons)
        })
        .catch(()=>this.props.endFetch())
    )
  }

  approveInvoice = (data) => {
    const payload = {
      ...this.formPayload(),
      ...data
    }
    this.props.startTran(()=>
      InvoiceService.approveInvoice(payload)
        .then(response => response.data.invoice)
        .then(invoice => {
          this.props.validateInvoice(invoice)
          const detailUrl = `/invoice/verified/${invoice.id}`
          this.props.history.push(detailUrl)
        })
        .catch(()=>this.props.endFetch())
    )
  }

  refuseInvoice = (data) => {
    const payload = {
      ...this.formPayload(),
      ...data
    }
    this.props.startTran(()=>
      InvoiceService.refuseInvoice(payload)
        .then(response => response.data.invoice)
        .then(invoice => {
          this.props.validateInvoice(invoice)
          const detailUrl = `/invoice/verified/${invoice.id}`
          this.props.history.push(detailUrl)
        })
        .catch(()=>this.props.endFetch())
    )
  }

  changeValidate = (e) => {
    this.setState({validated: e.target.value === 'valid'})
  }

  changeSelectedReason = (option) => {
    this.setState({selectedReason: option})
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedReason, validated } = this.state
    if (validated === false && !selectedReason){
      ToastStore.error(tSelector('fill-select'))
      return;
    }

    validated ? this.approveInvoice(this.state) : this.refuseInvoice(this.state)

  }

  componentDidMount(){
    if (this.props.reprovalReasons.length == 0){
      this.getRefusalReasons()
    }
    this.getInvoiceFromPath()
  }

  render() {
    const { validated } = this.state
    const { reprovalReasons} = this.props

    if(this.state.invoice.id === undefined) {
      return (
        <div />
      );
    } else {
      if(this.state.invoice.awaiting_approval) {
        return (
          <form className="col-md-5 col-lg-5 col-sm-4 mt-8" onSubmit={this.handleSubmit}>
            <ContentTitle sub title="Validar" />
            <div className="mx-bs">
              <div className={`pr-6 mb-2 font-quicksand-light`}>
                <label className="text-color w-1/3 text-xl">Definir como:</label>
                <div className="mr-5 text-black">
                  <div className="my-3">
                    <input className="pr-2 mr-2" type="radio" name="valid" value={'invalid'} checked={validated === false} onChange = { this.changeValidate }/>
                      Inválida
                  </div>
                  <div className="my-3">
                    <input className="pr-2 mr-2" type="radio" name="valid" value={'valid'} checked={validated === true}  onChange = { this.changeValidate }/>
                      Válida
                  </div>
                </div>
              </div>

              <InputContainer label="Motivo:" className={(validated || validated === undefined) && 'invisible'}>
                <ReactSelect
                  isClearable
                  onChange={this.changeSelectedReason}
                  options={reprovalReasons}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder='Selecione a motivo de rejeição'
                  className='w-full'
                  classNamePrefix='custom-select'/>
              </InputContainer>

              <div className={"flex justify-left mt-3"}>
                <button disabled={ validated === undefined }
                  className={"rounded primary-bg text-white p-3"}>Confirmar Fatura</button>
              </div>
            </div>
          </form>
        )
      } else {
        return (
          <div>
            <p>A factura não pode ser alterada pois está no estado {this.state.invoice.current_state}.</p>
          </div>
        )
      }
    }
  }
}

const mapStateToProps = (state) => ({
  reprovalReasons: state.invoiceReducer.reprovalReasons,
  user: state.userReducer.user
})

const mapDispatchToProps = (dispatch) => ({
  startTran: (promiseAction) => {
    dispatch(startFetch())
    promiseAction()
      .then(()=>
        dispatch(endFetch())
      ).catch(() =>
        dispatch(endFetch())
      )
  },
  endfetch: () => dispatch(endFetch()),
  saveReprovalReasons: (reprovalReasons) => dispatch(saveReprovalReasons(reprovalReasons)),
  validateInvoice: (invoice) => dispatch(validateInvoice(invoice))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceValidationForm))

const InputContainer = ({label, children, className}) => (
  <div className={`flex pr-6 mb-2 font-quicksand-light items-center ${className ||''}`}>
    <label className="text-color w-1/3 text-xl">{label}</label>
    <div className="flex w-full text-black">
      {children}
    </div>
  </div>
)
