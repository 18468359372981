import React, { Component } from 'react';
import styled from 'styled-components';


const SelectedSelector = styled.div`
  z-index: 1;
  width: 100%;
  :hover{
    color: ${props=> props.text_color};
    background-color: ${props=> props.background_color};
  }
`
const Selector = styled.div`
  z-index: 1;
  width: 100%;
  color: ${props=> props.text_color};
  background-color: ${props=> props.background_color};
`
class CustomSelector extends Component {

  render(){
    const {
      selected,
      handleClick,
      value,
      campaign,
      text
    } = this.props;

    return(
      selected ?
        <Selector
          text_color = {campaign.navbar_text_color}
          background_color = {campaign.color_secondary}
          onClick = {() => handleClick()} 
          value={value} 
          className='px-3 py-3 cursor-pointer'
          >
          {text}
        </Selector>
        :
        <SelectedSelector 
          text_color = {campaign.navbar_text_color}
          background_color = {campaign.color_secondary}
          onClick = {() => handleClick()} 
          value={value} 
          className='px-3 py-3 cursor-pointer'
          >
          {text}
        </SelectedSelector>
    )
  };
}

export default CustomSelector;
   