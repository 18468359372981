import React, {Component} from 'react'
import { startFetch, endFetch} from '../../../actions/generalActions';
import { saveValidity } from '../../../actions/balanceActions'
import { BalanceService } from '../../../services'
import { NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import Moment from 'moment'
import {prefix, t} from '../../../i18n'
const tBalance = prefix('balance')

class UserExpired extends Component {

  fetchUserExpired() {
    this.props.startFetch();
    const {
      user,
      campaign,
      page
    } = this.props

    const payload = {
      clientSlug: user.client.slug, 
      campaignId: campaign.slug,
      page: page
    }

    BalanceService.getUserExpired(payload).then((response) => {
      const balance = response.data
      this.props.saveValidity(balance);
      this.props.endFetch();
    }).catch(error => {
      this.props.endFetch();
    })
  }

  componentDidUpdate(prevProps){
    if(this.props.page !== prevProps.page ){
      this.fetchUserExpired()
    }
    
    if( this.props.campaign.slug !== prevProps.campaign.slug) {
      this.fetchUserExpired()
    }
  }

  componentDidMount() {
    if (this.props.points.length === 0){
      this.fetchUserExpired()
    }
  }

  render() {
    const { points } =  this.props
    return (
        <>
          <NavLink to={"/profile/balance"} className = 'text-lg float-right my-5 gray' exact>
            Ver tabela de pontos <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </NavLink>
          <table className='mt-12 w-full'>
            <thead>
              <tr className='user-header uppercase'>
                <th className='w-15 prod-th-title order-th text-left'>{tBalance('expired-date')}</th>
                <th className='w-10 prod-th-title  text-left'>{tBalance('status')}</th>
                <th className='w-5 prod-th-title  text-right'>{tBalance('points')}</th>
                <th className='w-5 prod-th-title  text-right'>{tBalance('points-expired')}</th>
                <th className='w-5 prod-th-title  text-right'>{tBalance('points-spent')}</th>
              </tr>
            </thead>
            <tbody>
              {points && points.map((e, key) =><Row key={key} {...e}/> )}
            </tbody>
          </table>  
        </>
      
    )
  }
}
const Row = ({expired_date, expired_status, points_spent, points_earned, points_expired}) => {
  return (
    <tr className="gray user-row">
      <th className="text-left py-2 ">
        <p>{ expired_date && Moment(expired_date).format(t('date-format'))}</p>
      </th>
      <th className="text-left">
        <p>{expired_status}</p>
      </th>
      <th className="text-right ">
        <p>{points_earned}</p>
      </th>
      <th className="text-right ">
        <p>{points_expired}</p>
      </th>
      <th className="text-right ">
        <p>{points_spent}</p>
      </th>
    </tr>
  )
}

function mapDispatchToProps(dispatch){
  return{
    saveValidity: (balance) => { dispatch(saveValidity(balance))},
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}


export default connect(null, mapDispatchToProps)(UserExpired)