import React, { Component } from 'react';
import {t, prefix} from '../../../i18n'

const StoreDetailsForm = ({ required, onChangeStoreDetails}) => {
  const tRegistry = prefix('registry')
  return (
    <>
      <div>
        <h2 className='py-8 template-title'>{tRegistry('store-details-title')}</h2>
      </div>
      <div className='flex template-block between-xs'>
        <div className='w46'>
          <input required={required} onChange={onChangeStoreDetails} placeholder={tRegistry('store-name')} className='w-full template-blue' type='text' name='name'></input>
        </div>
        <div className='w46'>
          <input required={required} onChange={onChangeStoreDetails} placeholder={tRegistry('store-insignia')} className='w-full template-blue' type='text' name='insignia'></input>
        </div>
      </div>
      <div className='flex template-block between-xs'>
        <div className='w46'>
          <input required={required} onChange={onChangeStoreDetails} placeholder={tRegistry('zipcode')} className='w-full template-blue' type='text' name='zip_code'></input>
        </div>
        <div className='w46'>
          <input required={required} onChange={onChangeStoreDetails} placeholder={tRegistry('city')} className='w-full template-blue' type='text' name='city'></input>
        </div>
      </div>
    </>
  )
}
export default StoreDetailsForm