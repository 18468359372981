import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { FilePicker } from 'react-file-picker';
import { prefix } from '../../../i18n';
import CustomSelect from '../../shared/CustomSelect';
import { InvoiceService } from '../../../services';
const tInvoice = prefix('invoice')

export default class InvoiceForm extends Component {
  // needs to hold form state 
  state = {
    number: '',
    saleType: {},
    document: {},
    thereIsInvoice: false,
    errorToGetInvoice: null,
    invoiceDate: new Date()
  }

  updateState = (stateName, mapper) => {
    return (data) => {
      data  = mapper ? mapper(data) : data
      this.setState({[stateName]: data})
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (!this.state.errorToGetInvoice && !this.state.thereIsInvoice)  {
      this.props.onSubmit(this.state)
    } else {
      if(this.state.thereIsInvoice) return window.alert("Já existe factura com mesmo número")
      else if (this.state.errorToGetInvoice) return window.alert("Ocorreu um erro para verificar número de factura")
    }
  }

  checkExistentInvoice = (event) => {
    event.preventDefault()
    InvoiceService.getInvoiceByNumber({campaignId: this.props.campaign.slug, clientId: this.props.user.client.slug, invoiceNumber: this.state.number})
      .then(response => {
        if (response.status === 200 && response.data.id) return this.setState({ thereIsInvoice: true, errorToGetInvoice: null })
        else return this.setState({ thereIsInvoice: false, errorToGetInvoice: null })
      })
      .catch(error => {
        if (error.response.status === 404) return this.setState({ thereIsInvoice: false, errorToGetInvoice: null })
        else return this.setState({ thereIsInvoice: false, errorToGetInvoice: error });
      })
  }

  eventMapper = (e) => e.target.value

  componentDidUpdate(oldProps){
    const { saleTypes } = this.props
    if (oldProps.saleTypes !== saleTypes){
      const defaultValue = saleTypes.length > 0 ? saleTypes[0] : null 
      this.setState({saleType: defaultValue})
    }
  }

  componentDidMount(){
    const { saleTypes, invoice } = this.props
    const defaultValue = saleTypes.length > 0 ? saleTypes[0] : null 
    if (invoice === undefined){
      this.setState({saleType: defaultValue})
    } else {
      this.setState({
        saleType: saleTypes.find((i => i.name === invoice.sale_type)) || defaultValue,
        number: invoice.invoice_number,
        invoiceDate: new Date(invoice.invoice_date)
      })
    }
  }

  render() {
    const {campaign, saleTypes, errors, inputWidth } = this.props
    const {number, saleType, document, invoiceDate} = this.state

    return (
      <form className="col-xs" onSubmit={this.handleSubmit}>
        <Input
          // required
          label={tInvoice('number')+":"}
          onChange={this.updateState('number', this.eventMapper)}
          onBlur={this.checkExistentInvoice}
          value={number}
          error={errors.invoice_number}
          width={ inputWidth ? inputWidth : 'w-1/3' }
          className="px-3 py-2 input-round w-full font-quicksand-light"
        />
        
        <InputContainer errors={errors.sale_type} label ={tInvoice('sellType') +":"} width={ inputWidth ? inputWidth : 'w-1/3' } >
          <CustomSelect 
            campaign={campaign} 
            onChange={this.updateState('saleType')} 
            options={saleTypes}  
            getOptionLabel={(option) => option.name} 
            value={saleType} 
            getOptionValue={(option) => option.id} 
            placeholder='' className='w-full' 
            noOptionsMessage="Sem opções"
            classNamePrefix='custom-select'
            />
        </InputContainer>
        <InputContainer label="Fatura:" errors={errors.attachment} width={ inputWidth ? inputWidth : 'w-1/3' } >
            <FilePicker
              className="w-full"
              extensions={['jpg', 'jpeg', 'png','pdf']}
              onChange={this.updateState('document')}
              onError={errMsg => {}}
            >
            <div className="px-3 py-2 input-round w-full  bg-white cursor-pointer break" >
              { document.name || 'deve ter a extensão .pdf, .png, .jpeg ou .jpg'}
            </div>
          </FilePicker>

        </InputContainer>
        <DateInput
          error = { errors.invoice_date}
          selected={invoiceDate}
          onChange={this.updateState('invoiceDate')}
          maxDate={new Date()}
          dateFormat="dd-MM-yyyy"
          label={tInvoice('invoice-date') +":"}
          width={ inputWidth ? inputWidth : 'w-1/3' }
        />
        <div className="flex justify-end mt-2">
          <button className="rounded primary-bg text-white px-5 py-3 ">Guardar Fatura</button>
        </div>
      </form>
    )
  }
}

const Input = ({label, error, type="text", width, ...rest}) => (
  <InputContainer label={label} width={width} errors={error} >
    <input type={type} {...rest}/>
  </InputContainer>
)

const DateInput = ({label, error, width, ...rest}) => (
  <InputContainer label={label} errors={error} width={width} >
    <DatePicker {...rest} />
  </InputContainer>
)

const InputContainer = ({label, children, className, errors, width}) => (
  <>
  <div className={`flex pr-6 mb-2 font-quicksand-light items-center ${className ||''}`}>
    <label className="text-color w-1/3 text-xl">{label}</label>
    <div className={`flex  ${width}  text-black`}>
      {children}
    </div>
  </div>
  { errors && 
    <div className={`flex pr-6 mb-2 font-quicksand-light`}>
      <p className='w-full font-quicksand invoice-error'>{errors.join(',')}</p>
    </div>
  }
  </>
 
)
