import React from "react"

const CustomButton = ({
  outline,
  primary,
  secondary,
  right,
  cart,
  small,
  medium,
  className,
  ...rest
}) => {
  const buttonClass = [
    "g-profile__button g-content-shadow",
    outline && "g-profile__button--border border-primary primary-text",
    right && "g-profile__button--right",
    primary && "primary-bg text-color",
    secondary && "secondary-bg text-color",
    cart && "g-profile__button--cart",
    small && "g-profile__button--small",
    medium && "g-profile__button--medium",
    className
  ].join(" ")
  return <button className={buttonClass} {...rest} />
}

export default CustomButton
