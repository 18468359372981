import React, {memo} from 'react'
import Modal from "../../../shared/Modal"
import PaymentMethodsList from '../../shared/PaymentMethodsList'
import {prefix} from '../../../../i18n'

const tCartCheckout = prefix('cart-checkout')

const CoPaymentModal = memo(
  ({
    show,
    close,
    campaignRate,
    debtPoints,
    paymentMethods,
    handleMethodChoosing,
  }) => { 

   return <Modal class="h-auto" show={show} lessPadding medium untoggle={close}>
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 pb-2 flex flex-row justify-center items-center">
          <h2 class="flex-1">{tCartCheckout("choose-payment-method")}</h2>
        </div>
      </div>
     {<PaymentMethodsList campaignRate={campaignRate} debtPoints={debtPoints} methods={paymentMethods} handleMethodChoosing={handleMethodChoosing}/>} 
    </Modal>
  }
); 

export default CoPaymentModal
