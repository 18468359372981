import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NewsService } from '../../../services/index';
import { buildTransaction } from '../../../actions/generalActions';

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { news: [] };
  }

  componentDidMount() {
    if (this.state.news.length === 0) {
      this.getNews()
    }
  }

  getNews() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }

    this.props.startTransaction(() =>
      NewsService.getNews(payload)
        .then((response) => {
          this.setState({ news: response.data.news })
      })
    )
  }

  handleOnShow = (id) => {
    this.props.history.push(`/news/${id}`)
  }

  render() {
    const {
      component: Component,
      ...rest
    } = this.props;

    return (
      <Component
        news={this.state.news}
        onShow={this.handleOnShow}
        {...rest}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    loading: state.campaignReducer.campaign_loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch)
  }
}

const Container = connect(mapStateToProps, mapDispatchToProps)(NewsContainer)

export function withNewsContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
