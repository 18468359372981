const initialState = {
  addresses: []
}

const addressesReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_ADDRESSES':
      return {
        ...state,
        addresses: action.payload.addresses
      }
    default:
      return state;
  }
}
export default addressesReducer;