import React from 'react'

import styled, {css} from 'styled-components'

const HouverButtonWrapper = styled.button`
  color: ${props=> props.textColor}
  background: ${props=> props.primary}
  text-align: left;
  padding: 19px
  :hover {
    background: ${props=> props.secondary}
  }
  ${props => props.active && css`
    background: ${props=> props.secondary}
  `}
`
export function HouverButton({campaign, ...rest}) {
  return (
    <HouverButtonWrapper 
      {...extractCampaignInfo(campaign)}
      {...rest}
    />
  )
}

const CampaignButtonWrapper = styled.button`
  color: ${props=> props.textColor}

  ${props=> props.isPrimary && css`
    background: ${props=> props.primary}
  `}
`
export function CampaignButton({campaign, ...rest}) {
  return (
    <CampaignButtonWrapper
      {...extractCampaignInfo(campaign)}
      {...rest}    
    />
  )
}

//utility function to capture campaing information
const extractCampaignInfo = (campaign) => ({
  primary: campaign.color_primary,
  secondary: campaign.color_secondary,
  textColor: campaign.navbar_text_color
})