import React from "react"
import Moment from "moment"
import { t } from "../../../i18n"
import StyledImage from "../shared/StyledImage"
import { Chevron, substring } from "../normalizer"
import {Link} from "react-router-dom"
const Preview = ({ announcement, imageSize, limitText, onHandleFeedOnClick }) => {
  return (
    <div className="navbar-text g-home__promotion" onClick={() => onHandleFeedOnClick(announcement.slug)}>
      <StyledImage
        url={announcement.prev_image[imageSize] ? announcement.prev_image[imageSize].url : ""}
        className="g-promotion__image g-promotion__image--announcement"
      />
      <div className="g-promotion w-full">
        <div className="g-promotion__title">
          <h3 className="g-post__title g-post__title--promotion ">
            {announcement.title}
          </h3>
          <Chevron className="primary-fill g-promotion__chevron" />
        </div>
        <div className="g-promotion__description g-promotion__description--announcement">
          <p>{substring(announcement.subtitle, limitText, '...')}</p>
        </div>
        <p className="text-right vodafone">
          {Moment(announcement.created_at).format(t("date-format"))}
        </p>
      </div>
    </div>
  )
  
}


export default Preview
