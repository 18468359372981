export function saveBonusPointsBalance(state){
  return {
    type: 'SAVE_BONUS_POINTS_BALANCE',
    payload: state
  }
}
export function saveGivenBonusPoints(state){
  return {
    type: 'SAVE_GIVEN_BONUS_POINTS',
    payload: state
  }
}