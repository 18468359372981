import React from "react"
import { Link } from "react-router-dom"

const EditForgottenPassword = ({
  client_logo: ClientLogo,
  client,
  login,
  finishPasswordRecovery
}) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 default-container-login m-auto">
        <div className="py-10">
          <ClientLogo src={client.logo.url} />
        </div>
        <form className="pb-10" onSubmit={finishPasswordRecovery}>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="template-blue text-center"
              placeholder="Nova Password"
              required
              type="password"
              name="password"
            />
          </div>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="template-blue text-center"
              placeholder="Repetir Password"
              required
              type="password"
              name="passwordConfirmation"
            />
          </div>
          <div className="row center-lg center-xs center-md py-5">
            <button
              style={{ width: "290px" }}
              className="button-cart button-primary text-xl"
              type="submit"
              value="Submit"
            >
              Finalizar
            </button>
          </div>
        </form>
        <Link className="link pb-20" to={login}>
          Voltar para login
        </Link>
      </div>
    </div>
  )
}

export default EditForgottenPassword
