import React, { Component } from 'react'
import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'
import { saveStores, buildTransaction, AnalyticsService, eraseStoreData } from '../../normalizer'
import { HOC } from '../';
export class AnalyticsContainer extends Component {

  state = {
    selectedGroup: undefined
  }

  fetchGroupDetails(selectedGroup, redirect) {
    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id : selectedGroup.id
    }

    this.props.startTransaction(()=>
      AnalyticsService.getGroupDetails(payload)
        .then(response => response.data )
        .then(({group}) => {
          this.setState({
            selectedGroup: group
          },
            () => {
              if(redirect)
                this.props.history.push('/analytics/' + group.id)
            }
          )
        })
    )
  }

  handleStoreSelection = (selectedGroup) =>  {
    const currentGroup = this.state.selectedGroup
    if(!currentGroup || selectedGroup.id !== currentGroup.id) {
      this.props.eraseStoreData()
      this.fetchGroupDetails(selectedGroup, true)
    }
  }

  tryDeepLinking = (groups) => {
    const matching = matchPath(this.props.history.location.pathname, {
      path: '/analytics/:id'
    })

    if(matching) {
      const group = groups.find(g => g.id.toString() === matching.params.id)
      if(group)
        this.fetchGroupDetails(group, false)
    }
  }

  fetchGroups() {
    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug
    }

    this.props.startTransaction(()=>
      AnalyticsService.getUserGroups(payload)
        .then(response => response.data)
        .then(({groups}) => {
          this.props.saveStores(groups)
          return groups
        })
        .then(groups => {
          const anayticsIndex = matchPath(this.props.history.location.pathname, {
            path: '/analytics',
            exact: true
          })

          if(anayticsIndex && groups.length === 1) {
            this.handleStoreSelection(groups[0])
          } else {
            this.tryDeepLinking(groups)
          }
        })
    )
  }

  componentDidMount() {
    this.fetchGroups()
  }

  render() {
    const {component: Component,...rest} = this.props

    const matching = matchPath(this.props.history.location.pathname, {
      path: '/analytics',
      exact: true
    })

    const group = matching ? undefined : this.state.selectedGroup

    return (
      <Component
        onGroupSelection={this.handleStoreSelection}
        {...rest}
        selectedGroup={group}
        campaignUser={this.props.campaignUser}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  stores : state.analyticsReducer.stores,
  campaign: state.campaignReducer.campaign,
  client: state.userReducer.client,
  campaignUser: state.balanceReducer.campaignUser,
})

const mapDispatchToProps = (dispatch) => ({
  saveStores: stores => dispatch(saveStores(stores)),
  eraseStoreData: stores => dispatch(eraseStoreData()),
  startTransaction: buildTransaction(dispatch)
})

const Container =  connect(mapStateToProps, mapDispatchToProps)(AnalyticsContainer)

export function withAnalyticsContainer(component){
  const withAnalyticsContainer = HOC(Container, component)
  return withAnalyticsContainer
}

export default Container
