import React, { useEffect,Component, useState } from 'react';
import { NavLink, withRouter, Redirect, Switch, Route} from 'react-router-dom'
import { saveLinks } from "../../../../actions/contentActions"
import {connect} from 'react-redux'
import Moment from 'moment'
import { t } from '../../../../i18n';
import { formatNumber, CardsService } from '../../normalizer';
import { startFetch, endFetch } from '../../../../actions/generalActions';
import { array } from 'prop-types';
import Popup from './Popup'
import InspectCard from './InspectCard'


const CardTable  = (props) =>{

  const [CampaignCards,setCampaignCards] = useState (null);
  const [UserCards,setUserCards] = useState (null);
  const [ToCancel, setToCancel] = useState (false);
  const [ToCharge, setToCharge] = useState (false);
  const [ToRegister, setToRegister] = useState (false);
  const [ToChargeNumber, setToChargeNumber] = useState ("");
  const [SelectedCard, setSelectedCard] = useState (false);
  const [response, setResponse] = useState (0);

  const [toInspect, setToInspect] = useState(null);

  const [cardToRegister, setCardToRegister] = useState ("");
  const [typeToRegister, setTypeToRegister] = useState ("");


  useEffect(() => {
    getUserCards()
    getCampaignCards()
  }, []);

  
 
  //#region API CALLS

  // Done
  function getCampaignCards(){
    const {
      user,
      campaign,
    } = props

    const payload  = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
    }

    props.startTransaction(() =>
    CardsService.getCampaignCards(payload)
      .then((response) => {
        setCampaignCards(response.data["campaign/card_types"])
    }))
  }

  // Done
  function getUserCards(){
    const {
      user,
      campaign
    } = props

    const payload  = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
    }

    props.startTransaction(() =>
    CardsService.getUserCards(payload)
      .then((response) => {
        setUserCards(response.data["user/cards"])
    }))
  }

  // Done
  function PutChargeCard(){
    const {
      user,
      campaign
    } = props

    var points = SelectedCard.recharge_options.find(e=>e.value == ToChargeNumber).points

    const payload  = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      cardId: SelectedCard.id,
      value: ToChargeNumber,
      points: points
    }

    props.startTransaction(() =>
    CardsService.putChargeUserCard(payload)
      .then((response) => {
        setResponse(response.status)
    }))
  }

  // Done
  function PutcancelCard(){
    const {
      user,
      campaign
    } = props

    const payload  = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      cardId: SelectedCard.id
    }

    props.startTransaction(() =>
    CardsService.putCancelUserCard(payload)
      .then((response) => {
        //setResponse(response.status) -----
        setResponse(200)
      })
      .catch(e => {
        setResponse(-1)
      })
  )}

  // Done
  function PutRegisterCard(){
    const {
      user,
      campaign
    } = props

    const payload  = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      cardType:  CampaignCards.find(e => e.card_type.name == typeToRegister).card_type.id,
      number: cardToRegister
    }

    props.startTransaction(() =>
    CardsService.putRegisterUserCard(payload)
      .then((response) => {
        setResponse(response.status)
    }))
  }

  //#endregion

  

 

  //#region Charge Event

  function RunToCharge(Card)
  {
    setToCharge(true)
    setSelectedCard(Card)
  }
  
  function clearChargeVariables()
  {
    setSelectedCard(null);
    setToChargeNumber("");
    setResponse(0);
  }


  function ComfirmCharge()
  {
    PutChargeCard();
  }

  //#endregion

  //#region  Cancel Event

  function RunToCancel(Card)
  {
    setToCancel(true)
    setSelectedCard(Card)
  }

  function clearCancelVariables()
  {
    setSelectedCard(null);
    setResponse(0);
  }

  function ComfirmCancel()
  {
    PutcancelCard();
    setSelectedCard(null);
    window.location.reload(false);
  }

  //#endregion

  //#region  Register Event

 function RunToRegister()
 {
  setToRegister(true)
 }

 function clearResgisterVariables()
 {
  setTypeToRegister("")
  setCardToRegister("")
   setSelectedCard(null);
   setResponse(0);
 }

 function ComfirmRegister()
 {
    PutRegisterCard();
    setSelectedCard(null);
    window.location.reload(false);
 }

 //#endregion



  function CheckPermission(array,string)
  {
    for(var a=0;a<array.length;a++)
    {
      var b = array[a].code;
      
      if(b == string)
      {
        return true;
      }
    }
    return false;
  }


  function IsRegisted(Value)
  {
    if (Value.registrar == null)
    {
      return false;
    }

    if(Value.registrar.name == null)
    {
      return false;
    }

    return true;
  }


    return (
      <>
      {toInspect == null && <section className="w-full">
        <div className="overflow-x-auto">
          <table className={"mb-10 w-full"}>
            <thead className="text-color gl-profile__table">
              <tr className="gl-profile__info-row">
                <th className="w-15 prod-th-title order-th text-left">TIPO DE CARTÃO</th>
                <th className="w-10 prod-th-title  text-left">PORTADOR</th>
                <th className="w-10 prod-th-title  text-center">Estado</th>
                <th className="w-10 prod-th-title  text-center">Nº DE CARTÃO</th>
                <th className="w-10 prod-th-title  text-center">TOTAL CARREGADO</th>
                <th className="w-10 prod-th-title  text-center">ACÇÕES</th>
              </tr>
            </thead>
            <tbody className="dead-grey">
              {  UserCards && UserCards.map((value)=>(
                <tr key={value.number} className="gray user-row"  >
                  <td className="p-4">{value.card_type.name}</td>
                  <td className="p-4 whitespace-no-wrap">{IsRegisted(value)  ? value.registrar.name : "Por Registar"}</td>
                  <td className="p-4 text-center">{value.state.label}</td>
                  <td className='p-4 text-center'>{value.number}</td>
                  <td className='p-4 text-center'>{value.balance}</td>
                  <td className='p-4 text-center'>

                    {CheckPermission(value.available_actions,"view_balance") &&
                      <p style={{cursor: 'pointer'}} className='p-1 text-center' onClick={event => setToInspect(value)}>Consultar</p>
                    }

                    {CheckPermission(value.available_actions,"recharge") &&
                    <p style={{cursor: 'pointer'}} className='p-1 text-center' onClick={event => RunToCharge(value)}>Carregar</p>
                    }

                    {CheckPermission(value.available_actions,"cancel") &&
                    <p style={{cursor: 'pointer'}} className='p-1 text-center' onClick={event => RunToCancel(value)}>Cancelar</p>
                    }

                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {(ToCancel) && <Popup isOpenValue={true} Response={response} Title={"Cancelar cartão"} clearVariables={clearCancelVariables} Selected={SelectedCard} ToExcute={ComfirmCancel} Condition={setToCancel}></Popup> }
        {(ToCharge) && <Popup isOpenValue={true} Response={response} Title={"Carregar cartão"} clearVariables={clearChargeVariables} Selected={SelectedCard} ToExcute={ComfirmCharge} Condition={setToCharge}  Value={ToChargeNumber} ToChangeValue={setToChargeNumber}  EventType="Charge" ></Popup> }
        <div style={{display:'flex', justifyContent:'right'}}>
          <button className='btn-secondary px-4 py-1' style={{height:'40px', width:'200px', borderRadius:'10px' }} onClick={event => RunToRegister()}> Registar Novo </button>
          {(ToRegister) && <Popup isOpenValue={true} Response={response} Title={"Registar cartão"} clearVariables={clearResgisterVariables} ToExcute={ComfirmRegister} Condition={setToRegister} Value={cardToRegister} ToChangeValue={setCardToRegister}  Value_={typeToRegister} ToChangeValue_={setTypeToRegister} EventType={"Register"} CampaignCards={CampaignCards} ></Popup>}
        </div>
      </section>}
      {toInspect != null && <InspectCard Condition={toInspect} setCondition={setToInspect} Selected={toInspect}>

      </InspectCard>}
      </>
    )
  
}


function mapDispatchToProps(dispatch) {
  return {
    saveLinks: links => {
      dispatch(saveLinks(links))
    },
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(CardTable))
