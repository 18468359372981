import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {prefix} from '../../../i18n'
import {ColoredBackground} from '../shared/client/BackgroundWrapper'


const footer = prefix('footer')
const company = prefix('company')

const If = ({ condition, children }) => {
  if (condition) {
    return children
  } else {
    return null
  }
}

class Footer extends Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  findFeatureByName = (name) => {
    const feature = this.props.features.find(f => f.name === name)

    return feature
  }
   
  showRegulations = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_regulations : true
  }

  showRules = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_rules : true
  }
  
  showCompanyTrademark = () => {
    const feature = this.findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? this.props.campaign.show_company_trademark : true
  }

  render(){
    const {campaign, toRender, client} = this.props
    return(
      <ColoredBackground 
        className="footer" 
        bg={campaign.color_primary} 
        text={campaign.navbar_text_color} 
        secondary={campaign.color_secondary}
      >
        <div className="row center-lg center-md center-xs text-left pt-20 ml-3">
          <div className="col-lg-3 col-md-2 col-xs-12 items-center">
            <img src={campaign.footer_image.url || campaign.logo.url} alt="lagran-logo"></img>
          </div>
          <div className="col-lg-3 col-md-3 col-xs-12">
            <p className="text-base footer-title pb-5">{company('name')}</p>
            <p className="text-sm incentive-information">{company('address')}</p>
            <p className="text-sm incentive-information">{company('postcode')}</p>
            <p className="text-sm incentive-information">{company('number')}</p>            
          </div>
          <div className="col-lg-2 col-md-2 col-xs-12">
            <p className="text-base footer-title pb-5 uppercase">{footer('info')}</p>
            {/* <Link to="/" className="text-sm info-link">{footer('info')}</Link> */}
            {toRender("News") &&  <Link to="/news" className="text-sm info-link link-reset">{footer('news')}</Link> }
            <If condition={this.showRules()}>
              <Link to="/utility-info/rules" className="text-sm info-link link-reset">{footer('rules')}</Link>
            </If>
            {/* <Link to="/" className="text-sm info-link">{footer('faq')}</Link> */}
            <If condition={this.showRegulations()}>
              <Link to="/utility-info/regulation" className="text-sm info-link link-reset">{footer('regulation')}</Link>
            </If>
            {client.chat_enabled && <a href="javascript:$zopim.livechat.window.show()" className="text-sm info-link link-reset">{footer('live-chat')}</a> }
          </div>
          <div className="col-lg-3 col-md-4 col-xs-12">
            <p className="text-xl contacts-grey">{footer('doubts')}</p>
            <p className="text-2xl contacts secondary mt-auto">{footer('contact-us')}</p>
            <p className="text-3xl contacts secondary">{company('number')}</p>
          </div>
        </div>
        <div className="row center-lg center-md center-xs pb-1 mt-10">
          <hr className="hr-up-catalog"></hr>
        </div>
        <div className="row start-lg start-md start-xs pb-20">
          <div className="col-lg-3 col-md col-xs-12">
            <If condition={this.showCompanyTrademark()}>
              <p className="text-xs footer-footer">{company('copyright')}</p>
            </If>
          </div>
          <Link to ="/terms" className="col-lg-9 col-md col-xs link-reset">
            <p className="text-xs footer-footer">{company('legal-info')} </p>
          </Link>
        </div>
      </ColoredBackground>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer.campaign,
    client: state.userReducer.client
  }
}
export default  connect(mapStateToProps,null)(Footer);
