const initialState = {
  stores: [],
  sales: [],
  salesPagination: {},
  objectives: [],
  objectivesPagination: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case 'SAVE_USER_STORES':
  case 'SAVE_GROUP_SALES':
  case 'SAVE_GROUP_OBJECTIVES':
  case 'ERASE_STORE_DATA':
    return { ...state, ...payload }
  default:
    return state
  }
}
