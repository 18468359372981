import axios from "./interceptor"

export const GroupPositionUserService = {
  getGroupUsers() {
    const url = "api/users/group_positions/colleagues"
    return axios({
      method: "get",
      url: url
    })
  }
}