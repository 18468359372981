import React from "react"
import Requirement from "./Requirement"

const LevelRequirements = (props) => {
  const {  requirements, campaign, isCampaignUserLevel }= props
  return (
    <div className={'level-requirements-container row justify-start'}>

      { requirements.map((requirement, i) => {
        return (
          <Requirement requirement={requirement} key={requirement.id} campaign={campaign} isCampaignUserLevel={isCampaignUserLevel} />
        )
        }) 
      }
    </div>
  )
}

export default LevelRequirements