import React from "react";
import LineHeader from "./LineHeader";
import ColumnContainer from "./ColumnContainer";

const PageLine = (props) => {
  const { rows } = props

  return (
    <>
      {
        !!rows && rows.length > 0 &&
        rows.map((row, idx) => {
          const { cols, bck_color } = row
          return (
            <div key={idx} className='w-full h-full' style={{ backgroundColor: `${bck_color}` }}>
              <LineHeader {...row} />
              <div className="flex flex-row justify-between items-start -mx-1 h-full">
                {cols && cols.length > 0 &&
                  cols.map((col, key) => ( <ColumnContainer key={key} {...col} />))
                }
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export default React.memo(PageLine);

