import React, { Component }from "react"
import LevelLoader from "./LevelLoader"
import LevelRequirements from "./LevelRequirements";
import LevelPointProgress from "./LevelPointProgress";

class LevelPost extends Component {
  constructor(props) {
    super(props)
  }

  getPercentage = (currentLevel, lastLevel) => {
    if (lastLevel === undefined || currentLevel === undefined){
      return 0
    }
    if (currentLevel === lastLevel){
     return 100
    }

    return currentLevel.percentage
  }
  canRenderNormalProgresses = () => {
    const requirements = this.props.selectedLevel.requirements
    if (requirements === undefined){
      return false
    }
    const requirementsTypes = requirements.map((requirement,i) => {
      return requirement.requirement_type
    })
    return requirementsTypes.includes('points') && requirements.length === 1
  }
  
  isLastLevel = (selectedLevel, lastLevel) => {
    if (lastLevel === undefined || selectedLevel === undefined){
      return 0
    }
    return lastLevel.level_number === selectedLevel.level_number
  }
  nextLevelName = (selectedLevel, levels) => { 
    const nextLevel = levels.find(level => 
      level.level_number === selectedLevel.level_number + 1
    )
    return nextLevel !== undefined ? nextLevel.level_number : 'N/A'
  }

  render (){
    const {campaignUser, levels, campaign, selectedLevel} = this.props
    const lastLevel = levels[levels.length-1]
    const progress = this.getPercentage(campaignUser.currentLevel, lastLevel)

    return (
      <div className="text-color gl-home__catalog ">
        <h3 className="gl-post__title gl-wishes__title">
          O meu progresso
        </h3>
        <LevelLoader id={'main-level-loader'} {...this.props} progress={progress}/>
        { !(this.isLastLevel(selectedLevel,lastLevel)) &&
          <>
          { this.canRenderNormalProgresses() ? 
            <LevelPointProgress  {...selectedLevel}  campaign={campaign} />
          :
            <div className='mt-16'>
              <h4 className='text-lg mb-3'>Requisitos nível {this.nextLevelName(selectedLevel,levels)}</h4>
              <LevelRequirements 
                {...selectedLevel} 
                campaign={campaign} 
                isCampaignUserLevel={selectedLevel.level_number === campaignUser.currentLevel.level_number} 
              />
            </div>
          }
          </>
        }
      </div>
    )
  }
}

export default LevelPost
