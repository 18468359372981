import React from "react";
import Slider from "react-slick";
import Product from "./Product";
import NewProductLabel from "./NewProductLabel";

class SimpleSlider extends React.Component {
  
  constructor(props){
    super(props);
    this.state={width: window.screen.availWidth, col: this.props.productsNumber};
    this.landscapeMediaQuery = window.matchMedia("(orientation: landscape)");
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  handleOrientationChange(mql) {
    const productsNumber = this.props.productsNumber;
    if (this.state.width >= 1024) {
      this.setState({ col: productsNumber })
    } else if (this.state.width <= 600) {
      this.setState({ col: productsNumber - 1 })
    } else {
      this.setState({ col: productsNumber - 2 })
    }
  }

  componentDidMount(){
    //this.landscapeMediaQuery.addListener((mql)=> this.handleOrientationChange(mql));
    //this.handleOrientationChange(this.landscapeMediaQuery);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount(){
    //this.landscapeMediaQuery.removeListener(this.handleOrientationChange);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const productsNumber = this.props.productsNumber;
    if (window.innerWidth >= 1240) {
      this.setState({ col: productsNumber })
    } else if (window.innerWidth >= 765) {
      this.setState({ col: 2 })
    } else {
      this.setState({ col: 1 })
    }
  }

  render() {
    const infinite = this.props.products.length >= this.state.col 
    const {
      col
    } = this.state 
    const {
      boxify
    } = this.props
    const settings = {
      infinite: infinite,
      speed: 500,
      slidesToShow: col,
      slidesToScroll: 1
    }

    const boxComponent = boxify ? 'total-product--boxify' :''

    return (
      <Slider {...settings}>
        {
          this.props.products.map((item, i) => {
            return (
              <div className={`box prod total-product ${boxComponent}`} key={i}>
                {
                  item.is_new &&
                  <NewProductLabel/>
                }
                <Product campaign = {this.props.campaign}style={{outline: 'none'}} item={item}  item_key={i} />
              </div>
            )
          })
        }
      </Slider>
    );
  }
}

export default SimpleSlider;