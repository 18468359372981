import React from 'react'
import styled, {css} from 'styled-components'
import ContentTitle from './ContentTitle'

const LayoutWrapper = styled.div`
  background-color: ${props=> props.background};
  padding: 40px 0;
  ${props => props.subcontent && css`
    padding: 40px;
  `}
`

const ContentLayout = ({campaign,enlarged,title, subcontent, children, backgroundColor='#eaeaef', font="font-quicksand", classTitle='uppercase'}) => {

  const reducedClass = enlarged ? 'cont--reduced' : ''
  return (
    <LayoutWrapper background ={backgroundColor} subcontent={subcontent}>
    <div className="cont row start-lg start-md start-xs pb-2">
      <ContentTitle title={title} className={classTitle}/>
    </div>
    <div className={`cont row start-lg start-md start-xs pb-6 ${subcontent ? 'mx-10' : ''} ${font} ${reducedClass}`}>
      {children}
    </div>
  </LayoutWrapper>
  )
}



export default ContentLayout
