import React from 'react';
import { Redirect } from 'react-router-dom';
import { ShoppingCartContainer, CampaignUserContainer, withContainers, AddressContainer } from '../../../containers/gamification';
import CartWarning from '../CartWarning';

import Controller from './Controller';

const CartAddress = props => {

  const {
    cart,
    addresses,
    onUpdateAddress,
    onReturnToCart,
    onCheckAddresses,
    onNewAddressCreation,
    states,
    campaign,
    errors,
    countries,
    selectedState,
    selectedCountry,
    onCountryChange,
    onStateChange
  } = props

  if (cart.items.length === 0) {
    return <Redirect to='/cart' />
  }

  return (
    <>
      <Controller
        onUpdateAddress={onUpdateAddress}
        onReturnToCart={onReturnToCart}
        cart={cart}
        errors={errors}
        addresses={addresses}
        onNewAddressCreation={onNewAddressCreation}
        onCheckAddresses={onCheckAddresses}
        states={states}
        countries={countries}
        selectedState={selectedState}
        selectedCountry={selectedCountry}
        onCountryChange={onCountryChange}
        onStateChange={onStateChange}
      />
      <CartWarning text={campaign.call_attention} />
    </>
  )
}

export default withContainers(CartAddress, [CampaignUserContainer, AddressContainer, ShoppingCartContainer])


