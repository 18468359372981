import React, { Component } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import {t} from '../../../i18n'
class UserNavbar extends Component{

  constructor(props){
    super(props);
    this.state={active: this.props.active};
  }
  balanceActive = () => {
    const url = this.props.location.pathname
    const urls = ["/profile/balance","/profile/validity"]
    return urls.includes(url)
  }

  render(){
    const activeStyle = {backgroundColor: this.props.campaign.color_primary }
    return(
      <div>
        <div className='flex user-tabs space-around'>
          <NavLink 
            to={"/profile"}
            exact
            className="btn-secondary w-1/3 user-button-tabs" 
            activeClassName='w-1/3 user-active-button' 
            activeStyle={activeStyle}
          >
            <div className='user-button-tabs-text uppercase'>{t('user.personal-data')}</div>
          </NavLink>
          <NavLink to={"/profile/orders"}
            className="btn-secondary w-1/3 user-button-tabs" 
            activeClassName='w-1/3 user-active-button' 
            activeStyle={activeStyle}
          >
            <div className='user-button-tabs-text uppercase'>{t('user.orders')}</div>
          </NavLink>
          <NavLink  to={"/profile/balance"}
             className="btn-secondary w-1/3 user-button-tabs" 
             activeClassName='w-1/3 user-active-button' 
             activeStyle={activeStyle}
             isActive={this.balanceActive}
           >
            <div className='user-button-tabs-text uppercase'>{t('user.balance')}</div>
          </NavLink>

          <NavLink to={"/profile/cards"}
            className="btn-secondary w-1/3 user-button-tabs" 
            activeClassName='w-1/3 user-active-button' 
            activeStyle={activeStyle}
          >
            <div className='user-button-tabs-text uppercase'>{t('user.cards')}</div>
          </NavLink>
        </div>
    </div>
    )
  }
}
export default withRouter(UserNavbar);
 