import React, {Component} from 'react'
import {connect} from 'react-redux'

class SimpleCampaignUserContainer extends Component {


  render() {
    const {component: Component, ...rest} = this.props
    return  <Component {...rest}/>
  }
}

const mapStateToProps = (state) => ({
  campaignUser: state.balanceReducer.campaignUser,
  user: state.userReducer.user,
  client: state.userReducer.client
})

const mapDispatchToProps = null

const Container = connect(mapStateToProps, mapDispatchToProps)(SimpleCampaignUserContainer)

export function withSimpleCampaignUserContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
