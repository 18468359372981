import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HOC } from '../';
import { saveGroupSales, buildTransaction, AnalyticsService } from '../../normalizer';
import { ToastStore, ToastContainer } from 'react-toasts';
export class GroupSalesContainer extends Component {

  state = {
    page: 1,
    filtered: false
  }

  fetchGroupSales(filters) {
    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.props.group.id,
      page: this.state.page,
      filters : filters
    }

    this.props.startTransaction(() =>
      AnalyticsService.getGroupSales(payload)
        .then(response => response.data)
        .then(({ sales, pagination }) => {
          this.props.saveGroupSales(sales, pagination);
        })
    )
  }

  requesGroupProduction(){
    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.props.group.id,
    }

    this.props.startTransaction(() =>
    AnalyticsService.requesGroupProduction(payload)
      .then((response) => {
        const APIresponse = response.data
        ToastStore.success(APIresponse.message)
      })
      .catch(() => {
        ToastStore.error('Impossivel de Realizar o Pedido.')
      })
    );
  }
  
  handleClickGroupProduction = () => {
    this.requesGroupProduction();
  }

  handlePageChange = (data) => {
    this.setState({ page: data.selected + 1 }, () => this.fetchGroupSales())
  }

  hasFilters = ({begin_date, end_date, user_name}) => {
    return begin_date || end_date || user_name
  }

  handleSaleFilter = (data) => {
    const {startDate, endDate} = data

    const normalizedStartDate = startDate && new Date(Date.UTC(startDate.getFullYear(),startDate.getMonth(),startDate.getDate()))
    const normalizedEndDate = endDate && new Date(Date.UTC(endDate.getFullYear(),endDate.getMonth(),endDate.getDate()))

    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.props.group.id,
      page: this.state.page,
      filters : {
        begin_date: normalizedStartDate,
        end_date: normalizedEndDate,
        user_name: data.username
      }
    }

    AnalyticsService.getGroupSales(payload)
      .then(response => response.data)
      .then(({ sales, pagination }) => {
        this.props.saveGroupSales(sales, pagination);
      }).catch(e => {
        const errorApi = window.rollbar.error
        if(errorApi) {
          errorApi(e)
        }
      })


    if(this.hasFilters(payload.filters)){
      this.setState({filtered: true})
    } else {
      this.setState({filtered: false})
    }
  }

  componentDidMount() {
    if(this.props.sales.length === 0) {
      this.fetchGroupSales();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <>
        <ToastContainer store={ToastStore}/>
        <Component
          onPageChange={this.handlePageChange}
          onSaleFilter={this.handleSaleFilter}
          page={this.state.page}
          filtered={this.state.filtered}
          onClickGroupProduction={this.handleClickGroupProduction}
          {...rest}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  sales: state.analyticsReducer.sales,
  campaign: state.campaignReducer.campaign,
  client: state.userReducer.client,
  pagination: state.analyticsReducer.salesPagination,
})

const mapDispatchToProps = (dispatch) => ({
  saveGroupSales: (sales, pagination) => dispatch(saveGroupSales(sales, pagination)),
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(GroupSalesContainer)

export function withGroupSalesContainer(component) {
  const withGroupSalesContainer = HOC(Container, component)
  return withGroupSalesContainer
}
export default Container
