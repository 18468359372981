export function savePointBlocks(state){
    return {
      type: "SAVE_POINT_BLOCKS",
      payload: state
    }
};

export function saveHistoryBlocks(state){
  return {
    type: "SAVE_HISTORY_BLOCKS",
    payload: state
  }
};
