import React from 'react'
import Moment from 'moment'
import { t } from '../../../../i18n';

import Pagination from '../../shared/Pagination';
const Row = (historyBlock) => {
    const normalizedDate =  new Moment(historyBlock.end_date).format(t('date-format'))
    return (
      <tr className="gl-profile__info-row text-color">
        <td className='text-left py-3'>{historyBlock.group_name}</td>
        <td className='text-left py-3'>{normalizedDate}</td>
        <td className='text-left py-3'>{historyBlock.product_name}</td>
        <td className="text-right py-3">{historyBlock.objective}</td>
        <td className="text-right py-3">{historyBlock.total_progress}</td>
        <td className="text-right py-3">{historyBlock.total_points_earned}</td>
        <td className="text-right py-3">{historyBlock.unlocked_points_earned}</td>
      </tr>
    )
  }

const HistoryPointBlockTable = (props) => {
    const {
        campaign,
        currencyLabel,
        historyBlocks,
        historyPagination,
        page,
        onPageChange,
        pointBlockLabel
      } = props
    
      return (
        <section className="overflow-x-auto w-full">       
          <table className={"mb-10 w-full gl-profile__table"}>
            <thead className="text-color">
              <tr className="gl-profile__info-row" >
                <th className="w-1/7 text-left py-3">Loja</th>
                <th className="w-1/7 text-left py-3">Data</th>
                <th className="w-1/7 text-left py-3">Produto</th>
                <th className="w-1/7 text-right py-3">Objetivo {pointBlockLabel} { campaign.point_block_nominal ? "" : "(%)" }</th>
                <th className="w-1/7 text-right py-3">Atingido {pointBlockLabel} { campaign.point_block_nominal ? "" : "(%)" }</th>
                <th className="w-1/7 text-right py-3">{currencyLabel} atribuidos</th>               
                <th className="w-1/7 text-right py-3">{currencyLabel} desbloqueados</th>
              </tr>
            </thead>
            <tbody className="">
              {historyBlocks.map(p => <Row {...p} key={p.id} />)}
            </tbody>
          </table>
          { historyPagination.total_pages > 1  &&
            <div className="flex justify-end mt-10">
              <Pagination
                totalPages={historyPagination.total_pages}
                currentPage={page}
                onPageChange={onPageChange}/>
            </div>
          }
        </section>
    )
}

export default HistoryPointBlockTable