import React from 'react'
import styled from 'styled-components'
import incentivehouse from '../../../assets/img/incentivelogo.png';
const LogoWrapper = styled.img`
  width:'260px'
  alt: "logo"
`
export default function Logo({src}) {

  return (
    <LogoWrapper src={src? src : incentivehouse} />
  )
}
