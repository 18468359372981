import React, { useState, useEffect } from "react";
import Moment from "moment";
import { t } from "../../../i18n";
import StyledImage from "../../shared/StyledImage";
import { Chevron, substring } from "../normalizer";
import { Link } from "react-router-dom";

const Preview = ({ announcement, limitText }) => {
  const [deviceType, setDeviceType] = useState('desktop');
  let size;
  let normlimitText = limitText;

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType('mobile');
      } else if (width >= 768 && width < 992) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    window.addEventListener('resize', updateDeviceType);
    updateDeviceType();

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  switch (deviceType) {
    case 'desktop':
      size = 'rectangular_thumbnail_image';
      break;
    case 'mobile':
      size = 'square_thumbnail_image';
      normlimitText = 60;
      break;
    case 'tablet':
      size = 'rectangular_thumbnail_image';
      break;
    default:
      size = 'rectangular_thumbnail_image';
  }

  const croppedImageKey = size.replace('image', 'cropped_image');
  const imageUrl = announcement.image[croppedImageKey] && announcement.image[croppedImageKey].url
                  ? announcement.image[croppedImageKey].url
                  : (announcement.image[size] ? announcement.image[size].url : "");

  return (
    <Link className="text-color gl-home__promotion" to={"/news/" + announcement.id}>
      <StyledImage
        url={imageUrl}
        className="gl-promotion__image gl-promotion__image--announcement"
      />
      <div className="gl-promotion w-full">
        <div className="gl-promotion__title">
          <h3 className="gl-post__title gl-post__title--promotion ">
            {announcement.title}
          </h3>
          <Chevron className="primary-fill gl-promotion__chevron" />
        </div>
        <div className="gl-promotion__description gl-promotion__description--announcement">
          <p>{substring(announcement.subtitle, limitText, '...')}</p>
        </div>
        <p className="text-right">
          {Moment(announcement.created_at).format(t("date-format"))}
        </p>
      </div>
    </Link>
  )
  
}


export default Preview
