import axios from './interceptor';

export const LevelService = {

  getLevels({clientSlug,campaignSlug}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/user_levels`;
    return axios({
      method: 'get',
      url: url
    });
  },
  getProductsByLevels({clientSlug,campaignSlug,levelId}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/items/by_level`
    return axios({
      method: 'get',
      url: url,
      params: {level_id: levelId}
    })
  }
};