import React from "react"
import { withPendingTradesContainer } from "../../../containers/default"
import SaleTable from "./SaleTable"
import ConfirmationModal from "./ConfirmationModal"
const PendingSaleTrades = props => {
  const {
    //data
    page,
    saleTrade,
    pendingSaleTrades,
    pendingPagination,
    isTradeSelected,
    //actions
    onAcceptSaleTrade,
    onRejectSaleTrade,
    onToggleAcceptModal,
    onUnToggleAcceptModal,
    onPageChange
  } = props

  return (
    <div className="template col-xs-12 font-quicksand-light">
      <SaleTable
        trades={pendingSaleTrades}
        pagination={pendingPagination}
        onAccept={onToggleAcceptModal}
        onReject={onRejectSaleTrade}
        onPageChange={onPageChange}
        page={page}
      />

      <ConfirmationModal
        selected={isTradeSelected}
        saleTrade={saleTrade}
        onAcceptSaleTrade={onAcceptSaleTrade}
        onUnselectObject={onUnToggleAcceptModal}
      />

      {pendingSaleTrades.length <= 0 && (
        <div className="text-center text-xl text-color pl-2 pt-10 pb-8">
          Não lhe foram propostas trocas de contratos.
        </div>
      )}
    </div>
  )
}

export default withPendingTradesContainer(PendingSaleTrades)
