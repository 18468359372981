import React, { Component } from "react"
import Flag  from "./LevelFlag"
class LevelLoader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      barWidth: 1000
    }
  }

  isOrBelowUserLevel = (level,currentLevel) => {
    return level.level_number <= currentLevel.level_number
  }
  
  isNextLevel = (level,currentLevel) => {
    return currentLevel.level_number + 1 === level.level_number 
  }
  getLevelStyle = (level,currentLevel) => {
    if ( this.isOrBelowUserLevel(level,currentLevel) ){
      return 'active' 
    } else if ( this.isNextLevel(level,currentLevel) ) {
      return 'next-level'
    } else  {
      return 'locked'
    }
  }
  ContainerClasses = ({miniFlags}) => {
    if (miniFlags){
      return { 
                levelscontainer: 'gl-levels__container-mini',
                flagsContainer: 'gl-levels__flags-container-mini'
             }
    } 
    return {
            levelscontainer: 'gl-levels__container',
            flagsContainer: 'gl-levels__flags-container'
           }
  } 
  
  LevelFlagWidth = () => {
    if (this.props.miniFlags){
      return 23.33
    }
    return 33.33
  }
  
  updateBarWidth = () => {
    const bar = document.getElementById(this.props.id)
    if (bar){
      this.setState({
        barWidth: bar.clientWidth
      })
    }
  }

  findCurrentLevelIndex = () => {
    const currentLevel = this.props.campaignUser.currentLevel
    return this.props.levels.findIndex((level) =>{
      return level.level_number === currentLevel.level_number 
    })

  }

  flagProgress = () => {
    const fullWidth = this.state.barWidth
    return (this.LevelFlagWidth() / fullWidth * 100)
  }

  initialStart = () => {
    if (this.props.miniFlags){
      return 1
    }
    return 2.5
  }

  percentageforOneLevel = (percentage) => {
    const actualPercentage = Number(percentage)
    const fullBar = 100 - 2 * this.initialStart() - this.props.levels.length * this.flagProgress()
    const fullPercentage = (fullBar / (this.props.levels.length - 1)) 
    return (actualPercentage * fullPercentage / 100 )
  }

  fullProgress = () => {
    const NumberOfLevelsCompleted = this.findCurrentLevelIndex() 
    const totalFilled = this.initialStart() + 
                        (NumberOfLevelsCompleted + 1)  * this.flagProgress() + 
                        (NumberOfLevelsCompleted) * this.percentageforOneLevel(100) +
                        this.percentageforOneLevel(this.props.campaignUser.currentLevel.percentage)

    
    return totalFilled > 0 ? totalFilled : 0
  }


  componentDidMount() {
    window.addEventListener("resize", this.updateBarWidth)
    this.updateBarWidth()
  }
  

  render (){
  
    const { campaign, levels, progress, miniFlags, onSelectLevel, heightFlag, textAlign, id}= this.props
    const { currentLevel } = this.props.campaignUser
    const classes = this.ContainerClasses(this.props)
    let expectedProgress = progress >= 100 ? 100 : this.fullProgress()
    expectedProgress = expectedProgress >= 100 ? 100 : expectedProgress
    return (
      <div id={id} className={classes.levelscontainer}>
        <svg id='gl-levels_bar' className={'gl-levels__loader_bar'}>
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={campaign.color_primary} />
              <stop offset="100%" stopColor={campaign.color_primary} />
            </linearGradient>
          </defs>
          <rect
            fill="url(#linear)"
            height={"15px"}
            width={Number(expectedProgress) + "%"}
          >
          </rect>
          
        </svg>
        <div className={classes.flagsContainer}>
          { levels.map((level,i) => {
              return (
                <Flag onClick={onSelectLevel}  level={level} requirements = {currentLevel.requirements}
                  key={i} mode={this.getLevelStyle(level,currentLevel)} miniFlag={miniFlags} height={heightFlag} textAlign={textAlign}>
                  <span className='font-bold'>{level.name}</span>
                </Flag>
              )
                  
            }) 
          }
        </div>
      </div>
      
    )
  }
}

export default LevelLoader