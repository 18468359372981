import React, { Component } from "react"
import { connect } from "react-redux"
import {
  saveRankings,
  saveHighlightedObjectives,
  ObjectiveService,
  RankingsService,
  buildTransaction
} from "../normalizer"

class TemplateContainer extends Component {
  constructor(props) {
    super(props)
  }
  getHighlightedObjectives() {
    const { user, campaign } = this.props

    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug
    }

    this.props.startTransaction(() =>
      ObjectiveService.getHighlighted(payload)
        .then(response => response.data)
        .then(objectives => {
          if (objectives) {
            this.props.saveHighlightedObjectives(objectives)
          }
        })
    )
  }

  getRankings(){
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug,
    }

    this.props.startTransaction(() =>
      RankingsService.getRankings(payload)
        .then((response) => {
          this.props.saveRankings(response.data)
      })
    )
  }

  componentDidMount(){
    this.getRankings()
    this.getHighlightedObjectives()
  }

  render() {
    const { component: Component, ...rest } = this.props
    return <Component {...rest} />
  }
}

const mapDispatchToProps = dispatch => ({
  startTransaction: buildTransaction(dispatch),
  saveHighlightedObjectives: objectives =>
    dispatch(saveHighlightedObjectives(objectives)),
  saveRankings: rankings => 
    dispatch(saveRankings(rankings))
})

const mapStateToProps = state => ({
  objectives: state.objectivesReducer.highlighted,
  campaign: state.campaignReducer.campaign,
  rankings: state.rankingsReducer.rankings,
  user: state.userReducer.user
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateContainer)

export function withTemplateContainer(component) {
  const withTemplateContainer = props => (
    <Container component={component} {...props} />
  )
  const name = component.displayName
  || component.name
    || 'Component'
  
  withTemplateContainer.displayName = `with(${name})`
  return withTemplateContainer
}

export default Container
