import React from "react"
import { Link } from "react-router-dom"

const Login = ({ client_logo: ClientLogo, client, login, forgetPw }) => {
  return (
    <div className="row center-lg center-xs center-md h-screen font-quicksand">
      <div className="col-lg-5 col-md-7 col-xs-11 default-container-login">
        <div className="p-10">
          <ClientLogo src={client.logo.url} />
        </div>
        <form className="pb-10" onSubmit={login}>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="template-blue"
              placeholder="Email/Login"
              required
              type="text"
              name="email"
            />
          </div>
          <div className="row center-lg center-xs center-md py-3">
            <input
              className="template-blue"
              placeholder="Password"
              required
              type="password"
              name="password"
            />
          </div>
          <div className="row center-lg center-xs center-md py-5">
            <button
              className="button-cart button-primary text-xl login__button"
              type="submit"
              value="Submit"
            >
              Entrar
            </button>
          </div>
        </form>
        <Link className="link pb-20 login__link" to={forgetPw}>
          Recuperar password?
        </Link>
      </div>
    </div>
  )
}

export default Login
