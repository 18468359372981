import React from "react"
import { withCampaignContainer } from "../../containers/shared"

const RectLoader = ({
  progress = 0,
  expected_progress = 0,
  campaign,
  radius = "2px",
  thin
}) => {
  const container = ["g-rect-loader", thin && "g-rect-loader--thin"].join(" ")
  return (
    <svg className={container}>
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={campaign.color_secondary} />
          <stop offset="100%" stopColor={campaign.color_primary} />
        </linearGradient>
      </defs>
      <rect
        fill="url(#linear)"
        height={thin ? "10px" : "20px"}
        width={Number(progress) + "%"}
        rx={radius}
      />
      <line
        x1={Number(expected_progress) + "%"}
        y1="0%"
        x2={Number(expected_progress) + "%"}
        y2="100%"
        stroke="#333"
        strokeWidth="2"
      />
    </svg>
  )
}

export default withCampaignContainer(RectLoader)
