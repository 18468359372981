import React from "react"
import ReactSelect from "react-select"
const CustomSelect = (props) =>  { 

  return (
  <ReactSelect
    isClearable={props.isClearable}
    onChange={e => props.onChangeEvent(e)}
    options={props.options}
    getOptionLabel={option => option[props.optionsLabel]}
    value={props.selectedValue}
    getOptionValue={option => option[props.optionsId]}
    placeholder={props.placeholder}
    className={props.classNames}
    classNamePrefix="gl-custom-select"
    noOptionsMessage={() => (props.noMessage || 'Sem resultados')}
  />)
}

export default CustomSelect