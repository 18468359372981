import React from "react"

const HeadersSection = ({ links }) => {
  return (
    <div className="navbar-header-links">
      <div className="cont end-lg end-mg end-xs">
        {links.map((link, i) => {
          return (
            <a
              className="navbar-header-links-text"
              key={i}
              target="_blank"
              rel="noopener noreferrer"
              href={link.url}
            >
              {link.title}
            </a>
          )
        })}
      </div>  
    </div>
  )
}

export default HeadersSection
