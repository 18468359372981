import styled, { css } from "styled-components"

const ClassBuilder = styled.div`
  font-style: inherit;
  ${props =>
    props.campaign &&
    css`
    .primary-text {
      color: ${props.campaign.color_primary}
    }
    .text-color {
      color: ${props.campaign.text_color}
    }

    .secondary-text {
      color: ${props.campaign.color_secondary}
    }
    .header-text {
      color: ${props.campaign.header_text_color}
    }
    .navbar-text {
      color: ${props.campaign.navbar_text_color}
    }
    .table-body-color {Color:#8E959B}

    .dropdown-item {#000000}
      
    .table-body-color-bg { 
      background: #dae1e7
      fill: #dae1e7}

    .navbar-text-white {
      color: white
    }

    .primary-bg {
      background: ${props.campaign.color_primary}
      fill: ${props.campaign.color_primary}
    }

    .primary-fill {
      fill: ${props.campaign.color_primary}
    }
    .secondary-fill {
      fill: ${props.campaign.color_secondary}
    }
    .navbar-fill {
      fill: ${props.campaign.navbar_text_color}
    }
    .mish-fill {
      fill: ${props.campaign.navbar_text_color}
    }

    .primary-bg-after {
      &:after {
        background: ${props.campaign.color_primary}
      }
    }

    .secondary-bg {
      background: ${props.campaign.color_secondary}
      fill: ${props.campaign.color_secondary}
    }

    .text-bg {
      background: ${props.campaign.text_color}
      fill: ${props.campaign.text_color}
    }

    .header-bg {
      background: ${props.campaign.header_bg_color}
    }

    .navbar-bg {
      background: ${props.campaign.navbar_text_color}
    }

    .footer{
      background: ${props.campaign.footer_bg_color};
      color: ${props.campaign.footer_text_color}
    }

    .mish-bg {
      background: ${props.campaign.navbar_text_color}
      fill: ${props.campaign.navbar_text_color}
    }

    .border-mish {
      border-color: ${props.campaign.navbar_text_color}
    }

    .border-primary {
      border-color: ${props.campaign.color_primary}
    }
    .border-secondary {
      border-color: ${props.campaign.color_secondary}
    }
    .border-navbar {
      border-color: ${props.campaign.navbar_text_color}
    }

    .primary-border-color{
      border-color: ${props.campaign.color_primary}
    }

    .half-gradient {
      background: linear-gradient(to bottom, ${
        props.campaign.color_primary
      } 20%, #973031 50%, white 50%)
    }
    .navbar-campaign-link {
      position:relative;
      color: ${props.campaign.header_text_color};
      &:after {
        transition: background-color 300ms ease-in;
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: transparent;
      }
      &:visited {
        color: ${props.campaign.header_text_color};
      }
      &:hover {
        color: ${props.campaign.header_hover_color};
        &:after {
          background: ${props.campaign.header_hover_color};
        }
        path, circle, rect, line {
          stroke: ${props.campaign.header_hover_color};
        }
      }
    }
    .campaign-link {
      position:relative;
      color: ${props.campaign.navbar_text_color};
      &:after {
        transition: background-color 300ms ease-in;
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: transparent;
      }
      &:visited {
        color: ${props.campaign.navbar_text_color};
      }
      &:hover {
        color: ${props.campaign.color_primary};
        &:after {
          background: ${props.campaign.color_primary};
        }
        path, circle, rect {
          stroke: ${props.campaign.color_primary};
        }
      }
    }
    .navbar-active-link {
      position:relative;
      color: ${props.campaign.header_hover_color}
      &:visited {
        color: ${props.campaign.header_hover_color};
      }
      &:hover {
        color: ${props.campaign.header_hover_color};
      }
      &:after {
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: ${props.campaign.header_hover_color}
      }
    }

    .campaign-link-sub-navbar {
      position:relative;
      color: ${props.campaign.color_primary};
      &:after {
        transition: background-color 300ms ease-in;
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: transparent;
      }
      &:visited {
        color: ${props.campaign.color_secondary};
      }
      &:hover {
        color: ${props.campaign.color_primary};
        &:after {
          background: ${props.campaign.color_primary};
        }
        path, circle, rect {
          stroke: ${props.campaign.color_secondary};
        }
      }
    }

    .svg-primary-fill {
      path, circle, rect {
        fill: ${props.campaign.color_primary};
      }
    }
    .navbar-campaign-link-icon {
      color: ${props.campaign.header_text_color};
      background-color: ${props.campaign.header_bg_color};
      .navbar-campaign-link-icon__stroke {
        path, circle, rect {
          stroke: ${props.campaign.header_text_color};
        }
      }
      &:hover {        
        span#notification-counter{
          color: ${props.campaign.header_hover_color};
          background-color: ${props.campaign.header_hover_bg_color};
        }
        color: ${props.campaign.header_hover_color};
        background-color: ${props.campaign.header_hover_bg_color};
        .navbar-campaign-link-icon__stroke {
          path, circle, rect {
            stroke: ${props.campaign.header_hover_color};
            fill: ${props.campaign.header_hover_color};
          }
        }
       
      }
    }
    .campaign-link-icon {
         path, circle, rect, polyline, line {
          stroke: ${props.campaign.header_text_color};
        } 
      &:hover {
        path, circle, rect, polyline, line {
          stroke: ${props.campaign.header_hover_color};
        }
      }
    }
    .campaign-drop-down-item {
      &:hover {
        & > path, & >  circle, & > rect, & > polyline, & > line {
          stroke: ${props.campaign.color_primary};
        }
        color: ${props.campaign.color_primary};
      }
    }
    .active-link {
      position:relative;
      &:visited {
        color: ${props.campaign.color_primary};
      }
      &:hover {
        color: ${props.campaign.color_primary};
      }
      &:after {
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: ${props.campaign.color_primary}
      }
    }

    

    &__menu, __menu-list {
      border-radius: 0px;
      width: calc(100% - 1rem)
    }

    &__control {
      border-color: #8E959B;
      box-shadow: none;
      border-radius: 0px;
      &--is-focused, &--menu-is-open {
        border: 1px solid ${props.campaign.navbar_text_color};
        &:hover {
          border: 1px solid ${props.campaign.navbar_text_color};
        }
      }
    }

    &__option {
      width: calc(100% - 10px);
      margin: 0 5px;
      color: #8E959B;
      cursor: pointer;
      background: white;
      
      &--is-focused  {
        color: ${props.campaign.navbar_text_color};
        background: #FAFAFA;
      }

      &--is-selected {
        color: ${props.campaign.navbar_text_color};
        background: #FAFAFA;
      }

      &:active {
        background: ${props.campaign.navbar_text_color};
        background: ${props.campaign.text_color};
        
      }
    }
  }
  
    .campaign-link-icon-reverse {
      color: ${props.campaign.header_bg_color};
      background-color: ${props.campaign.header_text_color};
      border: 1px solid ${props.campaign.header_bg_color};
      &:hover {
        color: ${props.campaign.header_text_color};
        background-color: ${props.campaign.header_bg_color};
        path, circle, rect {
          stroke: ${props.campaign.header_bg_color};
        }
      }
    }

    .navbar-campaign-link-icon-reverse {
      color: ${props.campaign.header_bg_color};
      background-color: ${props.campaign.header_text_color};
      border: 1px solid ${props.campaign.header_bg_color};
      &:hover {
        color: ${props.campaign.header_bg_color};
        background-color: ${props.campaign.header_text_color};
        path, circle, rect {
          stroke: ${props.campaign.header_bg_color};
        }
      }
    }
    .drop-down-item {
      &:hover {
        path, circle, rect, polyline,line {
          stroke:#000000;
        }
        color: #000000;
      }
    }
    .campaign-drop-down-item {
      &:hover {
        path, circle, rect, polyline,line {
          stroke: ${props.campaign.color_primary};
        }
        color: ${props.campaign.color_primary};
      }
    }

    .active-link {
      position:relative;
      &:visited {
        color: ${props.campaign.color_primary};
      }
      &:hover {
        color: ${props.campaign.color_primary};
      }
      &:after {
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: ${props.campaign.color_primary}
      }
    }

    .active-link-sub-navbar {
      position:relative;
      &:visited {
        color: ${props.campaign.color_primary};
      }
      &:hover {
        color: ${props.campaign.color_primary};
      }
      &:after {
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: ${props.campaign.color_primary}
      }
    }

    .gl-dots{
      color: gray
      .slick-active {
        span {
          color : ${props.campaign.color_primary};
        }
      }
    }
    .level-active{
      g{
        g{
          use{
            fill: ${props.campaign.color_primary}
          }
        }
      }
    }
    .level-next-level{
      g{
        g{
          use{
            fill: ${props.campaign.color_secondary}
          }
        }
      }
    }
    .level-locked{
      g{
        g{
          use{
            fill:gray
          }
        }
      }
    }
    .gl-custom-select{
      &__option:hover {
        color: ${props.campaign.navbar_text_color};
        background: ${props.campaign.color_primary};
      }
      &__option--is-selected, &__option--is-focused{
        color: ${props.campaign.navbar_text_color};
        background: ${props.campaign.color_primary};
        opacity: 0.8;
      }

      &__menu, __menu-list {
        width: calc(100% - 1rem)
      }

      &__option {
        width: calc(100% - 10px);
        margin: 0 5px;
        cursor: pointer;
      }
    }


    .gl-rankings {
      &__data {
        @extend %dead-grey;
        border-top: 11px solid #ffffff;
    
        &-separator {
          border-color: ${props => props.campaign.color_primary};
          border-width: 1px;
          width: 100%;
    
          &.red {
            border-color: #ff6752;
          }
        }
      }
    }
    



  `}
`
export default ClassBuilder
