import React from "react"
import SaleSlider from "./SaleSlider";

const SalesPost = ({content}) => {
  return (
    <div className="text-color gl-home__catalog ">
      <label className="gl-post__label">{content.label}</label>
      <h3 className="gl-post__title gl-wishes__title">
        {content.title}
      </h3>
      { content.items.length === 0 ? 
        <p>Sem vendas destacadas</p>
      :
        <SaleSlider 
          backgroundColor={content.background_color} 
          borderColor={content.border_color}
          textColor={content.text_color}
          sales={content.items}
          numOfProducts={content.limit_num_products}
        />
      }
      

    </div>
  )
}

export default SalesPost
