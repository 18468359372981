import React, { useState, useEffect } from "react";
import Modal from "../../shared/Modal";
import BackgroundDiv from "./BackgroundDiv";
import CustomLink from "./CustomLink";
import { substring } from "../../../utils/utils";
import { withContainers, PopUpContainer, ResizeContainer } from "../normalizer";

const CHARACTED_LIMIT = 590;

function getDeviceType() {
  const width = window.innerWidth;
  if (width < 768) {
    return 'mobile';
  } else if (width >= 768 && width < 992) {
    return 'tablet';
  } else {
    return 'desktop';
  }
}

const PopUpLayout = props => {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    const updateDeviceType = () => {
      setDeviceType(getDeviceType());
    };

    window.addEventListener('resize', updateDeviceType);
    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  const { image, title, description = "", show = false, untoggle, id, slug, fontFamily } = props;

  let imageSize;
  switch (deviceType) {
    case 'desktop':
      imageSize = 'banner_desktop_image';
      break;
    case 'mobile':
    case 'tablet':
      imageSize = 'banner_mobile_image';
      break;
    default:
      imageSize = 'banner_desktop_image';
  }

  const showButton = description.length > CHARACTED_LIMIT;
  const info = showButton
    ? substring(description, CHARACTED_LIMIT, "...")
    : description;

  const popUpClasses = ["bg-white gl-popup", fontFamily].join(" ");

  return (
    <Modal show={show} untoggle={untoggle} large lessPadding>
      <div className={popUpClasses}>
        <div className="gl-popup__image">
          <BackgroundDiv
            className="bg-center bg-cover h-full relative"
            url={image[imageSize] ? image[imageSize].url : ''}
          >
            <button className="bg-black gl-popup__close" onClick={untoggle}>
              <i className="fas fa-times fa-lg text-white" />
            </button>
          </BackgroundDiv>
        </div>
        <div>
          <div className="gl-popup__title font-bold dark-grey text-3xl tracking-wide px-5 pt-5 pb-4">
            {title}
          </div>
          <div
            className="custom-inner-html gl-popup__info leading-normal dead-grey px-5 mb-3"
            dangerouslySetInnerHTML={{ __html: info }}
          />
          {showButton && (
            <div>
              <div className="flex justify-center gl-popup__more" />
              <CustomLink
                to={"/announcement/" + slug}
                className="gl-popup__button link-reset"
                onClick={untoggle}
              >
                Ver mais
              </CustomLink>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default withContainers(PopUpLayout,[PopUpContainer,ResizeContainer])
