import React from "react"
import Momment from "moment"
import RectLoader from "../shared/RectLoader"
import CustomButtom from "../../GamificationTemplate/shared/CustomButton"
import { t } from "../../../i18n"

const objectiveTotalProgress = (rules) => {
  if (rules.length === 0){
    return { 
      totalObjective: 0,
      totalProgress: 0
    }
  }

  let firstRule = rules[0]
  let progressList = rules.map(item => item.progress)
  return { 
    totalObjective: `${firstRule.target}`,
    totalProgress: progressList.reduce((a,b) =>  a+b, 0) / firstRule.target * 100 
  }
}

const ObjectiveCard = ({
  name,
  percentage,
  expected_percentage,
  end_date,
  onClick,
  isFirst,
  rules,
  last_date_updated_sales
}) => {
  const {totalProgress, totalObjective } = objectiveTotalProgress(rules)
  const className = [
    "",
    isFirst && "col-xs-12",
    !isFirst && "col-md-6 col-xs-12"
  ].join(" ")
  const normalizedPercentage = totalProgress >= 100 ? 100 : totalProgress
  const normalizedExperctedPercentage = Number(expected_percentage)
  return (
    <div className={className}>
      <div
        className={`g-objectives__card m-4 g-content-shadow ${isFirst &&
          `g-content-shadow--main`} navbar-text`}
      >
        <div className="flex justify-between pb-1">
          <h3 className="vodafone g-post__title">{name}</h3>
          <h3 className="primary-text font-medium">
            Checkpoint{" "}
            <span className="vodafone">{normalizedExperctedPercentage.toFixed(2)}%</span>
          </h3>
        </div>
        <div className="flex justify-between pb-3">
          <h3 className="font-medium">
            Objetivo:{" "} {totalObjective} 
          </h3>
          <h3 className="font-medium">
            Atingimento{" "}
            <span className="vodafone">{totalProgress.toFixed(2) + "%"}</span>
          </h3>
        </div>
        <div className="g-objectives__progress">
          <RectLoader
            progress={normalizedPercentage}
            expected_progress={normalizedExperctedPercentage}
          />
        </div>
        <div className="g-objectives__label flex justify-between items-center pt-4">
          <span>
            {/*Termina a
            <span className="ml-1">
              {new Momment(end_date).format(t("date-format"))}
            </span> */}
          </span>

          <span>
            Última Atualização
            <span className="ml-1">
              {last_date_updated_sales !== null ? new Momment(last_date_updated_sales).format(t("date-format")) : '-'}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default ObjectiveCard
