import React, { Component } from 'react'
import { withNotificationContainer } from '../normalizer';
import NotificationList from './NotificationList';

class NotificationController  extends Component {
  state = {
    opened : false,
    deleted: 0
  }
  
  handleListShow = (e) => {
    e.stopPropagation()
    this.setState(state => ({
      opened: true,
      deleted: state.deleted +1
    }))
  }

  handleListHide = () => {
    this.setState({
      opened: false
    })
  }

  render() {
    return (
      <NotificationList {...this.props} {...this.state}
        onListShow={this.handleListShow}
        onListHide={this.handleListHide}
      />
    )
  }
}



export default withNotificationContainer(NotificationController)