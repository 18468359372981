import { LevelFlag }from "../../normalizer"
import Locked from "../../../../assets/img/lock.svg"
import React, { Component }from "react"
import { NumberToHumanRange } from "../../normalizer";
class Flag extends Component {
  constructor(props) {
    super(props)
  }

  ChildrenColor = () => {
    const mode = this.props.mode
    if (mode === 'active'){
      return 'navbar-text '
    }
    else if ( mode === 'next-level'){
      return 'primary-text'  
    } else
      return 'text-white'
  }

  generalizeTextAlign = () => {
    switch(this.props.textAlign){
      case 'top':
        return 'top'
      case 'middle':
        return 'middle'
      case 'topmiddle':
        return 'topmiddle'
      default:
        return 'middle'
    }
  }

  canIlustrateGoal = () => {
    const { requirements, mode, miniFlag } = this.props
    if (requirements === undefined){
      return false
    }
    const requirementsTypes = requirements.map((requirement,i) => {
      return requirement.requirement_type
    })
    if (requirements.length !== 1 || mode !== 'next-level' || miniFlag || !requirementsTypes.includes('points')) {
      return false
    }
    return true
  }

  Goal = () => {
    const { requirements } = this.props
    const goals = requirements.map((requirement,i) => {
      return Number(requirement.goal)
    })
    return NumberToHumanRange(goals.reduce((result,goal) => result + goal),0,4) + '  Pontos'
  }
  
  getClasses = () => {
    const {miniFlag, mode, onClick, textAlign } = this.props
    if (miniFlag){
      return {
        flagClasses: `gl-levels__flag-content  gl-levels__flag-content-${this.generalizeTextAlign(textAlign)} text-center text-base`,
        flagContainerClasses: 'gl-levels__flag-container'
      }
    }
    
    const flagContainerClassesList = [
      "gl-levels__flag-container",
      mode !== 'locked' && onClick !== undefined ? "cursor-pointer" : "",
    ]
  
    return {
            flagClasses: `gl-levels__flag-content  gl-levels__flag-content-${this.generalizeTextAlign(textAlign)} text-center text-lg`,
            flagContainerClasses: flagContainerClassesList.join(" ")
           }
  
  }

  handleClick = () => {
    this.props.onClick(this.props.level)
  }
  
  conditionsToClick = () => {
    return this.props.onClick !== undefined && this.props.onClick !== null && this.props.mode !== 'locked'
  }

  render (){
  const height = this.props.height || '50px'
  const classes = this.getClasses()
  const {mode , children} = this.props
  return (
      <div onClick={this.conditionsToClick() ? this.handleClick : undefined} className={classes.flagContainerClasses} style={this.props.style}>
        { this.canIlustrateGoal() && 
          <div className='primary-text gl-levels__flag-requirement' >{this.Goal()}</div>
        }
        <LevelFlag className={`level-${mode}`} height={height} width={height} />
        { mode === 'locked' && 
          <img src={Locked} alt='' className='gl-levels__flag-locked' />
        }
        <div className={classes.flagClasses + ' ' + this.ChildrenColor()}>
          {children}
        </div>
      </div>
    )
  }
}

export default Flag
