import React, { Component } from 'react';
import NewsPreview from './NewsPreview';
import NewsShow from './NewsShow';
import NotFound from '../shared/NotFound'
import {Route, Switch} from 'react-router-dom'
import { withResizeContainer } from '../containers/shared'

class NewsHandler extends Component{

  render() {
    const {
      annoucement,
      campaign,
      onShow,
      gamification,
      imageSize
    } = this.props

    return (
      <div className="col-md-12 col-lg-12 col-xs-12 my-8">
        <Switch>
          <Route exact path ={`/news/${annoucement.id}`}>
            <NewsShow
              imageSize={imageSize}
              annoucement={annoucement}
              campaign={campaign}
              gamification={gamification}
            />
          </Route>
          <Route path="/news">
            <NewsPreview
              annoucement={annoucement}
              campaign={campaign}
              gamification={gamification}
              onShow={onShow}
              imageSize={imageSize}
            />
          </Route>
          <Route component={NotFound}/>
        </Switch>
      </div>

    );

  }
  static defaultProps = {
    showable: false
  }
}

export default withResizeContainer(NewsHandler);
