const initialState = {
  news: [],
  pagination: {},
  selectedAnnouncement: undefined
}

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_NEWS":
      return {
        ...state,
        news: action.payload.news,
        pagination: action.payload.pagination
      }
    case 'SAVE_SELECTED_NEWS':
      return {
        ...state,
        selectedAnnouncement: action.payload.announcement
      }
    default:
      return state
  }
}
export default newsReducer
