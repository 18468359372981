import React from 'react'
import Consent from '../../../shared/Consent'
import CustomButton from '../../shared/CustomButton'


const UserConsents = (props) => {
  const { consents, updatedConsents, confirmEditConsents, onUpdateConsent, onHandleEditConsents, onHandleCancelEditConsents, editConsents } = props
  return (<div className='mb-10'>
          <h1 className='gl-profile__title'>Consentimentos</h1>
          { editConsents ?
            <>
            { updatedConsents.map((consent,i) => {  
                return <Consent {...consent} key={consent.id} formUpdate={onUpdateConsent} />
              })
            }
            <div className='flex justify-end'>
              <CustomButton outline
              onClick={() => onHandleCancelEditConsents()} 
              className='mr-3 '>
                Cancelar
              </CustomButton>
              <CustomButton primary
              onClick={() => confirmEditConsents()} 
              className='ml-3'
              >
                Atualizar
              </ CustomButton>
            </div>
          </>
         : 
          <>
            { updatedConsents.map((consent,i) => {  
                return <Consent disabled {...consent} key={consent.id} formUpdate={onUpdateConsent} />
              })
            }
            <div className='flex justify-end'>
              <CustomButton outline
              onClick={() => onHandleEditConsents()} 
              className=''>
                Editar Consentimentos
              </CustomButton>
            </div>
          </>
          }
        </div>
  )
}

export default UserConsents
