import React from 'react'
import ReactSelect from 'react-select'

const KeyDropdownValue = ({ title, classNameData, classNameTitle, ...rest }) => {

  const tilteClass = classNameTitle !== undefined ? `g-profile__info g-profile__info--editable ${classNameTitle}` : "g-profile__info g-profile__info--editable"
  const dataClass = "w-full g-profile__info g-profile__info--value outline-none g-profile__info-icon "

  return (
    <div className="g-profile__table-cell">
      <div className={tilteClass}>
        <span>{title}</span>
        <i className="g-profile__info-icon far fa-edit"></i>
      </div>
      <ReactSelect
        {...rest}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        className={dataClass}
        classNamePrefix='o-custom-select'
      />
    </div>

  )
}
export default KeyDropdownValue
