import React from "react"
import { Link } from "react-router-dom"
import StyledImage from "../../../shared/StyledImage"
import { Chevron, substring } from "../../normalizer"
  
const PromotionPost = ({content, limitText, imageSize, title}) => {
  let size = 'square_thumbnail_image'
  let normlimitText = limitText
  if  ('square_thumbnail_image' === imageSize){
    normlimitText = 60
  } else if (!['banner_desktop_image','banner_desktop_image'].includes(imageSize)){
    size = 'square_thumbnail_image'
  }

  const croppedImageKey = size.replace('image', 'cropped_image');

  const image = content.image[croppedImageKey] && content.image[croppedImageKey].url
                ? content.image[croppedImageKey].url
                : (content.image[size] ? content.image[size].url : '');
  
  return (
    <Link 
      className="text-color gl-home__promotion" 
      to={"/news/" + content.id}
    >
      <StyledImage url={image} alt="" className="gl-promotion__image" />
      <div className="gl-promotion">
        <label className="gl-post__label primary-text">{title ? title : "Novidade!"}</label>
        <div className="gl-promotion__title">
          <h3 className="gl-post__title gl-post__title--promotion ">
            {content.title}
          </h3> 
        </div>
        <div className="gl-promotion__description">
          <p>
            { substring(content.subtitle,normlimitText, '...') }
          </p>
          <Chevron className="primary-fill primary-fill gl-home__new-chevron" />
        </div>
      </div>
    </Link>
  )
}

export default PromotionPost
