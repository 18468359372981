import React from 'react'
import { prefix } from '../../../i18n'
import { Link } from "react-router-dom";

const company = prefix('company')
const footer = prefix('footer')

export default function Footer() {

  return (
    <footer className="vodafone mish-bg text-color g-footer">
      <div className="row flex-1 center-lg center-md center-xs text-left pt-20 ml-3">
        <div className="col-lg-3 col-md-3 col-xs">
          <p className="text-base g-footer-title pb-5">{company('name')}</p>
          <p className="text-sm incentive-information">{company('address')}</p>
          <p className="text-sm incentive-information">{company('postcode')}</p>
          <p className="text-sm incentive-information">{company('number')}</p>
        </div>
        <div className="col-lg-3 col-md-2 col-xs-12">
          <p className="text-base g-footer-title pb-5">{footer('info')}</p>
          <Link to="/utility-info/rules" className="text-sm info-link link-reset">{footer('rules')}</Link>
          <br/>
          <Link to="/utility-info/regulation" className="text-sm info-link link-reset">{footer('regulation')}</Link>
        </div>
        <div className="col-lg-4 col-md-2 col-xs-12">
          <p className="text-xl contacts-grey">{footer('doubts')}</p>
          <p className="text-2xl contacts secondary mt-auto">{footer('contact-us')}</p>
          <p className="text-3xl contacts secondary">{company('number')}</p>
        </div>
      </div>
      <div className="row center-lg center-md center-xs pb-1 mt-10">
        <hr className="hr-up-catalog"/>
        <div className="m-10 pb-20 ie-11">
          <div className="mb-2">
            <p className="text-xs footer-footer">{company('copyright')}</p>
          </div>
          <Link to="/terms" className="link-reset">
            <p className="text-xs footer-footer">{company('legal-info')} </p>
          </Link>
        </div>
      </div>
    </footer>
  )
}
