import React, { Component } from 'react';
import { UserService } from '../../../services'
import { saveUser } from '../../../actions/userActions';
import { ToastStore } from 'react-toasts';
import { connect } from 'react-redux';
import { isEmpty } from '../../../utils/utils'
import { startFetch, endFetch} from '../../../actions/generalActions';
import {prefix} from '../../../i18n'

const tPW= prefix('password-form')
class UserPassForm extends Component{

  constructor(props){
    super(props);
    this.state = { 
      user: {}, 
      errors: {}
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  handleInputChange(event) {
    let user = this.state.user
    
    const target = event.target;
    const value =  target.value;
    const name = target.name;

    value === '' ? delete user[name] : user[name] = value
    
    this.setState({
      user: user
    });
  }

  getStyle(){
    const color_primary = this.props.campaign.color_primary
    return {
      color: color_primary, 
      borderBottom: `1px solid ${color_primary}`
    }
  }

  savePassword(e) {
    e.preventDefault();

    const payload = {user: this.state.user};
    if (isEmpty(payload.user)){
      this.props.onClick()
      return;
    }
    this.props.startFetch();
    UserService.updatePassword(payload)
      .then((response) => {
        ToastStore.success('Password Atualizada')
        this.props.endFetch();
        this.props.onClick()
      })
      .catch((error) => {
        this.setState({errors: error.response.data})
        this.props.endFetch();
      });
  };

  render(){
    const error = this.state.errors
    const {
      color_primary,
      text_color
    } = this.props.campaign

    return(
      <div>
        <div className='flex template-container between-xs sm:flex-turn sm:flex-turn--a-center sm:flex-turn--j-center'>
          <div>
            <h2 style={{color: color_primary}} className='template-title'>{tPW('pw')}</h2>
            <hr style={{backgroundColor: text_color}} className="hr-down-left"></hr>
          </div>
          
          <button  onClick={(e) => this.savePassword(e) } value='Submit' className='btn-secondary user-button btn-secondary user-button px-4 py-1'>{tPW('save')}</button>
          
        </div>
        <div  className='flex between-xs sm:flex-turn sm:flex-turn--j-center'>
          <form className=' sm:w-full flex flex-col template-block between-xs' id='edit-password'>
            <div>
              <input style={this.getStyle()} className='w-full template-pass' onChange={this.handleInputChange} 
                name='current_password' type='password'  placeholder='Senha antiga'></input>
              {error.current_password ? <p className='w-full template-errors'>{tPW('old-pw')} {error.current_password.join(',')}</p> : null}
            </div>
            <div>
              <input style={this.getStyle()} className='w-full template-pass pt-6' onChange={this.handleInputChange}
                name='password' type='password'  placeholder='Nova senha'></input>
              {error.password ? <p className='w-full template-errors'>{tPW('new-pw')} {error.password.join(',')}</p> : null}
            </div>
            <div>
              <input style={this.getStyle()} className='w-full template-pass pt-6' onChange={this.handleInputChange}
                name='password_confirmation' type='password'  placeholder='Repetir Nova senha'></input>
              {error.password_confirmation ? <p className='w-full template-errors'>{tPW('new-pw')} {error.password_confirmation.join(',')}</p> : null}
            </div>
          </form>
          <div className='my-auto sm:w-full px-2'>
            <div className='template-warning'>
              <h4>{tPW('warning')}</h4>
            </div>
            <div className='template-passwordinfo'>
              <h5 className='template-password-title'>{tPW('criteria')}</h5>
              <ul className='template-password-requirements'>
                <li>{tPW('special-character')}</li>
                <li>{tPW('one-upcased')}</li>
                <li>{tPW('one-lowercased')}</li>
                <li>{tPW('one-number')}</li>
                <li>{tPW('eight-characters')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(null,mapDispatchToProps)(UserPassForm);