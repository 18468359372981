import React from "react";

const ColumnHeader = ({title, show_title, color}) => {
  return (
    <div className="flex justify-center items-center w-full h-12" >
      {
        !!show_title && <h2 className="font-bold" style={{ color }}>{title}</h2>
      }
      
    </div>
  )
}

export default React.memo(ColumnHeader);
