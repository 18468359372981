import React from "react"
import { NavLink as Link } from "react-router-dom"
import {formatNumber, Avatar, t, CampaignUserContainer, withContainers} from "../../../normalizer"
import Moment from "moment"
import LevelUserStatus from "../../../Home/LevelPost/LevelUserStatus";
import PointBlockStatus from "../../../Home/LevelPost/PointBlockStatus";

function currentStatusLoader(toRender){
    if(toRender("Bloqueio de Pontos")){
        return PointBlockStatus
    }else if(toRender('Gamification-Niveis')){
        return LevelUserStatus
    }
}
const Status = ({ user, campaignUser, client, toRender}) => {

  const { first_name: firstName, last_name: lastName } = user

  const { availablePoints, avatar: campaignAvatar = {} } = campaignUser
  const { currency_label: currencyLabel } = client
  let avatar = undefined;
  if (campaignAvatar && campaignAvatar.url) {
    avatar = campaignAvatar.url
  }
  const TargetStatusLoader = currentStatusLoader(toRender)
  const normalizedLastName = lastName !== null ? lastName : ''
  const normalizedfirstName = firstName !== null ? firstName : ''
  const splitFirstName = normalizedfirstName.split(' ')
  const splitLastName = normalizedLastName.split(' ')
  let minFirstName =  splitFirstName[0]
  if (splitFirstName.length > 1){
    minFirstName = splitFirstName[0] + ' ' + splitFirstName[splitFirstName.length - 1]
  }
  const strippedFullName = minFirstName + ' ' + splitLastName[splitLastName.length - 1]
  return (
      <>
        <div className="gl-user__info">
          <Link to="/profile" className="gl-user__link">
            <img src={avatar || Avatar} alt="user" className="gl-user-avatar" />
          </Link>
          <div className="gl-user-data">
            <p className="text-left gl-user-name ">
              {strippedFullName}
            </p>
            <div className="flex items-baseline">
              <p className="gl-user-points__value ">
                {formatNumber(availablePoints)}
              </p>
              <p className="text-sm">{currencyLabel}</p>
              <p className="font-bold mx-1">|</p>
              <p>a {new Moment().format(t("date-format"))}</p>
            </div>
          </div>
        </div>
          {TargetStatusLoader &&
          <TargetStatusLoader/>}

      </>
  )
}

export default withContainers(Status,[CampaignUserContainer])
