import React from 'react'
import Moment from 'moment'
import { t } from '../../../../i18n';
import { formatNumber } from '../../normalizer';
import Pagination from '../../shared/Pagination';

const Row = ({id, number, checkout_date, total_points : totalPoints, status, received_by_user : receivedByUser, order, onOrderShow, getOrderPaymentInfo, onUserClickReceived}) => {

    const normalizedStatus = status.toLowerCase();
    let color = 'processing'
    let disabled = false
  
    switch(normalizedStatus){
  
      case 'enviado':
        color = 'sent';
        break
      case 'entregue':
        color = 'delivered';
        disabled = true;
        break
      default:
        color = 'processing';
        disabled = true;
    }
  
    return (
      <tr className="g-profile__info-row-hover" onClick={() => onOrderShow(id)} >
        <td className="p-3">{number}</td>
        <td className="p-3 whitespace-no-wrap">{new Moment(checkout_date).format(t('date-format'))}</td>
        <td className='p-3 text-left'>{formatNumber(totalPoints)}</td>
        <td className='p-3 text-center'><span className={`g-profile__order-status g-profile__order-status--${color} m-3 p-2`} disabled>{status}</span></td>
        <td className='text-base text-center sm:no-display'>{getOrderPaymentInfo(order)}</td>
        <td align="center" className='g-input__checkbox-container'><input disabled={disabled} className="g-profile__checkbox-container__checkbox-input" type="checkbox" checked={receivedByUser}/></td>
      </tr>
    )
}

const OrdersTable = (props) => {
    const {
      currencyLabel,
      orders,
      pagination,
      page,
      onPageChange,
      onReceivedChange,
      onOrderShow,
      onUserClickReceived,
      getOrderPaymentInfo,
    } = props

  return (
    <section className="w-full">
      <div className="overflow-x-auto">
        <table className={"mb-10 w-full"}>
          <thead className="navbar-text g-profile__table">
            <tr className="g-profile__info-row">
              <th className="p-3 text-left py-3">Nº encomenda</th>
              <th className="p-3 whitespace-no-wrap text-left">Data</th>
              <th className="p-3 text-left">{currencyLabel}</th>
              <th className="p-3 text-center">Estado</th>
              <th className="p-3 text-center">Informação</th>
              <th className="p-3 text-center">Recebida?</th>
            </tr>
          </thead>
          <tbody className="dead-grey">
            {orders.map((o) => (
              <Row
                {...o}
                order={o}
                key={o.id}
                onReceivedChange={onReceivedChange}
                onOrderShow={onOrderShow}
                onUserClickReceived={onUserClickReceived}
                getOrderPaymentInfo={getOrderPaymentInfo}
              />
            ))}
          </tbody>
        </table>
      </div>
      {pagination.total_pages > 1 && (
        <div className="flex justify-end mt-10">
          <Pagination
            totalPages={pagination.total_pages}
            currentPage={page}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </section>
  );
}

export default OrdersTable
