export function saveOrders(state){
  return {
    type: 'SAVE_ORDERS',
    payload: state
  }
};
export function saveSelectedOrder(state){
  return {
    type: 'SAVE_SELECTED_ORDER',
    payload: state
  }
};
