
import React from 'react'
import Product from './Product';
import Pagination from './Pagination';
import { formatNumber } from '../../../utils/utils';

const TopPagination = ({pagination, onPageChange,currentPage}) => {
  return (
    <div className="flex flex-wrap justify-between items-center my-5 px-2 primary-text">
      <div className="text-sm mb-3">
        <p>{formatNumber(pagination.records)} Prémios disponíveis</p>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={pagination.total_pages}
        onPageChange={onPageChange}
      />
    </div>
  )
}

export default function ProductCatalog(props) {
  const {
    currencyLabel,
    products,
    header,
    pagination = {},
    currentPage,
    onPageChange
  } = props

  return (
    <>
      {header}
      { pagination.total_pages > 1  &&
          <TopPagination
            pagination={pagination}
            currentPage={currentPage}
            onPageChange={onPageChange}/>
      }
      <div className="row -mx-bs">
        {
          products.map((p,i)=> (
            <Product
              key={`p-id-${p.id}-${i}`}
              currencyLabel={currencyLabel}
              {...p}
            />

          ))
        }
      </div>
      { pagination.total_pages > 1  &&
        <div className="flex justify-end mt-10">
          <Pagination
            totalPages={pagination.total_pages}
            currentPage={currentPage}
            onPageChange={onPageChange}/>
        </div>
      }
    </>
  )
}
