import React from "react";
import PageLine from "./PageLine";
import Tabs from './Tabs'

const PageContainer = (props) => {
  // const { json } = props
  // const { rows } = json
  const rows = props && props.content_blocks
  // const rows = json && json.content_blocks
  const structure = props && props.structure

  if (structure && structure === 'tabbed') {
    return  (
      <div className="px-10 py-5 w-full bg-grey-lighter">
        {rows && rows.length > 0 && <Tabs rows={rows}/> }
      </div>
    )
  }

  return  (
    <div className="px-10 py-5 w-full bg-grey-lighter">
      {rows && rows.length > 0 &&
        rows.map((row, key) => (
          <PageLine key={key} {...row} />
        ))
      }
    </div>
  )
}

export default React.memo(PageContainer);
