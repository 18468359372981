import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({totalPages,currentPage, onPageChange})=> {
  return (
    <ReactPaginate
      className='outline-none'
      previousLabel='<'
      nextLabel='>'
      breakLabel='...'
      breakClassName='break-me g-active-page'
      pageCount={totalPages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={1}
      forcePage={currentPage -1}
      onPageChange={onPageChange}
      containerClassName='g-pagination justify-start center-xs g-active-page start-sm list-reset navbar-text'
      pageClassName='g-pagination__page g-active-page transparent'
      activeClassName='primary-bg g-active-page text-white'
    />
  )
}

export default Pagination
