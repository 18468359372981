import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUser } from '../../../actions/userActions';
import { UserService } from '../../../services'
import { ToastStore } from 'react-toasts';
import { capitalize, isEmpty } from '../../../utils/utils'
import { startFetch, endFetch} from '../../../actions/generalActions';

class UserInfoForm extends Component{

  constructor(props){
    super(props);
    this.state =  { 
      user: {}, 
      errors: {} 
    }
    
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    let user = this.state.user
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    user[name] = value
    this.setState({
      user: user
    });
  }

  getStyle(){
    const color_primary = this.props.campaign.color_primary
    return {
      color: color_primary, 
      borderBottom: `1px solid ${color_primary}`
    }
  }
  saveUser(e){
    e.preventDefault();
    const payload = {user: this.state.user};
    if (isEmpty(payload.user)){
      this.props.onClick()
      return;
    }
    this.props.startFetch()
    UserService.updateUser(payload)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.props.saveUser(response.data.user);
        ToastStore.success('Utilizador Atualizado')
        this.props.endFetch()
        this.props.onClick()
      })
      .catch((error) => {
        this.setState({errors: error.response.data})
        this.props.endFetch()
      });
  };  

  render(){
    const {
      user,
      campaign
    } = this.props
    const error = this.state.errors
    const birthday = new Date(user.birthday).toISOString().substr(0,10);
    return(
      <div>
        <div className='flex template-container between-xs'>
          <div>
            <h2 style={{ color: campaign.color_primary }} className='template-title'>INFORMAÇÃO PESSOAL</h2>
            <hr style={{ backgroundColor: campaign.text_color }}className="hr-down-left"></hr>
          </div>
          <div style={{padding:'0 15px'}}>          
            <button className="btn-secondary user-button" onClick={(e) => this.saveUser(e) } value='Submit'>Guardar</button> 
          </div>
        </div>
        <form id='edit-user' >
          <div className='flex template-block between-xs'>
            <input style={this.getStyle()} className="w46 template-blue"  onChange={this.handleInputChange}
            placeholder={user.first_name ? capitalize(user.first_name) : 'Nome'} type="text" name='first_name'></input>
            <input style={this.getStyle()} className="w46 template-blue" onChange={this.handleInputChange}
             placeholder={user.last_name ? user.last_name : 'Apelido'} type="text" name='last_name'></input>
          </div>
          <div className='flex template-block between-xs'>
            <div className='w46'>
              <input style={this.getStyle()} className='w-full template-blue' onChange={this.handleInputChange}
              placeholder={user.phone ? 'Telefone: ' + user.phone : 'Sem Telefone'} type='text' name='phone'></input>
              {error.phone ? <p className='w-full template-errors'>Telemovel: {error.phone.join(',')}</p> : null}
            </div>
            <div className='w46'>
              <input style={this.getStyle()} className='w-full template-blue' onChange={this.handleInputChange}
              placeholder={user.nif ? 'NIF: ' + user.nif : 'Sem NIF'}  type='text' name='nif'></input>
              {error.nif ? <p className='w-full template-errors'>NIF: {error.nif.join(',')}</p> : null}
            </div>
          </div>
          <div className='flex template-block between-xs'>
            <input style={this.getStyle()} className='w46 template-blue' onChange={this.handleInputChange} placeholder={'Email: ' + user.email} type='email' name='email'></input>
            <input style={this.getStyle()}  defaultValue={birthday} onChange={this.handleInputChange} className='w46 template-blue' type='date' name='birthday'></input>
          </div>
        </form>
      </div>
    )
  }
}
function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}
export default connect(null,mapDispatchToProps)(UserInfoForm);