import React, { Component } from "react"
import { matchPath, withRouter } from "react-router-dom"

class Dropdown extends Component {
  state = {
    active: false
  }

  isActive = () =>
    this.props.activeUrls.find(url =>
      matchPath(this.props.history.location.pathname, {
        path: url
      })
    )

  render() {
    const { message, children, boldIt, key, noArrow } = this.props

    const classes = [
      "dropbtn hover-secondary navbar-text cursor-pointer",
      this.isActive() ? "secondary-bg" : "primary-bg",
      boldIt ? "font-quicksand" : "font-quicksand-light"
    ].join(" ")
    const showArrow = !noArrow

    const elements = React.Children.toArray(children).map((e, i) =>
      React.cloneElement(e, {
        fromDropdown: true,
        key: "from-dropelem" + i + key
      })
    )

    return (
      <div className="dropdown">
        <div className={classes}>
          {message}
          {showArrow && <i className="fas fa-angle-down dropdownIcon" />}
        </div>
        <div className="dropdown-content width-available">{elements}</div>
      </div>
    )
  }
}

export default withRouter(Dropdown)
