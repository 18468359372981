import React from "react"
import { withGroupSalesContainer, SearchIcon } from "../../normalizer"

import Pagination from "../../shared/Pagination"
import SalesFilter from "./SalesFilter"
import SalesTable from "./SalesTable"
import EmptyStateLayout from "../../shared/EmptyStateLayout"

const Sales = props => {
  const {
    sales,
    pagination,
    onPageChange,
    onSaleFilter,
    filtered,
    page
  } = props

  const noSales = sales.length === 0
  const noSalesWhileFiltering = filtered && noSales
  const noSalesWhileNotFiltering = !filtered && noSales

  const EmptyStateContent = (
    <div className="w-full flex items-center flex-col">
      <div className="gl-objectives__line mt-5 mb-10 w-full" />
      <SearchIcon className="mb-10" />
    </div>
  )
  return (
    <div className="w-full text-size">
      {noSalesWhileNotFiltering ? (
        <EmptyStateLayout message="Ainda não existem vendas registadas para esta loja.">
          {EmptyStateContent}
        </EmptyStateLayout>
      ) : (
        <SalesFilter onSaleFilter={onSaleFilter} />
      )}

      {noSalesWhileFiltering ? (
        <EmptyStateLayout message="Não existem resultados com critérios de pesquisa introduzidos.">
          {EmptyStateContent}
          <h1 className="mb-5 normal-case">Sem resultados!</h1>
        </EmptyStateLayout>
      ) : (
        sales.length > 0 && <SalesTable sales={sales} />
      )}

      {pagination.total_pages > 1 && (
        <div className="flex justify-between mt-10">
          <p className="font-light lowercase dead-grey mb-5">
            {pagination.records || 0} vendas efetuadas
          </p>
          <Pagination
            totalPages={pagination.total_pages}
            currentPage={page}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  )
}

export default withGroupSalesContainer(Sales)
