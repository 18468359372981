import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TermsAndConditionsModal from "./TermsAndConditionsModal"
import {t, prefix} from '../../../i18n'

const TermsAndConditionsForm = ({termsAccepted,onTermsCheckChange,onShowTerms}) => {
  const tRegistry = prefix('registry')
  const company = prefix('company')

  const handleTermsCheckChange = e => {
    e.preventDefault()
  }

  const handleShowTerms = e => {
    e.preventDefault()
    onShowTerms()
  }

  return (
    <>
      <div>
        <h2 className='py-8 template-title'>{tRegistry('terms-and-conditions-title')}</h2>
      </div>
      <div className='template-block'>
        <input className="gl-profile__checkbox-container__checkbox-input" type="checkbox" value="1"
          checked={termsAccepted}
          onChange={handleTermsCheckChange}
        /> {tRegistry('terms-and-conditions-accept')}
      </div>
      <div className='template-block'>
        <Link to ="#" onClick={handleShowTerms} className="col-lg-9 col-md col-xs link-reset">{tRegistry('terms-and_conditions-details-and-acceptance-options')}</Link>
      </div>
    </>
  )
}

export default TermsAndConditionsForm 
