import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { saveUser, saveLogIn, saveClient } from "../../../actions/userActions"
import { startFetch, endFetch } from "../../../actions/generalActions"
import { UserService } from "../../../services"
import { ToastContainer, ToastStore } from "react-toasts"
import BackgroundWrapper from "../client/BackgroundWrapper"
import ClientLogo from "../client/Logo"

import DefaultLogin from "../../DefaultTemplate/Login/index"
import GamificationLogin from "../../GamificationTemplate/Login/index"

const TemplateLogIn = props => {
  const { client } = props
  switch (client.slug) {
    case "vodafone":
      return <GamificationLogin {...props} />
    default:
      return <DefaultLogin {...props} />
  }
}

class LogIn extends Component {

  state = {
    image: this.props.client.background_banner.url
  }

  resize = () => {
    const mobileFriendly = window.innerWidth < 1200
    let image = this.props.client.background_banner.url
    if (mobileFriendly){
      image = this.props.client.mobile_background.url || image
    }
    this.setState({image: image})
  }

  componentDidMount() {
    this.resize()
    window.addEventListener("resize", this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  
  login(e) {
    e.preventDefault()
    const { client } = this.props
    // console.log('Client => ', client)
    this.props.startFetch()
    const payload = {
      client_slug: client.slug,
      user: {
        username: e.target.email.value,
        password: e.target.password.value
      }
    }
    UserService.doLogin(payload)
      .then(response => {
        const user = response.data
        localStorage.setItem("user", JSON.stringify(user))
        this.props.saveUser(user)
        localStorage.setItem("client", client.slug)
        if (response.headers.authorization) {
          localStorage.setItem("jwt", response.headers.authorization)
          this.props.saveLogIn(localStorage.getItem("jwt"))
        }
        this.props.endFetch()
        if (client.validate_data_enabled && !user.confirm_data) {
          this.props.history.push(`/validate_data`)
        } else {
          this.props.history.push(`/campaign`)
        }
      })
      .catch(error => {
        this.props.endFetch()
        switch (error.response.status) {
          case 401:
            ToastStore.error("Password incorreta")
            break
          case 404:
            ToastStore.error("Utilizador não encontrado")
            break
          default:
            ToastStore.error("Something went wrong")
        }
      })
  }

  render() {
    const { client } = this.props
    const image = this.state.image
    const forgetPw = `/password-recovery`
    return (
      <BackgroundWrapper backgroundImage={image}>
        <ToastContainer
          store={ToastStore}
          position={ToastContainer.POSITION.TOP_CENTER}
          lightBackground
        />
        <TemplateLogIn
          client={client}
          login={e => this.login(e)}
          forgetPw={forgetPw}
          client_logo={ClientLogo}
        />
      </BackgroundWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    client: state.userReducer.client
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveUser: user => {
      dispatch(saveUser(user))
    },
    saveClient: client => {
      dispatch(saveClient(client))
    },
    saveLogIn: state => {
      dispatch(saveLogIn(state))
    },
    startFetch: () => {
      dispatch(startFetch())
    },
    endFetch: () => {
      dispatch(endFetch())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogIn)
