import axios from './interceptor';

export const PopUpService = {

  getUnseenPopup({clientSlug,campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/popups`;
    return axios({
      method: 'get',
      url: url
    });
  },
  markAsSeen({clientSlug,campaignSlug, id}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/popups/${id}`;
    return axios({
      method: 'put',
      url: url
    });
  }
};
