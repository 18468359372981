import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import { AcceleratorsService } from '../../../services/index';
import { startFetch, endFetch } from '../../../actions/generalActions';
import AcceleratorShow from './AcceleratorShow';
import BannerSlider from '../shared/BannerSlider'
import ContentLayout from '../shared/ContentLayout';
import { withResizeContainer } from '../normalizer'
import {prefix} from '../../../i18n'

const tAccelerators= prefix('accelerators')
class Accelerators extends Component{
  constructor(props){
    super(props);
    this.state={accelerators: [] };
  }

  getAccelerators(){
    const { campaign, user} = this.props

    const payload = { client_slug: user.client.slug, campaign_slug: campaign.slug}
    this.props.startFetch();

    AcceleratorsService.getAccelerators(payload)
      .then((response) => {
          this.setState({accelerators: response.data.accelerators})
          this.props.endFetch();
      })

  }

  componentDidMount(){
    if ( this.state.accelerators.length === 0 ) {
      this.getAccelerators()
    }
  }
  componentDidUpdate(oldProps) {
    if(oldProps.campaign.slug !== this.props.campaign.slug) {
      this.getAccelerators()
    }
  }
  getAcceleratorsBanners(){
    const banners = []
    const { accelerators } = this.state
    const imageSize = this.props.imageSize
    for(var i=0; i < this.state.accelerators.length; i++){
      banners.push(
        {
          id: i, 
          src: accelerators[i].full_image[imageSize] ? accelerators[i].full_image[imageSize].url : '', 
          text: accelerators[i].banner_text 
        })
    }
    return banners;
  }

  render() {
    const { campaign, imageSize } = this.props
    const { accelerators } = this.state
    const banners = this.getAcceleratorsBanners()
    return (
      <div>
        <div className='font-quicksand' style={{backgroundColor: '#eaeaef'}}>
          <div className="row center-lg center-xs center-md slider">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <BannerSlider data={banners} backgroundColor = {campaign.color_primary} borderColor = { campaign.color_secondary } />
            </div>
          </div>
          <ContentLayout title={tAccelerators('title')} campaign={campaign}>
            {accelerators.map((accelerator)=>(
              <div className='col-lg-12 col-md-12 col-xs-12 my-8 '>
              <AcceleratorShow
                key={accelerator.id}
                accelerator={accelerator}
                campaign={campaign}
                imageSize={imageSize}
                />
              </div>
            ))}
          </ContentLayout>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return{
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}
const AcceleratorsComponent = connect(mapStateToProps,mapDispatchToProps)(Accelerators)

export default withResizeContainer(AcceleratorsComponent);
