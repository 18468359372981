import React from "react"
import NavLink from "../shared/NavigationLink"

const ProfileNavigation = () => {
  return (
    <nav className="flex g-profile__title  mb-5 vodafone navbar-text">
      <NavLink to="/profile" exact>
        Dados pessoais
      </NavLink>
      <NavLink to="/profile/balance">Pontos e extrato</NavLink>
      <NavLink to="/profile/orders">Encomendas</NavLink>
    </nav>
  )
}

const ProfileLayout = props => {
  return (
    <div className="my-10 flex flex-col">
      <ProfileNavigation />
      {props.children}
    </div>
  )
}

export default ProfileLayout
