import axios from "./interceptor"

export const FeedService = {
  getFeed({ clientSlug, campaignSlug, page }) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/feeds`
    return axios({
      method: "get",
      url: url,
      params: {
        page: page
      }
    })
  },
  getSelectedFeed({clientSlug, campaignSlug, contentType, contentId}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/feeds/${contentId}`
    return axios({
      method: "get",
      url: url,
      params: {
        content_type: contentType
      }
    })
  }
}
