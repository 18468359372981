import React, { Component } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Home from "./Home"
import Profile from "./Profile"
import ShoppingCart from "./ShoppingCart"
import CartAddress from "./ShoppingCart/CartAddress"
import News from "./News"
import Page from "./Page"
import Catalog from "./Catalog"
import CartCheckout from "./ShoppingCart/CartCheckout"
import CartSuccess from "./ShoppingCart/CartSuccess"
import WishList from "./WishList"
import Rankings from "./Rankings"
import Accelerators from "./Accelerators"
import NotFound from "./shared/NotFound"
import SupportingDocuments from "./SupportingDocuments"
import UtilityInfo from "./UtilityInfo"
import LoggedinTerms from "../shared/TermsOfService"
import Footer from "./shared/Footer"
import Header from "./shared/Header"
import InvoiceContainer from "./Invoice"
import "../../assets/css/default/index.scss"
import ClassBuilder from "../shared/client/ClassBuilder"
import { CampaignService } from "../../services"
import CustomRoute from "../../routes/CustomRoute"
import SaleAttribution from "./SaleAttribution"
import SaleTrade from "./SaleTrade"

class DefaultTemplate extends Component {
  state = {
    features: [],
    requested: false,
  }

  componentDidMount() {
    const { slug, campaign } = this.props
    this.fetchFeatures(slug, campaign.slug)
  }

  fetchFeatures(clientSlug, campaignSlug) {
    CampaignService.getFeatures({ clientSlug, campaignSlug })
      .then((response) => {
        const { features } = response.data
        this.setState({ features: features, requested: true })
      })
      .catch((e) => {
        this.setState({ requested: true })
      })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.campaign !== this.props.campaign) {
      const { slug, campaign } = this.props
      this.fetchFeatures(slug, campaign.slug)
    }
  }

  toRender = (route) => {
    return this.state.features.find(
      (f) => f.name.toLowerCase() === route.toLowerCase()
    )
  }

  render() {
    const { slug, campaign, campaigns, campaignUser } = this.props
    const { requested, features } = this.state
    if (!requested) return null

    if (
      campaignUser.has_pending_sale_attributions &&
      !campaignUser.has_seen_pending_sale_attributions
    ) {
      campaignUser.has_seen_pending_sale_attributions = true
      //alert("pending sales attibutions")
    }

    return (
      <ClassBuilder campaign={campaign}>
        <Header
          campaigns={campaigns}
          features={features}
          toRender={this.toRender}
        />
        <Switch>
          <Route path="/" exact>
            <Home features={features} toRender={this.toRender} />
          </Route>
          <Route path="/profile">
            <Profile toRender={this.toRender} features={features} />
          </Route>
          <Route path="/catalog" component={Catalog} />
          <Route path="/wishlist" exact component={WishList} />
          <Route path="/cart" exact component={ShoppingCart} />
          <Route path="/cart_address" exact component={CartAddress} />
          <Route path="/cart_checkout" exact component={CartCheckout} />
          <Route path="/cart_sucess" exact component={CartSuccess} />
          <Route path="/utility-info" component={UtilityInfo} />
          <Route path="/terms" component={LoggedinTerms} />
          {this.toRender("Atribuição de Contratos") && (
            <CustomRoute
              path="/sale-attributions"
              component={SaleAttribution}
              campaign={campaign}
              campaignUser={campaignUser}
            />
          )}
          {this.toRender("Troca de Vendas") && (
            <CustomRoute
              path="/sale-trading"
              component={SaleTrade}
              campaign={campaign}
            />
          )}
          {this.toRender("Rankings") && (
            <Route path="/rankings" component={Rankings} />
          )}
          {this.toRender("Accelerators") && (
            <Route path="/accelerators" exact component={Accelerators} />
          )}
          {this.toRender("News") && <Route path="/news" component={News} />}
          {this.toRender("Pages") && (
            <Route path="/page/:slug" component={Page} />
          )}
          {this.toRender("SupportDocuments") && (
            <Route
              path="/supporting-documents/:slug"
              component={SupportingDocuments}
            />
          )}
          {this.toRender("Invoice") && (
            <Route path="/invoice" component={InvoiceContainer} />
          )}
          <CustomRoute
            path="/sale-trading"
            component={SaleTrade}
            campaign={campaign}
          />
          {campaignUser.canSeeSaleAttributions && (
            <CustomRoute
              path="/sale-attributions"
              component={SaleAttribution}
              campaign={campaign}
            />
          )}
          <Redirect
            from={`/${slug}/profile/orders/:number`}
            to="/profile/orders/:number"
          />
          <Redirect from={`/${slug}/profile/orders`} to="/profile/orders" />
          <Redirect from={`/${slug}/profile/balance`} to="/profile/balance" />
          <Redirect from={`/${slug}/profile`} to="/profile" />
          <Redirect
            from={`/${slug}/catalog/product/:id`}
            to="/catalog/product/:id"
          />
          <Redirect from={`/${slug}/catalog`} to="/catalog" />
          <Redirect from={`/${slug}/rankings`} to="/rankings" />
          <Redirect from={`/${slug}/accelerators`} to="/accelerators" />
          <Redirect from={`/${slug}/wishlist`} to="/wishlist" />
          <Redirect from={`/${slug}/cart`} to="/cart" />
          <Redirect from={`/${slug}/cart_address`} to="/cart_address" />
          <Redirect from={`/${slug}/cart_success`} to="/cart_success" />
          <Redirect from={`/${slug}/news`} to="/news" />
          <Redirect from={`/${slug}/page/:slug`} to="/page/:slug" />
          <Redirect
            from={`/${slug}/supporting-documents/:slug`}
            to="/supporting-documents/:slug"
          />
          <Redirect
            from={`/${slug}/utility-info/:slug`}
            to="/utility-info/:slug"
          />
          <Redirect from={`/${slug}`} to="/" />
          <Route component={NotFound} />
        </Switch>
        <Footer features={features} toRender={this.toRender} />
      </ClassBuilder>
    )
  }
}

export default DefaultTemplate
