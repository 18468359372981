
const initialState = {
  registredInvoices: [],
  verifiedInvoices: [],
  unapprovedInvoices: [],
  createdInvoices: [],
  saleTypes: [],
  reprovalReasons: [],
  brands: []
}

const invoiceReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SAVE_REGISTRED_INVOICES':
      return {
        ...state,
        registredInvoices: action.payload
      }
    case 'SAVE_VERIFIED_INVOICES':
      return {
        ...state,
        verifiedInvoices: action.payload
      }
    case 'SAVE_UNAPPROVED_INVOICES':
      return {
        ...state,
        unapprovedInvoices: action.payload
      }
    case 'SAVE_CREATED_INVOICES':
      return {
        ...state,
        createdInvoices: action.payload
      }
    case 'CREATE_INVOICE':
      state.createdInvoices.push(action.payload)
      return {
        ...state
      }
    case 'SAVE_INVOICE_SALE_TYPES':
      return {
        ...state,
        saleTypes: action.payload
      }
    case 'SAVE_INVOICE_REPROVAL_REASONS':
      return {
        ...state,
        reprovalReasons: action.payload
      }
    case 'SAVE_INVOICE_BRANDS':
      return {
        ...state,
        brands: action.payload
      }
    case 'VALIDATE_INVOICE':
      const validatedInvoice = action.payload
      
      state.verifiedInvoices.push(validatedInvoice)
      return {
        ...state,
        unapprovedInvoices: state.unapprovedInvoices
          .filter(i => i.id !== validatedInvoice.id ), 
      }
    case 'SUBMIT_TO_VALIDATION':
      const sendToAproveInvoice = action.payload
      state.unapprovedInvoices.push(sendToAproveInvoice)
      return {
        ...state,
        createdInvoices: state.createdInvoices
          .filter(i => i.id !== sendToAproveInvoice.id)
      }
    case 'UPDATE_CREATED_INVOICE':
      const updatedInvoice = action.payload
      const invoices = state.createdInvoices
                        .filter(i => i.id !== updatedInvoice.id)
      invoices.push(updatedInvoice)
      return {
        ...state,
        createdInvoices: invoices
      }
    default: 
      return state
  }

}

export default invoiceReducer
