import React, { Component } from "react"
import { Link } from "react-router-dom"
import { AnalyticsIcon, LogoutIcon, MoneyBagIcon, WebPageIcon, substring } from "../../../normalizer"

const DropdownItem = props => {
  const { title, className, icon: Icon, ...rest } = props
  const classes = [
    "link-reset gl-navbar__dropdown-item drop-down-item flex items-center ",
    className
  ].join(" ")

  return (
    <Link {...rest} className={classes}>
      <Icon className="gl-navbar__icons16 mr-3" />
      <p>{title}</p>
    </Link>
  )
}

const LogoutButton = ({ className, onLogout }) => {
  const classes = [
    "gl-navbar__dropdown-item flex items-center flex dropdown-item ",
    className
  ].join(" ")

  return (
    <span className={classes} onClick={onLogout}>
      <LogoutIcon className="gl-navbar__icons16 mr-3" />
      <p>Logout</p>
    </span>
  )
}

export default class Dropdown extends Component {
  state = {
    viewMore: false
  }

  onViewMore = () => {
    this.setState({ viewMore: true })
  }

  onViewLess = () => {
    this.setState({ viewMore: false })
  }

  handleClick = e => {
    const isContainer = !!this.node.contains(e.target)
    if (!isContainer && this.state.viewMore) {
      this.onViewLess()
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  render() {
    const { viewMore } = this.state

    const dropdownClass = [
      viewMore && "gl-navbar__dropdown gl-content-shadow dropdown-item",
      !viewMore && "hidden"
    ].join(" ")
    return (
      <span
        className="cursor-pointer gl-notification__container gl-mobile-hide dropdown-item"
        onClick={viewMore ? this.onViewLess : this.onViewMore}
      >
        <i className="fas fa-ellipsis-h gl-navbar__icons20 mb-3 mt-2 header-text" />
        <div className={dropdownClass} ref={ref => (this.node = ref)}>
          { this.props.campaignUser.canSeeAnalytics &&
            <DropdownItem
              to="/analytics"
              title="Dashboard"
              icon={AnalyticsIcon}
            />
          }

          { this.props.toRender('Pontos Bonus') && this.props.campaignUser.canGiveBonusPoints &&
            <DropdownItem
              to="/bonus_points"
              title="Pontos a distribuir"
              icon={MoneyBagIcon}
            />
          }
          {
            this.props.toRender('Troca de Vendas') &&
            <DropdownItem
              to="/sale-trading/requested"
              title="Troca de contratos"
              icon={WebPageIcon}
            />
          }
          {
             this.props.toRender("Atribuição de Contratos") && this.props.campaignUser.canSeeSaleAttributions &&
             <DropdownItem
               to="/sale-attributions/pending"
               title="Atribuição de contratos"
               icon={WebPageIcon}
             />
          }
          <LogoutButton onLogout={this.props.onLogout} />
        </div>
      </span>
    )
  }
}
