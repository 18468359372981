import React, { Component } from "react";
import { connect } from 'react-redux';
import { TemplateContainer, ResizeContainer, BannerContainer, ClientContainer, HomeContainer, PostContainer, LevelContainer, withContainers } from "../normalizer";
import NewsFeed from "./NewsFeed";
import PostFeed from "./PostFeed";
import LevelProductList from "./LevelPost/LevelProductList";
import LevelPost from "./LevelPost";
import RankingSlider from "../shared/Header/User/RankingSlider";
import PointBlocks from "../shared/Header/User/PointBlocks";
import Objectives from "../shared/Header/User/Objectives";

import {
  saveLevels,
  selectLevel,
  saveLevelProducts,
  saveRankings,
  saveWishes,
  saveHighlights,
  saveRecent,
  saveMostViewed,
  saveHomepageBanners,
  saveNews,
  savePosts,
  startFetch,
  endFetch,
  LevelService, 
  PostsService, 
  ProductsService,
  RankingsService,
  BannersService,
  NewsService,
  saveUser,
} from '../normalizer'

class Home extends Component {

  state = {
    mobileFriendly: false
  } 
  resize = () => {
    this.setState({mobileFriendly: window.innerWidth < 576})
  }
  
  componentDidMount(){
    window.addEventListener("resize",this.resize)
    this.resize();

    const {
      news,
      user,
      campaign
    } = this.props;
    if(user.client && campaign.slug) {
      if (news.length === 0){
        this.getHomepageNews()
      }
    }

  }

  componentDidUpdate(prevProps){
    if(prevProps.campaign !== this.props.campaign ){

      this.getLevels()

      if (this.props.campaignUser.currentLevel !== null ){
        const CampaignUserLevelNumber = this.props.campaignUser.currentLevel.level_number
        const selectedLevelNumber = this.props.selectedLevel.level_number
        const level =  this.props.levels.find(level => level.level_number === CampaignUserLevelNumber)
      
        if(selectedLevelNumber === 0 && CampaignUserLevelNumber >= 1 && level !== undefined) {
          this.props.selectLevel(level)
          this.getLevelProducts(level)
        } 
      }

      this.getHomepageBanners()
      this.getHomepageNews()
      this.getHomeRanking()
      this.getPosts()
      this.getWishes()
      this.getHighlights()
      this.getMostViewed()
      this.getRecent()
    }
  }

  componentWillUnmount(){
    window.removeEventListener("resize",this.resize)
  }

  getLevels() {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug
    }
    this.props.startTransaction(() => 
      LevelService.getLevels(payload).then(response => {
        const { data } = response
        this.props.saveLevels(data)
      })
    )
  }

  getLevelProducts(level) {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      levelId: level.id
    }
    this.props.startTransaction(() => 
      LevelService.getProductsByLevels(payload).then(response => {
        const { data } = response
        this.props.saveLevelProducts(data)
        this.props.selectLevel(level)
      })
    )
  }

  getWishes(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getWishes(payload).then((response) => {
      const wishes = response.data
      this.props.saveWishes(wishes);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getRecent(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getRecent(payload).then((response) => {
      const recentProducts = response.data
      this.props.saveRecent(recentProducts);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getMostViewed(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug 
    }
    ProductsService.getMostViewed(payload).then((response) => {
      const mostViewed = response.data
      this.props.saveMostViewed(mostViewed);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
    
  }

  getHighlights(){
    this.props.startFetch()
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
       campaign_slug: campaign.slug 
    }
    ProductsService.getHighlights(payload).then((response) => {
      const highlights = response.data
      this.props.saveHighlights(highlights);
      this.props.endFetch();
    }).catch((error) => {
      this.props.endFetch();
    })
  }

  getHomeRanking() {
    const rankings = this.props.rankings
    if (rankings.length > 0){
      this.setState({ranking: rankings[0]})
      return; 
    }
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug
    const payload = {
      client_slug: clientSlug,
      campaign_slug: campaign.slug 
    }
    RankingsService.getRankings(payload)
      .then((response) => { 
        const data = response.data
        if ( data.rankings.length > 0){
          this.setState({ranking: data.rankings[0]})
        }
        this.props.saveRankings(data)
        this.props.endFetch();
      })
      .catch((error) => {
        this.props.endFetch();
      })
  }

  getHomepageBanners() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      client_slug: clientSlug,
      campaign_slug: campaign.slug 
    }
    this.props.startFetch();
    BannersService.getHomepageBanners(payload).then((response) => { 
      this.props.saveHomepageBanners(response.data);
      this.props.endFetch();
    })
    .catch((error) => {
      this.props.endFetch();
    })
  }

  getHomepageNews() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug 
    }

    NewsService.getHomepageNews(payload).then(response => {
      const news = response.data["content/news"]
      this.props.saveNews(news)
    }).catch(e => {})
  }

  getPosts() {
    const {
      user,
      campaign
    } = this.props
    const clientSlug = user.client.slug

    const payload = {
      clientSlug: clientSlug,
      campaignSlug: campaign.slug
    }

    PostsService.getPosts(payload).then((response) => {
      this.props.savePosts(response.data)
    })
  }

  needsToRenderMobile = () => {
    const toRender = this.props.toRender
    return (toRender('Rankings') ||  toRender('Gamification-Objetivos') ||  toRender('Bloqueio de Pontos'))
  }

  render(){
    const mobileFriendly = this.state.mobileFriendly
    const {
      posts,
      news,
      toRender,
      client,
      selectedLevel,
      levelProducts,
      campaignUser,
      levels,
      campaign,
      onSelectLevel,
      rankings,
      objectives,
      imageSize
    } = this.props

    const hasLevels = toRender("Gamification-Niveis")
    
    return (
      <div className="row gl-home__container text-color  transparent">
        {
          (mobileFriendly && this.needsToRenderMobile()) &&
          <div className= 'col-xs-12 text-color gl-home_posts'>
            <div className='row gl-home__catalog m-auto'>
              { toRender('Bloqueio de Pontos') &&
                <div className='col-xs-12'>
                  <h3 className="gl-post__title primary-text ">Bloqueio de Pontos</h3>
                  <PointBlocks currencyLabel = {client.currency_label} color='text-color'/>
                </div>
              }
              { toRender('Gamification-Objetivos') && 
                <div className='col-xs-12'>
                  <h3 className="gl-post__title primary-text ">Objectivos</h3>
                  <Objectives objectives={objectives} campaign={campaign} color='text-color' />
                </div>
              }
              { toRender('Rankings') && 
                <div className='col-xs-12'>
                  <h3 className="gl-post__title primary-text ">Rankings</h3>
                  <RankingSlider rankings={rankings} textColor/>
                </div>
              }
            </div>
          </div>
        }
        <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 gl-home__posts">
          { hasLevels &&
            <LevelPost levels={levels} onSelectLevel={onSelectLevel} selectedLevel={selectedLevel} campaignUser={campaignUser} campaign={campaign}/>
          }
          <PostFeed imageSize = {imageSize} posts={posts} toRender={toRender} />
        </div>
        <NewsFeed feed={news}/>
        { hasLevels && 
          <div className='col-sm-12 col-md-12 col-xs-12'>
            <LevelProductList client={client} campaignUser={campaignUser} selectedLevel={selectedLevel} levelProducts={levelProducts}/>     
          </div>
        }
      </div>
  )}
}

const mapStateToProps = (state) => {
  return {
    
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    campaignUser: state.balanceReducer.campaignUser,
    levels: state.levelsReducer.levels,
    selectedLevel: state.levelsReducer.selectedLevel,
    levelProducts: state.productsReducer.levelProducts,
    products: state.productsReducer.products,
    highlights: state.productsReducer.highlights,
    wishes: state.productsReducer.wishes,
    recent: state.productsReducer.recent,
    mostViewed: state.productsReducer.mostViewed,
    banners: state.homepageBannersReducer.banners,
    news: state.newsReducer.news,
    rankings: state.rankingsReducer.rankings,
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveLevels: (levels) => {
      dispatch(saveLevels(levels))
    },
    selectLevel: (level) => {
      dispatch(selectLevel(level))
    },
    saveLevelProducts: (products) => {
      dispatch(saveLevelProducts(products))
    },
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes));
    },
    saveRecent: (recent) => {
      dispatch(saveRecent(recent));
    },
    saveMostViewed: (mostViewed) => {
      dispatch(saveMostViewed(mostViewed));
    },
    saveRankings: (rankings) => {
      dispatch(saveRankings(rankings));
    },
    saveHighlights: (highlights) => {
      dispatch(saveHighlights(highlights));
    },
    saveHomepageBanners: (banners) => {
      dispatch(saveHomepageBanners(banners));
    },
    saveNews: (news) => {
      dispatch(saveNews(news));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}
const HomeComponent = connect(mapStateToProps,mapDispatchToProps)(Home)

export default withContainers(HomeComponent, [
  HomeContainer,
  BannerContainer,
  ClientContainer,
  PostContainer,
  LevelContainer,
  TemplateContainer,
  ResizeContainer
])
