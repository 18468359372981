import React from "react"
import CustomLink from "./Link"
import DropdownLink from "../DropdownLink"
import SimpleDropdown from "../SimpleDropDown"
import { t } from "../../../../i18n"
import { HouverButton } from "../client/buttons"
import { withRouter } from "react-router-dom"

const removeBurgerNav = () => {
  document.getElementById("navbar").classList.add("fade")
}

const Navigation = props => {
  const { pages, campaigns, campaign, campaignUser, supportingDocuments, toRender } = props

  const firstElementOfSupportingDocuments =
    supportingDocuments[0] === undefined ? null : supportingDocuments[0].id

  const isContentNeeded =
    toRender("Pages") || toRender("News") || toRender("SupportDocuments")

  return (
    <nav id="navbar" className="primary-bg navbar-text fade">
      <div className="cont navWide">
        <div className="wideDiv">
          <CustomLink exact to="/" onClick={removeBurgerNav}>
            {t("navbar.home")}
          </CustomLink>

          <DropdownLink
            message={t("navbar.products")}
            activeUrls={["/catalog", "/wishlist"]}
          >
            <CustomLink to="/catalog" onClick={removeBurgerNav}>{t("navbar.catalog")}</CustomLink>
            <CustomLink to="/wishlist" onClick={removeBurgerNav}>Favoritos</CustomLink>
          </DropdownLink>

          {toRender("Rankings") && (
            <CustomLink to="/rankings" onClick={removeBurgerNav}>{t("navbar.ranking")}</CustomLink>
          )}

          {toRender("Accelerators") && (
            <CustomLink to="/accelerators" onClick={removeBurgerNav}>
              {t("navbar.accelerators")}
            </CustomLink>
          )}


          {isContentNeeded && (
            <DropdownLink
              boldIt
              message={t("navbar.content")}
              activeUrls={[
                "/news",
                "/page/:slug",
                "/supporting-documents/:slug"
              ]}
              
            >
              {toRender("News") && (
                <CustomLink to="/news" onClick={removeBurgerNav}>{t("navbar.news")}</CustomLink>
              )}

              {toRender("Pages") &&
                pages.map((item, i) => (
                  <CustomLink key={i} to={"/page/" + item.slug} onClick={removeBurgerNav}>
                    {item.title}
                  </CustomLink>
                ))}

              {toRender("SupportDocuments") && (
                <CustomLink
                  to={
                    "/supporting-documents/" + firstElementOfSupportingDocuments
                  } onClick={removeBurgerNav}
                >
                  Documentos de apoio
                </CustomLink>
              )}
            </DropdownLink>
          )}

          {toRender("Invoice") && (
            <CustomLink to="/invoice" onClick={removeBurgerNav}>{t("navbar.invoices")}</CustomLink>
          )}

          <SimpleDropdown
            condition={campaigns.length > 1}
            message="Campanhas"
            campaign={campaign}
          >
            <div className="flex flex-col">
              {campaigns.map(reducedCampaign => (
                <HouverButton
                  active={campaign.slug === reducedCampaign.slug}
                  onClick={() => {
                    removeBurgerNav();
                    props.selectCampaign(reducedCampaign)();
                    props.history.push("/")
                  }}
                  className="font-quicksand-light"
                  key={`nav-houver-${reducedCampaign.slug}`}
                  campaign={campaign}
                >
                  {reducedCampaign.name}
                </HouverButton>
              ))}
            </div>
          </SimpleDropdown>
        </div>

        <DropdownLink
          message={<i className="fas fa-ellipsis-h" ></i>}
          activeUrls={["/sale-trading", "/profile", "/utility-info"]}
          noArrow
          >
          <CustomLink to="/profile" onClick={removeBurgerNav}>{t("navbar.perfil")}</CustomLink>
          <CustomLink to="/utility-info" onClick={removeBurgerNav}>{t("navbar.info")}</CustomLink>
            
          {toRender("Troca de Vendas") && (
            <CustomLink to="/sale-trading/requested" onClick={removeBurgerNav}>
              {t("navbar.sales")}
            </CustomLink>
          )}

          {toRender("Atribuição de Contratos") && campaignUser.canSeeSaleAttributions && (
            <CustomLink to="/sale-attributions/pending" onClick={removeBurgerNav} class='nav-item active-link fromDropdown'>
              {t("navbar.sale_attributions")}
            </CustomLink>
          )}

          <CustomLink to="/logout" onClick={props.handleLogout} class='nav-item active-link fromDropdown margin-logout'>
            {t("navbar.logout")}
          </CustomLink>
        </DropdownLink>
      </div>

      <button className="nav-mobile-close bg-white" onClick={removeBurgerNav}>
        <i className="fa fa-times news-remove" />
      </button>
    </nav>
  )
}

export default withRouter(Navigation)
