import React from "react"
import { Switch, Route, Redirect} from "react-router-dom"
import ProductCarousel from "../shared/ProductCarousel"
import CatalogFilters from "./CatalogFilters"
import CatalogOptions from "./CatalogOptions"
import ContentLayout from "../shared/ContentLayout"
import CatalogList from "./CatalogListProduct"
import "./css/style.scss"
import CatalogProduct from "./CatalogProduct"
import CatalogProductSearch from "./CatalogProductSearch"
import { prefix } from "../../../i18n"
import { withContainers} from "../../containers/gamification"
import {CatalogContainer} from "../../containers/shared"

const catalog = prefix("catalog")

const Catalog = (props) => {

  const {
    products,
    lastSeenProducts,
    highlights,
    pagination,
    onPageChange,
    onProductTextSearch,
    currentPage,
    campaign,
    optionActions,
    defaultValues,
    optionState,
    onBrandChange,
    brands,
    selectedBrand,
    generateFilterState,
    generateFilterActions
  } = props;

  return (
    <div className="catalog">
      <ContentLayout title={catalog("title")} campaign={campaign}>
        <div className="col-lg-4 col-md-4 col-xs-12 filters-catalog">
          <CatalogFilters
            campaign={campaign}
            filterActions={generateFilterActions()}
            filterState={generateFilterState()}
          />
        </div>
        <div className="col-lg-8 col-md-8 col-xs-12">
          <CatalogProductSearch
            campaign={campaign}
            brands={brands}
            onBrandChange={onBrandChange}
            brandDefaultValue={selectedBrand}
            onSearchRequest={onProductTextSearch}
          />
          <CatalogOptions
            campaign={campaign}
            optionState={optionState()}
            optionActions={optionActions()}
            defaultValues={defaultValues()}
          />
          <Switch>
            <Route
              exact
              path={"/catalog/product/:id"}
              component={CatalogProduct}
            />
            <Route exact path={"/catalog"}>
              <CatalogList
                products={products}
                currentPage={currentPage}
                campaign={campaign}
                pagination={pagination}
                onPageClick={onPageChange}
              />
            </Route>
            <Redirect from="*" to="/not-found" />
          </Switch>
        </div>
      </ContentLayout>
      <div className="cont row start-lg start-md start-xs">
        <div className="col-lg-12 col-md-12 col-xs-12 mt-16">
          <Switch>
            <Route exact path={"/catalog/product/:id"}>
              <ProductCarousel
                products={lastSeenProducts.reverse()}
                productsNumber={4}
                campaign={campaign}
                title={catalog("viewed-recently")}
                catalog={true}
                lineColor="#82D643"
              />
            </Route>
            <Route exact path={"/catalog"}>
              <ProductCarousel
                products={highlights}
                campaign={campaign}
                productsNumber={4}
                title={catalog("on-spotlight")}
                catalog={true}
                lineColor="#82D643"
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withContainers(Catalog, [CatalogContainer])
