import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUser} from '../../../actions/userActions';
import { saveCart} from '../../../actions/cartActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import { saveAddresses } from '../../../actions/addressesActions';
import {ToastContainer, ToastStore} from 'react-toasts';
import AddressForm from '../Profile/AddressForm'
import '../../../assets/css/slider.scss';
import styled from 'styled-components';
import { Redirect, Link } from "react-router-dom";
import CustomSelect from '../../shared/CustomSelect';
import { AddressesService, CartService } from '../../../services';
import CartAddressShow from '../shared/AddressShow';
import CartWarning from './CartWarning'
import CartNavigation from './CartNavigation';


class CartAddress extends Component{
  constructor(props){
    super(props);
    this.state={redirect: false, creatable: false};

    this.setOnCreatable = this.setOnCreatable.bind(this);
    this.setOffCreatable = this.setOffCreatable.bind(this);
  }
  getActiveStyle(){
    const  {
      text_color,
      navbar_text_color
    } = this.props.campaign
    return styled.div`
      color: ${navbar_text_color} !important;
      background: ${text_color}
      :before{
        border-left: 25px solid ${text_color};
      }
    ` 
  }
  getButtons(){
    const {
      color_primary, 
      navbar_text_color
    } = this.props.campaign
    
    const primary = styled.button`
      color: ${navbar_text_color};
      background: ${color_primary};
      border: 2px solid ${color_primary};
      :hover{
        background: ${navbar_text_color};
        color: ${color_primary};
      }
    `
    return primary
  }

  setOnCreatable(e){
    this.setState({creatable: true})
  }
  setOffCreatable(e){
    this.setState({creatable: false})
  }

  updateAddress(option){
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug, 
      address_id : option.id
    }
    this.props.startFetch();
    CartService.updateShipmentAddress(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        ToastStore.success('Endereço alterado')
        this.props.endFetch();

      })
      .catch((error) => {
        ToastStore.error('Impossivel de associar endereço')
        this.props.endFetch();

      });
    
  }
  checkCartAddress(e){
    e.preventDefault()
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug
    }

    this.props.startFetch();
    CartService.checkCartAddress(payload)
      .then((response) => {
        this.setState({redirect: true});
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.error('Endereço não associado')
        this.props.endFetch();
    });
  }

  getAddresses(){
    AddressesService.getAddresses().then((response) => {
      const addresses = response.data
      this.props.saveAddresses(addresses);
    });
  }
  componentDidMount(){
    if (this.props.addresses.length === 0){
      this.getAddresses()
    } 
  }

  render() {
    const {
      campaign,
      addresses,
      cart
    } = this.props
    const { 
      redirect,
      creatable
    } = this.state
    const ActiveDiv = this.getActiveStyle();
    let   ButtonPrimary = this.getButtons();

    if (cart.items.length === 0){
      return <Redirect to='/cart' />
    }
    if (redirect) {
      return <Redirect to='/cart_checkout'/>;
    }

    return (
      <div>     
        <div className='font-quicksand' style={{padding: '40px 0', backgroundColor: '#eaeaef'}}>
          <div className="cont flex start-lg start-md start-xs pb-2">
            <div className="col-lg col-md col-xs">
              <h1 style={{color: campaign.color_primary}} className="primary">CARRINHO DE COMPRAS</h1>
            </div>
          </div>
          <div className="cont row start-lg start-md start-xs pb-6">
            <div className="col-lg col-md col-xs">
              <hr className="hr-down-catalog"></hr>
            </div>
          </div>
          <div className="cont row start-lg start-md start-xs pb-6">
            <div className="col-xs-12">
              <div className='template cart-checkout-container'>
                <div className='w-95 m-auto'>
                  <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_LEFT} lightBackground/>
                  <CartNavigation campaign={campaign} step={2}/>
                  <div className='py-6 flex justify-between '>
                    { creatable ?  
                      <div className='cart-title-block'> 
                        <button onClick = {e => this.setOffCreatable(e) } className='cart-title-block-text' style={{color: campaign.color_primary}}>Voltar</button>
                      </div>
                      : <CustomSelect 
                        onChange = { e => this.updateAddress(e) } 
                        campaign={campaign} options={addresses} 
                        placeholder='Moradas' 
                        getOptionLabel={(option) => option.main_name} 
                        getOptionValue={(option) => option.id} 
                        className='col-lg-4 col-md-4 col-xs pl-0 pr-0' 
                        classNamePrefix='custom-select'/>
                    }
                    <div className='cart-title-block'> 
                      <button onClick = {e => this.setOnCreatable(e) } className='cart-title-block-text' style={{color: campaign.color_primary}}>Criar nova Morada</button>
                    </div>
                  </div>
                  { creatable ? <AddressForm handleClick = {this.setOffCreatable} create={true} campaign = {campaign}/> : null }
                  { cart.shipment_address ? <CartAddressShow classes='cart-address-border sm:flex-turn sm:flex-turn--j-center ' campaign = {campaign} address= {cart.shipment_address} /> : null }
                </div>
                <div className='w-95 m-auto'>
                  <div style={{padding: '7px 0 7px 10px'}} className='mt-8 mb-1 template-warning'>
                    <p>ATENÇÃO</p>
                  </div>
                  <div className='cart-warning-address-info'>
                      <p style={{ color: campaign.color_primary, padding:'10px 0'}} className='template-password-title'>Moradas de entrega incorretas ou incompletas geram devolução das encomendas, originando custos adicionais para si.</p>
                      <p style={{ color: campaign.color_primary, padding:'10px 0'}} className='template-password-title' > O fornecimento de uma morada de entrega incorreta ou incompleta pode originar o retorno da encomenda à Incentive House, originando custos adicionais para si.<br/>
                        Por este motivo, recomendamos que se certifique sempre de que a morada de entrega indicada aquando do registo da encomenda está efetivamente correta e completa.<br/>
                        Uma morada correta e completa deve incluir elementos como o tipo de via (rua, largo, avenida, edifício, bairro, praça, etc) e o respetivo nome, número da porta ou lote e o andar (se aplicável).</p>
                      <p style={{ color: campaign.color_primary, padding:'10px 0'}} className='template-password-title'>Caso o local de entrega pertença a uma empresa, indique por favor o nome da mesma.
                        Ex: Incentive House — Rua da Esperança, Nº9, 1º Esquerdo, 1200-655 Lisboa</p>
                      <Link className='py-5 block' style={{textDecoration: 'none' }}to='/profile'>
                        <button className='btn-secondary cart-button xs:w-full'>Verificar ou editar Moradas</button>
                      </Link>
                  </div>
                  
                </div>

                <div className='py-6 flex end-xs'>
                      <ButtonPrimary onClick = { e => this.checkCartAddress(e) } className='button-cart'>PRÓXIMO PASSO</ButtonPrimary>
                  </div>
              </div>
            </div>
            <CartWarning text={campaign.call_attention} />
          </div>        
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    cart: state.cartReducer.cart,
    addresses: state.addressesReducer.addresses
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart));
    },
    saveAddresses: (addresses) => {
      dispatch(saveAddresses(addresses));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CartAddress);