import React from 'react'
import Layout from '../shared/Layout'
import Details from './UserDetails';
import Orders from './Orders';
import Card from './Card';
import OrderShow from './Orders/OrderShow';
import Balance from './Balance';
import HistoryPointBlocks from './HistoryPointBlocks';
import BalanceHistory from './Balance/BalanceHistory';
import Route from '../../../routes/CustomRoute'
import { Switch } from 'react-router-dom'
import ProfileLayout from './ProfileLayout';
import {withClientContainer} from '../../containers/gamification'

const Profile = props => {

  
  const{
    currency_label : currencyLabel
  } = props.client

  const {toRender, client, campaign} = props

  
  return (
    <>
      <ProfileLayout toRender={toRender} pointBlockLabel={campaign.point_block_label}>
        <Switch>
          <Route path="/profile" exact component={Details} />
          <Route exact path="/profile/balance"  component={Balance} client={client} currencyLabel={currencyLabel} />
          <Route exact path="/profile/balance/history"  component={BalanceHistory} currencyLabel={currencyLabel}/>
          <Route exact path="/profile/orders/:id"  component={OrderShow} currencyLabel={currencyLabel}/>
          <Route exact path="/profile/orders"  component={Orders} currencyLabel={currencyLabel}/>
          { toRender('Bloqueio de Pontos') &&
            <Route exact path="/profile/history_point_blocks"  component={HistoryPointBlocks} pointBlockLabel={campaign.point_block_label} currencyLabel={currencyLabel} />
          }
          { toRender('Cartões Recarregáveis') &&
            <>
            <Route exact path="/profile/cards"  component={Card} currencyLabel={currencyLabel} />
            <Route exact path="/profile/cards/:id"  component={Card} currencyLabel={currencyLabel} />
            </>
          }
          

        </Switch>
      </ProfileLayout>
    </>
  )
}

export default withClientContainer(Profile)
