import "react-datepicker/dist/react-datepicker.css";
import React, { Component } from 'react'
import ContentTitle from '../shared/ContentTitle'
import DatePicker from 'react-datepicker'
import Moment from 'moment'
import {prefix} from '../../../i18n'
const tInvoice = prefix('invoice')
export default class InvoiceSearch extends Component {
  state = {
    username: "",
    storeName: "",
    number: "",
    initialRegistryDate: Moment(Date()),
    endRegistryDate: Moment(Date()),
    initialInvoiceDate: Moment(Date()),
    endInvoiceDate: Moment(Date())
  }

  handleSearch = (event) => {
    event.preventDefault()
    const {
      username,
      storeName,
      number,
      initialInvoiceDate,
      endInvoiceDate,
      initialRegistryDate,
      endRegistryDate
    } = this.state
    this.props.onSearchRequest({
      username,
      storeName,
      number,
      initialInvoiceDate: initialInvoiceDate.toString(),
      endInvoiceDate: endInvoiceDate.toString(),
      initialRegistryDate: initialRegistryDate.toString(),
      endRegistryDate: endRegistryDate.toString()
    })
    // event.target.reset()
  }

  changeFilter(name, mapper = (event) => event.target.value) {
    return (value) => {
      this.setState({[name]: mapper(value)})
    }
  }
  mommentMapper = (date) => new Moment(date)

  render() {
    // console.log("InvoiceSearch::Props >", this.props);
    const {campaign, validator} = this.props
    const {
      username,
      storeName,
      number,
      initialInvoiceDate,
      endInvoiceDate,
      initialRegistryDate,
      endRegistryDate
    } = this.state

    const RegistryDateRange = () => (
      <InputContainer
        label={tInvoice('registry-label')}
      >
        <DatePicker
          // adjustDateOnChange
          selected={initialRegistryDate.toDate()}
          startDate={initialRegistryDate.toDate()}
          onChange= {this.changeFilter('initialRegistryDate', this.mommentMapper)}
          endDate={endRegistryDate.toDate()}
          maxDate={endRegistryDate.toDate()}
          selectsStart
          dateFormat="dd-MM-yyyy"
          name={"invoiceNumber"}
          showDisabledMonthNavigation
        />
        <DatePicker
          // adjustDateOnChange
          selectsEnd
          selected={endRegistryDate.toDate()}
          endDate={endRegistryDate.toDate()}
          onChange= {this.changeFilter('endRegistryDate', this.mommentMapper)}
          startDate={initialRegistryDate.toDate()}
          minDate={initialRegistryDate.toDate()}
          dateFormat="dd-MM-yyyy"
          showDisabledMonthNavigation
          name={"invoiceNumber"}
        />
      </InputContainer>
    )

    const InvoiceDateRange = () => (
      <InputContainer
        label={tInvoice('invoice-label')}
      >
        <DatePicker
          // adjustDateOnChange
          selected={initialInvoiceDate.toDate()}
          startDate={initialInvoiceDate.toDate()}
          endDate={endInvoiceDate.toDate()}
          maxDate={endInvoiceDate.toDate()}
          selectsStart
          showDisabledMonthNavigation
          dateFormat="dd-MM-yyyy"
          onChange= {this.changeFilter('initialInvoiceDate', this.mommentMapper)}
          name={"invoiceNumber"}
        />
        <DatePicker
          // adjustDateOnChange
          selected={endInvoiceDate.toDate()}
          endDate={endInvoiceDate.toDate()}
          minDate={initialInvoiceDate.toDate()}
          startDate={initialInvoiceDate.toDate()}
          selectsEnd
          showDisabledMonthNavigation
          dateFormat="dd-MM-yyyy"
          onChange= {this.changeFilter('endInvoiceDate', this.mommentMapper)}
          name={"invoiceNumber"}
        />
      </InputContainer>
    )

    return (
      <form className="template-container template-container--light"onSubmit={this.handleSearch}>
        <div className="flex items-center justify-between">
          <ContentTitle sub title={"Pesquisa"} campaign={campaign}/>
          <button className="rounded primary-bg text-white px-5 py-1" type="submit">{tInvoice('filter')}</button>
        </div>
        <div className="col-xs-12 ">
          <div className="flex flex-col mb-3" >
            <div className="flex flex-wrap mb-2">
              <RegistryDateRange/>
              <InvoiceDateRange/>
            </div>
            <div className="flex flex-wrap mb-2">
              {validator &&
                <Input
                  className="px-3 py-2 input-round gray"
                  onChange= {this.changeFilter('username')}
                  value={username}
                  name={"username"}
                  label={tInvoice('user')}/>
              }
              <Input
                onChange= {this.changeFilter('number')}
                className="px-3 py-2 input-round gray"
                value={number}
                name={"invoiceNumber"}
                label={tInvoice('number')}/>
              <Input
                onChange= {this.changeFilter('storeName')}
                className="px-3 py-2 input-round gray"
                value={storeName}
                name={"storeName"}
                label={tInvoice('storeName')}/>
            </div>
          </div>
        </div>
     </form>
    )
  }
}


const Input = ({label, type="text", ...rest}) => (
  <InputContainer label={label}>
    <input type={type} {...rest}/>
  </InputContainer>
)

const InputContainer = ({label, children, className}) => (
  <div className={`flex flex-col pr-6 mb-2 ${className ||''}`}>
    <label className="mb-1 primary-text">{label}</label>
    <div className="flex flex-wrap gray">
      {children}
    </div>
  </div> 
)
