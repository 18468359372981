import React, { Component } from 'react';
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import { 
  saveRankings, 
  saveSelectedRanking,  
  saveSelectedPositions, 
  clearRankings, 
  buildTransaction,
  RankingsService
} from '../normalizer'

class RankingsContainer extends Component {

  state = {
    winners: [],
    points: [],
    topTen: [],
    userNeighbours: [],
    currPage: 1,
    totalPages: 1
  }
 
  getRankings(){
    const {
      user,
      campaign
    } = this.props

    const payload  = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug,
    }

    this.props.startTransaction(() =>
    RankingsService.getRankings(payload)
      .then((response) => {
        const rankings = response.data.rankings
        this.props.saveRankings(response.data)
        if (rankings.length !== 0){
          const firstRanking = rankings[0]
          this.loadData(firstRanking.id)
          this.props.history.push(`/rankings/${firstRanking.id}`)
        }
        else  
          this.props.clearRankigns();
      }))
  }

  fetchWinners = (payload) => {
    this.props.startTransaction(() =>
    RankingsService.getWinners(payload)
      .then((response) => {
        const {results, user_position, user_neighbours} = response.data
        this.setState(state => ({
          winners : results,
          userPosition : user_position,
          userNeighbours: user_neighbours || [],
          requested: true
        }))
      })
    )
  }

  fetchRankingPages = (payload) => {
    const page = this.state.currPage;
    this.props.startTransaction(() =>
    RankingsService.getRankingPoints({...payload, page: page})
      .then((response) => {
        this.setState({
          points: response.data.ranking_points,
          totalPages : response.data.pagination.total_pages,
          currPage : response.data.pagination.current_page,
        })
      })
    )
  }

  loadData = (ranking_id) => {
    const {user, campaign} = this.props

    const payload  = {
      client_slug: user.client.slug, 
      campaign_slug: campaign.slug, 
      ranking_id: ranking_id
    }
    
    this.fetchWinners(payload)
    this.fetchRankingPages(payload)
  }

  handlePageChange = (data)=> {
    const {user, campaign} = this.props

    const payload  = {
      client_slug: user.client.slug, 
      campaign_slug: campaign.slug, 
      ranking_id: this.props.match.params.id
    }

    this.setState({currPage: data.selected +1 },()=> this.fetchRankingPages(payload))
  }

  componentDidMount() {
    const { rankings} = this.props
    if(rankings.length === 0) {
      this.getRankings()
    }
    else {
      const firstRanking = rankings[0]
      this.loadData(firstRanking.id)
      this.props.history.push(`/rankings/${firstRanking.id}`)
    }
  }

  componentDidUpdate(oldProps) {
    const currId = this.props.match.params.id
    if(currId !== undefined && oldProps.match.params.id !== currId) {
      this.loadData(currId);
    }
  }

  render() {
    
    const {
      component: Component,
      rankings,
    } = this.props

    const firstRanking = rankings[0]
    
    const {...rest} = this.props
    
    if (!rankings) return null
    
    return ( 
      <Component {...rest}
        firstRanking={firstRanking}
        rankings={rankings}    
        winners={this.state.winners}
        points={this.state.points}
        userPosition={this.state.userPosition}
        userNeighbours={this.state.userNeighbours}
        onPageChange={this.handlePageChange}
        currPage={this.state.currPage}
        totalPages={this.state.totalPages}
        />
     );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaignUser: state.balanceReducer.campaignUser,
  campaign: state.campaignReducer.campaign,
  rankings: state.rankingsReducer.rankings,
  selectedRanking: state.rankingsReducer.selectedRanking,
  selectedRankingWinners: state.rankingsReducer.selectedRankingWinners,
  selectedUserPosition: state.rankingsReducer.selectedRankingUserPosition
});


function mapDispatchToProps(dispatch){
  return{
    saveRankings: (rankings) => {
      dispatch(saveRankings(rankings));
    },
    saveSelectedRanking: (rankings) => {
      dispatch(saveSelectedRanking(rankings));
    },
    saveSelectedPositions: (positions) => {
      dispatch(saveSelectedPositions(positions));
    },
    clearRankigns : () => dispatch(clearRankings()),
    startTransaction:  buildTransaction (dispatch)
  }
}

const Container = withRouter(connect(mapStateToProps, mapDispatchToProps)(RankingsContainer))

export function withRankingsContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container;