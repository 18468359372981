import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import styled from 'styled-components';
import RankingUserRow from './RankingUserRow';
import {prefix} from '../../../i18n'

const tRanking = prefix('ranking')
class RankingTable extends Component{

  customDiv(){
    const { 
      navbar_text_color,
      color_secondary,
      color_primary
    } = this.props.campaign;
    
    return styled.div`
      color: ${navbar_text_color};
      background: linear-gradient(${color_primary} 0%,${color_secondary} 95%)`
  }

  PointsDifference(){
    const winners = this.props.winners
    const user_position = this.props.user_position
    if (user_position === undefined && winners.length !== 0){
      return winners[winners.length-1].total_points
    }
    if (winners.length !== 0 ){
      return  winners[winners.length-1].total_points - user_position.total_points
    }
    return 0
  }

  isUserInWinners = (user) => {
    const { user_position } = this.props
    const hasPosition = !!user_position

    if(!user || !hasPosition) {
      return false
    }
    
    return  hasPosition && user.id === user_position.id
  }
  
  userAsWinner = (user)=> ({
    first_name : user.first_name,
    last_name : user.last_name,
    position : "N/A",
    total_points: 0
  })
  render() {
    const {
      campaign,
      user_position,
      ranking,
      winners,
      user
    } = this.props
  
    const CustomDiv = this.customDiv()
    const pointsDifference = this.PointsDifference()
    /**
     * if user is winner give him highlight
     * else print users
     * if user is not at the top , print him under
     */
    return( 
      <>
        <div className='template'>
          <div className="col-lg col-md col-xs">
            <h1 style={{color: campaign.color_primary}} className="primary">{ranking.name}</h1>
          </div>
          <div className="col-lg col-md col-xs">
            <hr className="hr-down-catalog"></hr>
          </div>
          <table className='text-lg w-full mt-12'>
            <thead>
              <tr className='user-header uppercase'>
                <th className='w-10 prod-th-title order-th text-center'>{tRanking('position')}</th>
                <th className='prod-th-title order-th text-center'>{tRanking('competitor')}</th>
                <th className='w-10 prod-th-title order-th text-center'>{tRanking('points')}</th>
              </tr>
            </thead>
            <tbody>
              { winners.map((winner,i) => (
                  <RankingUserRow 
                    key = {i} 
                    campaign = {campaign} 
                    isLoggedInUser={this.isUserInWinners(winner)}
                    winner = {winner} /> 
                ))
              }
              {!ranking.is_winner &&       
                <RankingUserRow 
                  isLoggedInUser
                  campaign = {campaign} 
                  winner = {user_position || this.userAsWinner(user)} 
                />
              }
          </tbody>
        </table>
      </div>
        <CustomDiv className='rounded mt-5 py-8 px-10'>
            { ranking.is_winner 
            ? 
              <p className='tracking-wide text-xl text-center' >{ranking.winning_message}</p>
            :
              <div className='flex between-xs'>
              <span className='tracking-wide text-xl text-left uppercase'>Para atingires a meta atual faltam-te </span>
              <span className='text-2xl text-right'>{pointsDifference} PTS</span>
              </div>
            }
        </CustomDiv>
      </>
    )
  }

}

export default RankingTable;