import React, {Component} from 'react'
import {connect} from 'react-redux'
import { 
  GroupPositionUserService, 
  bonusPointService, 
  buildTransaction, 
  saveBonusPointsBalance,
  saveGivenBonusPoints
} from '../normalizer';
import { isEmpty } from '../../../utils/utils';
import { ToastStore, ToastContainer } from 'react-toasts';

class BonusPointsContainer extends Component {
  state = {
    colleagues: [],
    selectedColleague: undefined,
    selectedProduct: undefined,
    givenPage: 1,
    pointsToGive: 0,
    errors: {}
  }

  getColleagues(){
    this.props.startTransaction(() =>
    GroupPositionUserService.getGroupUsers().then((response) => {
        const users = response.data.users
        this.setState({colleagues: users})
      })
    )
  }

  handlePageChange = data => {
    this.setState({ givenPage: data.selected + 1 }, () =>
      this.getGivenPointsBalance()
    )
  }

  changeColleague = (e) => {
    this.setState({selectedColleague: e})
  }

  changeProduct = (e) => {
    this.setState({selectedProduct: e})
  }

  updatePoints = (e) => {
    this.setState({
      pointsToGive: e.target.value
    })
  }

  getBonusPointsBalance(){
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug
    }
    this.props.startTransaction(() =>
    bonusPointService.getBonusPoints(payload).then((response) => {
      const bonusPoints = response.data.bonus_points
      this.props.saveBonusPointsBalance(bonusPoints);
    })
    .catch((error) => {
      const status = error.response.status
      switch (status) {
        case 403:
          return null
        default:
          return  ToastStore.error(`Error ao carregar pontos bonus`)
      }
    }))
  }

  getGivenPointsBalance(){
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      page: this.state.givenPage
    }
    this.props.startTransaction(() =>
    bonusPointService.getGivenPoints(payload).then((response) => {
      const data = response.data
      this.props.saveGivenBonusPoints(data)
    })
    .catch((error) => {
      const status = error.response.status
      switch (status) {
        case 403:
          return null
        default:
          return  ToastStore.error(`Error ao carregar pontos atribuidos`)
      }
    })
    )
  }

  transformBonusPointsIntoPoints = () => {
    let errors = {}
    const {
      selectedProduct,
      selectedColleague,
      pointsToGive
    } = this.state

    if ( selectedColleague === undefined){
      errors['colleague'] = 'Por favor selecione um utilizador'
    }
    if ( selectedProduct === undefined ) {
      errors['product'] = 'Por favor selecione um produto'
    }
    let normalizedPoints = new Number(pointsToGive)
    if (normalizedPoints.valueOf() === NaN || normalizedPoints.valueOf() <= 0 ){
      errors['number'] = 'Selecione um número superior a 1'
    }
    if (!isEmpty(errors)){
      this.setState({errors: errors})
      return;
    }
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      selectedProductId: selectedProduct.id,
      selectedUserId: selectedColleague.id,
      points: normalizedPoints
    }

    this.props.startTransaction(() =>
    bonusPointService.givePoints(payload).then((response) => {
      ToastStore.success(`Pontos atribuidos a ${selectedColleague.employee_identifier}`)
      const bonusPoints = response.data.bonus_points
      this.props.saveBonusPointsBalance(bonusPoints);
      this.props.history.push('/bonus_points')
    })
    .catch((error) => {
      ToastStore.error(`${error.response.data.message}`)
    }))

  }

  componentDidMount() {
    if(this.state.colleagues.length === 0 ){
      this.getColleagues()
    }
    if( this.props.bonusPointsBalance.length === 0 ){
      this.getBonusPointsBalance()
    }
    if( this.props.givenBonusPoints.length === 0 ){
      this.getGivenPointsBalance()
    }
  }

  render() {
    const {component: Component, ...rest} = this.props
    const {
      colleagues, 
      selectedColleague, 
      pointsToGive, 
      errors,
      selectedProduct,
      givenPage
    }= this.state
    return (
      <>
      <ToastContainer store={ToastStore}/>
      <Component {...rest}
          {...this.props}
          selectedColleague={selectedColleague}
          colleagues={colleagues} 
          onChangeColleague={this.changeColleague}
          pointsToGive={pointsToGive}
          onUpdatePoints={this.updatePoints}
          onSubmitForm={this.transformBonusPointsIntoPoints}
          formErrors={errors}
          selectedProduct={selectedProduct}
          onChangeProduct={this.changeProduct}
          onPageChange={this.handlePageChange}
          givenPage={givenPage} 
      />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaignReducer.campaign,
  user: state.userReducer.user,
  bonusPointsBalance: state.bonusPointsReducer.bonusPointsBalance,
  givenBonusPoints: state.bonusPointsReducer.givenBonusPoints,
  givenBonusPagination: state.bonusPointsReducer.givenBonusPagination,
  clientProducts: state.bonusPointsReducer.clientProducts,
  campaignUser: state.balanceReducer.campaignUser
})

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch),
  saveBonusPointsBalance: bonusPoints => dispatch(saveBonusPointsBalance(bonusPoints)),
  saveGivenBonusPoints: givenPoints => dispatch(saveGivenBonusPoints(givenPoints))
})

const Container = connect(mapStateToProps, mapDispatchToProps)(BonusPointsContainer)

export function withBonusPointsContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
