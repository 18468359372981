import React from "react"
import ContentLayout from "../shared/ContentLayout"
import TabLink from "../shared/TabLink"
import {Switch, Route} from "react-router-dom"
import Pending from './Pending'
import Requested from './Requested'
import Create from './Create'

const SaleTrade = ({ campaign }) => {
  return (
    <ContentLayout campaign={campaign} title={"Troca de Contratos"}>
      <div className="flex py-2 justify-between tab-height uppercase col-xs-12 font-quicksand-light margin-tabs">
        <div className="flex">
          <TabLink to={"/sale-trading/requested"}>Realizadas</TabLink>
          <TabLink to={"/sale-trading/pending"}>Recebidas</TabLink>
        </div>
        <TabLink to={"/sale-trading/create"}>Adicionar</TabLink>
      </div>
      <Switch>
        <Route path="/sale-trading/pending" component={Pending}/>
        <Route path="/sale-trading/requested" component={Requested}/>
        <Route path="/sale-trading/create" component={Create}/>
      </Switch>
    </ContentLayout>
  )
}
export default SaleTrade
