const initialState = {
  highlights: [],
  wishes: [],
  recent: [],
  mostViewed: [],
  products: [],
  lastSeenProducts: [],
  levelProducts: [],
  pagination: {},
  brands: []
}

const productsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SELECT_CAMPAIGN':
      return initialState
    case 'SAVE_PRODUCTS':
      return {
        ...state,
        products: action.payload.products,
        pagination: action.payload.pagination
      }
    case 'SAVE_BRANDS':
      return {
        ...state,
        brands: action.payload.brands
      }
    case 'SAVE_WISHES':
      return {
        ...state,
        wishes: action.payload.wishes
      }
    case 'SAVE_MOST_VIEWED':
      return {
        ...state,
        mostViewed: action.payload.more_views
      }
    case 'SAVE_RECENT':
      return {
        ...state,
        recent: action.payload.recents
      }
    case 'SAVE_LEVEL_PRODUCTS':
      return {
        ...state,
        levelProducts: action.payload.level_highlights
      }
    case 'SAVE_HIGHLIGHTS':
      return {
        ...state,
        highlights: action.payload.highlights
      }
    case 'UPDATE_LAST_SEEN_PRODUCTS': {
      const { product } = action.payload
      const { lastSeenProducts } = state
      if(!lastSeenProducts.find(e => e.id === product.id)) {
        if(lastSeenProducts.length > 8) {
          state.lastSeenProducts.pop()
        }
        lastSeenProducts.push(action.payload.product)
      }
      return {
        ...state,
        lastSeenProducts
      }
    }
    case 'UPDATE_PRODUCTS':
      const updatedProducts = state.products.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite  } 
        }
        return { ...item }
      })
      const updatedHighlights = state.highlights.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite }
        }
        return { ...item }
      })
      const updatedLastSeen = state.lastSeenProducts.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite }
        }
        return { ...item }
      })
      const updatedRecent = state.recent.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite }
        }
        return { ...item }
      })
      const updatedMostViewed = state.mostViewed.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite }
        }
        return { ...item }
      })
      const updatedLevelProducts = state.levelProducts.map(item => {
        if(item.product.id === action.payload.id){
          return { ...item, is_favorite: action.payload.favorite }
        }
        return { ...item }
      })
      return {
        ...state,
        products: updatedProducts,
        highlights: updatedHighlights,
        lastSeenProducts: updatedLastSeen,
        recent: updatedRecent,
        mostViewed: updatedMostViewed,
        levelProducts: updatedLevelProducts
      }
    default:
      return state;
  }
}
export default productsReducer;