import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CartService, WishProductsService } from '../../../services';
import { updateProducts, saveWishes } from '../../../actions/productsActions';
import { saveCart } from '../../../actions/cartActions';
import { buildTransaction } from '../../../actions/generalActions';
import { withRouter } from 'react-router-dom'

class ProductContainer extends Component {

  payload = () => ({
    client_slug: this.props.user.client.slug,
    campaign_slug: this.props.campaign.slug,
    product_id: this.props.product.id
  })

  handleCartAddition = () => {
    const payload = {
    client_slug: this.props.user.client.slug, 
    slug: this.props.campaign.slug, 
    item_id: this.props.id
    }
    
    this.props.startTransaction(() =>
      CartService.addItem(payload)
        .then((response) => {
          this.props.saveCart(response.data)
          this.props.history.push('/cart')
        })
    )
  }

  handleFavAddition = () => {
    const payload = this.payload()
    this.props.startTransaction(()=>
      WishProductsService.addToWishes(payload)
      .then((response) => {
        const wishes = response.data
        this.props.saveWishes(wishes)
        this.props.updateProducts({id: payload.product_id , favorite: true})
      })
    )
  }

  handleFavRemove = () => {
    const payload = this.payload()
    this.props.startTransaction(()=>
      WishProductsService.deleteFromWishes(payload)
        .then((response) => {
          const {wishes} = response.data
          this.props.saveWishes(wishes)
          this.props.updateProducts({id: payload.product_id  , favorite: false})
        })
    )
  }

  render() {
    const {component: Component, ...rest} = this.props
    return (
        <Component {...rest}
          onFavAddition={this.handleFavAddition}
          onFavRemove={this.handleFavRemove}
          onCartAdd={this.handleCartAddition}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return {
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes))
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart))
    },
    updateProducts: (wish) => {
      dispatch(updateProducts(wish))
    },
    startTransaction: buildTransaction(dispatch)
  }
}
const Container = connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductContainer))

export function withProductContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
