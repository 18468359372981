import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import styled from 'styled-components'
import OrderButton from '../shared/OrderButton'
import {ToastContainer, ToastStore} from 'react-toasts';
import {Link} from 'react-router-dom';
import { CartService, WishProductsService, ProductsService } from '../../../services';
import { saveCart } from '../../../actions/cartActions';
import { startFetch, endFetch } from '../../../actions/generalActions'
import { saveWishes, updateProducts, updateLastSeenProducts } from '../../../actions/productsActions';

const H2 = styled.h2`
  color: ${props=> props.color}
`
class CatalogProduct extends Component {
  constructor(props){
    super(props)
    this.state = {
      notFound: false,
      expandText: false,
      currentItem: null
    }
  }
  
  addtoCart(){
    const {
      user,
      campaign,
      match
    } = this.props
    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug, 
      item_id: match.params.id
    }
    this.props.startFetch();
    CartService.addItem(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        ToastStore.success('Produto foi adicionado')
        this.props.history.push('/cart')
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.error('Impossivel de adicionar produto')
        this.props.endFetch();
      });
  }

  changeFav(){
    const {
      user,
      campaign
    } = this.props
    const item = this.findPathItem(this.props)
    const payload = {
      client_slug: user.client.slug, 
      campaign_slug: campaign.slug, 
      product_id: item.product.id
    }
    this.props.startFetch();
    if(item.is_favorite){
      WishProductsService.deleteFromWishes(payload)
        .then((response) => {
          const wishes = response.data
          this.props.saveWishes(wishes)
          this.props.updateProducts({id: item.product.id , favorite: false})
          ToastStore.success("Item removido dos favoritos");
          this.props.endFetch();
        })
        .catch((error) => {
          ToastStore.error('Impossivel de remover produto')
          this.props.endFetch();
        });
    }
    else{
      WishProductsService.addToWishes(payload)
      .then((response) => {
        ToastStore.success("Produto adicionado dos favoritos");
        const wishes = response.data
        this.props.saveWishes(wishes)
        this.props.updateProducts({id: item.product.id , favorite: true})
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.error('Impossivel de adicionar produto')
        this.props.endFetch();
      });
    }
  }

  tryGetProduct(){
    const payload = {
      client_slug: this.props.user.client.slug, 
      campaign_slug: this.props.campaign.slug, 
      product_id: this.props.match.params.id
    }
    this.props.startFetch();
    ProductsService.getProductById(payload).then(response=>{
      const item = response.data
      this.props.updateLastSeenProducts(item)
      this.props.endFetch();
      window.scrollTo(0, 0)
      this.incrementViewCount()
    }).catch(error => {
      this.props.endFetch();
      this.setState({notFound: true})
    })
  }
  incrementViewCount(){
    const campaignItem = this.findPathItem(this.props)
    const payload = {
      client_slug: this.props.user.client.slug, 
      campaign_slug: this.props.campaign.slug, 
      product_id: campaignItem.id
    }
    ProductsService.incrementViewCount(payload)
    .then().catch()
    this.setState({currentItem: campaignItem})
  }
  generateValidProduct({product}){
    let { description } = product
    return {
      ...product,
      description: description === "" ? "Sem descrição." : description.trim()
    }
  }

  findPathItem({lastSeenProducts, products, highlights,recent, wishes, mostViewed, match}) {
    return products.find(e => e.id.toString() === match.params.id) ||
            lastSeenProducts.find(e => e.id.toString() === match.params.id) ||
            highlights.find(e => e.id.toString() === match.params.id) ||
            wishes.find(e => e.id.toString() === match.params.id) ||
            recent.find(e => e.id.toString() === match.params.id) ||
            mostViewed.find(e => e.id.toString() === match.params.id) 
  }

  componentDidUpdate(oldprops){
    const campaignItem = this.findPathItem(this.props)
    if(this.props.campaign.slug !== oldprops.campaign.slug) {
      this.props.history.push('/catalog')    
      return
    } 

    if(campaignItem) {
      this.props.updateLastSeenProducts({product : campaignItem})
    }
    
    if(campaignItem && this.state.currentItem && this.state.currentItem.id !== campaignItem.id) {
      this.incrementViewCount()
    }

 
    //fav updates trigger rerender

 
  }

  componentDidMount() {
    const campaignItem = this.findPathItem(this.props)
    if(campaignItem) {
      this.incrementViewCount()
      this.props.updateLastSeenProducts({product : campaignItem})
      window.scrollTo(0, 0)
    } else {
      this.tryGetProduct()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextItem = this.findPathItem(nextProps)
    const oldItem = this.findPathItem(this.props)
    let favUpdate = false;
    if(nextItem && oldItem){
      favUpdate = oldItem !== nextItem
    }

    return nextState.notFound || favUpdate || //impossible to find product or product fav updated
      nextProps.products.length !== this.props.products.length || //product new item
      nextProps.lastSeenProducts.length !== this.props.lastSeenProducts.length || //last seen has new item
      this.props.match.params.id !==  nextProps.match.params.id ||// product changed
      this.props.campaign.slug !== nextProps.campaign.slug
    
  }
  
  render() {
    const { campaign } = this.props
    const { notFound, currentItem } = this.state
    const { color_primary, color_secondary, text_color, navbar_text_color } = campaign
    const item = this.findPathItem(this.props) 

    if(!item && notFound){
      return <Redirect to="/product/not-found"/>
    }

    if(!item) {
      return <div></div>
    }

    const product = this.generateValidProduct(item) 
    return (
      <div className="catalog-product row start-xs relative">
        <div className ="absolute pin-r pin-t p-2 crux">
          <Link to="/catalog">
            <i className='fa fa-times news-remove'></i>
          </Link>
        </div>
        <div className="col-lg-5 flex flex-col content-center product-image">
          <img src={product.main_image.url} alt={product.name} />
        </div>
        <div className= "col-lg-7 justify-between">
          <H2 className="catalog-product__name mb-2" color={color_primary}>{product.name}</H2>
          <H2 className="catalog-product__points flex items-center items-baseline font-quicksand mb-2" color={color_secondary}>
            {item.points} <p className="text-sm ml-1">pontos</p>
          </H2>
          <hr className="catalog-product__hr bg-grey"/>
          <p className="catalog-product__description text-sm my-3" dangerouslySetInnerHTML={{__html: product.description}}/>
          <hr className="catalog-product__hr bg-grey mb-5"/>
          <div className ="row flex">
            <OrderButton onClick= {()=> this.addtoCart()} color={text_color} hoverColor={navbar_text_color}/>
            <div className="col-lg col-md col-xs order text-right pb-3 m-auto">
              <i className={item.is_favorite ? 'fas fa-heart fav text-xl cursor-pointer': 'far fa-heart text-grey text-xl cursor-pointer'} onClick={() => this.changeFav()}></i>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  campaign: state.campaignReducer.campaign,
  products: state.productsReducer.products,
  lastSeenProducts: state.productsReducer.lastSeenProducts,
  highlights: state.productsReducer.highlights,
  wishes: state.productsReducer.wishes,
  recent: state.productsReducer.recent,
  mostViewed:state.productsReducer.mostViewed,
  user: state.userReducer.user  
})

function mapDispatchToProps(dispatch){
  return {
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes)) 
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart))
    },
    updateProducts: (wish) => {
      dispatch(updateProducts(wish))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())},
    updateLastSeenProducts: (product) => { dispatch(updateLastSeenProducts(product))}
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CatalogProduct)
