
export const AuthenticationService = {
  logout(){
    const prev = localStorage.getItem('client')
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    localStorage.removeItem('campaign');
    localStorage.removeItem('client');
    localStorage.setItem('prevClient', prev);
  }
}