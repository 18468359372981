import React from "react"
import StyledImage from "../../../shared/StyledImage"
import { substring, Chevron } from "../../normalizer"
import { Link } from "react-router-dom"

const HighlightedNew = props => {
  const {title, subtitle, id, image } = props;
  
  const croppedImageKey = 'rectangular_thumbnail_image';
  const imageUrl = image[croppedImageKey] && image[croppedImageKey].url
                    ? image[croppedImageKey].url
                    : (image.mobile_image ? image.mobile_image.url : '');

  return (
    <Link
      className="gl-home__new-item text-color"
      to={"news/" + id}
    >
      {imageUrl &&
        (<StyledImage url={imageUrl} className="gl-home__new-image" />)
      }

      <div className="gl-home__new-content">
        <h3 className="gl-home__new-type font-bold primary-text">Notícia</h3>
        <div className="flex justify-between mb-3 ">
          <h2 className="gl-home__new-title lighter">{substring(title, 15)}</h2>
          <Chevron className="primary-fill gl-home__new-chevron" width="30px" />
        </div>
        {subtitle && <h3 className="gl-home__new-subtitle">{substring(subtitle,80)}</h3>}
      </div>
    </Link>
  )
}

const index = ({ feed })  => {
  return (
      <>
        {
        <div className="gl-home__new-container">
          {feed.map(item => (
            <HighlightedNew {...item} key={"f-" + item.id} />
          ))}
        </div>
        }
      </>
  )
}

export default index