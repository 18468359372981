import React from "react"
import { Link, Switch, Route } from "react-router-dom"
import AnnouncementShow from "./Details"
import AnnouncementPreview from "./Preview"
import { withContainers, ResizeContainer, NewsContainer, AcceleratorsContainer } from "../normalizer"
import '../../../assets/css/slider.scss';
import AcceleratorShow from '../Accelerators/AcceleratorShow';
import AcceleratorDetail from '../Accelerators/Details';

const Announcement = ({ news, imageSize, limitText , accelerators}) => {
  const previews = news.map(announcement => {
    return (
      <div
        className="my-8 flex flex-col"
        key={"announcement-" + announcement.id}
      >
        <AnnouncementPreview announcement={announcement} imageSize={imageSize} limitText={limitText}/>
      </div>
    )
  })

  return (
    <Switch>
      <Route exact path={"/news"}>
        <>
          {previews}
          {accelerators != null && accelerators.length > 0 &&
            <>
              {accelerators.map((accelerator) => (
                <div key={accelerator.id} className='my-8 flex flex-col'>
                  <Link className="text-color gl-home__promotion" to={`/news/${accelerator.id}`}>
                    <AcceleratorShow accelerator={accelerator} limitText={limitText} imageSize={imageSize} />
                  </Link>
                </div>
              ))}
            </>
          }
        </>
      </Route>
      {news.map(element => (
        <Route key={`route-${news.id}`}
          exact path={`/news/${element.id}`}>
          <AnnouncementShow imageSize={imageSize} announcement={element} />
        </Route>
      ))}
      {accelerators.map((accelerator) => (
        <Route exact key={`route-${accelerator.id}`} path={`/news/${accelerator.id}`}>
          <AcceleratorDetail accelerator={accelerator} limitText={limitText} imageSize={imageSize} />
        </Route>))}
    </Switch>
  )
}

export default withContainers(Announcement,[NewsContainer, AcceleratorsContainer, ResizeContainer])
