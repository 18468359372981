import React from 'react'

const KeyValue = ({title, value, colSpan = 1, classNameData, classNameTitle}) => {

  const tilteClass = classNameTitle !== undefined ? `g-profile__info ${classNameTitle}` : "g-profile__info"
  const dataClass = classNameData !== undefined ? `g-profile__info g-profile__info--value ${classNameData}` : "g-profile__info g-profile__info--value "

  return(
    <div colSpan={colSpan} className="g-profile__table-cell">
      <div className={tilteClass}>{title}</div>
      <div className={dataClass}>{value}</div>
    </div>
  )
}

export default KeyValue
