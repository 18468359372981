import React from 'react'

const Counter = ({value}) => {
  return (
    <span 
      className="g-notification__count very-small-text primary-bg text-white vodafone-bold"
    >
      {value}
    </span>
)
}

export default Counter
