import React from 'react';
import { NavLink } from 'react-router-dom'

const RankingsSelector = props => {

  const {
    rankings
  } = props

  return(
    <div className='md:w-1/3 sm:w-full mb-10 mr-5'>
      <div className='g-rankings__selector-container'>
        {rankings.map((ranking) => (
          <NavLink
            activeClassName="border-primary g-rankings__selector--active"
            className="g-rankings__selector p-3 cursor-pointer font-medium vodafone"
            key={"ranking-selector-" + ranking.id}
            to={`/rankings/${ranking.id}`}>
              {ranking.name}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default RankingsSelector