import React, { Component } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import BackgroundDiv from "../shared/BackgroundDiv";
import { t } from "../../../i18n";

function getDeviceType() {
  const width = window.innerWidth;
  if (width < 768) {
    return 'mobile';
  } else if (width >= 768 && width < 992) {
    return 'tablet';
  } else {
    return 'desktop';
  }
}

export default class Details extends Component {
  state = {
    deviceType: getDeviceType()
  };
  componentDidMount() {
    const { accelerator } = this.props;
    const element = document.getElementById(`accelerator-${accelerator.id}`);

    let resize = -20;
    const { offsetHeight } = document.getElementById("g-navbar");
    resize = -offsetHeight - 10;

    window.scrollTo(0, element.offsetTop + resize);

    window.addEventListener('resize', this.updateDeviceType);
    this.updateDeviceType();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDeviceType);
  }

  updateDeviceType = () => {
    this.setState({ deviceType: getDeviceType() });
  };

  render() {
    const { accelerator } = this.props;
    const { deviceType } = this.state;
    let imageSize;
    switch (deviceType) {
      case 'desktop':
        imageSize = 'banner_desktop_image';
        break;
      case 'mobile':
      case 'tablet':
        imageSize = 'banner_mobile_image';
        break;
      default:
        imageSize = 'banner_desktop_image';
    }
    
    const croppedImageKey = imageSize.replace('image', 'cropped_image');
    const imageUrl = accelerator.image[croppedImageKey] && accelerator.image[croppedImageKey].url
                    ? accelerator.image[croppedImageKey].url
                    : (accelerator.image[imageSize] ? accelerator.image[imageSize].url : "");    

    return (
      <div
        className="content-container my-8 text-color"
        id={`accelerator-${accelerator.id}`}
      >
        <BackgroundDiv
          url={imageUrl}
          className="gl-centered-bg relative col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 content-images-full rounded-t"
        >
          <Link to="/news" className="bg-white gl-news__remove">
            <i className="fas fa-times fa-lg primary-text" />
          </Link>
        </BackgroundDiv>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 px-5">
          <div className="py-4">
            <h2 className="primary-text font-bold news-title ">
              {accelerator.name}
            </h2>
          </div>
          <div className="custom-inner-html">
            <div
              dangerouslySetInnerHTML={{ __html: accelerator.description }}
            />
          </div>
          <p className="py-3 primary-text text-size text-right">
            {new Moment(accelerator.created_at).format(t("date-format"))}
          </p>
        </div>
      </div>
    )
  }
}
