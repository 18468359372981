import axios from './interceptor';

export const BannersService = {

  getHomepageBanners(payload) {
    const url = `/api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/banners`
    return axios({
      method: 'get',
      url: url
    });
  }
};