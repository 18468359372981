import React, {Component} from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { InvoiceService } from '../../../services';
import { connect } from 'react-redux'
import { startFetch, endFetch } from '../../../actions/generalActions';
import InvoiceItem from './InvoiceItem';
import { updateCreatedInvoice, submitToValdiation } from '../../../actions/invoiceActions';

class InvoiceItemContainer extends Component {
  state = {
    products: [],
    headerErrors: {},
    invoice: {},
  }

  invoicePayload = () => ({
    clientId: this.props.user.client.slug, 
    campaignId: this.props.campaign.slug
  })

  getInvoiceFromPath = ( props = this.props) => {
    const { startTran } = props
    const { id } = props.match.params
    const payload = {
      ...this.invoicePayload(),
      id
    }

    startTran(() =>
      InvoiceService.getInvoiceDetail(payload)
        .then(response => response.data.invoice)
        .then(invoice => {
          console.log(" [InvoiceItemContainer] [getInvoiceFromPath] Invoice = ", invoice)
          this.setState(prevState => ({...prevState, invoice}))
        })
        .catch(error => {
          const errors = error.response.data.errors
          this.setState({headerErrors: errors})
        })
    )
  }

  handleProductCreation = (data) => {
    const { invoice } = this.state 
    const payload = {
      ...this.invoicePayload(),
      ...data,
      id: invoice.id
    }

    this.props.startTran(()=>
      InvoiceService.addInvoiceProduct(payload)
      .then(response => response.data.invoice)
      .then(invoice => this.setState({products: invoice.details}))
    )
  }

  handleProductEdit = (data, product) => {
    const { invoice } = this.state

    const payload = {
      ...this.invoicePayload(),
      ...data,
      invoiceId: invoice.id,
      productDetailId: product.id
    }

    this.props.startTran(()=>
      InvoiceService.editInvoiceProduct(payload)
        .then(response => response.data.invoice)
        .then(invoice => this.setState({products: invoice.details}))
    )
  }

  handleHeaderEdit = (data) => {
    const { invoice } = this.state

    const payload = {
      ...this.invoicePayload(),
      ...data,
      invoiceId: invoice.id
    }

    this.props.startTran(()=>
      InvoiceService.editInvoiceHeader(payload)
        .then(response => response.data.invoice)
        .then(invoice => 
          this.props.updateCreatedInvoice(invoice)
        )
        .catch(error => {
          const errors = error.response.data.errors
          this.setState({headerErrors: errors})
        })
    )
  }

  handleSubmitToValidation = () => {
    const { invoice } = this.state
    const payload = {
      ...this.invoicePayload(),
      id: invoice.id
    }

    this.props.startTran(() => 
      InvoiceService.sendToValidation(payload)
        .then(response => response.data.invoice)
        .then(elem => {
          this.props.submitToValdiation(elem)
          this.props.history.push('/invoice/to-approve')
        })
    )
  }

  getInvoiceProducts = (invoice) => {
    const payload = {
      ...this.invoicePayload(),
      id: invoice.id
    }

    this.props.startTran(()=> 
      InvoiceService.getInvoiceDetail(payload)
        .then(response => response.data.invoice)
        .then(invoice=> {
          this.setState({products: invoice.details})
        })
    )
  }

  handleProductRemove = (product) => {
    const { invoice } = this.state
    const payload = {
      ...this.invoicePayload(),
      id: invoice.id,
      productDetailId: product.id
    }

    this.props.startTran(()=> 
      InvoiceService.removeInvoiceProduct(payload)
        .then( ()=>
          this.setState(state => ({
            products: state.products.filter(p => p.id !== payload.productDetailId) 
          })
        )
      )
    )
  }

  componentDidMount() {
    this.getInvoiceFromPath()
  }

  componentDidUpdate(oldProps, oldState) {
    if((!oldState.invoice && this.state.invoice) || (oldState.invoice && (oldState.invoice.id !== this.state.invoice.id))) {
      this.getInvoiceProducts(this.state.invoice)
    }
  }

  render () {
    const { invoice } = this.state

    if(!invoice) {
      // return <Redirect to="/not-found" />
      return null
    }

    return (
      <InvoiceItem 
        invoice={invoice}
        onProductCreation={this.handleProductCreation}
        onProductEdit={this.handleProductEdit}
        onProductRemove={this.handleProductRemove}
        onSubmitToValidation={this.handleSubmitToValidation}
        products={this.state.products}
        onHeaderEdit={this.handleHeaderEdit}
        headerErrors={this.state.headerErrors}
        {...this.props}    
      />
    )
    
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  saleTypes: state.invoiceReducer.saleTypes

})

const mapDispatchToProps = dispatch => ({
  startTran: (promiseAction) => {
    dispatch(startFetch())
    promiseAction()
      .then(()=> 
        dispatch(endFetch())
      ).catch(()=>
        dispatch(endFetch())
      )
  },
  updateCreatedInvoice: (invoice)=> dispatch(updateCreatedInvoice(invoice)),
  submitToValdiation: (invoice)=> dispatch(submitToValdiation(invoice))
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceItemContainer))
