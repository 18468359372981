import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import CartItemsTable from '../../ShoppingCart/CartItemsTable';
import Button from '../../shared/CustomButton';
import { withOrdersContainer, NoOrders } from '../../normalizer';
import EmptyStateLayout from '../../shared/EmptyStateLayout';
import Moment from 'moment'
import { t, prefix } from '../../../../i18n';
import { formatNumber } from '../../normalizer';

const tPaymentMethod = prefix('cart-checkout.payment-method')
const tPaymentSource = prefix('cart-checkout.source')

const PaymentDetails = memo(({ orderPayment }) => {
  if ((orderPayment === undefined) || (orderPayment === null))
    return

  const { provider, source } = orderPayment
  return (
    <>
    <div className="mt-4">Detalhes de Pagamento</div>
    <div class="flex-1 flex justify-around items-center">
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("entity") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.entity || "")}</h5>
         </div>
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("reference") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.reference || "")}</h5>
         </div>
         <div class="flex-1 flex justify-around items-start my-5 border-r-4 border-grey ">
           <h5 class="flex-1 my-2 text-center">{tPaymentSource("value") + ':'}</h5>
           <h5 class="flex-1 self-center ml-5 text-left">{source && (source.value || "")}</h5>
         </div>
        {provider.validity > 0 && <div class="flex-1 flex justify-around items-start my-5">
           <h5 class="flex-1 my-2 text-center">{tPaymentMethod("validity") + ':'}</h5>
           <h5 class="flex-1 self-center text-left">{provider.validity || ""}</h5>
         </div>}
     </div>
     </>
  )
})

const OrderShowRow = ({id, number, checkout_date, total_points : totalPoints, status, received_by_user : receivedByUser, order, getOrderPaymentInfo, onUserClickReceived, onReceivedChange}) => {

  const normalizedStatus = status.toLowerCase();
  let color = 'processing'
  let disabled = false

  switch(normalizedStatus){

    case 'enviado':
      color = 'sent';
      break
    case 'entregue':
      color = 'delivered';
      disabled = true;
      break
    default:
      color = 'processing';
      disabled = true;
  }

  return (
    <tr className="g-profile__info-row">
      <td className="p-3">{number}</td>
      <td className="p-3">{new Moment(checkout_date).format(t('date-format'))}</td>
      <td className='p-3'>{formatNumber(totalPoints)}</td>
      <td align="center"><span className={`g-profile__order-status g-profile__order-status--${color} m-3 p-2`}>{status}</span></td>
      <td className='text-base text-center sm:no-display'>{getOrderPaymentInfo(order)}</td>
      <td align="center"><input disabled={disabled} className="g-profile__checkbox-container__checkbox-input" onClick={() => onUserClickReceived(id, receivedByUser, status)}  type="checkbox" onChange={() => onReceivedChange(id)} checked={receivedByUser}/></td>
    </tr>
  )
}

const OrderShow = (props) => {
  const {
    currencyLabel,
    selectedOrder,
    onUserClickReceived,
    onReceivedChange,
    getOrderPaymentInfo,
  } = props
 
  const messageNoOrder = "Por favor escolha uma encomenda válida.";

  if(!selectedOrder.id){
    return (
      <EmptyStateLayout message={messageNoOrder}>
        <div className="g-no-store">
          <NoOrders className="mr-3 dead-grey g-no-store__image"></NoOrders>
        </div>
      </EmptyStateLayout>
    )
  }
  
  const {
    shipment_address,
    order_payment: orderPayment
  } = props.selectedOrder;

  const {
    first_name : firstName,
    last_name : lastName,
    address1,
    city, 
    country, 
    zip_code
  } = shipment_address

  const message = "Neste momento ainda não tem detalhe da encomenda."

  return (
    <div>
      <div className={'overflow-x-auto'}>
      <table className={"mb-10 w-full"}>
          <thead className="navbar-text g-profile__table">
            <tr className="g-profile__info-row" >
              <th className="w-2/7 p-3 text-left ">Nº encomenda</th>
              <th className="w-1/7 p-3 text-left">Data</th>
              <th className="w-1/7 p-3 text-left">{currencyLabel}</th>
              <th className="w-2/7 p-3 text-center">Estado</th>
              <th className="w-2/7 p-3 text-center">Informação</th>
              <th className="w-1/7 p-3 text-center">Recebida?</th>
            </tr>
          </thead>
          <tbody className="dead-grey">
            <OrderShowRow {...selectedOrder} order={props.selectedOrder} onUserClickReceived={onUserClickReceived} onReceivedChange={onReceivedChange} getOrderPaymentInfo={getOrderPaymentInfo}/>
          </tbody>
      </table>
      </div>
      <div className='ml-8'>
        <CartItemsTable
          currencyLabel={currencyLabel}
          cart={selectedOrder}
          checkout
        />
        <hr className="catalog__horizontal-line catalog__horizontal-line--no-margin"/>
        <PaymentDetails orderPayment={orderPayment} />
        <hr className="catalog__horizontal-line catalog__horizontal-line--no-margin"/>
        <div className="vodafone">
          <div className="mt-4">Morada da encomenda</div>
          <div className="mt-2 dead-grey">
            <div className="pt-4 dark-grey">{`${firstName} ${lastName}`}</div>
            <div className="flex justify-between mt-3">
              <div className=" mt-1">{address1}</div>
              <div className="g-cart__address__data mt-1">{zip_code}</div>
              <div className="g-cart__address__data mt-1">{city}, {country.name}</div>
            </div>
          </div>
        </div>  
      </div>
      <div className='my-8 flex justify-between sm:flex-turn sm:flex-turn--reverse sm:flex-turn--j-center sm:flex-turn--a-center'>
        <NavLink className='primary-text' to="/profile/orders" exact>
          <Button outline>
            Voltar
          </Button>
        </NavLink>
      </div>
    </div> 
  )
}

export default withOrdersContainer(OrderShow);
