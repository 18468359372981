import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pt from './locales/pt.json';
import es from './locales/es.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  pt: {
    translation: pt
  },
  es: {
    translation: es
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'pt',
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

export const t = (key) => i18n.t(key)
export const prefix = (prefix) => (key) => i18n.t(`${prefix}.${key}`)
export const setLanguage = (newLanguage, cb) => i18n.changeLanguage(newLanguage, cb)
