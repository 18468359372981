import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AcceleratorsService, buildTransaction } from '../normalizer'


class AcceleratorsContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      accelerators: []
    };
  }

  componentDidMount() {
    if (this.state.accelerators.length === 0) {
      this.getAccelerators()
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.campaign.slug !== this.props.campaign.slug) {
      this.getAccelerators()
    }
  }

  getAccelerators() {
    const { campaign, user } = this.props

    const payload = { client_slug: user.client.slug, campaign_slug: campaign.slug }
    this.props.startTransaction( () =>
      AcceleratorsService.getAccelerators(payload)
        .then((response) => {
          this.setState({ accelerators: response.data.accelerators })
        })
    );
  }

  render() {
    const {
      component: Component,
      ...rest
    } = this.props;

    const {accelerators} = this.state;

    return (
      <Component
        accelerators={accelerators}
        {...rest}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch)
  }
}



const Container = connect(mapStateToProps, mapDispatchToProps)(AcceleratorsContainer)

export function withAcceleratorsContainer(component) {
  return (props) => (
    <Container
      component={component}
      {...props}
    />
  )
}

export default Container
