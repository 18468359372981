import React, { Component } from 'react';

class AddressShow extends Component{
  
  render(){
    const { address, classes } = this.props
    const { color_primary } = this.props.campaign

    return(
      <>
        <div className='my-6 flex template-container between-xs'>
          <div>
            <h2 style={{color: color_primary}} className='template-title'>MORADA DE ENTREGA</h2>
          </div>
        </div>
        <div className={'flex py-8 '+ classes}>
          <div className='px-10 text-break'  >
            <p style={{color: color_primary}} className='template-address-blue'>{address.address1} {address.address2}</p>
            <p className='template-address-gray'>{address.city} , {address.country.name}</p>
            <p className='template-address-gray'>{address.zip_code}</p>
          </div>
          <div className='px-10 text-break'>
            <p style={{color: color_primary}} className='template-address-blue'>{address.first_name} {address.last_name}</p>
            <p className='template-address-gray'>{address.email}</p>
            <p className='template-address-gray'>TLM: {address.phone}</p>
          </div>
        </div>
      </>
  )};
  static defaultProps = {
    campaign: {
      color_primary: undefined, 
    }
  }
}

export default AddressShow;
   
