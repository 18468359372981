import React from 'react';
import Moment from 'moment'
import RankingTableLines from './RankingTableLines';
import RankingRow from './RankingRow';
import { t } from '../../../i18n';

const pointsDifference = (props) => {
  const winners = props.winners
  const userPosition = props.userPosition

  if (userPosition === undefined && winners.length !== 0){
    let difference = Number(winners[winners.length-1].total_points) + 1
    return difference % 1 === 0 ? difference.toFixed(0) : difference.toFixed(2)
  }
  if (winners.length !== 0 ){
    let difference = Number(winners[winners.length-1].total_points) - Number(userPosition.total_points) + 1
    return difference % 1 === 0 ? difference.toFixed(0) : difference.toFixed(2)
  }
  return 0
}

const neighbourIsNextToWinners =  (winners, userNeighbours) => {
  if (winners === undefined || userNeighbours === undefined ){
    return false 
  }
  const neighboursPositions = userNeighbours.map((element) => { return element.position; })
  const firstNPosition = Math.min(...neighboursPositions)
  const winnersPositions = winners.map((element) => { return element.position; })
  const lastWPosition = Math.max(...winnersPositions)
  return (lastWPosition + 1 >= firstNPosition)
}

const NeighboursWithoutWinners = (neighbours,winners) => {
  return neighbours.filter(element => 
    !winners.find(winner => winner.id === element.id)
  )
}



const parseTotalPoints = (totalPoints) => {
  const parsedTotalPoints = parseFloat(totalPoints)
  const normalizedTotalPoints = parsedTotalPoints % 1 === 0 ? parsedTotalPoints.toFixed(0) : parsedTotalPoints
  return normalizedTotalPoints
}
  
const userAsWinner = (user, campaignUser, userPosition)=> (
  {
  avatar : campaignUser.avatar || user.avatar,
  full_name : userPosition ? userPosition.full_name : user.first_name + ' ' +  user.last_name,
  position : userPosition ? userPosition.position : '-',
  total_points: userPosition ? parseTotalPoints(userPosition.total_points) : 0
  }
)

const RankingSeparator = () => {
  
  const tableClass= [
    'g-rankings__data my-7', 
    'navbar-text'
  ].join(' ')
  return(
    <tr className={tableClass}>
      <td colSpan={4} className={'p-3'}><hr className={'g-rankings__data-separator'}/></td>
    </tr>
  )
}

const RankingTable = props => {
  
  const {
    user,
    winners,
    userPosition,
    userNeighbours,
    campaignUser,
    endDate,
    isWinner,
    rankingLabel,
    numberOfWinners,
    winningMessage,
  } = props

  const userFakeWinner = userAsWinner(user, campaignUser, userPosition)
  const endDateNormalized = new Moment(endDate)
  return(
    <>
      <div className='g-rankings__table-container overflow-x-auto'>
        <table className='g-rankings__table w-full px-2 text-size vodafone'>
          <thead className='my-10 text-left'>
            <tr className='g-rankings__table-header py-5 my-5'>
              <td className='g-rankings__table-header text-left py-3 px-2 sm:px-3'>Posição</td>
              <td className='g-rankings__table-header w-2/5 text-left py-3 px-2  sm:px-3'>Jogador</td>
              <td className='g-rankings__table-header w-2/5 text-left py-3 px-2  sm:px-3'>Loja</td>
              <td className='g-rankings__table-header text-right py-3 px-2 whitespace-no-wrap sm:px-3'>{rankingLabel.currencyLabel}</td>
            </tr>
          </thead>
          <tbody>
            <RankingTableLines 
              results = {winners}
              userPosition={userPosition}
              areWinners={true}
            />

            { !neighbourIsNextToWinners(winners, userNeighbours) && 
              <RankingSeparator />
            }
            <RankingTableLines 
              results = {NeighboursWithoutWinners(userNeighbours, winners)}
              userPosition={userPosition}
            />
          
            { !isWinner && userNeighbours.length === 0 &&
              <RankingRow 
                isCurrentUser
                result={userFakeWinner}
              />
            }
          </tbody>
        </table>  
      </div>
      <div className='g-rankings__goal-container mt-4 p-3 text-color secondary-bg'>
        { isWinner ?
          <p>{ winningMessage }</p>
          :
          <p>{`Faltam ${pointsDifference(props)} ${rankingLabel.winnerDescription} para chegares ao TOP ${numberOfWinners}`}</p>
        }
        {
          endDateNormalized._isValid && 
          <p className='mt-5'>{`Termina a ${endDateNormalized.format(t('date-format'))}`}</p>
        }
      </div>
    </>
  )
}

export default RankingTable