import React, { Component } from 'react';
import { connect } from 'react-redux';
import { startFetch, endFetch } from '../../../actions/generalActions';
import {ToastContainer, ToastStore} from 'react-toasts';
import incentivehouse from '../../../assets/img/incentivelogo.png';
import { UserService } from '../../../services';


class TermsOfService extends Component{
  state={
    terms: ''
  }

  getTerms(){
    this.props.startFetch()
    UserService.getTermsOfService().then((response) => {
     this.setState({terms: response.data.terms.terms_of_service})
     this.props.endFetch();
    })
    .catch((error) => {
      this.setState({terms: 'Sem termos de serviço'})
      this.props.endFetch()
    });

  }
  
  componentDidMount(){
    this.getTerms()
  }

  render() {
    const { 
      user
    } = this.props;

    const {
      terms
    } = this.state

    return (
      <div className='image-background image-background--no-overlay py-10' style={user.client.background_banner.url ? {backgroundImage: 'url(' + user.client.background_banner.url + ')'}: {} } >
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_CENTER} lightBackground/>
        <div className="cont row default-container py-10 ">
            <div className='col-xs-12 py-10 center-xs'>
              <img style={{width:'260px'}} src={user.client.logo.url ? user.client.logo.url : incentivehouse } />
            </div>
            <div 
              className='custom-inner-html col-xs-12 leading-normal text-left p-5' 
              dangerouslySetInnerHTML={{ __html: terms }}>
            </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user
  } 
}
function mapDispatchToProps(dispatch){
  return {
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfService);