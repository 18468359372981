import React, { Component } from 'react';
import styled from 'styled-components'

const DropdownWrapper = styled.button`
  background: ${props=> props.primary}
  color: ${props=> props.textColor}
  &:hover {
    background: ${props=> props.secondary}
  }
  &.active-link {
    background: ${props=> props.secondary}
  }

  & > item {
    color:${props=> props.textColor}
  }
`

export default function SimpleDropdown({ condition, message, campaign, children, boldIt}){
  const font = `font-${boldIt ?'quicksand' :'quicksand-light'}`
  if(!condition) return null
  return(
    <div className="dropdown">
      <DropdownWrapper 
        textColor={campaign.navbar_text_color}
        primary={campaign.color_primary}
        secondary={campaign.color_secondary}
        className={`dropbtn ${font} `} 
        >
        {message}
        <i className="fas fa-angle-down dropdownIcon"></i>
      </DropdownWrapper>
      <div className={`dropdown-content width-available ${font}`} >
        {children}
      </div>
    </div>
  )
}
