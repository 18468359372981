import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({totalPages,currentPage, onPageChange})=> {
  return (
    <ReactPaginate
      className='outline-none'
      previousLabel='<'
      nextLabel='>'
      breakLabel='...'
      breakClassName='break-me gl-active-page'
      pageCount={totalPages}
      marginPagesDisplayed={1}
      pageRangeDisplayed={1}
      forcePage={currentPage -1}
      onPageChange={onPageChange}
      containerClassName='gl-pagination justify-start center-xs gl-active-page start-sm list-reset mr-3'
      pageClassName='gl-pagination__page gl-active-page transparent'
      activeClassName='primary-bg gl-active-page text-white'
    />
  )
}

export default Pagination