import React from 'react'
import { withQuestionContainer } from '../../containers/gamification';
import RectLoader from '../shared/RectLoader';
import CustomButton from '../shared/CustomButton';
import CustomLink from '../shared/CustomLink';

const QuizAnswer = (props) => {
  const {
    onClick,
    isCorrect,
    isSelected,
    option_name: questionText,
    done
  } = props

  const containerClass = [
    "gl-quiz-card__answer mb-5 gl-outline-reset",
    isSelected && 'primary-bg secondary-text',
    !isSelected && 'text-color',
    done && 'cursor-not-allowed'
  ].join(' ')

  return (
    <button
      className={containerClass}
      onClick={onClick}
    >
      {questionText}
      {isCorrect && <i className="fas fa-check gl-quiz-card__answer--correct primary-text"></i>}
      {isSelected && isCorrect=== false &&
        <i className="fas fa-times gl-quiz-card__answer--correct primary-text"></i>
      }
    </button>
  )
}

const QuestionCard = (props) => {
  const {
    currentQuestion = {},
    total, done,
    selectedAnswerId,
    currentQuestionNumber,
    onNextQuestion,
    onPrevQuestion,
    onLeave,
    onQuizSubmit,
    onQuizresponse
  } = props

  const first =currentQuestionNumber ===1
  const last  =currentQuestionNumber === total
  const primaryButtonCondition = !(done && last)
  const navigationContainer = [
    'flex flex-wrap gl-q-center',
    first && 'justify-end',
    !first && 'justify-between'
  ].join(' ')

  return (
    <>
      <div className="gl-content-shadow gl-quiz-card vodafone-light primary-text gl-title-size mb-10">
        <div className ="flex items-center mb-5">
          <p className="mr-5 text-size">Pergunta {currentQuestionNumber}/{total}</p>
          <div className="flex-1 gl-quiz-card__loader ">
            <RectLoader thin progress={Number(currentQuestionNumber/total) * 100}/>
          </div>
        </div>
        <p className="mb-5 vodafone-bold gl-quiz-card__question">{currentQuestion.question_text}</p>
          <ul className="list-reset">
            {currentQuestion.options.map(a => (
              <li key={a.id}>
                <QuizAnswer
                  onClick={done? undefined : ()=>onQuizresponse(a.id)}
                  isSelected={selectedAnswerId === a.id}
                  isCorrect={a.is_answer}
                  done={done}
                  {...a}
                /> 
                
              </li>
            ))}
          </ul>
          <div className={navigationContainer}>
            {!first && <CustomButton secondary className="mb-2" onClick={onPrevQuestion}>Questão anterior</CustomButton>}
            {primaryButtonCondition ? (
              <CustomButton className="mb-2" primary onClick={last? onQuizSubmit:onNextQuestion}>
                {last ? 'Submeter Quiz' : 'Próxima questão' }
              </CustomButton>
            ) : (
                <CustomLink primary to="/quiz/history" className="mb-2">
                Voltar para histórico
              </CustomLink>
            )}
          </div>
      </div>
    </>
  )
}

export default withQuestionContainer(QuestionCard)

export {
  QuestionCard
}
