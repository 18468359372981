export function saveRanking(state) {
  return {
    type: 'SAVE_RANKING',
    payload: state
  }
};
export function saveRankings(state){
  return {
    type: 'SAVE_RANKINGS',
    payload: state
  }
};
export function saveSelectedRanking(state){
  return {
    type: 'SAVE_SELECTED_RANKING',
    payload: state
  }
};
export function saveSelectedPositions(state){
  return {
    type: 'SAVE_SELECTED_POSITIONS',
    payload: state
  }
}

export function clearRankings(){
  return {
    type: 'CLEAR_RANKINGS'
  }
}
