import React, {useState, useEffect} from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { prefix, t } from '../../../i18n';
import Moment from 'moment'
const tInvoice = prefix('invoice')

const InvoiceTableHeader = ({complete, sortByValidationDate, sortByInvoiceDate, sortByRegistryDate, validator}) => {

  const CompleteColumns = (
    <>
      <th onClick={sortByValidationDate} className="text-left invoice__table-cell--medium cursor-pointer">
        <div className="inline-flex items-center">Data de Verificação<i className="fas fa-sort" /></div>
      </th>
      <th className="text-left invoice__table-cell--big ">Razão</th>
    </>
  )
  const AcceptedColumn = (
    <th className="text-center px-1">Aceite</th>
  )

  return (
    <thead>
      <tr className="invoice__table-header primary-bg text-white">
        {complete && AcceptedColumn}
        { validator && <th className="text-left pl-2 invoice__table-cell--medium">{tInvoice('user')}</th>}
        <th className={`text-left pr-2 ${ !validator && 'pl-2' } invoice__table-cell--big`}>{tInvoice('place')}</th>
        <th className="text-left pr-2 invoice__table-cell--big">{tInvoice('number')}</th>
        <th onClick={sortByInvoiceDate} className="text-left pr-2 invoice__table-cell--medium px-1 cursor-pointer">
          <div className="inline-flex items-center"> {tInvoice('invoice-date')}<i className="fas fa-sort" /></div></th>
        <th onClick={sortByRegistryDate} className="text-left pr-2 invoice__table-cell--medium px-1 cursor-pointer">
          <div className="inline-flex items-center">{tInvoice('registry-date')}<i className="fas fa-sort" /></div></th>
        {complete && CompleteColumns}
        <th  className="text-left pr-2 invoice__table-cell--small">{tInvoice('sellType')}</th>
        <th className="text-right pr-2">{tInvoice('points')}</th>
        <th className="text-center pr-2">Ações</th>
      </tr>
    </thead>
  )
}

const InvoiceTableRow = ({validator,complete, invoice, linking, history}) => {
  const invoiceURL = `${window.location.origin}${linking}/${invoice.id}`;
  
  const CompleteColumns = (
    <>
      <td className="px-1" >{invoice.validation_date}</td>
      <td className="px-1 break hyphens">{invoice.reprove_reason ? invoice.reprove_reason.reason : 'Sucesso'}</td>
    </> 
  )
  const AcceptedColumn = () => (
    <td className="px-1 text-color text-center">
      { invoice.approved === "approved" ? 
          <i className="fas fa-check"></i>
          :
          <i className="fa fa-times" ></i>
      }
    </td>
  )
  
  return (
    <tr className="invoice__table-row user-header font-quicksand-light text-left table-text">
      {complete && <AcceptedColumn /> }
      {validator  && <td className="text-left pl-2 py-2 ">{invoice.user}</td>}
      <td className="px-1">{invoice.client_group ? invoice.client_group.external_identifier : 'Sem grupo'}</td>
      <td className="px-1">{invoice.invoice_number}</td>
      <td className="px-1">{new Moment(invoice.invoice_date).format(t('date-format'))}</td>
      <td className="px-1">{new Moment(invoice.created_at).format(t('date-format'))}</td>
      {complete && CompleteColumns}
      <td className="px-1">{invoice.sale_type}</td>
      <td className="text-right pr-2">{invoice.points || 0}</td>
      <td className="text-center pr-2 text-color cursor-pointer">
        <a href={invoiceURL} target="_blank">
          <i className="fas fa-eye"></i>
          </a>
      </td>
    </tr>
  )
}


const InvoiceTable = ({ validator, complete, invoices, linking, history }) => {
  const [invcs, setInvcs] = useState(invoices.map( inv => {
    const createdAt = inv.created_at.replace("Z","")
    return {
      ...inv,
      created_at: createdAt,
    }
  } ));
  const [ascInvoiceDate, setAscInvoiceDate] = useState(false);
  const [ascRegistryDate, setAscRegistryDate] = useState(false);
  const [ascValidationDate, setAscValidationDate] = useState(false);
  
  useEffect(() => {
    setInvcs(invoices)
  }, [invoices])

  useEffect(() => {
    orderByValidationDate();
  }, [ascValidationDate]);

  useEffect(() => {
    orderByInvoiceDate();
  }, [ascInvoiceDate])

  useEffect(() => {
    orderByRegistryDate()
  }, [ascRegistryDate])

  const orderByValidationDate = () => {
    let newArray = [...invcs];
    if (ascValidationDate) {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.validation_date);
        const bDate = new Moment(b.validation_date);
        if (aDate.isBefore(bDate)) return -1;
        else if (aDate.isAfter(bDate)) return 1;
        else return 0;
      });
    } else {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.validation_date);
        const bDate = new Moment(b.validation_date);
        if (aDate.isBefore(bDate)) return 1;
        else if (aDate.isAfter(bDate)) return -1;
        else return 0;
      });
    }
    setInvcs(newArray);
  };

  const orderByInvoiceDate = () => {
    let newArray = [...invcs];
    if (ascInvoiceDate) {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.invoice_date);
        const bDate = new Moment(b.invoice_date);
        if (aDate.isBefore(bDate)) return -1;
        else if (aDate.isAfter(bDate)) return 1;
        else return 0;
      });
    } else {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.invoice_date);
        const bDate = new Moment(b.invoice_date);
        if (aDate.isBefore(bDate)) return 1;
        else if (aDate.isAfter(bDate)) return -1;
        else return 0;
      });
    }
    setInvcs(newArray);
  };

  const orderByRegistryDate = () => {
    let newArray = [...invcs];
    if (ascRegistryDate) {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.created_at);
        const bDate = new Moment(b.created_at);
        if (aDate.isBefore(bDate)) return -1;
        else if (aDate.isAfter(bDate)) return 1;
        else return 0;
      });
    } else {
      newArray.sort(function(a, b) {
        const aDate = new Moment(a.created_at);
        const bDate = new Moment(b.created_at);
        if (aDate.isBefore(bDate)) return 1;
        else if (aDate.isAfter(bDate)) return -1;
        else return 0;
      });
    }
    setInvcs(newArray);
  };

  const handleSortByInvoiceDate = (event) => {
    event.preventDefault();
    setAscInvoiceDate((obid) => !obid);
  };

  const handleSortByRegistryDate = (event) => {
    event.preventDefault();
    setAscRegistryDate((obrd) => !obrd);
  };

  const handleSortByValidationDate = (event) => {
    event.preventDefault();
    setAscValidationDate((obvd) => !obvd);
  };

  return (
    <>
      <table className="w-full">
        <InvoiceTableHeader
          validator={validator}
          sortByValidationDate={handleSortByValidationDate}
          sortByInvoiceDate={handleSortByInvoiceDate}
          sortByRegistryDate={handleSortByRegistryDate}
          complete={complete}
        />
        <tbody>
          {invcs.map((i) => (
            <InvoiceTableRow
              validator={validator}
              history={history}
              linking={linking}
              invoice={i}
              key={`invoice-table-td-${i.id}`}
              complete={complete}
            />
          ))}
        </tbody>
      </table>
      {invcs.length <= 0 && (
        <div className="text-center text-xl text-color pl-2 py-2">
          Sem faturas
        </div>
      )}
    </>
  );
};

export default withRouter(InvoiceTable)
