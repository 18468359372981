import React from "react"
import NavigationSubNavbarLink from "../shared/NavigationSubNavbarLink"

const ProfileNavigation = ({toRender, pointBlockLabel}) => {
  return (
    <nav className="flex gl-profile__title mb-5 navbar-text">
      <NavigationSubNavbarLink to="/profile" exact>
        Dados pessoais
      </NavigationSubNavbarLink>
      <NavigationSubNavbarLink to="/profile/balance">Extrato de Pontos</NavigationSubNavbarLink>
      <NavigationSubNavbarLink to="/profile/orders">Encomendas</NavigationSubNavbarLink>
      { toRender('Bloqueio de Pontos') &&
        <NavigationSubNavbarLink to="/profile/history_point_blocks">Objetivos</NavigationSubNavbarLink>
      }
      { toRender('Cartões Recarregáveis') &&
        <NavigationSubNavbarLink to="/profile/cards">Cartões</NavigationSubNavbarLink>
      }
    </nav>
  )
}

const ProfileLayout = props => {
  return (
    <div className="my-10 flex flex-col">
      <ProfileNavigation { ...props }/>
      {props.children}
    </div>
  )
}

export default ProfileLayout
