import React from "react"
import Modal from "../../shared/Modal"
import BackgroundDiv from "./BackgroundDiv"
import CustomLink from "./CustomLink"
import { substring } from "../../../utils/utils"
import { withContainers, PopUpContainer, ResizeContainer } from "../normalizer"
const CHARACTED_LIMIT = 590

const PopUpLayout = props => {
  const { image, imageSize, title, description = "", show = false, untoggle, id, slug } = props
  const showButton = description.length > CHARACTED_LIMIT
  const info = showButton
    ? substring(description, CHARACTED_LIMIT, "...")
    : description

  return (
    <Modal show={show} untoggle={untoggle} large lessPadding>
      <div className="bg-white popup">
        <div className="popup__image">
          <BackgroundDiv
            className="bg-center bg-cover h-full relative"
            url={image[imageSize] ? image[imageSize].url : ''}
          >
            <button className="bg-black popup__close" onClick={untoggle}>
              <i className="fas fa-times fa-lg text-white" />
            </button>
          </BackgroundDiv>
        </div>
        <div>
          <div className="popup__title vodafone-bold dark-grey text-3xl tracking-wide px-5 pt-5 pb-4">
            {title}
          </div>
          <div
            className="custom-inner-html popup__info leading-normal dead-grey px-5 mb-3"
            dangerouslySetInnerHTML={{ __html: info }}
          />
          {showButton && (
            <div>
              <div className="flex justify-center popup__more" />
              <CustomLink
                to={"/announcement/" + slug}
                className="popup__button link-reset"
                onClick={untoggle}
              >
                Ver mais
              </CustomLink>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default withContainers(PopUpLayout,[PopUpContainer, ResizeContainer])
