import React, { Component } from 'react';
import { capitalize } from '../../../utils/utils' 
import Moment from 'moment' 
import { connect } from 'react-redux';
import styled from 'styled-components'
import { FilePicker } from 'react-file-picker'
import { ToastStore } from 'react-toasts';
import { UserService } from '../../../services'
import userImg from '../../../assets/img/user-placeholder.svg';
import { saveUser } from '../../../actions/userActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import {prefix, t} from '../../../i18n'

const tUser = prefix('user')

const Avatar = styled.img`
  border: 5px solid ${props=> props.borderColor}
`
class UserProfile extends Component{

  constructor(props){
    super(props);
    this.state={};
  }
  onChangeImage(file){
    
    const formData = new FormData();
    this.props.startFetch();
    formData.append('user[avatar]', file, file.name );
    UserService.updateAvatar(formData).then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.user));
      this.props.saveUser(response.data.user);
      this.props.endFetch();
    })
    .catch((error) => {
      ToastStore.error(tUser('image-error'));
      this.props.endFetch();
    })
  }

  render(){
    const {user,campaign, campaignUser} = this.props

    const {
      expiredPoints,
      spentPoints,
      availablePoints
    } = campaignUser
    
    let { lastDate } = campaignUser
    lastDate = lastDate <= 0 ? "-" : Moment(lastDate).format(t('date-format'))
    return(
      <div>
        <div style={{background: campaign.color_primary }} className='user-info'>
          <div className="row pt-3 pb-3">
            <div className='relative mx-auto'>
            <Avatar 
              borderColor={campaign.color_secondary}
              className='user-profile' 
              src={user.avatar.url ? user.avatar.url : userImg}
            />
            <FilePicker
              extensions={['jpg', 'jpeg', 'png']}
              onChange={FileObject => (this.onChangeImage(FileObject))}
              onError={errMsg => ToastStore.error(tUser('unknown-format'))}
            >
              <i className='fa fa-camera avatar-picker' style={{background: campaign.color_secondary}}></i>
            </FilePicker>
            </div>
          </div>
          <div className='user-name'>
          <p>{capitalize(user.first_name)}   {user.last_name}</p>
          </div>
        </div>
        <div style={{background: `linear-gradient(50deg, ${campaign.color_secondary}, ${campaign.color_primary})`}} className='user-points'> 
          <p>{availablePoints} Pontos</p>
        </div>
        <div className='user-history uppercase'>
          <div className='user-history-container'>
            <h4 style={{color: campaign.color_primary }} className='user-history-title'>{tUser('last-upload')}</h4>
            <p className='user-history-text'>{lastDate}</p>
          </div>
          { expiredPoints > 0 && 
          <div className='user-history-container'>
            <h4 style={{color: campaign.color_primary }} className='user-history-title'>{tUser('extint-points')}</h4>
            <p className='user-history-text'>{expiredPoints}</p>
          </div>
          } 
          <div className='user-history-container user-used-points'>
            <h4 style={{color: campaign.color_primary }} className='user-history-title'>{tUser('total-points')}</h4>
            <p className='user-history-text'>{spentPoints}</p>
          </div>
        </div>
    </div>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(null,mapDispatchToProps)(UserProfile);
 