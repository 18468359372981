import styled, { css } from "styled-components"

const ClassBuilder = styled.div`
  font-style: inherit;
  ${props =>
    props.campaign &&
    css`
    .primary-text {
      color: ${props.campaign.color_primary}
    }
    .secondary-text {
      color: ${props.campaign.color_secondary}
    }

    .navbar-text {
      color: ${props.campaign.navbar_text_color}
    }

    .text-color {
      color: ${props.campaign.text_color}
    }

    .primary-bg {
      background: ${props.campaign.color_primary}
      fill: ${props.campaign.color_primary}
    }

    .primary-fill {
      fill: ${props.campaign.color_primary}
    }
    .secondary-fill {
      fill: ${props.campaign.color_secondary}
    }
    
    .mish-fill {
      fill: ${props.campaign.navbar_text_color}
    }

    .primary-bg-after {
      &:after {
        background: ${props.campaign.color_primary}
      }
    }

    .secondary-bg {
      background: ${props.campaign.color_secondary}
      fill: ${props.campaign.color_secondary}
    }

    .text-bg {
      background: ${props.campaign.text_color}
      fill: ${props.campaign.text_color}
    }

    .mish-bg {
      background: ${props.campaign.navbar_text_color}
      fill: ${props.campaign.navbar_text_color}
    }

    .border-primary {
      border-color: ${props.campaign.color_primary}
    }
    .border-secondary {
      border-color: ${props.campaign.color_secondary}
    }
    .border-mish {
      border-color: ${props.campaign.navbar_text_color}
    }

    .half-gradient {
      background: linear-gradient(to bottom, ${
        props.campaign.color_primary
      } 20%, #973031 50%, white 50%)
    }
    .campaign-link {
      position:relative;
      color: ${props.campaign.navbar_text_color};
      &:after {
        transition: background-color 300ms ease-in;
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: transparent;
      }
      &:visited {
        color: ${props.campaign.navbar_text_color};
      }
      &:hover {
        color: ${props.campaign.color_primary};
        &:after {
          background: ${props.campaign.color_primary};
        }
        path, circle, rect {
          stroke: ${props.campaign.color_primary};
        }
      }
    }
    .campaign-link-icon {
      &:hover {
        path, circle, rect, polyline, line {
          stroke: ${props.campaign.color_primary};
        }
      }
    }
    .campaign-drop-down-item {
      &:hover {
        & > path, & >  circle, & > rect, & > polyline, & > line {
          stroke: ${props.campaign.color_primary};
        }
        color: ${props.campaign.color_primary};
      }
    }
    .active-link {
      position:relative;
      &:visited {
        color: ${props.campaign.color_primary};
      }
      &:hover {
        color: ${props.campaign.color_primary};
      }
      &:after {
        content: '';
        position:absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        background: ${props.campaign.color_primary}
      }
    }

    .active-link-secondary {
      color: ${props.campaign.navbar_text_color};

      position:relative;
      &:visited {
        color: ${props.campaign.navbar_text_color};
      }
      &:hover {
        color: ${props.campaign.color_secondary};
      }
    }

    .o-custom-select {

      &__menu, __menu-list {
        border-radius: 0px;
        width: calc(100% - 1rem)
      }

      &__control {
        border-color: #8E959B;
        box-shadow: none;
        border-radius: 0px;
        &--is-focused, &--menu-is-open {
          border: 1px solid ${props.campaign.navbar_text_color};
          &:hover {
            border: 1px solid ${props.campaign.navbar_text_color};
          }
        }
      }

      &__option {
        width: calc(100% - 10px);
        margin: 0 5px;
        color: #8E959B;
        cursor: pointer;
        background: white;
        
        &--is-focused  {
          color: ${props.campaign.navbar_text_color};
          background: #FAFAFA;
        }

        &--is-selected {
          color: ${props.campaign.navbar_text_color};
          background: #FAFAFA;
        }

        &:active {
          background: ${props.campaign.navbar_text_color};
          background: ${props.campaign.text_color};
          
        }
      }
    }
    .g-rankings__dots{
      color: white
      .slick-active {
        span {
          color : ${props.campaign.color_secondary};
        }
      }
    }
    .level-active{
      g{
        g{
          use{
            fill: ${props.campaign.color_primary}
          }
        }
      }
    }
    .level-next-level{
      g{
        g{
          use{
            fill: ${props.campaign.color_secondary}
          }
        }
      }
    }
    .level-locked{
      g{
        g{
          use{
            fill:gray
          }
        }
      }
    }

  `}
`
export default ClassBuilder
