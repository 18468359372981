import React from "react"
import { Link } from "react-router-dom"
import StyledImage from "../../shared/StyledImage"
import { Chevron, substring } from "../../normalizer"
  
const PromotionPost = ({content, imageSize, limitText}) => {
  let size = 'tablet_image'
  let normlimitText = limitText
  if  ('tablet_image' === imageSize){
    normlimitText = 60
  } else if (!['extra_desktop_image','desktop_image'].includes(imageSize)){
    size = 'mobile_image'
  }
  const image = content.prev_img[size] ? content.prev_img[size].url : '';
  return (
    <Link 
      className="navbar-text g-home__promotion" 
      to={"/announcement/"+content.url}
    >
      <StyledImage url={image} alt="" className="g-promotion__image" />
      <div className="g-promotion">
        <label className="g-post__label">{content.content_title}</label>
        <div className="g-promotion__title">
          <h3 className="g-post__title g-post__title--promotion ">
            {content.title}
          </h3> 
        </div>
        <div className="g-promotion__description">
          <p>
            { substring(content.subtitle,normlimitText, '...')}
          </p>
          <Chevron className="primary-fill primary-fill g-home__new-chevron" />
        </div>
      </div>
    </Link>
  )
}

export default PromotionPost
