import React from 'react'

const InputKeyValue = ({ title, colSpan = 1, error, classNameData, classNameTitle, ...rest}) => {


  const tilteClass = classNameTitle !== undefined ? `gl-profile__info gl-profile__info--editable primary-text ${classNameTitle}` : "gl-profile__info gl-profile__info--editable"
  const dataClass = classNameData !== undefined ? `gl-profile__info--input w-full gl-profile__info gl-profile__info--value outline-none ${classNameData}` : "w-full gl-profile__info gl-profile__info--value outline-none"

  return (
    <div colSpan={colSpan} className="gl-profile__table-cell">
      <div className={tilteClass}>
        <span>{title}{error && <p className="error gl-small-title-size lowercase">{error.join(' ')}</p>}</span>
        <i className="gl-profile__info-icon far fa-edit"></i>
      </div>
      <input className={dataClass} {...rest}/>
    </div>
  )
}

export default InputKeyValue
