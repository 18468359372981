import axios from './interceptor'
export const CampaignService = {
  getFeatures({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/features`;
    return axios({
      method: 'get',
      url: url
    })
  },
  getManualUrl(clientSlug, campaignSlug){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/download_manual`
    return axios({
      method: 'get',
      url: url
    })
  },
  getPagesList({client_slug, slug}){
    const url = `/api/clients/${client_slug}/campaigns/${slug}/pages`;
    return axios({
      method: 'get',
      url: url
    });
  },
  getPageContent({client_slug, slug, page_slug, detailed=false}) {
    const url = `/api/clients/${client_slug}/campaigns/${slug}/pages/${page_slug}`;
    return axios({
      method: 'get',
      url: url,
      params: {
        detailed,
      }
    });
  },
};
