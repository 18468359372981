import React, { Component } from 'react';
class Terms extends Component{

  constructor(props){
    super(props);
  }

  render(){
    const {
      text,
      seeMore,
      onClickEvent
    } = this.props

    return(
      <div className='col-xs-12 leading-normal text-left py-10' >  
        <div 
          dangerouslySetInnerHTML={{ __html: seeMore ? text : text.substring(0,1000) + '...' }}>
        </div>
        { !seeMore && 
          <div className=' end-xs end-lg end-sm end-md col-sm col-lg col-md col-xs'>
            <button  onClick={() => onClickEvent()} className='btn-secondary user-button'>Ver mais</button>
          </div>
        }
      </div>
    )
  }

}
export default Terms;