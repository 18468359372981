import React from 'react'
import { NavLink as Link } from 'react-router-dom'

const NavigationLink = ({excludeDisplayClass, className, ...rest}) => {
  const displayClassName = excludeDisplayClass ? '' : 'flex'
  const currentClassName = [className, displayClassName, 'campaign-link g-navbar__link'].join(' ')
  return (
    <Link {...rest}
      activeClassName ="active-link"
      className={currentClassName}
    />
  )
}

const NavbarButton = ({className, ...rest}) => {
  const currentClassName = [className, 'campaign-link g-navbar__link'].join(' ')
  return (
    <button {...rest}
      className={currentClassName}
    />
  )
}

export default NavigationLink

export { NavbarButton }
