import React, { Component } from 'react';
import SimpleSlider from './SimpleSlider';

class ProductCarousel extends Component{

  static defaultProps = {
    fontColor: 'text-grey-darker'
  }

  selectCarousel = (icon) => {
      
      switch(icon){
        case 'highlights':
          return icon + ' mt-5 mini-icon p-0'
        case 'most-viewed':
          return icon + ' mt-5 mini-icon p-0'
        default:
          return 'fa-heart'
      }
  }

  render(){
    const {
      products, 
      campaign,
      productsNumber,
      title,
      catalog,
      icon,
      transparent,
      fontColor,
      boxify
    } = this.props

    const choosenCarousel = this.selectCarousel(icon)
    return(
      <div className={`product-carousel ${transparent? 'bg-transparent': 'bg-white' } soft-round`}>
        <div className={`row ${fontColor} pb-1 px-0`}>
          <div className={catalog ? 'col-lg col-md col-xs' :'col-lg-6 col-md-6 col-xs-6 px-0'}>
            <h2 className={catalog ? 'pt-5 text-center product-carousel-title uppercase mb-2' : 'pt-5 pl-6 text-left product-carousel-title uppercase' }>{title}</h2>
          </div>
          <div className="col-lg-6 col-md-6 col-xs-6 text-right text-4xl pr-8" style={{display: this.props.catalog ? 'none' : ''}}>
            <i className={`pt-5 far ${choosenCarousel} text-grey-dark`}></i>
          </div>
        </div>
        <div className={`mb-4 flex pl-6 ${catalog ?  'justify-center': '' }`}>
          <span className="hr-line" style={{backgroundColor: this.props.lineColor}}/>
        </div>
        <div className="row p-5 product-carousel carousel-inside" >
          <SimpleSlider 
            boxify={boxify} 
            productsNumber={productsNumber} 
            products = {products} 
            campaign = {campaign} 
            color={this.props.lineColor}/>
        </div>
      </div>
    )
  }
}

export default ProductCarousel;