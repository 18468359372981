import React from 'react'
import { Switch } from 'react-router-dom'
import RankingsLayout from './RankingLayout'
import {default as Route} from '../../../routes/CustomRoute';
import { withRankingsContainer } from '../normalizer'

import EmptyStateLayout from '../shared/EmptyStateLayout';
import { ReactComponent as NoRankings } from '../../../assets/img/rankings.svg'

const EmptyRankingsMessage = "De momento não temos rankings para apresentar."

const Rankings = ({rankings}) => {
  const hasRankings = rankings.length > 0
  return(
    <div className= "g-rankings"> 
    { hasRankings ?
      <Switch>
        <Route 
          path="/rankings/:id"
          component={RankingsLayout}
          />
          <Route 
          path="/rankings"
          component={RankingsLayout}
          />
      </Switch>
      : 
      <EmptyStateLayout message={EmptyRankingsMessage}>
        <div className="g-no-store">
          <NoRankings className="mr-3 dead-grey g-no-store__image"></NoRankings>
        </div>
      </EmptyStateLayout> 
    } 
    </div>
    
  
  )
}

export default withRankingsContainer(Rankings);