import CatalogOptions from './CatalogOptions';
import CatalogFilters from './CatalogFilters';
import CatalogSimpleHeader from './CatalogSimpleHeader';
import React, { Component } from 'react'

export class CatalogHeader extends Component {

  render() {
    const {
      campaign,
      onProductTextSearch,
      optionActions,
      defaultValues,
      optionState,
      onBrandChange,
      brands,
      selectedBrand,
      onFilterHide,
      onFilterShow,
      generateFilterActions,
      generateFilterState,
      filtering,
      orderOptions,
      selectedOrder,
      onOrderChange
    } = this.props;

    return (
      <>
        <CatalogSimpleHeader
        searching
        filtering={filtering}
        onFilterHide={onFilterHide}
        onFilterShow={onFilterShow}
        onProductTextSearch={onProductTextSearch}
        />
        <div className={["catalog__search-elements", filtering && 'catalog__search-elements--extended'].join(' ')}>
          <hr className="catalog__horizontal-line"></hr>
          <div className="py-2">
            {/* SearchButton */}
            <CatalogOptions
              campaign={campaign}
              optionState={optionState}
              optionActions={optionActions}
              defaultValues={defaultValues}
              onBrandChange={onBrandChange}
              brands={brands}
              selectedBrand={selectedBrand}
              generateFilterActions={generateFilterActions}
              generateFilterState={generateFilterState}
              selectedOrder={selectedOrder}
              onOrderChange={onOrderChange}
              orderOptions={orderOptions}
            />
          </div>
          <hr className="catalog__horizontal-line"></hr>
          <div>
            <CatalogFilters
              campaign={campaign}
              filterActions={generateFilterActions()}
              filterState={generateFilterState()}
            />
          </div>
        </div>
      </>
    )
  }
}

export default CatalogHeader
