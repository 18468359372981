import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import { OrdersService } from '../../../services'
import { saveOrders } from '../../../actions/ordersActions';
import { startFetch, endFetch} from '../../../actions/generalActions';
import ReactPaginate from 'react-paginate'
import OrderRow from './OrderRow';
import OrderPaymentDetailsModal from '../shared/OrderPaymentDetailsModal'

import {prefix} from '../../../i18n'

const tOrders = prefix('orders')
class UserOrders extends Component{
  constructor(props){
    super(props); 
    this.state = { showPaymentDetailsModal: false, orderPayment: null}
  }

  getOrders(){
    this.props.startFetch();
    const {
      user,
      campaign,
      page
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug,
      page: page
    }

    OrdersService.getOrders(payload).then((response) => {
      const orders = response.data
      this.props.saveOrders(orders);
      this.props.endFetch();
    });
  }

  componentDidMount(){
    if (this.props.orders.length === 0){
      this.getOrders()
    } 
  }

  componentDidUpdate(prevProps){
    if(this.props.page !== prevProps.page ){
      this.getOrders()
    }

    if( this.props.campaign.slug !== prevProps.campaign.slug) {
      this.getOrders()
    }
  }

  openModal = (orderPayment) => {
    this.setState(prevState => ({
      ...prevState,
      orderPayment,
      showPaymentDetailsModal: true,
    }))
  }

  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      orderPayment: null,
      showPaymentDetailsModal: false,
    }))
  }

  render() {
    const {
      campaign,
      orders,
      user,
      page
    } = this.props
    return (
      <>
      <table className='mt-12 w-full '>
        <thead>
          <tr className='user-header text-center uppercase'>
            <th className='w-1/5 prod-th-title order-th '>{tOrders('nr')}</th>
            <th className='w-1/5 prod-th-title '>{tOrders('date')}</th>
            <th className='w-1/5 prod-th-title '>{tOrders('points')}</th>
            <th className='w-1/5 prod-th-title order-th sm:no-display'>{tOrders('state')}</th>
            <th className='w-1/5 prod-th-title order-th sm:no-display'>{tOrders('info')}</th>
            <th className='w-1/5 prod-th-title order-th '>{tOrders('received')}</th>
          </tr>
        </thead>
        <tbody className="break hyphens">
          { 
            orders.map((order,i) => 
              <OrderRow 
                key={i} 
                user={user}
                campaign={campaign} 
                order={order}
                page={page}
                openOrderPaymentDetailsModal={this.openModal}
              />
            )      
          }
        </tbody>
      </table>
      <OrderPaymentDetailsModal
        show={this.state.showPaymentDetailsModal}
        close={this.closeModal}
        orderPayment={this.state.orderPayment}
        bgColor={campaign.color_primary}
        textColor={campaign.footer_text_color}
      />
      </>
    );
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveOrders: (orders) => {
      dispatch(saveOrders(orders))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(null,mapDispatchToProps)(UserOrders);
