import React, { Component } from 'react'
import NotificationItem from './NotificationItem';

export default class NotificationList extends Component {

  handleClick = (e) => {
    const isContainer = !!this.node.contains(e.target)
    if(!isContainer && this.props.opened) {
      this.props.onListHide()
    }
  }

  componentDidMount(){
    document.addEventListener('mousedown', this.handleClick, false )
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClick, false )
  }

  render() {
    const {
      notifications,
      opened, 
      numNotificationsToShow, 
      onListShow, 
      onListHide,
      onNotificationView,
      onHandleScroll
    } = this.props
  
    const hasNotifications = numNotificationsToShow > 0 
  
    return (
      <div ref={node => this.node = node}>
        <div 
          id='notification-bell'
          onClick={opened ? onListHide : onListShow}
        >
          <i className="fas fa-bell text-lg p-4 above-content cursor-pointer" />
          {hasNotifications &&  <span className="items-center d-notification__count text-xs primary-bg text-white">
                                  {numNotificationsToShow}
                                </span>}
        </div>
        {opened && 
          <div className="d-notification__dropdown default-bg  primary-text" >
            <div className="notification__dropdown__scrolling" id="notification-dropdown" onScroll = { onHandleScroll } >
              {
                notifications.map(n => (
                  <NotificationItem 
                    onNotificationView={onNotificationView} 
                    key={n.id} 
                    {...n}
                  />
                ))
              }
              {!hasNotifications &&  notifications.length === 0 &&
                <div className="flex flex-col items-center ">
                  <i className="fas fa-bell-slash text-xl mb-2"></i>
                  <p className="text-size">Sem notificações</p>
                </div>
              }
            </div>
          </div> 
        }
      </div>
    )
  }
}
