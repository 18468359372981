import React from "react"
import { Link } from "react-router-dom"
import { withClientContainer } from "../normalizer"

const If = ({ condition, children }) => {
  if (condition) {
    return children
  } else {
    return null
  }
}

const Footer = ({ fontFamily, client, campaign, features, clientSlug }) => {
  //console.log('clientSlug:', clientSlug);
  const footerClasses = [
    "bgl-footer footer",
    fontFamily
  ].join(" ") 

  const findFeatureByName = (name) => {
    const feature = features.find(f => f.name === name)

    return feature
  }

  const showRegulations = () => {
    const feature = findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? campaign.show_regulations : true
  }

  const showRules = () => {
    const feature = findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? campaign.show_rules : true
  }
  
  const showCompanyTrademark = () => {
    const feature = findFeatureByName('Configuracao de Footer')

    return typeof feature != 'undefined' ? campaign.show_company_trademark : true
  }
    /* Caso seja preciso, colocar depois da classe g-footer__main
        {campaign.manual !== "" && (
        <div className="g-footer--margin-bottom-40">
          <h3 className="g-footer__title">Sobre o VPlay</h3>
            <ul className="g-footer--items-no-style g-footer--center-ul">
              <li className="g-footer__item">
                <a className="g-footer--link" onClick={(e)=>this.fetchManual(e)}>
                  Manual de utilização da Plataforma
                </a>
              </li>
            </ul>
        </div>)
      }
  */
  const generalNotesLink = "https://incentive-platform-prod.s3-eu-west-1.amazonaws.com/imagens/Notas+Gerais+VStore.pdf"
  return (
    clientSlug === 'vodafone' ? (
      // Código para o footer da Vodafone
    <footer className="vodafone footer text-color g-footer break-words sm:break-normal">
        <div className="g-footer__container p-container">
          <div className="g-footer__main">
            <div className="g-footer--margin-bottom-40">
              <h3 className="g-footer__title">Outras informações</h3>
              <ul className="g-footer--items-no-style g-footer--center-ul">
                <If condition={showRegulations()}>
                  <li className="g-footer__item">
                    <Link className="g-footer--link" to={"/utility-info/regulation"}>
                      Regulamento (Termos, Condições e Privacidade)
                    </Link>
                  </li>
                </If>
                <If condition={showRules()}>
                  <li className="g-footer__item">
                    <Link className="g-footer--link" to={"/utility-info/rules"}>
                      Regras
                    </Link>
                  </li>
                </If>
                <li className="g-footer__item">
                  <a className="g-footer--link" target="_blank" href={generalNotesLink}>
                  Notas Gerais
                  </a>
                </li>
              </ul>
            </div>
            <div className="g-footer--margin-bottom-40">
              <h3 className="g-footer__title">Contactos</h3>
              <ul className="g-footer--items-no-style g-footer--center-ul">
                <div className='pb-2'>
                  <li className="g-footer__item-vodafone-contacts leading-normal">
                    <span className='text-lg'>Vodafone:  </span>
                    <a className="g-footer--contacts-link" href="mailto:unp_comissoes_e_incentivos@vodafone.com">
                      unp_comissoes_e_incentivos@vodafone.com
                    </a>
                    <br />
                    <span className='text-xs'>(questões de utilização da plataforma, dificuldades técnicas e esclarecimentos sobre report de atividade de vendas)</span>
                  </li>
                </div>
                <li className="my-3">
                  <span className='text-lg'>Incentivehouse:  </span>  
                  <a className="g-footer--contacts-link" href="mailto:vplay_incentivos@incentivehouse.com.pt">
                    Vplay_incentivos@incentivehouse.com.pt
                  </a>
                  <br />
                  <span className='text-xs'>(informações sobre Loja de Prémios - encomendas e entrega de prémios)</span>
                </li>
              </ul>
            </div>  
          </div>
          <If condition={showCompanyTrademark()}>
            <div className="g-footer__contract-list">
              <div>
                <p>© 2019 IncentiveHouse</p>
              </div>
            </div>
          </If>
        </div>
      </footer>
    ) : (
      // Código para o footer padrão
    <footer className={footerClasses}>
      <div className="gl-footer__container gl-p-container">
        <div className="gl-footer__main">
          <div className="gl-footer--margin-bottom-40">
            <ul className="gl-footer--items-no-style gl-footer--center-ul">
              <If condition={showRegulations()}>
                <li className="gl-footer__item pb-2">
                  <Link className="gl-footer--link" to={"/utility-info/regulation"}>
                    Consultar Regulamento 
                  </Link>
                </li>
              </If>
              <If condition={showRules()}>
                <li className="gl-footer__item pb-2">
                  <Link className="gl-footer--link" to={"/utility-info/rules"}>
                    Consultar Regras
                  </Link>
                </li>
              </If>
              <li className="gl-footer__item pb-2">
                <Link className="gl-footer--link" to={"/terms"}>
                  Consultar Termos e Condições de Serviços 
                </Link>
              </li>
            </ul>
          </div>
          <div className="gl-footer--margin-bottom-40">
            <div className="gl-footer__title">Contactos</div>
            <ul className="gl-footer--items-no-style gl-footer--center-ul">
              <li className="gl-footer__item">
                <i className="fa fa-phone fa-rotate-90 mr-3"/> 210 967 025
              </li>
              <li className="gl-footer__item">
                <i className="fa fa-envelope mr-3"/>{client ? client.email : '-'}
              </li>
            </ul>
          </div>
        </div>
        <If condition={showCompanyTrademark()}>
          <div className="gl-footer__contract-list">
            <div>
              <p>© 2019 IncentiveHouse</p>
            </div>
          </div>
        </If>
      </div>
    </footer>
    )
  )
}

export default withClientContainer(Footer)
