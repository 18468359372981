import React, { Component } from 'react'
import ContentTitle from '../shared/ContentTitle'
import ReactSelect from 'react-select'
export default class InvoiceProductForm extends Component {

  render() {
    const {
      createMode,
      brands, groups,
      categories, products,
      saleTypes,
      onCancel, selectedSaleType, selectedProduct, selectedBrand, selectedGroup, selectedCategory
    } = this.props
    const title = createMode ? "Adicionar novo produto" : "Editar produto"
    return (
      <div className="template-container">
        <ContentTitle title={title} sub/>
        <form onSubmit={this.props.onSubmit} >
          <InputContainer label="Tipo de venda">
            <ReactSelect
              isClearable
              onChange={this.props.onSaleTypeChange}
              options={saleTypes}
              value={selectedSaleType}
              defaultValue={selectedSaleType}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder='tipo de venda...'
              className='w-full'
              classNamePrefix='custom-select'/>
          </InputContainer>
          <InputContainer label="Marca">
            <ReactSelect
              isClearable
              onChange={this.props.onBrandChange}
              options={brands}
              value={selectedBrand}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder='marca...'
              className='w-full'
              classNamePrefix='custom-select'/>
          </InputContainer>
          <InputContainer label="Grupo">
            <ReactSelect
              isClearable
              onChange={this.props.onGroupChange}
              options={groups}
              value={selectedGroup}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              placeholder='grupo...'
              className='w-full'
              classNamePrefix='custom-select'/>
          </InputContainer>
          <InputContainer label="Categoria">
            <ReactSelect
              isClearable
              onChange={this.props.onCategoryChange}
              options={categories}
              value={selectedCategory}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder='categoria...'
              className='w-full'
              classNamePrefix='custom-select'/>
          </InputContainer>
          <InputContainer label="Produto - Código">
            <ReactSelect
              isClearable
              onChange={this.props.onProductChange}
              options={products}
              value = {selectedProduct}
              getOptionLabel={(option) => option.external_identifier}
              getOptionValue={(option) => option.id}
              placeholder='código...'
              className='w-full'
              classNamePrefix='custom-select'/>
          </InputContainer>
          <Input
            label="Quantidade"
            type="number"
            name="quantity"
            min="1"
            defaultValue={1}

            className="py-2 px-3 rounded w-1/5"
          />
          <div className="flex justify-between col-xs-12 mt-6">
            <button  type="button" className="mish-bg navbar-text p-2 rounded " onClick={onCancel}>Cancelar</button>
            <button  className="primary-bg navbar-text p-2 rounded ">Adicionar</button>
          </div>
        </form>
      </div>
    )
  }
}

const Input = ({label, type="text", ...rest}) => (
  <InputContainer label={label}>
    <input type={type} {...rest}/>
  </InputContainer>
)

const InputContainer = ({label, children, className}) => (
  <div className={`flex flex-col mb-2 ${className ||''} col-xs font-quicksand-light`}>
    <label className="mb-1 ">{label}</label>
    <div className="flex flex-wrap">
      {children}
    </div>
  </div>
)
