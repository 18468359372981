import React, { Component } from 'react';
import { prefix } from '../../../i18n';
import { Redirect } from 'react-router-dom';
import CartItemsTable from './CartItemsTable';
import {ToastStore} from 'react-toasts';
import CartButtons from './CartButtons';
import { withContainers, ShoppingCartContainer, CampaignUserContainer } from '../../containers/gamification';
import CartWarning from './CartWarning';
import CartCheckoutModal from './shared/CartCheckoutModal';
import CoPaymentModal from './shared/CoPaymentModal'
import PaymentMethodsModal from './shared/PaymentMethodsModal'

const tCartCheckout = prefix("cart-checkout")

class CartCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      showAlert: false,
      alertMsg: "",
      showPaymentMethodsModal: false,
      showCoPaymentConditionsModal: false,
      shouldUseCoPayment: false,
      acceptCoPaymentConditions: false,
      currentStage: 0,
      userHasNoPoints: false,
      checkoutStages: [
        "checking-points",
        "checking-percentage",
        "checking-cart",
        "checking-terms",
        "choosing-payment-method",
        "checking-end",
      ],
      selectedPaymentMethod: null,
      hasEnoughPoints: false,
      overcomeMaxPercentage: false,
      restingPoints: 0,
      noCoPaymentItems: [],
      debtPoints: 0,
    };
  }

  componentDidUpdate(prevState, prevProps) {
    const { campaign: { confirmation_message } } = this.props;
    if ( prevState && prevState.checkingEnd && this.state.checkingEnd && prevState.checkingEnd !== this.state.checkingEnd ) {
      this.setAlertMsg(confirmation_message);
    }
  }

  componentDidMount() {
    const { cart: { allows_co_payments, payment_definitions, total_points, items }, campaignUser: { availablePoints }, getUserCheckoutRestrictions } = this.props;
    const maximum_percentage = payment_definitions ? payment_definitions.maximum_percentage : 0;
    const noCoPaymentItems = this.filterNoCoPaymentProducts(items);
    const { userHasNoPoints, hasEnoughPoints, overcomeMaxPercentage, restingPoints, debtPoints } = getUserCheckoutRestrictions(availablePoints, total_points, maximum_percentage);
    this.setState((prevState) => ({
      ...prevState,
      allows_co_payments,
      userHasNoPoints,
      hasEnoughPoints,
      overcomeMaxPercentage,
      restingPoints,
      debtPoints,
      noCoPaymentItems,
    }));
  }

  openAlertModal = () => {
    const { userHasNoPoints, hasEnoughPoints, allows_co_payments } = this.state;
    if (userHasNoPoints && !allows_co_payments) {
      ToastStore.error(tCartCheckout("user-has-no-points"))
    } else if ( !hasEnoughPoints && !allows_co_payments) {
      ToastStore.error(tCartCheckout("user-has-not-enough-points"))
    } else {
      this.setState((prevState) => ({
        ...prevState,
        showAlert: true,
        showCoPaymentConditionsModal: true,
      }));
    }
  };

  closeAlertModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  openCoPaymentConditionsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: true,
    }));
  };

  closeCoPaymentConditionsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCoPaymentConditionsModal: false,
    }));
  };

  openPaymentMethodsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: false,
      showPaymentMethodsModal: true,
    }));
  };

  closePaymentMethodsModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showPaymentMethodsModal: false,
    }));
  };

  setAlertMsg(message) {
    this.setState((prevState) => ({
      ...prevState,
      alertMsg: message,
    }));
  }

  setAcceptCoPaymentConditions = (e) => {
    e.preventDefault();
    const { target } = e;
    this.setState((prevState) => ({
      ...prevState,
      acceptCoPaymentConditions: target.checked,
      showAlert: true,
      showCoPaymentConditionsModal: false,
      showPaymentMethodsModal: true,
      currentStage: this.nextStage(),
    }));
  };

  goToTermsStage = () => {
    this.setState((prevState) => ({
      ...prevState,
      showAlert: false,
      showCoPaymentConditionsModal: true,
      currentStage: this.nextStage(),
    })); 
  }

  goToFinalStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: 5 })) 
  }

  goToNextStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: this.nextStage() })) 
  }

  goToPreviousStage = () => {
    this.setState(prevState => ({ ...prevState, currentStage: this.previousStage() })) 
  }
  
  previousStage = () => {
    const { currentStage, checkoutStages } = this.state
    return ( currentStage - 1) % checkoutStages.length
  }

  nextStage = () => {
    const { currentStage, checkoutStages } = this.state
    return (currentStage + 1) % checkoutStages.length
  }

  handleShouldUseCoPayment = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCoPaymentConditionsModal: true,
      shouldUseCoPayment: true,
      currentStage: this.nextStage(),
    }));
  };

  handleWishRemoveProducts = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentStage: this.nextStage(),
      redirect: true,
    }));
  }

  handleChoosePaymentMethod = (method) => {
    this.setState(prevState => ({
      ...prevState,
      showAlert: true,
      showCoPaymentConditionsModal: false,
      selectedPaymentMethod: method,
      currentStage: this.nextStage(),
    }))
  }

  handleModalCancellationAction = () => {
    this.closeAlertModal();
  };

  filterNoCoPaymentProducts = (items) =>
    items.filter((item) => !item.product.accepts_co_payment);

  renderFinalModal = () => {
    const { onCheckout } = this.props
    const { showAlert, shouldUseCoPayment, acceptCoPaymentConditions, selectedPaymentMethod } = this.state;
    const content = {
      msg: tCartCheckout("order-confirmation")
    }

    const coPaymentParams = { shouldUseCoPayment, acceptCoPaymentConditions, selectedPaymentMethod }

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={() => onCheckout(coPaymentParams)}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderHasNotEnoughPointsModal = () => {
    const { showAlert, debtPoints } = this.state;
    const content = {
      alertMsg: tCartCheckout("insuficient-points").replace("{points}", Math.abs(debtPoints)),
      items: null,
      shouldUseCoPayment: false,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleShouldUseCoPayment}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderOvercomeMaxPercentageModal = () => {
    const { showAlert, restingPoints } = this.state;
    const content = {
      alertMsg: tCartCheckout("exceeded-percentage").replace("{restingPoints}", Math.abs( Math.floor(restingPoints * 100) / 100)),
      items: null,
      shouldUseCoPayment: false,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleWishRemoveProducts}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderWithNoCoPaymentProductsModal = () => {
    const { showAlert, noCoPaymentItems } = this.state;
    const content = {
      alertMsg: tCartCheckout("products-dont-allow-co-payment"),
      items: noCoPaymentItems,
      shouldUseCoPayment: true,
    };

    return (
      <CartCheckoutModal
        selected={showAlert}
        content={content}
        onAccept={this.handleWishRemoveProducts}
        onNoAccept={this.handleModalCancellationAction}
      />
    );
  };

  renderCoPaymentTermsModal = () => {
    const { cart: { payment_definitions } } = this.props
    const { showCoPaymentConditionsModal, acceptCoPaymentConditions } = this.state;
    return (
        <CoPaymentModal
          show={showCoPaymentConditionsModal}
          close={this.closeCoPaymentConditionsModal}
          paymentDefinitions={payment_definitions}
          conditionsAccepted={acceptCoPaymentConditions}
          handleAcceptConditions={this.setAcceptCoPaymentConditions}
        />
    );
  };

  renderChoosePaymentMethodModal = () => {
    const { cart: { payment_methods: paymentMethods }, campaign: { rate } } = this.props;
    const { showPaymentMethodsModal, debtPoints } = this.state;
    return (
      <PaymentMethodsModal
        show={showPaymentMethodsModal}
        close={this.closePaymentMethodsModal}
        campaignRate={rate}
        debtPoints={Math.abs(debtPoints)}
        paymentMethods={paymentMethods}
        handleMethodChoosing={this.handleChoosePaymentMethod}
      />
    );
  };

  renderCheckoutModal = () => {
    const {
      hasEnoughPoints,
      currentStage,
      overcomeMaxPercentage,
      noCoPaymentItems,
    } = this.state;

    return currentStage === 0
      ? !hasEnoughPoints ? this.renderHasNotEnoughPointsModal() : this.goToFinalStage() 
      : currentStage === 1
      ? overcomeMaxPercentage ? this.renderOvercomeMaxPercentageModal() : this.goToNextStage() 
      : currentStage === 2
      ? noCoPaymentItems.length > 0 ? this.renderWithNoCoPaymentProductsModal() : this.goToTermsStage()
      : currentStage === 3
      ? this.renderCoPaymentTermsModal()
      : currentStage === 4
      ? this.renderChoosePaymentMethodModal()
      : this.renderFinalModal();
  };

  render() {

    const {
      onReturnToAddress,
      cart,
      campaign,
    } = this.props;
    const { redirect } = this.state

    const {
      shipment_address, items,
    } = this.props.cart;

    const {
      currency_label : currencyLabel
    } = this.props.client;

    if (items.length === 0 || redirect) {
      return <Redirect to="/cart" />;
    }

    if (!shipment_address){
      return <Redirect to='/cart' />
    }

    const {
      
      first_name : firstName,
      last_name : lastName,
      address1,
      city, 
      country, 
      zip_code
    } = shipment_address

    return (
      <div>
        <CartItemsTable
          currencyLabel={currencyLabel}
          cart={cart}
          checkout
        />
        <hr className="catalog__horizontal-line catalog__horizontal-line--no-margin"/>
        <div className="vodafone">
          <div className="mt-4">Morada da encomenda</div>
          <div className="mt-2 dead-grey">
            <div className="pt-4 dark-grey">{`${firstName} ${lastName}`}</div>
            <div className="flex justify-between mt-3">
              <div className=" mt-1">{address1}</div>
              <div className="g-cart__address__data mt-1">{zip_code}</div>
              <div className="g-cart__address__data mt-1">{city}, {country.name}</div>
            </div>
          </div>
        </div>
        <CartButtons onPrev={onReturnToAddress} onNext={this.openAlertModal} prevText="Voltar" nextText="Confirmar"/>
        <CartWarning text={campaign.call_attention} />
        {this.renderCheckoutModal()}
      </div>
    );
  }
}

export default withContainers(CartCheckout, [CampaignUserContainer,ShoppingCartContainer]);
