import React, { Component } from "react"
import { connect } from "react-redux"
import { SaleAttributionService, GroupPositionUserService, buildTransaction, moveToRequested } from "../../normalizer"
import { HOC } from "../../gamification"
import {ToastContainer, ToastStore} from 'react-toasts'

class UpdateAttributionContainer extends Component {
  state = {
    id: undefined,
    contractNumber: "",
    user: undefined,
    groupUsers: []
  }

  payload = () => ({
    campaignSlug: this.props.campaign.slug,
    clientSlug: this.props.user.client.slug
  })

  handleSubmit = async () => {
    try {
      const sale = await SaleAttributionService.updateSaleTrade({
        ...this.payload(),
        id: this.state.id,
        contract: this.state.contractNumber,
        user: this.state.user.id
      })
      this.props.moveToRequested(sale)
      this.props.history.push("/sale-trading/requested")
    } catch (error) {
      ToastStore.error(error.response.data.error)
    }
  }

  handleContractChange = value => {
    this.setState({ contractNumber: value.trim() })
  }

  handleUserChange = value => {
    this.setState({ user: value })
  }

  async fetchGroupUsers() {
    try {
      const response = await GroupPositionUserService.getGroupUsers(this.payload())
      this.setState({ groupUsers: response.data.users })
    } catch (error) {
      ToastStore.error(error.response.data.error)
    }
  }

  componentDidMount() {
    this.fetchGroupUsers()
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <>
        <ToastContainer store={ToastStore} />

        <Component
          {...rest}
          users={this.state.groupUsers}
          onUserChange={this.handleUserChange}
          selectedUser={this.state.user}
          onContractChange={this.handleContractChange}
          onSubmit={this.handleSubmit}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startTransaction: buildTransaction(dispatch),
    moveToRequested: (sale)=> dispatch(moveToRequested(sale))
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateAttributionContainer)

export function withUpdateAttributionContainer(component) {
  const withUpdateAttributionContainer = HOC(Container, component)
  return withUpdateAttributionContainer
}
export default Container
