import axios from './interceptor';

export const bonusPointService = {

  getBonusPoints({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/bonus_points`
    return axios({
      method: 'get',
      url: url
    });
  },
  getGivenPoints({clientSlug, campaignSlug, page}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/bonus_points/given_points`
    return axios({
      method: 'get',
      url: url,
      params:{
        page: page
      }
    });
  },
  givePoints(payload){
    const url = `/api/clients/${payload.clientSlug}/campaigns/${payload.campaignSlug}/bonus_points/give_points`
    return axios({
      method: 'post',
      url: url,
      params: {
        user_id: payload.selectedUserId,
        product_id: payload.selectedProductId,
        points: payload.points
      }
    })
  }
};