import React from 'react'
import {Link} from 'react-router-dom'
import cartImg from "../../../../assets/img/cart.png"
import userImg from "../../../../assets/img/user-placeholder.svg"
import styled, {css} from 'styled-components'
import NotificationList from "../../Notifications";

const NavigationStyles = styled.div`
  ${props => css`

    .nav__image-background {
      background: linear-gradient(to right, white 0%, white 50%, ${props.campaign.color_primary} 50%,  ${props.campaign.color_primary} 100% );
    }
    .nav__points { 
      background: linear-gradient(40deg, ${props.campaign.color_secondary},  ${props.campaign.color_primary});
    }
    .nav__notifications {
      background: linear-gradient(40deg, ${props.campaign.color_primary}, ${props.campaign.color_secondary});
    }
  
  `}
`

const UserSection = ({user, toRender, campaign, campaignUser, cart}) => {

    const userFirstNameReduced =
      user.first_name && user.first_name.length > 10
        ? user.first_name.substring(0, 10) + "... "
        : user.first_name || "Sem"

    const userLastNameReduced =
      user.last_name && user.last_name.length > 10
        ? user.last_name.substring(0, 10) + "..."
        : user.last_name || "Nome"
  return (
    <NavigationStyles campaign={campaign}>
      <div className="cont row">
       
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs center-xs">
          <img className="logo" src={campaign.logo.url} alt=""/>
        </div>
      
        <div className="user-bar col-lg-9 col-md-9 col-sm-9 col-xs-12 my-auto py-4">
          <div className="row end-lg end-md center-xs w-full ">
            <div
              className="div-profile-image relative nav__image-background"
            >
              <img
                className="profile-image border-secondary"
                alt=""
                src={
                  user.avatar.thumb.url ? user.avatar.thumb.url : userImg
                }
              />
            </div>
          
            <Link
              to="/profile"
              className="col-blue my-auto navbar-text primary-bg"
            >
              <div className="content-user-bar">
                <p className="text-left font-bold">
                  {userFirstNameReduced + " " + userLastNameReduced}
                </p>
                <p className="text-left font-bold">{user.login}</p>
              </div>
            </Link>
            <Link
              to="/profile/balance"
              className=" my-auto nav__points navbar-text"
            >
              <div className="content-user-bar text-center">
                <p className="text-xl font-bold">
                  {campaignUser.availablePoints || 0}
                </p>
                <p className="text-xs">pontos</p>
              </div>
            </Link>
            { toRender('Notificações-InApp') && 
              <div className="d__notification__container nav__notifications navbar-text flex items-center justify-center ">
                <NotificationList /> 
              </div> 
            }
            <div
              className="text-center cart-container mish-bg navbar-text no-underline"
            >
              <Link
                className="cart-link"
                to="/cart"
              >
                <img src={cartImg} alt="" className="cart-image" />
                <span className="cart-items font-quicksand-light">
                  {cart.items.length || 0}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </NavigationStyles>

  )
}

export default UserSection
