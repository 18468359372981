import React, { Component } from "react"
import { Link } from "react-router-dom"
import { AnalyticsIcon, LogoutIcon } from "../../../normalizer"

const DropdownItem = props => {
  const { title, className, icon: Icon, ...rest } = props
  const classes = [
    "link-reset g-navbar__dropdown-item campaign-drop-down-item flex items-center campaign-link-icon",
    className
  ].join(" ")

  return (
    <Link {...rest} className={classes}>
      <Icon className="g-navbar__icons16 mr-3" />
      <p>{title}</p>
    </Link>
  )
}

const LogoutButton = ({ className, onLogout }) => {
  const classes = [
    "g-navbar__dropdown-item flex items-center campaign-drop-down-item campaign-link-icon",
    className
  ].join(" ")

  return (
    <span className={classes} onClick={onLogout}>
      <LogoutIcon className="g-navbar__icons16 mr-3" />
      <p>Logout</p>
    </span>
  )
}

export default class Dropdown extends Component {
  state = {
    viewMore: false
  }

  onViewMore = () => {
    this.setState({ viewMore: true })
  }

  onViewLess = () => {
    this.setState({ viewMore: false })
  }

  handleClick = e => {
    const isContainer = !!this.node.contains(e.target)
    if (!isContainer && this.state.viewMore) {
      this.onViewLess()
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false)
  }

  render() {
    const { viewMore } = this.state

    const dropdownClass = [
      viewMore && "g-navbar__dropdown g-content-shadow navbar-text",
      !viewMore && "hidden"
    ].join(" ")

    return (
      <span
        className="pl-3 py-3 above-content cursor-pointer relative campaign-drop-down-item mobile-hide"
        onClick={viewMore ? this.onViewLess : this.onViewMore}
      >
        <i className="fas fa-ellipsis-h navbar-text" />
        <div className={dropdownClass} ref={ref => (this.node = ref)}>
          { this.props.campaignUser.canSeeAnalytics &&
          <DropdownItem
            to="/analytics"
            title="Dashboard"
            icon={AnalyticsIcon}
          />
          }
          <LogoutButton onLogout={this.props.onLogout} />
        </div>
      </span>
    )
  }
}
