import axios from './interceptor';

export const AnalyticsService = {
  getUserGroups({clientSlug, campaignSlug}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/analytics/groups`
    return axios({
      method: 'get',
      url: url
    });
  },
  getGroupDetails({clientSlug, campaignSlug, id}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/analytics/groups/${id}`
    return axios({
      method: 'get',
      url: url
    });
  },
  getGroupSales({clientSlug, campaignSlug, id, page, filters}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/analytics/groups/${id}/sales`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page,
        ...filters
      }
    });
  },
  requesGroupProduction({clientSlug, campaignSlug, id}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/analytics/groups/${id}/sales/export_group_sales`
    return axios({
      method: 'get',
      url: url,
    });
  },
  getGroupObjectives({clientSlug, campaignSlug, id, page, filters}) {
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/analytics/groups/${id}/objectives`
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page,
        ...filters
      }
    });
  }
}
