import React, { Component } from 'react'
import { CountryStateService } from '../../../services';
import { connect } from 'react-redux'
import { buildTransaction } from '../../../actions/generalActions';
import { saveAddresses } from '../../../actions/addressesActions';
class ConutryStateContainer extends Component {
  state = {
    states: [],
    countries: [],
    selectedState: undefined,
    selectedCountry: undefined
  }

  getInitalCountryState(){
    const { 
      selectedCountry, 
      selectedState,
    } = this.state
    const { 
      address ={}, 
    } = this.props

    this.props.startTransaction(()=>
      CountryStateService.getCountries()
        .then((response) => {
          const {countries} = response.data 
          const defaultCountry = selectedCountry || address.country || countries.find(e => e.iso.toString() === 'PT')
          return CountryStateService.getStates({country_id: defaultCountry.id})
            .then((response) => {
              const {states} = response.data
              let defaultState = selectedState || states.find(e => e.abbr.toString() === 'CNT')
              if(!defaultState && states.length > 0) {
                defaultState = states[0]
              }
              this.setState({
                countries, 
                selectedCountry:  selectedCountry || defaultCountry,
                states, 
                selectedState: selectedState || defaultState
              })
              return Promise.resolve()
            })
        })
    )
  }

  handleCountryChange = (country) => {
    const payload = {country_id: country.id}
    this.props.startTransaction(()=>
      CountryStateService.getStates(payload)
        .then((response) => {
          const states = response.data.states
          this.setState({
            selectedCountry: country, 
            states,
            selectedState: states[0]
          })
      })
    )
  }

  handleStateChange = (state) => {
    this.setState({selectedState: state})
  }

  componentDidMount = () => {
    this.getInitalCountryState()
  }

  render() {
    const {component: Component, ...rest} = this.props
    
    return (
      <Component 
        {...rest} 
        {...this.state}
        onCountryChange={this.handleCountryChange}
        onStateChange={this.handleStateChange}  
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  startTransaction: buildTransaction(dispatch),
  saveAddresses: (adresses)=>dispatch(saveAddresses(adresses))
})

const Container = connect(null, mapDispatchToProps)(ConutryStateContainer)

export const withContryStateContainer= (component) => {
  return (props) => <Container component={component} {...props} />
}

export default Container