import React from "react"
import { withNewTradeContainer } from "../../../containers/default"
import CustomSelect from "../../../shared/CustomSelect"

const index = ({
  onUserChange,
  onSubmit,
  onContractChange,
  campaign,
  users,
  selectedUser
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <div className="template col-xs-12 font-quicksand-light">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-justify-between">
          <div className="flex flex-col mb-5 w-1/2 mr-5">
            <label className="mb-2">Número do contrato</label>
            <input
              type="text"
              onChange={e => onContractChange(e.target.value)}
              className="px-3 py-2 css-vj8t7z"
              placeholder="digite o número de contrato"
            />
          </div>
          <div className="flex flex-col mb-5 w-1/2">
            <label className="mb-2">Nome do utilizador</label>
            <CustomSelect
              isClearable
              campaign={campaign}
              onChange={onUserChange}
              options={users}
              getOptionLabel={option =>
                option.employee_identifier || option.email
              }
              value={selectedUser}
              getOptionValue={option => option.id}
              placeholder="pesquise um utilizador..."
              classNamePrefix="custom-select"
            />
          </div>
        </div>
        <div className="flex justify-end ">
          <button className="primary-bg p-2 rounded font-quicksand navbar-text-white">
            Adicionar
          </button>
        </div>
      </form>
    </div>
  )
}

export default withNewTradeContainer(index)
