const initialState = {
  links: []
}

const contentReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_LINKS':
      return {
        ...state,
        links: action.payload.header_links
      }
    default:
      return state;
  }
}
export default contentReducer;