import React, { Component } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

class CustomSelect extends Component{

  customSelect(){
    const { 
      navbar_text_color,
      color_secondary  
    } = this.props.campaign
    return styled(Select)`
      .custom-select__option:hover{
        color: ${navbar_text_color};
        background-color: ${color_secondary};
      }
      .custom-select__option--is-focused{
        background-color: ${color_secondary};
        color: ${navbar_text_color};
      }
      `
  }

  render(){
    const CustomSelect = this.customSelect();
    return(<CustomSelect {...this.props} />)
  }
}

export default CustomSelect;