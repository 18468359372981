import React from "react"
import CircularLoader from "./CircularLoader"
const objectiveTotalProgress = (rules) => {
  if (rules.length === 0){
    return { 
      totalObjective: 0,
      totalProgress: 0
    }
  }

  let firstRule = rules[0]
  let progressList = rules.map(item => item.progress)
  return { 
    totalObjective: `${firstRule.target}`,
    totalProgress: progressList.reduce((a,b) =>  a+b, 0) / firstRule.target * 100 
  }
}

const HighlightedObjective = props => {
  const {totalObjective, totalProgress} = objectiveTotalProgress(props.objective.rules)
  const normalizedPercentage = totalProgress > 100 ? 100 : totalProgress
  const normalizedExperctedPercentage = Number(
    props.objective.expected_percentage
  )
  return (
    <div className="flex flex-col items-center px-3 vodafone-bold">
      <CircularLoader
        campaign={props.campaign}
        progress={normalizedPercentage}
        expected_progress={normalizedExperctedPercentage}
        id={props.objective.id}
      >
        {totalProgress % 1 === 0 ? totalProgress + '%': totalProgress.toFixed(2) + "%"}
      </CircularLoader>
      <p className="text-center">
        {props.objective.abbr}
        <br></br>
        Obj = {totalObjective}
      </p>

    </div>
  )
}

const Objectives = ({ objectives, campaign }) => {
  return (
    <section className="m-1 my-4 text-color">
      <h4 className="text-center text-2xl">Atingimentos</h4>
      <div className="flex -mx-3 mt-4 flex-wrap justify-center">
        {objectives.map(o => (
          <HighlightedObjective
            objective={o}
            campaign={campaign}
            key={"high-obj-" + o.id}
          />
        ))}
      </div>
    </section>
  )
}

export default Objectives
