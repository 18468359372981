export function saveNews(news, pagination) {
  return {
    type: "SAVE_NEWS",
    payload: {
      news,
      pagination
    }
  }
}

export function saveAnnouncement(announcement) {
  return {
    type: "SAVE_SELECTED_NEWS",
    payload: {
      announcement
    }
  }
}
