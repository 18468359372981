import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import SupportingDocumentsLayout from './SupportingDocumentsLayout';
import SupportingDocumentsContent from './SupportingDocumentsContent';

class SupportingDocuments extends Component{
  constructor(props){
    super(props);
    this.state={
      user: this.props.user
    }
  }

  componentDidMount() {
  }

  render() {
    const {
      campaign,
      user,
      supportingDocuments
    } = this.props;
    return (
      <>
      <SupportingDocumentsLayout campaign={campaign} user={user}>
        <Switch>
          {supportingDocuments.map((item,i) =>
            <Route exact path= {"/supporting-documents/:slug"} key={item.id}>
              <SupportingDocumentsContent key={item.id}/>
            </Route>
          )}

        </Switch>

      </SupportingDocumentsLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer.campaign,
    user: state.userReducer.user,
    supportingDocuments: state.supportingDocumentsReducer.supportingDocuments
  }
}

function mapDispatchToProps(dispatch){
  return{
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SupportingDocuments);
