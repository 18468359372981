import axios from './interceptor';

export const pointBlocksService = {

  getBlocks({clientSlug, campaignSlug}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/point_blocks`;
    return axios({
      method: 'get',
      url: url
    });
  },
  getHistoryBlocks({clientSlug, campaignSlug, page}){
    const url = `/api/clients/${clientSlug}/campaigns/${campaignSlug}/point_blocks/history`;
    return axios({
      method: 'get',
      url: url,
      params: {
        page: page
      }
    });
  }
};