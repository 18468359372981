export function saveUser(user){
  return {
    type: 'SAVE_USER',
    payload: user
  }
}

export function saveClient(client){
  return {
    type: 'SAVE_CLIENT',
    payload: client
  }
}

export function saveConsents(consents){
  return {
    type: 'SAVE_CONSENTS',
    payload: consents
  }
}


export function saveLogIn(state){
  return {
    type: 'SAVE_LOGIN',
    payload: state
  }
}