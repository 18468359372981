import React, { Component} from 'react';
import { connect } from 'react-redux';
import {ToastContainer, ToastStore} from 'react-toasts';
import {Link, withRouter} from 'react-router-dom';
import { CartService, WishProductsService } from '../../../services';
import { saveCart } from '../../../actions/cartActions';
import { startFetch, endFetch } from '../../../actions/generalActions'
import { saveWishes, updateProducts } from '../../../actions/productsActions';
import OrderButton from './OrderButton'

class Product extends Component{

  addtoCart(){
    const {
      user,
      campaign,
      item
    } = this.props
    const payload = {
      client_slug: user.client.slug, 
      slug: campaign.slug, 
      item_id: item.id
    }
    this.props.startFetch();
    CartService.addItem(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        ToastStore.success('Produto foi adicionado')
        this.props.history.push('/cart')
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.error('Impossivel de adicionar produto')
        this.props.endFetch();
      });
  }

  changeFav(){
    const {
      user,
      campaign,
      item
    } = this.props

    const payload = {
      client_slug: user.client.slug, 
      campaign_slug: campaign.slug, 
      product_id: item.product.id
    }
    this.props.startFetch();
    if(item.is_favorite){
      WishProductsService.deleteFromWishes(payload)
        .then((response) => {
          const wishes = response.data
          this.props.saveWishes(wishes)
          this.props.updateProducts({id: item.product.id , favorite: false})
          ToastStore.success("Item removido dos favoritos");
          this.props.endFetch();
        })
        .catch((error) => {
          ToastStore.error('Impossivel de remover produto')
          this.props.endFetch();
        });
    }
    else{
      WishProductsService.addToWishes(payload)
      .then((response) => {
        ToastStore.success("Produto adicionado dos favoritos");
        const wishes = response.data
        this.props.saveWishes(wishes)
        this.props.updateProducts({id: item.product.id , favorite: true})
        this.props.endFetch();
      })
      .catch((error) => {
        ToastStore.error('Impossivel de adicionar produto')
        this.props.endFetch();
      });
    }
  }

  render(){
    const {
      text_color,
      color_primary,
      navbar_text_color
    } = this.props.campaign
    const {
      item
    } = this.props

    const reducedName = item.product.name.length > 40 ? item.product.name.substring(0,40) + "..." : item.product.name
    return(
      <div style={{width: 'inherit'}} >
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_LEFT} lightBackground/>
        <Link className="row pt-3 pb-3 product-image" to={`/catalog/product/${item.id}`}>
          <img className= {'image'} src={item.product.main_image ? item.product.main_image.url : '' } alt="Imagem não se encontra disponível"/>
        </Link>
        <div className='product-text'>
          <div className="row text-left product-title font-bold pb-3">
            <div className="col">
              <p className="text-secondary text-center" style={{ maxWidth: "235px"}}>{reducedName}</p>
            </div>
          </div>
          <div className="row block text-center flex justify-center items-center pb-4">
              <p  className="product-points font-bold primary-text mr-1">{item.points }</p>
              <p  className="product-points-label text-secondary">pontos</p>
          </div>
          <div className="row">
            <div className="col-10 text-left order pb-3">
              <OrderButton onClick={() => this.addtoCart()} color={text_color} hoverColor={navbar_text_color}></OrderButton>
            </div>
            <div className="col-lg col-md col-xs order text-right pb-3 m-auto">
              <i className={item.is_favorite ? 'fas fa-heart fav text-xl cursor-pointer': 'far fa-heart text-grey text-xl cursor-pointer'} onClick={() => this.changeFav()}></i>
            </div>
          </div>
        </div>      
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user
  }
}
function mapDispatchToProps(dispatch){
  return{
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes)) 
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart))
    },
    updateProducts: (wish) => {
      dispatch(updateProducts(wish))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Product));