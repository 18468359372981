const initialState = {
  points: [],
  pointsPagination: {},
  validityPoints: [],
  validityPagination: {},
  historyPoints: {},
  historyMonths: [],
  historyRange: '',
  campaignUser: {
    totalPoints: 0,
    expiredPoints: 0,
    spentPoints: 0,
    availablePoints: 0,
    canSeeBonusPoints: true,
    lastDate: 0,
    avatar: {},
    currentLevel: {level_number: 0, requirements: [], percentage: 0 }
  },
  orderPayment: {
    id: null,
    validity: null,
    entity: null,
    reference: null,
    value: null,
  }
}

const balanceReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_VALIDITY':
      return {
        ...state,
        validityPoints: action.payload.points_expired,
        validityPagination: action.payload.pagination
      }
    case 'SAVE_BALANCE':
      return {
        ...state,
        points: action.payload.points,
        pointsPagination: action.payload.pagination
      }
    case 'SAVE_HISTORY':
      return {
        ...state,
        historyPoints: action.payload.data,
        historyMonths: action.payload.months,
        historyRange: action.payload.time_range

      }
    case 'SAVE_CAMPAIGN_USER':
      const {campaign_user} = action.payload
      return {
        ...state,
        campaignUser: {
          totalPoints: campaign_user.total_points,
          expiredPoints: campaign_user.expired_points,
          availablePoints: campaign_user.available_points,
          spentPoints: campaign_user.spent_points,
          lastDate: campaign_user.last_points_created,
          canInvoiceValidate: campaign_user.can_invoice_validate,
          canSeeAnalytics : campaign_user.can_see_group_analytics,
          canSeeGDS : campaign_user.can_see_group_gds,
          canGiveBonusPoints : campaign_user.can_see_bonus_points,
          canSeeSaleAttributions : campaign_user.can_see_sale_attributions,
          has_pending_sale_attributions : campaign_user.has_pending_sale_attributions,
          has_seen_pending_sale_attributions : false,
          avatar: campaign_user.avatar,
          currentLevel: campaign_user.current_level,
          validity: action.payload.order_payment ? action.payload.order_payment.provider.validity : null,
          entity: action.payload.order_payment ? action.payload.order_payment.source.entity : null,
          reference: action.payload.order_payment ? action.payload.order_payment.source.reference : null,
          value: action.payload.order_payment ? action.payload.order_payment.source.value : null,
        }
      }
    case 'SAVE_ORDER_PAYMENT':
      const {order_payment} = action.payload
      return {
        ...state,
        orderPayment: {
          id: order_payment.id,
          validity: order_payment.provider.validity,
          entity: order_payment.source.entity,
          reference: order_payment.source.reference,
          value: order_payment.source.value,
        }
      }
    default:
      return state;
  }
}
export default balanceReducer;
