import React from 'react'
import Layout from '../shared/Layout'
import Details from './UserDetails';
import Orders from './Orders';
import OrderShow from './Orders/OrderShow';
import Balance from './Balance';
import Route from '../../../routes/CustomRoute'
import { Switch } from 'react-router-dom'
import ProfileLayout from './ProfileLayout';
import {withClientContainer} from '../../containers/gamification'

const Profile = props => {

  
  const{
    currency_label : currencyLabel
  } = props.client
  
  return (
    <>
      <ProfileLayout>
        <Switch>
          <Route path="/profile" exact component={Details} />
          <Route path="/profile/balance"  component={Balance} currencyLabel={currencyLabel} />
          <Route exact path="/profile/orders/:id"  component={OrderShow} currencyLabel={currencyLabel}/>
          <Route exact path="/profile/orders"  component={Orders} currencyLabel={currencyLabel}/>
        </Switch>
      </ProfileLayout>
    </>
  )
}

export default withClientContainer(Profile)
