import React from 'react';
import { ReactComponent as NoStore } from '../../../assets/img/no_store.svg';
import { ClientContainer, ShoppingCartContainer, withContainers } from '../../containers/gamification';
import CustomLink from '../shared/CustomLink';
import EmptyStateLayout from '../shared/EmptyStateLayout';
import CartButtons from './CartButtons';
import CartItemsTable from './CartItemsTable';
import CartWarning from './CartWarning';

const CartItems = props => {

  const {
    client = {},
    cart,
    onIncreaseItem,
    onDecreaseItem,
    onRemoveItem,
    checkout,
    campaign,
    onReturnToCatalog,
    onCheckCartItem
  } = props

  const message = "Clique no link seguinte para adicionar items ao seu carrinho: "
  const path = `/catalog`

  return (
    cart.items.length > 0 ? (
      <>
        <CartItemsTable
          currencyLabel={client.currency_label}
          cart={cart}
          onIncreaseItem={onIncreaseItem}
          onDecreaseItem={onDecreaseItem}
          onRemoveItem={onRemoveItem}
          checkout={checkout}
        />
        <CartButtons onPrev={onReturnToCatalog} onNext={onCheckCartItem} prevText="Voltar" nextText="Seguinte" />
        <CartWarning text={campaign.call_attention} />
      </>
    )
      : (
        <div>
          <EmptyStateLayout message={message}>
            <div className="g-no-store">
              <NoStore className="mr-3 dead-grey g-no-store__image"></NoStore>
            </div>
            <h2 className="mb-5">O seu carrinho está vazio.</h2>
          </EmptyStateLayout>
          <div className="flex justify-center">
            <CustomLink
              to={path}
              primary

            >
              Loja de prémios
          </CustomLink>
          </div>
        </div>
      )
  )
}

export default withContainers(CartItems, [ShoppingCartContainer, ClientContainer])
