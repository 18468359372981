import React from 'react'

const NotificationItem = (props) => {

  const {
    title, description, 
    onNotificationView,
    id,
    shown: seen
  } = props

  let handleMouseEnter = undefined
  if(!seen){
    handleMouseEnter = (e) => {
      e.preventDefault()
      onNotificationView(id)
    }
  }   
  
  const className= [" mb-1 px-1 text-left", !seen && 'primary-bg-after'].join(' ')
  return (
    <div className={seen ? 'd-notification__item' : "d-notification__item--to-view" } id='notification-item' onMouseEnter={handleMouseEnter}>
      <h4 className={className}>{title}</h4>
      <p className="text-left dead-grey small-title-size px-1">{description}</p>
      <span></span>
    </div>
  )
}

export default NotificationItem