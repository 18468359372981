import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveCampaignPages, saveCampaignPageContent } from "../../../actions/campaignActions";
import { buildTransaction } from '../../../actions/generalActions';
import { CampaignService } from '../../../services';

export class CampaignContainer extends Component {

  fetchCampaignPages() {
    const { user, campaign } = this.props
    const { client } = user
    const slug = client && client.slug ? client.slug : '';

    if (campaign.slug === null) {
      return
    }

    const payload = {
      client_slug: slug, 
      slug: campaign.slug,
    }

    this.props.startTransaction(()=>
      CampaignService.getPagesList(payload).then((response) => {
        const { "campaign/pages": pages } = response.data
        // console.log('Campaign Pages -> ', pages)
        this.props.saveCampaignPages({pages});
        CampaignService.getPageContent({...payload, page_slug: pages[0].slug, detailed: true}).then((response) => {
          const { "campaign/page": page } = response.data
          // console.log(' Page Content -> ', page)
          this.props.saveCampaignPageContent(page);
        })
      })
    )
  }

  componentDidMount(){
    this.fetchCampaignPages();
  }
 
  render() {
    const {component: Component, ...rest} = this.props
    return (
      <Component {...rest}/>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: state.campaignReducer.campaign,
  user: state.userReducer.user,
  banners: state.homepageBannersReducer.banners
})

const mapDispatchToProps = (dispatch) => ({
  saveCampaignPages: (pages) => { dispatch(saveCampaignPages(pages)) },
  saveCampaignPageContent: (page) => { dispatch(saveCampaignPageContent(page)) },
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(CampaignContainer)

export const withCampaignContainer = (component) => {
  return (props)=> <Container component={component} {...props}/>
}

export default Container
