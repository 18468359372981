import React from 'react'
import {AnnouncementIcon} from '../../normalizer'

const NotificationAlert = () => (
  <div className="justify-center items-center flex mb-4">
    <AnnouncementIcon className="gl-notification__announcement-icon svg-primary-fill"/>
    <p className="dead-grey small-text-size px-2 font-bold text-center">Anúncio Importante!</p>
  </div>
)

const NotificationItem = (props) => {

  const {
    title, 
    description, 
    onNotificationView,
    id,
    shown: seen,
    important
  } = props

  let handleMouseEnter = undefined
  if(!seen){
    handleMouseEnter = (e) => {
      e.preventDefault()
      onNotificationView(id)
    }
  }   
  
  const containerClassName = ["gl-notification__item-container", important && "eee-bg"].join(" ")
  const className= [!seen && 'gl-notification__item--to-view primary-bg'].join (' ')
  return (
    <div className={containerClassName}>
      <div className={"gl-notification__item"} onMouseEnter={handleMouseEnter}>
        {important && <NotificationAlert/>}
        <div className="font-bold flex mb-4">
          <span className={className}></span>
          <p>{title}</p>
        </div>
        <p className="dead-grey gl-small-title-size font-light font-normal leading-normal">{description}</p>
        <span className="gl-notification__line"></span>
      </div>
    </div>
  )
}

export default NotificationItem