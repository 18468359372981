import React from "react";
import { colorSchemes } from '@nivo/colors'
import { useTheme } from '@nivo/core';
import { Bar, ResponsiveBar } from '@nivo/bar';

const BarChart = (props) => {
  const { data, height, legend, palette, orientation = 'horizontal', dataOptions, axisLeft, axisBottom, axisRight, axisTop, numOfCols, message } = props
  const { onMouseEnter, onMouseLeave } = props

  let biggestScheme = 0;
  const schemeOptions = palette ? colorSchemes[palette.name] : colorSchemes['nivo']
  const markers = dataOptions && dataOptions.markers && dataOptions.markers.length > 0 ? dataOptions.markers.map((marker) => {
    const axis = orientation && orientation.toLowerCase() == 'horizontal' ? 'x' : 'y'
    return {
      axis,
      value: marker.value,
      lineStyle: { stroke: 'rgba(0, 0, 0, .8)', strokeWidth: 3 },
      // legend: marker.label,
      // legendOrientation: orientation && orientation.toLowerCase() == 'horizontal' ? 'vertical' : 'horizontal',
    }
  }) : []

  // Loop to get the right color palette
  Object.keys(schemeOptions).forEach(key => {
    if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
      biggestScheme = key + 1;
    } else {
      biggestScheme = key;
    }
  });

  // copy to avoid changing the original array
  let colorScheme = [ ...schemeOptions[biggestScheme] ]
  if (palette && palette.inverted) {
    colorScheme.reverse();
  }

  const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
  const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
  const thereIsPaletteEnhancedIndex = palette && palette.enhanced_index !== null && palette.enhanced_index !== undefined
  const enhancedColor = colorScheme && colorScheme.length > 0 && thereIsPaletteEnhancedIndex && colorScheme[palette.enhanced_index % (colorScheme.length)] || '#fff'

  if (thereIsPaletteFixedColor) {
    colorScheme = thereIsPaletteStartIndex ? [colorScheme[palette.start_index]] : [colorScheme[0]];
  } else {
    colorScheme = thereIsPaletteStartIndex ? colorScheme.slice(palette.start_index) : colorScheme.slice(0);
  }

  const compare = (property) => (a, b) => {
    if (a[`${property}`] < b[`${property}`]) {
      return -1;
    }
    if (a[`${property}`] > b[`${property}`]) {
      return 1;
    }
    return 0;
  }

  let newData = []
  if (palette && palette.fixed_color) {
    newData = data && data.map((cnt) => {
      if (cnt.enhanced) {
        return {
          ...cnt,
          color: enhancedColor
        }
      }
      return {
        ...cnt,
        color: colorScheme[0]
      }
    }).sort(compare('position'));
  } else {
    newData = data && data.map((cnt, idx) => {
      if (cnt.enhanced) {
        return {
          ...cnt,
          color: enhancedColor
        }
      }
      return {
        ...cnt,
        color: colorScheme[(idx % colorScheme.length)]
      }
    }).sort(compare('position'));
  }

  newData = newData.map((data) => {
    console.log(' Data => ', data)
    return {
      ...data,
      label: !!data && !!data.position ? `${data.position} - ${data.label}` : !!data && !!data.label ? `${data.label}` : '',
    }
  })

  newData = orientation === 'horizontal' ? newData.reverse() : newData;

  const CustomLeftTick = (tick) => {
    const posAndText = !!tick && !!tick.value && tick.value.includes('-') && tick.value.split('-') 
    // console.log(' Pos And Text => ', posAndText)
    const textInArr = !!posAndText && posAndText.length > 1 ? posAndText[1].split(' ') : null
    // console.log(' Text In Arr => ', textInArr)
    const labelToShow = !!posAndText && !!textInArr && textInArr.length > 2 ? `${posAndText[0]} - ${textInArr[1]} ${textInArr[2]}` : tick.value

    return (
      <g transform={`translate(${tick.x - 5},${tick.y})`}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            // ...theme.axis.ticks.text,
            fill: '#333',
            fontSize: 10,
            marginRight: 5,
            paddingRight: 5,
          }}
        >
          {labelToShow}
        </text>
      </g>
    )
  }

  const CustomBottomTick = (tick) => {
    const posAndText = !!tick && !!tick.value && tick.value.includes('-') && tick.value.split('-')
    // console.log(' Pos And Text => ', posAndText)
    const textInArr = !!posAndText && posAndText.length > 1 ? posAndText[1].split(' ') : null
    // console.log(' Text In Arr => ', textInArr)
    const labelToShow = !!posAndText && !!textInArr && textInArr.length > 2 ? `${posAndText[0]} - ${textInArr[1]} ${textInArr[2]}` : tick.value

    return (
      <g transform={`translate(${tick.x + 5},${tick.y + 5}), rotate(-45)`}>
        <text
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            // ...theme.axis.ticks.text,
            fill: '#333',
            fontSize: 10,
            marginTop: 8,
            paddingTop: 8,
          }}
        >
          {labelToShow}
        </text>
      </g>
    )
  }

  const marginAndLegends = ({ anchor, direction }, serieSize = 0) => {
    let margin = {
      top: 20,
      bottom: 40,
      right: 20,
      left: 110
    }

    if (!anchor || !direction) {
      return {
        margin,
        legends: []
      }
    }

    let legend = {
      dataFrom: 'indexes',
      anchor: anchor.replace('_', '-'),
      direction: direction,
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 50,
      itemHeight: 15,
      itemOpacity: 0.85,
      symbolSize: 10,
      itemsSpacing: 5,
    }

    if (anchor.includes('top')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              top: 40,
            },
            legends: [{
              ...legend,
              translateY: -20,
              itemDirection: 'bottom-to-top',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              left: 180,
            },
            legends: [{
              ...legend,
              translateX: -180,
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            top: 40,
          },
          legends: [{
            ...legend,
            translateY: -20,
            itemDirection: 'bottom-to-top',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            right: 85,
          },
          legends: [{
            ...legend,
            translateX: 80,
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    if (anchor.includes('bottom')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              bottom: 80,
            },
            legends: [{
              ...legend,
              translateY: 50,
              itemDirection: 'top-to-bottom',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              left: 180,
            },
            legends: [{
              ...legend,
              translateX: -180,
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            bottom: 80,
          },
          legends: [{
            ...legend,
            translateY: 50,
            itemDirection: 'top-to-bottom',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            right: 85,
          },
          legends: [{
            ...legend,
            translateX: 80,
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    return { margin, legends: [] }
  }

  const colMarginAndLegends = ({ anchor, direction }, serieSize = 0) => {
    let margin = {
      top: 20,
      bottom: 80,
      right: 20,
      left: 40
    }

    if (!anchor || !direction) {
      return {
        margin,
        legends: []
      }
    }

    let legend = {
      dataFrom: 'indexes',
      anchor: anchor.replace('_', '-'),
      direction: direction,
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 50,
      itemHeight: 15,
      itemOpacity: 0.85,
      symbolSize: 10,
      itemsSpacing: 5,
    }

    if (anchor.includes('top')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              top: 40,
            },
            legends: [{
              ...legend,
              translateY: -20,
              itemDirection: 'bottom-to-top',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              left: 200,
            },
            legends: [{
              ...legend,
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            top: 40,
          },
          legends: [{
            ...legend,
            translateY: -20,
            itemDirection: 'bottom-to-top',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            right: 100,
          },
          legends: [{
            ...legend,
            translateX: 80,
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    if (anchor.includes('bottom')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              bottom: 80,
            },
            legends: [{
              ...legend,
              itemDirection: 'top-to-bottom',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              left: 200,
            },
            legends: [{
              ...legend,
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            bottom: 80,
          },
          legends: [{
            ...legend,
            itemDirection: 'top-to-bottom',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            right: 100,
          },
          legends: [{
            ...legend,
            translateX: 80,
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    return { margin, legends: [] }
  }

  if (orientation === 'horizontal') {
    return (
      <div style={{ width: '100%', height }} >
        <ResponsiveBar
          data={newData}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          keys={['value']}
          layout={'horizontal'}
          indexBy="label"
          margin={marginAndLegends(legend, newData.length).margin}
          padding={0.3}
          axisTop={axisTop || null}
          axisBottom={{
            tickValues: (numOfCols - 1),
          }}
          axisLeft={{
            renderTick: CustomLeftTick,
            tickRotation: -45,
          }}
          axisRight={axisRight || null}
          enableGridX={true}
          enableGridY={false}
          enableLabel={false}
          valueScale={{ type: 'linear' }}
          // indexScale={{ type: 'band', round: true }}
          markers={markers}
          colors={({ data }) => String(data['color'])}
          legendLabel={datum => {
            const tmp = datum && datum.indexValue || ''
            const tmpIdVal = tmp.trim().split('-')
            const idVal = tmpIdVal && tmpIdVal.legth > 0 && tmpIdVal[0] || ''
            return `${idVal} (${datum.formattedValue})`
            // return `(${datum.formattedValue})`
          }}
          legends={marginAndLegends(legend, newData.length).legends}
        />
      </div>
    );
  }

  return (
    <div style={{ width: '100%', height }} >
      <ResponsiveBar
        data={newData}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        keys={['value']}
        layout={'vertical'}
        indexBy="label"
        margin={colMarginAndLegends(legend, newData.length).margin}
        padding={0.3}
        axisTop={axisTop || null}
        axisBottom={{
          renderTick: CustomBottomTick,
          tickRotation: -45,
        }}
        axisLeft={{
          tickValues: (numOfCols - 1),
        }}
        axisRight={axisRight || null}
        enableGridX={false}
        enableGridY={true}
        enableLabel={false}
        valueScale={{ type: 'linear' }}
        // indexScale={{ type: 'band', round: true }}
        markers={markers}
        colors={({ data }) => String(data['color'])}
        legendLabel={datum => {
          const tmp = datum && datum.indexValue || ''
          const tmpIdVal = tmp.trim().split('-')
          const idVal = tmpIdVal && tmpIdVal.legth > 0 && tmpIdVal[0] || ''
          return `${idVal} (${datum.formattedValue})`
          // return `(${datum.formattedValue})`
        }}
        legends={marginAndLegends(legend, newData.length).legends}
      />
    </div>
  );
};

export default BarChart;
