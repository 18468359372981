import React from 'react';
import '../../../assets/css/slider.scss';
import { NavLink} from 'react-router-dom'
import {t} from '../../../i18n'
import styled from 'styled-components'

const CampaignNavLink = styled(NavLink).attrs({       
  className : " p-3 cursor-pointer link-reset"
})`
  width: 100%;
  display: block;
  z-index: 1;
  :hover{
    color: ${props=> props.navbar_text_color};
    background-color: ${props=> props.color_secondary};
    &:visited {
      color: ${props=> props.navbar_text_color};
    
    }
  }
  

  &.active {
    &:visited {
      color: ${props=> props.navbar_text_color};
    
    }
    color: ${props=> props.navbar_text_color};
    background-color: ${props=> props.color_secondary};
  }
`
const RankingSelector = props => {
 
    const {
      campaign,
      rankings
    } = props
  
    return( 
      <div className='flex flex-col col-lg-4 col-md-4 col-xs'>
        <div style={{backgroundColor: campaign.color_primary }} className='template-info mb-2'>
          <p className="uppercase">{t('ranking.rankings')}</p>
        </div>
        <div className='default-container py-3'>
          { 
            rankings.map(ranking => (
              <CampaignNavLink 
              key={"ranking-selector-" + ranking.id}
                to={`/rankings/${ranking.id}`} 
                {...campaign}>
                  {ranking.name}
              </CampaignNavLink>
            ))
          }
        </div>
      </div>
    )
}

export default RankingSelector;