import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import TabLink from '../shared/TabLink';
import ContentTitle from '../shared/ContentTitle'
import { Switch, Route, Redirect, withRouter} from 'react-router-dom'
import InvoiceRoute from './InvoiceRoute'
import {ToastContainer, ToastStore} from 'react-toasts';
import InvoiceForm from './InvoiceForm';
import { InvoiceService } from '../../../services';
import { startFetch, endFetch } from '../../../actions/generalActions'
import {
  saveUnapprovedInvoices,
  saveVerifiedInvoices,
  saveRegistredInvoices,
  saveCreatedInvoices,
  saveSaleTypes,
  createInvoice,
  saveBrands
} from '../../../actions/invoiceActions';

class UserContainer extends Component {
  constructor(props) {
    super(props);

    this.pathToAction = {
      "/invoice/to-approve": {
        action: this.getToAproveInvoices,
        stateVariable: "toApprovePage",
      },
      "/invoice/created": {
        action: this.getCreatedInvoices,
        stateVariable: "createdPage",
      },
      "/invoice/registred": {
        action: this.getRegistredInvoices,
        stateVariable: "registredPage",
      },
      "/invoice/verified": {
        action: this.getVerifiedInvoices,
        stateVariable: "verifiedPage",
      },
    };
  }

  state = {
    fetchedRegistred: false,
    fetchedWaitingApproval: false,
    fetchedCreated: false,
    fetchedVerified: false,
    toApprovePage: 1,
    toApprovePagination: {},
    createdPage: 1,
    createdPagination: {},
    registredPage: 1,
    registredPagination: {},
    verifiedPage: 1,
    verifiedPagination: {},
    toApproveFilters: {},
    registredFilters: {},
    createdFilters: {},
    verifiedFilters: {},
    HeaderErrors: {},
  };

  invoicePayload = () => ({
    clientId: this.props.user.client.slug,
    campaignId: this.props.campaign.slug,
  });

  addInvoiceHeader = (data) => {
    const payload = {
      ...this.invoicePayload(),
      ...data,
    };

    this.props.startTran(() =>
      InvoiceService.addInvoiceHeader(payload)
        .then((response) => response.data.invoices)
        .then((invoice) => {
          this.props.createInvoice(invoice);
          const detailUrl = `/invoice/created/${invoice.id}`;
          this.props.history.push(detailUrl);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          this.setState({ HeaderErrors: errors });
        })
    );
  };

  getToAproveInvoices = () => {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.toApprovePage,
      filters: {
        ...this.state.toApproveFilters,
      },
    };
    this.props.startTran(() => InvoiceService.getToApproveInvoices(payload).then((response) => { const { pagination, invoices } = response.data;
        this.props.saveUnapprovedInvoices(invoices);
        this.setState({
          fetchedWaitingApproval: true,
          toApprovePagination: pagination,
        });
      })
    );
  };

  getRegistredInvoices = () => {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.registredPage,
      filters: {
        ...this.state.registredFilters,
      },
    };
    this.props.startTran(() =>
      InvoiceService.getRegistredInvoices(payload).then((response) => {
        const { pagination, invoices } = response.data;
        this.props.saveRegistredInvoices(invoices);
        this.setState({
          fetchedRegistred: true,
          registredPagination: pagination,
        });
      })
    );
  };

  getCreatedInvoices = () => {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.createdPage,
      filters: {
        ...this.state.createdFilters,
      },
    };

    this.props.startTran(() =>
      InvoiceService.getCreatedInvoices(payload).then((response) => {
        const { pagination, invoices } = response.data;
        this.props.saveCreatedInvoices(invoices);
        this.setState({ fetchedCreated: true, createdPagination: pagination });
      })
    );
  };
  
  getVerifiedInvoices = () => {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.verifiedPage,
      filters: {
        ...this.state.verifiedFilters,
      },
    };
    this.props.startTran(() =>
      InvoiceService.getVerifiedInvoices(payload).then((response) => {
        const { pagination, invoices } = response.data;
        this.props.saveVerifiedInvoices(invoices);
        this.setState({
          fetchedVerified: true,
          verifiedPagination: pagination,
        });
      })
    );
  };

  getSaleTypes = () => {
    this.props.startTran(() =>
      InvoiceService.getSaleTypes(this.invoicePayload())
        .then((response) => response.data.sale_types)
        .then((saleTypes) => {
          this.props.saveSaleTypes(saleTypes);
          this.setState({ fetchedCreated: true });
        })
    );
  };

  getClientProducts = () => {
    this.props.startTran(() =>
      InvoiceService.getClientCategories({
        clientId: this.props.user.client.slug,
        parentId: null,
      })
        .then((response) => response.data.client_categories)
        .then((brands) => {
          this.props.saveBrands(brands);
        })
    );
  };

  handleClientPageUpdate = (path) => {
    const { action, stateVariable } = this.pathToAction[path];
    return ({ selected }) => {
      const normalizedPage = selected + 1;
      if (this.state[stateVariable] !== normalizedPage) {
        this.setState({ [stateVariable]: normalizedPage }, () => {
          action();
        });
      }
    };
  };
  
  updateCreatedFilters = (filters) => {
    this.setState({ createdFilters: filters }, () => this.getCreatedInvoices());
  };

  updateRegistredFilters = (filters) => {
    this.setState({ registredFilters: filters }, () =>
      this.getRegistredInvoices()
    );
  };

  updateToApproveFilters = (filters) => {
    this.setState({ toApproveFilters: filters }, () =>
      this.getToAproveInvoices()
    );
  };
  
  updateVerifiedFilters = (filters) => {
    this.setState({ verifiedFilters: filters }, () =>
      this.getVerifiedInvoices()
    );
  };

  componentDidMount() {
    this.getRegistredInvoices();
    this.getCreatedInvoices();
    this.getToAproveInvoices();
    this.getVerifiedInvoices();
    this.getSaleTypes();
    this.getClientProducts();
  }

  render() {
    const {
      campaign,
      user,
      waitingApproval,
      registred,
      created,
      verified,
      saleTypes,
    } = this.props;
    const {
      fetchedWaitingApproval,
      fetchedRegistred,
      fetchedCreated,
      fetchedVerified,
      toApprovePagination,
      registredPagination,
      createdPagination,
      verifiedPagination,
      HeaderErrors,
    } = this.state;
    const activeStyle = {
      backgroundColor: campaign.color_primary,
      color: campaign.navbar_text_color,
    };
    return (
      <Fragment>
        <ToastContainer
          store={ToastStore}
          position={ToastContainer.POSITION.TOP_LEFT}
          lightBackground
        />
        <div className="flex py-2 justify-between tab-height uppercase col-xs-12 font-quicksand-light">
          <div className="flex">
            <TabLink to={"/invoice/created"} activeStyle={activeStyle}>
              Criadas
            </TabLink>
            <TabLink to={"/invoice/to-approve"} activeStyle={activeStyle}>
              Por aprovar
            </TabLink>
            <TabLink to={"/invoice/registred"} activeStyle={activeStyle}>
              Registadas
            </TabLink>
            {fetchedVerified && <TabLink to={"/invoice/verified"} activeStyle={activeStyle}>
              Verificadas
            </TabLink>}
          </div>
          <TabLink to={"/invoice/add"} activeStyle={activeStyle}>
            Adicionar
          </TabLink>
        </div>
        <Switch>
          <Redirect exact from="/invoice" to="/invoice/created" />
          {fetchedCreated && (
            <InvoiceRoute
              editable
              onPageChange={this.handleClientPageUpdate}
              currentPage={this.state.createdPage - 1}
              totalPages={createdPagination.total_pages || 1}
              path="/invoice/created"
              onSearchRequest={this.updateCreatedFilters}
              campaign={campaign}
              invoices={created}
            />
          )}
          {fetchedWaitingApproval && (
            <InvoiceRoute
              onPageChange={this.handleClientPageUpdate}
              currentPage={this.state.toApprovePage - 1}
              totalPages={toApprovePagination.total_pages || 1}
              path="/invoice/to-approve"
              campaign={campaign}
              onSearchRequest={this.updateToApproveFilters}
              invoices={waitingApproval}
            />
          )}
          {fetchedRegistred && (
            <InvoiceRoute
              onPageChange={this.handleClientPageUpdate}
              currentPage={this.state.registredPage - 1}
              totalPages={registredPagination.total_pages || 1}
              completeTable
              onSearchRequest={this.updateRegistredFilters}
              path="/invoice/registred"
              campaign={campaign}
              invoices={registred}
            />
          )}
          {fetchedVerified && (
            <InvoiceRoute
              onPageChange={this.handleClientPageUpdate}
              currentPage={this.state.verifiedPage - 1}
              totalPages={verifiedPagination.total_pages || 1}
              completeTable
              onSearchRequest={this.updateVerifiedFilters}
              path="/invoice/verified"
              campaign={campaign}
              invoices={verified}
            />
          )}
          <Route path="/invoice/add">
            <div className="template col-xs-12">
              <div className="template-container template-container--light">
                <ContentTitle sub title="Registar fatura para validação" />
                <InvoiceForm
                  saleTypes={saleTypes}
                  campaign={campaign}
                  user={user}
                  onSubmit={this.addInvoiceHeader}
                  errors={HeaderErrors}
                />
              </div>
            </div>
          </Route>
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  waitingApproval: state.invoiceReducer.unapprovedInvoices,
  registred: state.invoiceReducer.registredInvoices,
  created: state.invoiceReducer.createdInvoices,
  verified: state.invoiceReducer.verifiedInvoices,
  saleTypes: state.invoiceReducer.saleTypes,
  campaign: state.campaignReducer.campaign
})

const mapDispatchToProps = dispatch => ({
  startTran: (promiseAction) => {
    dispatch(startFetch())
    promiseAction()
      .then(()=>
        dispatch(endFetch()))
      .catch(()=>
        dispatch(endFetch()))
  },
  saveUnapprovedInvoices: (invoices) => dispatch(saveUnapprovedInvoices(invoices)),
  saveRegistredInvoices: (invoices) => dispatch(saveRegistredInvoices(invoices)),
  saveCreatedInvoices: (invoices) => dispatch(saveCreatedInvoices(invoices)),
  saveVerifiedInvoices: (invoices) => dispatch(saveVerifiedInvoices(invoices)),
  saveSaleTypes: (invoices) => dispatch(saveSaleTypes(invoices)),
  createInvoice: (invoice) => dispatch(createInvoice(invoice)),
  saveBrands: (brands) => dispatch(saveBrands(brands))

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (UserContainer))
