import React from "react"
import { withProductContainer } from "../../containers/shared"
import { Link } from "react-router-dom"
import { formatNumber, substring, CartItem } from "../normalizer"

const Product = props => {
  const {
    product,
    onFavAddition,
    onFavRemove,
    onCartAdd,
    currencyLabel,
    ...campaignProduct
  } = props

  const { is_favorite, points } = campaignProduct
  const heartClass = [
    "g-product__icon",
    !is_favorite && "far fa-heart text-navigation",
    is_favorite && "fa fa-heart fav  secondary-text"
  ].join(" ")

  const limitedName = substring(product.name, 35, "...")
  return (
    <div className="col-md-3 col-sm-4 col-xs-12 px-12 md:px-0 ">
      <div className="g-product__container bg-default g-content-shadow ">
        <p className="g-product__title navbar-text">{limitedName}</p>
        <Link to={`/catalog/product/${campaignProduct.id}`}>
          <img
            src={product.main_image.url}
            alt={product.name}
            className="g-product__image"
          />
        </Link>
        <div className="g-product__action-container navbar-text">
          <i
            className={heartClass}
            onClick={is_favorite ? onFavRemove : onFavAddition}
          />
          <div className="g-product__points-container">
            <span className="g-product__points-value">
              {formatNumber(points)}
            </span>
            <span className="g-product__points-label">{currencyLabel}</span>
          </div>
          <CartItem
            className="g-navbar__icons20 cursor-pointer campaign-link-icon"
            onClick={onCartAdd}
          />
        </div>
      </div>
    </div>
  )
}
export default withProductContainer(Product)
