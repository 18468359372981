import React from "react"
import Modal from "../Modal";
import {t, prefix} from '../../../i18n'

const TermsAndConditionsModal = ({
  selected,
  terms,
  onNoAcceptTermsModal,
  onAcceptTermsModal
}) => {

  const handleNoAcceptAction = e => {
    e.preventDefault()
    onNoAcceptTermsModal()
  }

  const handleAcceptAction = e => {
    e.preventDefault()
    onAcceptTermsModal()
  }

  const tRegistry = prefix('registry')
  const company = prefix('company')

  return (
    <Modal show={selected} large>
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 py-2">
          <h2>{tRegistry('terms-and_conditions-details')}</h2>
        </div>
        <div 
          className='custom-inner-html col-xs-12 leading-normal text-left p-5' 
          dangerouslySetInnerHTML={{ __html: terms }}>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          className="outline-reset bg-red-dark text-base text-white rounded px-4 py-3"
          onClick={handleNoAcceptAction}
        >
          <i className="fa fa-times" />{" "}
          <span className="font-quicksand">Não aceitar</span>
        </button>
        <button
          className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
          onClick={handleAcceptAction}
        >
          <i className="fas fa-check" />{" "}
          <span className="font-quicksand">Aceitar</span>
        </button>
      </div>
    </Modal>
  )
}

export default TermsAndConditionsModal
