import React, { Component } from "react"
import { connect } from "react-redux"
import { SaleAttributionService, buildTransaction, saveCompletedAttributions, startFetch, endFetch} from "../../normalizer"
import { HOC } from "../../gamification"

class CompletedAttributionsContainer extends Component {
  state = {
    page: 1
  }

  payload() {
    return {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug
    }
  }

  getCompletedSaleAttributions() {
    const { user, campaign } = this.props

    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(() =>
      SaleAttributionService.getCompletedSaleAttributions(payload).then(response => {
        const sales_completed = response.data.sales_completed
        const pagination = response.data.pagination
        this.props.saveCompletedAttributions(sales_completed, pagination)
        this.fetchGroupUsers(payload)
      })
    )
  }

  componentDidMount() {
    this.getCompletedSaleAttributions()
  }

  render() {
    const { component: Component, ...rest } = this.props

    return <Component {...rest} />
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    completedSaleAttributions: state.saleAttributionReducer.completedSaleAttributions,
    pagination: state.saleAttributionReducer.requestedPagination
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveCompletedAttributions: (completedAttributions, pagination) => {
      dispatch(saveCompletedAttributions(completedAttributions, pagination))
    },
    startTransaction: buildTransaction(dispatch)
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedAttributionsContainer)

export function withCompletedAttributionsContainer(component) {
  const withCompletedAttributionsContainer = HOC(Container, component)
  return withCompletedAttributionsContainer
}
export default Container
