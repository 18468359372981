import React, { Component } from 'react';
import { capitalize } from '../../../utils/utils' 
import {prefix} from '../../../i18n'

const tUser = prefix('user')
class UserInfoShow extends Component{
  
  render(){
    const {
      color_primary,
      text_color
    } = this.props.campaign
    const user = this.props.user
    return(

      <div>
        <div className='flex template-container between-xs sm:flex-turn sm:flex-turn--a-center sm:flex-turn--j-center'>
          <div>
            <h2 style={{ color: color_primary }} className='template-title uppercase'>{tUser('my-info')}</h2>
            <hr style={{ backgroundColor: text_color }}className="hr-down-left"></hr>
          </div>   
          <button onClick={this.props.onClick} className='btn-secondary user-button px-4 py-1'>{tUser('edit')}</button> 
        </div>
        <div className='template-show mt-10 mb-10 pt-5 pb-5 pl-10 pr-10 flex between-xs profile-personal-info-container'>
          <div>
            <p style={{color: color_primary}} className='template-text-blue'>{capitalize(user.first_name)}  {user.last_name}</p>
            <p style={{color: color_primary}} className='template-text-blue'>
              <span className="font-quicksand-light">{tUser('taxpayer-nr')}</span> {user.nif ? user.nif : tUser('no-taxpayer-nr')}</p>
          </div>
          <div>
            <p style={{color: color_primary}} className='template-text-blue'>
              <span className="font-quicksand-light">{tUser('login')} </span> 
              {user.login}
            </p>
            <p style={{color: color_primary}} className='template-text-blue'>
              <span className="font-quicksand-light">{tUser('phone')} </span> 
              {user.phone ? user.phone : tUser('no-phone')}
            </p>
          </div>
          <div>
            <p style={{color: color_primary}} className='template-text-blue no-hyphens'>{user.email}</p>
          </div>
        
        </div>
      </div>
    )
  }

}
export default UserInfoShow;