import React from 'react';
import Modal from './Modal'

const ConsentModal = ({
  selected,
  name,
  description,
  onUnSelectObject
}) => {
  return (
    <Modal show={selected} untoggle={onUnSelectObject} large>
      <div className="">
        <div className="flex justify-between items-center navbar-text mb-5">
          <button className="consent-exit outline-reset" onClick={onUnSelectObject}>
            <i className="fa fa-times" ></i>
          </button>
        </div>
        <div className="text-center font-quicksand mb-7 border-b-2 py-2">
          <h2>Descrição de {name}</h2>
        </div>
        <div className="py-4 px-2 consent-modal-description custom-inner-html" dangerouslySetInnerHTML={{__html: description}}>
        </div>
      </div>
    </Modal>
  )
}

export default ConsentModal;