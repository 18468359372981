import styled, { css } from "styled-components"

const StyledBannerImage = styled.div`
  ${props => css`
    background-image: url("${props.url}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
`}
`
export default StyledBannerImage
