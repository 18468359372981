import React from "react"
import CatalogCheckbox from "./CatalogCheckbox"
import CustomSelect from '../shared/CustomSelect'
import CatalogOrderOptions from "./CatalogOrderOptions"
const CatalogOptions = ({
  campaign,
  optionState,
  optionActions,
  defaultValues,
  onBrandChange,
  brands,
  selectedBrand,
  generateFilterActions,
  generateFilterState,
  selectedOrder,
  onOrderChange,
  orderOptions
}) => {
  const { nextAffordableProducts, affordableProducts, highlighted, wished } = generateFilterState()
  const {
    handleNextAffordableProducts,
    handleAffordableProducts,
    handleHighlighted,
    handleWished
  } = generateFilterActions()
  const { firstTaxon, secondTaxon, thirdTaxon } = optionState()
  const {
    changeFirstTaxons,
    changeSecondTaxons,
    changeThirdTaxons
  } = optionActions()
  const {
    selectedFirstTaxon,
    selectedSecondTaxon,
    selectedThirdTaxon
  } = defaultValues()
  return (
    <>
      <div className="row between-xs py-3 items-center">
        <CustomSelect
          isClearable
          onChangeEvent={onBrandChange}
          options={brands}
          optionsLabel={'name'}
          optionsId={'id'}
          selectedValue={selectedBrand}
          placeholder="Marcas"
          classNames="col-sm col-xs-12 gl-catalog__filter"
          noMessage={"Sem resultados"}
        />
          { firstTaxon.length > 1 && (
            <CustomSelect
               isClearable
               onChangeEvent={changeFirstTaxons}
               options={firstTaxon}
               optionsLabel={'name'}
               optionsId={'id'}
               selectedValue={selectedFirstTaxon}
               placeholder="Prémios"
               classNames="text-color col-sm col-xs-12 gl-catalog__filter"
               noMessage={"Sem resultados"}
            />
          ) }
        <CustomSelect
          isClearable
          onChangeEvent={changeSecondTaxons}
          options={secondTaxon}
          optionsLabel={'name'}
          optionsId={'id'}
          selectedValue={selectedSecondTaxon}
          placeholder="Escolha uma categoria"
          classNames="text-color col-sm col-xs-12 gl-catalog__filter"
          noMessage={"Sem resultados"}
        />
        <CustomSelect
          isClearable
          onChangeEvent={changeThirdTaxons}
          options={thirdTaxon}
          optionsLabel={'name'}
          optionsId={'id'}
          selectedValue={selectedThirdTaxon}
          placeholder="Sub-categorias"
          classNames="text-color col-sm col-xs-12 gl-catalog__filter"
          noMessage={() => "Sem resultados"}
        />
        { firstTaxon.length <= 1 && (
            <CatalogOrderOptions
                campaign={campaign}
                selectedOrder={selectedOrder}
                onOrderChange={onOrderChange}
                orderOptions={orderOptions}
            />
        )}
      </div>
      <div className="row justify-between py-3">
        <div className="col-sm-9 col-xs-12 flex flex-col">
          <CatalogCheckbox
            primary
            handleClick={e => handleAffordableProducts(e)}
            label="Já tenho pontos para trocar por estes prémios"
            isSelected={affordableProducts}
          />
          <CatalogCheckbox
            handleClick={e => handleHighlighted(e)}
            label="Novidades na loja"
            isSelected={highlighted}
          />
          <CatalogCheckbox
            primary
            handleClick={e => {
              handleWished(e)
            }}
            label="Os meus favoritos"
            isSelected={wished}
          />
          <CatalogCheckbox
            primary
            handleClick={e => {
              handleNextAffordableProducts(e)
            }}
            label="Estou quase a ter pontos para poder trocar por estes prémios"
            isSelected={nextAffordableProducts}
          />

        </div>
        { firstTaxon.length > 1 && (
            <CatalogOrderOptions
                campaign={campaign}
                selectedOrder={selectedOrder}
                onOrderChange={onOrderChange}
                orderOptions={orderOptions}
            />
        )}
      </div>
    </>
  )
}

export default CatalogOptions
