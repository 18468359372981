import React, { Component } from 'react'
import { connect } from 'react-redux'
import { buildTransaction, QuizzService } from '../../normalizer';

class QuizQuestionHistoryContainer extends Component {

  state= {
    quiz:{},
    currentAnswer : {
      question: {
        options: [],
        id: 0
      }
    },
    currentQuestionNumber: 0,
    total: undefined,
    answers: []
  }

  getQuizId() {
    return Number(this.props.match.params.id)
  }

  getQuizAnswers(){
    const id = this.getQuizId()
    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      id
    }

    this.props.startTransaction( ()=>
      QuizzService.getUserQuizAnswers(payload)
        .then(response => response.data)
        .then(({quiz_completed}) => {
          const {user_questions, quiz} = quiz_completed
          this.setState({
            quiz: quiz,
            answers: user_questions,
            total: user_questions.length,
            currentQuestionNumber: 1,
            currentAnswer: user_questions[0],
          })
        })
      )
  }
  
  handleNextQuestion = () => {
      this.setState(state => ({
        currentQuestionNumber: state.currentQuestionNumber + 1,
        currentAnswer: state.answers[state.currentQuestionNumber]
      }))
   
  }
 
  handlePrevQuestion = () => {
    if(this.state.currentQuestionNumber > 1) {
      this.setState(state => ({
        currentQuestionNumber: state.currentQuestionNumber -1,
        currentAnswer: state.answers[state.currentQuestionNumber - 2]
      }))
    }
  }

  componentDidMount() {
    this.getQuizAnswers()
  }
  
  render() {
    const {component: Component, ...rest} = this.props
    const {answers, currentAnswer} = this.state
    const selected = answers.length > 0 ? currentAnswer.option_selected.id : undefined
    return (
      <Component 
        selectedAnswerId={selected}
        {...rest}
        {...this.state}
        currentQuestion={currentAnswer.question}
        onNextQuestion={this.handleNextQuestion}
        onPrevQuestion={this.handlePrevQuestion}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
})

const mapDispatchToProps =(dispatch) => ({
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(QuizQuestionHistoryContainer)

export default Container

export function withQuizQuestionHistoryContainer(component) {
  const QuestionHistoryContainerWrapper = (props) => (
    <Container
      component={component}
      {...props}
    />
  )
  return QuestionHistoryContainerWrapper
}
