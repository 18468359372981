export function startFetch() {
  return {
    type: "LOADING_TRUE"
  }
}
export function endFetch() {
  return {
    type: "LOADING_FALSE"
  }
}
export function resetFetch() {
  return {
    type: "RESET_LOADING"
  }
}
export function doLogout() {
  return {
    type: "USER_LOGOUT"
  }
}

export function buildTransaction(dispatch) {
  return promiseAction => {
    dispatch(startFetch())
    return promiseAction()
      .then(() => dispatch(endFetch()))
      .catch((error) => {
        const response = error.response;
        const rollbar = window.Rollbar;
        if(response){
          const status = error.response.status;
          switch (status) {
            case 500:
              if (rollbar) {
                rollbar.error(error)
              }
              break    
          }
        }
        else{
          if (rollbar) {
            rollbar.error(error)
          }
        }
        dispatch(endFetch());
      })
  }
}
