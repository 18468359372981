import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import AcceleratorShow from './AcceleratorShow';
import AcceleratorDetail from './Details';
import EmptyStateLayout from "../shared/EmptyStateLayout"
import { AcceleratorIcon, AcceleratorsContainer, ResizeContainer, withContainers  } from '../normalizer'
import {Link, Route, Switch} from "react-router-dom";
class Accelerators extends Component{

  render() {
    const { accelerators, imageSize, limitText } = this.props
    return (
      <>
        {accelerators.length === 0 ?
            <EmptyStateLayout message="Não existem aceleradores ativos de momento.">
              <div className="w-full flex items-center flex-col">
                <AcceleratorIcon className="gl-news__empty-icon mb-10"/>
              </div>
            </EmptyStateLayout>
            :
            <Switch>
              {accelerators.map((accelerator) => (
                  <Route exact key={`route-accelerator-${accelerator.id}`} path={`/accelerators/${accelerator.id}`}>
                    <AcceleratorDetail accelerator={accelerator}  limitText={limitText} imageSize={imageSize}/>
                  </Route>)
              )}
              <Route key={`route-accelerators`} exact path="/accelerators">
                <>
                  {accelerators.map((accelerator) => (
                      <div key={accelerator.id} className='col-lg-12 col-md-12 col-xs-12 my-8 '>
                        <Link className="text-color" to={`/accelerators/${accelerator.id}`}>
                          <AcceleratorShow accelerator={accelerator} limitText={limitText} imageSize={imageSize} />
                        </Link>
                      </div>
                  ))}
                </>
              </Route>
            </Switch>
        }
      </>
    );
  }
}
export default withContainers(Accelerators, [AcceleratorsContainer, ResizeContainer]); 
