export function saveSupportingDocuments(state){
  return {
    type: 'SAVE_SUPPORTING_DOCUMENTS',
    payload: state
  }
};

export function saveSupportingDocumentsOfType(state){
  return {
    type: 'SAVE_SUPPORTING_DOCUMENTS_OF_TYPE',
    payload: state
    
  }
};