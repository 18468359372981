import React from 'react'

const InvoiceProductRow = ({product, editable, onProductEdit, onProductRemove}) => (
  <tr className="user-header">
    {
      product.categories.filter((e,i)=> i!==0).map((category, i) => (
        <td key={i} className="text-left px-1  ">{category}</td>
      ))
    }
    <td className="px-1 py-2">{product.product.external_identifier || ''}</td>
    <td className="pr-2">{product.quantity || 0}</td>
    {editable && 
      <td  className="pr-2 text-color">
        <i className="far fa-edit mr-3 inline-block cursor-pointer" title="editar"  onClick={()=> onProductEdit(product)}></i>
        <i className="fa fa-times inline-block cursor-pointer" title="remover"  onClick={()=> onProductRemove(product)}></i>
      </td>
    }
  </tr>
)
const InvoiceProductTable = ({products, editable, ...rest}) => {
  return (
    <>
      <table className="w-full font-quicksand-light text-left">
        <thead className="primary-bg navbar-text py-4">
          <tr>
            <th className="pl-2 py-2">Marca</th>
            <th className="px-1">Grupo</th>
            <th className="px-1">Categoria</th>
            <th className="px-1">Produto-Código</th>
            <th className="pr-2 text-left">Quantidade</th>
            {editable && <th className="pr-2 text-left">Ações</th> }
          </tr>
        </thead>
        <tbody> 
          {
            products.map((p, i) => (
              <InvoiceProductRow 
                key={'product-' + i}
                product={p}
                editable={editable}
                {...rest}
              />
            ))
          }
          
        </tbody>
      </table>
      {products.length <= 0 &&
        <div className="text-center text-xl text-color pl-2 py-2">
          Sem produtos
        </div>
      }
    </>
  )
}

export default InvoiceProductTable