import React from "react"
import Moment from "moment"
import EmptyStateLayout from "../shared/EmptyStateLayout"
import BonusPointsLayout from "./BonusPointsLayout"
import Pagination from "../shared/Pagination"

const BonusGivenPointsHeaders = () => {
  return (
    <>
      <tr className='gl-table__row'>
        <th className="p-2 text-left">Produto</th>
        <th className="p-2 text-right">Pontos Atribuidos</th>
        <th className="p-2 text-right">Atribuido a:</th>
        <th className='p-2 text-right'>Data de atribuição</th>
      </tr> 
    </>
  )
}

const BonusPointsGivenTable = props => {

  const {
    givenBonusPoints,
    pagination,
    onPageChange,
    page
  } = props

  const hasPointsToGive = givenBonusPoints.length !== 0 
  
  return (
    <BonusPointsLayout>
      <div className="w-full text-size">
        {!hasPointsToGive ? (
          <EmptyStateLayout message="Ainda não existem transferencias realizadas.">
          </EmptyStateLayout>
        ) : (
          <div className='overflow-x-auto'>
            <table className='w-full gl-table'>
              <thead>
                <BonusGivenPointsHeaders />
              </thead>
              <tbody>
              { givenBonusPoints.map((givenPoint,i) => {
                const normalizedDate = new Moment(givenPoint.date)
                return (
                  <tr className='gl-table__row' key={givenPoint.id}>
                    <td className='p-2'>{givenPoint.product_name}</td>
                    <td className='p-2 text-right'>{givenPoint.points_earned}</td>
                    <td className='p-2 text-right'>{givenPoint.user_login}</td>
                    <td className='p-2 text-right'>{normalizedDate.format('DD/MM/YYYY')}</td>
                  </tr> 
                  )
                })
              }
              </tbody>
            </table>
          </div>
          
        )}
        {pagination.total_pages > 1 && (
          <div className="flex justify-between mt-10">
            <p className="lowercase dead-grey my-auto">
              {pagination.records || 0} transferencias efetuadas
            </p>
            <Pagination
              totalPages={pagination.total_pages}
              currentPage={page}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </BonusPointsLayout>

  )
}

export default BonusPointsGivenTable
