import axios from './interceptor';

export const UserService = {

  doLogin(payload) {
    const url ='/users/sign_in';
    return axios({
      method: 'post',
      url: url,
      data: payload
    });
  },
  doLoginWithToken(payload) {
    const url ='/users/sign_in_with_token';
    return axios({
      method: 'post',
      url: url,
      data: payload
    });
  },
  verifyUser(payload) {
    let url = '/api/verify';
    return axios({
      method: 'post',
      url: url,
      data: payload
    });
  },
  updateUser(payload) {
    const url = '/api/users'
    return axios({
      method: 'put',
      url: url,
      data: payload
    });
  },
  updatePassword(payload) {
    const url = '/api/users/update_password'
    return axios({
      method: 'put',
      url: url,
      data: payload
    })
  },
  requestPasswordRecovery(payload){
    const url = `/api/clients/${payload.clientSlug}/passwords`
    return axios({
      method: 'post',
      url: url,
      params: {
        user_email : payload.emailOrLogin
      } 
    })
  },
  finishPasswordRecovery(payload){
    const url = `/api/clients/${payload.clientSlug}/passwords`    
    return axios({
      method: 'put',
      url: url,
      data: {
        user: {
          password: payload.password,
          password_confirmation: payload.passwordConfirmation
        },
        password_token: payload.pwToken
      }
    })
  },
  checkToken(payload){
    const url = `/api/clients/${payload.client_slug}/users/by_token`
    return axios({
      method: 'get',
      url: url,
      params: payload
    })
  },
  updateAvatar(payload){
    const url = '/api/users/update_avatar'
    return axios({
      method: 'put',
      url: url,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    })
  },
  createNewEntry(payload) {
    const url = `/api/clients/${payload.client_slug}/users`
    return axios({
      method: 'post',
      url: url,
      data: payload
    })
  },
  logOut(){
    let url = '/users/sign_out';
    return axios({
      method: 'delete',
      url: url
    });
  },
  checkFirstLogin(payload){
    const url = `/api/clients/${payload.client_slug}/users/set_new_password`
    return axios({
      method: 'get',
      url: url,
      params: {
        user_email: payload.user.email,
        user_login: payload.user.login
      }
    })
  },
  doFirstLogin(payload){
    const url = `/api/clients/${payload.client_slug}/users/update_password`
    return axios({
      method: 'put',
      url: url,
      data: payload
    })
  },
  getJobs(){
    const url = `api/users/group_positions`

    return axios({
      method: 'get',
      url: url
    })
  },
  validateData(payload){
    const url = `api/users/validate_data`

    return axios({
      method: 'put',
      url: url,
      data: payload
    })
  },
  getTermsOfService(){
    const url = 'api/users/terms_of_services'
    return axios({
      method: 'get',
      url: url
    })
  },
  acceptsTermsOfService(consents){
    const url = '/api/users/terms_of_services/accept_terms'
    return axios({
      method: 'put',
      url: url,
      data: {
        consents: consents
      }
    })
  },
  getRules(){
    const url = 'api/users/rules'
    return axios({
      method: 'get',
      url: url
    })
  },
  getRegulation(){
    const url = 'api/users/regulation'
    return axios({
      method: 'get',
      url: url
    })
  },
  getConsents(){
    const url = 'api/users/consents'
    return axios({
      method: 'get',
      url: url
    })
  },
  updateConsents(consents){
    const url = 'api/users/consents'
    return axios({
      method: 'put',
      url: url,
      data: {
        client_consents: consents
      }
    })
  }
};
