import React from "react"
import RankingSlider from "./RankingSlider"
import Objectives from "./Objectives"
import Status from "./Status"
import { withRouter, matchPath } from "react-router-dom"
import { withTemplateContainer } from "../../../normalizer"

const User = ({ objectives, campaign, rankings, location, mobileView }) => {
  const isHome = matchPath(location.pathname, { path: "/", exact: true })

  const contentClasses = [
    "w-full sm:w-2/3 md:w-1/2 lg: w-2/5 xl:w-2/5 g-user-content",
    isHome && "g-user-content--home" 
  ]
  return (
    <div id="g-user" className={"g-user-container p-container navbar-text"}>
      <div className={contentClasses.join(" ")}>
        <Status />
        { !mobileView && isHome && (
          <>
            <Objectives objectives={objectives} campaign={campaign} />
            <RankingSlider rankings={rankings} />
          </>
        )}
      </div>
    </div>
  )
}

export default withRouter(withTemplateContainer(User))
