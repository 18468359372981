import React, { Component } from "react"
import { connect } from "react-redux"
import { matchPath, withRouter } from "react-router-dom"
import {
  BannersService,
  saveHomepageBanners,
  buildTransaction
} from "../normalizer"

class BannerContainer extends Component {
  randomizedNumber = Math.floor(Math.random() * Math.floor(100))

  getHomepageBanners() {
    const { user, campaign } = this.props
    const clientSlug = user.client.slug

    const payload = {
      client_slug: clientSlug,
      campaign_slug: campaign.slug
    }

    this.props.startTransaction(() =>
      BannersService.getHomepageBanners(payload).then(response => {
        this.props.saveHomepageBanners(response.data)
      })
    )
  }

  getBannerTypes() {
    const { homepageBanners } = this.props
    const { pathname } = this.props.location

    const paths = [
      { "url": "objectives", "page": "objectives"   },
      { "url": "news",       "page": "announcement" },
      { "url": "rankings",   "page": "rankings"     },
      { "url": "catalog",    "page": "catalog"      },
      { "url": "quiz",       "page": "quiz"         },
      { "url": "dashboard",  "page": "dashboard"    },
      { "url": "",           "page": ""             }
    ]
    const choosenPath = paths.find(path =>
      matchPath(pathname, { path: "/" + path.url })
    )
    const banners = this.props[`${choosenPath.page}Banners`] || homepageBanners || []
    return banners
  }

  componentDidMount() {
    if (this.props.homepageBanners.length === 0) this.getHomepageBanners()
  }

  render() {
    const banners = this.getBannerTypes()
    const { component: Component, ...rest } = this.props
    const randomizedBanner = banners[0]
    // const randomizedBanner = banners[this.randomizedNumber % banners.length]
    return <Component {...rest} banner={randomizedBanner} banners={banners} />
  }
}

const mapStateToProps = state => ({
  homepageBanners: state.homepageBannersReducer.banners,
  rankingsBanners: state.homepageBannersReducer.rankingsBanners,
  objectivesBanners: state.homepageBannersReducer.objectivesBanners,
  dashboardBanners: state.homepageBannersReducer.dashboardBanners,
  announcementBanners: state.homepageBannersReducer.announcementsBanners,
  catalogBanners: state.homepageBannersReducer.catalogBanners,
  quizBanners: state.homepageBannersReducer.quizzesBanners,
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign
})

const mapDispatchToProps = dispatch => ({
  saveHomepageBanners: banners => {
    dispatch(saveHomepageBanners(banners))
  },
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BannerContainer))

export default Container

export function withBannerContainer(component) {
  const BannerContainerWrapper = props => (
    <Container component={component} {...props} />
  )
  return BannerContainerWrapper
}
