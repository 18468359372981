const initialState = {
  bonusPointsBalance: [],
  givenBonusPoints: [],
  givenBonusPagination: {},
  clientProducts: []
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_BONUS_POINTS_BALANCE":
      const clientProducts = payload.map((bonusPoints) => (
        bonusPoints.client_product
      ))
      return {
        ...state,
        bonusPointsBalance: payload,
        clientProducts: clientProducts
      }

    case "SAVE_GIVEN_BONUS_POINTS":
      return {
        ...state,
        givenBonusPoints: payload.points_given,
        givenBonusPagination: payload.pagination
      }
    default:
      return state
  }
}
