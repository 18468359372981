import React from 'react';

const PageTable = ({ columns = [], rows = [], headerBckColor, enhancedBckColor }) => {
  const columnKeys = columns.map(col => col.key)
  return (
    <div className='w-full h-full pr-2 py-3 overflow-y-auto overflow-x-hidden'>
      <table className='center w-full dashboard-table'>
        <thead className='w-full dashboard-table-header'>
          <tr className='w-full dashboard-table-header-row' style={{ backgroundColor: `${headerBckColor}` }}>
            {
              columns.map((col, idx) => (
                <th className='dashboard-table-header-column' key={`pages_col_${idx}`} >
                  <div
                    // className={`${idx === 0 ? 'text-left' : idx === (columns.length - 1) ? 'text-right' : 'text-center'} text-xl font-bold mx-2`}
                    className={`flex flex-col justify-center items-start w-full h-full text-left text-sm font-bold px-2`}
                    style={{ paddingright: '2rem', color: 'rgb(0, 0, 0)' }}
                  >
                    {col.legend}
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody className='w-full my-1 dashboard-table-body' >
          {
            rows.map((row, ridx) => (
              <tr className='dashboard-table-body-row' key={`pages_row_${ridx}`} style={{ backgroundColor: !!row.enhanced ? `${enhancedBckColor}` : '#fff' }}>
                {columnKeys && columnKeys.map((col, idx) => (
                  <td className='dashboard-table-body-column' key={`row_col_${idx}`}>
                    <div
                      // className={`${idx === 0 ? 'text-left' : idx === (columns.length - 1) ? 'text-right' : 'text-center'} text-lg font-semibold mx-2`}
                      className={`flex flex-col justify-center items-start w-full h-full text-xs font-semibold px-2`}
                      style={{ paddingright: '2rem', color: 'rgb(0, 0, 0)' }}
                    >
                      {row[col]}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default React.memo(PageTable);
