import React, { Component } from 'react'
import { connect } from 'react-redux'
import {saveObjectives} from '../../../actions/objectiveActions'
import { ObjectiveService } from '../../../services';
import { buildTransaction } from '../../../actions/generalActions';
import { matchPath} from 'react-router-dom'
export class ObjectiveContainer extends Component {
  state = {
    page: 1,
    selectedObjective: null
  }

  handleSelectObjective = (item) => {
    this.setState({selectedObjective: item}, ()=> {
      this.props.history.push('/objectives/'+ item.id)
    })
  }

  unselectObject = () => {
    this.setState({selectedObjective: null}, () => {
      this.props.history.push('/objectives')
    })
  }

  tryGoingToObjectiveDetail = (objectives) => {
    const matching = matchPath(this.props.history.location.pathname, {
      path: '/objectives/:id',
      exact: true
    })

    if(matching) {
      const objective = objectives.find(e => e.id.toString() === matching.params.id.toString())
      if(objective) {
        this.handleSelectObjective(objective)
      } else {
        this.props.history.push('/objective/not-found')
      }
    }
  }

  getObjectives = () => {
    const payload  = {
      client_slug: this.props.client.slug,
      slug: this.props.campaign.slug,
      page: this.state.page
    }
    
    this.props.startTransaction(()=>
      ObjectiveService.getAll(payload)
        .then(response => response.data.objectives)
        .then(objectives=> {
          this.tryGoingToObjectiveDetail(objectives)
          this.props.saveObjectives(objectives)
        })
    )
  }
  

  componentDidMount() {
    this.getObjectives()
  }

  render() {
    const {
      component: Component,
      ...rest
    } = this.props

    return (
      <Component 
        onObjectSelect={this.handleSelectObjective}
        selected={this.state.selectedObjective}
        onUnselectObject={this.unselectObject}
        {...rest} 
      />
    )
  }
}

const mapStateToProps = (state) => ({
  objectives: state.objectivesReducer.objectives,
  client: state.userReducer.client,
  campaign: state.campaignReducer.campaign
})

const mapDispatchToProps = (dispatch) => ({
  saveObjectives: (objectives) => dispatch(saveObjectives(objectives)),
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(ObjectiveContainer)

export function withObjectiveContainer(component) {
  return (props) => (
    <Container 
      component={component}
      {...props}
    />
  )
}
export default Container
