import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveUser } from '../../../actions/userActions';
import { saveWishes, saveHighlights } from '../../../actions/productsActions';
import Product from '../shared/Product';
import ProductCarousel from '../shared/ProductCarousel';
import { ProductsService } from '../../../services/products.service';
import { startFetch, endFetch} from '../../../actions/generalActions';
import ContentLayout from '../shared/ContentLayout';
import {t} from '../../../i18n'
class WishList extends Component{

  constructor(props){
    super(props);
    this.state={fetched_wishes: false};
  }
  getWishes(){
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }

    ProductsService.getWishes(payload).then((response) => {
      const products = response.data
      this.props.saveWishes(products);
      this.props.endFetch();
    });
  }

  getHighlights(){
    this.props.startFetch();
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      campaign_slug: campaign.slug
    }

    ProductsService.getHighlights(payload).then((response) => {
      const products = response.data
      this.props.saveHighlights(products);
      this.props.endFetch();
    });
  }

  componentDidUpdate(oldProps) {
    if(oldProps.campaign.slug !== this.props.campaign.slug) {
      this.getHighlights()
      this.getWishes()
    }
  }


  componentDidMount(){
    if (this.props.wishes.length === 0){
      this.getWishes()
      this.setState( state => ({
        fetched_wishes: true
      }))
    }
    if (this.props.highlights.length === 0){
      this.getHighlights()
    }
  }

  render() {
    const {
      lastSeenProducts,
      wishes,
      campaign
    } = this.props
    return (
      <div className="catalog">
        <ContentLayout campaign={campaign} title="Meus Favoritos">
          <div className="col-lg col-md col-xs">
            <div className="row start-xs">
            {
              wishes.map((item,i) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4" key={i}>
                  <div className="new" style={{outline: 'none',display: item.new ? '' : 'none'}}>
                    <p className="new-label pt-1 pl-1">{t('product.new')}</p>
                  </div>
                  <div className="box prod">
                    <Product item={item} campaign={campaign}/>
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </ContentLayout>
        <div className=" cont row start-lg start-md start-xs">
          <div className="col-lg-12 col-md-12 col-xs-12 mt-16">
            <ProductCarousel
              catalog
              productsNumber= {4}
              products = {lastSeenProducts}
              campaign ={campaign}
              title={t('catalog.viewed-recently')}
              lineColor="#82D643"/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    loading: state.campaignReducer.campaign_loading,
    wishes: state.productsReducer.wishes,
    highlights: state.productsReducer.highlights,
    lastSeenProducts: state.productsReducer.lastSeenProducts,
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveWishes: (wishes) => {
      dispatch(saveWishes(wishes))
    },
    saveHighlights: (highlights) => {
      dispatch(saveHighlights(highlights))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WishList);
