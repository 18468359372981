const initialState = {
  groupBlock: {percentage: 0, group_name: ''},
  productBlocks: [],
  historyBlocks: [],
  historyPagination: {},
  lastUpdated: 'N/A'
}

const pointBlocksReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_POINT_BLOCKS':
      return {
        ...state,
        groupBlock: action.payload.groupBlock,
        productBlocks: action.payload.productBlocks,
        lastUpdated: action.payload.lastUpdated
      }
    case 'SAVE_HISTORY_BLOCKS':
      return {
        ...state,
        historyBlocks: action.payload.historyBlocks,
        historyPagination: action.payload.pagination
      }
    default:
      return state;
  }
}
export default pointBlocksReducer;