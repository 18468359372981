import React, { Component } from 'react';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import CartItems from '../shared/CartItems';
import { Redirect } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import CartAddressShow from '../shared/AddressShow';
import OrderPaymentDetailsInline from '../shared/OrderPaymentDetailsInline';

class UserOrderShow extends Component{

  getStyle(status){
    const {
      color_primary,
      color_secondary
    } = this.props.campaign
    if (status === 'Enviado'){
      return {backgroundColor: color_primary}
    }
    else if (status === 'Reembolsado'){
      return {backgroundColor: color_primary}
    }
    else if (status === 'Entregue'){
      return {backgroundColor: color_secondary}
    }
    else {
      return {}
    }
  }

  componentDidUpdate(oldprops){
    if(oldprops.campaign.slug !== this.props.slug) {
      this.props.history.push('/profile/orders')
    }
  }

  render() {
    if (this.props.order.id === null) {
      return <Redirect to='/profile/orders'/>;
    }

    const {
      campaign,
      order
    } = this.props

    return (
        <>
          <div className='template'>
            <div className='pb-6 flex between-xs'>
              <p>Nº de Encomenda <span style = {{color: campaign.color_primary}} className='text-3xl'>{order.number}</span></p>
              <Link style={{color: campaign.color_primary}}to='/profile/orders'>Voltar</Link>
            </div>
            <div style={this.getStyle(order.status)} className='order-show-status'>
              <p className='text-center'>{order.status}</p>
            </div>
            <CartItems campaign ={campaign} cart={order}/>
            { order.order_payment ? <OrderPaymentDetailsInline classes='' campaign = {campaign} orderPayment= {order.order_payment} /> : null }
            { order.shipment_address ? <CartAddressShow classes='' campaign = {campaign} address= {order.shipment_address} /> : null }
          </div>
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer.campaign,
    order: state.ordersReducer.selectedOrder
  }
}

export default connect(mapStateToProps,null)(withRouter(UserOrderShow));
