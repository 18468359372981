import styled, { css } from "styled-components"

const StyledImage = styled.div`
  ${props => css`
    background-image: url("${props.url}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`}
`
export default StyledImage
