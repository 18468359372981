import React from "react"
import RankingSlider from "./RankingSlider"
import Objectives from "./Objectives"
import PointBlocks from "./PointBlocks"
import Status from "./Status"
import { withRouter, matchPath } from "react-router-dom"
import { withTemplateContainer } from "../../../normalizer"

const User = ({ objectives, campaign, rankings, location, mobileView, toRender, user }) => {
  const isHome = matchPath(location.pathname, { path: "/", exact: true })
  const needsUserHomeInfo = toRender('Gamification-Objetivos') ||  toRender('Bloqueio de Pontos') || toRender('Rankings')
  const contentClasses = [
    "w-full sm:w-2/3 md:w-1/2 lg: w-2/5 xl:w-1/3 gl-user-content",
    (isHome && needsUserHomeInfo) && "gl-user-content--home" 
  ]
  return (
    <div id="gl-user" className={"gl-user-container gl-p-container primary-text"}>
      <div className={contentClasses.join(" ")}>
        <Status toRender={toRender} />
        { !mobileView && isHome && (
          <>
            <div className='gl-user-container-bubbles'>
              {/* toRender('Bloqueio de Pontos') &&
                <PointBlocks currencyLabel = {user.client.currency_label} color='secondary-text' />
                */}
              { toRender('Gamification-Objetivos') && 
                <Objectives objectives={objectives} campaign={campaign} color='navbar-text' />
              }
              </div>
              { toRender('Rankings') &&
                <RankingSlider rankings={rankings} secondaryText/>
              }
          </>
        )}
      </div>
    </div>
  )
}

export default withRouter(withTemplateContainer(User))
