import axios from './interceptor';

export const CartService = {

  getCart(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts`;
    return axios({
      method: 'get',
      url: url
    });
  },
  addItem(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/add_item/${payload.item_id}`
    return axios({
      method: 'post',
      url: url
    });
  },
  increaseItem(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/increase_quantity/${payload.item_id}`
    return axios({
      method: 'put',
      url: url
    });
  },
  decreaseItem(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/decrease_quantity/${payload.item_id}`
    return axios({
      method: 'put',
      url: url
    });
  },
  removeItem(payload) {
    const url =`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/remove_item/${payload.item_id}`
    return axios({
      method: 'delete',
      url: url
    });
  },
  updateShipmentAddress(payload){
    const url=`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/update_address/${payload.address_id}`
    return axios({
      method: 'put',
      url: url
    });
  },
  checkCartItems(payload){
    const url=`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/check_items`
    return axios({
      method: 'post',
      url: url
    });
  },
  checkCartAddress(payload){
    const url=`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/check_address`
    return axios({
      method: 'post',
      url: url
    });
  },
  CartCheckout(payload){
    const url=`/api/clients/${payload.client_slug}/campaigns/${payload.slug}/carts/checkout`
    return axios({
      method: 'post',
      url: url,
      data: payload.co_payment
    });
  }
};
