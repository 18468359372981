import React, { Component } from "react"
import { Switch } from "react-router-dom"
import Objectives from "./Objectives"
import CampaignDashboad from "../shared/CampaignPages";
import "../../assets/css/gamification/index.scss"
import { default as Route } from "../../routes/CustomRoute"
import Home from "./Home"
import Catalog from "./Catalog"
import UtilityInfo from "./UtilityInfo"
import Profile from "./Profile"
import ShoppingCart from "./ShoppingCart"
import News from "./Announcement"
import Quiz from "./Quiz"
import Rankings from "./Rankings"
import Analytics from "./Analytics"
import { CampaignService, ChatService } from "./normalizer"
import GamificationLayout from "./shared/Layout"

export default class GamificationTemplate extends Component {
  state = {
    features: [],
    requested: false,
  }

  fetchFeatures(clientSlug, campaignSlug) {
    CampaignService.getFeatures({ clientSlug, campaignSlug })
      .then((response) => {
        const { features } = response.data

        const hasChatFeature = features.find(
          (feature) => feature.name === "Chat"
        )
        if (hasChatFeature) ChatService.createChatTag("ze-snippet")

        this.setState({ features: features, requested: true })
      })
      .catch((e) => {
        this.setState({ requested: true })
      })
  }

  toRender = (route) => {
    return this.state.features.find(
      (f) => f.name.toLowerCase() === route.toLowerCase()
    )
  }

  componentDidCatch(e) {
    const { rollbar } = window
    if (rollbar && rollbar.error) {
      window.rollbar.error(e)
    }
    console.error(e)
  }

  componentDidMount() {
    const { slug, campaign } = this.props
    this.fetchFeatures(slug, campaign.slug)
  }

  features = () => { return this.state.features }

  render() {
    const { client, campaign, slug } = this.props
    return (
      <GamificationLayout
        toRender={this.toRender}
        campaign={campaign}
        slug={slug}
        features={this.features()}
      >
        <Switch>
          <Route
            exact
            path="/"
            component={Home}
            campaign={campaign}
            slug={slug}
            toRender={this.toRender}
          />
          <Route
            path="/catalog"
            component={Catalog}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/utility-info"
            component={UtilityInfo}
            campaign={campaign}
          />
          <Route
            path="/profile"
            component={Profile}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/cart"
            component={ShoppingCart}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/objectives"
            component={Objectives}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/dashboard"
            component={CampaignDashboad}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/announcement"
            component={News}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/quiz"
            component={Quiz}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/rankings"
            component={Rankings}
            campaign={campaign}
            slug={slug}
          />
          <Route
            path="/analytics"
            component={Analytics}
            client={client}
            campaign={campaign}
            slug={slug}
          />
        </Switch>
      </GamificationLayout>
    )
  }
}
