import React from "react";
import Slider from "react-slick";
import { Chevron } from '../../../normalizer';

function GamificationNextArrow({ onClick, secondaryText, textColor }) {
  return (
    <Chevron
      className={`gl-rankings__arrow gl-rankings__arrow--next ${secondaryText ? 'navbar-fill' : ''} ${textColor ? 'text-fill': ''}`}
      
      onClick={onClick}
    />
  );
}

function GamificationPrevArrow({ onClick, secondaryText, textColor }) {

  return (
    <Chevron
      className={`gl-rankings__arrow gl-rankings__arrow--left ${secondaryText ? 'navbar-fill' : ''} ${textColor ? 'text-fill' : ''}`}
      onClick={onClick}
    />
  );
}

const RankingSlider = ({ rankings, secondaryText, textColor }) => { 
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <GamificationNextArrow secondaryText={secondaryText} textColor={textColor} />,
      prevArrow: <GamificationPrevArrow secondaryText={secondaryText} textColor={textColor} />,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 7500,
      cssEase: "linear",
      appendDots: dots => (
        <div>
          <ul className='gl-rankings__dots'> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <span className='gl-rankings-dot'>.</span>
      )
    }
    if (rankings.length === 0){
      return <></>
    }

    
    return (
        
        <Slider {...settings} className={`my-8 gl-rankings__slider ${secondaryText && 'navbar-text'} ${textColor && 'text-color'}`}>
          {
            rankings.map((ranking,i) => {
            return(
              <div key={i} className={`text-xl text-center mb-2 ${secondaryText && 'navbar-text'} ${textColor && 'text-color'}`}>
                  <p>{ranking.name}</p>
                  <p>
                    <span className='text-3xl ml-1'>
                      {ranking.is_winner ? '' : ranking.position !== 'N/A' ? `${ranking.position}ª ` : '-'}
                    </span>
                    <span className='mr-1'>{ranking.is_winner ? ranking.winning_message : 'Posição'} </span>
                  </p>
              </div>
            )
            })
          }
        </Slider>
    );
}

export default RankingSlider;