import React from 'react'

import styled, {css} from 'styled-components'

const HoverButtonWrapper = styled.button`
  color: ${props=> props.textColor}
  text-align: left;
  padding: 19px
  
  :hover {
    background: ${props=> props.secondary}
  }
  
  ${props => props.active && css`
    color: ${props.secondaryTextColor}
    background: ${props=> props.secondary}
  `}
`
export function HoverButton({campaign, ...rest}) {
  return (
    <HoverButtonWrapper 
      {...extractCampaignInfo(campaign)}
      {...rest}
    />
  )
}

//utility function to capture campaing information
const extractCampaignInfo = (campaign) => ({
  primary: campaign.color_primary,
  secondary: campaign.color_secondary,
  textColor: campaign.navbar_text_color
})
