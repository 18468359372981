import React, { Component } from 'react'
import { connect } from 'react-redux'
import InvoiceProductForm from './InvoiceProductForm';
import { InvoiceService } from '../../../services';
import {startFetch, endFetch} from '../../../actions/generalActions';
import {ToastStore} from 'react-toasts';
import {t, prefix} from '../../../i18n'
const tSelector = prefix('registry')
export class InvoiceProductFormContainer extends Component {

  state = {
    // groupId: "",
    // categoryId: "",
    brands: [],
    groups: [],
    categories: [],
    products: [],
    selectedSaleType: null,
    selectedBrand: null, 
    selectedGroup: null,
    selectedCategory: null,
    selectedProduct: null,
  }
  handleSaleTypeChange = (data) => {
    if(data == null) {
      this.setState({
        brands:[], 
        groups: [], 
        categories: [], 
        products: [],
        selectedSaleType: null,
        selectedBrand: null, 
        selectedGroup: null,
        selectedCategory: null,
        selectedProduct: null
      })
      return 
    }

    const { slug } = this.props.user.client
    const payload = {clientId: slug, parentId: data.id}
    this.props.startTran(()=> 
      InvoiceService.getClientCategories(payload)
        .then(response => response.data.client_categories)
        .then(brands => {
          this.setState({ 
            brands: brands,
            groups: [], 
            categories: [], 
            products: [],
            selectedSaleType: data,
            selectedBrand: null, 
            selectedGroup: null,
            selectedCategory: null,
            selectedProduct: null
          })
        })
    )
  }

  handleBrandChange = (data) => {

    if(data == null) {
      this.setState({
        groups: [], 
        categories: [], 
        products: [],
        selectedBrand: data,
        selectedGroup: null,
        selectedCategory: null,
        selectedProduct: null
      })
      return 
    }

    const {slug} = this.props.user.client
    const payload = {clientId: slug, parentId: data.id}
    this.props.startTran(()=> 
      InvoiceService.getClientCategories(payload)
        .then(response => response.data.client_categories)
        .then(groups => {
          this.setState({
            groups: groups,
            categories: [], 
            products: [],
            selectedBrand: data,
            selectedGroup: null,
            selectedCategory: null,
            selectedProduct: null
          })
        })
    )
  }


  handleGroupChange = (data) => {
    if(data == null) {
      this.setState({
        categories: [], 
        products:[], 
        selectedGroup: data,
        selectedCategory: null,
        selectedProduct: null
      })
      return 
    }
    
    const {slug} = this.props.user.client
    const payload = {clientId: slug, parentId: data.id}
    this.props.startTran(()=> 
      InvoiceService.getClientCategories(payload)
        .then(response => response.data.client_categories)
        .then(categories => {
          this.setState({
            categories: categories,
            products: [],
            selectedGroup: data,
            selectedCategory: null,
            selectedProduct: null
          })
        })
    )
  }
  
  handleCategoryChange = (data) => {
    if(data == null) {
      this.setState({
        products:[], 
        selectedCategory: data,
        selectedProduct: null
      })
      return 
    }
    
    const {slug} = this.props.user.client
    const payload = {clientId: slug, parentId: data.id}
    this.props.startTran(()=> 
      InvoiceService.getClientProducts(payload)
        .then(response => response.data.client_products)
        .then(products => {
          this.setState({
            products: products, 
            selectedCategory: data,
            selectedProduct: null
          })
        })
    )
  }

  handleProductChange = (data) => {
    this.setState({selectedProduct: data})
  }

  handleSubmit = (e)=> {
    e.preventDefault()
    const quantity = e.target.quantity.value
    const { selectedProduct, selectedCategory, products } = this.state
    if ( !selectedProduct || !selectedCategory){
      ToastStore.error(tSelector('fill-select'))
      return;
    }
    const data = {
      productId: selectedProduct.id,
      clientCategoryId: selectedCategory.id,
      quantity
    }
    this.props.onSubmit(data)
  }


  render() {
    return (
      <InvoiceProductForm 
        {...this.props} 
        onProductChange={this.handleProductChange}
        onSaleTypeChange={this.handleSaleTypeChange}
        onGroupChange={this.handleGroupChange}
        onCategoryChange={this.handleCategoryChange}
        onBrandChange={this.handleBrandChange}
        onSubmit={this.handleSubmit}
        {...this.state}/>
    )
  }
}

const mapStateToProps = (state) => ({
  saleTypes: state.invoiceReducer.brands, //martelada -> brands passou a ser sale types
  user: state.userReducer.user,
})

const mapDispatchToProps = (dispatch) =>  ({
  startTran: (promiseAction) => {
    dispatch(startFetch())
    promiseAction()
      .then(()=> 
        dispatch(endFetch())
      ).catch(()=> 
        dispatch(endFetch())
      )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProductFormContainer)
