import React from "react"
import Moment from 'moment'
import { Switch } from "react-router-dom"
import { default as Route } from "../../../routes/CustomRoute"
import EmptyStateLayout from "../shared/EmptyStateLayout"
import { NoPoints, withBonusPointsContainer } from "../normalizer"
import BonusPointsTable from "./BonusPointsTable"
import BonusPointsGivenTable from "./BonusPointsGivenTable"
import BonusPointsAddForm from "./BonusPointsAddForm"

const BonusPoints = (props) => {
  const { 
    selectedColleague, 
    colleagues, 
    onChangeColleague, 
    campaign, 
    onUpdatePoints,
    pointsToGive,
    onSubmitForm,
    formErrors,
    bonusPointsBalance,
    clientProducts,
    onChangeProduct,
    selectedProduct,
    givenBonusPoints,
    campaignUser,
    onPageChange,
    givenBonusPagination,
    givenPage
  } = props

  return (
    <>
      { !campaignUser.canGiveBonusPoints ? 
        <EmptyStateLayout message={'Não existe pontos para atribuires'}>
          <div className="gl-no-store">
            <NoPoints className="mr-3 dead-grey gl-no-store__image" />
          </div>
        </EmptyStateLayout>
      : 
        <Switch>
          <Route
            exact
            path="/bonus_points"
            component={BonusPointsTable}
            bonusPoints={bonusPointsBalance}
          />
          <Route
            exact
            path="/bonus_points/add"
            component={BonusPointsAddForm}
            campaign={campaign}
            colleagues={colleagues}
            onChangeColleague={onChangeColleague}
            selectedColleague={selectedColleague}
            points={pointsToGive}
            onUpdatePoints={onUpdatePoints}
            onSubmitForm={onSubmitForm}
            formErrors={formErrors}
            clientProducts={clientProducts}
            onChangeProduct={onChangeProduct}
            selectedProduct={selectedProduct}
          />
           <Route
            exact
            path="/bonus_points/history"
            component={BonusPointsGivenTable}
            givenBonusPoints={givenBonusPoints}
            pagination={givenBonusPagination}
            onPageChange={onPageChange}  
            page={givenPage}  
          />
        </Switch>
      }
    </>

  )
}

export default withBonusPointsContainer(BonusPoints)