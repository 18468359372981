import React from "react";
import { colorSchemes } from '@nivo/colors'
import { ResponsiveLine } from '@nivo/line';

const Line = (props) => {
  const { data, title, height, legend, palette, orientation, dataOptions, numOfCols, axisTop, margin, axisRight, axisLeft, axisBottom } = props
  const { onMouseEnter, onMouseLeave } = props

  let biggestScheme = 0;
  const schemeOptions = palette ? colorSchemes[palette.name] : colorSchemes['nivo']

  const markers = dataOptions && dataOptions.markers && dataOptions.markers.length > 0 ? dataOptions.markers.map((marker) => {
    const axis = orientation && orientation.toLowerCase() == 'horizontal' ? 'x' : 'y'
    return {
      axis,
      value: marker.value,
      lineStyle: { stroke: 'rgba(0, 0, 0, .8)', strokeWidth: 3, strokeStyle: 'dotted' },
      legend: marker.label,
      legendOrientation: orientation && orientation.toLowerCase() == 'horizontal' ? 'vertical' : 'horizontal',
    }
  }) : []
  // Loop to get the right color palette
  Object.keys(schemeOptions).forEach(key => {
    if (schemeOptions[key + 1] && schemeOptions[key] && schemeOptions[key].length <= schemeOptions[key + 1].length) {
      biggestScheme = key + 1;
    } else {
      biggestScheme = key;
    }
  });

  // copy to avoid changing the original array
  let colorScheme = [ ...schemeOptions[biggestScheme] ]
  if (palette && palette.inverted) {
    colorScheme.reverse();
  }

  const thereIsPaletteStartIndex = palette && palette.start_index !== null && palette.start_index !== undefined
  const thereIsPaletteFixedColor = palette && palette.fixed_color !== null && palette.fixed_color !== undefined && palette.fixed_color
  if (thereIsPaletteFixedColor) {
    colorScheme = thereIsPaletteStartIndex ? [colorScheme[palette.start_index]] : [colorScheme[0]];
  } else {
    colorScheme = thereIsPaletteStartIndex ? colorScheme.slice(palette.start_index) : colorScheme.slice(0);
  }

  const marginAndLegends = ({ anchor, direction }, serieSize = 0) => {
    let margin = {
      top: 40,
      bottom: 100,
      right: 40,
      left: 60
    }

    if (!anchor || !direction) {
      return {
        margin,
        legends: []
      }
    }

    let legend = {
      dataFrom: 'keys',
      anchor: anchor.replace('_', '-'),
      direction: direction,
      justify: false,
      translateX: 0,
      translateY: 0,
      itemWidth: 50,
      itemHeight: 15,
      itemOpacity: 0.85,
      symbolSize: 10,
      itemsSpacing: 5,
    }

    if (anchor.includes('top')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              top: 80,
            },
            legends: [{
              ...legend,
              translateY: -60,
              itemDirection: 'bottom-to-top',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              top: (25 * serieSize),
            },
            legends: [{
              ...legend,
              translateX: -60,
              translateY: (-25 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            top: 80,
          },
          legends: [{
            ...legend,
            translateY: -60,
            itemDirection: 'bottom-to-top',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            top: (25 * serieSize),
          },
          legends: [{
            ...legend,
            translateX: 30,
            translateY: (-25 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    if (anchor.includes('bottom')) {
      if (anchor.includes('left')) {
        if (direction == 'row') {
          return {
            margin: {
              ...margin,
              bottom: 80,
            },
            legends: [{
              ...legend,
              translateY: 60,
              itemDirection: 'top-to-bottom',
            }]
          }
        }
        if (direction == 'column') {
          return {
            margin: {
              ...margin,
              top: 10,
              bottom: (125 * serieSize),
            },
            legends: [{
              ...legend,
              translateX: -60,
              translateY: (115 * serieSize),
              itemDirection: 'left-to-right',
            }]
          }
        }
      }

      if (direction == 'row') {
        return {
          margin: {
            ...margin,
            bottom: 80,
          },
          legends: [{
            ...legend,
            translateY: 60,
            itemDirection: 'top-to-bottom',
          }]
        }
      }
      if (direction == 'column') {
        return {
          margin: {
            ...margin,
            top: 10,
            bottom: (125 * serieSize),
          },
          legends: [{
            ...legend,
            translateX: 30,
            translateY: (115 * serieSize),
            itemDirection: 'right-to-left',
          }]
        }
      }
    }

    return { margin, legends: [] }
  }


  let preparedData = [{
    id: title,
    data: [],
  }]
  for (var i = 0; i < data.length; i++) {
    preparedData[0].data.push(
      {
        "x": data[i].label,
        "y": parseFloat(data[i].value),
      },
    )
  }

  return (
    <div style={{ width: '100%', height }}>
      <ResponsiveLine
        data={preparedData}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        animate={true}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        pointSize={5}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-16}
        useMesh={true}
        axisTop={axisTop || null}
        axisBottom={{ tickValues: (numOfCols - 1), tickPadding: 5, tickRotation: -45 }}
        axisLeft={axisLeft || null}
        axisRight={axisRight || null}
        margin={marginAndLegends(legend, preparedData.length).margin}
        // markers={markers}
        yFormat=" >-.2f"
        legends={marginAndLegends(legend, preparedData.length).legends}
      />
    </div>
  );
};

export default Line;

