import React, { Component } from 'react';
import { connect } from 'react-redux';
import {prefix} from '../../../i18n'

const tRegistry = prefix('registry')
class ThankYou extends Component{

  constructor(props){
    super(props);
    this.state={}
  }

  render() {
    const client = this.props.client
    return (
      
      <div>
         <div className='image-background'  style={client.background_banner.url ? {backgroundImage: 'url(' + client.background_banner.url + ')'}: {} }>
          <div className="row center-lg  font-quicksand center-xs center-md h-screen">
            <div className="col-lg-8 col-md-8 col-xs-11 default-container-login bg-white">
              <h1 className='pt-10 template-title text-center'>{tRegistry('thank-you')}</h1>
              <div className="py-10 mx-8 px-8 my-10  text-left bg-white">
                <p className='template-title text-2xl center'>
                  {tRegistry('thanking-message')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedIn: state.userReducer.loggedIn,
    client: state.userReducer.client
  }
}

export default connect(mapStateToProps,null)(ThankYou);