import axios from './interceptor';

export const TaxonomiesService = {
  getTaxons(payload) {
    const url ='/api/clients/'+ payload.client_slug +'/campaigns/' + payload.campaign_slug + '/taxonomies'
    return axios({
      method: 'get',
      url: url,
      params: {
        parent_taxon_id: payload.parent_taxon
      }
    });
  },
}