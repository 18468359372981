import React from "react"
import { withPendingAttributionsContainer } from "../../../containers/default"
import SaleTable from "./SaleTable"
import ConfirmationModal from "./ConfirmationModal"
const PendingSaleAttributions = props => {
  const {
    //data
    page,
    saleAttribution,
    users,
    selectedUser,
    pendingSaleAttributions,
    pendingPagination,
    isAttributionSelected,
    //actions
    onUpdateSaleAttribution,
    onCancelSaleAttribution,
    onToggleAcceptModal,
    onPageChange
  } = props

  return (
    <div className="template col-xs-12 font-quicksand-light">
      <SaleTable
        attributions={pendingSaleAttributions}
        users={users}
        selectedUser={selectedUser}
        onAccept={onToggleAcceptModal}
        pagination={pendingPagination}
        onPageChange={onPageChange}
        page={page}
      />

      <ConfirmationModal
        selected={isAttributionSelected}
        saleAttribution={saleAttribution}
        selectedUser={selectedUser}
        onUpdateSaleAttribution={onUpdateSaleAttribution}
        onCancelSaleAttribution={onCancelSaleAttribution}
      />

      {pendingSaleAttributions.length <= 0 && (
        <div className="text-center text-xl text-color pl-2 pt-10 pb-8">
          Não Existem contratos com erros de utilizador.
        </div>
      )}
    </div>
  )
}

export default withPendingAttributionsContainer(PendingSaleAttributions)
