import React, { Component } from "react"
import { connect } from "react-redux"
import {
  LevelService,
  buildTransaction,
  saveLevelProducts,
  saveLevels,
  selectLevel
} from "../normalizer"

class LevelContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      levels: []
    }
  }

  fetchLevels() {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug
    }
    this.props.startTransaction(() => 
      LevelService.getLevels(payload).then(response => {
        const { data } = response
        this.props.saveLevels(data)
      })
    )
  }

  fetchProductsByLevel(level) {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      levelId: level.id
    }
    this.props.startTransaction(() => 
      LevelService.getProductsByLevels(payload).then(response => {
        const { data } = response
        this.props.saveLevelProducts(data)
        this.props.selectLevel(level)
      })
    )
  }
  selectLevelFlag = (level) => {
    this.fetchProductsByLevel(level)
  }

  componentDidUpdate(){
    if (this.props.campaignUser.currentLevel !== null ){
      const CampaignUserLevelNumber = this.props.campaignUser.currentLevel.level_number
      const selectedLevelNumber = this.props.selectedLevel.level_number
      const level =  this.props.levels.find(level => level.level_number === CampaignUserLevelNumber)
    
      if(selectedLevelNumber === 0 && CampaignUserLevelNumber >= 1 && level !==undefined) {
        this.props.selectLevel(level)
        this.fetchProductsByLevel(level)
      } 
    }
  }

  componentDidMount() {
    if (this.props.levels.length === 0){
      this.fetchLevels()
    }
  }

  render() {
    const { component: Component, levels, selectedLevel, levelProducts, ...rest } = this.props
    return <Component 
            levels={levels}
            selectedLevel={selectedLevel}
            onSelectLevel={this.selectLevelFlag} 
            levelProducts={levelProducts}
            {...rest} 
            />
  }
}

const mapDispatchToProps = dispatch => ({
  startTransaction: buildTransaction(dispatch),
  saveLevelProducts: (products) => {
    dispatch(saveLevelProducts(products))
  },
  selectLevel: (level) => {
    dispatch(selectLevel(level))
  },
  saveLevels: (levels) => {
    dispatch(saveLevels(levels))
  }
})

const mapStateToProps = state => ({
  levels: state.levelsReducer.levels,
  selectedLevel: state.levelsReducer.selectedLevel,
  campaignUser: state.balanceReducer.campaignUser,
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  levelProducts: state.productsReducer.levelProducts
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelContainer)

export function withLevelContainer(component) {
  return props => <Container component={component} {...props} />
}

export default Container
