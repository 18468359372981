import React from "react"
import EmptyStateLayout from "../../shared/EmptyStateLayout"
import { NoPoints, withPointBlockContainer } from "../../normalizer"
import HistoryPointBlockTable from "./HistoryPointBlockTable"

const HistoryPointBlocks = props => {
  const { historyBlocks, ...rest } = props

  const message = "Ainda não foram registados pontos."

  return historyBlocks.length > 0 ? (
    <>
      <HistoryPointBlockTable historyBlocks={historyBlocks} {...rest}></HistoryPointBlockTable>
    </>
  ) : (
    <EmptyStateLayout message="Não há ainda histórico de pontos de bloqueio">
      <div className="flex dead-grey flex-col justify-center items-center mt-5 mb-10">
        <NoPoints className="mr-3 dead-grey gl-no-store__image mb-5" />
        <h2>{message}</h2>
      </div>
    </EmptyStateLayout>
  )
}

export default withPointBlockContainer(HistoryPointBlocks)
