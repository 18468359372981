import React, { Component } from 'react';

class ShowJob extends Component{
  
  render(){
    const {
      jobs
    } = this.props

    const job =jobs[0]
    return(
      <div className="template-block between-xs text-left">
        <h2 className='pb-10 template-title'>Posição</h2>
        { job ? 
          <p className='py-3 px-5 template-title text-2xl'>{job.position.name}</p>
        : null }
      </div>
      ) 
  }
}

export default ShowJob;