import React from 'react'
import leftImg from '../../../assets/img/Left.png'
import { withShoppingCartContainer } from '../../containers/gamification';
import { matchPath } from 'react-router-dom';

const CartStep = ({to, exact, activeClassName, className, children}) => {

  const options = {
    exact: exact,
    path: to
  }

  const isActive = !!matchPath(window.location.pathname, options)

  const stepClass = ['px-0 md:px-2', isActive ? activeClassName : className].join(' ')

  return(
    <p className={stepClass}> {children} </p>
  )
}

const CartNavigation = props => {

  return (
    <nav className='gl-cart__navbar text-sm md:text-lg justify-between flex items-center text-center py-6 mt-5'>
      <CartStep
        exact to='/cart'
        activeClassName='link-reset py-1 sm:py-0 gl-cart__navbar-active primary-text'
        className='link-reset py-1 sm:py-0 secondary-text'
        >
          <span>1. Encomenda</span>
          <i className="px-1 fas fa-chevron-right"></i>
      </CartStep>
      <CartStep
        exact to='/cart/address'
        activeClassName='link-reset py-1 sm:py-0 gl-cart__navbar-active primary-text'
        className='link-reset py-1 sm:py-0 secondary-text'
        >
          <span>2. Entrega</span>
          <i className="px-1 fas fa-chevron-right "/>
      </CartStep>
      <CartStep
        to='/cart/checkout'
        activeClassName='link-reset py-1 sm:py-0 gl-cart__navbar-active primary-text'
        className='link-reset py-1 sm:py-0 secondary-text'
        >
          <span>3. Confirmação</span>
          <i className="px-1 fas fa-chevron-right "/>          
      </CartStep>
    </nav>
  )
}

export default withShoppingCartContainer(CartNavigation);
