import React from "react"
import {
    CampaignUserContainer,
    LevelContainer,
    withContainers
} from "../../normalizer"
import LevelLoader from "./LevelLoader"

const getLevelsToShow = (currentLevel,levels) => {
    let nextLevel = levels.find(level =>
      level.level_number  === currentLevel.level_number + 1
    )
    if (nextLevel === undefined){
      return [currentLevel]
    }  
    return [currentLevel,nextLevel]
}

const getPercentage = (currentLevel, lastLevel) => {
    if (lastLevel === undefined || currentLevel === undefined){
        return 0
    }
    if (currentLevel === lastLevel){
        return 100
    }
    return Number(currentLevel.percentage)
}

const LevelUserStatus = ({campaign, campaignUser, levels}) => {

    const levelsToShow = getLevelsToShow(campaignUser.currentLevel,levels)

    const progress = getPercentage(campaignUser.currentLevel, levels[levels.length-1] )
    return (
        <div className='flex justify-end gl-levels__user--container'>
            <div className='gl-levels__user--loader'>
                <LevelLoader
                    id = 'profile-level-loader'
                    campaign = {campaign}
                    campaignUser = {campaignUser}
                    levels={levelsToShow}
                    progress={progress}
                    miniFlags
                    textAlign='topmiddle'
                    heightFlag = '35px'
                />
            </div>
        </div>
    )
}

export default withContainers(LevelUserStatus, [CampaignUserContainer, LevelContainer])