import React, { Component } from 'react';
import Select from 'react-select';
import {t, prefix} from '../../../i18n'

const getHint = (field) => {
  if (field === undefined){
    return;
  }
  return field.field_hint
}

const willRender = (fields,input_field) => {
  const personalField = fields.find((field) => {
    return field.field_type === input_field
  })
  return personalField
}
const PersonalForm = ({required, onUserChange, errors, genderOptions, onGenderChange, personalOptionalFields}) => {
  const tRegistry = prefix('registry')
  const firstNameField = willRender(personalOptionalFields,'first_name')
  const lastNameField = willRender(personalOptionalFields,'last_name')
  const nifField = willRender(personalOptionalFields,'nif')
  const phoneField = willRender(personalOptionalFields,'phone')
  const birthdayField = willRender(personalOptionalFields,'birthday')
  const genderField = willRender(personalOptionalFields,'gender')

  return (
    <>
      <h2 className='py-8 template-title'>{tRegistry('personal-info')}</h2>
      <div className="flex template-block between-xs">
        { firstNameField &&
          <div className='w46'>
            <input required={required} onChange={onUserChange} className="w-full template-blue" placeholder={tRegistry('first-name')} type="text" name="first_name"></input>
            <p className='w-full my-1 template-hint'>{getHint(firstNameField)}</p>
          </div>
        }
        { lastNameField &&
          <div className='w46'>
            <input required={required} onChange={onUserChange} className="w-full template-blue" placeholder={tRegistry('last-name')} type="text" name="last_name"></input>
            <p className='w-full my-1 template-hint'>{getHint(lastNameField)}</p>
          </div>
        }
      </div>
      <div className="flex template-block between-xs">
        { nifField &&
          <div className='w46'>
            <input required={required} onChange={onUserChange} className="w-full template-blue" placeholder={tRegistry('taxpayer-nr')} type="text" name="nif"></input>
            <p className='w-full my-1 template-hint'>{getHint(nifField)}</p>
            {errors.nif  && <p className='w-full template-errors'>{tRegistry('taxpayer-nr')}: {errors.nif.join(',')}</p> }
          </div>
        }
        {  phoneField &&
         <div className='w46'> 
            <input required={required} onChange={onUserChange} className="w-full template-blue" placeholder={tRegistry('phone')}type="text" name="phone"></input>
            <p className='w-full my-1 template-hint'>{getHint(phoneField)}</p>
            {errors.phone && <p className='w-full template-errors'>{tRegistry('phone')}: {errors.phone.join(',')}</p> }
          </div>
        }
      </div>
      <div className="flex template-block between-xs">
        { birthdayField &&
          <div className='w46'>
            <input required={required} onChange={onUserChange} className="template-blue" placeholder={tRegistry('birthdate')} type="date" name="birthday"></input>
            <p className='w-full my-1 template-hint'>{getHint(birthdayField)}</p>
          </div>
        }
        { genderField &&
          <div className='w46'>
            <Select onChange = {e => onGenderChange(e)} options = {genderOptions} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
              name='gender' placeholder={tRegistry('gender')} className='w-full mt-3' classNamePrefix='custom-select'/>
          </div>
        } 
      </div>
    </>
  )
}

export default PersonalForm