import React, { Component } from "react"
import { connect } from "react-redux"
import { startFetch, endFetch } from "../../actions/generalActions"
import { UserService } from "../../services"
import { saveUser, saveLogIn, saveClient } from "../../actions/userActions"
import { Redirect, Route } from "react-router-dom"

const HomeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return localStorage.getItem("jwt") ? (
        <Redirect to="/" />
      ) : (
        <Component {...props} />
      )
    }}
  />
)
class LogInWithToken extends Component {
  state = {
    requested: false
  }
  logIn() {
    this.props.startFetch()
    const prevClient = window.localStorage.getItem("prevClient")
    const client = window.localStorage.getItem("client")
    let { token } = this.props.match.params

    if (token !== undefined) {
      // silen login with token
      const payload = {
        token: token
      }
      UserService.doLoginWithToken(payload)
        .then(response => {
          const client = response.data.client
          const user = response.data.user
          localStorage.setItem("user", JSON.stringify(user))
          this.props.saveUser(user)

          window.localStorage.setItem("prevClient", client.slug)
          document.title = client.name
          window.localStorage.setItem("currency", client.currency_label)
          this.props.saveClient(client)
          if (response.headers.authorization) {
            localStorage.setItem("jwt", response.headers.authorization)
            this.props.saveLogIn(localStorage.getItem("jwt"))
          }
          this.props.endFetch()
          window.location.href = '/'
        })
        .catch(error => {
          this.props.endFetch()
          if ((error.response !== undefined) && (error.response.data !== undefined) && (error.response.data.callback_url !== undefined)) {
            window.location.href = error.response.data.callback_url
          } else {
            this.props.history.push(`/not-found`)
          }
        })
    } else {
      this.props.history.push("/not-found")
    }
  }

  componentDidMount() {
    this.logIn()
  }

  render() {
    if (!this.state.requested) return null
  }
}
function mapDispatchToProps(dispatch) {
  return {
    saveClient: client => dispatch(saveClient(client)),
    saveUser: user => { dispatch(saveUser(user)) },
    saveLogIn: state => { dispatch(saveLogIn(state)) },
    startFetch: () => { dispatch(startFetch()) },
    endFetch: () => { dispatch(endFetch()) }
  }
}
function mapStateToProps(state) {
  return {
    client: state.userReducer.client
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogInWithToken)
