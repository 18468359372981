import React from "react"
import {
  formatNumber,
  substring,
  imagePlaceholder
} from "../../normalizer"

const PostSaleProduct = ({ colors, saleProduct }) => {  
  return (
    <div style={{borderColor: `${colors.borderColor}`}} className="gl-posts-sales__container gl-product__container--post bg-default">
      <p className="gl-posts-sales__title text-color">
        {saleProduct.name}
      </p>
      <div>
        <img
          src={saleProduct.main_image.url || imagePlaceholder}
          alt={saleProduct.name}
          className="gl-posts-sales__image "
        />
      </div>
      <div style={{color: colors.textColor, backgroundColor: colors.backgroundColor}} 
           className="gl-posts-sales__action-container">
        <div className="gl-posts-sales__points-container">
          <span className="gl-posts-sales__points-value gl-product__points-value--post">
            {formatNumber(saleProduct.quantity)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PostSaleProduct
