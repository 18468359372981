import React, { Component } from 'react'
import { connect } from 'react-redux'
import {ToastContainer, ToastStore} from 'react-toasts'
import { 
  buildTransaction, saveQuizResponse, 
  moveToHistory, QuizzService 
} from '../../normalizer';
import {withRouter} from'react-router-dom'

export class QuizQuestionContainer extends Component {

  state = {
    currentQuestion : {
      options: [],
      id: 0
    },
    currentQuestionNumber: 0,
    total: undefined,
    questions: []
  }
  
  getQuizId() {
    return Number(this.props.match.params.id)
  }

  getAllQuestions() {
    const idAsNumber = this.getQuizId()

    const payload = {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: idAsNumber
    }

    this.props.startTransaction( () =>
      QuizzService.getQuizQuestions(payload)
        .then(response => response.data)
        .then(({questions, }) => {
          if(questions.length === 0) {
            this.props.history.push('/quiz')
            return
          }

          const currentQuestion =  questions[0] 
          this.setState({
            currentQuestion, 
            currentQuestionNumber: 1, 
            total: questions.length,
            questions
          })
        })
        .catch(() => this.props.history.push('/quiz'))
    )
  }

  getCachedReponse = () => {
    try {
      return this.props.cache[this.getQuizId()][this.state.currentQuestion.id]
    } catch(e) {
      return undefined
    }
  }

  handleQuizResponse = (answerId) =>{
    
    const payload =  {
      quizId: this.getQuizId(),
      questionId: this.state.currentQuestion.id,
      answerId
    }
    this.props.saveQuizResponse(payload)
  }

  handleNextQuestion = () => {
    if(this.getCachedReponse()) {
      this.setState(state => ({
        currentQuestionNumber: state.currentQuestionNumber + 1,
        currentQuestion: state.questions[state.currentQuestionNumber]
      }))
    } else {
      ToastStore.warning('Selecione uma opção para poder avançar para a próxima pergunta.')
    }
  }
 
  handlePrevQuestion = () => {
    if(this.state.currentQuestionNumber > 1) {
      this.setState(state => ({
        currentQuestionNumber: state.currentQuestionNumber -1,
        currentQuestion: state.questions[state.currentQuestionNumber - 2]
      }))
    }
  }

  handleQuizSubmit = () => {
    if(this.getCachedReponse()) {

      const id = this.getQuizId()
      let answers = []
      const quiz = this.props.cache[id]
      for (const questionId in quiz) {
        if (quiz.hasOwnProperty(questionId)) {
          const answerId = quiz[questionId];
          answers.push({
            option_id: answerId,
            question_id: questionId
          })
        }
      }

      const payload = {
        clientSlug: this.props.user.client.slug,
        campaignSlug: this.props.campaign.slug,
        id,
        answers
      }
      this.props.startTransaction(()=>
        QuizzService.completeQuiz(payload)
          .then(response => response.data)
          .then(data =>{
            this.props.moveToHistory(data.quizzes_completed, data.pagination, id)
            this.props.history.push('/quiz/history')
          })
          .catch(e => {
            ToastStore.error('Por favor, repita a submissão.')
          })
        )
    } else {
      ToastStore.warning('Selecione uma opção para poder submeter o Quiz.')
    }
  }

  componentDidMount() {
    this.getAllQuestions()
  }

  render() {
    const {component: Component, cache, ...rest} = this.props
    const selected = this.getCachedReponse()
 
    return (
      <>
        <ToastContainer store={ToastStore} />
        <Component 
          {...this.state}
          selectedAnswerId={selected}
          onQuizresponse={this.handleQuizResponse}
          onNextQuestion={this.handleNextQuestion}
          onPrevQuestion={this.handlePrevQuestion}
          onQuizSubmit={this.handleQuizSubmit}
          {...rest} 
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign,
  quizzes: state.quizReducer.quizzes,
  cache: state.quizReducer.cache,
})

const mapDispatchToProps = dispatch => ({
  saveQuizResponse : (payload) => dispatch(saveQuizResponse(payload)),
  moveToHistory: (quizzes, pagination,id) => dispatch(moveToHistory(quizzes, pagination, id)),
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(withRouter(QuizQuestionContainer))

export default Container

export function withQuestionContainer(component) {
  const QuestionContainerWrapper = (props) => (
    <Container
      component={component}
      {...props}
    />
  )
  
  return QuestionContainerWrapper
}
