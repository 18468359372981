import React from 'react'
import QuizzItem from './QuizzListItem';
import QuizzLayout from './QuizLayout';
import EmptyStateLayout from '../shared/EmptyStateLayout';
import {ReactComponent as NoQuiz} from '../../../assets/img/No_Quiz.svg'


const TodoQuizes = ({quizzes}) => {
  const noElements = quizzes.length === 0
  return (
    <QuizzLayout>
      {
        quizzes.map(q => (
          <QuizzItem {...q} key={'quiz-list-item' + q.id}/>
        ))
      }
      {
        noElements &&
        <EmptyStateLayout message="Não existem Quizes ativos">
          <NoQuiz className="flex-1 mb-5"></NoQuiz>
        </EmptyStateLayout>
      }
    </QuizzLayout>
  )
}

export default TodoQuizes
