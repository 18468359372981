import React, { memo } from "react"
import Modal from "../../../shared/Modal"
import {prefix} from '../../../../i18n'
import OrderItemsList from '../../shared/OrderItemsList'
import styled from 'styled-components'

const MsgSpan = styled.span`
  flex: 1;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(9, 128, 247, 0.3);
  font-size: 1.0em;
  border-radius: 10px;
`

const WarningSpan = styled.span`
  flex: 1;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.3);
  font-size: 1.0em;
  border-radius: 10px;
`
const tCartCheckout = prefix('cart-checkout')

const ModalMsgSpan = memo(({msg}) => {
  return (
    <div class="flex flex-1 flex-row justify-center items-center">
      <MsgSpan>
        <i className="fa fa-check-circle mx-5" />{" "}
        <h3 class="mx-5">{`${msg}`}</h3>
      </MsgSpan>
    </div>
  )
})

const AlertMsgSpan = memo(({alertMsg}) => {
  return (
    <div class="flex flex-1 flex-row justify-center items-center">
      <WarningSpan>
        <i className="fa fa-times-circle mx-5" />{" "}
        <h3 class="mx-5">{`${alertMsg}`}</h3>
      </WarningSpan>
    </div>
  )
})

const CartCheckoutModal = memo(({
  selected,
  content,
  onNoAccept,
  onAccept,
}) => {

  const {
    alertMsg,
    msg,
    items,
    shouldUseCoPayment,
  } = content;

  const handleNoAcceptAction = e => {
    e.preventDefault()
    onNoAccept()
  }

  const handleAcceptAction = e => {
    e.preventDefault()
    onAccept()
  }

  return (
    <Modal show={selected} lessPadding medium>
      <div className="">
        <div className="text-center font-quicksand mb-7 border-b-2 py-2 flex flex-row justify-center items-center">
          <h2 class="flex-1">{tCartCheckout("checkout-alert-title")}</h2>
        </div>
        <div className="custom-inner-html col-xs-12 leading-normal text-left p-5" />
        { alertMsg && <AlertMsgSpan alertMsg={alertMsg} />}
        { msg && <ModalMsgSpan msg={msg} />}
      </div>
      {shouldUseCoPayment && items && <OrderItemsList items={items} />}
      <div className="flex justify-between mt-10">
        <button
          className="outline-reset bg-red-dark text-base text-white rounded px-4 py-3"
          onClick={handleNoAcceptAction}
        >
          <i className="fa fa-times" />{" "}
          <span className="font-quicksand">Não aceitar</span>
        </button>
        <button
          className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
          onClick={handleAcceptAction}
        >
          <i className="fas fa-check" />{" "}
          <span className="font-quicksand">Aceitar</span>
        </button>
      </div>
    </Modal>
  );
})

export default CartCheckoutModal
