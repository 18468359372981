import axios from './interceptor';

export const PagesService = {

  getLinks(){
    const url = 'api/users/pages';
    return axios({
      method: 'get',
      url: url
    });
  }
};