import axios from './interceptor';

export const WishProductsService = {

  addToWishes(payload) {
    const url = `api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/wish_products`
    return axios({
      method: 'post',
      url: url,
      data: payload
    });
  },
  deleteFromWishes(payload){
    const url = `api/clients/${payload.client_slug}/campaigns/${payload.campaign_slug}/wish_products/${payload.product_id}`
    return axios({
      method: 'delete',
      url: url
    })
  }
};