import React, { Component } from 'react';
import { saveUser} from '../../../actions/userActions';
import { saveCart} from '../../../actions/cartActions';
import {ToastContainer, ToastStore} from 'react-toasts';
import { CartService } from '../../../services';
import '../../../assets/css/slider.scss';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, Redirect } from "react-router-dom";
import CartWarning from './CartWarning'
import { startFetch, endFetch} from '../../../actions/generalActions';
import ContentLayout from '../shared/ContentLayout';
import {prefix} from '../../../i18n'
import CartNavigation from './CartNavigation';
import CartItems from '../shared/CartItems';

const tCart = prefix('cart-item')
class ShoppingCart extends Component{
  constructor(props){
    super(props);
    this.state={redirect: false};
    this.removeItem = this.removeItem.bind(this)
    this.increaseItem = this.increaseItem.bind(this)
    this.decreaseItem = this.decreaseItem.bind(this)
  }
  getActiveStyle(){
    const  {
      text_color,
      navbar_text_color
    } = this.props.campaign
    return styled.div`
      color: ${navbar_text_color} !important;
      background: ${text_color}
      :before{
        border-left: 25px solid ${text_color};
      }
    `
  }
  getButtons(){
    const {
      color_primary,
      text_color,
      navbar_text_color
    } = this.props.campaign

    const primary = styled.button`
      color: ${navbar_text_color};
      background: ${color_primary};
      border: 2px solid ${color_primary};
      :hover{
        background: ${navbar_text_color};
        color: ${color_primary};
      }
    `
    const secondary = styled.button`
      color: ${text_color};
      background: white;
      border: 2px solid ${text_color};
      :hover{
        background: ${text_color};
        color: white;
      }
    `
    return [primary,secondary]
  }
  checkCartItems(e){
    this.props.startFetch();
    e.preventDefault()
    const {
      user,
      campaign
    } = this.props

    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug
    }

    CartService.checkCartItems(payload)
      .then((response) => {
        this.props.endFetch();
        this.setState({redirect: true});
      })
      .catch((error) => {
        this.props.endFetch();
        ToastStore.error('Carrinho vazio')
    });
  }

  increaseItem(item){
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug,
      item_id : item.id
    }
    this.props.startFetch();
    CartService.increaseItem(payload)
      .then((response) => {

        this.props.saveCart(response.data)
        this.props.endFetch();
        ToastStore.success('Foi adicionado uma unidade do produto')
      })
      .catch((error) => {
        this.props.endFetch();
        const message = error.response.data.message || "Impossivel de alterar produto"
        ToastStore.error(message)
      });
  }

  decreaseItem(item){
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug,
      item_id : item.id
    }
    this.props.startFetch();
    CartService.decreaseItem(payload)
      .then((response) => {
        this.props.saveCart(response.data)
        this.props.endFetch();
        ToastStore.success('Foi retirado uma unidade do produto')
      })
      .catch((error) => {
          this.props.endFetch();
          const message = error.response.data.message || "Impossivel de alterar produto"
          ToastStore.error(message)
      });
  }

  removeItem(item){
    const {
      user,
      campaign
    } = this.props
    const payload = {
      client_slug: user.client.slug,
      slug: campaign.slug,
      item_id : item.id
    }
    this.props.startFetch();
    CartService.removeItem(payload)
    .then((response) => {
      this.props.saveCart(response.data)
      this.props.endFetch();
      ToastStore.success('Produto foi removido')
    })
    .catch((error) => {
      this.props.endFetch();
      ToastStore.error('Impossivel de remover produto')
    });
  }

  render() {
    const {
      campaign,
      cart
    } = this.props
    const { redirect } = this.state
    const ActiveDiv = this.getActiveStyle();
    let  [ButtonPrimary, ButtonSecondary] = this.getButtons();

    if (redirect){
      return <Redirect to='cart_address' />
    }
    return (
      <ContentLayout campaign ={campaign} title ={tCart('title')}>
        <div className="col-xs-12">
          <div className='template cart-checkout-container'>
            <div className='w-95 m-auto'>
              <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_LEFT} lightBackground/>
              <CartNavigation campaign={campaign} step ={1}/>
              <CartItems
                removable
                campaign={campaign}
                cart={cart}
                onItemIncrease={this.increaseItem}
                onItemDecrease={this.decreaseItem}
                onItemRemove={this.removeItem}/>
              <div className='p-6 flex justify-between uppercase sm:flex-turn sm:flex-turn--reverse sm:flex-turn--j-center sm:flex-turn--a-center'>
                <Link style={{textDecoration: 'none' }} to='/catalog'>
                  <ButtonSecondary className='button-cart'>
                    {tCart('keep-buying')}
                  </ButtonSecondary>
                </Link>
                <ButtonPrimary onClick = { e => this.checkCartItems(e) } className='button-cart sm:mb-4'>{tCart('next-step')}</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
        <CartWarning text={campaign.call_attention} />
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    cart: state.cartReducer.cart
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart));
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())}
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ShoppingCart);
