
import React, { Component } from 'react'
import CustomSelect from '../../shared/CustomSelect'
import styled from 'styled-components'
const SearchIcon = styled.i`
  &.fa-search {
    color:#979797
  }
`

const CatalogProductSearch = ({campaign,brandDefaultValue,onBrandChange, brands, onSearchRequest}) => {
  const handleSearch = (e) => {
    e.preventDefault()
    const {productName} = e.target
    onSearchRequest({value: productName.value})
  }

  return (
    <div className="row between-xs">
      <CustomSelect 
        isClearable
        campaign={campaign} 
        onChange={e => onBrandChange(e)} 
        options={brands.filter(item => (item.name !== null))} 
        getOptionLabel={(option) => option.name} 
        defaultValue={brandDefaultValue} 
        getOptionValue={(option) => option.id} 
        placeholder='Marcas' className='col-md-4 col-xs-12 mb-3' 
        classNamePrefix='custom-select'/>
        <form className="col-md-8 col-xs-12 flex mb-3" onSubmit={handleSearch}>
          <input name="productName" placeholder="Pesquisa livre" className="product-input" type="text"/>
          <button type="submit" className="product-button__search">
            <SearchIcon className="fas fa-search"/>
          </button>
        </form>
    </div>
  )
  
}

export default CatalogProductSearch
