import React from 'react'

const Counter = ({value}) => {
  return (
    <span
      id="notification-counter"
      className="gl-notification__count gl-very-small-text navbar-campaign-link-icon-reverse font-bold"
    >
      {value}
    </span>
)
}

export default Counter
