import React, { Component } from 'react'
import { Switch, Redirect,withRouter} from 'react-router-dom'
import TabLink from '../shared/TabLink';
import InvoiceRoute from './InvoiceRoute'
import { InvoiceService } from '../../../services';
import { connect } from 'react-redux';
import {startFetch, endFetch} from '../../../actions/generalActions'
import {saveUnapprovedInvoices, saveVerifiedInvoices}from '../../../actions/invoiceActions'
import {ToastContainer, ToastStore} from 'react-toasts';

class ValidatorContainer extends Component {
  constructor(props) {
    super(props)
    this.pathToAction = {
      "/invoice/to-validate" : {
        action: this.getWaitingApprovalInvoices,
        stateVariable: "waitingApprovalPage"
      },
      "/invoice/verified" : {
        action: this.getVerifiedInvoices,
        stateVariable: "verifiedPage"
      }
    }
  }
  
  state = {
    fetchedVerified: false,
    fetchedWaitingApproval: false,
    verifiedPage: 1,
    verifiedPagination: {},
    waitingApprovalPage: 1,
    waitingApprovalPagination: {},
    verifiedFilters: {},
    waitingApprovalFilters: {}
  }

  invoicePayload = () => ({
    clientId: this.props.user.client.slug, 
    campaignId: this.props.campaign.slug
  })

  getWaitingApprovalInvoices = () =>  {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.waitingApprovalPage,
      filters : {
        ...this.state.waitingApprovalFilters
      }
    }
    this.props.startTran(()=>
      InvoiceService.getWaitingApprovalInvoices(payload)
      .then(response => {
        const {pagination, invoices} = response.data
          this.props.saveUnapprovedInvoices(invoices)
          this.setState({fetchedWaitingApproval: true, waitingApprovalPagination: pagination})
        })
        .catch(()=>this.props.endFetch())
    )
  }

  getVerifiedInvoices = () =>  {
    const payload = {
      ...this.invoicePayload(),
      page: this.state.verifiedPage,
      filters : {
        ...this.state.verifiedFilters
      }
    }
    this.props.startTran(()=>
      InvoiceService.getVerifiedInvoices(payload)
      .then(response => {
          const {pagination, invoices} = response.data
          this.props.saveVerifiedInvoices(invoices)
          this.setState({fetchedVerified: true, verifiedPagination: pagination})
        })
        .catch(()=>this.props.endFetch())
    )
  }

  handleClientPageUpdate = (path)=> {
    const {action, stateVariable} = this.pathToAction[path]
    return ({selected})=> {
      const normalizedPage = selected + 1
      if(this.state[stateVariable] !== normalizedPage) {
        this.setState({[stateVariable]: normalizedPage}, ()=> {
          action()
        })
      }
    }
  }
  
  updateVerifiedFilters = (filters) => {
    this.setState({verifiedFilters: filters}, ()=> this.getVerifiedInvoices())
  }
  
  updateWaitingApprovalFilters = (filters) => {
    this.setState({waitingApprovalFilters: filters}, ()=> this.getWaitingApprovalInvoices())
  }

  componentDidMount() {
    this.getWaitingApprovalInvoices()
    this.getVerifiedInvoices()
  }

  render() {
    const {campaign, toValidate, verified} = this.props
    const {
      fetchedWaitingApproval, fetchedVerified,
      waitingApprovalPagination, verifiedPagination
    } = this.state
    const activeStyle = {backgroundColor: campaign.color_primary, color: campaign.navbar_text_color }
    
    return (
      <>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_LEFT} lightBackground/>
        <div className="flex py-2 tab-height uppercase col-xs-12">
          <TabLink
            to={"/invoice/to-validate"}
            activeStyle={activeStyle}
            >
            Por validar
          </TabLink>
          <TabLink
            to={"/invoice/verified"}
            activeStyle={activeStyle}
            >
            Verificadas
          </TabLink>
        </div>
        <Switch>
          <Redirect exact from="/invoice" to="/invoice/to-validate" />
          <Redirect exact from="/invoice/add" to="/invoice/to-validate" />
          {fetchedWaitingApproval && <InvoiceRoute
            validatorView
            validatable
            onPageChange={this.handleClientPageUpdate}
            currentPage={this.state.waitingApprovalPage -1}
            totalPages={waitingApprovalPagination.total_pages || 1}
            onSearchRequest={this.updateWaitingApprovalFilters}
            path="/invoice/to-validate"
            campaign={campaign}
            invoices={toValidate}
          /> }
          {fetchedVerified && <InvoiceRoute
            validatorView
            completeTable
            onPageChange={this.handleClientPageUpdate}
            currentPage={this.state.verifiedPage -1}
            totalPages={verifiedPagination.total_pages || 1}
            onSearchRequest={this.updateVerifiedFilters}
            path="/invoice/verified"
            campaign={campaign}
            invoices={verified}
          />}
        </Switch>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  toValidate: state.invoiceReducer.unapprovedInvoices,
  verified: state.invoiceReducer.verifiedInvoices,
  user: state.userReducer.user
})

const mapDispatchToProps = (dispatch) => ({
  startTran: (promiseAction) => {
    dispatch(startFetch())
    promiseAction()
      .then(()=> 
        dispatch(endFetch())
      ).catch(() =>
        dispatch(endFetch())
      )
  },
  endfetch: () => dispatch(endFetch()),
  saveUnapprovedInvoices: (invoices) => dispatch(saveUnapprovedInvoices(invoices)),
  saveVerifiedInvoices: (invoices) => dispatch(saveVerifiedInvoices(invoices))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValidatorContainer))
