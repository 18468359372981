const initialState = {
  pendingSaleAttributions: [],
  completedSaleAttributions: [],
  pendingPagination: {},
  requested: [],
  requestedPagination: {}
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_PENDING_ATTRIBUTIONS":
      return {
        ...state,
        pendingSaleAttributions: payload.pendingSaleAttributions,
        pendingPagination: payload.pagination
      }

    case "SAVE_COMPLETED_ATTRIBUTIONS":
      return {
        ...state,
        completedSaleAttributions: payload.completedSaleAttributions,
        pendingPagination: payload.pagination
      }

    case "UPDATE_SALE_ATTRIBUTION":
      const updatedPendingSaleAttributions = state.pendingSaleAttributions.map(
        saleAttribution => {
          if (saleAttribution.id === payload.id) {
            return { ...saleAttribution, status: payload.status }
          }
          return { ...saleAttribution }
        }
      )
      return {
        ...state,
        pendingSaleAttributions: updatedPendingSaleAttributions
      }

    case "SAVE_REQUESTED_ATTRIBUTIONS":
      return { ...state, ...payload }

    case "MOVE_TO_REQUESTED_SALES":
      state.requested.unshift(payload.sale)
      return {
        ...state
      }
    default:
      return state
  }
}
