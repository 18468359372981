import React, { memo } from "react";
import { formatNumber } from "../normalizer";
import {t, prefix} from '../../../i18n'

const tOrdersItems = prefix('orders.items') 

const OrderItem = memo(({item, index}) => {
  return (
    <tr key={item.id} className="g-cart__data text-xs md:text-base my-2">
      <td className="">{index + 1}</td>
      <td className="g-cart__cell">{item.product.name}</td>
      <td className="g-cart__cell flex flex-row justify-center items-center">
      {item.product.accepts_co_payment ? 
        (<i className="fas fa-check text-green" />) :
        (<i className="fa fa-times text-red" />)
      }
      </td>
      <td className="text-center"><span className="px-1">{item.quantity}</span></td>
      <td className="text-center">{formatNumber(item.points)}</td>
    </tr>
  );
});


const OrderItemsList = memo(({items}) => {
  return (
    <div>
      <table className="mt-12 w-full ">
        <thead>
          <tr className="user-header text-center uppercase">
            <th className="w-1/5 prod-th-title order-th text-left ">Nº</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tOrdersItems("product.name")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tOrdersItems("product.accepts-co-payment")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tOrdersItems("quantity")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tOrdersItems("points")}</th>
          </tr>
        </thead>
        <tbody className="break hyphens">
          {
            items.map((item,i) => <OrderItem key={i} item={item} index={i} /> )
          }
        </tbody>
      </table>
    </div>
  )
});

export default OrderItemsList;
