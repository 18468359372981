const initialState = {
  cart: { items: [], shipment_address: undefined }
}

const cartReducer = (state = initialState, action) => {
  switch(action.type){
    case 'SAVE_CART':
      return {
        ...state,
        cart: action.payload.cart
      }
    default:
      return state;
  }
}
export default cartReducer;