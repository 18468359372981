import React from "react"
import CustomButton from "../shared/CustomButton"
import StyledImage from "../../shared/StyledImage"

const PageTitle = ({pageTitle}) => {
  return (
    <h2 className="primary-text pb-2 px-4 custom-inner-html gl-cust-page__title">
        {pageTitle}
    </h2>
  );
};

const PageImage = ({imageUrl}) => {

  return (
    <StyledImage url={imageUrl} className="content-images-full">
    </StyledImage>
  );
};

const PageBody = ({subtitle, body}) => {
  return (
    <>
    <h4 className="pb-2 custom-inner-html gl-cust-page__subtitle"> {subtitle} </h4>
    <div className="pb-2 custom-inner-html" dangerouslySetInnerHTML={{__html: body}}></div>
    </>
  );
};

const PageAttachments = ({attachments}) => {
  const onClickDownloadButton = (attachment) => {
    const link = document.createElement('a');
    link.href = attachment.url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAttachments = () => {
    return attachments.map((item, i) => {
      return <CustomButton key={i} primary className="gl-cust-page__button" onClick={() => onClickDownloadButton(item)}>
          Descarregar Anexo {attachments.length === 1 ? "" : i+1}
        </CustomButton>
    });
  };

  return (
    <>
    {
      (attachments.length > 0) &&
        <>
        <h4> Anexos </h4>
        <div className = 'py-3' > { getAttachments() } </div>
        </>
    }
    </>
  );
};


const Page = (props) => {
  const {
    pages,
    match
  } = props;

  let pageSlug = match.params.slug;
  let page = pages.find(p => p.slug === pageSlug);

  if (!page) {
    return null;
  }

  return (
    <div className="gl-cust-page">
      <PageImage imageUrl={page.image.url}></PageImage>
      <div className="px-4 text-color">
        <PageTitle pageTitle={page.title} ></PageTitle>
        <PageBody subtitle={page.subtitle} body={page.body}></PageBody>
        <PageAttachments attachments={page.attachments}></PageAttachments>
      </div>
    </div>
  );
};

export default Page;
