import React from "react"
import CircularHeaderLoader from "./CircularHeaderLoader"
const HighlightedObjective = props => {
  const normalizedPercentage = Number(props.objective.percentage)
  const normalizedExperctedPercentage = Number(
    props.objective.expected_percentage
  )
  const totalPercentage = normalizedPercentage > 100 ? 100 : normalizedPercentage
  return (
    <div className="flex flex-col items-center px-3">
      <CircularHeaderLoader
        campaign={props.campaign}
        progress={totalPercentage}
        expected_progress={normalizedExperctedPercentage}
        id={props.objective.id}
      >
        <span class='font-bold'>{normalizedPercentage.toFixed(1) + "%"}</span>
      </CircularHeaderLoader>
      <p className="text-center">{props.objective.abbr}</p>
    </div>
  )
}

const Objectives = ({ objectives, campaign, color }) => {
  if (objectives.length === 0){
    return <></>
  }
  return (
    <section className={`m-1 my-4 ${color}`}>
      <h4 className="text-center text-2xl">Objetivos</h4>
      <div className="flex -mx-3 mt-4 flex-wrap justify-center">
        {objectives.map(o => (
          <HighlightedObjective
            objective={o}
            campaign={campaign}
            key={"high-obj-" + o.id}
          />
        ))}
      </div>
    </section>
  )
}

export default Objectives
