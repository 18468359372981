import React from 'react'
import KeyValue from '../../shared/KeyValue'

const UserInfo = (props) => {
  const {
    first_name: firstName ,
    last_name: lastName,
    phone,
    login,
    email,
    gender,
    nif
  } = props

  let genderValue = ''
  
  if (gender === "male") {
    genderValue = "Masculino"
  } 
  else if (gender === "female"){
    genderValue = "Feminino"
  }


  return (
    <div className="g-profile__data-container mb-10">
      <div className="row g-profile__info-data g-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Identificador"
            value={login}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Primeiro nome"
            value={firstName}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Último nome"
            value={lastName}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
      <div className="row g-profile__info-data g-profile__info-block m-0">
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Email"
            value={email}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Telefone/Telemóvel"
            value={phone}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12 remove-padding">
          <KeyValue
            title="Género"
            value={genderValue}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
      <div className="row g-profile__info-data g-profile__info-block m-0">
        <div className="col-md-10 col-sm-10 col-xs-12 remove-padding">
          <KeyValue
            title="NIF"
            value={nif}
            classNameData="g-profile__info-resized"
            classNameTitle="g-profile__info-title"
          />
        </div>
      </div>
    </div>
  )
}

export default UserInfo
