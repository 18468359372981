import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import FooterVodafone from "./FooterVodafone"
import ClassBuilder from "./ClassBuilder"
import PopUpLayout from "./PopUpLayout"
import { withRouter } from "react-router"

const GamificationLayout = ({ children, className, campaign, toRender, slug, features }) => {
  const container = [
    "p-container default-bg py-10 catalog__container vodafone mb:pt-0",
    !className && "default-bg",
    className
  ].join(" ")
    return (
    <ClassBuilder campaign={campaign}>
      <Header toRender={toRender} features={features} />
      <main className={container}>{children}</main>
      <PopUpLayout />
      <FooterVodafone campaign={campaign} clientSlug={slug} features={features}/>
    </ClassBuilder>
  )
}

export default withRouter(GamificationLayout)
