import React from 'react'
import { withOrdersContainer, NoOrders } from '../../normalizer';
import EmptyStateLayout from '../../shared/EmptyStateLayout';
import OrdersTable from './OrdersTable';

const Orders = (props) => {
  const {
    orders,...rest
  } = props

  const message = "Neste momento ainda não tem encomendas."

  return (
    orders.length > 0 ? (
      <OrdersTable orders={orders} {...rest} />
    )
    : (
      <EmptyStateLayout message={message}>
        <div className="gl-no-store">
          <NoOrders className="mr-3 dead-grey gl-no-store__image"></NoOrders>
        </div>
      </EmptyStateLayout>
    )
    
  )
}

export default withOrdersContainer(Orders)
