import React from 'react'

const Tab = (props) => {
  const { activeTab, label, onClick } = props

  const handleOnClick = () => {
    onClick(label);
  }

  let className = 'tab-list-item';

  if (activeTab === label) {
    className += ' tab-list-active';
  }

  if (label !== '') {
    return (
      <li className={className} onClick={handleOnClick} >
        {label}
      </li>
    )
  } else {
    return <></>
  }
}

export default React.memo(Tab);
