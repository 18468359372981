import React, { Component } from 'react';
import GroupAddressShow from '../../DefaultTemplate/shared/AddressShow';
class Group extends Component{

  constructor(props){
    super(props); 
    this.state = { showable: this.props.showable }  

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      showable: !state.showable
    }));
  }
  
  render(){
    const {
      group,
      keys
    } = this.props

    const {
      showable
    } = this.state
    return(
        <div key= {keys}>
          <div className='flex template-blue between-xs'>
            <p className='py-3 '> {keys + 1}º {group.external_identifier}</p>
            <i onClick={(e) => this.handleClick() }className={"py-3 my-2 text-3xl fas fa-sort-down"}></i>
          </div>

          { showable ? 
             group.address !== null ?
              <GroupAddressShow classes='cart-address-border'  address = {group.address} />
              : <div className='py-5 px-8 cart-address-border  text-xl'>
                  <p className='template-address-gray'>Não existe endereço para este ponto de venda</p>
                </div>  
            : null 
          }
        </div>
      ) 
  }
  static defaultProps = {
    showable: false
  }
}

export default Group;