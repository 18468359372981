import React, {useCallback} from 'react'
import {Route, Switch} from 'react-router-dom'
import InvoiceSearch from './InvoiceSearch'
import InvoiceItemContainer from './InvoiceItemContainer'
import InvoiceTable from './InvoiceTable'
import InvoiceValidationForm from './InvoiceValidationForm'
import ReactPaginate from 'react-paginate'

export default function InvoiceRoute({
  path,
  completeTable, //if invoice has complete table -> validator(verified) || user(registref)
  completeForm, 
  validatable, //if invoice is to validate show validation form -> validator
  editable, //if invoice is to editable -> user
  campaign,
  invoices,
  validatorView,
  onPageChange,
  currentPage,
  totalPages,
  onSearchRequest,
  }) {

  // console.log(" ------------------------- [Invoice Route] ------------------------- ")
  // console.log(" [Invoice Route] Complete Table => ", completeTable)
  // console.log(" [Invoice Route] Path => ", path)
  // console.log(" [Invoice Route] Validatable => ", validatable)
  // console.log(" [Invoice Route] Editable => ", editable)
  // console.log(" [Invoice Route] Campaign => ", campaign)
  // console.log(" [Invoice Route] Invoices => ", invoices)

    return (
      <Route path={path}>
        <div className="template col-xs-12">
          <InvoiceSearch 
            invoices={invoices}
            campaign={campaign} 
            validator={completeForm} 
            onSearchRequest={onSearchRequest}
          />
          <Switch>
            <Route path={`${path}/:id`}>
              <div className="template bg-white relative">
                <InvoiceItemContainer 
                  editable={editable}
                  linking={path}
                  campaign={campaign}
                />
                {validatable && <InvoiceValidationForm campaign={campaign}/>}
              </div>
            </Route>
            <Route path={path}>
              <>
                <InvoiceTable 
                  validatable={validatorView}
                  validator={validatorView}
                  complete={completeTable}
                  linking={path}
                  invoices={invoices}
                  onPageChange={onPageChange}
                />
                  {totalPages &&  totalPages > 1 &&  
                  <div className="mt-5">
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      initialPage={currentPage}
                      onPageChange={onPageChange(path)}
                      containerClassName={'pagination justify-start center-xs start-sm row'}
                      subContainerClassName={'pages'}
                      activeClassName={'active-block'}/>
                  </div>
                  }
              </>
            </Route>
          </Switch>
        </div>
      </Route>
    )
}
