import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveCampaigns } from '../actions/campaignActions';
import { saveUser } from '../actions/userActions';
import { Route } from "react-router-dom";
import { Redirect } from 'react-router';

class PasswordRoute extends Component{

  constructor(props){
    super(props);
    this.state={redirect: false};
  }

  render() {
    const {component: Component, ...rest} = this.props
    return(
      <Route {...rest} render={(props) => {
        return this.props.user.client
        ? <Component {...props}/>
        : <Redirect to={'/confirmation'} />
      }} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCampaign: (campaign) => {
      dispatch(saveCampaigns(campaign));
    }
  }

}

export default connect(mapStateToProps,mapDispatchToProps)(PasswordRoute);