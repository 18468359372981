import React, { Component } from 'react'
import { connect } from 'react-redux'
import { HOC } from '../';
import { saveGroupObjectives, buildTransaction, AnalyticsService} from '../../normalizer';

export class GroupAnalyticsContainer extends Component {

  state = {
    page: 1,
    filtered: false
  }

  handleObjectiveFilter = (data) => {
    
    const startDate = data.startDate && data.startDate.toDate()
    const endDate = data.endDate && data.endDate.toDate()
    const normalizedStartDate = startDate && new Date(Date.UTC(startDate.getFullYear(),startDate.getMonth(),startDate.getDate()))
    const normalizedEndDate = endDate && new Date(Date.UTC(endDate.getFullYear(),endDate.getMonth(),endDate.getDate())) 

    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.props.group.id,
      page: this.state.page,
      filters : {
        begin_date: normalizedStartDate,
        end_date: normalizedEndDate,
        user_name: data.username
      }
    }

    AnalyticsService.getGroupObjectives(payload)
      .then(response => response.data)
      .then(({ objectives, pagination }) => {
        this.props.saveGroupObjectives(objectives, pagination);
      }).catch(e => {
        const errorApi = window.rollbar.error
        if(errorApi) {
          errorApi(e)
        }
      })

    if(this.hasFilters(payload.filters)){
      this.setState({filtered: true})
    } else {
      this.setState({filtered: false})
    }
  }

  hasFilters = ({begin_date, end_date, user_name}) => {
    return begin_date || end_date || user_name
  }


  fetchGroupObjectives() {
    const payload = {
      clientSlug: this.props.client.slug,
      campaignSlug: this.props.campaign.slug,
      id: this.props.group.id,
      page: this.state.page
    }

    this.props.startTransaction(() =>
      AnalyticsService.getGroupObjectives(payload)
        .then(response => response.data)
        .then(({ objectives, pagination }) => {
          this.props.saveGroupObjectives(objectives, pagination);
        })
    )
  }

  handlePageChange = (data) => {
    this.setState({ page: data.selected + 1 }, () => this.fetchGroupObjectives())
  }

  componentDidMount() {
    if(this.props.objectives.length === 0) {
      this.fetchGroupObjectives();
    }
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Component
        onPageChange={this.handlePageChange}
        onObjectiveFilter={this.handleObjectiveFilter}
        page={this.state.page}
        filtered={this.state.filtered}
        {...rest}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  objectives: state.analyticsReducer.objectives,
  campaign: state.campaignReducer.campaign,
  client: state.userReducer.client,
  pagination: state.analyticsReducer.objectivesPagination,
})

const mapDispatchToProps = (dispatch) => ({
  saveGroupObjectives: (objectives, pagination) => dispatch(saveGroupObjectives(objectives, pagination)),
  startTransaction: buildTransaction(dispatch)
})

const Container = connect(mapStateToProps, mapDispatchToProps)(GroupAnalyticsContainer)

export function withObjectiveAnalyticsContainer(component) {
  const withObjectiveAnalyticsContainer = HOC(Container, component)
  return withObjectiveAnalyticsContainer
}
export default Container
