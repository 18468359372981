import React from "react"
import CustomLink from "../shared/CustomLink"
import CustomButton from "../shared/CustomButton"
import CustomSelect from "../shared/CustomSelect"
const BonusPointsAddNavbar = () => {
  return (
    <>
      <div className='flex justify-start'>
        <h3>Atribuição de Pontos</h3>
      </div>
      <div className="flex gl-bonus__title navbar-text mb-8" />
    </>
  )
}
const BonusPointsAddForm = props => {

  const {
    colleagues,
    selectedColleague,
    onChangeColleague,
    onUpdatePoints,
    points,
    onSubmitForm,
    formErrors,
    clientProducts,
    selectedProduct,
    onChangeProduct
  } = props
  
  return (
    <div className='my-10 flex flex-col'>
      <BonusPointsAddNavbar />
      <div className="w-full text-size">
        <div className='gl-home__catalog px-3 py-5'>
          <div className='gl-bonus__input-row row'>
            <span className='primary-text gl-bonus__label'>Selecione o utilizador a atribuir:</span>
            <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6' >
              <CustomSelect 
                campaign={props.campaign} 
                onChangeEvent={onChangeColleague} 
                options={colleagues} 
                optionsLabel={'employee_identifier'}
                selectedValue={selectedColleague}
                optionsId={'id'} 
                placeholder='Escolha um Utilizador' 
                classNames={'w-full'}
                noMessage={"Sem utilizadores"}
              />
              { formErrors['colleague'] &&
                <span className='w-full gl-bonus__input-error'>{formErrors['colleague']}</span>
              }
            </div>
          </div>

          <div className='gl-bonus__input-row row'>
            <span className='primary-text gl-bonus__label'>Selecione o produto a atribuir:</span>
            <div className='col-xs-12 col-sm-6 col-md-6 col-lg-6' >
              <CustomSelect 
                campaign={props.campaign} 
                onChangeEvent={onChangeProduct} 
                options={clientProducts} 
                optionsLabel={'external_identifier'}
                selectedValue={selectedProduct}
                optionsId={'id'} 
                placeholder='Escolha um Produto' 
                classNames={'w-full'}
              />
              { formErrors['product'] &&
                <span className='w-full gl-bonus__input-error'>{formErrors['product']}</span>
              }
            </div>
          </div>
          <div className='gl-bonus__input-row row'>
            <span className='primary-text gl-bonus__label'>Selecione o nº de pontos a atribuir:</span>
            <div className='gl-bonus__input-width'>
              <input type='number' value={points} onChange={(e) => onUpdatePoints(e) }className='gl-bonus__number-input w-full' min='1'/>
              { formErrors['number'] &&
                <span className='w-full gl-bonus__input-error'>{formErrors['number']}</span>
              }
            </div>
          </div>
          <div className='row justify-between pt-10 px-3'>
            <CustomLink secondary to="/bonus_points">Cancelar</CustomLink>
            <CustomButton onClick={() => onSubmitForm() } primary>Confirmar</CustomButton>
          </div>
        </div>

      </div>

    </div>

  )
}

export default BonusPointsAddForm
