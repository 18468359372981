import React from 'react'

const KeyValue = ({title, value, colSpan = 1, classNameData, classNameTitle}) => {

  const tilteClass = classNameTitle !== undefined ? `gl-profile__info ${classNameTitle}  primary-text` : "gl-profile__info primary-text"
  const dataClass = classNameData !== undefined ? `gl-profile__info gl-profile__info--value ${classNameData}` : "gl-profile__info gl-profile__info--value "
  return (
    <div colSpan={colSpan} className="gl-profile__table-cell">
      <div className={tilteClass}>{title}</div>
      <div className={dataClass}>{value}</div>
    </div>
  )
}

export default KeyValue
