import React from "react"
import Moment from "moment"
import EmptyStateLayout from "../shared/EmptyStateLayout"
import BonusPointsLayout from "./BonusPointsLayout"

const BonusPointsHeaders = () => {
  return (
    <>
      <tr className='gl-table__row'>
        <th className="p-2 text-left">Produto</th>
        <th className="p-2 text-right">Pontos Totais</th>
        <th className="p-2 text-right">Pontos Atribuidos Extra</th>
        <th className="p-2 text-right">Pontos por atribuir</th>
        <th className="p-2 text-right">Ultimo Carregamento</th>
      </tr> 
    </>
  )
}

const BonusPointsTable = props => {

  const {
    bonusPoints
  } = props

  const hasPointsToGive = bonusPoints.length !== 0 

  
  return (
    <BonusPointsLayout>
    <div className="w-full text-size">
      {!hasPointsToGive ? (
        <EmptyStateLayout message="Ainda não se encontram pontos bonus atribuidos">
        </EmptyStateLayout>
      ) : (
        <div className='overflow-x-auto'>
          <table className='w-full gl-table'>
            <thead>
              <BonusPointsHeaders />
            </thead>
            <tbody>
              { bonusPoints.map((bonusPoint,i) => {
                  let normalizedDate = new Moment(bonusPoint.last_date)
                  let pointsEarned = Number(bonusPoint.total_points_earned)
                  let pointsSpent = Number(bonusPoint.total_points_spent)
                  return (
                    <tr className='gl-table__row' key={i}>
                      <td className='p-2'>{bonusPoint.client_product.external_identifier}</td>
                      <td className='p-2 text-right'>{pointsEarned}</td>
                      <td className='p-2 text-right'>{pointsSpent}</td>
                      <td className='p-2 text-right'>{pointsEarned - pointsSpent}</td>
                      <td className='p-2 text-right'>{normalizedDate.format('DD/MM/YYYY')}</td>
                    </tr> 
                  )
                })
              }
            </tbody>
          </table>
        </div>
        
      )}
    </div>
    </BonusPointsLayout>

  )
}

export default BonusPointsTable
