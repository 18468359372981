import React from "react"
import { withCatalogProductContainer } from "../../normalizer"
import Product from "../../shared/Product"
import Item from "./Item"
import { Link } from "react-router-dom"

const CatalogProductItem = ({ item, client, lastSeenProducts, ...actions }) => {
  const { currency_label: currencyLabel } = client

  return (
    <div className="my-5">
      <section className="mb-5 flex ">
        <Link to="/catalog" className="link-reset flex items-center">
          <i className="fas fa-arrow-left mr-3 primary-text" />
          <p className="lowercase">Voltar</p>
        </Link>
      </section>

      <section className="mb-3">
        <Item item={item} actions={actions} currencyLabel={currencyLabel} />
      </section>

      <hr className="gl-catalog__horizontal-line" />

      <section className="mt-8">
        <h4 className="mb-3 gl-product-detail__title font-bold">
          Vistos recentemente
        </h4>
        <div className="row -mx-bs">
          {lastSeenProducts.slice(0, 5).map((p, i) => (
            <Product
              currencyLabel={currencyLabel}
              key={`p-id-${p.id}-${i}`}
              {...p}
            />
          ))}
        </div>
      </section>
    </div>
  )
}

export default withCatalogProductContainer(CatalogProductItem)
