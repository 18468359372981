import React, { Component } from 'react';
import ConsentModal from './ConsentModal';

class Consent extends Component{

  constructor(props){
    super(props);
    
    this.state={ selected: false };
  }

  toggleConsentModal = () => {
    this.setState({
      selected: true,
    })
  }

  untoggleConsentModal = () => {
    this.setState({
      selected: false,
    })
  }
  render(){
    const { subdescription , description, disabled, name, id, accepted, formUpdate} = this.props
    const { selected } = this.state

    return(
      <>
      <div className='consent-container flex w-full py-3'>
        <label className='consent-checkbox-container' htmlFor={`consent-${id}`}>
          <input disabled={disabled} onChange={ e => formUpdate(e,id) }className = 'consent-checkbox-input' checked = {  accepted || false } value = { accepted || false } id={`consent-${id}`} type='checkbox'></input>
          <span className = 'consent-checkbox-label' ></span>
        </label>
        <div className='consent-text-container'>  
          <p className="text-lg consent-text">
            {subdescription} 
            <span onClick={() => this.toggleConsentModal()}className='pl-1 consent-seemore'>(Sabe mais...)</span>
          </p>
        </div>
      </div>
      <ConsentModal selected={selected} onUnSelectObject={this.untoggleConsentModal} description={description} name={name} />
      </>
    )
  }

}
export default Consent;