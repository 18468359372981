import React, { Component } from "react"
import { connect } from "react-redux"
import {
  pointBlocksService,
  buildTransaction,
  savePointBlocks,
  saveHistoryBlocks
} from "../normalizer"

class PointBlockContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1
    }
  }

  fetchPointBlocks() {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug
    }

    this.props.startTransaction(() => 
    pointBlocksService.getBlocks(payload).then(response => {
      const {group_block, products_blocks, last_update}  = response.data 
      const pointBlocks = {
        groupBlock: group_block,
        productBlocks: products_blocks,
        lastUpdated: last_update
      }
      this.props.savePointBlocks(pointBlocks)
    }))
  }

  fetchHistoryBlocks() {
    const { user, campaign } = this.props
    const payload = {
      clientSlug: user.client.slug,
      campaignSlug: campaign.slug,
      page: this.state.page
    }

    this.props.startTransaction(() => 
    pointBlocksService.getHistoryBlocks(payload).then(response => {
      const history_response = {
        historyBlocks: response.data.history_blocks, 
        pagination: response.data.pagination
      }  
      this.props.saveHistoryBlocks(history_response)
    }))
  }

  handlePageClick = data => {
    let selected = data.selected
    this.setState(
      {
        page: selected + 1
      },
      () => {
        this.fetchHistoryBlocks()
      }
    )
  }

  componentDidMount() {
    if (this.props.productBlocks.length === 0){
      this.fetchPointBlocks()
    }
    if (this.props.historyBlocks.length === 0){
      this.fetchHistoryBlocks()
    }
  }

  render() {
    const { component: Component, ...rest } = this.props
    
    return <Component 
      {...rest} 
      onPageChange={this.handlePageClick}
      page={this.state.page}
    />
  }
}

const mapDispatchToProps = dispatch => ({
  startTransaction: buildTransaction(dispatch),
  savePointBlocks: (pointBlocks) => dispatch(savePointBlocks(pointBlocks)),
  saveHistoryBlocks: (historyBlocks) => dispatch(saveHistoryBlocks(historyBlocks))
})

const mapStateToProps = state => ({
  groupBlock: state.pointBlocksReducer.groupBlock,
  productBlocks: state.pointBlocksReducer.productBlocks,
  lastUpdated: state.pointBlocksReducer.lastUpdated,
  historyBlocks: state.pointBlocksReducer.historyBlocks,
  historyPagination: state.pointBlocksReducer.historyPagination,
  user: state.userReducer.user,
  campaign: state.campaignReducer.campaign
})

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PointBlockContainer)

export function withPointBlockContainer(component) {
  return props => <Container component={component} {...props} />
}

export default Container
