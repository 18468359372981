import React, {memo, useState} from 'react'
import '../../../assets/css/slider.scss';
import Modal from "../../shared/Modal"
import {prefix} from '../../../i18n'
import styled from 'styled-components'

const WarningSpan = styled.span`
  flex: 1;
  display: flex;
  width: fit-content;
  height: fit-content;
  padding: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.3);
  font-size: 1.0em;
  border-radius: 10px;
`
const AlertMsgSpan = memo(({alertMsg}) => {
  return (
    <div class="flex flex-1 flex-row justify-center items-center">
      <WarningSpan>
        <i className="fa fa-times-circle mx-5" />{" "}
        <h3 class="mx-5">{`${alertMsg}`}</h3>
      </WarningSpan>
    </div>
  )
})

const tPaymentMethod = prefix('cart-checkout.payment-method')
const tPaymentSource = prefix('cart-checkout.source')

const PaymentSourceContent = memo(({ source, validity, bgColor, textColor }) => {
  return (
    <div class="flex-1 flex-col justify-center items-center">
         <div class="flex-1 flex justify-around items-start my-5">
           <h3 style={{ backgroundColor: bgColor, color: textColor }} class="flex-1 m-2 py-2 text-center">{tPaymentSource("entity")}</h3>
           <h3 class="flex-1 self-center ml-5 secondary-text text-left">{source && (source.entity || "")}</h3>
         </div>
         <div class="flex-1 flex justify-around items-start my-5">
           <h3 style={{ backgroundColor: bgColor, color: textColor }} class="flex-1 m-2 py-2 text-center">{tPaymentSource("reference")}</h3>
           <h3 class="flex-1 self-center ml-5 secondary-text text-left">{source && (source.reference || "")}</h3>
         </div>
         <div class="flex-1 flex justify-around items-start my-5">
           <h3 style={{ backgroundColor: bgColor, color: textColor }} class="flex-1 m-2 py-2 text-center">{tPaymentSource("value")}</h3>
           <h3 class="flex-1 self-center ml-5 secondary-text text-left">{source && (Number(source.value).toFixed(2) || "")}</h3>
         </div>
         {validity > 0 && <div class="flex-1 flex justify-around items-start my-5">
           <h3 style={{ backgroundColor: bgColor, color: textColor }} class="flex-1 m-2 py-2 text-center">{tPaymentMethod("validity")}</h3>
           <h3 class="flex-1 self-center ml-5 secondary-text text-left">{validity || ""}</h3>
         </div>}
     </div>
  )
})

const CoPaymentModal = memo(
  ({
    show,
    close,
    orderPayment,
    bgColor,
    textColor,
  }) => { 
    const [showPaymentMethodInfo, setShowPaymentMethodInfo] = useState(false)

    if ( orderPayment && orderPayment.provider && orderPayment.provider.payment_method ) {
      const { provider, source } = orderPayment
      return (
        <Modal show={show} lessPadding small untoggle={close}>
         <div className="">
           <div class="flex-1 flex justify-end items-center">
            <button onClick={close}>X</button>
           </div>
           <div className="text-center font-quicksand mb-7 border-b-2 pb-5 mb-5 flex flex-row justify-center items-center">
             <div class="flex-3 justify-center items-center pl-2">
              <h2 className="primary-text">{tPaymentMethod("payment-details-title")}</h2>
             </div>
           </div>
           <div class="flex-1 flex-col justify-center items-center">
             <PaymentSourceContent source={source} validity={provider.validity} bgColor={bgColor} textColor={textColor}/>
           </div>
         </div>
        </Modal>
      )
    }


   return (
     <Modal class="h-auto" show={show} lessPadding small untoggle={close}>
       <div class="flex flex-col justify-center items-center">
         <AlertMsgSpan
           alertMsg={tPaymentMethod("error.absent-payment-details")}
         />
       </div>
     </Modal>
   );
  }
); 

export default CoPaymentModal



