import React from "react"

const ProductLoader = ({
  progress = 0
}) => {
  return (
    <svg className={'g-product__loader-bar'}>
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={'#0984e3'} />
          <stop offset="100%" stopColor={'#0984e3'} />
        </linearGradient>
        <linearGradient id="prev-linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={'#0984e3'}/>
          <stop offset="100%" stopColor={'#0984e3'} />
        </linearGradient>
      </defs>
      <rect
        fill="url(#linear)"
        height={"10px"}
        width={Number(progress) + "%"}
      />
       <rect
        fill="url(#prev-linear)"
        height={"10px"}
        width={"100%"}
        opacity={"0.4"}
      />
    </svg>
  )
}

export default ProductLoader
