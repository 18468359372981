import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClientService, CartService, BalanceService, PagesService, SupportingDocumentsService } from '../services';
import { saveCampaign, saveCampaigns,selectCampaign } from '../actions/campaignActions';
import { saveUser } from '../actions/userActions';
import { saveCart } from '../actions/cartActions';
import { saveCampaignUser } from '../actions/balanceActions'
import { startFetch, endFetch, resetFetch } from '../actions/generalActions';
import { savePages } from '../actions/pagesActions'
import { saveSupportingDocuments } from '../actions/supportingDocumentsActions'
import DefaultTemplate from '../components/DefaultTemplate'
import ReactGA from 'react-ga';
import GamificationTemplate from '../components/GamificationTemplate';
import GamificationLevelTemplate from '../components/GamificationLevelTemplate';

// selects current Template
class TemplateWrapper extends Component {

  componentDidMount(){
    const { slug } = this.props.user.client 
    const currClientSlug = this.props.client.slug

    //user client slug must be equal to user.client slug
    if(slug !==  currClientSlug) {
      this.props.history.push('/not-found')
      return
    }

    const {selectedCampaign} = this.props
    if(selectedCampaign) {
      document.title = selectedCampaign.name
      this.loadData(selectedCampaign.slug)
      this.setGoogleAnalytics(selectedCampaign.slug)
    } else {
      this.props.history.push('/campaign')
    }
  }

  componentDidUpdate(prevprops){
    const {selectedCampaign} = this.props
    const prevSlug = prevprops.selectedCampaign.slug
    if(selectedCampaign && prevSlug && prevSlug !== selectedCampaign.slug){
      const {slug, name} = selectedCampaign
      document.title = name
      this.loadData(slug)
      this.setGoogleAnalytics(slug)
    }
  }

  setGoogleAnalytics = (slug) => {
    ReactGA.initialize(this.props.client.ga_id)
    this.props.history
      .listen(location => ReactGA.pageview("/" + slug + location.pathname))
  }

  loadData(campaignSlug){
    const clientSlug = this.props.user.client.slug
    
    this.props.startFetch()
    ClientService.getCampaign({client_slug: clientSlug, slug: campaignSlug})
      .then(response => {
        let responseCampaign = response.data;
        if (responseCampaign.campaign.range_filters) {
          responseCampaign.campaign.range_filters.sort((a, b) => {
            return a.order - b.order;
          });
        }
        this.props.saveCampaign(responseCampaign)
        this.props.endFetch()
  
        this.props.startFetch()
        CartService.getCart({client_slug: clientSlug, slug: campaignSlug})
          .then((response) => {
            this.props.saveCart(response.data)
            this.props.endFetch()
          }).catch(error => {
            this.props.endFetch()           
          })


        this.props.startFetch()
        BalanceService.getUserPoints({clientSlug: clientSlug, campaignId: campaignSlug} )
          .then(response => {
            const campaignUser = response.data
            this.props.saveCampaignUser(campaignUser)
            this.props.endFetch()
          })
          .catch(()=>(this.props.endFetch()))
    
        this.props.startFetch()
        PagesService.getLinks()
          .then(response => {
            const pages = response.data
            this.props.savePages(pages)
            this.props.endFetch()
          })
          .catch(()=>(this.props.endFetch()))
          
        this.props.startFetch()
        SupportingDocumentsService.getSupportingDocuments({client_slug: clientSlug})
          .then(response => {
            const supportingDocuments = response.data
            this.props.saveSupportingDocuments(supportingDocuments);  
            this.props.endFetch()
          })
          .catch(()=>(this.props.endFetch()));
    
        if(this.props.campaigns.length === 0) {
          this.props.startFetch()
          ClientService.getCampaigns({client_slug: clientSlug})
            .then((response) => {
              this.props.saveCampaigns(response.data)
              this.props.endFetch()})
            .catch(() => this.props.endFetch())
        }
      }).catch(() => {
        this.props.history.push("/campaign")
        this.props.endFetch()
      })


  }

  selectTemplate = () => {
    const {template_type} = this.props.campaign
    switch(template_type) {
      case 'gamification_levels':
        return GamificationLevelTemplate
      case 'gamification':
        return GamificationTemplate
      default:
        return DefaultTemplate
    }
  }

  render(){
    const { client, user, campaign, campaignUser } = this.props
    const { slug } = user.client
    let fontFamily = campaign.font_family

    if(!slug || !campaign.slug) return null

    const Template = this.selectTemplate()

    return <Template slug={slug} client={client} campaign={campaign} campaignUser={campaignUser} fontFamily={fontFamily}/>
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    campaigns: state.campaignReducer.campaigns,
    campaignUser: state.balanceReducer.campaignUser,
    selectedCampaign: state.campaignReducer.selectedCampaign,
    products: state.productsReducer,
    client: state.userReducer.client,
    loading: state.generalReducer.loading,
    pages: state.pagesReducer.pages,
    supportingDocuments: state.supportingDocumentsReducer.supportingDocuments
  }
}

function mapDispatchToProps(dispatch){
  return{
    saveUser: (user) => {
      dispatch(saveUser(user));
    },
    saveCampaign: (campaign) => {
      dispatch(saveCampaign(campaign));
    },
    saveCampaigns: (campaign) => {
      dispatch(saveCampaigns(campaign));
    },
    saveCampaignUser: (user) => {
      dispatch(saveCampaignUser(user));
    },  
    selectCampaign: (campaign) => {
      dispatch(selectCampaign(campaign));
    },
    saveCart: (cart) => {
      dispatch(saveCart(cart))
    },
    savePages: (pages) => {
      dispatch(savePages(pages))
    },
    saveSupportingDocuments: (supportingDocuments) => {
      dispatch(saveSupportingDocuments(supportingDocuments))
    },
    startFetch: () => { dispatch(startFetch())},
    endFetch: () => { dispatch(endFetch())},
    resetFetch: () => { dispatch(resetFetch())},
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(TemplateWrapper);
