import React from "react"
import { withCampaignContainer } from "../../../containers/shared"

const ProductLoader = ({
  progress = 0,
  campaign
}) => {
  return (
    <svg className={'g-product__loader-bar'}>
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={campaign.color_primary} />
          <stop offset="100%" stopColor={campaign.color_primary} />
        </linearGradient>
      </defs>
      <rect
        fill="url(#linear)"
        height={"10px"}
        width={Number(progress) + "%"}
      />
    </svg>
  )
}

export default withCampaignContainer(ProductLoader)
