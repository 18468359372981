import React, { Component } from "react"
import { connect } from "react-redux"
import {
  SaleTradeService,
  startFetch,
  endFetch,
  saveRequestedTrades
} from "../../normalizer"
import { HOC } from "../../gamification"

class RequestedTradesContainer extends Component {
  state = {
    page: 1
  }

  payload() {
    return {
      clientSlug: this.props.user.client.slug,
      campaignSlug: this.props.campaign.slug
    }
  }

  async fetchRequestedTrades() {
    try {
      this.props.startFetch()

      const payload = {
        ...this.payload(),
        page: this.state.page
      }
      const response = await SaleTradeService.getRequestedSales(payload)
      const { sales_requested, pagination } = response.data
      this.props.saveRequestedTrades(sales_requested, pagination)
    } finally {
      this.props.endFetch()
    }
  }

  componentDidMount() {
    this.fetchRequestedTrades()
  }

  render() {
    const { component: Component, ...rest } = this.props

    return <Component {...rest} />
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    campaign: state.campaignReducer.campaign,
    sales: state.saleTradeReducer.requested,
    pagination: state.saleTradeReducer.requestedPagination
  }
}

function mapDispatchToProps(dispatch) {
  return {
    startFetch: () => dispatch(startFetch()),
    endFetch: () => dispatch(endFetch()),
    saveRequestedTrades: (sales, pagination)=> dispatch(saveRequestedTrades(sales, pagination))
  }
}

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestedTradesContainer)

export function withRequestedTradesContainer(component) {
  const withRequestedTradesContainer = HOC(Container, component)
  return withRequestedTradesContainer
}
export default Container
