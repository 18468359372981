import React, { memo } from "react";
import {t, prefix} from '../../../i18n'

const tPaymentMethod = prefix('cart-checkout.payment-method') 

const PaymentMethodItem = memo(({item, campaignRate, points, index, chooseMethod}) => {
  const rate = Number(item.rate)
  
  const commissionPercent = Number(item.payment_method.commission_percentage) || 0
  const commissionFixedValue = Number(item.payment_method.commission_fixed_value) || 0

  const monetaryValue = points / rate
  const commissionFinalValue = commissionFixedValue + (monetaryValue * (commissionPercent / 100))
  const monetaryValueWitCommission = Math.ceil( (monetaryValue + commissionFinalValue) * 100) / 100
  
  return (
    <tr key={item.id} className="g-cart__data text-xs md:text-base my-2">
      <td className="">
          <input
            className="bg-green-dark text-base text-white rounded px-4 py-3 outline-reset"
            style={{ height: '20px', width: '20px' }}
            type="checkbox"
            onChange={e => chooseMethod(item)}
          />
      </td>
      <td className="g-cart__cell text-center"><span className="px-1">{`${monetaryValueWitCommission} \u20ac`}</span></td>
      <td className="g-cart__cell text-center">{item.payment_method.name}</td>
      <td className="g-cart__cell text-center"><span className="px-1">{item.validity > 0 ? item.validity : 'Sem validade'}</span></td>
      <td className="g-cart__cell flex flex-row justify-center items-center">
      {item.payment_method.allows_manual_payment ? 
        (<i className="fas fa-check text-green" />) :
        (<i className="fa fa-times text-red" />)
      }
      </td>
    </tr>
  );
});

const PaymentMethodsList = memo(({campaignRate, debtPoints, methods, handleMethodChoosing}) => {
  return (
    <div>
      <table className="mt-12 w-full ">
        <thead>
          <tr className="user-header text-center uppercase">
            <th className="w-1/5 prod-th-title order-th text-left ">Escolha</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tPaymentMethod("value-to-pay")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tPaymentMethod("name")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tPaymentMethod("validity")}</th>
            <th className="w-1/5 prod-th-title order-th text-center ">{tPaymentMethod("allows-manual-payment")}</th>
          </tr>
        </thead>
        <tbody className="break hyphens">
          {
            methods.map((item,i) => <PaymentMethodItem key={i} item={item} campaignRate={campaignRate} points={debtPoints} index={i} chooseMethod={handleMethodChoosing}/> )
          }
        </tbody>
      </table>
    </div>
  )
});

export default PaymentMethodsList;
