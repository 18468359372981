import { combineReducers } from "redux"
import userReducer from "./userReducer"
import campaignReducer from "./campaignReducer"
import productsReducer from "./productsReducer"
import ordersReducer from "./ordersReducer"
import balanceReducer from "./balanceReducer"
import cartReducer from "./cartReducer"
import addressesReducer from "./addressesReducer"
import generalReducer from "./generalReducer"
import contentReducer from "./contentReducer"
import rankingsReducer from "./rankingsReducer"
import newsReducer from "./newsReducer"
import homepageBannersReducer from "./homepageBannersReducer"
import pagesReducer from "./pagesReducer"
import supportingDocumentsReducer from "./supportingDocumentsReducer"
import invoiceReducer from "./invoiceReducer"
import objectivesReducer from "./objectivesReducer"
import notificationsReducer from "./notificationsReducer"
import quizReducer from "./quizReducer"
import analyticsReducer from "./analyticsReducer"
import saleAttributionReducer from "./saleAttributionReducer"
import saleTradeReducer from "./saleTradeReducer"
import postsReducer from "./postsReducer"
import pointBlocksReducer from "./pointBlocksReducer"
import bonusPointsReducer from "./bonusPointsReducer"
import levelsReducer from "./levelsReducer"

const appReducer = combineReducers({
  userReducer,
  campaignReducer,
  productsReducer,
  cartReducer,
  addressesReducer,
  contentReducer,
  ordersReducer,
  balanceReducer,
  generalReducer,
  rankingsReducer,
  newsReducer,
  homepageBannersReducer,
  pagesReducer,
  supportingDocumentsReducer,
  invoiceReducer,
  objectivesReducer,
  notificationsReducer,
  quizReducer,
  analyticsReducer,
  saleAttributionReducer,
  saleTradeReducer,
  postsReducer,
  pointBlocksReducer,
  levelsReducer,
  bonusPointsReducer
});

export default appReducer
